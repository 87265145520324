import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useContext, useEffect } from 'react'
import { Button, Dimmer, Divider, Icon, Input, List, Loader, Menu, Segment } from 'semantic-ui-react'
import { RootStoreContext } from '../../stores/RootStoreContext';
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from 'react-router';
import { CreateCampaignWidget } from './components/CreateCampaignWidget';
import { MarketingCampaignsStore } from './functions/CampaignsStore';

interface IProps {
    store: MarketingCampaignsStore
}

const MarketingCampaignsWidget: React.FC<IProps> = ({ store }) => {
    const navigate = useNavigate();

    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const { openModal } = context.modalStore;
    const { load,
        dispose,
        loading,
        items,
        hasMore,
        search,
    } = store;

    useEffect(() => {
        load();

        return () => {
            dispose();
        };
    }, [load, dispose]);

    const [timer, setTimer] = React.useState(0);

    return (
        <div>
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "5px 0" }}
            >
                <Menu.Item
                    className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}
                >
                    <Input
                        disabled={true}
                        fluid={isMobile}
                        transparent={isMobile}
                        size={isMobile ? "large" : "small"}
                        icon={
                            !isMobile ? <Icon name="search" color="blue" /> : undefined
                        }
                        placeholder={"Search..."}
                        input={
                            <input className={isMobile ? "" : "bluePlaceholder"} />
                        }
                        onChange={(event: ChangeEvent, data: any) => {
                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: (text: string) => void, text: string) => {
                                        callback(text);
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search,
                                    data.value
                                )
                            );
                        }}
                    />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Button
                        content="New Campaign"
                        color="blue"
                        basic
                        onClick={() => {
                            openModal(<CreateCampaignWidget />, "small", undefined, false, false);
                        }}
                    />
                </Menu.Menu>
            </Menu>
            <Divider />
            {!loading || items.length > 0 ? (
                <div>
                    <InfiniteScroll
                        style={{ overflow: "hidden" }}
                        dataLength={items.length} //This is important field to render the next data
                        next={() => load()}
                        hasMore={hasMore}
                        loader={loading ? <h4>Loading...</h4> : <div />}
                    >
                        <List
                            divided
                            relaxed
                            selection
                            style={isMobile ? { padding: "7px" } : undefined}
                        >
                            {items.map((x) => (
                                <List.Item key={x.id} onDoubleClick={() => navigate(`/marketing-campaigns/${x.id}`)}>
                                    <List.Icon
                                        name="user circle outline"
                                        size="large"
                                        verticalAlign="middle"
                                    />
                                    <List.Content>
                                        <List.Header>
                                            {x.title ?? "-"}
                                        </List.Header>

                                        <List.Description>
                                            <p>
                                                <span>
                                                    <b>Total :</b>{" "}
                                                    {x.target}
                                                </span>
                                                <span style={{ marginLeft: "5px" }}>
                                                    <b>Issues :</b>{" "}
                                                    {x.issues}
                                                </span>
                                            </p>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            ))}
                        </List>
                    </InfiniteScroll>
                </div>
            ) : (
                <Segment>
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                </Segment>
            )}
        </div>
    )
}

export default observer(MarketingCampaignsWidget)