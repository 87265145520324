import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { Device } from "../../../interfaces/devices";

class UserDevices {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable searchText?: string = undefined;
    @observable hasMore: boolean = false;
    @observable count: number = 0;
    @observable items: Device[] = []

    @action setLoading = (value: boolean) => this.loading = value;
    @action setCount = (value: number) => this.count = value;
    @action setSearchText = (value?: string) => this.searchText = value;
    @action resetItems = (value: Device[]) => this.items = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setItems = (value: Device[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action search = async (searchText?: string) => {
        try {
            this.resetItems([]);
            this.setCurrentPage(0)
            this.setSearchText(searchText)

            this.loadCount();
            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action loadCount = async () => {
        try {
            agent.Devices.count(this.searchText).then((count) => this.setCount(count));
        } catch (error) {
        }
    }

    @action load = async () => {
        try {
            this.setLoading(true);

            if (this.currentPage === 0) {
                this.loadCount();
            }

            var response = await agent.Devices.get(this.searchText, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action dispose = () => {
        this.setCurrentPage(0);
        this.setSearchText(undefined);
        this.setHasMore(false);
        this.setItems([]);
    }
}


export const UserDevicesStore = new UserDevices();