import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface IProps {
  onCategoryClick: (categoryId: string) => void;
  selectedCategories: string[];
}

const CategoriesFilter: React.FC<IProps> = ({
  onCategoryClick,
  selectedCategories,
}) => {
  const context = useContext(RootStoreContext);
  const { isMobile, isTablet } = context.deviceStore;
  const { setCategoryClickEvent } = context.analyticsStore;
  const { load, categories } = context.categoriesStore;

  useEffect(() => {
    load();

    return () => { };
  }, [load]);

  var settings = {
    dots: false,
    slidesToShow: isMobile ? 3 : 12,
    slidesToScroll: isMobile ? 2 : 4,
    infinite: false,
    adaptiveHeight: true,
    padding: "0px",
    swipeToSlide: true,
    swipe: true,
    touchMove: true,
    mobileFirst: isMobile,
    arrows: !isMobile,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      className={`${isMobile ? "slick-container-mobile" : (isTablet ? "slick-container-tablet" : "slick-container")}`}
    >
      <Slider {...settings}>
        {categories.map((x) => (
          <div
            key={x.id}
            className={`fxDisplay fxDirCol slick-option ${selectedCategories.includes(x.internalId)
              ? "slick-option-selected"
              : ""
              }`}
            onClick={() => {
              setCategoryClickEvent(x.description);
              onCategoryClick(x.internalId);
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={`/categories/${x.internalId}.png`}
                // src={x.imageUrl}
                alt={x.description}
                className="slick-menu-img"
              />
            </div>
            <div className="slick-menu-span">{x.description}</div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default observer(CategoriesFilter);
