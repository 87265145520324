import { action, makeObservable, observable } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./RootStoreContext";
import { IDietProfile } from "../interfaces/dietprofile";
import { FoodTypes } from "../options/diets";

export class UserDietProfileStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable loading: boolean = true;
    @observable selectedStep: number = 1;
    @observable profile?: IDietProfile = {
        meas_type: "metric",
        en_meas_type: "calories"
    };
    @observable setupSteps: number[] = [1, 2, 3, 4, 5];

    @action setLoading = (value: boolean) => this.loading = value;
    @action setProfile = (value?: IDietProfile) => this.profile = value;

    @action getUserDietProfile = async () => {
        try {
            this.setLoading(true);

            const { user } = this.rootStore.userStore;
            agent.Habibai.DietProfiles.get(user!.id)
                .then((result) => {
                    this.setProfile(result);
                    this.setLoading(false);
                })
                .catch(() => this.setLoading(false));

        } catch (error) {
            console.log(error);
        } finally {
            // this.setLoading(false);
        }
    }

    @action categoryClick = (type: string) => {
        if (!this.profile) {
            return;
        }

        var items = FoodTypes.filter(f => f.categories.includes(type) && !f.is_all).map(x => x.type);

        var allAreIncluded = this.profile?.avoid?.filter(f => items.includes(f)).length === items.length;

        if (allAreIncluded) {
            // remove items
            this.profile.avoid = this.profile?.avoid?.filter(f => !items.includes(f))
        }
        else {
            // include all items
            this.profile.avoid?.push(...items);
            this.profile.avoid = Array.from(new Set(this.profile.avoid));
        }
    }

    @action footTypeClick = (type: string) => {
        if (!this.profile) {
            return;
        }

        var isIncluded = (this.profile?.avoid?.filter(f => f === type).length ?? 0) > 0;

        if (isIncluded) {
            // remove items
            this.profile.avoid = this.profile?.avoid?.filter(f => f !== type)
        }
        else {
            this.profile.avoid?.push(type);
            this.profile.avoid = Array.from(new Set(this.profile.avoid));
        }
    }

    @action saveProfile = () => {
        if (!this.profile) return;

        try {
            this.setLoading(true);

            if (!this.profile?.id)
                agent.Habibai.DietProfiles.create(this.profile).then(x => {
                    this.setProfile({ ...this.profile, id: x });
                    this.setLoading(false);
                });
            else
                agent.Habibai.DietProfiles.update(this.profile).then(x => this.setLoading(false));;
        } catch (error) {

        }
    }

    @action nextStep = () => {
        // check final step
        if (this.selectedStep === 5) {
            this.saveProfile();
        }

        this.selectedStep += 1
    }

    @action previousStep = () => {
        this.selectedStep -= 1
    }

}