import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Divider, Step } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import UploadStep1 from "./Components/UploadStep1";
import UploadStep2 from "./Components/UploadStep2";
import UploadStep3 from "./Components/UploadStep3";
import { useNavigate, useParams } from "react-router";

const UploadInventoryWizard = () => {
  const navigate = useNavigate();
  let { shopId } = useParams<"shopId">();
  const context = useContext(RootStoreContext);
  const { step, dispose, getAllLocations } = context.uploadWizard;
  const { accountId, isAdmin } = context.userStore;

  useEffect(() => {
    if (!isAdmin && shopId !== accountId) {
      navigate("/");
      return;
    }
    getAllLocations(shopId ?? accountId!);
    return () => {
      dispose();
    };
  }, [dispose, shopId, isAdmin, accountId, getAllLocations, navigate]);

  return (
    <div>
      <div
        style={{ paddingTop: "18px" }}
        className="fxFixed fxDisplay fxDirCol fxAlignCenter"
      >
        <Step.Group>
          <Step
            key="prepare_data"
            icon="file excel outline"
            title="Getting Started"
            description="Instructions for data preparation"
            active={step === 0}
          />
          <Step
            key="review_data"
            icon="table"
            title="Review Data"
            description="Review the data from the file"
            active={step === 1}
          />
          <Step
            key="upload_result"
            icon="info"
            title="Upload Result"
            description="Review the data from the file"
            active={step === 2}
          />
        </Step.Group>
      </div>
      <Divider />

      {step === 0 && <UploadStep1 />}
      {step === 1 && <UploadStep2 />}
      {step === 2 && <UploadStep3 />}
    </div>
  );
};

export default observer(UploadInventoryWizard);
