import { action, computed, makeAutoObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IBoxes, IClientDetailsCourier, IOrderForMonitor } from "../../../interfaces/order";
import { ClientOrderProductStatusType } from "../../../options/HubOrderProductStatus";
import { toast } from "react-toastify";
import { ConstractFullName } from "../../../helpers/textHelper";
import { Localities } from "../../../options/Localities";
import { Button } from "semantic-ui-react";

class OrderPreparation {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable updatingProduct: boolean = false;
    @observable updatingOrder: boolean = false;
    @observable order?: IOrderForMonitor = undefined;
    @observable paymentType?: string = undefined;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setUpdatingProduct = (value: boolean) => this.updatingProduct = value;
    @action setUpdatingOrder = (value: boolean) => this.updatingOrder = value;
    @action setOrder = (value?: IOrderForMonitor) => this.order = value;
    @action setPaymentType = (value?: string) => this.paymentType = value;

    @action load = async (id: string) => {
        try {
            this.setLoading(true);

            var detail = await agent.Orders.Admin.Monitor.get_detail(id);
            this.setOrder(detail);
        } catch (error) {

        }
        finally {
            this.setLoading(false);
        }
    }

    @action dispose = () => {
        this.setLoading(false);
        this.setOrder(undefined);
    }

    @action getTrackingNumberEnabled = () => {
        if (!this.order) return false;

        return this.order.products.filter(f => f.quantity > (f.packedQuantity ?? 0) + (f.refundedQuantity ?? 0)).length === 0
    }

    @action updateTransactionId = async (closeModal: () => void, transactionId: string) => {
        if (!transactionId || !this.order) return;

        agent.Orders.updateOrderTransactionId(this.order.id, transactionId).then(() => {
            if (!this.order) return;
            this.load(this.order?.id).then(() => closeModal())
        });
    }

    @action getTrackingNumber = async (openModal: (...args: any) => void, closeModal: () => void, boxes?: IBoxes) => {
        if (!this.order) return;
        if (!boxes) return;

        try {
            // check that there is at least 1 box
            var totalBoxes = (boxes?.chill ?? 0) + (boxes?.dry ?? 0) + (boxes?.frozen ?? 0);

            if (totalBoxes === 0) {
                toast.error("Need at least 1 box for delivery")
                return;
            }

            // send api to save the order boxes and get the tracking number
            var addressLines = []
            addressLines.push(this.order.address.addressLine1)
            if ((this.order.address.addressLine2 ?? "") !== "") {
                addressLines.push(this.order.address.addressLine2)
            }

            var clientDetails: IClientDetailsCourier = {
                "name": ConstractFullName(
                    this.order.user.firstName,
                    this.order.user.lastName,
                    this.order.user.middleName
                ),
                "phone": this.order.user.phoneNumber!,
                "email": this.order.user.email,
                "addressLines": addressLines,
                "locality": Localities.filter(f => f.value === this.order?.address.localityId)[0].text,
                "postCode": this.order.address.postCode,
            }

            var orderDetails = {
                'id': this.order.id,
                'displayId': this.order.displayId,
            }

            var response = await agent.Orders.Admin.Monitor
                .requestTracking(this.order.id, boxes, clientDetails, orderDetails);

            runInAction(() => {
                if (!this.order) return;
                this.order.boxes = boxes;
                this.order.parcelTrackingNumber = response.parcelTrackingNumber;
                this.order.shippingLabel = response.shippingLabel;
            })

            this.printShippingLabel(openModal, closeModal, response.shippingLabel);

        } catch (error) {

        }
    }

    @action printShippingLabel = (openModal: (...args: any) => void, closeModal: () => void, shippingLabel: string) => {
        const base64 = shippingLabel
        // Prefix for the base64 string to make it a valid data URL
        const base64Prefix = 'data:application/pdf;base64,';

        // Creating a Blob directly from base64 string
        fetch(`${base64Prefix}${base64}`)
            .then(res => res.blob())
            .then(blob => {
                // Creating a URL for the Blob
                const blobUrl = URL.createObjectURL(blob);

                // Create an invisible iframe
                let iframe = document.createElement('object');
                // iframe.style.display = 'none';
                iframe.data = blobUrl;
                iframe.type = "application/pdf";
                iframe.height = "100%";
                iframe.width = "100%";


                openModal(
                    <div style={{ height: "70vh" }}>
                        <object aria-label="shipping-label" data={blobUrl} type="application/pdf" height={"95%"} width={"100%"} />
                        <div style={{ textAlign: "center" }}>
                            <Button color="blue" content="Mark as Printed" onClick={() => this.updateOrderPrinted()?.then(() => closeModal())} />
                        </div>
                    </div>,
                    "fullscreen",
                    "Print parcel label(s)",
                    true,
                    false
                );
            });
    }

    @action updateOrderPrinted = () => {
        if (!this.order) return

        return agent.Orders.Admin.Monitor.updatePrinted(this.order.id).then(() => {
            runInAction(() => {
                if (!this.order) return
                this.order.shippingLabel = undefined;
                this.order.labelPrinted = true;
            })
        })
    }

    @computed get isNextStepActive() {
        if (!this.order) return false;

        switch (this.order.status) {
            case "sent_to_market":
                var unpackedItems = this.order.products.filter(f => f.quantity > (f.packedQuantity ?? 0) + (f.refundedQuantity ?? 0)).length
                if (unpackedItems > 0) {
                    return false;
                }
                if ((this.order.transactionId === undefined || this.order.transactionId === null) && this.paymentType === undefined) {
                    return false;
                }
                switch (this.order.checkoutType) {
                    case "delivery":
                        // if (!this.order.boxes) {
                        //     return false;
                        // }
                        // if (!this.order.parcelTrackingNumber) {
                        //     return false;
                        // }
                        // if (this.order.shippingLabel) {
                        //     return false;
                        // }
                        // if (!this.order.labelPrinted) {
                        //     return false;
                        // }
                        return true;
                    case "pickup":
                        return true;
                    default:
                        return false;
                }
            case "out_for_delivery":
                return true
            case "completed":
                return false
            case "ready_for_pickup":
                return true
            case "ready_for_delivery":
                return true
            case "payment_failed":
            case "pending_payment":
                return false
            case "pending_processing":
                return false;
            default:
                return false;

        }
    }

    @computed get getNextStep() {
        if (!this.order) return "N/A";

        switch (this.order.status) {
            case "sent_to_market":
                return this.order.checkoutType === "pickup" ? "Ready For Pickup (Issue Receipt)" : "Ready For Delivery (Issue Receipt)"
            case "out_for_delivery":
                return "Mark As Completed"
            case "completed":
                return "N/A"
            case "ready_for_pickup":
                return "Mark As Completed"
            case "ready_for_delivery":
                return "Mark As Out For Delivery"
            case "payment_failed":
            case "pending_payment":
                return "Waiting for Payment"

            case "pending_processing":
                return "Waiting for Hub Order";
            default:
                return "Undefined";

        }
    }

    @action toggleHasImage = async (catalogueId: string) => {
        try {
            agent.Catalogue.Admin.toggleHasImage(catalogueId).then(() => {
                runInAction(() => {
                    if (!this.order) return;

                    var idx = this.order?.products.findIndex(x => x.catalogueId === catalogueId);
                    this.order.products[idx].hasImage = !this.order.products[idx].hasImage;
                })

            })
        } catch (error) {

        }
    }

    @computed get totalCost() {
        if (!this.order) return 0;

        return this.order.products.reduce((a, b) => a + (b.packedQuantity ?? 0) * b.currentPrice, 0) + this.order.serviceFee + this.order.deliveryFee - this.order.creditsUsed - this.order.discount;
    }

    @action updateOrderStatus = async () => {
        if (!this.order) return;

        try {
            this.setUpdatingOrder(true);

            switch (this.order.status) {
                case "sent_to_market":
                    // update print receive and mark ready_for_delivery or ready_for_pickup
                    await agent.Orders.Admin.Monitor.update(
                        this.order.id,
                        this.order.checkoutType === "delivery" ? "ready_for_delivery" : "ready_for_pickup",
                        this.paymentType
                    )
                        .then(() => {
                            runInAction(() => {
                                if (!this.order) return;
                                this.order.status = this.order.checkoutType === "delivery" ? "ready_for_delivery" : "ready_for_pickup"
                            })
                        })
                    toast.success("Updated!")
                    break;
                case "out_for_delivery":
                case "ready_for_pickup":
                    // update completed 
                    await agent.Orders.Admin.Monitor.update(this.order.id, "completed")
                        .then(() => {
                            runInAction(() => {
                                if (!this.order) return;
                                this.order.status = "completed"
                            })
                        })
                    toast.success("Updated!")
                    break;
                case "ready_for_delivery":
                    // update out for delivery
                    await agent.Orders.Admin.Monitor.update(this.order.id, "out_for_delivery")
                        .then(() => {
                            runInAction(() => {
                                if (!this.order) return;
                                this.order.status = "out_for_delivery"
                            })
                        })
                    toast.success("Updated!")
                    return true

            }

        } catch (error) {

        }
        finally {
            this.setUpdatingOrder(false);
        }
    }

    @action updatePackedProduct = async (catalogueId: string, locationId: string, packedAmount: number) => {
        if (!this.order) return;

        try {
            this.setUpdatingProduct(true);

            var idx = this.order.products.findIndex(x => x.catalogueId === catalogueId && x.locationId === locationId);

            var requestQ = this.order.products[idx].quantity

            var status: ClientOrderProductStatusType = +packedAmount === 0 ? "missing" : +packedAmount >= requestQ ? "fully_packed" : "partially_packed"

            await agent.Orders.Admin.Monitor.updateItem(this.order.id, locationId, catalogueId, {
                quantity: +packedAmount,
                status: status
            }).then(() => {
                runInAction(() => {
                    if (!this.order) return;

                    var idx = this.order.products.findIndex(x => x.catalogueId === catalogueId && x.locationId === locationId);
                    this.order.products[idx].packedQuantity = packedAmount;
                    this.order.products[idx].status = status
                })

            })
        } catch (error) {

        }
        finally {
            this.setUpdatingProduct(false);
        }
    }

    @action undoProduct = async (catalogueId: string, locationId: string) => {
        if (!this.order) return;

        try {
            this.setUpdatingProduct(true);

            await agent.Orders.Admin.Monitor.updateItem(this.order.id, locationId, catalogueId, {
                quantity: 0,
                status: "pending"
            }).then(() => {
                runInAction(() => {
                    if (!this.order) return;

                    var idx = this.order.products.findIndex(x => x.catalogueId === catalogueId && x.locationId === locationId);
                    this.order.products[idx].packedQuantity = 0
                    this.order.products[idx].refundedQuantity = 0
                    this.order.products[idx].status = "pending"
                })

            })
        } catch (error) {

        }
        finally {
            this.setUpdatingProduct(false);
        }
    }

    @action refundProduct = async (catalogueId: string, locationId: string, quantity: number) => {
        if (!this.order) return;

        try {
            this.setUpdatingProduct(true);

            await agent.Orders.Admin.Monitor.refundItem(this.order.id, locationId, catalogueId, quantity).then(() => {
                runInAction(() => {
                    if (!this.order) return;

                    var idx = this.order.products.findIndex(x => x.catalogueId === catalogueId && x.locationId === locationId);
                    this.order.products[idx].refundedQuantity = quantity
                })

            })
        } catch (error) {

        }
        finally {
            this.setUpdatingProduct(false);
        }
    }
}

export const OrderPreparationStore = new OrderPreparation();