import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Brand, Category } from "../../interfaces/search";
import "./Search.css"
import { Divider } from "semantic-ui-react";
import CategorySliderItem from "./CategorySliderItem";
import BrandSliderItem from "./BrandSliderItem";

interface IProps {
  onCategoryClick: (categoryId: string) => void;
  onBrandClick: (brandId: string) => void;
  selectedCategories?: Category[];
  selectedBrands?: Brand[];
  scroll?: () => void;
}

const SearchSlider: React.FC<IProps> = ({
  onCategoryClick,
  onBrandClick,
  selectedCategories,
  selectedBrands,
  scroll
}) => {
  const context = useContext(RootStoreContext);
  const { isMobile, isTablet } = context.deviceStore;
  let baseSettings = {
    dots: false,
    slidesToShow: isMobile ? 4 : 8,
    slidesToScroll: isMobile ? 2 : 4,
    adaptiveHeight: true,
    padding: "0px",
    swipeToSlide: true,
    swipe: true,
    touchMove: true,
    mobileFirst: isMobile,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let categoriesSettings = {
    ...baseSettings,
    infinite: false,
  }
  let brandsSettings = {
    ...baseSettings,
    infinite: false,
  }


  let categoriesSlider = selectedCategories && selectedCategories.length > 0 ? (
    <div className="horizontalList">
      <div className="horizontalListTitle">Categories</div>
      <Slider {...categoriesSettings}>
        {selectedCategories.map((x) => (
          <CategorySliderItem
            key={x.id}
            category={x}
            onCategoryClick={() => onCategoryClick(x.id)}
            scroll={scroll}
          />
        ))}
      </Slider>
      <Divider clearing /></div>
  ) : null;
  let brandsSlider = selectedBrands && selectedBrands.length > 0 ? (
    <div className="horizontalList">
      <div className="horizontalListTitle">Brands</div>
      <Slider {...brandsSettings} >
        {selectedBrands.map((x) => (
          <BrandSliderItem
            key={x.id}
            brand={x}
            onBrandClick={() => onBrandClick(x.id)}
          />
        ))}
      </Slider><Divider clearing /></div>
  ) : "";

  return (
    <Fragment>
      <div style={{ display: `${selectedCategories && selectedCategories.length <= 0 ? 'none' : ''}` }}
        className={`${isMobile ? "slick-container-mobile" : (isTablet ? "slick-container-tablet" : "slick-container")} horizontalList`}
      >
        {categoriesSlider}
      </div>
      <div style={{ display: `${selectedBrands && selectedBrands.length <= 0 ? 'none' : ''}` }}
        className={`${isMobile ? "slick-container-mobile" : (isTablet ? "slick-container-tablet" : "slick-container")} horizontalList`}
      >
        {brandsSlider}
      </div>
    </Fragment>
  );
};

export default observer(SearchSlider);
