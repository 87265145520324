import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Grid, Menu, MenuItemProps, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import BillingDetailsAddressWidget from "./Components/BillingDetailsAddressWidget";
import BillingDetailsContactInfoWidget from "./Components/BillingDetailsContactInfoWidget";
import BillingTransactionWidget from "./functions/BillingTransactionWidget";
import BillingModuleWidget from "./Components/BillingModuleWidget";
import PricingModuleWidget from "./Components/PricingModuleWidget";
import OtherShopAccessWidget from "./Components/OtherShopAccessWidget";

interface IProps {
  shopId?: string;
}

const BillingDetails: React.FC<IProps> = ({ shopId }) => {
  const context = useContext(RootStoreContext);
  const { activeTab, setActiveTab, getBillingDetails, dispose } =
    context.billingDetailsStore;
  const { accountId, isAdmin, isOwner } = context.userStore;

  useEffect(() => {
    getBillingDetails(shopId ?? accountId!);

    return () => {
      dispose();
    };
  }, [getBillingDetails, shopId, accountId, dispose]);

  return (
    <Fragment>
      <Menu attached="top" tabular>
        <Menu.Item
          name="billingDetails"
          content={"Billing Details"}
          active={activeTab === "billingDetails"}
          onClick={(e: any, data: MenuItemProps) => {
            setActiveTab(data.name!);
          }}
        />
        {/* <Menu.Item
          name="billingTransactions"
          content={"Transactions"}
          active={activeTab === "billingTransactions"}
          onClick={(e: any, data: MenuItemProps) => {
            setActiveTab(data.name!);
          }}
        /> */}
        {isAdmin && <Menu.Item
          name="modules"
          content={"Modules"}
          active={activeTab === "modules"}
          onClick={(e: any, data: MenuItemProps) => {
            setActiveTab(data.name!);
          }}
        />}
        {isAdmin && <Menu.Item
          name="other_shops"
          content={"Other Shop Access"}
          active={activeTab === "other_shops"}
          onClick={(e: any, data: MenuItemProps) => {
            setActiveTab(data.name!);
          }}
        />}
        {isOwner && <Menu.Item
          name="pricing"
          content={"Pricing"}
          active={activeTab === "pricing"}
          onClick={(e: any, data: MenuItemProps) => {
            setActiveTab(data.name!);
          }}
        />}

      </Menu>
      <Segment padded={"very"} attached="bottom" style={{ minHeight: "100%" }}>
        {activeTab === "billingDetails" && (
          <Fragment>
            <Grid centered columns={2}>
              <Grid.Column>
                {/**Contact Info */}
                <BillingDetailsContactInfoWidget />

                {/**Address Info */}
                <BillingDetailsAddressWidget />
              </Grid.Column>
            </Grid>
          </Fragment>
        )}
        {activeTab === "billingTransactions" && (
          <BillingTransactionWidget shopId={shopId} />
        )}
        {activeTab === "modules" && (
          <Fragment>
            <BillingModuleWidget shopId={shopId ?? accountId!} />
          </Fragment>
        )}
        {activeTab === "other_shops" && (
          <Fragment>
            <OtherShopAccessWidget shopId={shopId ?? accountId!} />
          </Fragment>
        )}
        {activeTab === "pricing" && (
          <Fragment>
            <PricingModuleWidget shopId={shopId ?? accountId!} />
          </Fragment>
        )}
      </Segment>
    </Fragment>
  );
};

export default observer(BillingDetails);
