import { Tab } from "semantic-ui-react";
import CreateSession from "../../registration/CreateSession";
import Registration from "../../registration/Registration";

export function requestUserSession(
  isMobile: boolean,
  openModal: (...args: any[]) => void,
  loginCallback?: (...args: any[]) => void,
  registrationCallback?: (...args: any[]) => void,
  emailForReset?: string
) {
  openModal(
    <Tab
      menu={{
        secondary: true,
        pointing: true,
        color: "green",
        size: isMobile ? "massive" : "small",
      }}
      panes={[
        {
          menuItem: {
            key: "signin",
            content: "Sign In",
            style: { flex: isMobile ? 1 : "" },
          },
          render: () => (
            <CreateSession
              callbackOnSuccess={loginCallback}
              emailForReset={emailForReset}
            />
          ),
        },
        {
          menuItem: {
            key: "register",
            content: "Register",
            style: { flex: isMobile ? 1 : "" },
          },
          render: () => (
            <Registration callbackOnSuccess={registrationCallback} />
          ),
        },
      ]}
    />,
    "mini",
    undefined,
    true,
    false
  );
}
