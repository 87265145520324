export const CheckOutOptions: Array<{
    value: string,
    key: string,
    text: string,
}> =
    [
        {
            "value": "delivery",
            "key": "delivery",
            "text": "Delivery",
        },
        {
            "value": "pickup",
            "key": "pickup",
            "text": "Pickup",
        },
    ]