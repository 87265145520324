import { formatRelative } from "date-fns";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Button, Card, Icon, Image, List } from "semantic-ui-react";
import agent from "../../api/agent";
import { getImageUrl } from "../../helpers/productImage";
import { IProduct, IProductForList } from "../../interfaces/product";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { currencyFormat } from "../products/functions/productHelper";

interface IProps {
  product: IProductForList;
  createGroup: () => void;
  addToGroup: () => void;
}

const WizardProductCard: React.FC<IProps> = ({
  product,
  createGroup,
  addToGroup,
}) => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const [productFull, setProductFull] = useState<IProduct>();

  useEffect(() => {
    agent.Products.getDetail(product.id).then((response) =>
      setProductFull(response)
    );

    return () => { };
  }, [setProductFull, product]);

  return (
    <Card>
      <Image
        alt="Product Logo"
        src={
          getImageUrl(product) ??
          `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
        }
      />
      <Card.Content>
        <Card.Header>{product.itemDesc}</Card.Header>
        <Card.Meta>
          <div
            style={{
              textAlign: "right",
              fontSize: "18px",
              fontWeight: "bolder",
            }}
          >
            <Icon name="euro" />
            {currencyFormat(product.currentPrice, false)}
          </div>
        </Card.Meta>
        <Card.Description>
          <List>
            <List.Item>
              <span>Market :</span>
              {markets.filter((f) => f.type_id === product.marketId)[0].title}
            </List.Item>
            <List.Item>
              <span>Last Updated :</span>
              {formatRelative(
                new Date(product.lastUpdated * 1000),
                new Date(),
                {}
              )}
            </List.Item>
            <List.Item>
              <span>Internal Id :</span> {productFull?.internalId}
            </List.Item>
            <List.Item>
              <span>Unit :</span> {productFull?.unit ?? "N/A"}
            </List.Item>
            <List.Item>
              <span>Price Per Unit :</span> {productFull?.pricePerUnit ?? "N/A"}
            </List.Item>
          </List>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button
            content="Create Group"
            basic
            color="blue"
            onClick={createGroup}
          />
          <Button
            content="Add to Group"
            basic
            color="purple"
            onClick={addToGroup}
          />
        </div>
      </Card.Content>
    </Card>
  );
};

export default observer(WizardProductCard);
