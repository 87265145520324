import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { IAssociationImage } from '../../../interfaces/catalogue';
import { ImageGroup, Image } from 'semantic-ui-react';
import { getImageUrlPlain } from '../../../helpers/productImage';
import CardWithUpload from '../../../components/photoUpload/CardWithUpload';

interface IProps {
    onUpdate: (path: string) => void;
    associationId: string;
}

const CatalogueImageSelection: React.FC<IProps> = ({ associationId, onUpdate }) => {
    const context = useContext(RootStoreContext);

    const { loadImages, uploadFile } = context.catalogueAdminStore;

    const [images, setImages] = React.useState<IAssociationImage[]>([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                loadImages(associationId).then((result) => setImages(result))

            } catch (error) {
                console.error('Error fetching data: ', error);
                // Handle errors as needed
            }
        };

        fetchData();

        return () => {
        }
    }, [loadImages, associationId])

    return (
        <div style={{ textAlign: "center" }}>
            <CardWithUpload
                loading={false}
                uploadImage={(file: object) => {
                    uploadFile(file).then((fileName) => {

                        onUpdate(fileName);

                        setImages([...images, {
                            marketId: "custom",
                            imageUrl: fileName
                        }])
                    });

                    return Promise.resolve();
                }} />
            <ImageGroup size='small'>
                {images.map((x, i) =>
                    <div key={i} style={{ backgroundColor: "#36E073", display: "inline-block" }}>
                        <Image style={{ padding: "5px" }} src={getImageUrlPlain(x.marketId, x.imageUrl)} />
                    </div>)}
            </ImageGroup>

        </div >
    )
}


export default observer(CatalogueImageSelection);