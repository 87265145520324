import { observer } from "mobx-react-lite";
import React from "react";
import { Fragment, useContext, useEffect } from "react";
import {
  Button,
  Dimmer,
  Divider,
  Grid,
  Header,
  Icon,
  Loader,
  Message,
  Segment,
  Table,
  Image,
  Menu,
  Dropdown,
  DropdownProps,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { OpenAssociationModal } from "../associations/functions/AssociationHelpers";
import { currencyFormat } from "../products/functions/productHelper";
import { requestUserSession } from "../registration/functions/sessionFnc";
import { WizardProductTableRow } from "./WizardProductTableRow";
import Highlighter from "react-highlight-words";
import WizardProductCard from "./WizardProductCard";
import Associations from "../associations/Associations";
import UnassociatedProductCount from "../products/UnassociatedProductCount";

const ProductWizard = () => {
  const context = useContext(RootStoreContext);
  const { isLoggedIn } = context.sessionStore;
  const { load, dispose, loading, item, addToAssociation, setMarketId } =
    context.productWizardStore;
  const { openModal, closeModal } = context.modalStore;
  const { isMobile } = context.deviceStore;
  const { markets } = context.cacheStore;
  const { setProductIdToExpand } = context.productAssociationStore;

  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  useEffect(() => {
    load();

    return () => {
      dispose();
    };
  }, [dispose, load]);

  return (
    <div>
      <Menu secondary>
        <Menu.Item>
          <UnassociatedProductCount />
        </Menu.Item>
        <Menu.Item>
          <Button icon labelPosition="right" color="blue" basic>
            Reload
            <Icon
              onClick={() => load()}
              loading={loading}
              name="refresh"
              style={{ backgroundColor: "transparent" }}
            />
          </Button>
        </Menu.Item>
        <Menu.Item style={{ padding: "0px" }}>
          <Dropdown
            placeholder={"Filter stores"}
            closeOnBlur
            selection
            clearable
            search
            onChange={(ev, data: DropdownProps) => {
              setMarketId(data.value as string);
            }}
            options={markets.map((x) => ({
              key: x.type_id,
              text: x.title,
              value: x.type_id,
            }))}
          />
        </Menu.Item>
      </Menu>
      {!loading && !isLoggedIn && (
        <div>
          <div style={{ padding: "5px" }}>
            <Message
              icon="user"
              info
              header="A free account is required to access this page!"
              content={
                <div>
                  {
                    "Hi there, thank you for being here, we have already grouped many products for your convenience to see which grocery shop has the cheapest but you need to create a free account. Your account will always be free and we will not ask you for any money!"
                  }
                  <Divider hidden />
                  <div style={{ textAlign: "center" }}>
                    <Button
                      content="Sign In"
                      className="ct-green-button-active"
                      onClick={() => {
                        requestUserSession(
                          isMobile,
                          openModal,
                          closeModal,
                          () => {
                            closeModal();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      )}
      {item && (
        <Fragment>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={5}>
                {loading && (
                  <Segment>
                    <Dimmer active inverted>
                      <Loader inverted content="Loading" />
                    </Dimmer>

                    <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                  </Segment>
                )}
                {!loading && (
                  <WizardProductCard
                    product={item.product}
                    createGroup={() => {
                      OpenAssociationModal(
                        openModal,
                        [
                          ...item.items.filter((f) =>
                            selectedIds.includes(f.id)
                          ),
                          item.product,
                        ],
                        () => { },
                        (...args: []) => {
                          load();
                          setSelectedIds([]);
                        }
                      );
                    }}
                    addToGroup={() => {
                      setProductIdToExpand(item.product.id);

                      openModal(
                        <Associations mode="select" />,
                        "large",
                        "Select an association",
                        true,
                        false,
                        () => {
                          load();
                          setSelectedIds([]);
                        }
                      );
                    }}
                  />
                )}
              </Grid.Column>

              <Grid.Column width={11}>
                <Grid stackable textAlign="left">
                  <Grid.Row
                    verticalAlign="top"
                    style={{
                      maxHeight: "350px",
                      overflowY: "scroll",
                    }}
                  >
                    <Grid.Column>
                      {loading && (
                        <Segment>
                          <Dimmer active inverted>
                            <Loader inverted content="Loading" />
                          </Dimmer>

                          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                        </Segment>
                      )}
                      {!loading && (
                        <Segment>
                          {item.items && item.items.length > 0 ? (
                            <Table basic="very" celled>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell colSpan={7}>
                                    <b>Products</b>
                                  </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.HeaderCell>Product</Table.HeaderCell>
                                  <Table.HeaderCell>
                                    Internal Id
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>Store</Table.HeaderCell>
                                  <Table.HeaderCell>
                                    Last Update
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>Price</Table.HeaderCell>
                                  <Table.HeaderCell>Score</Table.HeaderCell>
                                  <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {item.items
                                  .filter((f) => f.id !== item.product.id)
                                  .slice()
                                  .sort((a, b) => (a.score > b.score ? -1 : 1))
                                  .map((x) => (
                                    <WizardProductTableRow
                                      parentTitle={item.product.itemDesc}
                                      key={x.id}
                                      product={x}
                                      selected={
                                        selectedIds.filter((f) => f === x.id)
                                          .length > 0
                                      }
                                      setSelected={(id: string) => {
                                        if (
                                          selectedIds.filter((f) => f === x.id)
                                            .length > 0
                                        )
                                          setSelectedIds(
                                            selectedIds.filter((f) => f !== id)
                                          );
                                        else
                                          setSelectedIds([...selectedIds, id]);
                                      }}
                                    />
                                  ))}
                              </Table.Body>
                            </Table>
                          ) : (
                            <Header content="No similar products" />
                          )}
                        </Segment>
                      )}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row
                    verticalAlign="top"
                    style={{
                      maxHeight: "350px",
                      overflowY: "scroll",
                    }}
                  >
                    <Grid.Column>
                      {loading && (
                        <Segment>
                          <Dimmer active inverted>
                            <Loader inverted content="Loading" />
                          </Dimmer>

                          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                        </Segment>
                      )}
                      {!loading && (
                        <Segment>
                          {item.groups && item.groups.length > 0 ? (
                            <Table basic="very" celled>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell colSpan={3}>
                                    <b>Associations</b>
                                  </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    Group Name
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>Score</Table.HeaderCell>
                                  <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {item.groups
                                  .slice()
                                  .sort((a, b) => (a.score > b.score ? -1 : 1))
                                  .map((x) => (
                                    <Table.Row key={x.id}>
                                      <Table.Cell>
                                        <Highlighter
                                          caseSensitive={false}
                                          highlightStyle={{
                                            background: "#ffb7b7",
                                          }}
                                          searchWords={item.product.itemDesc.split(
                                            " "
                                          )}
                                          autoEscape={true}
                                          textToHighlight={x.title}
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <div
                                          style={{
                                            marginBottom: "0.5em",
                                            fontSize: "17px",
                                            fontWeight: "bolder",
                                          }}
                                        >
                                          {currencyFormat(x.score, false)}
                                        </div>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Icon
                                          circular
                                          inverted
                                          color={
                                            [
                                              ...item.items.filter((f) =>
                                                selectedIds.includes(f.id)
                                              ),
                                              item.product,
                                            ].length > 0
                                              ? "green"
                                              : "grey"
                                          }
                                          name="add"
                                          title="Add to group"
                                          onClick={() => {
                                            addToAssociation(x.id, [
                                              ...selectedIds,
                                              item.product.id,
                                            ]).then(() => {
                                              load();
                                              setSelectedIds([]);
                                            });
                                          }}
                                        />
                                        add{" "}
                                        {
                                          [...selectedIds, item.product.id]
                                            .length
                                        }{" "}
                                        products
                                      </Table.Cell>
                                    </Table.Row>
                                  ))}
                              </Table.Body>
                            </Table>
                          ) : (
                            <Header content="No matching groups" />
                          )}
                        </Segment>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Fragment>
      )}
    </div>
  );
};

export default observer(ProductWizard);
