export const getErrorDescription = (errorCode: string, response: any) => {
    switch (response.data) {
        case 3001:
            return "User does not exist or you don't have the rights to see this information.";
        case 3004:
            return "Current password is required.";
        case 1002:
            return "Email is required for registration.";
        case 1003:
            return "Password is required for registration.";
        case 1004:
            return "Password Confirm is required for registration.";
        case 1005:
            return "Passwords do not match.";
        case 1006:
        case 3005:
            return "Password does not meet complexity requirements. Password has to be at least 8 characters long and contain 1 capital letter, 1 lowercase letter, 1 number and a special character. e.g. Pass123$";
        case 1008:
            return "Terms and Conditions are mandatory for the registration.";
        case 1009:
        case 1010:
            return "Sign in failed. Kindly make sure you typed the correct email and password. In case you forgot your password try to reset it. ";
        case 3104:
            return "Concurrency Failure";
        case 3108:
        case 3106:
            return "The email already exists, kindly try to sign in.";
        case 3109:
        case 3112:
            return "The email address is invalid.";
        case 3115:
            return "Password requires at least 1 digit.";
        case 3116:
            return "Password requires at least 1 lower case letter.";
        case 3117:
            return "Password requires at least 1 special character.";
        case 3119:
            return "Password requires at least 1 upper case letter.";
        case 3120:
            return "Password is too short, at least 8 characters required.";
        case 3036:
            return "Country is missing but is required.";
        case 3037:
            return "Address Line 1 is missing but is required.";
        case 3038:
            return "Locality is missing but is required.";
        case 3039:
            return "The selected address does not exist.";
        case 5001:
            return "Update the title before you make the product available.";
        case 5002:
            return "Activate the product before you make the product available.";
        case 5003:
            return "Update the VAT before you make the product available.";
        case 5004:
            return "Update the Categories before you make the product available.";
        case 5005:
            return "Update the Compartment before you make the product available.";
        case 5006:
            return "The product is deleted hence cannot be marked available.";
        case 5007:
            return "The product has not prices hence cannot be marked available.";
        case 5008:
            return "Verify the association first before you enable the product.";
        case 5009:
            return "Item is not linked with an association";
        case 6000:
            return "You need to create a free account in order to search, compare or see more products.";
        default:
            return undefined;
        // return "Ohh we are so sorry that something went wrong; if the error persists please contact us to help you.";
    }
}