import JSZip from 'jszip';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Divider, Dropdown, DropdownProps, Grid, List, Message } from 'semantic-ui-react';

interface IProps {
    catalogueId: string
    closeModal: () => void
}

const CameraCapture: React.FC<IProps> = ({ catalogueId, closeModal }) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [images, setImages] = useState<string[]>([]);
    const [stream, setStream] = useState(null);
    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('');


    useEffect(() => {
        // Function to fetch and set available video devices
        const getDevices = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            var finalDevices: any[] = []
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            videoDevices.forEach(x => {
                if (finalDevices.filter(f => f.deviceId === x.deviceId).length > 0) return;
                finalDevices.push(x);
            })
            setDevices(finalDevices as any);
            if (videoDevices.length > 0) {
                var proposedId = videoDevices[videoDevices.length - 1].deviceId;
                setSelectedDeviceId(proposedId); // Default to the first camera
                startCamera(proposedId);
            }
        };

        navigator.mediaDevices.getUserMedia({ video: true }).then(() => getDevices());
    }, []);

    const changeCamera = async (deviceId: any) => {
        stopCamera();
        setSelectedDeviceId(deviceId);
        await startCamera(deviceId);
    }

    const startCamera = async (deviceId: any) => {
        try {
            if (!videoRef || !videoRef.current) return;

            const constraints = {
                video: {
                    deviceId: deviceId ? deviceId : undefined,
                },
            };

            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
            (videoRef.current as any).srcObject = mediaStream;
            setStream(mediaStream as any);
        } catch (error) {
            console.error('Error accessing the camera:', error);
        }
    };

    const captureImage = () => {
        if (!canvasRef || !canvasRef.current) return;

        const context = (canvasRef.current as any).getContext('2d');
        context.drawImage(videoRef.current, 0, 0, (canvasRef.current as any).width, (canvasRef.current as any).height);
        (canvasRef.current as any).toBlob((blob: any) => {
            const imageUrl = URL.createObjectURL(blob);
            setImages([imageUrl, ...images]);
        }, 'image/jpeg');
    };

    const stopCamera = () => {
        if (stream) {
            (stream as any).getTracks().forEach((track: any) => track.stop()); // Stop each track
            setStream(null); // Clear the stream from state
        }
    };

    async function urlToBlob(url: string) {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
    }

    function downloadImage(blob: any, filename = 'downloaded_image.jpg') {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    async function downloadImagesAsZip() {
        if (images.length === 0) {
            if (closeModal) closeModal()
            return;
        }

        const zip = new JSZip();

        // Convert all image URLs to blobs
        const blobPromises = images.map(url => urlToBlob(url));

        // Wait for all blobs to be fetched
        const blobs = await Promise.all(blobPromises);

        // Loop through your image blobs
        blobs.forEach((blob: any, index: any) => {
            zip.file(`${catalogueId}_${index + 1}.jpg`, blob);
        });

        // Generate the zip file and trigger a download
        zip.generateAsync({ type: "blob" }).then(function (content) {
            // Use the download function from the previous example
            downloadImage(content, `${catalogueId}.zip`);
            closeModal();
        });
    }

    // const sendImageToAPI = async (blob: any) => {
    //     const formData = new FormData();
    //     formData.append('file', blob, 'image.jpg');

    //     try {
    //         // Replace YOUR_API_ENDPOINT with your actual API endpoint
    //         const response = await fetch('YOUR_API_ENDPOINT', {
    //             method: 'POST',
    //             body: formData,
    //         });
    //         const data = await response.json();
    //         console.log(data); // Handle the response from your API
    //     } catch (error) {
    //         console.error('Error sending image to API:', error);
    //     }
    // };

    return (
        <div >
            <Grid columns={16}>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Dropdown
                            value={selectedDeviceId}
                            selection
                            onChange={(ev, data: DropdownProps) => {
                                changeCamera(data.value as string);
                            }}
                            options={devices
                                ?.slice()
                                .map((x: any) => ({
                                    key: x.deviceId,
                                    text: x.label,
                                    value: x.deviceId,
                                }))}
                        />
                        <Button style={{ marginTop: "7px", height: "80px" }} size='massive' fluid icon='camera' color={"green"} onClick={captureImage} />
                        <Button style={{ marginTop: "7px" }} size='big' fluid color={"red"} content="Save & Close" onClick={() => downloadImagesAsZip()} />
                        <Divider />
                        <List verticalAlign='middle' style={{ maxHeight: "70vh", overflow: "auto" }}>
                            {images.map(image =>
                                <List.Item key={image}>
                                    <div style={{ position: "relative" }}>
                                        <Button
                                            circular
                                            icon="remove"
                                            color="red"
                                            style={{ position: "absolute", right: "3px", top: "3px", margin: "0" }}
                                            onClick={() => {
                                                setImages(images.filter(f => f !== image));
                                            }}
                                        />
                                        <img style={{ width: "100%" }} src={image} alt="Captured" />
                                    </div>
                                </List.Item>)}
                            {images.length === 0 && <Message info content="Captured images will show up here" icon={"camera"} />}
                        </List>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <video style={{ width: "100%" }} ref={videoRef} autoPlay playsInline></video>
                        <canvas ref={canvasRef} width="640" height="480" style={{ display: 'none' }}></canvas>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div>
    );
}

export default observer(CameraCapture)