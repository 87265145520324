import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { RootStoreContext } from '../../stores/RootStoreContext';

const Mealplanner = () => {
    const context = useContext(RootStoreContext);
    const { setPage } = context.analyticsStore;

    useEffect(() => {
        setPage("HabibAi-Meal", window.location.pathname);

        document.body.style.overflow = 'hidden'; // Disable background scroll

        return () => {
            document.body.style.overflow = 'auto';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>Mealplanner</div>
    )
}

export default observer(Mealplanner);