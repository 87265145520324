import { action, makeAutoObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { DeliverySlotStatus, IDeliverySlotForList } from "../../../interfaces/delivery";
import { toast } from "react-toastify";

class DeliveriesOverview {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable includeClosed: boolean = false;
    @observable slots: IDeliverySlotForList[] = []

    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setLoading = (value: boolean) => this.loading = value;
    @action resetSlots = (value: IDeliverySlotForList[]) => this.slots = value;
    @action setSlots = (value: IDeliverySlotForList[]) => {
        var existing = this.slots.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.slots.push(...missing);
    }

    @action toggleIncludeClosed = () => {
        this.includeClosed = !this.includeClosed;
        this.slots = [];
        this.currentPage = 0;

        this.load();
    }

    @action load = async () => {
        this.loading = true;

        try {
            var response = await agent.Scheduler.Deliveries.get(this.includeClosed, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setSlots(response.Items);

        } catch (error) {
            console.log(error);
        } finally {
            this.setLoading(false);
        }
    }

    @action closePast = async () => {
        // TODO: fix the country id
        agent.Scheduler.Deliveries.close_past(129).then(() => this.reload());
    }

    @action reload = async () => {
        this.setCurrentPage(0);
        this.setHasMore(true);
        this.slots = []

        this.load();
    }

    @action update_status = async (slotId: string, newStatus: DeliverySlotStatus) => {
        agent.Scheduler.Deliveries.update_status(slotId, newStatus).then(() => {
            agent.Scheduler.Deliveries.get_one(slotId).then((newItem) => {
                const idx = this.slots.findIndex(f => f.id === slotId);
                this.slots[idx] = newItem;
            })
        })
    }

    @action refresh_slot = async (slotId: string) => {
        agent.Scheduler.Deliveries.refresh(slotId).then(() => {
            agent.Scheduler.Deliveries.get_one(slotId).then((newItem) => {
                const idx = this.slots.findIndex(f => f.id === slotId);
                this.slots[idx] = newItem;
            })
        })
    }


    @action delete_slot = async (slotId: string) => {
        agent.Scheduler.Deliveries.delete(slotId).then(() => {
            runInAction(() => {
                this.slots = this.slots.filter(f => f.id !== slotId)
            })
        }).catch(() => toast.error("There was an issue when you tried to delete this slot. Make sure there is no delivery attached to it."))
    }

    @action dispose = () => {
        this.resetSlots([]);
        this.setCurrentPage(0);
        this.setLoading(false);
    }
}


export const DeliveriesOverviewStore = new DeliveriesOverview();