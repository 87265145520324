import React from "react";
import {
  FormFieldProps,
  Form,
  TextArea,
  TextAreaProps,
} from "semantic-ui-react";
import { LoadingParagraph } from "../components/LoadingParagraph";

interface IProps extends TextAreaProps, FormFieldProps {}

const TextAreaInput: React.FC<IProps> = ({
  input,
  width,
  rows,
  placeholder,
  meta: { touched, error },
  disabled = false,
  loading = false,
}) => {
  return (
    <Form.Field error={touched && !!error} width={width} disabled={disabled}>
      {loading ? (
        <LoadingParagraph lines={5} />
      ) : (
        <TextArea rows={rows} {...input} placeholder={placeholder} />
      )}
      {/* {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )} */}
    </Form.Field>
  );
};

export default TextAreaInput;
