import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IVatSummaryItem } from "../../../interfaces/report";
import { currencyFormat } from "../../products/functions/productHelper";
import { format } from "date-fns";
import { Countries } from "../../../options/Countries";

class VatSummaryReport {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable downloading: boolean = false;
    @observable countryId?: number = undefined;
    @observable hasMore: boolean = false;
    @observable items: IVatSummaryItem[] = []
    @observable dateFrom?: number = undefined;
    @observable dateTo?: number = undefined;
    @observable frdateFrom?: number = undefined;
    @observable frdateTo?: number = undefined;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setDownloading = (value: boolean) => this.downloading = value;
    @action setDateFrom = (value?: number) => this.dateFrom = value;
    @action setDateTo = (value?: number) => this.dateTo = value;
    @action setfrDateFrom = (value?: number) => this.frdateFrom = value;
    @action setfrDateTo = (value?: number) => this.frdateTo = value;
    @action setCountryId = (value?: number) => this.countryId = value;
    @action resetItems = (value: IVatSummaryItem[]) => this.items = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setItems = (value: IVatSummaryItem[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action search = async () => {
        try {
            this.resetItems([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action load = async () => {
        try {
            if (!this.countryId) return;

            this.setLoading(true);

            var response = await agent.Reports.VatSummary.get(this.countryId, this.dateFrom, this.dateTo, this.frdateFrom, this.frdateTo, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action exportDataForCsv = async () => {
        if (!this.countryId) return;

        this.setDownloading(true);
        try {
            var csvData: any[] = [];

            var company = await agent.Company.get(this.countryId);
            // csv push dates of the report
            csvData.push([`Generated by`, company.name])
            csvData.push([`Title`, "Vat Summary Report"])
            csvData.push([`Address `, `${company.addressLine1}, ${company.addressLine2}, ${company.country}`])
            csvData.push([`VAT`, company.vatNumber])
            csvData.push([`EXO`, company.exoNumber])
            csvData.push([`BCRS`, company.bcrsNumber])

            csvData.push([])
            csvData.push([])
            csvData.push(["Filters :"])
            csvData.push(["", `Country`, Countries.filter(f => f.value === this.countryId)[0].text])
            csvData.push(["", `(Created) - Date From`, this.dateFrom ? format(this.dateFrom * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push(["", `(Created) - Date To`, this.dateTo ? format(this.dateTo * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push(["", `(Receipt) - Date From`, this.frdateFrom ? format(this.frdateFrom * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push(["", `(Receipt) - Date To`, this.frdateTo ? format(this.frdateTo * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])

            csvData.push([])
            csvData.push([])

            csvData.push(["VAT Rate", "VAT Code", "VAT Description", "Total VAT", "Sales ex. VAT", "Sales inc. VAT"]);

            this.items.slice().sort((a, b) => a.rate > b.rate ? 1 : -1).forEach((x) => {
                csvData.push([
                    `${x.rate * 100}%`,
                    x.code,
                    x.title,
                    currencyFormat(x.vat, true),
                    currencyFormat(x.net, true),
                    currencyFormat(x.total, true)
                ]);
            });

            // add totals
            csvData.push([
                "",
                "",
                "Totals :",
                currencyFormat(this.items?.reduce((a, b) => a + b.vat, 0), true),
                currencyFormat(this.items?.reduce((a, b) => a + b.net, 0), true),
                currencyFormat(this.items?.reduce((a, b) => a + b.total, 0), true),
            ])

            var today = new Date();
            var fileName = `${today.getFullYear()}_${today.getMonth()}_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
            return {
                data: csvData,
                filename: `cheap_trolley_vat_summary_${fileName}.csv`,
            };

        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownloading(false);
        }
    };

    @action dispose = () => {
        this.setCountryId(undefined);
        this.setDateFrom(undefined);
        this.setDateTo(undefined);
        this.setCurrentPage(0);
        this.setHasMore(false);
        this.setItems([]);
        this.setDownloading(false);
    }
}


export const VatSummaryReportStore = new VatSummaryReport();