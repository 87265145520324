import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Header, Table, Image, Popup, Icon, Label } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { currencyFormat } from "../../products/functions/productHelper";
import { IAssociationForGlobalIntentory } from "../../../interfaces/association";
import { getImageUrlPlain } from "../../../helpers/productImage";
import { formatRelative } from "date-fns";
import { ISuperMarket } from "../../../interfaces/supermarket";
import { IGlobalInventoryProductForList } from "../../../interfaces/product";

interface IProps {
  association: IAssociationForGlobalIntentory;
  accountId?: string;
}

const GlobalInventoryTableRow: React.FC<IProps> = ({ association, accountId }) => {
  const context = useContext(RootStoreContext);
  const { openModal } = context.modalStore;
  const { selectedMarketIds, discontinued, percentage, markets } = context.globalInventoryStore;

  const [product] = React.useState(association.products.filter(f => f.marketId === markets.filter(f => f.id === accountId)[0]?.type_id)[0])

  function hasActiveProducts(x: ISuperMarket) {
    return association.products?.filter(f => f.marketId === x.type_id && !f.discontinued).length > 0;
  }

  function getPriceToShow(x: ISuperMarket) {
    var price = association.products?.filter(f => f.marketId === x.type_id && !f.discontinued).map(z => z.currentPrice).sort((a, b) => (a < b ? -1 : 1))[0]
    if (price && price > 0) {
      return currencyFormat(price, true);
    }
    if (!discontinued) {
      return undefined;
    }

    var lastPrice = association.products?.filter(f => f.marketId === x.type_id).sort((a, b) => (a.lastUpdated < b.lastUpdated ? 1 : -1))[0]?.currentPrice
    if (lastPrice && lastPrice > 0) {
      return currencyFormat(lastPrice, true);
    }
  }

  function hasOffers(x: ISuperMarket) {
    // var items = discontinued ? association.products?.filter(f => f.marketId === x.type_id).sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1)) : association.products?.filter(f => f.marketId === x.type_id && !f.discontinued).sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1));
    // if (items.length > 1) {
    //   var tmpPrice = items[0].currentPrice;
    //   for (let index = 0; index < items.length; index++) {
    //     if (tmpPrice !== items[index].currentPrice) return true;
    //   }
    // }

    return (discontinued ? association.products?.filter(f => f.marketId === x.type_id && f.textOffer) : association.products?.filter(f => f.marketId === x.type_id && !f.discontinued && f.textOffer)).length > 0;
  }

  function hasActiveOffers(x: ISuperMarket) {
    // var items = discontinued ? association.products?.filter(f => f.marketId === x.type_id).sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1)) : association.products?.filter(f => f.marketId === x.type_id && !f.discontinued).sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1));
    // if (items.length > 1) {
    //   var tmpPrice = items[0].currentPrice;
    //   for (let index = 0; index < items.length; index++) {
    //     if (tmpPrice !== items[index].currentPrice) return true;
    //   }
    // }

    return association.products?.filter(f => f.marketId === x.type_id && !f.discontinued && f.textOffer).length > 0;
  }

  function priceDrop(x: ISuperMarket) {
    var item = association.products?.filter(f => f.marketId === x.type_id && !f.discontinued).sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1))[0]
    if (item) {
      return item.drop ?? 0;
    }

    return 0;
  }



  function getOffers(x: ISuperMarket) {
    // var items = (discontinued ? association.products?.filter(f => f.marketId === x.type_id && f.textOffer) : association.products?.filter(f => f.marketId === x.type_id && !f.discontinued && f.textOffer))
    // if (items.length === 0) {
    //   items = discontinued ? association.products?.filter(f => f.marketId === x.type_id).sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1)) : association.products?.filter(f => f.marketId === x.type_id && !f.discontinued).sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1));
    //   for (let index = 0; index < items.length; index++) {
    //     items[index].textOffer = ""
    //   }
    // }

    return (discontinued ? association.products?.filter(f => f.marketId === x.type_id && f.textOffer) : association.products?.filter(f => f.marketId === x.type_id && !f.discontinued && f.textOffer));
  }


  function getPriceDiffToShow(x: ISuperMarket, y: number) {
    if (y <= 0) return undefined;

    var price = association.products?.filter(f => f.marketId === x.type_id && !f.discontinued).map(z => z.currentPrice).sort((a, b) => (a < b ? -1 : 1))[0]
    if (price && price > 0 && y > price) {
      return percentage ? `${((price - y) / price * 100).toFixed(2)}%` : currencyFormat(price - y, true);
    }
    if (!discontinued) {
      return undefined;
    }

    var lastPrice = association.products?.filter(f => f.marketId === x.type_id).sort((a, b) => (a.lastUpdated < b.lastUpdated ? 1 : -1))[0]?.currentPrice
    if (lastPrice && lastPrice > 0 && y > lastPrice) {
      return percentage ? `${((lastPrice - y) / lastPrice * 100).toFixed(2)}%` : currencyFormat(lastPrice - y, true);
    }
  }

  function getDetailProducts() {
    if (!association.products) return [];

    if (!discontinued) {
      return association.products.filter(f => !f.discontinued).sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1));
    }

    var groups = association.products.reduce(function (rv: any, x: any) {
      (rv[x['marketId']] = rv[x['marketId']] || []).push(x);
      return rv;
    }, {});

    var retVal: IGlobalInventoryProductForList[] = []
    Object.keys(groups).forEach((key) => {
      if (groups[key].length === 1) {
        retVal.push(groups[key][0])
      } else { retVal.push(groups[key].sort((a: IGlobalInventoryProductForList, b: IGlobalInventoryProductForList) => (a.lastUpdated < b.lastUpdated ? 1 : -1))[0]); }
    });

    return retVal;
  }

  function getLastPriceUpdate(x: ISuperMarket) {
    if (!discontinued) {
      return undefined;
    }

    return association.products?.filter(f => f.marketId === x.type_id).sort((a, b) => (a.lastUpdated < b.lastUpdated ? 1 : -1))[0]?.lastUpdated
  }

  return (
    <Table.Row>
      <Table.Cell verticalAlign="middle">
        <div className="fxDisplay fxStretch fxFixed">
          {association.verified && <div style={{ margin: "auto", marginLeft: "0px", marginRight: "7px" }}>
            <Popup
              on={"hover"}
              trigger={
                <Image src='/verified.png' title="Verified" alt="Verified" />
              }
              content={"Verified"}
            />
          </div>}
          <Popup
            trigger={
              <Image
                src={association.imageUrl}
                alt="Product Logo"
                size="mini"
                style={{ objectFit: "contain", maxWidth: "80px", maxHeight: "50px", height: "50px" }}
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                }}
              />
            }
            content={
              <Image
                src={association.imageUrl}
                alt="Product Logo"
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                }}
              />
            }
          />

          <Header as="h4" style={{ padding: "0", margin: "0", paddingLeft: "7px", minWidth: "180px" }}>
            {association.title}
            <Header.Subheader className="fluid">{product?.sku}</Header.Subheader>
          </Header>
        </div>
        {/* </Header> */}
      </Table.Cell>
      {accountId &&
        <Table.Cell singleLine textAlign="center"
          warning={!!product?.textOffer}
          className={product?.discontinued ? "disabled-text" : ""}
          positive={product && !product.discontinued &&
            (association.products?.length ?? 0) > 0
            && (association.products?.filter(f => f.currentPrice < product?.currentPrice).length ?? -1) === 0}>
          <div className="fxDisplay fxDirCol">
            {(product?.drop ?? 0) > 0 &&
              <Label image size="small" color={(association.products?.filter(f => f.currentPrice < product?.currentPrice).length ?? -1) === 0 ? "green" : "brown"}>
                <img src={`${process.env.REACT_APP_CLOUDFRONT}/price_drop.png`} alt="drop" />
                {currencyFormat(product.drop ?? 0, true)}
              </Label>}
            {product && (discontinued ? currencyFormat(product?.currentPrice, true) : (!product.discontinued && currencyFormat(product?.currentPrice, true)))}
          </div>

          {product && product.textOffer &&
            <Fragment>
              <br />
              <Popup
                trigger={
                  <span className="linkable-with-decoration"><Icon fitted size="small" name="percent" /> Offer</span>
                }
                content={<Table basic='very' >
                  <Table.Header>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{product.itemDesc}</Table.Cell>
                      <Table.Cell>{product.textOffer}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>}
                on="click"
              />
            </Fragment>
          }
        </Table.Cell>}
      {
        (selectedMarketIds.length > 0 ? markets.filter(f => selectedMarketIds.includes(f.type_id)) : markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1))
          .filter(f => f.id !== accountId && !f.shutDown)
          .map(x =>
            <Popup
              key={x.id}
              on='hover'
              trigger={
                <Table.Cell
                  singleLine
                  textAlign="center"
                  title={hasActiveProducts(x) ? x.title : `${x.title} ${getLastPriceUpdate(x) ? `- last price on ${formatRelative(
                    new Date(getLastPriceUpdate(x)! * 1000),
                    new Date(),
                    {})}` : ''}`}
                  className={hasActiveProducts(x) ? "" : "disabled-text"}
                  warning={hasActiveOffers(x)}
                  negative={(association.products?.filter(f => f.marketId === x.type_id && !f.discontinued && f.currentPrice < product?.currentPrice).length ?? 0) > 0}>

                  <div className="fxDisplay fxDirCol">
                    {priceDrop(x) > 0 &&
                      <Label image size="small" color={(association.products?.filter(f => f.marketId === x.type_id && !f.discontinued && f.currentPrice < product?.currentPrice).length ?? 0) > 0 ? "red" : "brown"}>
                        <img src={`${process.env.REACT_APP_CLOUDFRONT}/price_drop.png`} alt="drop" />
                        {currencyFormat(priceDrop(x), true)}
                      </Label>}
                    {getPriceToShow(x)}
                  </div>
                  <p style={{ fontSize: '13px' }}>
                    {getPriceDiffToShow(x, product?.currentPrice)}
                  </p>
                  {hasOffers(x) === true &&
                    <Fragment>
                      <br />
                      <Popup
                        trigger={
                          <span className="linkable-with-decoration"><Icon fitted size="small" name="percent" /> Offer</span>
                        }
                        content={<Table basic='very'>
                          <Table.Header>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                          </Table.Header>
                          <Table.Body>
                            {
                              getOffers(x).map(prd =>
                                <Table.Row key={prd.id}>
                                  <Table.Cell >{prd.itemDesc}</Table.Cell>
                                  <Table.Cell>{prd.textOffer}</Table.Cell>
                                </Table.Row>)
                            }

                          </Table.Body>
                        </Table>}
                        on="click"
                      />
                    </Fragment>
                  }
                </Table.Cell>
              }
              content={hasActiveProducts(x) ? x.title : `${x.title} ${getLastPriceUpdate(x) ? `- last price on ${formatRelative(
                new Date(getLastPriceUpdate(x)! * 1000),
                new Date(),
                {}
              )}` : ''}`}
            />
          )
      }
      <Table.Cell>
        <Icon
          inverted
          name="info"
          color="blue"
          circular
          disabled={association.products === undefined || association.products.length === 0}
          onClick={() => {
            openModal(<div>
              <Table>
                <Table.Body>
                  {getDetailProducts()?.map((detProduct) =>
                    <Table.Row
                      key={detProduct.id}
                      className={detProduct.discontinued ? "disabled-text" : ""}
                      style={detProduct.marketId === (markets.filter(f => f.id === accountId)[0]?.type_id) ? { fontWeight: "bold" } : {}} warning={detProduct.marketId === (markets.filter(f => f.id === accountId)[0]?.type_id)}>
                      <Table.Cell>
                        <Image
                          style={{ objectFit: "contain" }}
                          src={getImageUrlPlain(detProduct.marketId, detProduct.imageUrl)}
                          onError={({ currentTarget }: any) => {
                            currentTarget.onerror = null;
                            currentTarget.src =
                              `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                          }}
                          alt={detProduct.itemDesc}
                          className="tracker-detail-product-image"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h4'>
                          {detProduct.itemDesc}
                          <Header.Subheader className="fluid">{detProduct?.sku}</Header.Subheader>
                          {detProduct.textOffer && <p>
                            <b style={{ color: "red" }}>Offer : {" "}</b>{detProduct.textOffer}</p>}
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        {markets.filter((f) => f.type_id === detProduct.marketId)[0].title}
                      </Table.Cell>
                      <Table.Cell>
                        {currencyFormat(detProduct.currentPrice, true)}
                      </Table.Cell>
                      <Table.Cell>
                        {detProduct.lastUpdated && (
                          <p>
                            {formatRelative(
                              new Date(detProduct.lastUpdated * 1000),
                              new Date(),
                              {}
                            )}
                          </p>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </div>, "large", "Price Comparison", true, true)
          }}
        />
      </Table.Cell>
    </Table.Row >
  );
};

export default observer(GlobalInventoryTableRow);
