import { observable, action, makeObservable, computed } from "mobx";
import { RootStore } from "../../../stores/RootStoreContext";
import agent from "../../../api/agent";
import { ISuperMarket } from "../../../interfaces/supermarket";

export class AccountPageStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable activeItem: string = "";
    @observable activeModules: string[] = [];
    @observable fullScreen: boolean = false;
    @observable selectedAccountId: string = "";
    @observable account_markets: ISuperMarket[] = []

    @action setMarkets = (value: ISuperMarket[]) => { this.account_markets = value };

    @action setActiveModules = (value: string[]) => {
        this.activeModules = value;
    }

    @action setSelectedAccountId = (value: string) => {
        this.selectedAccountId = value;
    };

    @action getActiveModules = async (accountId: string) => {
        agent.Shop.Billing.Modules.active(accountId).then((modules: string[]) => this.setActiveModules(modules));
    }

    @action toggleFullScreen = () => {
        this.fullScreen = !this.fullScreen;
    };

    @action dispose = () => {
        this.activeItem = "";
        this.fullScreen = false;
    };

    @action setActiveItem = (value: string) => {
        this.activeItem = value;
    };

    @action isMenuItemDisabled = (codes: string[]) => {
        return this.activeModules.filter(f => codes.includes(f)).length === 0;
    }

    @computed get isTrackerDisabled() {
        const { isOwner } = this.rootStore.userStore;
        return this.activeModules.filter(f => ['tracker_free_trial', 'tracker_paid'].includes(f)).length === 0 && !isOwner;
    }

    @computed get isBranchesEnabled() {
        const { isOwner } = this.rootStore.userStore;
        return this.activeModules.filter(f => ['branch_management'].includes(f)).length > 0 || isOwner;
    }

    @computed get isDeliveryEnabled() {
        return this.activeModules.filter(f => ['delivery_partner'].includes(f)).length > 0;
    }

    @action oneTimeLoad = () => {
        const { isAdmin } = this.rootStore.userStore;
        if (!isAdmin)
            agent.Supermarkets.getForAccount()
                .then((markets) => this.setMarkets(markets));
    }

}
