import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Icon, Header } from "semantic-ui-react";

interface IProps {
  setFiles: (files: object[]) => void;
}

const PhotoWidgetDropzone: React.FC<IProps> = ({ setFiles }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file: object) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file as any),
          })
        )
      );
    },
    [setFiles]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={`photoWidgetDropzone fxDirCol fxDisplay fxJustifyCenter ${
        isDragActive ? "active" : ""
      }`}
    >
      <input {...getInputProps()} />
      <Icon name="upload" fitted size="massive" />
      <Header content="Drop image here" />
    </div>
  );
};

export default PhotoWidgetDropzone;
