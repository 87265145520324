import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import {
  combineValidators,
  isRequired,
  composeValidators,
  matchesField,
} from "revalidate";
import { FORM_ERROR } from "final-form";
import ProfileFieldUpdate from "./ProfileFieldUpdate";
import { Icon } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import agent from "../../../api/agent";

const PasswordEditor = () => {
  const context = useContext(RootStoreContext);
  const {
    profile,
    loading,
    currentlyEditing,
    setCurrentlyEditing,
    updatePassword,
  } = context.profileStore;
  const { user, isAdmin } = context.userStore;
  const { isMobile } = context.deviceStore;

  let passwordPlaceholder = [];
  for (let i = 0; i < 8; ++i) {
    passwordPlaceholder.push(<Icon name="circle" key={i} size="tiny"></Icon>);
  }

  return (
    <ProfileFieldUpdate
      fullWidthOnEdit={isMobile}
      loading={loading}
      allowEdit={profile?.id === user?.id || isAdmin}
      groupFieldLabel={"Password"}
      editMode={currentlyEditing === "Password"}
      fieldName="Password"
      editClicked={(value: string) => {
        if (isAdmin && profile) {
          agent.Users.Manage.request_password_reset(profile!.id).then((url) => alert(url));
        } else {
          setCurrentlyEditing(value);
        }
      }}
      cancelClicked={() => {
        setCurrentlyEditing("");
      }}
      update={(changePassword: any) =>
        updatePassword(changePassword).catch((error) => ({
          [FORM_ERROR]: error,
        }))
      }
      validate={combineValidators({
        currentPassword: isRequired("Password"),
        newPassword: isRequired("Password"),
        confirmNewPassword: composeValidators(
          isRequired("Confirm Password"),
          matchesField("newPassword", "Password does not match")
        )("Is empty or password does not match"),
      })}
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      }}
      displayValue={passwordPlaceholder}
      fields={[
        {
          display: "Current Password",
          placeholder: "Current Password",
          name: "currentPassword",
          showDisplay: true,
          type: "password",
        },
        {
          display: "New Password",
          placeholder: "New Password",
          name: "newPassword",
          showDisplay: true,
          type: "password",
        },
        {
          display: "Confirm New Password",
          placeholder: "Confirm New Password",
          name: "confirmNewPassword",
          showDisplay: true,
          type: "password",
        },
      ]}
    />
  );
};

export default observer(PasswordEditor);
