import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { LabelStat } from "../../../interfaces/labelStats";

export class LabelStatStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable external: boolean = false;
    @observable reviewed: boolean = false;
    @observable count: number = 0;
    @observable label?: LabelStat;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setReviewed = (value: boolean) => this.reviewed = value;
    @action setCount = (value: number) => this.count = value;
    @action setLabel = (value?: LabelStat) => this.label = value;


    @action loadNext = async (editLabel?: LabelStat) => {
        try {
            if (editLabel) {
                this.external = true;
                this.setLabel(editLabel);
                return;
            }

            this.setLoading(true);

            agent.Labels.count().then((count) => this.setCount(count))

            var response = await agent.Labels.next(this.reviewed);

            this.setLabel(response);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action save = async (updLabel: any) => {
        if (!this.label) return;

        await agent.Labels.update(this.label.id, updLabel)

        if (this.external) {
            this.rootStore.modalStore.closeModal();
        }

        return this.loadNext();
    }


    @action dispose = () => {
        this.external = false;
        this.setLoading(false);
        this.setLabel(undefined);
        this.setReviewed(false);

    }
}