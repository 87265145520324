import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form } from "semantic-ui-react";
import { Guid } from "../helpers/guid";

interface IProps extends FieldRenderProps<any, any>, FormFieldProps {}

const CheckBox: React.FC<IProps> = ({
  input,
  label,
  width,
  type,
  meta: { touched, error },
  style,
  toggle,
  disabled,
  onChange,
  overId = Guid.newGuid(),
  fieldStyle,
}) => {
  return (
    <Form.Field
      error={touched && !!error}
      type={type}
      width={width}
      style={fieldStyle}
    >
      <div
        className={`ui checkbox ${toggle === true ? "fitted toggle" : ""} ${
          disabled ? "disabled " : ""
        }`}
        style={style}
      >
        {disabled ? (
          <input id={`${overId}`} type="checkbox" {...input} disabled />
        ) : (
          <input
            id={`${overId}`}
            type="checkbox"
            {...input}
            onClick={onChange}
          />
        )}
        <label style={{ textAlign: "start" }} htmlFor={`${overId}`}>
          {label}
        </label>
      </div>
      {/* {touched && error && (
                <Label basic color='red'>
                    {error}
                </Label>
            )} */}
    </Form.Field>
  );
};

export default CheckBox;
