import { format, formatRelative } from "date-fns";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router";
import {
  Button,
  Dimmer,
  Icon,
  Input,
  Label,
  List,
  Loader,
  Menu,
  Segment,
} from "semantic-ui-react";
import { ConstractFullName } from "../../helpers/textHelper";
import { RootStoreContext } from "../../stores/RootStoreContext";
import UsersCount from "./UsersCount";
import React from "react";
import { ButtonTypes } from "../confirmationModal/functions/ButtonTypes";

const Users = () => {
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { isOwner } = context.userStore;
  const { openConfirmationModal, closeConfirmationalModal } = context.confirmationModalStore;
  const { load,
    dispose,
    loading,
    users,
    hasMore,
    lockUser,
    unlockUser,
    search,
    admin,
    lockedOnly,
    owner,
    toggleAdmin,
    toggleLocked,
    toggleOwner,
    deleteUserPermanately
  } = context.userListStore;
  const { isMobile } = context.deviceStore;

  const [timer, setTimer] = React.useState(0);

  useEffect(() => {
    load();

    return () => {
      dispose();
    };
  }, [load, dispose]);

  return (
    <div className="simply-paddedPage">
      <Menu
        secondary
        size={isMobile ? "massive" : "small"}
        style={{ margin: "5px 0" }}
      >
        <Menu.Item
          className={!isMobile ? "no-padding" : ""}
          style={{ flex: isMobile ? 1 : "" }}
        >
          <Input
            fluid={isMobile}
            // disabled={loading || !search}
            transparent={isMobile}
            size={isMobile ? "large" : "small"}
            icon={
              !isMobile ? <Icon name="search" className="ct-green-text" /> : undefined
            }
            placeholder={"Search..."}
            input={
              <input className={isMobile ? "" : "bluePlaceholder"} />
            }
            onChange={(event: ChangeEvent, data: any) => {
              if (!search) return;
              if (timer != null) {
                clearTimeout(timer);
                setTimer(0);
              }

              setTimer(
                setTimeout(
                  (callback: (text: string) => void, text: string) => {
                    callback(text);
                    clearTimeout(timer);
                  },
                  500,
                  search,
                  data.value
                )
              );
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <UsersCount />
        </Menu.Item>
        <Menu.Item className={!isMobile ? "no-padding" : ""}
          style={{ flex: isMobile ? 1 : "" }}>
          <Button
            color={!lockedOnly ? "blue" : "green"}
            basic={!lockedOnly}
            content={"Filter Locked"}
            onClick={() => {
              toggleLocked();
            }}
          />
        </Menu.Item>
        <Menu.Item className={!isMobile ? "no-padding" : ""}
          style={{ flex: isMobile ? 1 : "" }}>
          <Button
            color={!admin ? "blue" : "green"}
            basic={!admin}
            content={"Filter Admins"}
            onClick={() => {
              toggleAdmin();
            }}
          />
        </Menu.Item>
        <Menu.Item className={!isMobile ? "no-padding" : ""}
          style={{ flex: isMobile ? 1 : "" }}>
          <Button
            color={!owner ? "blue" : "green"}
            basic={!owner}
            content={"Filter Owners"}
            onClick={() => {
              toggleOwner();
            }}
          />
        </Menu.Item>
      </Menu>

      {!loading || users.length > 0 ? (
        <div>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={users.length} //This is important field to render the next data
            next={() => load()}
            hasMore={hasMore}
            loader={loading ? <h4>Loading...</h4> : <div />}
          >
            <List
              divided
              relaxed
              selection
              style={isMobile ? { padding: "7px" } : undefined}
            >
              {users.map((x) => (
                <List.Item key={x.id} onDoubleClick={() => navigate(`/manage-user/${x.id}`)}>
                  <List.Content floated="right">
                    {isOwner && <Button onClick={() => openConfirmationModal(
                      "Confirm user deletion - Action non reversable",
                      "Are you sure you want to delete this user?",
                      () =>
                        deleteUserPermanately(x.id).then(() =>
                          closeConfirmationalModal()
                        ),
                      "Cancel",
                      "Delete",
                      ButtonTypes.None,
                      ButtonTypes.Negative
                    )} color="red">
                      Delete User
                    </Button>}
                    {x.lockoutEnd !== null ? (
                      <Button onClick={() => unlockUser(x.id)} color="green">
                        UnLock User
                      </Button>
                    ) : (
                      <Button onClick={() => lockUser(x.id)} color="red">
                        Lock User
                      </Button>
                    )}
                  </List.Content>
                  <List.Icon
                    name="user circle outline"
                    size="large"
                    verticalAlign="middle"
                  />
                  <List.Content>
                    <List.Header>
                      {ConstractFullName(x.firstName, x.lastName, x.middleName)}
                      {x.isAdmin && <Label color="blue" content="Admin" />}
                      {x.isOwner && <Label color="green" content="Owner" />}
                      {x.lockoutEnd && (
                        <Label
                          color="red"
                          basic
                          content={`LockedOut : ${format(
                            new Date(x.lockoutEnd),
                            "dd/MM/yyyy HH:mm"
                          )}`}
                        />
                      )}
                    </List.Header>
                    {x.registeredOn && (
                      <List.Description>
                        <p>
                          <span>
                            <b>Registerd On :</b>{" "}
                            {formatRelative(
                              new Date(x.registeredOn * 1000),
                              new Date()
                            )}
                          </span>
                          <span
                            style={{
                              paddingLeft: "3px",
                              color: `${x.emailConfirmed ? "green" : "red"}`,
                            }}
                          >
                            {x.email}
                          </span>
                          {` - [${x.id}]`}
                        </p>
                      </List.Description>
                    )}
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </InfiniteScroll>
        </div>
      ) : (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      )
      }
    </div >
  );
};

export default observer(Users);
