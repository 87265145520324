import { observer } from "mobx-react-lite";
import React from "react";
// import { format } from "date-fns";
// import { Divider, Icon, Label, Table } from "semantic-ui-react";
// import { RootStoreContext } from "../../../stores/RootStoreContext";
// import agent from "../../../api/agent";
// import { saveByteArray } from "../../../helpers/urlImageHelper";
// import {
//   PaymentTransactionType,
//   PaymentTransactionTypeOptions,
// } from "../../../options/PaymentTransactionType";
// import { IPaymentTransactions } from "../../../interfaces/billing";
// import {
//   PaymentTransactionStatus,
//   PaymentTransactionStatusOptions,
// } from "../../../options/PaymentTransactionStatus";
// import InfiniteScroll from "react-infinite-scroll-component";
// import TransactionTableRow from "../Components/TransactionTableRow";

interface IProps {
  shopId?: string;
}

const BillingTransactionWidget: React.FC<IProps> = ({ shopId }) => {
  // const context = useContext(RootStoreContext);
  // const { isMobile } = context.deviceStore;
  // const {
  //   loadData,
  //   dispose,
  //   loading,
  //   searchData,
  //   searching,
  //   hasMore,
  //   setStartDate,
  //   setEndDate,
  //   setStatuses,
  //   setTypes,
  //   startDate,
  //   endDate,
  //   statuses,
  //   types,
  //   items,
  //   itemsFromSearch,
  //   setDefaultStatus,
  // } = context.billingTransactionsStore;

  // useEffect(() => {
  //   setDefaultStatus();
  //   loadData(shopId);
  //   return () => {
  //     dispose();
  //   };
  // }, [loadData, setDefaultStatus, dispose, shopId]);

  return (<div />
    // <InfiniteScroll
    //   style={{ overflow: "hidden" }}
    //   dataLength={items.length} //This is important field to render the next data
    //   next={() => loadData()}
    //   hasMore={hasMore}
    //   loader={loading ? <h4>Loading...</h4> : <div />}
    //   endMessage={
    //     <p style={{ textAlign: "center" }}>
    //       {items.length > 0 ? (
    //         <b></b>
    //       ) : (
    //         <NoResults/>
    //       )}
    //     </p>
    //   }
    // >
    //   <Table basic="very" celled>
    //     <Table.Header>
    //       <Table.Row>
    //         <Table.HeaderCell>Transaction Id</Table.HeaderCell>
    //         <Table.HeaderCell>Amount</Table.HeaderCell>
    //         <Table.HeaderCell>Status</Table.HeaderCell>
    //         <Table.HeaderCell>Type</Table.HeaderCell>
    //         <Table.HeaderCell>Date</Table.HeaderCell>
    //         <Table.HeaderCell>Invoice Number</Table.HeaderCell>
    //         <Table.HeaderCell></Table.HeaderCell>
    //       </Table.Row>
    //     </Table.Header>

    //     <Table.Body>
    //       {items.map((x) => (
    //         <TransactionTableRow item={x} key={x.id} />
    //       ))}
    //     </Table.Body>
    //   </Table>
    // </InfiniteScroll>
  );
};

export default observer(BillingTransactionWidget);
