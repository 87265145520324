import React, { ChangeEvent, Fragment } from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Input, Icon, Label } from "semantic-ui-react";
import { LoadingParagraph } from "../components/LoadingParagraph";

interface IProps extends FieldRenderProps<string, any>, FormFieldProps {
  piggyback: "left" | "right" | undefined;
}

const TextInput: React.FC<IProps> = ({
  input,
  width,
  type,
  placeholder,
  icon,
  className,
  disabled,
  hidden,
  fluid,
  meta: { touched, error },
  inputLabel,
  labelPosition,
  piggyback,
  loading = false,
  allowShowError = false,
  onIconClick,
  autoComplete,
  onChange
}) => {
  return (
    <Form.Field error={touched && !!error} type={type} width={width}>
      {loading ? (
        <LoadingParagraph lines={2} />
      ) : (
        <Fragment>
          <Input
            disabled={disabled}
            {...input}
            onChange={(event: ChangeEvent, data: any) => {
              input.onChange(event);

              if (onChange)
                onChange(data.value)

            }}
            labelPosition={labelPosition}
            placeholder={placeholder}
            autoComplete={autoComplete}
            icon={
              <Icon
                className={className}
                name={icon}
                onClick={onIconClick}
              ></Icon>
            }
            fluid={fluid}
            className={`${hidden ? "custom-hidden" : ""} ${piggyback ? "piggyback " + piggyback : ""
              }`}
            label={inputLabel}
          />
          {allowShowError && touched && error && (
            <Label color="red" basic pointing>
              {error}
            </Label>
          )}
        </Fragment>
      )}
    </Form.Field>
  );
};

export default TextInput;
