import { observer } from "mobx-react-lite";
import React from "react";
import { List } from "semantic-ui-react";

const Disclaimer = () => {
  return (
    <div className="paddedPage documents">
      <h2>Acceptance of Terms</h2>
      <p>
        By accessing and utilizing the Cheap Trolley website and/or application, you acknowledge and agree to abide by the terms outlined in this disclaimer. Should any portion of these terms be unacceptable to you, we kindly request that you discontinue your use of our website and/or application.
      </p>
      <p>
        This disclaimer represents the complete and final agreement between the user and Cheap Trolley in relation to the use of this website and/or application. We may adjust this disclaimer from time-to-time. Please keep up to date with this page regularly to ensure you are familiar with the latest version.
      </p>
      <h2>Jurisdiction and Agreement</h2>
      <p>
        Our website and/or application operates under Maltese law, and any disputes arising from its use fall under the exclusive jurisdiction of the Maltese courts. This disclaimer serves as the definitive agreement between you, the user, and Cheap Trolley regarding the utilization of our website and/or application.
      </p>
      <h2>Warranty and Disclaimer</h2>
      <p>
        Cheap Trolley provides no warranty and expressly disclaims any obligation, including but not limited to:
        <List bulleted>
          <List.Item>
            Ensuring that the Website/Application meets your requirements or remains available on an uninterrupted, timely, secure, or error-free basis.
          </List.Item>
          <List.Item>
            Guaranteeing that the content will be up-to-date, complete, comprehensive, accurate, or applicable to your specific circumstances.
          </List.Item>
          <List.Item>
            Assuring that the results obtained from the use of the Website/Application, or any services offered through the site will be accurate or reliable.
          </List.Item>
          <List.Item>
            Warranting the quality of any products, services, information, or other material obtained by you through the Website/Application to meet your expectations.
          </List.Item>
        </List>
      </p>

      <p>
        By accessing our website, you agree to:
        <List bulleted>
          <List.Item>
            Use the website for personal use, including viewing, downloading for caching purposes, and printing pages, under the condition that you do not republish any material from Cheap Trolley website, whether private or public, unless granted permission by Cheap Trolley. For permissions, contact us via email at  <a target="_blank" rel="noreferrer" href="mailto:info@cheaptrolley.com">
              info@cheaptrolley.com
            </a>.
          </List.Item>
          <List.Item>
            must not reproduce, duplicate, copy, sell, resell, visit, or
            otherwise exploit our website or material on our website for a
            commercial purpose, without our written consent.
          </List.Item>
        </List>
      </p>
      <h2>Website information</h2>
      <p>
        <List bulleted>
          <List.Item>
            Our website/application provides an impartial comparison of prices and
            features to help you make an informed decision.
          </List.Item>
          <List.Item>
            Any reliance on the information provided on this website is at your own risk, and we shall not be liable for any damages arising from such reliance.
          </List.Item>
          <List.Item>
            This website may contain links or references to third-party websites that are not under our control. We are not responsible for the content, accuracy, or privacy practices of these websites.
          </List.Item>
          <List.Item>
            The information on our website is intended for general informational
            purposes only. It is not a substitute for professional advice. Please
            consult a qualified expert before making any purchase
          </List.Item>
          <List.Item>
            The information on our website is intended for general informational purposes only. It is not a substitute for professional advice. Please consult a qualified expert before making any purchase.
          </List.Item>
          <List.Item>
            By using this website/application, you agree to indemnify and hold us harmless from any claims, damages, or losses arising from your use of this website or any breach of these terms and conditions.
          </List.Item>
        </List>
      </p>
      <h2>Availability, Errors, and Inaccuracies</h2>
      <p>
        <List bulleted>
          <List.Item>
            Cheap Trolley is always striving to provide accurate information to the user, but prices are constantly changing, so there is no guarantee that you are seeing the latest pricing. One can find the date of the last price update from the relevant supermarket/store written under each product, to provide the user the visibility of the last price update.
          </List.Item>
          <List.Item>
            Whenever pricing is shared on social media posts, a time stamp of when the data was collected is mentioned by Cheap Trolley. The data collected at a particular time by Cheap Trolley is taken from sources which are made available by the stores themselves.
          </List.Item>
          <List.Item>
            The prices listed on our website are subject to change without prior notice. We strive to keep our information as up to date as possible.
          </List.Item>
          <List.Item>
            Any offers that are available at the stores, which are not available online, are not reflected on Cheap Trolley.
          </List.Item>
          <List.Item>
            The grouped products may have inaccuracies as the grouping is done by an AI algorithm that is not infallible. As a company, we strive to keep the data up to date and in case you find a mistake, we would appreciate if you can raise it with us via Chat, or via email at <a target="_blank" rel="noreferrer" href="mailto:info@cheaptrolley.com">info@cheaptrolley.com</a>
          </List.Item>
          <List.Item>
            We do not guarantee that the prices listed on our website are the lowest available. We recommend that you compare prices from multiple sources.
          </List.Item>
          <List.Item>
            The material or information provided on this Website should not be solely relied upon for making business, legal, or any other decisions. Although we strive to maintain the information up to date and accurate, we do not make any representations or warranties, express or implied, regarding the completeness, accuracy, reliability, or availability of the Website or the information, products, services, or related graphics contained therein for any purpose. Any reliance placed on such material is done at your own risk.
          </List.Item>
        </List>
      </p>
      <p>This document was last updated on March 23, 2024</p>
    </div>
  );
};

export default observer(Disclaimer);
