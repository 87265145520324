import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect } from 'react'
import { Segment, Header, Divider, Dimmer, Loader, Icon, Button } from 'semantic-ui-react'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { HubOrderStatus } from '../../../options/HubOrderProductStatus';
import { currencyFormat } from '../../products/functions/productHelper';
import { useNavigate } from 'react-router';

const ActiveHubOrders = () => {
    const context = useContext(RootStoreContext);
    const navigate = useNavigate();

    const {
        hubOrders,
        loadingActiveHubOrders,
        loadActiveHubOrders,
        locations,
    } = context.hubOrdersStore;
    const { markets } = context.cacheStore;

    useEffect(() => {
        loadActiveHubOrders();

        return () => { };
    }, [loadActiveHubOrders]);

    return (
        <Segment basic>
            <div style={{ textAlign: "center" }}>
                <Header textAlign="center" color="blue">
                    <div style={{ position: "absolute", right: "0", top: "15px" }}>
                        <Icon
                            onClick={loadActiveHubOrders}
                            className='clickable'
                            title="Share order"
                            name={loadingActiveHubOrders ? 'circle notch' : 'refresh'}
                            loading={loadingActiveHubOrders}
                            color='blue'
                            size='large'
                        />
                    </div>
                    Active Hub Orders
                    {hubOrders && <Header.Subheader>
                        {`${hubOrders.length} order(s) - ${hubOrders.slice().reduce((c, d) => c + d.quantity, 0)} product(s)`}
                    </Header.Subheader>}
                </Header>

            </div>
            <Divider />
            {loadingActiveHubOrders && (
                <Dimmer active inverted>
                    <Loader inverted content="Loading" />
                </Dimmer>
            )}

            <div style={{ maxHeight: "78vh", overflow: "auto" }}>
                {hubOrders && markets.length > 0 && locations.length > 0 &&
                    hubOrders.slice().sort((a, b) => HubOrderStatus.filter(f => f.value === a.status)[0].order < HubOrderStatus.filter(f => f.value === b.status)[0].order ? 1 : -1).map((x) => (
                        <Segment key={x.locationId} color={HubOrderStatus.filter(f => f.value === x.status)[0].color}>
                            <div className="fxDisplay fxFixed fxJustifyBetween">
                                <div style={{ width: "80px", maxWidth: "80px" }} className='fxAlignCenter'>
                                    <b>{markets.filter(f => f.id === x.accountId)[0].title}</b>
                                    <p>{locations.filter(f => f.id === x.locationId)[0].title}</p>
                                </div>
                                <div className='fxTextAlignCenter'>
                                    <b>{`${x.orderIds.length}`}</b>
                                    <p>order(s)</p>
                                </div>
                                <div className='fxTextAlignCenter'>
                                    <b>{`${x.quantity}`}</b>
                                    <p>product(s)</p>
                                </div>
                            </div>
                            <Divider style={{ marginTop: "4px", marginBottom: "4px" }} />
                            <div className="fxDisplay fxFixed fxJustifyBetween">
                                <b>{x.displayId}</b>
                                <b>{currencyFormat(x.total_net + x.total_vat, true)}</b>
                                <div><Icon name="circle" color={HubOrderStatus.filter(f => f.value === x.status)[0].color} /> {HubOrderStatus.filter(f => f.value === x.status)[0].text}</div>
                            </div>
                            {x.boxes !== undefined &&
                                <Fragment>
                                    <Divider style={{ marginTop: "4px", marginBottom: "4px" }} />
                                    <div className="fxDisplay fxFixed fxJustifyEven">
                                        {(x.boxes.dry ?? 0) > 0 && <div><Icon name="box" /> {x.boxes.dry}</div>}
                                        {(x.boxes.chill ?? 0) > 0 && <div><Icon name="snowflake outline" color='green' />{x.boxes.chill}</div>}
                                        {(x.boxes.frozen ?? 0) > 0 && <div><Icon name="snowflake outline" color='blue' />{x.boxes.frozen}</div>}
                                    </div>
                                </Fragment>}
                            {/* "sent_to_market" | "received" | "in_process" | "ready_for_delivery" | "seen" | */}

                            <Divider style={{ marginTop: "4px", marginBottom: "4px" }} />
                            <div className="fxDisplay fxFixed fxJustifyEven">
                                <Button
                                    basic
                                    icon={x.status === "ready_for_delivery" ? "clipboard check" : "wpforms"}
                                    color={x.status === "ready_for_delivery" ? 'green' : "blue"}
                                    content={x.status === "ready_for_delivery" ? "Verification" : "Open"} onClick={() => navigate(`${x.id}/verify`)} />
                            </div>
                        </Segment>
                    ))}
            </div>
        </Segment>
    )
}


export default observer(ActiveHubOrders)