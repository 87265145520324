import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Icon, List } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { IProductListForListDto } from "../../../interfaces/productLists";
import { formatRelative } from "date-fns";
import { ButtonTypes } from "../../confirmationModal/functions/ButtonTypes";
import CreateUpdateProductList from "./CreateUpdateProductList";

interface IProps {
  item: IProductListForListDto;
}

const ProductListItem: React.FC<IProps> = ({ item }) => {
  const context = useContext(RootStoreContext);
  const { openModal } = context.modalStore;
  const { deleteList, setSelectedProductList } = context.productListStore;
  const { openConfirmationModal, closeConfirmationalModal } =
    context.confirmationModalStore;

  return (
    <List.Item onDoubleClick={() => setSelectedProductList(item)}>
      <List.Content floated="right">
        <Icon
          name="trash"
          circular
          color="red"
          inverted
          onClick={() => {
            openConfirmationModal(
              "Confirm product list deletion",
              "Are you sure you want to delete this product list? This action cannot be reverted once done.",
              () =>
                deleteList(item.id).then(() =>
                  closeConfirmationalModal()
                ),
              "Cancel",
              "Delete",
              ButtonTypes.None,
              ButtonTypes.Negative
            );
          }}
        />
        <Icon
          name="pencil"
          circular
          color="blue"
          inverted
          onClick={() => {
            openModal(
              <CreateUpdateProductList itemId={item.id} />,
              "small",
              `Edit : ${item.title}`,
              true,
              false
            );
          }}
        />
        <Icon
          name="play"
          circular
          color="green"
          inverted
          onClick={() => {
            setSelectedProductList(item);
          }}
        />
      </List.Content>
      <List.Content>
        <List.Header>
          {item.title}
        </List.Header>
        <List.Description style={{ marginTop: "3px" }}>
          {item.skus} sku(s) - {formatRelative(
            new Date(item.updatedOn * 1000),
            new Date()
          )}
        </List.Description>
      </List.Content>
    </List.Item>
  );
};

export default observer(ProductListItem);
