import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { LastSyncItem } from '../../../interfaces/lastSync'
import { Header, Table, Image, Icon, Button, Label } from 'semantic-ui-react'
import { getMarketImageUrl } from '../../../helpers/supermarketImage'
import { ISuperMarket } from '../../../interfaces/supermarket'
import { currencyFormat } from '../../products/functions/productHelper'
import { getImageUrlPlain } from '../../../helpers/productImage'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import { CSSTransition } from "react-transition-group";

interface IProps {
    item: LastSyncItem;
    markets: ISuperMarket[];
}

const LastSyncTableRow: React.FC<IProps> = ({ item, markets }) => {
    const context = useContext(RootStoreContext)
    const { deletingId, deleteItem } = context.lastSyncStore;

    const [expanded, setexpanded] = React.useState(false);
    return (
        <Table.Row>
            <Table.Cell>
                {(item.others?.filter(f => f.id !== item.productId).length ?? 0) > 0 && <Button
                    basic
                    disabled={deletingId === item.id}
                    color="blue"
                    icon={expanded ? "minus" : "plus"}
                    size="tiny"
                    onClick={() => setexpanded(!expanded)}
                />}
                <Header as="h4" image style={{ marginTop: "0px" }}>
                    <Image
                        alt="Product Logo"
                        src={
                            getImageUrlPlain(item.marketId, item.imageUrl) ??
                            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                        }
                        onError={({ currentTarget }: any) => {
                            currentTarget.onerror = null;
                            currentTarget.src =
                                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                        }}
                        className="fxFixed"
                        style={{
                            maxWidth: "300px",
                            maxHeight: "300px",
                            alignSelf: "center",
                            objectFit: "contain",
                        }}
                    />
                    <Header.Content>
                        {item.itemDesc}
                    </Header.Content>
                </Header>
                <CSSTransition
                    unmountOnExit
                    in={expanded}
                    timeout={300}
                    classNames="fxDisplay fxDirCol expansion "
                >
                    <Table basic="very" celled>
                        <Table.Body>
                            {item.others?.filter(f => f.id !== item.productId)
                                ?.slice()
                                .sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1))
                                .map((x) => (
                                    <Table.Row key={x.id}>
                                        <Table.Cell >{x.itemDesc}</Table.Cell>
                                        <Table.Cell >{markets.filter((f) => f.type_id === x.marketId)[0].title}</Table.Cell>
                                        <Table.Cell>{currencyFormat(x.currentPrice, true)}</Table.Cell>
                                        <Table.Cell>{currencyFormat(item.currentPrice - x.currentPrice, true)}</Table.Cell>
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                </CSSTransition>
            </Table.Cell>
            <Table.Cell>
                <Header as="h4" image>
                    <Image
                        alt="Market Logo"
                        src={getMarketImageUrl(
                            markets.filter((f) => f.type_id === item.marketId)[0]
                        )}
                        rounded
                        size="mini"
                    />
                    <Header.Content>
                        {markets.filter((f) => f.type_id === item.marketId)[0].title}
                    </Header.Content>
                </Header>
            </Table.Cell>
            <Table.Cell>
                {item.type === "offer" ? currencyFormat(-1 * (item.drop ?? 0), true) : item.type}
            </Table.Cell>
            <Table.Cell>

                <Label color={item.associationId ? "green" : "red"} basic content={item.associationId ? "Yes" : "No"} />
            </Table.Cell>
            <Table.Cell>
                {item.discontinued && (
                    <p>
                        <Label color="red" basic content="Discontinued" />
                    </p>
                )}
            </Table.Cell>
            <Table.Cell>
                <div
                    style={{
                        marginBottom: "0.5em",
                        fontSize: "17px",
                        fontWeight: "bolder",
                    }}
                >
                    <Icon name="euro" />
                    {currencyFormat(item.currentPrice, false)}
                    {(item.others?.filter(f => f.id !== item.productId).length ?? 0) > 0 && <p style={{ fontSize: "13px", textAlign: "center" }}>
                        {currencyFormat(item.currentPrice - ((item.others
                            ?.filter(f => f.id !== item.productId)
                            ?.slice() ?? [])
                            .sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1))[0]?.currentPrice ?? 0), false)}
                    </p>}
                </div>
            </Table.Cell>
            <Table.Cell>
                <Button
                    circular
                    disabled={deletingId === item.id}
                    color="red"
                    icon="remove"
                    size="tiny"
                    onClick={() => deleteItem(item.id)}
                />
            </Table.Cell>
        </Table.Row >
    )
}

export default observer(LastSyncTableRow);