import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { Grid, Ref } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import AccountDetails from "./AccountDetails";
import BillingDetails from "./BillingDetails";
import ShopManageMenu from "./Components/ShopManageMenu";
import Inventory from "./Inventory";
import Users from "./Users";
import ShopOrders from "./Components/ShopOrders";
import PriceTracker from "./Components/PriceTracker";
import GlobalInventory from "./Components/GlobalInventory";
import LocationsManagementWidget from "./Components/LocationsManagementWidget";
import ReferralSalesWidget from "./Components/ReferralSalesWidget";
import ProductLists from "./ProductLists";

const ManageShopAccount = () => {
  const stickyRef = React.createRef<HTMLElement>();
  let { id } = useParams<"id">();

  const context = useContext(RootStoreContext);
  const { setPage } = context.analyticsStore;
  const { setSelectedAccountId, getActiveModules, activeItem, fullScreen, dispose, setActiveItem, oneTimeLoad } = context.accountPageStore;
  const { accountId, isShopAdmin, isOwner } = context.userStore;

  useEffect(() => {
    if (!id) setPage("ManageShopAccount", window.location.pathname);

    oneTimeLoad();
    setSelectedAccountId(id ?? accountId!);
    getActiveModules(id ?? accountId!);
    setActiveItem(isShopAdmin || isOwner ? "account_details" : "inventory");

    return () => { dispose() };
  }, [setPage, id, accountId, setSelectedAccountId, setActiveItem, getActiveModules, isShopAdmin, isOwner, dispose, oneTimeLoad]);

  return (
    <Ref innerRef={stickyRef}>
      <Grid>
        {!fullScreen &&
          <Grid.Column width="3">
            <ShopManageMenu stickyContext={stickyRef} />
          </Grid.Column>
        }
        <Grid.Column width={fullScreen ? '16' : "13"} stretched style={{ marginTop: "15px" }}>
          {activeItem === "account_details" && <AccountDetails shopId={id} />}
          {activeItem === "billing_details" && <BillingDetails shopId={id} />}
          {activeItem === "users" && <Users shopId={id} />}
          {activeItem === "inventory" && <Inventory shopId={id ?? accountId!} />}
          {activeItem === "orders" && <ShopOrders shopId={id} />}
          {activeItem === "price_tracker" && <PriceTracker shopId={id} />}
          {activeItem === "product_lists" && <ProductLists shopId={id ?? accountId!} />}
          {activeItem === "global_inventory" && <GlobalInventory shopId={id} />}
          {activeItem === "locations" && <LocationsManagementWidget shopId={id ?? accountId!} />}
          {activeItem === "sales" && <ReferralSalesWidget shopId={id ?? accountId!} />}
        </Grid.Column>
      </Grid>
    </Ref>
  );
};

export default observer(ManageShopAccount);
