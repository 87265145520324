import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { Icon } from 'semantic-ui-react';

interface CarouselProps {
    images: string[];
    onImageChange: (currentIndex: number) => void;
}


const ImageCarousel: React.FC<CarouselProps> = ({ images, onImageChange }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [dragging, setDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const preventDefault = (e: WheelEvent) => e.preventDefault();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        onImageChange(currentIndex);
    }, [currentIndex, onImageChange]);

    const reset = () => {
        setDragging(false);
        setStartPosition({ x: 0, y: 0 })
        setPosition({ x: 0, y: 0 })
        setZoomLevel(1);
        setRotation(0);
        containerRef?.current?.removeEventListener('wheel', preventDefault);
    }

    const goToPrevious = () => {
        reset();
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        reset();
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        setDragging(true);
        setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseUp = () => {
        setDragging(false);
        containerRef?.current?.removeEventListener('wheel', preventDefault);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (dragging) {
            setPosition({ x: e.clientX - startPosition.x, y: e.clientY - startPosition.y });
        }
    };

    const handleZoom = (deltaY: number) => {
        setZoomLevel((prevZoom) => {
            const newZoom = prevZoom - deltaY * 0.2;
            return Math.min(Math.max(newZoom, 1), 3); // limit zoom level between 1 and 3
        });
    };

    const handleWheel = (e: React.WheelEvent) => {
        if (e.ctrlKey) {
            e.preventDefault();
            handleZoom(e.deltaY);
        }
    };

    const rotateLeft = () => {
        setRotation((prevRotation) => prevRotation - 90);
    };

    const rotateRight = () => {
        setRotation((prevRotation) => prevRotation + 90);
    };

    return (
        <div className="carousel">
            <div className='fxFixed fxDisplay' style={{ position: "absolute", zIndex: 3, padding: "4px", left: 15 }}>
                <Icon size='large' color='blue' inverted name="zoom in" circular onClick={() => handleZoom(-1)} />
                <Icon size='large' color='blue' inverted name="zoom out" circular onClick={() => handleZoom(1)} />
                <Icon size='large' color='red' inverted name="undo" circular onClick={reset} />

                <Icon size='large' color='orange' inverted name="chevron circle left" circular onClick={rotateLeft} />
                <Icon size='large' color='orange' inverted name="chevron circle right" circular onClick={rotateRight} />
            </div>
            <div className='fxFixed fxDisplay' style={{ position: "absolute", zIndex: 3, padding: "4px", right: 10 }}>
                <Icon size='large' color='green' inverted name="arrow left" circular onClick={goToPrevious} />
                <Icon size='large' color='green' inverted name="arrow right" circular onClick={goToNext} />
            </div>

            <div
                className="image-container"
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onWheel={handleWheel}
                onMouseEnter={() => containerRef?.current?.addEventListener('wheel', preventDefault, { passive: false })}
            >
                <img
                    src={images[currentIndex]}
                    alt=""
                    style={{
                        transform: `scale(${zoomLevel}) translate(${position.x / zoomLevel}px, ${position.y / zoomLevel}px) rotate(${rotation}deg)`,
                        cursor: dragging ? 'grabbing' : 'grab'
                    }}
                />
            </div>

        </div >
    )
}

export default observer(ImageCarousel)