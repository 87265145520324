import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Dimmer, List, Loader, Segment } from 'semantic-ui-react';
import BulkPossibleAssociationItem from './BulkPossibleAssociationItem';

const ListViewAssociations = () => {
    const context = useContext(RootStoreContext);
    const {
        load,
        dispose,
        loading,
        hasMore,
        possAssociations
    } = context.possibleAssociationsV2Store;

    useEffect(() => {
        load();

        return () => {
            (window as any).groupForm = null;
        };
    }, [load, dispose]);

    return (
        !loading || possAssociations.length > 0 ? (
            <div>
                <InfiniteScroll
                    style={{ overflow: "hidden" }}
                    dataLength={possAssociations.length} //This is important field to render the next data
                    next={() => load()}
                    hasMore={hasMore}
                    loader={loading ? <h4>Loading...</h4> : <div />}
                >
                    <List
                        divided
                        relaxed
                        selection
                    >
                        {possAssociations.map((x) => (
                            <BulkPossibleAssociationItem key={x.id} item={x} />
                        ))}
                    </List>
                </InfiniteScroll>
            </div>
        ) : (
            <Segment>
                <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            </Segment>
        )
    )
}

export default observer(ListViewAssociations)