import React, { Fragment } from "react";
import {
  Grid,
  Header,
  Button,
  Form,
  Divider,
  Placeholder,
  SemanticWIDTHS,
} from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { observer } from "mobx-react-lite";
import { IFieldDefinition } from "../../../interfaces/user";
import DateInput from "../../../form/DateInput";
import SelectInput from "../../../form/SelectInput";
import TextInput from "../../../form/TextInput";
import ErrorMessage from "../../../form/ErrorMessage";

interface IProps {
  loading: boolean;
  editMode: boolean;
  updateEditMode?: (value: boolean) => void;
  update: (...args: any[]) => void;
  validate?: (values: object) => {};
  initialValues?: object;
  displayValue: any | null;
  fields: IFieldDefinition[];
  groupFieldLabel: string;
  editClicked?: (...args: any[]) => void;
  cancelClicked?: (...args: any[]) => void;
  allowEdit?: boolean;
  fieldName?: String;
  hideGrioupLabelOnEdit?: boolean;
  overrideColumnsCount?: SemanticWIDTHS | "equal" | undefined;
  fullWidthOnEdit?: boolean;
}

const ProfileFieldUpdate: React.FC<IProps> = ({
  loading,
  editMode,
  updateEditMode,
  update,
  validate,
  initialValues,
  displayValue,
  fields,
  groupFieldLabel,
  editClicked,
  allowEdit = true,
  cancelClicked,
  fieldName,
  hideGrioupLabelOnEdit = false,
  overrideColumnsCount = 3,
  fullWidthOnEdit,
}) => {
  return (
    <Grid.Row columns={overrideColumnsCount ?? 3} className="no-padding">
      {hideGrioupLabelOnEdit ? (
        updateEditMode && (
          <Grid.Column textAlign="left" width={4}>
            <Header as="h4">{groupFieldLabel}</Header>
          </Grid.Column>
        )
      ) : (
        <Grid.Column textAlign="left" width={4}>
          <Header as="h4">{groupFieldLabel}</Header>
        </Grid.Column>
      )}
      {!editMode && (
        <Fragment>
          <Grid.Column textAlign="left" width={9}>
            {loading ? (
              <Placeholder>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder>
            ) : (
              <div style={{ whiteSpace: "pre-wrap" }}>{displayValue}</div>
            )}
          </Grid.Column>
          <Grid.Column textAlign="right" width={3}>
            {allowEdit && (
              <Button
                onClick={() => {
                  updateEditMode && updateEditMode(true);
                  editClicked && editClicked(fieldName);
                }}
                color="blue"
                className="button-as-link ct-green-text"
                disabled={loading}
              >
                {"Edit"}
              </Button>
            )}
          </Grid.Column>
        </Fragment>
      )}
      {editMode && (
        <Grid.Column width={fullWidthOnEdit ? 16 : 12}>
          <FinalForm
            onSubmit={(values: any) => update(values)}
            validate={validate}
            initialValues={initialValues}
            render={({
              handleSubmit,
              submitError,
              submitting,
              dirtySinceLastSubmit,
              invalid,
              pristine,
            }) => (
              <Form onSubmit={handleSubmit} error>
                <Grid className="small-top-padding">
                  {fields.map((field: IFieldDefinition) => (
                    <Fragment key={field.name}>
                      <Grid.Row>
                        <Grid.Column
                          width={fullWidthOnEdit ? 16 : 12}
                          verticalAlign="middle"
                        >
                          {field.customComponent !== undefined ? (
                            field.customComponent
                          ) : (
                            <Fragment>
                              {field.fieldType === "date" && (
                                <Form.Field style={{ textAlign: "left" }}>
                                  {field.showDisplay && (
                                    <label style={{ fontSize: "13px" }}>
                                      {field.display}
                                    </label>
                                  )}
                                  <Field
                                    name={field.name}
                                    component={DateInput}
                                    placeholder={field.placeholder}
                                    changeFinished={field.changeFinished}
                                    date={true}
                                    time={false}
                                  />
                                </Form.Field>
                              )}
                              {!field.fieldType && (
                                <Form.Field style={{ textAlign: "left" }}>
                                  {field.showDisplay && (
                                    <label style={{ fontSize: "13px" }}>
                                      {field.display}
                                    </label>
                                  )}
                                  <Field
                                    name={field.name}
                                    component={
                                      field.options ? SelectInput : TextInput
                                    }
                                    options={field.options}
                                    multiselect={field.multiple}
                                    placeholder={field.placeholder}
                                    changeFinished={field.changeFinished}
                                    type={field.type}
                                    allowShowError={field.allowShowError}
                                  />
                                </Form.Field>
                              )}
                            </Fragment>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Fragment>
                  ))}
                  {submitError && !dirtySinceLastSubmit && (
                    <Grid.Row>
                      <ErrorMessage error={submitError} />
                    </Grid.Row>
                  )}

                  <Divider className="no-margin" />

                  <Grid.Row centered>
                    <Grid.Column
                      width={fullWidthOnEdit ? 16 : 12}
                      verticalAlign="middle"
                    >
                      <Button.Group>
                        <Button
                          disabled={
                            (invalid && !dirtySinceLastSubmit) || pristine
                          }
                          // color="blue"
                          className="ct-green-button-active"
                          loading={submitting}
                        >
                          {"Save"}
                        </Button>
                        <Button.Or />
                        <Button
                          onClick={() => {
                            updateEditMode && updateEditMode(false);
                            cancelClicked && cancelClicked(fieldName);
                          }}
                        >
                          {"Cancel"}
                        </Button>
                      </Button.Group>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          />
        </Grid.Column>
      )}
    </Grid.Row>
  );
};

export default observer(ProfileFieldUpdate);
