import { formatRelative } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Card, Image } from "semantic-ui-react";
import agent from "../../../api/agent";
import { getBase64 } from "../../../helpers/urlImageHelper";
import { ISuperMarket } from "../../../interfaces/supermarket";

interface IProps {
  shop: ISuperMarket;
}

const SupermarketCard: React.FC<IProps> = ({ shop }) => {
  const [image, setImage] = React.useState<string>();

  useEffect(() => {
    if (shop.imageUrl && shop.createdOn && !shop.imageUrl.startsWith("http")) {
      var storageItem = localStorage.getItem(shop.imageUrl);

      if (storageItem) {
        var obj = JSON.parse(storageItem);
        setImage(obj.value);
      } else
        agent.AWS.Bucket.getFileUrl(shop.imageUrl).then((dataUrl) => {
          setImage(dataUrl);
          fetch(dataUrl).then((r) => {
            r.blob().then((blobFile) =>
              getBase64(blobFile, (base64: string) => {
                localStorage.setItem(
                  shop.imageUrl!,
                  JSON.stringify({
                    value: base64,
                    expiry: new Date().getTime() / 1000 + 86400 * 3,
                  })
                );
              })
            );
          });
        });
    } else if (shop.imageUrl) {
      setImage(shop.imageUrl);
    }

    return () => {
      // second;
    };
  }, [shop.createdOn, shop.imageUrl]);
  return (
    <Card
      key={shop.type_id}
      raised
      className={`superMarketCard`}
      id={shop.type_id}
      onClick={() => {
        if (shop.website) window.open(shop.website, "_blank");
      }}
    >
      <Image
        centered
        src={
          image ??
          `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
        }
        size="tiny"
        className={`superMarketImage `}
        alt="shop Logo"
        onError={({ currentTarget }: any) => {
          currentTarget.onerror = null;
          currentTarget.src =
            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
        }}
      />
      <Card.Content
        style={{ padding: "1em 0em" }}
        className="fxDisplay fxDirCol"
        textAlign="center"
      >
        <Card.Header>{shop.title}</Card.Header>
        {shop.lastUpdated && (
          <Card.Meta>
            Last Update:{" "}
            {formatRelative(new Date(shop.lastUpdated * 1000), new Date(), {})}
          </Card.Meta>
        )}
      </Card.Content>
    </Card>
  );
};

export default observer(SupermarketCard);
