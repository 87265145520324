import React, { Fragment, useContext } from "react";
import { Button, Divider, Icon, Image, Popup } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { IProductForList } from "../../../interfaces/product";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { getImageUrl } from "../../../helpers/productImage";
import { getMarketImageUrl } from "../../../helpers/supermarketImage";
import { currencyFormat } from "../functions/productHelper";
import { formatRelative } from "date-fns";
import { OpenAssociationModal } from "../../associations/functions/AssociationHelpers";
import Associations from "../../associations/Associations";
import { requestUserSession } from "../../registration/functions/sessionFnc";

interface IProps {
  product: IProductForList;
}

const ProductCard: React.FC<IProps> = ({ product }) => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const { isAdmin } = context.userStore;
  const { products } = context.productStore;
  const { isMobile } = context.deviceStore;
  const { isLoggedIn } = context.sessionStore;
  const {
    addToCard,
    removeFromCart,
    shopingCart,
    quantityInProgress,
    getProductQuantity,
  } = context.shoppingListStore;
  const { selectedMarketIds } = context.commonStore;
  const {
    tempListForGroup,
    toggleFromList,
    clearTempList,
  } = context.productAssociationStore;
  const { openModal, closeModal } = context.modalStore;

  return (
    <div className="ui card" style={{ padding: "7px", maxHeight: "500px" }}>
      <div>
        <Image
          src={getImageUrl(product)}
          onError={({ currentTarget }: any) => {
            currentTarget.onerror = null;
            currentTarget.src =
              `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
          }}
          alt={product.itemDesc}
          className="grouped-product-image"
        />

        <div
          title={product.itemDesc}
          style={{ height: "45px" }}
          className="grouped-product-title fxStretch"
        >
          {product.itemDesc}
        </div>

        <div
          className="fxDisplay fxAlignCenter fxJustifyEven "
          style={{
            marginTop: "7px",
            height: "50px",
          }}
        >
          {product && (markets?.length ?? 0) > 0 &&
            (selectedMarketIds.length > 0
              ? selectedMarketIds.findIndex((f) => f === product.marketId) > -1
              : true) && (
              <div
                style={{
                  fontSize: "19px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                <Icon name="euro" />
                {currencyFormat(product.currentPrice, false)}
              </div>
            )}

          {product && (markets?.length ?? 0) > 0 &&
            (selectedMarketIds.length > 0
              ? selectedMarketIds.findIndex((f) => f === product.marketId) > -1
              : true) && (
              <div>
                <Fragment>
                  <Image
                    centered
                    alt={
                      markets.filter((f) => f.type_id === product.marketId)[0]
                        ?.title
                    }
                    title={
                      markets.filter((f) => f.type_id === product.marketId)[0]
                        ?.title
                    }
                    src={getMarketImageUrl(
                      markets.filter((f) => f.type_id === product.marketId)[0]
                    )}
                    size="tiny"
                    style={{ maxHeight: "45px", objectFit: "contain" }}
                  />
                </Fragment>
              </div>
            )}
        </div>

        <div className="fxStretch fxTextAlignCenter">
          <div style={{ marginTop: "0.5em" }}></div>
          <span className="subTextTwo">
            {product.lastUpdated && (
              <p>
                Last Update :{" "}
                {formatRelative(
                  new Date(product.lastUpdated * 1000),
                  new Date(),
                  {}
                )}
              </p>
            )}
          </span>
        </div>

        <Divider
          style={
            shopingCart?.items?.filter((x) => product.id === x.productId)
              .length === 1
              ? { marginBottom: "7px" }
              : undefined
          }
        />

        {isAdmin && (
          <div
            className="fxDisplay fxFixed fxJustifyBetween"
            style={{ paddingLeft: "8px", paddingRight: "8px" }}
          >
            <Popup
              trigger={
                <Icon.Group
                  size="large"
                  onClick={() => {
                    toggleFromList(product.id);
                  }}
                >
                  <Icon name="linkify" color="blue" />
                  {tempListForGroup.includes(product.id) ? (
                    <Icon corner="top right" color="red" name="remove" />
                  ) : (
                    <Icon corner="top right" color="green" name="add" />
                  )}
                </Icon.Group>
              }
              content="Add to group"
              on="hover"
            />

            <Popup
              trigger={
                <Icon
                  disabled={!tempListForGroup.includes(product.id)}
                  fitted
                  name={"object group outline"}
                  color="blue"
                  size="large"
                  onClick={() => {
                    OpenAssociationModal(
                      openModal,
                      products?.filter((f) => tempListForGroup.includes(f.id)),
                      () => clearTempList()
                    );
                  }}
                />
              }
              disabled={!tempListForGroup.includes(product.id)}
              content="Group"
              on="hover"
            />

            <Popup
              trigger={
                <Icon
                  disabled={!tempListForGroup.includes(product.id)}
                  fitted
                  name={"compress"}
                  color="blue"
                  size="large"
                  onClick={() => {
                    openModal(
                      <Associations mode="select" />,
                      "large",
                      "Select an association",
                      true,
                      false,
                      () => clearTempList()
                    );
                  }}
                />
              }
              content="Add to existing group"
              on="hover"
              disabled={!tempListForGroup.includes(product.id)}
            />
          </div>
        )}

        {!isAdmin && (
          <div>
            {shopingCart?.items?.filter((x) => product.id === x.productId)
              .length === 1 ? (
              <div
                className="fxDisplay fxFixed fxJustifyBetween"
                style={{ paddingLeft: "8px", paddingRight: "8px" }}
              >
                <div>
                  <Popup
                    trigger={
                      <Button
                        size="tiny"
                        circular
                        disabled={quantityInProgress}
                        className="ct-green-button-active"
                        icon={"plus"}
                        onClick={() => {
                          addToCard(product);
                        }}
                      />
                    }
                    content={"Add more"}
                    on="hover"
                  />
                </div>

                <div
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    marginTop: "5px",
                  }}
                >
                  {getProductQuantity(product)}
                </div>

                <div>
                  <Popup
                    trigger={
                      <Button
                        size="tiny"
                        circular
                        disabled={quantityInProgress}
                        className="ct-green-button-active"
                        icon={"minus"}
                        onClick={() => {
                          removeFromCart(product);
                        }}
                      />
                    }
                    content={"Remove from cart"}
                    on="hover"
                  />
                </div>
              </div>
            ) : (shopingCart?.items?.filter((x) => product.id === x.productId)
              .length ?? 0) === 0 ? (
              <div
                className="fxDisplay fxFixed fxJustifyEven "
                style={{ marginBottom: "0px" }}
              >
                <Icon
                  className="linkable ct-green-text"
                  disabled={quantityInProgress}
                  size="large"
                  name={"plus cart"}
                  onClick={() => {
                    if (!isLoggedIn)
                      requestUserSession(
                        isMobile,
                        openModal,
                        closeModal,
                        () => {
                          closeModal();
                        }
                      );
                    else {
                      addToCard(product);
                    }
                    // addToCard(product);
                  }}
                ></Icon>
              </div>
            ) : (
              "There is more than 1 products selected from this group; to edit click the compare button"
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(ProductCard);
