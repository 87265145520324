import React, { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Divider, Menu, Table } from "semantic-ui-react";
import AddNewModule from "./AddNewModule";
import { format } from "date-fns";
import { NoResults } from "../../../common/NoResults";

interface IProps {
    shopId: string;
}

const BillingModuleWidget: React.FC<IProps> = ({ shopId }) => {
    const context = useContext(RootStoreContext);
    const { openModal } = context.modalStore;
    const { isOwner } = context.userStore;
    const {
        loadData,
        dispose,
        loading,
        oneTimeLoad,
        hasMore,
        items,
        availableModules
    } = context.billingModuleStore;

    useEffect(() => {
        oneTimeLoad(shopId)
        loadData();
        return () => {
            dispose();
        };
    }, [loadData, oneTimeLoad, dispose, shopId]);

    return (
        <Fragment>
            {isOwner && <Fragment>
                <Menu
                    secondary
                    size={"small"}
                    style={{ margin: "5px 0" }}
                >
                    <Menu.Menu position="right">
                        <Menu.Item style={{ padding: "0px" }}>
                            <Button
                                basic
                                color="blue"
                                onClick={() => {
                                    openModal(<AddNewModule shopId={shopId} />, "tiny", "", false, false);
                                }}
                            >
                                Add
                            </Button>
                        </Menu.Item>
                    </Menu.Menu>

                </Menu>
                <Divider /></Fragment>}

            <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={items.length}
                next={() => loadData()}
                hasMore={hasMore}
                loader={loading ? <h4>Loading...</h4> : <div />}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        {items.length > 0 ? (
                            <b></b>
                        ) : (
                            <NoResults />
                        )}
                    </p>
                }>
                <Table basic="very" celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Module</Table.HeaderCell>
                            <Table.HeaderCell>Start Date</Table.HeaderCell>
                            <Table.HeaderCell>Expires On</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items.map((x) => (
                            <Table.Row>
                                <Table.Cell>
                                    {availableModules.filter(f => f.id === x.moduleId)[0]?.display ?? "-"}
                                </Table.Cell>
                                <Table.Cell>
                                    {format(new Date(x.startsOn * 1000), "dd/MM/yyyy")}
                                    {/* <p>for {`${x.duration.value} ${x.duration.type}`}</p> */}
                                </Table.Cell>
                                <Table.Cell
                                    negative={new Date(x.expiresOn * 1000) < new Date()}
                                    positive={new Date(x.expiresOn * 1000) > new Date()}
                                >
                                    {format(new Date(x.expiresOn * 1000), "dd/MM/yyyy")}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </InfiniteScroll>
        </Fragment>
    )
}

export default observer(BillingModuleWidget)