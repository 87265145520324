import React, { useState, useEffect, useContext } from 'react';
import { currencyFormat } from '../products/functions/productHelper';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { observer } from 'mobx-react-lite';

const SavingsBubble: React.FC = () => {
    const context = useContext(RootStoreContext)
    const { toggleSavings } = context.cacheStore;
    const { loadingSavings, savings, basketItems } = context.shoppingCartStore;

    const [showPopup, setShowPopup] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({
        x: window.innerWidth - 100,
        y: window.innerHeight - 100,
    });

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        setLastMousePosition({ x: e.clientX, y: e.clientY });
    };

    const [lastMousePosition, setLastMousePosition] = useState<{ x: number; y: number } | null>(null);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if (!isDragging || !lastMousePosition) return;

            const dx = e.clientX - lastMousePosition.x;
            const dy = e.clientY - lastMousePosition.y;

            setPosition(prevPosition => ({
                x: prevPosition.x + dx,
                y: prevPosition.y + dy,
            }));

            setLastMousePosition({ x: e.clientX, y: e.clientY });
        };

        const handleMouseUp = () => {
            setIsDragging(false);
            setLastMousePosition(null);
        };

        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, lastMousePosition]);

    const preventDragHandler = (e: React.DragEvent<HTMLImageElement>) => {
        e.preventDefault();
    };

    return (
        <div
            className="savings-widget"
            onMouseDown={handleMouseDown}
            onMouseEnter={() => setShowPopup(true)}
            onMouseLeave={() => setShowPopup(false)}
            style={{ top: `${position.y}px`, left: `${position.x}px` }}
        >
            <span className="close-button" onClick={() => { toggleSavings() }}>X</span>
            <img src={`${process.env.PUBLIC_URL}/piggy-bank.png`} onDragStart={preventDragHandler} alt="Savings" />

            <span>{loadingSavings ? <div className="loading-dots">
                <span></span>
                <span></span>
                <span></span>
            </div> : savings === undefined ? "--" : currencyFormat(savings.avg)}</span>
            <p>avg</p>
            {savings === undefined && <div className="savings-popup">
                Sign in and begin your shopping journey now to discover your savings!
            </div>}
            {savings && showPopup && !isDragging && (
                basketItems.length === 0 ? <div className="savings-popup">
                    Your cart is currently empty. Begin your shopping journey now to discover your savings!
                </div> :
                    <div className="savings-popup">
                        You are saving between
                        <b style={{ paddingLeft: "4px" }} className='ct-green-text-dark'>{currencyFormat(savings.avg)}</b> and
                        <b style={{ paddingLeft: "4px" }} className='ct-green-text-dark'>{currencyFormat(savings.max)}</b>.
                    </div>
            )}
        </div>
    );
};

export default observer(SavingsBubble);
