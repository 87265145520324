import { createContext } from "react";
import { configure, makeObservable, action } from "mobx";
import { ProductsStore } from "../features/products/functions/ProductsStore";
import { ModalContainerStore } from "../components/modal/functions/ModalContainerStore";
import { ProductAssociationStore } from "../features/associations/functions/ProductAssociationStore";
import { AnalyticsStore } from "./AnalyticsStore";
import { DeviceStore } from "./DeviceStore";
import { ShoppingListStore } from "../features/shopping-list/functions/ShoppingListStore";
import { UserStore } from "./userStore";
import { SessionStore } from "./SessionStore";
import { AssociationStore } from "../features/associations/functions/AssociationStore";
import { CacheStore } from "./CacheStore";
import { ProductCounterStore } from "../features/products/functions/ProductCounterStore";
import { UserListStore } from "../features/users/functions/UserlistStore";
import ConfirmationModalStore from "../features/confirmationModal/functions/confirmationModalStore";
import { ProductWizardStore } from "../features/productWizard/functions/ProductWizardStore";
import { ProfileStore } from "../features/profile/functions/profileStore";
import { UserAddressesStore } from "../features/profile/functions/UserAddressesStore";
import { MenuStore } from "../features/mainMenu/functions/MenuStore";
import { UsersCounterStore } from "../features/users/functions/UsersCounterStore";
import { CommonStore } from "./commonStore";
import { AssociationViewStore } from "../features/associations/functions/AssociationViewStore";
// import { AdvertiseSetupStore } from "../features/AdvertSetup/functions/AdvertiseSetupStore";
import { OffersStore } from "../features/offers/functions/OffersStore";
// import { AdDetailStore } from "../features/AdvertSetup/functions/AdDetailStore";
import { AdminPanelStore } from "../features/adminOwner/functions/AdminPanelStore";
// import { AdminCampaignStore } from "../features/adminOwner/functions/AdminCampaignStore";
// import { AdPerformanceStore } from "../features/AdvertSetup/functions/AdPerformanceStore";
import { InventoryStore } from "../features/onlineShop/functions/InventoryStore";
import { OnlineShopStore } from "../features/onlineShop/functions/OnlineShopStore";
import { UploadWizard } from "../features/onlineShop/functions/UploadWizard";
import { OrphanStore } from "../features/products/functions/OrphanStore";
import { PossibleAssociationsV2Store } from "../features/possibleAssociations/functions/PossbileAssociationsV2Store";
import { ShopListStore } from "../features/adminShops/functions/ShopListStore";
import { ShopClaimStore } from "../features/onlineShop/functions/ShopClaimStore";
import { ClaimRequestStore } from "../features/adminClaims/functions/ClaimRequestStore";
import { IntegrationRequestStore } from "../features/onlineShop/functions/IntegrationRequestStore";
import { IntegrationRequestListStore } from "../features/adminIntegration/functions/IntegrationRequestListStore";
import { ShopUserStore } from "../features/onlineShop/functions/ShopUserStore";
import BillingDetailsStore from "../features/onlineShop/functions/BillingDetailsStore";
import { AccountPageStore } from "../features/onlineShop/functions/AccountPageStore";
import { PromoCodesStore } from "../features/promoCodes/functions/PromoCodesStore";
import { AllClientOrdersStore } from "../features/clientOrders/functions/AllClientOrdersStore";
import { ClientCreditStore } from "../features/credits/functions/ClientCreditStore";
import { CategoriesStore } from "../features/mainMenu/functions/CategoriesStore";
import { UnverifiedStore } from "../features/products/functions/UnverifiedStore";
import { MeasurementsStore } from "../features/measurementUnits/functions/MeasurementsStore";
import { HubsStore } from "../features/shopping-list/functions/hubsStore";
import { ShopOrderStore } from "../features/onlineShop/functions/ShopOrderStore";
import SignalRStore from "./SignalRStore";
import { LabelStatStore } from "../features/labelStats/functions/LabelStatStore";
import { LabelListStore } from "../features/labelStats/functions/LabelListStore";
import { PriceTrackerStore } from "../features/onlineShop/functions/PriceTrackerStore";
import { LastSyncStore } from "../features/lastSync/functions/LastSyncStore";
import { GlobalInventoryStore } from "../features/onlineShop/functions/GlobalInventoryStore";
import { AssociationVerificationStore } from "../features/groupVerification/functions/AssociationVerificationStore";
import BillingModuleStore from "../features/onlineShop/functions/BillingModuleStore";
import { GlobalConfiguration } from "./GlobalConfiguration";
import LocationsManagementStore from "../features/onlineShop/functions/LocationsManagementStore";
import { CatalogueAdminStore } from "../features/catalogue/functions/CatalogueAdminStore";
import { BrandAdminStore } from "../features/brands/functions/BrandAdminStore";
import { ShoppingCatalogueStore } from "../features/ShoppingCatalogue/functions/ShoppingCatalogueStore";
import { ShoppingCartStore } from "../features/shopping-list/functions/ShoppingCartStore";
import LocationIQStore from "../components/maps/functions/LocationIQStore";
import { StabbiltaStore } from "../features/Stabbilta/functions/StabbiltaStore";
import { HubOrdersStore } from "../features/hubOrders/functions/hubOrdersStore";
import { AllClientOrderBubblesStore } from "../features/clientOrders/functions/AllClientOrderBubblesStore";
import { SearchStore } from "../features/search/store/SearchStore";
import { ShopOrderDetailStore } from "../features/onlineShop/functions/ShopOrderDetailStore";
import { UserAssociationStore } from "../features/associations/functions/UserAssociationStore";
import { CategoriesAdminStore } from "../features/productCategories/functions/ProductCategoriesStore";
import { ProductAnalysisStore } from "../features/productAnalysis/functions/ProductAnalysisStore";
import { ProductListStore } from "../features/onlineShop/functions/ProductListStore";
import { ChatUIStore } from "../features/chat/functions/ChatUIStore";
import { UserDietProfileStore } from "./UserDietProfileStore";

configure({ enforceActions: "always" });

export class RootStore {
    productStore: ProductsStore;
    modalStore: ModalContainerStore;
    associationStore: AssociationStore;
    productAnalysisStore: ProductAnalysisStore;
    userAssociationStore: UserAssociationStore;
    analyticsStore: AnalyticsStore;
    deviceStore: DeviceStore;
    shoppingListStore: ShoppingListStore;
    userStore: UserStore;
    sessionStore: SessionStore;
    cacheStore: CacheStore;
    productAssociationStore: ProductAssociationStore;
    productCounterStore: ProductCounterStore;
    userListStore: UserListStore;
    shopListStore: ShopListStore;
    confirmationModalStore: ConfirmationModalStore;
    possibleAssociationsV2Store: PossibleAssociationsV2Store;
    productWizardStore: ProductWizardStore;
    profileStore: ProfileStore;
    userAddressesStore: UserAddressesStore;
    menuStore: MenuStore;
    usersCounterStore: UsersCounterStore;
    commonStore: CommonStore;
    associationViewStore: AssociationViewStore;
    // advertiseSetupStore: AdvertiseSetupStore;
    offersStore: OffersStore;
    // adDetailStore: AdDetailStore;
    adminPanelStore: AdminPanelStore;
    // adminCampaignStore: AdminCampaignStore;
    // adPerformanceStore: AdPerformanceStore;
    onlineShopStore: OnlineShopStore;
    inventoryStore: InventoryStore;
    catalogueAdminStore: CatalogueAdminStore;
    priceTrackerStore: PriceTrackerStore;
    uploadWizard: UploadWizard;
    orphanStore: OrphanStore;
    shopClaimStore: ShopClaimStore;
    claimRequestStore: ClaimRequestStore;
    integrationRequestStore: IntegrationRequestStore;
    integrationRequestListStore: IntegrationRequestListStore;
    shopUserStore: ShopUserStore;
    billingDetailsStore: BillingDetailsStore;
    accountPageStore: AccountPageStore;
    // billingTransactionsStore: BillingTransactionsStore;
    promoCodesStore: PromoCodesStore;
    allClientOrdersStore: AllClientOrdersStore;
    clientCreditStore: ClientCreditStore;
    categoriesStore: CategoriesStore;
    unverifiedStore: UnverifiedStore;
    labelStatStore: LabelStatStore;
    measurementsStore: MeasurementsStore;
    hubsStore: HubsStore;
    shopOrderStore: ShopOrderStore;
    signalRStore: SignalRStore;
    labelListStore: LabelListStore;
    lastSyncStore: LastSyncStore;
    globalInventoryStore: GlobalInventoryStore;
    associationVerificationStore: AssociationVerificationStore;
    billingModuleStore: BillingModuleStore;
    globalConfig: GlobalConfiguration;
    locationsManagementStore: LocationsManagementStore;
    brandAdminStore: BrandAdminStore;
    shoppingCatalogueStore: ShoppingCatalogueStore;
    stabbiltaStore: StabbiltaStore;
    shoppingCartStore: ShoppingCartStore;
    locationIQStore: LocationIQStore;
    hubOrdersStore: HubOrdersStore;
    allClientOrderBubblesStore: AllClientOrderBubblesStore;
    searchStore: SearchStore;
    shopOrderDetailStore: ShopOrderDetailStore;
    categoriesAdminStore: CategoriesAdminStore;
    productListStore: ProductListStore;
    chatUIStore: ChatUIStore;
    userDietProfileStore: UserDietProfileStore;

    constructor() {
        makeObservable(this);

        this.productAnalysisStore = new ProductAnalysisStore(this);
        this.signalRStore = new SignalRStore(this);
        this.globalConfig = new GlobalConfiguration(this);
        this.productStore = new ProductsStore(this);
        this.modalStore = new ModalContainerStore(this);
        this.associationStore = new AssociationStore(this);
        this.userAssociationStore = new UserAssociationStore(this);
        this.analyticsStore = new AnalyticsStore(this);
        this.deviceStore = new DeviceStore(this);
        this.shoppingListStore = new ShoppingListStore(this);
        this.userStore = new UserStore(this);
        this.sessionStore = new SessionStore(this);
        this.productAssociationStore = new ProductAssociationStore(this);
        this.cacheStore = new CacheStore(this);
        this.productCounterStore = new ProductCounterStore(this);
        this.userListStore = new UserListStore(this);
        this.confirmationModalStore = new ConfirmationModalStore(this);
        this.productWizardStore = new ProductWizardStore(this);
        this.profileStore = new ProfileStore(this);
        this.userAddressesStore = new UserAddressesStore(this);
        this.menuStore = new MenuStore(this);
        this.usersCounterStore = new UsersCounterStore(this);
        this.commonStore = new CommonStore(this);
        this.associationViewStore = new AssociationViewStore(this);
        // this.advertiseSetupStore = new AdvertiseSetupStore(this);
        this.offersStore = new OffersStore(this);
        // this.adDetailStore = new AdDetailStore(this);
        this.adminPanelStore = new AdminPanelStore(this);
        // this.adminCampaignStore = new AdminCampaignStore(this);
        // this.adPerformanceStore = new AdPerformanceStore(this);
        this.onlineShopStore = new OnlineShopStore(this);
        this.inventoryStore = new InventoryStore(this);
        this.uploadWizard = new UploadWizard(this);
        this.priceTrackerStore = new PriceTrackerStore(this);
        this.globalInventoryStore = new GlobalInventoryStore(this);
        this.orphanStore = new OrphanStore(this);
        this.shopListStore = new ShopListStore(this);
        this.possibleAssociationsV2Store = new PossibleAssociationsV2Store(this);
        this.shopClaimStore = new ShopClaimStore(this);
        this.claimRequestStore = new ClaimRequestStore(this);
        this.integrationRequestStore = new IntegrationRequestStore(this);
        this.integrationRequestListStore = new IntegrationRequestListStore(this);
        this.shopUserStore = new ShopUserStore(this);
        this.billingDetailsStore = new BillingDetailsStore(this);
        this.accountPageStore = new AccountPageStore(this);
        this.billingModuleStore = new BillingModuleStore(this)
        this.promoCodesStore = new PromoCodesStore(this);
        this.stabbiltaStore = new StabbiltaStore(this);
        this.allClientOrdersStore = new AllClientOrdersStore(this)
        this.clientCreditStore = new ClientCreditStore(this);
        this.categoriesStore = new CategoriesStore(this);
        this.unverifiedStore = new UnverifiedStore(this);
        this.measurementsStore = new MeasurementsStore(this);
        this.hubsStore = new HubsStore(this);
        this.shopOrderStore = new ShopOrderStore(this);
        this.labelStatStore = new LabelStatStore(this);
        this.labelListStore = new LabelListStore(this);
        this.lastSyncStore = new LastSyncStore(this);
        this.associationVerificationStore = new AssociationVerificationStore(this);
        this.locationsManagementStore = new LocationsManagementStore(this);
        this.catalogueAdminStore = new CatalogueAdminStore(this);
        this.brandAdminStore = new BrandAdminStore(this);
        this.shoppingCatalogueStore = new ShoppingCatalogueStore(this);
        this.shoppingCartStore = new ShoppingCartStore(this);
        this.locationIQStore = new LocationIQStore(this);
        this.hubOrdersStore = new HubOrdersStore(this);
        this.allClientOrderBubblesStore = new AllClientOrderBubblesStore(this)
        this.searchStore = new SearchStore(this);
        this.shopOrderDetailStore = new ShopOrderDetailStore(this);
        this.categoriesAdminStore = new CategoriesAdminStore(this);
        this.productListStore = new ProductListStore(this);
        this.chatUIStore = new ChatUIStore(this);
        this.userDietProfileStore = new UserDietProfileStore(this);
    }

    @action dispose = () => {
        // this.productStore.dispose();
        // this.associationStore.dispose()
        // this.userStore.di
    }
}

export const RootStoreContext = createContext(new RootStore());
