import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useContext } from 'react'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { Button, Divider, Header, Icon, Input } from 'semantic-ui-react';

const DietGoal = () => {
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const {
        setProfile,
        profile,
        nextStep,
        previousStep
    } = context.userDietProfileStore;

    return (
        <div
            className='fxFixed fxDisplay fxAlignCenter fxStretch fxDirCol'
            style={{ marginTop: "55px" }}>
            <Header
                size='large'
                textAlign='left'
                content="Do you have any specific goal?"
                subheader="We will use this information to filter futher the recommended recipes!"
                style={isMobile ? { padding: "15px" } : {
                    marginBottom: "25px"
                }}
            />

            <div style={isMobile ? { padding: "10px", width: "100%" } : { maxWidth: "550px", width: "550px" }}>
                <div className='fxDisplay'>
                    <div className='fxStretch' style={{ margin: "auto" }}>Set your target</div>
                    <div className=''>
                        <Button.Group vertical={isMobile}>
                            <Button onClick={() => setProfile({ ...profile, target: undefined })} className={profile?.target === undefined ? 'ct-green-button-active' : 'ct-green-button'} content="None" active={profile?.target === undefined} />
                            <Button onClick={() => setProfile({ ...profile, target: "general" })} className={profile?.target === "general" ? 'ct-green-button-active' : 'ct-green-button'} content="General" active={profile?.target === "general"} />
                            <Button onClick={() => setProfile({ ...profile, target: "exact" })} className={profile?.target === "exact" ? 'ct-green-button-active' : 'ct-green-button'} content="Specific" active={profile?.target === "exact"} />
                        </Button.Group>
                    </div>
                </div>
                {profile?.target === "general" && <div className='fxDisplay' style={{ marginTop: "30px" }}>
                    <div className='fxStretch' style={{ margin: "auto" }}>Set a goal</div>
                    <div className=''>
                        <Button.Group vertical={isMobile}>
                            <Button onClick={() => setProfile({ ...profile, target_type: 'maintain_weight' })} className={profile?.target_type === "maintain_weight" ? 'ct-green-button-active' : 'ct-green-button'} content="Maintain Weight" active={profile?.target_type === "maintain_weight"} />
                            <Button onClick={() => setProfile({ ...profile, target_type: 'build_muscle' })} className={profile?.target_type === "build_muscle" ? 'ct-green-button-active' : 'ct-green-button'} content="Build Muscle" active={profile?.target_type === "build_muscle"} />
                            <Button onClick={() => setProfile({ ...profile, target_type: "lose_fat" })} className={profile?.target_type === "lose_fat" ? 'ct-green-button-active' : 'ct-green-button'} content="Lose Fat" active={profile?.target_type === "lose_fat"} />
                        </Button.Group>
                    </div>
                </div>}
                {profile?.target === "exact" &&
                    <div className='fxDirCol'>
                        <Divider />
                        <div className='fxDisplay' style={{ marginTop: "30px" }}>
                            <div className='fxStretch' style={{ margin: "auto" }}>Weight goal</div>
                            <div className='fxDisplay'>
                                <Input
                                    style={{ maxWidth: "60px" }}
                                    defaultValue={profile?.target_weight}
                                    onChange={(event: ChangeEvent, data: any) => {
                                        setProfile({ ...profile, target_weight: data.value * 1 })

                                    }}
                                />
                                <span style={{ paddingLeft: "8px", margin: "auto" }}>kg</span>
                            </div>
                        </div>
                        <Divider />
                        <div className='fxDisplay' style={{ marginTop: "30px" }}>
                            <div className='fxStretch' style={{ margin: "auto" }}>Weight change per week</div>
                            <div className='fxDisplay'>
                                <Input
                                    style={{ maxWidth: "60px" }}
                                    defaultValue={profile?.target_weight_wk}
                                    onChange={(event: ChangeEvent, data: any) => {
                                        setProfile({ ...profile, target_weight_wk: data.value * 1 })

                                    }}
                                />
                                <span style={{ paddingLeft: "8px", margin: "auto" }}>kg</span>
                            </div>
                        </div>
                    </div>
                }
            </div>


            <div className='fxDisplay fxFixed fxJustifyBetween'
                style={isMobile ? {
                    marginBottom: "15px",
                    width: "100%",
                    paddingLeft: "10px",
                    paddingRight: "10px"
                } : {
                    width: "550px",
                    marginBottom: "15px"
                }}>
                <div
                    className={`diet-continue-button clickable`}
                    style={{ marginTop: "22px", }}
                    onClick={() => {
                        previousStep()
                    }}
                >
                    Back
                    <Icon name='angle left' />
                </div>
                <div
                    className={`diet-continue-button clickable`}
                    style={{ marginTop: "22px", }}
                    onClick={() => {
                        nextStep()
                    }}
                >
                    <Icon name='angle right' />
                    Continue
                </div>
            </div>
        </div>
    )
}

export default observer(DietGoal)