import { observer } from "mobx-react";
import { Fragment, useContext, useState } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Button, Form, Header } from "semantic-ui-react";
import {
  combineValidators,
  composeValidators,
  createValidator,
  isRequired,
} from "revalidate";
import { RootStoreContext } from "../../stores/RootStoreContext";
import agent from "../../api/agent";
import TextInput from "../../form/TextInput";
import PasswordInput from "../../form/PasswordInput";
import ErrorMessage from "../../form/ErrorMessage";
import { IAccessToken } from "../../interfaces/token";
import { toast } from "react-toastify";

interface IProps {
  callbackOnSuccess?: (...args: any[]) => void;
  emailForReset?: string;
}

const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  (field) => "x"
);

const isValidPassword = createValidator(
  (message) => (value) => {
    if (
      value &&
      !/^(?=.*[A-Z])(?=.*[!=@#$%^&*()\-__+.£?])(?=.*[0-9])(?=.*[a-z]).{8,25}$/i.test(
        value
      )
    ) {
      return message;
    }
  },
  (field) => "x"
);

const validate = combineValidators({
  username: composeValidators(isRequired, isValidEmail)(""),
  password: composeValidators(isRequired, isValidPassword)(""),
});

const validateReset = combineValidators({
  username: composeValidators(isRequired, isValidEmail)(""),
});

const CreateSession: React.FC<IProps> = ({
  callbackOnSuccess,
  emailForReset = undefined,
}) => {
  const context = useContext(RootStoreContext);
  const { login } = context.sessionStore;
  const { setEventCustom } = context.analyticsStore;
  const [passwordReset, setPasswordReset] = useState(
    emailForReset === undefined ? false : true
  );

  return (
    <div className="">
      <div style={{ textAlign: "center" }}>
        <img
          src="/cheaptrolley_slogan.svg"
          alt="Cheap Trolley Logo"
          style={{ maxHeight: "80px", maxWidth: "90%" }}
        />
      </div>

      <FinalForm
        validate={passwordReset ? validateReset : validate}
        initialValues={{ username: emailForReset }}
        onSubmit={(values: any) =>
          (passwordReset
            ? agent.Auth.requestPasswordReset(values.username).then(() =>
              setEventCustom("password_reset")
            )
            : login(values).then((response: IAccessToken) => {
              setEventCustom("user_login");
              if (!response.email_verified) {
                toast(<Fragment>
                  <Header>Please take a moment to verify your email address</Header>
                  <Header.Subheader>We've just sent you an email that includes an activation link. To verify your account, simply follow the instructions provided in the email.</Header.Subheader>
                </Fragment>, {
                  position: "bottom-center",
                  autoClose: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  className: 'email-verify-container email-verify-container-after'
                });
              }
            })
          )
            .then((values) => callbackOnSuccess!(values))
            .catch((error) => ({
              [FORM_ERROR]: error,
            }))
        }
        render={({
          handleSubmit,
          submitting,
          dirtySinceLastSubmit,
          submitError,
        }) => (
          <Form onSubmit={handleSubmit} error className="attached fluid">
            <Form.Field>
              <Field
                name="username"
                autoComplete={"username"}
                component={TextInput}
                placeholder={"E-mail"}
              />
            </Form.Field>
            {passwordReset && (
              <div style={{ fontSize: "12px", textAlign: "center" }}>
                If the email address is registered in our system you will
                receive an email with instructions to reset your password
              </div>
            )}

            {!passwordReset && (
              <Fragment>
                <Form.Field style={{ marginBottom: "3px" }}>
                  <Field
                    name="password"
                    component={PasswordInput}
                    placeholder={"Password"}
                  />
                </Form.Field>
                <div
                  style={{
                    marginTop: "-14px",
                    fontSize: "12px",
                    textAlign: "right",
                  }}
                >
                  <div
                    className="button-as-link div-as-link"
                    // size="small"
                    onClick={(e) => {
                      e.preventDefault();

                      setPasswordReset(true);
                    }}
                  >
                    Forgot my password
                  </div>
                </div>
              </Fragment>
            )}

            {submitError && !dirtySinceLastSubmit && (
              <ErrorMessage error={submitError} />
            )}

            <div style={{ marginTop: "7px", textAlign: "center" }}>
              {passwordReset ? (
                <Button.Group>
                  <Button
                    loading={submitting}
                    className="ct-green-button-active"
                    content={"Request"}
                  />
                  <Button.Or />
                  <Button
                    disabled={submitting}
                    content={"Go back"}
                    onClick={(e) => {
                      e.preventDefault();
                      setPasswordReset(false);
                    }}
                  />
                </Button.Group>
              ) : (
                <Button
                  loading={submitting}
                  className="ct-green-button-active"
                  content={"Sign In"}
                />
              )}
            </div>
          </Form>
        )}
      />

      <p
        className="clickable"
        style={{ marginTop: "6px", fontSize: "13px" }}
        onClick={() => { window.open("https://wa.me/+35627995146", '_blank'); }}
      >
        Do you need help? Chat with us on <span style={{ fontWeight: "bolder", color: "#086c41" }}>WhatsApp</span>!
      </p>
    </div>
  );
};

export default observer(CreateSession);
