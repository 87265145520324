import { formatRelative } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import {
  Header,
  Icon,
  Table,
  Image,
  TableCell,
  Button,
} from "semantic-ui-react";
import { getImageUrl } from "../../helpers/productImage";
import { getMarketImageUrl } from "../../helpers/supermarketImage";
import { IProduct } from "../../interfaces/product";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { currencyFormat } from "../products/functions/productHelper";

interface IProps {
  product: IProduct;
  removeFromAssociation: (product: IProduct) => Promise<void>
}

const AssociationProductTableRow: React.FC<IProps> = ({ product, removeFromAssociation }) => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const { isAdmin } = context.userStore;
  const { isMobile } = context.deviceStore;

  return (
    <Table.Row negative={product.discontinued}>
      {!isMobile && (
        <Table.Cell>
          <Header as="h4" image>
            <Image
              alt="Product Logo"
              src={
                getImageUrl(product) ??
                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
              }
              onError={({ currentTarget }: any) => {
                currentTarget.onerror = null;
                currentTarget.src =
                  `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
              }}
              rounded
              size="mini"
            />

            <Header.Content>{product.itemDesc}</Header.Content>
          </Header>
        </Table.Cell>
      )}
      <Table.Cell>
        <Header as="h4" image>
          <Image
            alt="Product Logo"
            src={getMarketImageUrl(
              markets.filter((f) => f.type_id === product.marketId)[0]
            )}
            rounded
            size="mini"
          />
          <Header.Content>
            {markets.filter((f) => f.type_id === product.marketId)[0].title}
          </Header.Content>
        </Header>
      </Table.Cell>
      {isAdmin && (
        <TableCell>{product.sku ?? `i - ${product.internalId}`}</TableCell>
      )}
      {!isMobile && (
        <Table.Cell>
          {product.lastUpdated && (
            <Fragment>
              {formatRelative(new Date(product.lastUpdated * 1000), new Date())}
            </Fragment>
          )}
        </Table.Cell>
      )}
      <Table.Cell>
        <div
          style={{
            marginBottom: "0.5em",
            fontSize: "17px",
            fontWeight: "bolder",
          }}
        >
          <Icon name="euro" />
          {currencyFormat(product.currentPrice, false)}
        </div>
      </Table.Cell>
      {isAdmin && !isMobile && (
        <TableCell>
          <Button
            circular
            color="red"
            icon="minus"
            size="tiny"
            onClick={(e) => {
              e.preventDefault();
              removeFromAssociation(product)
            }}
          />
        </TableCell>
      )}
    </Table.Row>
  );
};

export default observer(AssociationProductTableRow);
