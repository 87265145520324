import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { IShopOrderItemsForList } from "../../../interfaces/order";
import { currencyFormat } from "../../products/functions/productHelper";
import { toast } from "react-toastify";

export class ShopOrderDetailStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loadingItems: boolean = false;
    @observable shopId?: string = undefined;
    @observable orderId?: string = undefined;
    @observable locationId?: string = undefined;
    @observable displayId?: string = undefined;
    @observable downloading: boolean = false;
    @observable orderItems: IShopOrderItemsForList[] = [];

    @action setDownloading = (value: boolean) => this.downloading = value;
    @action setOrderItems = (items: IShopOrderItemsForList[]) => this.orderItems = items;
    @action setLoadingItems = (value: boolean) => this.loadingItems = value;
    @action setShopId = (value?: string) => this.shopId = value;
    @action setOrderId = (value?: string) => this.orderId = value;
    @action setDisplayId = (value?: string) => this.displayId = value;
    @action setLocationId = (value?: string) => this.locationId = value;

    @action exportDataForCsv = async () => {
        if (!this.orderItems) return;

        this.setDownloading(true);
        try {
            var csvData: any[] = [];

            csvData.push(["Product", "Barcode", "Quantity Asked", "Quantity Packed", "Quantity Accepted", "Unit Cost", "Total Cost"]);

            this.orderItems.forEach((x) => {
                var finalAmount = x.receivedAmount ?? x.packedAmount ?? x.quantity;
                csvData.push([
                    x.product.itemDesc,
                    x.product.sku ?? x.product.internalId,
                    x.quantity,
                    x.packedAmount,
                    x.receivedAmount,
                    currencyFormat(x.unitNet + x.unitVat, false),
                    currencyFormat(finalAmount * (x.unitNet + x.unitVat), false),
                ]);
            });

            var today = new Date();
            var fileName = `${today.getFullYear()}_${today.getMonth()}_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
            return {
                data: csvData,
                filename: `${this.displayId}_${fileName}.csv`,
            };

        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownloading(false);
        }
    }

    @action update_order_item_status = async (productId: string, amount: number) => {
        if (!this.orderId) return;

        var item = this.orderItems.filter(f => f.product.id === productId)[0];
        var requestedQuantity = item.quantity;

        var status: "out_of_stock" | "fully_packed" | "partially_packed" | "pending" = "pending"

        if (amount === 0) {
            status = "out_of_stock";
        }
        else if (amount > 0 && amount < requestedQuantity) {
            status = "partially_packed";
        }
        else if (amount === requestedQuantity) {
            status = "fully_packed";
        }
        else {
            toast.error(`There seems to insert invalid amount (${amount}). Accepted values are between 0 and ${requestedQuantity}`);
            return;
        }

        var upd_obj = {
            'locationId': this.locationId,
            'productId': productId,
            'status': status,
            'quantity': amount
        }

        return agent.Shop.Orders.update_item(this.orderId, upd_obj).then(() => {
            runInAction(() => {
                var item = this.orderItems.filter(f => f.product.id === productId)[0];
                item.packedAmount = amount;
                item.shopStatus = status;
            })
        });
    }

    @action loadItems = async (orderId: string, displayId: string, locationId: string) => {
        try {
            this.setDisplayId(displayId);
            this.setOrderId(orderId);
            this.setLocationId(locationId);
            this.setLoadingItems(true);

            var response = await agent.Shop.Orders.getItems(orderId);

            this.setOrderItems(response);
        } catch (error) {
        }
        finally {
            this.setLoadingItems(false);
        }

    };

    @action dispose = () => {
        this.setShopId(undefined);
        this.setDisplayId(undefined);
        this.setLocationId(undefined);
        this.setLoadingItems(false);
        this.setOrderItems([]);
    }
}