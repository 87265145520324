export const Countries =
    [{
        "value": 240,
        "key": "AF",
        "flag": "af",
        "text": "Afghanistan",
    },
    {
        "value": 1,
        "key": "AL",
        "flag": "al",
        "text": "Albania",
    },
    {
        "value": 2,
        "key": "DZ",
        "flag": "dz",
        "text": "Algeria",
    },
    {
        "value": 3,
        "key": "AS",
        "flag": "as",
        "text": "American Samoa",
    },
    {
        "value": 4,
        "key": "AD",
        "flag": "ad",
        "text": "Andorra",
    },
    {
        "value": 5,
        "key": "AO",
        "flag": "ao",
        "text": "Angola",
    },
    {
        "value": 6,
        "key": "AI",
        "flag": "ai",
        "text": "Anguilla",
    },
    {
        "value": 7,
        "key": "AQ",
        // "flag": "aq",
        "text": "Antarctica",
    },
    {
        "value": 8,
        "key": "AG",
        "flag": "ag",
        "text": "Antigua and Barbuda",
    },
    {
        "value": 9,
        "key": "AR",
        "flag": "ar",
        "text": "Argentina",
    },
    {
        "value": 10,
        "key": "AM",
        "flag": "am",
        "text": "Armenia",
    },
    {
        "value": 11,
        "key": "AW",
        "flag": "aw",
        "text": "Aruba",
    },
    {
        "value": 12,
        "key": "AU",
        "flag": "au",
        "text": "Australia",
    },
    {
        "value": 13,
        "key": "AT",
        "flag": "at",
        "text": "Austria",
    },
    {
        "value": 14,
        "key": "AZ",
        "flag": "az",
        "text": "Azerbaijan",
    },
    {
        "value": 15,
        "key": "BS",
        "flag": "bs",
        "text": "Bahamas",
    },
    {
        "value": 16,
        "key": "BH",
        "flag": "bh",
        "text": "Bahrain",
    },
    {
        "value": 17,
        "key": "BD",
        "flag": "bd",
        "text": "Bangladesh",
    },
    {
        "value": 18,
        "key": "BB",
        "flag": "bb",
        "text": "Barbados",
    },
    {
        "value": 19,
        "key": "BY",
        "flag": "by",
        "text": "Belarus",
    },
    {
        "value": 20,
        "key": "BE",
        "flag": "be",
        "text": "Belgium",
    },
    {
        "value": 21,
        "key": "BZ",
        "flag": "bz",
        "text": "Belize",
    },
    {
        "value": 22,
        "key": "BJ",
        "flag": "bj",
        "text": "Benin",
    },
    {
        "value": 23,
        "key": "BM",
        "flag": "bm",
        "text": "Bermuda",
    },
    {
        "value": 24,
        "key": "BT",
        "flag": "bt",
        "text": "Bhutan",
    },
    {
        "value": 25,
        "key": "BO",
        "flag": "bo",
        "text": "Bolivia",
    },
    {
        "value": 26,
        "key": "BA",
        "flag": "ba",
        "text": "Bosnia and Herzegovina",
    },
    {
        "value": 27,
        "key": "BW",
        "flag": "bw",
        "text": "Botswana",
    },
    {
        "value": 28,
        "key": "BR",
        "flag": "br",
        "text": "Brazil",
    },
    {
        "value": 29,
        "key": "IO",
        "flag": "io",
        "text": "British Indian Ocean Territory",
    },
    {
        "value": 30,
        "key": "VG",
        "flag": "vg",
        "text": "British Virgin Islands",
    },
    {
        "value": 31,
        "key": "BN",
        "flag": "bn",
        "text": "Brunei",
    },
    {
        "value": 32,
        "key": "BG",
        "flag": "bg",
        "text": "Bulgaria",
    },
    {
        "value": 33,
        "key": "BF",
        "flag": "bf",
        "text": "Burkina Faso",
    },
    {
        "value": 34,
        "key": "BI",
        "flag": "bi",
        "text": "Burundi",
    },
    {
        "value": 35,
        "key": "KH",
        "flag": "kh",
        "text": "Cambodia",
    },
    {
        "value": 36,
        "key": "CM",
        "flag": "cm",
        "text": "Cameroon",
    },
    {
        "value": 37,
        "key": "CA",
        "flag": "ca",
        "text": "Canada",
    },
    {
        "value": 38,
        "key": "CV",
        "flag": "cv",
        "text": "Cape Verde",
    },
    {
        "value": 39,
        "key": "KY",
        "flag": "ky",
        "text": "Cayman Islands",
    },
    {
        "value": 40,
        "key": "CF",
        "flag": "cf",
        "text": "Central African Republic",
    },
    {
        "value": 41,
        "key": "TD",
        "flag": "td",
        "text": "Chad",
    },
    {
        "value": 42,
        "key": "CL",
        "flag": "cl",
        "text": "Chile",
    },
    {
        "value": 43,
        "key": "CN",
        "flag": "cn",
        "text": "China",
    },
    {
        "value": 44,
        "key": "CX",
        "flag": "cx",
        "text": "Christmas Island",
    },
    {
        "value": 45,
        "key": "CC",
        "flag": "cc",
        "text": "Cocos Islands",
    },
    {
        "value": 46,
        "key": "CO",
        "flag": "co",
        "text": "Colombia",
    },
    {
        "value": 47,
        "key": "KM",
        "flag": "km",
        "text": "Comoros",
    },
    {
        "value": 48,
        "key": "CK",
        "flag": "ck",
        "text": "Cook Islands",
    },
    {
        "value": 49,
        "key": "CR",
        "flag": "cr",
        "text": "Costa Rica",
    },
    {
        "value": 50,
        "key": "HR",
        "flag": "hr",
        "text": "Croatia",
    },
    {
        "value": 51,
        "key": "CU",
        "flag": "cu",
        "text": "Cuba",
    },
    {
        "value": 52,
        "key": "CW",
        // "flag": "cw",
        "text": "Curacao",
    },
    {
        "value": 53,
        "key": "CY",
        "flag": "cy",
        "text": "Cyprus",
    },
    {
        "value": 54,
        "key": "CZ",
        "flag": "cz",
        "text": "Czech Republic",
    },
    {
        "value": 55,
        "key": "CD",
        "flag": "cd",
        "text": "Democratic Republic of the Congo",
    },
    {
        "value": 56,
        "key": "DK",
        "flag": "dk",
        "text": "Denmark",
    },
    {
        "value": 57,
        "key": "DJ",
        "flag": "dj",
        "text": "Djibouti",
    },
    {
        "value": 58,
        "key": "DM",
        "flag": "dm",
        "text": "Dominica",
    },
    {
        "value": 59,
        "key": "DO",
        "flag": "do",
        "text": "Dominican Republic",
    },
    {
        "value": 60,
        "key": "TL",
        "flag": "tl",
        "text": "East Timor",
    },
    {
        "value": 61,
        "key": "EC",
        "flag": "ec",
        "text": "Ecuador",
    },
    {
        "value": 62,
        "key": "EG",
        "flag": "eg",
        "text": "Egypt",
    },
    {
        "value": 63,
        "key": "SV",
        "flag": "sv",
        "text": "El Salvador",
    },
    {
        "value": 64,
        "key": "GQ",
        "flag": "gq",
        "text": "Equatorial Guinea",
    },
    {
        "value": 65,
        "key": "ER",
        "flag": "er",
        "text": "Eritrea",
    },
    {
        "value": 66,
        "key": "EE",
        "flag": "ee",
        "text": "Estonia",
    },
    {
        "value": 67,
        "key": "ET",
        "flag": "et",
        "text": "Ethiopia",
    },
    {
        "value": 68,
        "key": "FK",
        "flag": "fk",
        "text": "Falkland Islands",
    },
    {
        "value": 69,
        "key": "FO",
        "flag": "fo",
        "text": "Faroe Islands",
    },
    {
        "value": 70,
        "key": "FJ",
        "flag": "fj",
        "text": "Fiji",
    },
    {
        "value": 71,
        "key": "FI",
        "flag": "fi",
        "text": "Finland",
    },
    {
        "value": 72,
        "key": "FR",
        "flag": "fr",
        "text": "France",
    },
    {
        "value": 73,
        "key": "PF",
        "flag": "pf",
        "text": "French Polynesia",
    },
    {
        "value": 74,
        "key": "GA",
        "flag": "ga",
        "text": "Gabon",
    },
    {
        "value": 75,
        "key": "GM",
        "flag": "gm",
        "text": "Gambia",
    },
    {
        "value": 76,
        "key": "GE",
        "flag": "ge",
        "text": "Georgia",
    },
    {
        "value": 77,
        "key": "DE",
        "flag": "de",
        "text": "Germany",
    },
    {
        "value": 78,
        "key": "GH",
        "flag": "gh",
        "text": "Ghana",
    },
    {
        "value": 79,
        "key": "GI",
        "flag": "gi",
        "text": "Gibraltar",
    },
    {
        "value": 80,
        "key": "EL",
        "flag": "gr",
        "text": "Greece",
    },
    {
        "value": 81,
        "key": "GL",
        "flag": "gl",
        "text": "Greenland",
    },
    {
        "value": 82,
        "key": "GD",
        "flag": "gd",
        "text": "Grenada",
    },
    {
        "value": 83,
        "key": "GU",
        "flag": "gu",
        "text": "Guam",
    },
    {
        "value": 84,
        "key": "GT",
        "flag": "gt",
        "text": "Guatemala",
    },
    {
        "value": 85,
        "key": "GG",
        // "flag": "gg",
        "text": "Guernsey",
    },
    {
        "value": 86,
        "key": "GN",
        "flag": "gn",
        "text": "Guinea",
    },
    {
        "value": 87,
        "key": "GW",
        "flag": "gw",
        "text": "Guinea-Bissau",
    },
    {
        "value": 88,
        "key": "GY",
        "flag": "gy",
        "text": "Guyana",
    },
    {
        "value": 89,
        "key": "HT",
        "flag": "ht",
        "text": "Haiti",
    },
    {
        "value": 90,
        "key": "HN",
        "flag": "hn",
        "text": "Honduras",
    },
    {
        "value": 91,
        "key": "HK",
        "flag": "hk",
        "text": "Hong Kong",
    },
    {
        "value": 92,
        "key": "HU",
        "flag": "hu",
        "text": "Hungary",
    },
    {
        "value": 93,
        "key": "IS",
        "flag": "is",
        "text": "Iceland",
    },
    {
        "value": 94,
        "key": "IN",
        "flag": "in",
        "text": "India",
    },
    {
        "value": 95,
        "key": "ID",
        "flag": "id",
        "text": "Indonesia",
    },
    {
        "value": 96,
        "key": "IR",
        "flag": "ir",
        "text": "Iran",
    },
    {
        "value": 97,
        "key": "IQ",
        "flag": "iq",
        "text": "Iraq",
    },
    {
        "value": 98,
        "key": "IE",
        "flag": "ie",
        "text": "Ireland",
    },
    {
        "value": 99,
        "key": "IM",
        // "flag": "im",
        "text": "Isle of Man",
    },
    {
        "value": 100,
        "key": "IL",
        "flag": "il",
        "text": "Israel",
    },
    {
        "value": 101,
        "key": "IT",
        "flag": "it",
        "text": "Italy",
    },
    {
        "value": 102,
        "key": "CI",
        "flag": "ci",
        "text": "Ivory Coast",
    },
    {
        "value": 103,
        "key": "JM",
        "flag": "jm",
        "text": "Jamaica",
    },
    {
        "value": 104,
        "key": "JP",
        "flag": "jp",
        "text": "Japan",
    },
    {
        "value": 105,
        "key": "JE",
        // "flag": "je",
        "text": "Jersey",
    },
    {
        "value": 106,
        "key": "JO",
        "flag": "jo",
        "text": "Jordan",
    },
    {
        "value": 107,
        "key": "KZ",
        "flag": "kz",
        "text": "Kazakhstan",
    },
    {
        "value": 108,
        "key": "KE",
        "flag": "ke",
        "text": "Kenya",
    },
    {
        "value": 109,
        "key": "KI",
        "flag": "ki",
        "text": "Kiribati",
    },
    {
        "value": 110,
        "key": "XK",
        // "flag": "xk",
        "text": "Kosovo",
    },
    {
        "value": 111,
        "key": "KW",
        "flag": "kw",
        "text": "Kuwait",
    },
    {
        "value": 112,
        "key": "KG",
        "flag": "kg",
        "text": "Kyrgyzstan",
    },
    {
        "value": 113,
        "key": "LA",
        "flag": "la",
        "text": "Laos",
    },
    {
        "value": 114,
        "key": "LV",
        "flag": "lv",
        "text": "Latvia",
    },
    {
        "value": 115,
        "key": "LB",
        "flag": "lb",
        "text": "Lebanon",
    },
    {
        "value": 116,
        "key": "LS",
        "flag": "ls",
        "text": "Lesotho",
    },
    {
        "value": 117,
        "key": "LR",
        "flag": "lr",
        "text": "Liberia",
    },
    {
        "value": 118,
        "key": "LY",
        "flag": "ly",
        "text": "Libya",
    },
    {
        "value": 119,
        "key": "LI",
        "flag": "li",
        "text": "Liechtenstein",
    },
    {
        "value": 120,
        "key": "LT",
        "flag": "lt",
        "text": "Lithuania",
    },
    {
        "value": 121,
        "key": "LU",
        "flag": "lu",
        "text": "Luxembourg",
    },
    {
        "value": 122,
        "key": "MO",
        "flag": "mo",
        "text": "Macau",
    },
    {
        "value": 123,
        "key": "MK",
        "flag": "mk",
        "text": "Macedonia",
    },
    {
        "value": 124,
        "key": "MG",
        "flag": "mg",
        "text": "Madagascar",
    },
    {
        "value": 125,
        "key": "MW",
        "flag": "mw",
        "text": "Malawi",
    },
    {
        "value": 126,
        "key": "MY",
        "flag": "my",
        "text": "Malaysia",
    },
    {
        "value": 127,
        "key": "MV",
        "flag": "mv",
        "text": "Maldives",
    },
    {
        "value": 128,
        "key": "ML",
        "flag": "ml",
        "text": "Mali",
    },
    {
        "value": 129,
        "key": "MT",
        "flag": "mt",
        "text": "Malta",
        "active": true
    },
    {
        "value": 130,
        "key": "MH",
        "flag": "mh",
        "text": "Marshall Islands",
    },
    {
        "value": 131,
        "key": "MR",
        "flag": "mr",
        "text": "Mauritania",
    },
    {
        "value": 132,
        "key": "MU",
        "flag": "mu",
        "text": "Mauritius",
    },
    {
        "value": 133,
        "key": "YT",
        "flag": "yt",
        "text": "Mayotte",
    },
    {
        "value": 134,
        "key": "MX",
        "flag": "mx",
        "text": "Mexico",
    },
    {
        "value": 135,
        "key": "FM",
        "flag": "fm",
        "text": "Micronesia",
    },
    {
        "value": 136,
        "key": "MD",
        "flag": "md",
        "text": "Moldova",
    },
    {
        "value": 137,
        "key": "MC",
        "flag": "mc",
        "text": "Monaco",
    },
    {
        "value": 138,
        "key": "MN",
        "flag": "mn",
        "text": "Mongolia",
    },
    {
        "value": 139,
        "key": "ME",
        "flag": "me",
        "text": "Montenegro",
    },
    {
        "value": 140,
        "key": "MS",
        "flag": "ms",
        "text": "Montserrat",
    },
    {
        "value": 141,
        "key": "MA",
        "flag": "ma",
        "text": "Morocco",
    },
    {
        "value": 142,
        "key": "MZ",
        "flag": "mz",
        "text": "Mozambique",
    },
    {
        "value": 143,
        "key": "MM",
        "flag": "mm",
        "text": "Myanmar",
    },
    {
        "value": 144,
        "key": "NA",
        "flag": "na",
        "text": "Namibia",
    },
    {
        "value": 145,
        "key": "NR",
        "flag": "nr",
        "text": "Nauru",
    },
    {
        "value": 146,
        "key": "NP",
        "flag": "np",
        "text": "Nepal",
    },
    {
        "value": 147,
        "key": "NL",
        "flag": "nl",
        "text": "Netherlands",
    },
    {
        "value": 148,
        "key": "AN",
        "flag": "an",
        "text": "Netherlands Antilles",
    },
    {
        "value": 149,
        "key": "NC",
        "flag": "nc",
        "text": "New Caledonia",
    },
    {
        "value": 150,
        "key": "NZ",
        "flag": "nz",
        "text": "New Zealand",
    },
    {
        "value": 151,
        "key": "NI",
        "flag": "ni",
        "text": "Nicaragua",
    },
    {
        "value": 152,
        "key": "NE",
        "flag": "ne",
        "text": "Niger",
    },
    {
        "value": 153,
        "key": "NG",
        "flag": "ng",
        "text": "Nigeria",
    },
    {
        "value": 154,
        "key": "NU",
        "flag": "nu",
        "text": "Niue",
    },
    {
        "value": 155,
        "key": "KP",
        "flag": "kp",
        "text": "North Korea",
    },
    {
        "value": 156,
        "key": "MP",
        "flag": "mp",
        "text": "Northern Mariana Islands",
    },
    {
        "value": 157,
        "key": "NO",
        "flag": "no",
        "text": "Norway",
    },
    {
        "value": 158,
        "key": "OM",
        "flag": "om",
        "text": "Oman",
    },
    {
        "value": 159,
        "key": "PK",
        "flag": "pk",
        "text": "Pakistan",
    },
    {
        "value": 160,
        "key": "PW",
        "flag": "pw",
        "text": "Palau",
    },
    {
        "value": 161,
        "key": "PS",
        "flag": "ps",
        "text": "Palestine",
    },
    {
        "value": 162,
        "key": "PA",
        "flag": "pa",
        "text": "Panama",
    },
    {
        "value": 163,
        "key": "PG",
        "flag": "pg",
        "text": "Papua New Guinea",
    },
    {
        "value": 164,
        "key": "PY",
        "flag": "py",
        "text": "Paraguay",
    },
    {
        "value": 165,
        "key": "PE",
        "flag": "pe",
        "text": "Peru",
    },
    {
        "value": 166,
        "key": "PH",
        "flag": "ph",
        "text": "Philippines",
    },
    {
        "value": 167,
        "key": "PN",
        "flag": "pn",
        "text": "Pitcairn",
    },
    {
        "value": 168,
        "key": "PL",
        "flag": "pl",
        "text": "Poland",
    },
    {
        "value": 169,
        "key": "PT",
        "flag": "pt",
        "text": "Portugal",
    },
    {
        "value": 170,
        "key": "PR",
        "flag": "pr",
        "text": "Puerto Rico",
    },
    {
        "value": 171,
        "key": "QA",
        "flag": "qa",
        "text": "Qatar",
    },
    {
        "value": 172,
        "key": "CG",
        "flag": "cg",
        "text": "Republic of the Congo",
    },
    {
        "value": 173,
        "key": "RE",
        "flag": "re",
        "text": "Reunion",
    },
    {
        "value": 174,
        "key": "RO",
        "flag": "ro",
        "text": "Romania",
    },
    {
        "value": 175,
        "key": "RU",
        "flag": "ru",
        "text": "Russia",
    },
    {
        "value": 176,
        "key": "RW",
        "flag": "rw",
        "text": "Rwanda",
    },
    {
        "value": 177,
        "key": "BL",
        // "flag": "bl",
        "text": "Saint Barthelemy",
    },
    {
        "value": 178,
        "key": "SH",
        "flag": "sh",
        "text": "Saint Helena",
    },
    {
        "value": 179,
        "key": "KN",
        "flag": "kn",
        "text": "Saint Kitts and Nevis",
    },
    {
        "value": 180,
        "key": "LC",
        "flag": "lc",
        "text": "Saint Lucia",
    },
    {
        "value": 181,
        "key": "MF",
        // "flag": "mf",
        "text": "Saint Martin",
    },
    {
        "value": 182,
        "key": "PM",
        "flag": "pm",
        "text": "Saint Pierre and Miquelon",
    },
    {
        "value": 183,
        "key": "VC",
        "flag": "vc",
        "text": "Saint Vincent and the Grenadines",
    },
    {
        "value": 184,
        "key": "WS",
        "flag": "ws",
        "text": "Samoa",
    },
    {
        "value": 185,
        "key": "SM",
        "flag": "sm",
        "text": "San Marino",
    },
    {
        "value": 186,
        "key": "ST",
        "flag": "st",
        "text": "Sao Tome and Principe",
    },
    {
        "value": 187,
        "key": "SA",
        "flag": "sa",
        "text": "Saudi Arabia",
    },
    {
        "value": 188,
        "key": "SN",
        "flag": "sn",
        "text": "Senegal",
    },
    {
        "value": 189,
        "key": "RS",
        "flag": "rs",
        "text": "Serbia",
    },
    {
        "value": 190,
        "key": "SC",
        "flag": "sc",
        "text": "Seychelles",
    },
    {
        "value": 191,
        "key": "SL",
        "flag": "sl",
        "text": "Sierra Leone",
    },
    {
        "value": 192,
        "key": "SG",
        "flag": "sg",
        "text": "Singapore",
    },
    {
        "value": 193,
        "key": "SX",
        // "flag": "sx",
        "text": "Sint Maarten",
    },
    {
        "value": 194,
        "key": "SK",
        "flag": "sk",
        "text": "Slovakia",
    },
    {
        "value": 195,
        "key": "SI",
        "flag": "si",
        "text": "Slovenia",
    },
    {
        "value": 196,
        "key": "SB",
        "flag": "sb",
        "text": "Solomon Islands",
    },
    {
        "value": 197,
        "key": "SO",
        "flag": "so",
        "text": "Somalia",
    },
    {
        "value": 198,
        "key": "ZA",
        "flag": "za",
        "text": "South Africa",
    },
    {
        "value": 199,
        "key": "KR",
        "flag": "kr",
        "text": "South Korea",
    },
    {
        "value": 200,
        "key": "SS",
        // "flag": "ss",
        "text": "South Sudan",
    },
    {
        "value": 201,
        "key": "ES",
        "flag": "es",
        "text": "Spain",
    },
    {
        "value": 202,
        "key": "LK",
        "flag": "lk",
        "text": "Sri Lanka",
    },
    {
        "value": 203,
        "key": "SD",
        "flag": "sd",
        "text": "Sudan",
    },
    {
        "value": 204,
        "key": "SR",
        "flag": "sr",
        "text": "Suriname",
    },
    {
        "value": 205,
        "key": "SJ",
        "flag": "sj",
        "text": "Svalbard and Jan Mayen",
    },
    {
        "value": 206,
        "key": "SZ",
        "flag": "sz",
        "text": "Swaziland",
    },
    {
        "value": 207,
        "key": "SE",
        "flag": "se",
        "text": "Sweden",
    },
    {
        "value": 208,
        "key": "CH",
        "flag": "ch",
        "text": "Switzerland",
    },
    {
        "value": 209,
        "key": "SY",
        "flag": "sy",
        "text": "Syria",
    },
    {
        "value": 210,
        "key": "TW",
        "flag": "tw",
        "text": "Taiwan",
    },
    {
        "value": 211,
        "key": "TJ",
        "flag": "tj",
        "text": "Tajikistan",
    },
    {
        "value": 212,
        "key": "TZ",
        "flag": "tz",
        "text": "Tanzania",
    },
    {
        "value": 213,
        "key": "TH",
        "flag": "th",
        "text": "Thailand",
    },
    {
        "value": 214,
        "key": "TG",
        "flag": "tg",
        "text": "Togo",
    },
    {
        "value": 215,
        "key": "TK",
        "flag": "tk",
        "text": "Tokelau",
    },
    {
        "value": 216,
        "key": "TO",
        "flag": "to",
        "text": "Tonga",
    },
    {
        "value": 217,
        "key": "TT",
        "flag": "tt",
        "text": "Trinidad and Tobago",
    },
    {
        "value": 218,
        "key": "TN",
        "flag": "tn",
        "text": "Tunisia",
    },
    {
        "value": 219,
        "key": "TR",
        "flag": "tr",
        "text": "Turkey",
    },
    {
        "value": 220,
        "key": "TM",
        "flag": "tm",
        "text": "Turkmenistan",
    },
    {
        "value": 221,
        "key": "TC",
        "flag": "tc",
        "text": "Turks and Caicos Islands",
    },
    {
        "value": 222,
        "key": "TV",
        "flag": "tv",
        "text": "Tuvalu",
    },
    {
        "value": 223,
        "key": "VI",
        "flag": "vi",
        "text": "U.S. Virgin Islands",
    },
    {
        "value": 224,
        "key": "UG",
        "flag": "ug",
        "text": "Uganda",
    },
    {
        "value": 225,
        "key": "UA",
        "flag": "ua",
        "text": "Ukraine",
    },
    {
        "value": 226,
        "key": "AE",
        "flag": "ae",
        "text": "United Arab Emirates",
    },
    {
        "value": 227,
        "key": "GB",
        "flag": "gb",
        "text": "United Kingdom",
    },
    {
        "value": 228,
        "key": "US",
        "flag": "us",
        "text": "United States",
    },
    {
        "value": 229,
        "key": "UY",
        "flag": "uy",
        "text": "Uruguay",
    },
    {
        "value": 230,
        "key": "UZ",
        "flag": "uz",
        "text": "Uzbekistan",
    },
    {
        "value": 231,
        "key": "VU",
        "flag": "vu",
        "text": "Vanuatu",
    },
    {
        "value": 232,
        "key": "VA",
        "flag": "va",
        "text": "Vatican",
    },
    {
        "value": 233,
        "key": "VE",
        "flag": "ve",
        "text": "Venezuela",
    },
    {
        "value": 234,
        "key": "VN",
        "flag": "vn",
        "text": "Vietnam",
    },
    {
        "value": 235,
        "key": "WF",
        "flag": "wf",
        "text": "Wallis and Futuna",
    },
    {
        "value": 236,
        "key": "EH",
        "flag": "eh",
        "text": "Western Sahara",
    },
    {
        "value": 237,
        "key": "YE",
        "flag": "ye",
        "text": "Yemen",
    },
    {
        "value": 238,
        "key": "ZM",
        "flag": "zm",
        "text": "Zambia",
    },
    {
        "value": 239,
        "key": "ZW",
        "flag": "zw",
        "text": "Zimbabwe",
    }];