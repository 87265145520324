import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { Category } from "../../interfaces/search";

interface IProps {
  category: Category;
  onCategoryClick: (categoryId: string) => void
  scroll?: () => void;
}
const CategorySliderItem: React.FC<IProps> = ({
  category,
  onCategoryClick,
  scroll
}) => {
  const context = useContext(RootStoreContext);
  const { setCategoryClickEvent } = context.analyticsStore;
  const handleCategoryClick = (categoryId: string) => {
    setCategoryClickEvent(categoryId);
    onCategoryClick(categoryId);
  }
  // useSliderHook(category.id, setCategoryClickEvent, onCategoryClick, scroll)
  return (
    <div
      id={category.id}
      key={category.id}
      onClick={() => handleCategoryClick(category.id)}
    >
      <div className="horizontalListItemCaption">{category.description}</div>

    </div>
  )
}
export default observer(CategorySliderItem);