import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { IBoxes, } from '../../../interfaces/order'
import { Button, Form } from 'semantic-ui-react'
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import NumericInput from '../../../form/NumericInput'
import ErrorMessage from '../../../form/ErrorMessage'
import { RootStoreContext } from '../../../stores/RootStoreContext';

interface IProps {
    initialValues?: IBoxes
    submit: (openModal: (...args: any) => void, closeModal: () => void, values: IBoxes) => Promise<void>
}

const AskForBoxes: React.FC<IProps> = ({ submit, initialValues }) => {
    const context = useContext(RootStoreContext);
    const { openModal, closeModal } = context.modalStore;

    return (
        <FinalForm
            initialValues={initialValues ?? {
                chill: 0,
                dry: undefined,
                frozen: 0
            }}
            onSubmit={(values: IBoxes) =>
                submit(openModal, closeModal, values)
                    .catch((error) => ({
                        [FORM_ERROR]: error,
                    }))
            }
            render={({
                handleSubmit,
                submitting,
                invalid,
                dirtySinceLastSubmit,
                submitError,
            }) => (
                <Form onSubmit={handleSubmit} error className="attached fluid">
                    <Form.Field>
                        <label style={{ fontSize: "13px", textAlign: "left", color: "Blue" }}>
                            {"Dry"}
                        </label>
                        <Field
                            name="dry"
                            component={NumericInput}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label style={{ fontSize: "13px", textAlign: "left", color: "Blue" }}>
                            {"Chill"}
                        </label>
                        <Field
                            name="chill"
                            component={NumericInput}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label style={{ fontSize: "13px", textAlign: "left", color: "Blue" }}>
                            {"Frozen"}
                        </label>
                        <Field
                            name="frozen"
                            component={NumericInput}
                        />
                    </Form.Field>

                    {submitError && !dirtySinceLastSubmit && (
                        <ErrorMessage error={submitError} />
                    )}

                    <div style={{ marginTop: "7px", textAlign: "center" }}>
                        <Button
                            disabled={invalid && !dirtySinceLastSubmit}
                            loading={submitting}
                            color="blue"
                            content={"Save"}
                        />
                    </div>
                </Form>
            )}
        />
    )
}


export default observer(AskForBoxes)