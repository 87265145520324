import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Divider, Header, Icon, Menu, Table } from "semantic-ui-react";
import ShopLocationDetailWidget from "./ShopLocationDetailWidget";
import { NoResults } from "../../../common/NoResults";

interface IProps {
    shopId: string;
}

const LocationsManagementWidget: React.FC<IProps> = ({ shopId }) => {
    const context = useContext(RootStoreContext);
    const { openModal } = context.modalStore;
    const { isMobile } = context.deviceStore;
    const {
        loadData,
        dispose,
        loading,
        oneTimeLoad,
        hasMore,
        items,
    } = context.locationsManagementStore;

    useEffect(() => {
        oneTimeLoad(shopId)
        loadData();
        return () => {
            dispose();
        };
    }, [loadData, oneTimeLoad, dispose, shopId]);

    return (
        <div>
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "5px 0" }}
            >
                <Menu.Menu position="right">
                    <Menu.Item>
                        <Button
                            basic
                            color="blue"
                            onClick={() => {
                                openModal(<ShopLocationDetailWidget />, "large", "", false, false);
                            }}
                        >
                            Add
                        </Button>
                    </Menu.Item>
                </Menu.Menu>

            </Menu>
            <Divider
                style={isMobile ? { padding: "0", margin: "0" } : undefined}
            />

            <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={items.length}
                next={() => loadData()}
                hasMore={hasMore}
                loader={loading ? <h4>Loading...</h4> : <div />}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        {items.length > 0 ? (
                            <b></b>
                        ) : (
                            <NoResults />
                        )}
                    </p>
                }>
                <Table basic="very" celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Location</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" >Auto Sync</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" >Delivery</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items.map((x) => (
                            <Table.Row key={x.id}>
                                <Table.Cell>
                                    <Header>
                                        {x.title}
                                        <Header.Subheader>{x.address}</Header.Subheader>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    <Icon
                                        name="refresh"
                                        color={x.autoSync ? "green" : "red"}
                                        size="large" />
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    <Icon
                                        name="motorcycle"
                                        color={x.hasDelivery ? "green" : "red"}
                                        size="large" />
                                </Table.Cell>
                                <Table.Cell>
                                    <Icon
                                        inverted
                                        name="pencil"
                                        color="blue"
                                        circular
                                        onClick={() => {
                                            openModal(<ShopLocationDetailWidget locationId={x.id} />, "large", "", false, false);
                                        }} />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </InfiniteScroll>
        </div>
    )
}

export default observer(LocationsManagementWidget)