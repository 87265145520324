export const ProductReviewOptions: KeyValuePair[] = [
    {
        key: "compartment",
        value: "compartment",
        text: "compartment",
    },
    {
        key: "category",
        value: "category",
        text: "category",
    },
    {
        key: "weight",
        value: "weight",
        text: "weight",
    },
    {
        key: "brand",
        value: "brand",
        text: "brand",
    },
    {
        key: "sku",
        value: "sku",
        text: "sku",
    },
    {
        key: "labels",
        value: "labels",
        text: "labels",
    },
    {
        key: "size",
        value: "size",
        text: "size",
    },
]

interface KeyValuePair {
    key: string;
    value: string;
    text: string;
}