import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { Dimmer, Divider, Header, Icon, Loader, Segment } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { format } from 'date-fns';

const ClientOrdersToSend = () => {
    const context = useContext(RootStoreContext);
    const {
        loadClientOrders,
        loadingClientOrders,
        proposedHubOrders,
        clientOrders,
        deliverySlots
    } = context.hubOrdersStore;

    useEffect(() => {
        loadClientOrders();

        return () => { };
    }, [loadClientOrders]);

    return (
        <Segment basic>
            <div style={{ textAlign: "center" }}>
                <Header textAlign="center" color="blue">
                    <div style={{ position: "absolute", top: "15px", left: "40px" }}>
                        <Icon
                            onClick={loadClientOrders}
                            className='clickable'
                            title="Reload"
                            name={'refresh'}
                            color='blue'
                            size='large'
                        />
                    </div>
                    Orders for processing
                    {clientOrders && <Header.Subheader>
                        {`${clientOrders.length} order(s) - ${clientOrders.slice().reduce((c, d) => c + d.totalQuantitySum, 0)} product(s)`}
                    </Header.Subheader>}
                </Header>
            </div>
            <Divider />
            {loadingClientOrders && (
                <Dimmer active inverted>
                    <Loader inverted content="Loading" />
                </Dimmer>
            )}

            {clientOrders &&
                clientOrders.map((x) => (
                    <Segment key={x.id}>
                        <div className="fxDisplay fxFixed fxJustifyBetween">
                            {proposedHubOrders.length > 0 && proposedHubOrders.filter(f => f.orderIds.filter(z => z === x.id).length === x.locations) ? <Icon
                                name="check"
                                color='green'
                            /> : <Icon
                                name="close"
                                color='red'
                            />}
                            <div className='fxDisplay'>
                                <b>{x.displayId}</b>
                            </div>
                            {x.deliverySlotId ?
                                (deliverySlots.length > 0 ? <div>{format(
                                    deliverySlots?.filter(f => f.id === x.deliverySlotId)[0]?.startsOn * 1000,
                                    "EEE do MMMM HH:mm"
                                )}</div> : <div>Loading...</div>)
                                :
                                <div>Pick up</div>}
                        </div>
                        <Divider />
                    </Segment>
                ))}
        </Segment>
    )
}


export default observer(ClientOrdersToSend)