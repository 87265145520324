


export type AdminModuleType = 'hub_orders' | 'client_orders' | 'pack_orders' |
    'product_management' | 'marketing_management' | 'shop_management' | 'config_management' |
    'user_management' | 'reports' | 'ai_management';

export const AdminStatusOptions: Array<{
    value: AdminModuleType,
    key: AdminModuleType,
    text: string,
    description?: string,
}> =
    [
        {
            "value": "hub_orders",
            "key": "hub_orders",
            "text": "Hub Orders",
        },
        {
            "value": "client_orders",
            "key": "client_orders",
            "text": "Client Orders",
        },
        {
            "value": "pack_orders",
            "key": "pack_orders",
            "text": "Pack Orders",
        },
        {
            "value": "product_management",
            "key": "product_management",
            "text": "Product Management",
        },
        {
            "value": "marketing_management",
            "key": "marketing_management",
            "text": "Marketing Management",
        },
        {
            "value": "shop_management",
            "key": "shop_management",
            "text": "Shop Management",
        },
        {
            "value": "config_management",
            "key": "config_management",
            "text": "Config Management",
        },
        {
            "value": "user_management",
            "key": "user_management",
            "text": "User Management",
        },
        {
            "value": "reports",
            "key": "reports",
            "text": "Reports",
        },
        {
            "value": "ai_management",
            "key": "ai_management",
            "text": "AI Management",
        },
    ]