import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import agent from '../../../api/agent';
import { FORM_ERROR } from "final-form";
import { Form as FinalForm, Field } from "react-final-form";
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from "final-form-arrays";
import { Button, Form, Icon, Table } from 'semantic-ui-react';
import ErrorMessage from '../../../form/ErrorMessage';
import SelectInput from '../../../form/SelectInput';


interface IProps {
    shopId: string;
}

const OtherShopAccessWidget: React.FC<IProps> = ({ shopId }) => {
    const context = useContext(RootStoreContext);
    const { markets } = context.cacheStore;

    const [accessibleShops, setAccessibleShops] = useState<string[]>([]);

    const load = () => {
        agent.Shop.Manage.get_access(shopId).then((response) => setAccessibleShops(response));
    }

    useEffect(() => {
        load()

        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <FinalForm
            onSubmit={(values: any) =>
                agent.Shop.Manage.set_access(shopId, values)
                    .catch((error) => ({
                        [FORM_ERROR]: error,
                    }))
            }
            mutators={{
                ...arrayMutators,
            }}
            initialValues={{
                'other_shops': accessibleShops
            }}
            render={({
                handleSubmit,
                submitError,
                dirtySinceLastSubmit,
                submitting,
                invalid,
                values,
                form: {
                    mutators: { push, remove },
                },
            }) => (
                <Form onSubmit={handleSubmit} error>
                    <div >
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Shop
                                    </Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <FieldArray name="other_shops">
                                    {({ fields }) =>
                                        fields.map((name, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    <Field
                                                        name={`${name}`}
                                                        component={SelectInput}
                                                        options={markets.filter(f => values?.other_shops[index] === f.type_id || !values?.other_shops.includes(f.type_id)).map(x => ({
                                                            key: x.type_id,
                                                            value: x.type_id,
                                                            text: x.title
                                                        }))}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell >
                                                    <Button
                                                        color="red"
                                                        icon="minus"
                                                        circular
                                                        size="mini"
                                                        onClick={(event: SyntheticEvent) => {
                                                            remove("other_shops", index);
                                                            event.preventDefault();
                                                        }}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </FieldArray>
                            </Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="9">
                                        <Button
                                            floated="right"
                                            basic
                                            color="blue"
                                            onClick={(event: SyntheticEvent) => {
                                                push("other_shops", {});
                                                event.preventDefault();
                                            }}
                                        >
                                            <Icon name="add" />{" "}
                                            Add
                                        </Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>

                    </div>

                    {submitError && !dirtySinceLastSubmit && (
                        <ErrorMessage error={submitError} />
                    )}
                    <div style={{ textAlign: "center" }}>
                        <Button.Group style={{ paddingTop: "15px" }}>
                            <Button
                                color="blue"
                                disabled={(invalid)}
                                loading={submitting}
                                content={"Save"}
                            />
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    )
}

export default observer(OtherShopAccessWidget);