import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { Button, Card, Divider, Header } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";

const OnlineShopLandingV2 = () => {
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { setPage, setEventCustom } = context.analyticsStore;
  const { isMobile } = context.deviceStore;

  useEffect(() => {
    window.scrollTo(0, 0);
    setPage("OnlineShopLanding", window.location.pathname);

    return () => {};
  }, [setPage]);

  return (
    <div>
      <Header
        style={{
          textAlign: "center",
          marginTop: "3px",
          textTransform: "capitalize",
          fontWeight: "200",
        }}
        size="huge"
        // color="blue"
        content="We offer various ways to collaborate! "
      />
      <div style={{ textAlign: "center" }}>
        <p
          style={{
            maxWidth: "950px",
            fontSize: "17px",
            margin: "auto",
            padding: isMobile ? "15px" : "",
          }}
        >
          Whether you don't own a website or you have a website but want to
          increase your sales, we have a solution for you. You can join Cheap
          Trolley and reach all of our users. The better the price you offer,
          the more visibility you will gain on our website.
        </p>
      </div>

      <Divider hidden />
      <Card.Group centered>
        <Card>
          <img
            alt="Open a new show"
            src="./newWebsite.jpg"
            style={{ height: "190px" }}
          />
          <Card.Content>
            <Card.Header>Open a new shop</Card.Header>

            <Card.Description>
              This option is for shops that do not currently have a website. By
              using Cheap Trolley, you can publish your online shop and upload
              your catalogue for anyone to see.
            </Card.Description>
          </Card.Content>
          <Card.Content extra style={{ textAlign: "center" }}>
            <Button
              color="green"
              content="Open my e-shop!"
              onClick={() => {
                setEventCustom("open_shop");

                navigate("/my-shop");
              }}
            />
          </Card.Content>
        </Card>
        <Card>
          <img
            alt="Claim an existing shop"
            src="./claimWebsite.jpg"
            style={{ height: "190px" }}
          />
          <Card.Content>
            <Card.Header>Claim an existing shop</Card.Header>

            <Card.Description>
              This option is for shops that we have currently integrated with
              (see About page). If you own a shop and want to start receiving
              orders through Cheap Trolley, we welcome you to become a partner.
            </Card.Description>
          </Card.Content>
          <Card.Content extra style={{ textAlign: "center" }}>
            <Button
              color="blue"
              content="Become a partner!"
              onClick={() => {
                setEventCustom("become_partner");

                navigate("/claim-shop");
              }}
            />
          </Card.Content>
        </Card>
        <Card>
          <img
            alt="Join Cheap Trolley"
            src="./includeWebsite.jpg"
            style={{ height: "190px" }}
          />
          <Card.Content>
            <Card.Header>Join Cheap Trolley</Card.Header>

            <Card.Description>
              This option is for shops that currently have a website and want to
              start receiving orders through Cheap Trolley. We will integrate
              with your website so that you can reach all of our customers.
            </Card.Description>
          </Card.Content>
          <Card.Content extra style={{ textAlign: "center" }}>
            <Button
              color="teal"
              content="Include my shop!"
              onClick={() => {
                setEventCustom("include_my_shop");

                navigate("/request-integration");
              }}
            />
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

export default observer(OnlineShopLandingV2);
