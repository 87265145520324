import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { IBrand } from "../../../interfaces/brand";


export class BrandAdminStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable originalId?: string = undefined;
    @observable targetId?: string = undefined;
    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable brands: IBrand[] = []
    @observable searchText: string = "";

    @action setOriginalId = (value?: string) => this.originalId = value;
    @action setTargetId = (value?: string) => this.targetId = value;
    @action setLoading = (value: boolean) => this.loading = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetBrands = (value: IBrand[]) => this.brands = value;
    @action setBrands = (value: IBrand[]) => {
        var existing = this.brands.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.brands.push(...missing);
    };


    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Brands.Admin.get(this.searchText, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setBrands(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action updateComplete = (brand: IBrand) => {
        var idx = this.brands.findIndex(f => f.id === brand.id);
        this.brands[idx].title = brand.title;
        this.brands[idx].tags = brand.tags;
        this.brands[idx].imageUrl = brand.imageUrl;
    }

    @action deleteBrand = async (brandId: string) => {
        try {
            if (!brandId) return;

            await agent.Brands.Admin.delete(brandId);

            this.resetBrands(this.brands.filter(f => f.id !== brandId))
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
        }

    };

    @action mergeBrands = async (targetId: string) => {
        try {
            this.targetId = targetId;

            if (!this.originalId || !this.targetId) return;

            await agent.Brands.Admin.merge(this.originalId, this.targetId);

            this.resetBrands(this.brands.filter(f => f.id !== this.originalId))
            this.setOriginalId(undefined);
            this.setTargetId(undefined);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
        }

    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetBrands([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action getProductSample = async (brandId?: string) => {
        try {
            if (!brandId) return;

            return await agent.Brands.Admin.get_sample(brandId);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
        }

    };

    @action dispose = () => {
        this.searchText = ""
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetBrands([]);
        this.setHasMore(false);
    }
}