import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Header, Icon } from 'semantic-ui-react'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { FoodCategories, FoodTypes } from '../../../options/diets';

const FoodExclution = () => {
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const {
        profile,
        nextStep,
        previousStep,
        categoryClick,
        footTypeClick
    } = context.userDietProfileStore;

    return (
        <div
            className='fxFixed fxDisplay fxAlignCenter fxStretch fxDirCol'
            style={{ marginTop: "55px" }}>
            <Header
                size='large'
                textAlign='left'
                content="Should you avoid any foods?"
                subheader="Select any items you should not consume due to allergies or preferences!"
                style={isMobile ? { padding: "15px" } : {
                    marginBottom: "25px"
                }}
            />

            <div style={isMobile ? { padding: "10px" } : { maxWidth: "550px" }}>
                {
                    FoodCategories.map((fcat) =>
                        <div key={fcat.type} style={{ marginTop: "15px" }}>
                            <Header content={`${fcat.title}`} />

                            <div className='fxDisplay fxWrap' style={{ maxWidth: "550px" }}>
                                {
                                    FoodTypes
                                        .filter(f => f.categories.includes(fcat.type))
                                        .map((ftype) =>
                                            ftype.is_all ?
                                                <div
                                                    key={ftype.type}
                                                    className={`clickable diet-food-item-box ${profile?.avoid?.filter(f => FoodTypes.filter(f => f.categories.includes(ftype.related_type ?? fcat.type) && !f.is_all).map(x => x.type).includes(f)).length === FoodTypes.filter(f => f.categories.includes(ftype.related_type ?? fcat.type) && !f.is_all).length ? "selected" : ""}`}
                                                    onClick={() => {
                                                        if (fcat.type !== "common")
                                                            categoryClick(fcat.type);
                                                        else
                                                            categoryClick(ftype.categories[0]);
                                                    }}
                                                >
                                                    {fcat.type === "common" ? ftype.title : "All"}
                                                </div> :
                                                <div
                                                    key={ftype.type}
                                                    className={`clickable diet-food-item-box ${profile?.avoid?.includes(ftype.type) ? "selected" : ""}`}
                                                    onClick={() => {
                                                        if (fcat.type === "common" && ftype.is_all) {
                                                            categoryClick(ftype.categories[0])
                                                        }
                                                        else {
                                                            footTypeClick(ftype.type)
                                                        }
                                                    }}
                                                >
                                                    {FoodTypes.filter(f => f.categories.includes(fcat.type)).length === 1 ? "All" : ftype.title}
                                                </div>)
                                }
                            </div>
                        </div>
                    )
                }

            </div>


            <div className='fxFixed fxDisplay fxJustifyBetween'
                style={isMobile ? {
                    marginBottom: "15px",
                    width: "100%",
                    paddingLeft: "10px",
                    paddingRight: "10px"
                } : {
                    width: "550px",
                    marginBottom: "15px"
                }}>
                <div
                    className={`diet-continue-button clickable`}
                    style={{ marginTop: "22px", }}
                    onClick={() => {
                        previousStep()
                    }}
                >
                    Back
                    <Icon name='angle left' />
                </div>
                <div
                    className={`diet-continue-button clickable`}
                    style={{ marginTop: "22px", }}
                    onClick={() => {
                        nextStep()
                    }}
                >
                    <Icon name='angle right' />
                    Continue
                </div>
            </div>
        </div>
    )
}

export default observer(FoodExclution)