import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { ICountry } from "../../../interfaces/country";

class CountriesConfig {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable searchText: string = "";
    @observable currentPage: number = 0;
    @observable countries: ICountry[] = [];
    @observable hasMore: boolean = false;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetCountries = (value: ICountry[]) => this.countries = value;
    @action setCountries = (value: ICountry[]) => {
        var existing = this.countries.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.countries.push(...missing);
    };

    @action load = async () => {
        this.loading = true;

        try {
            var response = await agent.GlobalConfig.Countries.get(this.searchText, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setCountries(response.Items);
        } catch (error) {
            console.log(error);
        } finally {
            this.setLoading(false);
        }
    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetCountries([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
        }
    };

    @action dispose = () => {
        this.resetCountries([]);
        this.setCurrentPage(0);
        this.setLoading(false);
        this.searchText = "";
    }
}


export const CountriesStore = new CountriesConfig();