import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, List } from "semantic-ui-react";
import { IEmailTemplateForList } from "../../../interfaces/email";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import EmailTemplateDetail from "./EmailTemplateDetail";
import { formatRelative } from "date-fns";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { ButtonTypes } from "../../confirmationModal/functions/ButtonTypes";

interface IProps {
    item: IEmailTemplateForList;
}

const EmailTemplateListItem: React.FC<IProps> = ({ item }) => {
    const context = useContext(RootStoreContext);
    const { openModal } = context.modalStore;
    const { openConfirmationModal, closeConfirmationalModal } = context.confirmationModalStore;

    return (
        <List.Item>
            <List.Content floated="right" style={{ paddingBottom: "5px" }}>
                <Button onClick={() => {
                    openConfirmationModal(
                        "Confirm template deletion",
                        "Are you sure you want to delete this template?",
                        () =>
                            agent.Emails.delete(item.id).then(() => {
                                toast.info("Deleted, refresh the screen");
                                closeConfirmationalModal();
                            }),
                        "Cancel",
                        "Delete",
                        ButtonTypes.None,
                        ButtonTypes.Negative
                    );

                }} color="red">
                    Delete
                </Button>
                <Button onClick={() => {
                    openModal(<EmailTemplateDetail item={item} />, "small");
                }} color="olive">
                    Edit
                </Button>

            </List.Content>
            <List.Icon
                name="envelope outline"
                size="large"
                verticalAlign="middle"
            />
            <List.Content>
                <List.Header>{item.subject.filter(f => f.key === 'en')[0].description}</List.Header>
            </List.Content>
            <List.Description>
                {formatRelative(
                    new Date(item.createdOn * 1000),
                    new Date()
                )}
            </List.Description>
        </List.Item >
    );
};

export default observer(EmailTemplateListItem);