import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { Dimmer, Grid, Icon, List, Loader, Segment, Table } from 'semantic-ui-react';
import ShoppingCartTableRow from './components/ShoppingCartTableRow';
import CheckoutProcessWidget from './components/CheckoutProcessWidget';
import ShoppingCartListItem from './components/ShoppingCartListItem';
import { NoResults } from '../../common/NoResults';
import { ButtonTypes } from '../confirmationModal/functions/ButtonTypes';

interface IProps {
    userId?: string;
}

const ShoppingCart: React.FC<IProps> = ({ userId }) => {
    let [searchParams] = useSearchParams();
    const context = useContext(RootStoreContext);
    const { setPage } = context.analyticsStore;
    const { deliveryEnabled } = context.globalConfig;
    const { isMobile } = context.deviceStore;
    const { user, isAdmin } = context.userStore;
    const { openConfirmationModal, closeConfirmationalModal } = context.confirmationModalStore;
    const { isLoggedIn } = context.sessionStore;
    const {
        oneTimeLoad,
        loadCart,
        basketItems,
        loadingShoppingCart,
        itemsForDisplay,
        setIsVisible,
        emptyCart
    } = context.shoppingCartStore;

    useEffect(() => {
        window.scrollTo(0, 0);
        if (user)
            oneTimeLoad(searchParams)
                .then(() => loadCart(searchParams.get("userId") ?? userId).then(() => setIsVisible(true)));
        else {
            oneTimeLoad(searchParams);
        }

        setPage("ShoppingCart", window.location.pathname);

        return () => {
            setIsVisible(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneTimeLoad, loadCart, setPage, isLoggedIn, user]);

    return loadingShoppingCart ? (
        <Segment
            basic
            style={{
                minHeight: "55vh",
                boxShadow: "none",
                border: "none",
            }}
        >
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
        </Segment>
    ) : (
        <Segment basic style={{ height: "89vh", maxHeight: "89vh", overflow: "auto" }}>
            <Grid columns={2} stackable textAlign='center' verticalAlign='top'>
                <Grid.Row verticalAlign='top'>
                    <Grid.Column width={deliveryEnabled ? 11 : 16} style={isMobile ? {} : { height: "85vh", maxHeight: "89vh", overflow: "auto" }}>
                        {(basketItems?.length ?? 0) === 0 ? (
                            <div style={{ padding: "5px" }}>
                                <NoResults
                                    header='There are no products in your cart!'
                                    subheader={
                                        deliveryEnabled ?
                                            <div>Please return to the <Link className='ct-green-text' to={"/online-shop"}>online shop</Link> to select the items you' d like to purchase.</div>
                                            :
                                            <div>Your early access isn't active yet! If you've signed up, please wait as we activate accounts.
                                            </div>
                                    }
                                    subheader2={""}
                                />

                            </div>
                        ) :
                            isMobile ? <List divided>
                                {itemsForDisplay.map((item) =>
                                    <ShoppingCartListItem key={item.id} itemId={item.id} />
                                )}
                            </List> : <Table basic="very">
                                <Table.Header>
                                    <Table.HeaderCell textAlign='left'>Product</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>Quantity</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='right'>Cost</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>
                                        <Icon name={"trash alternate outline"}
                                            className='clickable'
                                            color='red'
                                            // circular={!product.active}
                                            disabled={(basketItems?.length ?? 0) === 0}
                                            onClick={() => {
                                                openConfirmationModal(
                                                    "Are you sure you to empty your cart?",
                                                    undefined,
                                                    () =>
                                                        emptyCart().then(() =>
                                                            closeConfirmationalModal()
                                                        ),
                                                    "Cancel",
                                                    "Empty",
                                                    ButtonTypes.None,
                                                    ButtonTypes.Negative
                                                );
                                            }} />
                                    </Table.HeaderCell>
                                </Table.Header>

                                {itemsForDisplay.map((item) =>
                                    <ShoppingCartTableRow key={item.id} itemId={item.id} />
                                )}
                            </Table>}
                    </Grid.Column>
                    {deliveryEnabled && !isAdmin && <Grid.Column width={5} verticalAlign='top'>
                        <CheckoutProcessWidget />
                    </Grid.Column>}
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

export default observer(ShoppingCart)
