import { formatRelative } from 'date-fns'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button, Icon, List } from 'semantic-ui-react'
import { ConstractFullName } from '../../../helpers/textHelper'
import { currencyFormat } from '../../products/functions/productHelper'
import { ISavings, IShoppingCartForAdmin } from '../../../interfaces/shoping'
import agent from '../../../api/agent'
import { useNavigate } from 'react-router'

interface IProps {
    cart: IShoppingCartForAdmin
}

const UserShoppingCartItem: React.FC<IProps> = ({ cart }) => {
    const navigate = useNavigate()

    const [savings, setSavings] = React.useState<ISavings>({ avg: 0, discount: 0, max: 0 });
    const [savingsStored, setSavingStored] = React.useState(false);

    const calculateSavings = (userId: string) => {
        agent.Cart.V2.calculate_savings(userId).then((result) => {
            setSavings(result);
            setSavingStored(true);
        })
    }

    return (
        <List.Item onDoubleClick={() => { navigate(`/user-cart?userId=${cart.userId}`) }}>
            <List.Content floated="right">
                {!savingsStored && <Button content={"Calculate"} color='orange' onClick={() => calculateSavings(cart.userId)} />}
                {savingsStored &&
                    <div className='fxDisplay fxFixed fxJustifyEven' style={{ fontSize: "14px", fontWeight: "600", textAlign: "center" }}>
                        <div style={{ paddingLeft: "2px", paddingRight: "2px", width: "50px" }}>
                            <div>
                                {currencyFormat(savings.avg ?? 0)}
                            </div>
                            <span style={{ fontWeight: "100", fontSize: "12px" }}>Avg</span>
                        </div>
                        <div style={{ paddingLeft: "4px", paddingRight: "4px", width: "50px" }}>
                            <div>
                                {currencyFormat(savings.max ?? 0)}
                            </div>
                            <span style={{ fontWeight: "100", fontSize: "12px" }}>Max</span>
                        </div>
                        <div style={{ paddingLeft: "4px", paddingRight: "4px", color: "#eb700b", width: "60px" }}>
                            <div>
                                {currencyFormat(savings.total ?? 0)}
                            </div>
                            <span style={{ fontWeight: "100", fontSize: "12px" }}>Total</span>
                        </div>
                        <div style={{ paddingLeft: "2px", paddingRight: "2px", color: "green", width: "60px" }}>
                            <div>
                                {currencyFormat(savings.profit ?? 0)}
                            </div>
                            <span style={{ fontWeight: "100", fontSize: "12px" }}>Profit</span>
                        </div>
                        <div style={{ paddingLeft: "2px", paddingRight: "2px", color: "red", width: "50px" }}>
                            <div>
                                {currencyFormat(savings.discount ?? 0)}
                            </div>
                            <span style={{ fontWeight: "100", fontSize: "12px" }}>Discount</span>
                        </div>


                    </div>}
            </List.Content>
            <List.Icon
                name="user circle outline"
                size="large"
                verticalAlign="middle"
            />
            <List.Content>
                <List.Header>
                    {ConstractFullName(cart.user.firstName, cart.user.lastName, cart.user.middleName)} <b>{` - [${cart.countOfItems ?? 0}]`}</b> <Icon style={{ marginLeft: "7px" }} color='orange' onClick={() => window.open(`${window.location.origin}/manage-user/${cart.userId}`, '_blank', 'noopener,noreferrer')} name="external alternate" />
                </List.Header>
                <List.Description>
                    <p>
                        {cart.lastUpdatedOn && <span>
                            <b>Last Updated On :</b>{" "}
                            {formatRelative(
                                new Date(cart.lastUpdatedOn * 1000),
                                new Date()
                            )}
                        </span>}
                        <span
                            style={{
                                paddingLeft: "3px",
                            }}
                        >
                            <b>Created On :</b>{" "}
                            {formatRelative(
                                new Date(cart.createdOn * 1000),
                                new Date()
                            )}
                        </span>
                    </p>
                </List.Description>
            </List.Content>
        </List.Item>
    )
}

export default observer(UserShoppingCartItem)