import { Link } from "react-router-dom";
import { VivaEvents } from "../../../options/vivaEventId";

export function getCheckoutIssue(
  issue: string,
  minimumOrder: number,
  getShoppingCartCost: any,
  value?: any,
  onAddressClick?: () => void,
  requestUserSession?: () => void,
) {
  switch (issue) {
    case "login":
      return (
        <span>
          Kindly <span className="clickable button-as-link ct-green-text" onClick={requestUserSession} >sign in</span> to see your cart & checkout.
        </span>
      );
    case "minimumOrder":
      return (
        <span>
          {`Minimum order is ${minimumOrder.toFixed(2)} €, kindly add ${(minimumOrder - getShoppingCartCost).toFixed(2)} € to enable checkout`}
        </span>
      );
    case "timeslot":
      return (
        <span>
          Please choose a time slot from the options provided for your delivery.
        </span>
      );
    case "geolocationDelivery":
      return (
        <span>
          Currently, delivery services are not available for the chosen location. We apologize for any inconvenience this may cause. We look forward to the opportunity to serve you in the near future.
        </span>
      );
    case "email":
      return (
        <span>
          You need to verify your e-mail first. Please check your inbox (or spam) folder for instructions.
        </span>
      );
    case "phone":
      return (
        <span>
          Kindly click <Link className="clickable button-as-link blueColor" to="/my-profile">here</Link> update your phone
          number before you place the order
        </span>
      );
    case "address":
      return (
        <span>
          To arrange your delivery, we require your address. Kindly select one from the list above or enter a new address.
        </span>
      );
    case "addressLine1":
    case "geolocation":
      return (
        <span>
          The selected address is missing some important information. Kindly click <span className="clickable button-as-link blueColor" onClick={onAddressClick} >here</span> to update it.
        </span>
      );
    case "discontinued":
      return (
        <span>
          There are discontinued items in your cart. Kindly remove them to
          proceed with checkout.
        </span>
      );
    case "largeItemLimitPass":
      return (
        <span>
          There are too many large items in your basket. Kindly remove some to be able to checkout.
        </span>
      );
    case "bottleLimitPass":
      return (
        <span>
          There are too many single bottles in your basket. Kindly remove some to be able to checkout.
        </span>
      );
    case "paymentIssue":
      if (value && value > 0) {
        try {
          var event = VivaEvents[value as keyof typeof VivaEvents];
          return (
            <span>
              {`Reason : ${event.reason}`}
              <p>{event.description}</p>
            </span>
          );
        } catch (error) {
          return (
            <span>
              There was an error during the checkout. Kindly contact our support
              team to solve it or retry.
            </span>
          );
        }
      } else
        return (
          <span>
            There was an error during the checkout. Kindly contact our support
            team to solve it or retry.
          </span>
        );
    default:
      break;
  }
}
