import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IAddress } from "../../../interfaces/user";
import { RootStore } from "../../../stores/RootStoreContext";

export class UserAddressesStore {
    rootStore: RootStore;

    @observable loading: boolean = false;
    @observable addresses: IAddress[] = [];

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action setLoading = (value: boolean) => {
        this.loading = value;
    }

    @action load = async (userId: string) => {
        try {
            this.setLoading(true);

            var addresses = await agent.Users.Address.get(userId);

            runInAction(() => {
                this.addresses = addresses;
            });

        } catch (error) {
            console.log(error);
        }
        finally {
            this.setLoading(false);
        }
    }

    @action deleteAddress = async (id: string) => {
        await agent.Users.Address.delete(id)

        runInAction(() => {
            this.addresses = this.addresses.filter(f => f.id !== id);
        })
    }

    @action saveNewAddress = async (values: IAddress) => {
        console.log(values);

        if (values.id !== undefined) {
            await agent.Users.Address.update(values);

            runInAction(() => {
                var idx = this.addresses.findIndex(f => f.id === values.id);
                this.addresses[idx] = values;
            })
        }
        else {
            var id = await agent.Users.Address.add(values)

            runInAction(() => {
                values.id = id;
                this.addresses.push(values)
            })
        }

    }

    @action dispose = () => {
        this.addresses = [];
    }

}