import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IShoppingCartForAdmin } from "../../../interfaces/shoping";

class UserShoppingCarts {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable items: IShoppingCartForAdmin[] = []

    @action setLoading = (value: boolean) => this.loading = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setItems = (value: IShoppingCartForAdmin[]) => {
        var existing = this.items.map(f => f.cart_id)
        var missing = value.filter(f => existing.indexOf(f.cart_id) < 0);

        this.items.push(...missing);
    };

    load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Users.Admin.get(this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    dispose = () => {
        this.setCurrentPage(0);
        this.setLoading(false);
        this.setHasMore(true);
        this.items = [];
    }

}


export const UserShoppingCartsStore = new UserShoppingCarts();