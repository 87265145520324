import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, Fragment, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { Menu, Divider, Table, Icon, Input, Header, Button } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component'
import { DateTimePicker } from "react-widgets";
import { format, formatDistance } from 'date-fns';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { UserRequestsStore } from './functions/UserRequestsStore';
import { NoResults } from '../../common/NoResults';
import ReactJson from 'react-json-view';

interface IProps {
    userId?: string;
}

const UserRequests: React.FC<IProps> = ({ userId }) => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isAdmin } = context.userStore;
    const { isMobile } = context.deviceStore;

    const [timer, setTimer] = React.useState(0);
    const [leaderView, setLeaderView] = React.useState(false);
    const [valueFrom, setValueFrom] = React.useState<Date | null | undefined>();
    const [valueTo, setValueTo] = React.useState<Date | null | undefined>();

    const {
        items,
        leaderboard,
        hasMore,
        load,
        loadLeaderView,
        loading,
        setDateFrom,
        setDateTo,
        search,
        dateFrom,
        dispose } = UserRequestsStore;

    useEffect(() => {
        return () => {
            dispose()
        }
    }, [isAdmin, navigate, dispose, load])


    useEffect(() => {
        if (!leaderView)
            if (userId)
                search(userId);
            else
                load()
        else
            loadLeaderView();

        return () => {
        }
    }, [userId, search, leaderView, load, loadLeaderView])


    return (
        <div className="simply-paddedPage">
            {!userId && <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "0 0 5px 0" }}
            >
                {!leaderView && <Fragment>
                    <Menu.Item
                        className={!isMobile ? "no-padding" : ""}
                        style={{ flex: isMobile ? 1 : "" }}
                    >
                        <Input
                            fluid={isMobile}
                            transparent={isMobile}
                            size={isMobile ? "large" : "small"}
                            icon={
                                !isMobile ? <Icon name="search" color="green" /> : undefined
                            }
                            placeholder={"User Id/Function/Service..."}
                            input={
                                <input className={isMobile ? "" : "bluePlaceholder"} />
                            }
                            onChange={(event: ChangeEvent, data: any) => {
                                if (!search) return;
                                if (timer != null) {
                                    clearTimeout(timer);
                                    setTimer(0);
                                }

                                setTimer(
                                    setTimeout(
                                        (callback: (text: string) => void, text: string) => {
                                            callback(text);
                                            clearTimeout(timer);
                                        },
                                        500,
                                        search,
                                        data.value
                                    )
                                );
                            }}
                        />
                    </Menu.Item>
                    <Menu.Item className={"no-padding"}>
                        <DateTimePicker
                            value={valueFrom}
                            placeholder={"Date From"}
                            onChange={(date: Date | null | undefined, rawValue: string) => {
                                var dateNum = date?.getTime() ?? undefined;
                                setValueFrom(date);
                                setDateFrom((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                if (!search) return;
                                if (timer != null) {
                                    clearTimeout(timer);
                                    setTimer(0);
                                }

                                setTimer(
                                    setTimeout(
                                        (callback: () => void, text: string) => {
                                            callback();
                                            clearTimeout(timer);
                                        },
                                        1000,
                                        search
                                    )
                                );
                            }}
                            includeTime={true}
                            timePrecision={'minutes'}
                            max={new Date()}
                        />
                    </Menu.Item>
                    <Menu.Item className={"no-padding"}>
                        <DateTimePicker
                            placeholder={"Date To"}
                            value={valueTo}
                            onChange={(date: Date | null | undefined, rawValue: string) => {
                                setValueTo(date);
                                var dateNum = date?.getTime() ?? undefined;
                                setDateTo((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                if (!search) return;
                                if (timer != null) {
                                    clearTimeout(timer);
                                    setTimer(0);
                                }

                                setTimer(
                                    setTimeout(
                                        (callback: () => void, text: string) => {
                                            callback();
                                            clearTimeout(timer);
                                        },
                                        500,
                                        search
                                    )
                                );
                            }}
                            includeTime={true}
                            timePrecision={'minutes'}
                            max={new Date()}
                            min={dateFrom ? new Date(dateFrom * 1000) : undefined}
                        />
                    </Menu.Item>
                </Fragment>}
                <Menu.Item>
                    <Button
                        color="green"
                        icon='refresh'
                        basic
                        className="no-margin"
                        onClick={() => {
                            if (!leaderView)
                                search()
                            else
                                loadLeaderView();
                        }}
                    />
                </Menu.Item>
                <Menu.Item>
                    <Button
                        color="green"
                        icon='clock'
                        basic={!leaderView}
                        className="no-margin"
                        onClick={() => setLeaderView(!leaderView)}
                    />
                </Menu.Item>
            </Menu>}

            {!userId && <Divider />}
            {!leaderView && <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={items.length} //This is important field to render the next data
                next={() => load()}
                hasMore={hasMore}
                loader={loading ? <h4>Loading...</h4> : <div />}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        {items.length > 0 ? (
                            <b></b>
                        ) : (
                            <NoResults />
                        )}
                    </p>
                }
            >
                <Table celled  >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>User</Table.HeaderCell>
                            <Table.HeaderCell>Service</Table.HeaderCell>
                            <Table.HeaderCell>Route</Table.HeaderCell>
                            <Table.HeaderCell>Function</Table.HeaderCell>
                            <Table.HeaderCell>Method</Table.HeaderCell>
                            <Table.HeaderCell>Params</Table.HeaderCell>
                            <Table.HeaderCell>Body</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items.map((x) => {
                            return (
                                <Table.Row key={x.id}>
                                    <Table.Cell>
                                        {formatDistance(new Date(x.createdOn * 1000), new Date())} ago
                                        <p>{(x.createdOn ?? 0) > 0 ? format(new Date(x.createdOn! * 1000), "dd/MM/yyyy HH:mm") : "-"}</p>
                                    </Table.Cell>
                                    <Table.Cell style={{
                                        color: "green",
                                        textDecoration: "underline"
                                    }} className='clickable' onClick={() =>
                                        window.open(`${window.location.origin}/manage-user/${x.userId}`, '_blank', 'noopener,noreferrer')}>
                                        <Header>
                                            {`${x.name} ${x.lastName}`}
                                            <Header.Subheader>
                                                {x.userId}
                                            </Header.Subheader>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{x.service}</Table.Cell>
                                    <Table.Cell>{x.route ?? "-"}</Table.Cell>
                                    <Table.Cell>{x.function}</Table.Cell>
                                    <Table.Cell>{x.method ?? "-"}</Table.Cell>
                                    <Table.Cell>{x.parameters && <ReactJson
                                        src={x.parameters ?? {}}
                                    />}</Table.Cell>
                                    <Table.Cell> {x.body && <ReactJson
                                        theme={"solarized"}
                                        src={x.body ?? {}}
                                    />}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </InfiniteScroll>}
            {leaderView && <Table celled  >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>User</Table.HeaderCell>
                        <Table.HeaderCell>Count</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {leaderboard.map((x) => {
                        return (
                            <Table.Row key={x.id}>
                                <Table.Cell>
                                    {formatDistance(new Date(x.lastRequest * 1000), new Date())} ago
                                    <p>{(x.lastRequest ?? 0) > 0 ? format(new Date(x.lastRequest! * 1000), "dd/MM/yyyy HH:mm") : "-"}</p>
                                </Table.Cell>
                                <Table.Cell style={{
                                    color: "green",
                                    textDecoration: "underline"
                                }} className='clickable' onClick={() =>
                                    window.open(`${window.location.origin}/manage-user/${x.id}`, '_blank', 'noopener,noreferrer')}>
                                    <Header>
                                        {`${x.name} ${x.lastName}`}
                                        <Header.Subheader>
                                            {x.id}
                                        </Header.Subheader>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {x.totalToday.toFixed(2)}
                                </Table.Cell>

                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>}
        </div >
    )
}

export default observer(UserRequests)