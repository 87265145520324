import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { ProductCategory } from "../../../interfaces/categories";
import { IMeasurement } from "../../../interfaces/measurements";
import { IProductDetail, IProductForAdminUpdate } from "../../../interfaces/product";
import { RootStore } from "../../../stores/RootStoreContext";

export class UnverifiedStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }
    @observable loading: boolean = false;
    @observable productId?: string = undefined;
    @observable discontinued: boolean = false;
    @observable unverifiedOnly: boolean = true;
    @observable count: number = 0;
    @observable product?: IProductDetail;
    @observable searchText: string = "";
    @observable selectedMarketIds: string[] = [];
    @observable selectedCategoryIds: string[] = [];
    @observable selectedReviewIds: string[] = [];
    @observable categories: ProductCategory[] = [];
    @observable measurements: IMeasurement[] = [];

    @action setLoading = (value: boolean) => this.loading = value;
    @action setDiscontinued = (value: boolean) => this.discontinued = value;
    @action setUnverified = (value: boolean) => this.unverifiedOnly = value;
    @action setCount = (value: number) => this.count = value;
    @action setProductId = (value?: string) => this.productId = value;
    @action setProduct = (value?: any) => this.product = value;
    @action setSelectedMarketIds = (value: string[]) => this.selectedMarketIds = value;
    @action setSelectedCategoryIds = (value: string[]) => this.selectedCategoryIds = value;
    @action setSelectedReviewIds = (value: string[]) => this.selectedReviewIds = value;
    @action setCategories = (value: ProductCategory[]) => this.categories = value.sort((n1, n2) => n1.order - n2.order);
    @action setMeasurements = (value: IMeasurement[]) => this.measurements = value;

    @action refreshCategories = async () => agent.ProductCategories.getAll().then(value => this.setCategories(value));
    @action refreshMeasurements = async () => agent.ProductMeasurements.get().then(value => this.setMeasurements(value));


    @action loadNext = async (id?: string) => {
        try {
            this.setLoading(true);
            this.setProductId(id);

            if (this.categories.length === 0)
                agent.ProductCategories.getAll().then(value => this.setCategories(value));
            if (this.measurements.length === 0)
                agent.ProductMeasurements.get().then(value => this.setMeasurements(value));

            if (!id)
                agent.Products.getUnverifiedCount(!this.unverifiedOnly, this.selectedCategoryIds, this.selectedMarketIds, this.selectedReviewIds, this.discontinued).then((count) => this.setCount(count))

            var response = await agent.Products.unverifiedNext(!this.unverifiedOnly, this.searchText, this.selectedCategoryIds, this.selectedMarketIds, this.selectedReviewIds, this.discontinued, id);

            this.setProduct(response);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.loadNext();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action save = async (updProduct: IProductForAdminUpdate) => {
        if (!this.product) return;

        await agent.Products.save(this.product?.id, updProduct)

        if (this.product)
            this.rootStore.modalStore.closeModal();

        return this.loadNext();
    }


    @action dispose = () => {
        this.setLoading(false);
        this.setProduct(undefined);
        this.setUnverified(true);
        this.setProductId(undefined);
        this.searchText = "";

    }
}