import React, { useContext, useEffect } from 'react'
import { IAdvertFilter, IAdvertPanel } from '../../../interfaces/advert'
import { Icon } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';

interface IProps {
  items: IAdvertPanel[];
  onClick: (filter: IAdvertFilter) => void;
}

export const AdvertPanel: React.FC<IProps> = ({ items, onClick }) => {
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isVisible, setIsVisible] = React.useState(true);
  const [isOn, setOn] = React.useState(false);

  // Automatically change the image every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 10000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [currentIndex]);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleImageClick = () => {
    onClick(items[currentIndex].filter);
  };

  if (!isVisible) {
    return null; // Do not render anything if the panel is hidden
  }

  return (
    <div className='ad-panel-container' onMouseEnter={() => setOn(true)} onMouseLeave={() => setOn(false)}>
      {isOn && <div className='ad-panel-nav close'>
        <Icon
          name='close'
          size='large'
          onClick={() => setIsVisible(false)}
        />
      </div>}
      <div style={styles.carousel}>
        {isOn && <div className='ad-panel-nav left'>
          <Icon
            name='angle left'
            size='large'
            onClick={prevImage}
          />
        </div>}

        <div style={styles.imageContainer} onClick={handleImageClick}>
          <img
            src={isMobile ? items[currentIndex].mobileImageUrl : items[currentIndex].imageUrl}
            alt="promotional"
            style={styles.image}
          />
        </div>
        {isOn && <div className='ad-panel-nav right'>
          <Icon
            name='angle right'
            size='large'
            onClick={nextImage}
          />
        </div>}
      </div>
    </div >
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  carousel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageContainer: {
    flex: 1,
    textAlign: "center",
    cursor: "pointer",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    display: "block",
    margin: "0 auto",
  },
};
