import React, { useState, useEffect, useRef, Fragment } from "react";
import { CSVLink } from "react-csv";
import { Icon, SemanticCOLORS } from "semantic-ui-react";

interface IProps {
    disabled: boolean;
    size?: any;
    onClick: () => Promise<any>;
    content?: any;
    loading?: boolean;
    basic?: boolean;
    color?:
    | SemanticCOLORS
    | "facebook"
    | "google plus"
    | "vk"
    | "twitter"
    | "linkedin"
    | "instagram"
    | "youtube";
}

const CsvExport: React.FC<IProps> = ({
    onClick,
    disabled,
    loading,
    content,
    size,
    basic,
    color,
}) => {
    const [csvData, setCsvData] = useState(false);
    const csvInstance = useRef<any>();

    useEffect(() => {
        if (csvData && csvInstance.current && csvInstance.current.link) {
            setTimeout(() => {
                csvInstance.current.link.click();
                setCsvData(false);
            });
        }
    }, [csvData]);
    return (
        <Fragment>
            <Icon
                name={loading ? "circle notch" : "download"}
                color="blue"
                loading={loading}
                disabled={loading ? true : disabled}
                onClick={async () => {
                    const newCsvData = await onClick();
                    setCsvData(newCsvData);
                }} />
            {/* <Button
                loading={loading}
                disabled={disabled}
                size={size}
                content={content}
                basic={basic}
                color={color}
                onClick={async () => {
                    const newCsvData = await onClick();
                    setCsvData(newCsvData);
                }}
            /> */}

            {csvData ? (
                <CSVLink
                    data={(csvData as any).data}
                    //   headers={(csvData as any).headers}
                    filename={(csvData as any).filename}
                    // separator={';'}
                    ref={csvInstance}
                />
            ) : undefined}
        </Fragment>
    );
};

export default CsvExport;