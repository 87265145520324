import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IUserProfile } from "../../../interfaces/user";
import { RootStore } from "../../../stores/RootStoreContext";
import { ActiveOrder } from "../../../interfaces/order";

export class AllClientOrderBubblesStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable loadingDetail: boolean = false;
    @observable orders: ActiveOrder[] = []
    @observable searchText: string = "";
    @observable statuses: string[] = [];
    @observable users: IUserProfile[] = [];

    @action setLoading = (value: boolean) => this.loading = value;
    @action setLoadingDetail = (value: boolean) => this.loadingDetail = value;
    @action setSelectedStatuses = (value: string[]) => this.statuses = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetOrders = (value: ActiveOrder[]) => this.orders = value;
    @action setOrders = (value: ActiveOrder[]) => {
        if (this.orders.length === 0 || value.length === 0)
            this.orders = value
        else {
            var existing = this.orders.map(f => f.id)
            var missing = value.filter(f => existing.indexOf(f.id) < 0);
            this.orders.push(...missing);
        }
    };
    @action setUsers = (value: IUserProfile[]) => {
        if (this.orders.length === 0 || value.length === 0)
            this.users = value
        else {
            var existing = this.users.map(f => f.id)
            var missing = value.filter(f => existing.indexOf(f.id) < 0);

            this.users.push(...missing);
        }
    };

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Orders.active();


            this.setCurrentPage(this.currentPage + 1);
            this.setOrders(response);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action dispose = () => {
        this.searchText = ""
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetOrders([]);
        this.setHasMore(false);
    }

    @action disposeDetail = () => {
        this.setLoadingDetail(false);
    }
}