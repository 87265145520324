import React, { Fragment, useEffect } from "react";
import { Placeholder, Image, Header, Icon } from "semantic-ui-react";
import PhotoUploadWidget from "./PhotoUploadWidget";
import { observer } from "mobx-react-lite";
import agent from "../../api/agent";
import { AdImageSize } from "../../options/AdImageSizes";
import { getBase64 } from "../../helpers/urlImageHelper";

interface IProps {
  loading: boolean;
  imageSize?: AdImageSize;
  uploadImage: (photo: object) => Promise<void>;
  imageSrc?: string | undefined;
  disabled?: boolean;
}

const CardWithUpload: React.FC<IProps> = ({
  loading,
  uploadImage,
  imageSrc,
  imageSize,
  disabled = false,
}) => {
  const [image, setImage] = React.useState<string>();
  const [changeImage, setChangeImage] = React.useState(false);

  useEffect(() => {
    if (imageSrc && !imageSrc.startsWith("http")) {
      var storageItem = localStorage.getItem(imageSrc);

      if (storageItem) {
        var obj = JSON.parse(storageItem);
        setImage(obj.value);
      } else
        agent.AWS.Bucket.getFileUrl(imageSrc).then((dataUrl) => {
          setImage(dataUrl);
          fetch(dataUrl).then((r) => {
            r.blob().then((blobFile) =>
              getBase64(blobFile, (base64: string) => {
                localStorage.setItem(
                  imageSrc,
                  JSON.stringify({
                    value: base64,
                    expiry: new Date().getTime() / 1000 + 86400 * 3,
                  })
                );
              })
            );
          });
        });
    } else if (imageSrc) setImage(imageSrc);

    return () => {
      // second;
    };
  }, []);

  if (loading)
    return (
      <Placeholder style={{ height: 145 }} fluid>
        <Placeholder.Image square />
      </Placeholder>
    );

  return (
    <Fragment>
      <div>
        {imageSize && (
          <div>
            <Header
              size="tiny"
              color="blue"
              content={`Dimensions ${imageSize.dimention}`}
              style={{ marginBottom: "10px" }}
            />
          </div>
        )}
        {image && !changeImage && !disabled && (
          <div
            style={{ textAlign: "right" }}
            onClick={() => setChangeImage(true)}
          >
            <Icon name="pencil" />
            Change Image
          </div>
        )}
        {changeImage && (
          <div
            style={{ textAlign: "right" }}
            onClick={() => setChangeImage(false)}
          >
            <Icon name="close" />
            Cancel
          </div>
        )}
      </div>
      {image !== undefined && !changeImage ? (
        <Image src={image} centered />
      ) : (
        <PhotoUploadWidget
          uploadPhoto={(img: object) => {
            setChangeImage(false);
            setImage((img as any).preview);
            return uploadImage(img as any);
          }}
        />
      )}
    </Fragment>
  );
};

export default observer(CardWithUpload);
