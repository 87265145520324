import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, Fragment, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { isMobile } from 'react-device-detect';
import { Menu, Divider, Table, Icon, Input, Header, Button } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component'
import { currencyFormat } from '../../products/functions/productHelper';
import CsvExport from '../../../common/csvExport';
import { ClientWalletDetailReportStore } from '../functions/ClientWalletDetailReportStore';
import { DateTimePicker } from "react-widgets";
import { WalletTransactionTypes } from '../../../options/WalletTransactionTypes';
import { format } from 'date-fns';
import { NoResults } from '../../../common/NoResults';

const ClientWalletReport = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isAdmin } = context.userStore;

    const {
        items,
        hasMore,
        load,
        exportDataForCsv,
        downloading,
        loading,
        setDateFrom,
        setDateTo,
        search,
        dateFrom,
        dispose } = ClientWalletDetailReportStore;

    useEffect(() => {
        return () => {
            dispose()
        }
    }, [isAdmin, navigate, dispose, load])

    const [timer, setTimer] = React.useState(0);
    const [valueFrom, setValueFrom] = React.useState<Date | null | undefined>();
    const [valueTo, setValueTo] = React.useState<Date | null | undefined>();

    return (
        <div className="simply-paddedPage">
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "0 0 5px 0" }}
            >
                <Menu.Item
                    className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}
                >
                    <Input
                        fluid={isMobile}
                        transparent={isMobile}
                        size={isMobile ? "large" : "small"}
                        icon={
                            !isMobile ? <Icon name="search" color="green" /> : undefined
                        }
                        placeholder={"User Id..."}
                        input={
                            <input className={isMobile ? "" : "bluePlaceholder"} />
                        }
                        onChange={(event: ChangeEvent, data: any) => {
                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: (text: string) => void, text: string) => {
                                        callback(text);
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search,
                                    data.value
                                )
                            );
                        }}
                    />
                </Menu.Item>
                <Menu.Item className={"no-padding"}>
                    <DateTimePicker
                        value={valueFrom}
                        placeholder={"Date From"}
                        onChange={(date: Date | null | undefined, rawValue: string) => {
                            // date?.setUTCHours(0, 0, 0, 1);
                            var dateNum = date?.getTime() ?? undefined;
                            setValueFrom(date);
                            setDateFrom((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: () => void, text: string) => {
                                        callback();
                                        clearTimeout(timer);
                                    },
                                    1000,
                                    search
                                )
                            );
                        }}
                        includeTime={true}
                        timePrecision={'minutes'}
                        max={new Date()}
                    />
                </Menu.Item>
                <Menu.Item className={"no-padding"}>
                    <DateTimePicker
                        placeholder={"Date To"}
                        value={valueTo}
                        onChange={(date: Date | null | undefined, rawValue: string) => {
                            // date?.setUTCHours(23, 59, 59, 999);
                            setValueTo(date);
                            var dateNum = date?.getTime() ?? undefined;
                            setDateTo((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: () => void, text: string) => {
                                        callback();
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search
                                )
                            );
                        }}
                        includeTime={true}
                        timePrecision={'minutes'}
                        max={new Date()}
                        min={dateFrom ? new Date(dateFrom * 1000) : undefined}
                    />
                </Menu.Item>
                <Menu.Item>
                    <Button
                        color="green"
                        icon='refresh'
                        basic
                        className="no-margin"
                        onClick={() => search()}
                    />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item icon title="Export" style={{ padding: "0px" }}>
                        <CsvExport
                            onClick={exportDataForCsv}
                            content={
                                <Fragment>
                                    <Icon name="file excel outline" /> Export
                                </Fragment>
                            }
                            disabled={items.length === 0 || downloading}
                            loading={downloading}
                        />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider />
            <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={items.length} //This is important field to render the next data
                next={() => load()}
                hasMore={hasMore}
                loader={loading ? <h4>Loading...</h4> : <div />}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        {items.length > 0 ? (
                            <b></b>
                        ) : (
                            <NoResults />
                        )}
                    </p>
                }
            >
                <Table basic="very" celled >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Customer</Table.HeaderCell>
                            <Table.HeaderCell>Admin</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items.map((x) => (
                            <Table.Row key={x.id}>
                                <Table.Cell>
                                    {format(new Date(x.createdOn * 1000), "dd/MM/yyyy HH:mm")}
                                </Table.Cell>
                                <Table.Cell>
                                    <Header>
                                        {`${x.name} ${x.lastName}`}
                                        <Header.Subheader>
                                            {x.userId}
                                        </Header.Subheader>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{x.adminName}</Table.Cell>
                                <Table.Cell>{WalletTransactionTypes.filter(f => f.value === x.type)[0].text}</Table.Cell>
                                <Table.Cell textAlign='right'>{currencyFormat(x.amount, true)}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </InfiniteScroll>
        </div>
    )
}

export default observer(ClientWalletReport)