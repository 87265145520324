import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import { Button, Form, Icon } from "semantic-ui-react";
import TextInput from "../../form/TextInput";
import ErrorMessage from "../../form/ErrorMessage";
import { RootStoreContext } from "../../stores/RootStoreContext";
import NumericInput from "../../form/NumericInput";
import SelectInput from "../../form/SelectInput";
import CheckBox from "../../form/CheckBox";
import { ProductCategoryForProcessing } from "../../interfaces/categories";

interface IProps {
  categories: any[];
  showCloseIcon?: boolean;
  closeOnSave?: boolean;
  close: () => void;
  editingItem?: ProductCategoryForProcessing;
}

const validate = combineValidators({
  order: isRequired(""),
  internalId: isRequired(""),
  description: isRequired(""),
});

const CreateUpdateCategoryWidget: React.FC<IProps> = ({
  editingItem,
  categories,
  showCloseIcon,
  close,
  closeOnSave = true

}) => {
  const context = useContext(RootStoreContext);
  const { save } = context.categoriesStore;
  const { update } = context.categoriesAdminStore;

  return (
    <div>
      {showCloseIcon && <Icon size='large' name='close' onClick={close} />}
      <FinalForm
        validate={validate}
        initialValues={editingItem}
        onSubmit={(values: any) =>
          (editingItem === undefined ? save : update)(values)
            .then(() => {
              if (closeOnSave)
                close();
            })
            .catch((error) => ({
              [FORM_ERROR]: error,
            }))
        }
        render={({
          handleSubmit,
          submitting,
          form,
          invalid,
          dirtySinceLastSubmit,
          submitError,
        }) => (
          <Form onSubmit={handleSubmit} error className="attached fluid">
            <Form.Field>
              Id: {editingItem?.id}
            </Form.Field>
            <Form.Field>
              <label style={{ fontSize: "13px" }}>{"Internal Id"}</label>
              <Field
                name="internalId"
                component={TextInput}
                placeholder={"Internal Id"}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ fontSize: "13px" }}>{"Description"}</label>
              <Field
                name="description"
                component={TextInput}
                placeholder={"Description"}
                onChange={(data: string) => {
                  form.change("internalId", data.replaceAll(" ", "_").toLowerCase())
                }}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ fontSize: "13px" }}>{"Searchable Terms"}</label>
              <Field
                name="norm_desc"
                component={TextInput}
                placeholder={"Searchable Terms"}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ fontSize: "13px" }}>{"Order"}</label>
              <Field
                name="order"
                component={NumericInput}
                placeholder={"Order"}
              />
            </Form.Field>
            <Form.Field style={{ textAlign: "left" }}>
              <label style={{ fontSize: "13px" }}>
                {"Cheap Trolley Categories"}
              </label>
              <Field
                multiselect={true}
                component={SelectInput}
                options={categories
                  .map((x) => ({
                    key: x.id,
                    value: x.id,
                    text: x.description,
                  }))}
                name="parents"
                placeholder={"Select parent category(ies)"}
              />
            </Form.Field>
            <Form.Field style={{ textAlign: "center" }}>
              <label style={{ fontSize: "13px" }}>{"Is Main"}</label>

              <Field
                type="checkbox"
                component={CheckBox}
                toggle
                name="isMain"
              />
            </Form.Field>

            {submitError && !dirtySinceLastSubmit && (
              <ErrorMessage error={submitError} />
            )}

            <div style={{ marginTop: "7px", textAlign: "center" }}>
              <Button.Group>
                <Button
                  disabled={invalid && !dirtySinceLastSubmit}
                  loading={submitting}
                  color="blue"
                  content={"Save"}
                />
                <Button.Or />
                <Button
                  disabled={submitting}
                  content={"Cancel"}
                  onClick={(e) => {
                    e.preventDefault();
                    close();
                  }}
                />
              </Button.Group>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default observer(CreateUpdateCategoryWidget);
