import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Dropdown, Icon, Label, List } from "semantic-ui-react";
import { GetFormattedAddress } from "../../../helpers/addressHelper";
import { ConstractFullNameFromProfile } from "../../../helpers/textHelper";
import { IOrderForList } from "../../../interfaces/order";
import { IUserProfile } from "../../../interfaces/user";
import { CheckOutOptions } from "../../../options/CheckoutTypes";
import { OrderStatusOption } from "../../../options/OrderStatusOption";
import agent from "../../../api/agent";
import TrackOrderWidget from "../../orderMonitoring/components/TrackOrderWidget";
import { IDeliverySlotForList } from "../../../interfaces/delivery";

interface IProps {
  order: IOrderForList;
  onClick: () => void;
  user?: IUserProfile;
  refresh: (id: string) => void
  openModal: (...args: any[]) => void
}

const AdminClientOrderItem: React.FC<IProps> = ({ order, user, onClick, refresh, openModal }) => {
  const [deliveries, setDeliveries] = React.useState<IDeliverySlotForList[]>([])

  return (
    <List.Item onDoubleClick={onClick}>

      <List.Content floated="right">
        {order.status === "pending_payment" &&
          <Button
            size="large"
            content="Complete Payment"
            className="button-as-link greenColor"
            onClick={(e) => {
              agent.Orders.Admin.retry(order.id)
                .then(() => {
                  refresh(order.id);
                })
                .catch(() => { });
            }}
          />
        }
        {order.checkoutType === "delivery" && (order.status === "ready_for_delivery" || order.status === "sent_to_market" || order.status === "pending_processing") &&
          <Dropdown
            style={{ paddingRight: "6px" }}
            onClick={() => agent.Scheduler.Deliveries.get(false, 0).then((upcomingDeleries) => {
              setDeliveries(upcomingDeleries.Items);
            })}
            className="reschedule ct-green-text"
            direction="left"
            scrolling
            options={deliveries?.map((x) => ({
              key: x.id,
              text: format(new Date(x.startsOn * 1000), 'EEEE, dd/MM'),
              description: x.name,
              value: x.id
            }))}
            onChange={(e, data) => {
              agent.Orders.Admin.reschedule(order.id, data.value as string)
                .then(() => {
                  refresh(order.id);
                })
                .catch(() => { });
            }}
            text='Reschedule'
          />
          // <Button
          //   size="large"
          //   content="Reschedule"
          //   className="button-as-link ct-green-text"
          //   onClick={(e) => {
          //     agent.Scheduler.Deliveries.get(false, 0).then((upcomingDeleries) => {

          //     })
          //     // agent.Orders.Admin.Monitor.reprint(order.id)
          //     //   .then(() => {
          //     //     refresh(order.id);
          //     //   })
          //     //   .catch(() => { });
          //   }}
          // />
        }
        {(order.status === "ready_for_delivery" || order.status === "out_for_delivery" || order.status === "ready_for_pickup" || order.status === "completed") && <Button
          size="large"
          content="Reprint"
          className="button-as-link blueColor"
          onClick={(e) => {
            agent.Orders.Admin.Monitor.reprint(order.id)
              .then(() => {
                refresh(order.id);
              })
              .catch(() => { });
          }}
        />}
        <b>{`${order.totalCost.toFixed(2)} €`}</b>
        <Button
          size="large"
          className="button-as-link greenColor"
          icon={"angle right"}
          onClick={() => { }}
        />
      </List.Content>
      <List.Content>
        <List.Header>
          <Label
            basic
            size="small"
            color={
              OrderStatusOption.filter((f) => f.value === order.status)[0].color
            }
          >
            {OrderStatusOption.filter((f) => f.value === order.status)[0].text}
          </Label>
          <span style={{ paddingLeft: "7px" }} onClick={() => {
            if (order.checkoutType === "delivery")
              openModal(<TrackOrderWidget orderId={order?.id} />, "mini", `Tracking info`, true, false)
          }}>
            <Icon name="podcast" color="green" />
            {order.displayId ?? "Display id is not available"}
          </span>
          <span style={{ paddingLeft: "7px" }}>
            <Icon name="user circle outline" color="green" />
            {ConstractFullNameFromProfile(user)}
          </span>
          <span style={{ paddingLeft: "7px" }}>
            <Icon name="phone volume" color="green" />
            {user?.phoneNumber ?? "-"}
          </span>
          <span style={{ paddingLeft: "7px" }}>
            <Icon name="mail outline" color="green" />
            {user?.email ?? "-"}
          </span>
        </List.Header>
        <List.Description style={{ marginTop: "3px" }}>
          <div className="fxDisplay fxFixed ">
            <div>{format(order.createdOn * 1000, "dd/MM/yy, HH:mm")}</div>
            {` - ${CheckOutOptions.filter((f) => f.key === order.checkoutType)[0]
              .text
              }`}
            {order.checkoutType === "delivery" && (
              <div style={{ paddingLeft: "3px" }}>
                <Icon name="point" color="green" />
                {`${GetFormattedAddress(
                  order.address,
                  undefined,
                  undefined,
                  true
                )}`}
                {order.checkoutType === "delivery" && order.delivery && (
                  <span style={{ paddingLeft: "3px" }}>
                    <Icon name="clock outline" color="green" />
                    {`${format(new Date(order.delivery?.startsOn * 1000), 'EEEE, dd/MM/YYY HH:mm')} - ${format(new Date(order.delivery.to * 1000), 'HH:mm')} h`}
                  </span>
                )}
              </div>

            )}
          </div>
        </List.Description>
      </List.Content>
    </List.Item>
  );
};

export default observer(AdminClientOrderItem);
