import React, { useContext } from "react";
import { Grid, Form, Button } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import {
  combineValidators,
  composeValidators,
  createValidator,
  isRequired,
} from "revalidate";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { IUserForInvitation } from "../../../interfaces/user";
import TextInput from "../../../form/TextInput";
import ErrorMessage from "../../../form/ErrorMessage";
import SelectInput from "../../../form/SelectInput";
import { ShopUserRoleOptions } from "../../../options/ShopUserRoleOptions";

const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  (field) => "x"
);

const validate = combineValidators({
  firstName: isRequired(""),
  roleId: isRequired(""),
  lastName: isRequired(""),
  email: composeValidators(isRequired, isValidEmail)(""),
});

const InviteUserWidget = () => {
  const context = useContext(RootStoreContext);
  const { inviteUser } = context.shopUserStore;
  const { closeModal } = context.modalStore;

  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column>
          <FinalForm
            onSubmit={(values: IUserForInvitation) =>
              inviteUser(values)
                .then(() => closeModal())
                .catch((error) => ({
                  [FORM_ERROR]: error,
                }))
            }
            validate={validate}
            render={({
              handleSubmit,
              submitting,
              submitError,
              valid,
              dirtySinceLastSubmit,
            }) => (
              <Form onSubmit={handleSubmit} error>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label style={{ fontSize: "13px" }}>{"First Name"}</label>
                    <Field
                      name="firstName"
                      component={TextInput}
                      placeholder={"First Name"}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label style={{ fontSize: "13px" }}>{"Last Name"}</label>
                    <Field
                      name="lastName"
                      component={TextInput}
                      placeholder="Last Name"
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <label style={{ fontSize: "13px" }}>{"E-mail"}</label>
                    <Field
                      name="email"
                      component={TextInput}
                      placeholder={"E-mail"}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label style={{ fontSize: "13px" }}>{"Role"}</label>
                    <Field
                      name="roleId"
                      component={SelectInput}
                      options={ShopUserRoleOptions.map((section) => ({
                        key: section.key,
                        value: section.value,
                        text: section.text,
                        description: section.description,
                      }))}
                      multiselect={false}
                      placeholder={"Select Role"}
                    />
                  </Form.Field>
                </Form.Group>

                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage error={submitError} />
                )}
                <div style={{ textAlign: "center" }}>
                  <Button
                    disabled={!valid}
                    loading={submitting}
                    content={"Invite"}
                    basic
                    color="blue"
                  />
                </div>
              </Form>
            )}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(InviteUserWidget);
