import { IAddress } from "../interfaces/user";
import { Countries } from "../options/Countries";
import { Localities } from "../options/Localities";

export function GetFormattedAddress(
    address?: IAddress,
    ecxludeFields: string[] = [],
    breakCharacter: string = "\n",
    shortVersion: boolean = false
): string {
    if (address === undefined || address === null) return "-";
    /**
     * Line 1
     * Line 2
     * Locality, City , State , PostCode
     * Country
     */
    let returnAddress = "";

    if (
        ecxludeFields.indexOf("addressLine1") < 0 &&
        address.addressLine1 !== null &&
        address.addressLine1 !== undefined
    )
        returnAddress += address.addressLine1 + breakCharacter;

    if (
        ecxludeFields.indexOf("addressLine2") < 0 &&
        address.addressLine2 !== null &&
        address.addressLine2 !== undefined
    )
        returnAddress += address.addressLine2 + breakCharacter;

    if (
        ecxludeFields.indexOf("localityId") < 0 &&
        address.localityId !== null &&
        address.localityId !== undefined
    )
        returnAddress += Localities.filter(f => f.value === address.localityId)[0].text + ", ";

    if (shortVersion) return returnAddress;

    if (
        ecxludeFields.indexOf("city") < 0 &&
        address.city !== null &&
        address.city !== undefined
    )
        returnAddress += address.city + ", ";

    if (
        ecxludeFields.indexOf("state") < 0 &&
        address.state !== null &&
        address.state !== undefined
    )
        returnAddress += address.state + ", ";

    if (
        ecxludeFields.indexOf("postCode") < 0 &&
        address.postCode !== null &&
        address.postCode !== undefined
    )
        returnAddress += address.postCode + breakCharacter;

    if (
        ecxludeFields.indexOf("country") < 0 &&
        address.countryId !== null &&
        address.countryId !== undefined
    ) {
        const country = Countries.find((e) => e.value === address.countryId)?.text!;
        returnAddress += country;
    }

    return returnAddress;
}