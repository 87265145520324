import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import {
  Button,
  Dimmer,
  Divider,
  List,
  Loader,
  Menu,
  Segment,
} from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { RootStoreContext } from "../../stores/RootStoreContext";
import CreateUpdateProductList from "./Components/CreateUpdateProductList";
import ProductListItem from "./Components/ProductListItem";
import ProductListResults from "./Components/ProductListResults";

interface IProps {
  shopId?: string;
}

const ProductLists: React.FC<IProps> = ({ shopId }) => {
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const { setPage } = context.analyticsStore;
  const { openModal } = context.modalStore;
  const { loading, load, dispose, lists, hasMore, selectedProductList } = context.productListStore;

  useEffect(() => {
    setPage("ProductList", window.location.pathname);

    load(shopId);

    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPage, load, dispose]);

  return (<div style={{ marginRight: "20px" }}>
    {selectedProductList === undefined ? <Fragment>
      <Menu secondary size={isMobile ? "massive" : "small"} style={{ margin: "5px 0" }}      >
        <Menu.Menu position="right" >
          <Button
            className="ct-green-button-active"
            icon={"plus"}
            content={"Create"}
            onClick={() => {
              openModal(
                <CreateUpdateProductList />,
                "small",
                "Create a new product list",
                true,
                false
              );
            }}
          />
        </Menu.Menu>
      </Menu>
      < Divider style={isMobile ? { padding: "0", margin: "0" } : undefined} />
      {!loading || lists.length > 0 ? (
        <div>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={lists.length} //This is important field to render the next data
            next={() => load()}
            hasMore={hasMore}
            loader={loading ? <h4>Loading...</h4> : <div />}
          >
            <List
              divided
              relaxed
              style={isMobile ? { padding: "7px" } : undefined}
            >
              {lists.map((x) => (
                <ProductListItem key={x.id} item={x} />
              ))}
            </List>
          </InfiniteScroll>
        </div>
      ) : (
        <Segment
          style={{
            minHeight: "55vh",
            boxShadow: "none",
            border: "none",
          }}
        >
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      )}
    </Fragment> : <ProductListResults item={selectedProductList} />}
  </div>);
};

export default observer(ProductLists);
