import { observer } from 'mobx-react-lite'
import React from 'react'
import { useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    Dimmer,
    List,
    Loader,
    Segment,
} from "semantic-ui-react";
import { RootStoreContext } from '../../stores/RootStoreContext';
import { UserShoppingCartsStore } from './functions/UserShoppingCartsStore';
import UserShoppingCartItem from './components/UserShoppingCartItem';

const UserShoppingCarts = () => {
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const { load, loading, hasMore, items, dispose } = UserShoppingCartsStore;

    useEffect(() => {
        load();

        return () => {
            dispose()
        }
    }, [load, dispose])

    return (
        <div className='simply-paddedPage'>
            {!loading || items.length > 0 ? (
                <div>
                    <InfiniteScroll
                        style={{ overflow: "hidden" }}
                        dataLength={items.length} //This is important field to render the next data
                        next={() => load()}
                        hasMore={hasMore}
                        loader={loading ? <h4>Loading...</h4> : <div />}
                    >
                        <List
                            divided
                            relaxed
                            selection
                            style={isMobile ? { padding: "7px" } : undefined}
                        >
                            {items.map((x) => (
                                <UserShoppingCartItem key={x.cart_id} cart={x} />
                            ))}
                        </List>
                    </InfiniteScroll>
                </div>
            ) : (
                <Segment>
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                </Segment>
            )
            }
        </div>
    )
}

export default observer(UserShoppingCarts)