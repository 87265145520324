import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IProductForWizard } from "../../../interfaces/product";
import { RootStore } from "../../../stores/RootStoreContext";

export class ProductWizardStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable marketId?: string = undefined;
    @observable item?: IProductForWizard = undefined;

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setItem = (value?: IProductForWizard) => { this.item = value };

    @action setMarketId = (value?: string) => {
        this.marketId = value
        this.load();
    };

    @action load = async () => {
        try {
            this.setLoading(true);

            var item = await agent.Products.next(this.marketId);

            this.setItem(item);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action addToAssociation = async (assId: string, productIds: string[]) => {
        if (!productIds || !assId || productIds.length === 0) return;

        return await agent.Associations.addManyToAssociation(assId, productIds);
    }

    @action dispose = () => {
        this.setItem(undefined)
        this.setLoading(false)
    }
}