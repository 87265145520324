import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useContext, useEffect } from "react";
import { Button, Card, Placeholder } from "semantic-ui-react";
import { GetFormattedAddress } from "../../../helpers/addressHelper";
import { IAddress } from "../../../interfaces/user";
import { WidgetMode } from "../../../interfaces/WidgetMode";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { ButtonTypes } from "../../confirmationModal/functions/ButtonTypes";
import AddressDetailWidget from "./AddressDetailWidget";

interface IProps {
  userId: string;
  mode: WidgetMode;
  onAddressSelected?: (address: IAddress) => void;
  isInModal?: boolean;
}

const CurrentAddressesWidget: React.FC<IProps> = ({
  userId,
  mode,
  onAddressSelected,
}) => {
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const { openModal } =
    context.modalStore;
  const { load, addresses, loading, deleteAddress } =
    context.userAddressesStore;

  const [selected, setSelected] = React.useState("");
  const { openConfirmationModal, closeConfirmationalModal } =
    context.confirmationModalStore;

  useEffect(() => {
    load(userId);
    return () => { };
  }, [userId, load]);

  return (
    <div>
      <Card.Group
        itemsPerRow={isMobile ? 1 : 2}
        centered
        className="displayGrid"
      >
        {loading
          ? Array.from({ length: 3 }, (value, index) => (
            <Card key={index} raised className={"businessTypeCard"}>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line length="very short" />
                    <Placeholder.Line length="medium" />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Card.Content>
            </Card>
          ))
          : addresses.map((item) => (
            <Card
              key={item.id}
              raised
              className={
                selected === item.id
                  ? "businessTypeCard selectedBusinessTypeCard"
                  : "businessTypeCard"
              }
              onClick={() => {
                if (
                  mode !== WidgetMode.Select &&
                  mode !== WidgetMode.Checkout
                )
                  return;

                if (onAddressSelected) {
                  onAddressSelected(item);
                  setSelected(item.id);
                }
              }}
            >
              <Card.Content
                style={{ padding: "1em 0em" }}
                className="fxDisplay fxDirCol"
                textAlign="center"
              >
                <Card.Description>
                  <b>{item.title ?? "-"}</b>
                  <Card.Meta>
                    {GetFormattedAddress(item)}
                  </Card.Meta>
                </Card.Description>
              </Card.Content>
              {(mode === WidgetMode.Edit || mode === WidgetMode.Checkout) && (
                <Card.Content extra>
                  {mode === WidgetMode.Checkout && (
                    <Button
                      basic
                      color="green"
                      onClick={() => {
                        if (onAddressSelected) {
                          onAddressSelected(item);
                        }
                      }}
                      content={"Select"}
                    />
                  )}
                  {mode !== WidgetMode.Checkout && <Button
                    className="ct-green-button ct-green-text-dark"
                    onClick={(event) => {
                      event.preventDefault();
                      openModal(
                        <AddressDetailWidget address={item} />,
                        "mini",
                        "New Address",
                        true,
                        false
                      );
                    }
                    }
                    content={"Update"}
                  />}
                  <Button
                    basic
                    icon={"trash alternate outline"}
                    color="red"
                    onClick={() =>
                      openConfirmationModal(
                        "Confirm address deletion",
                        "Are you sure you want to delete this address?",
                        () =>
                          deleteAddress(item.id).then(() =>
                            closeConfirmationalModal()
                          ),
                        "Cancel",
                        "Delete",
                        ButtonTypes.None,
                        ButtonTypes.Negative
                      )
                    }
                  />
                </Card.Content>
              )}
            </Card>
          ))}
        {(mode === WidgetMode.Edit ||
          mode === WidgetMode.Select ||
          mode === WidgetMode.Checkout) && (
            <Card
              key={"addNew"}
              raised
              className={"businessTypeCard"}
              style={{ padding: "0px" }}
            >
              <Button
                fluid
                style={{ height: "100%" }}
                icon="plus"
                className="ct-green-button"
                content={"New"}
                onClick={(event: SyntheticEvent) => {
                  event.preventDefault();
                  openModal(
                    <AddressDetailWidget />,
                    "mini",
                    "New Address",
                    true,
                    false
                  );
                }}
              />
            </Card>
          )}
      </Card.Group>
    </div>
  );
};

export default observer(CurrentAddressesWidget);
