import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent, useContext } from 'react'
import { Button, Form, Icon, Label, Table } from 'semantic-ui-react'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import ErrorMessage from '../../../form/ErrorMessage'
import { FORM_ERROR } from "final-form";
import { Form as FinalForm, Field } from "react-final-form";
import CreateUpdateCategoryWidget from '../../categories/CreateUpdateCategoryWidget'
import SelectInput from '../../../form/SelectInput'
import CreateUpdateMeasurementWidget from '../../measurementUnits/CreateUpdateMeasurementWidget'
import NumericInput from '../../../form/NumericInput'
import { compartments } from '../../../options/compartments'
import BrandsSelector from '../../../components/BrandsDropdown/BrandsSelector'
import DietaryCategoriesManageWidget from '../../dietaryCategories/DietaryCategoriesManageWidget'
import BrandDetailWidget from '../../brands/components/BrandDetailWidget'
import { toast } from 'react-toastify'
import arrayMutators from "final-form-arrays";
import { FieldArray } from 'react-final-form-arrays'
import { currencyFormat } from '../../products/functions/productHelper'
import { BrandSelectorStore } from '../../../components/BrandsDropdown/functions/BrandSelectorStore'

const BulkUpdateItemsWidget = () => {
    const context = useContext(RootStoreContext)
    const { openSecondModal, closeSecondModal, closeModal } = context.modalStore;
    const {
        VATCategories,
        Extras,
        dietaryCategories,
        refreshDietaryCategories,
        categories,
        refreshCategories,
        measurements,
        refreshMeasurements,
        bulkFilterSave,
        refreshData
    } = context.catalogueAdminStore;


    return (
        <FinalForm
            onSubmit={(values: any) =>
                bulkFilterSave(values)
                    .then((result) => (result?.total ?? -3) > 0 ? toast.success(<div> Updated {result.total} item(s)</div>) : toast.error(<div> Error Code: {result.total === -1 ? "Update object is empty" : "Need to filter first"}</div>))
                    .then(() => refreshData())
                    .catch((error) => ({
                        [FORM_ERROR]: error,
                    }))
            }
            mutators={{
                ...arrayMutators,
            }}
            render={({
                handleSubmit,
                submitError,
                dirtySinceLastSubmit,
                submitting,
                invalid,
                pristine,
                values,
                form,
                form: {
                    mutators: { push, remove },
                },
            }) => (
                <Form onSubmit={handleSubmit} error>
                    <div style={{ minWidth: "400px", paddingTop: "15px" }}>
                        <Form.Group widths={'equal'}>
                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>{"Brand"}</label>
                                <BrandsSelector
                                    brandSelectStore={new BrandSelectorStore()}
                                    selectionChanged={(e, d: any) => {
                                        var arr: string[] = []
                                        if (Array.isArray(d.value)) {
                                            arr = d.value
                                        }
                                        else {
                                            arr = [d.value]
                                        }

                                        arr = arr.filter(f => f.trim() !== "");

                                        form.change("brand_tags", arr)
                                    }} />
                                <Label
                                    className="button-as-link clickable blueColor"
                                    content="Create New Brand"
                                    onClick={() => {
                                        openSecondModal(
                                            <BrandDetailWidget
                                                closeOnSave={true}
                                                showCloseIcon={false}
                                                close={() => { closeSecondModal() }}
                                            />,
                                            "mini",
                                            "Create new Brand",
                                            true,
                                            true,
                                            () => {
                                                // refreshDietaryCategories();
                                            }
                                        );
                                    }}
                                />

                            </Form.Field>
                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>
                                    {"VAT Category"}
                                </label>

                                <Form.Field style={{ textAlign: "left" }}>
                                    <Field
                                        component={SelectInput}
                                        options={VATCategories.map(v => ({
                                            'key': v.id!,
                                            'value': v.id!,
                                            'text': v.title
                                        }))}
                                        name="vat"
                                        placeholder={"Select VAT"}
                                    />
                                    {VATCategories.map((f) => (
                                        <Label
                                            color="blue"
                                            basic
                                            size="small"
                                            style={{ marginTop: "4px" }}
                                            content={f.title}
                                            onClick={() => {
                                                form.change(
                                                    "vat",
                                                    f.id!
                                                );
                                            }}
                                        />
                                    ))}
                                </Form.Field>
                            </Form.Field>
                        </Form.Group>

                        <Form.Group>
                            <Form.Field width={16}>
                                <label style={{ fontSize: "13px" }}>
                                    Extras
                                </label>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                Item
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Quantity
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Unit Cost
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Total Cost
                                            </Table.HeaderCell>
                                            <Table.HeaderCell />
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        <FieldArray name="extras">
                                            {({ fields }) =>
                                                fields.map((name, index) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell>
                                                            <Field
                                                                name={`${name}.id`}
                                                                component={SelectInput}
                                                                options={Extras.map(x => ({ 'key': x.id, 'value': x.id, 'text': x.title }))}
                                                                changeFinished={(value: any) => {
                                                                    if (value === "") {
                                                                        form.change(`${name}.fee`, 0);
                                                                        form.change(`${name}.vat_rate_id`, undefined);
                                                                    }
                                                                    else {
                                                                        form.change(`${name}.fee`, Extras.filter(f => f.id === value)[0].fee);
                                                                        form.change(`${name}.vat_rate_id`, Extras.filter(f => f.id === value)[0].vat_rate);
                                                                    }
                                                                }}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Field
                                                                component={NumericInput}
                                                                name={`${name}.quantity`}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Field
                                                                component={NumericInput}
                                                                name={`${name}.fee`}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {currencyFormat((values.extras[index]?.quantity ?? 0) * (values.extras[index]?.fee ?? 0), true)}
                                                        </Table.Cell>

                                                        <Table.Cell >
                                                            <Button
                                                                color="red"
                                                                icon="minus"
                                                                circular
                                                                size="mini"
                                                                onClick={(event: SyntheticEvent) => {
                                                                    remove("extras", index);
                                                                    event.preventDefault();
                                                                }}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))
                                            }
                                        </FieldArray>
                                    </Table.Body>

                                    <Table.Footer fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan="9">
                                                <Button
                                                    floated="right"
                                                    basic
                                                    color="blue"
                                                    onClick={(event: SyntheticEvent) => {
                                                        push("extras", {});
                                                        event.preventDefault();
                                                    }}
                                                >
                                                    <Icon name="add" />{" "}
                                                    Add
                                                </Button>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths={'equal'}>
                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>
                                    {"Dietary Categories"}
                                </label>
                                <Field
                                    component={SelectInput}
                                    multiselect={true}
                                    options={dietaryCategories?.map((x) => ({
                                        key: x.id,
                                        value: x.id,
                                        text: x.description,
                                    }))}
                                    name="dietFlag"
                                    placeholder={"Select a category"}
                                />
                                <Label
                                    className="button-as-link clickable blueColor"
                                    content="Create New Dietary Category"
                                    onClick={() => {
                                        openSecondModal(
                                            <DietaryCategoriesManageWidget />,
                                            "mini",
                                            "Create new Dietary Category",
                                            true,
                                            true,
                                            () => {
                                                refreshDietaryCategories();
                                            }
                                        );
                                    }}
                                />
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths={'equal'}>
                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>
                                    {"Compartment"}
                                </label>
                                <Field
                                    component={SelectInput}
                                    options={compartments}
                                    name="compartment"
                                    placeholder={"Select compartment"}
                                />
                                {compartments.map((f) => (
                                    <Label
                                        key={f.key}
                                        color="blue"
                                        basic
                                        size="small"
                                        style={{ marginTop: "4px" }}
                                        content={f.text}
                                        onClick={() => {
                                            form.change(
                                                "compartment",
                                                f.value
                                            );
                                        }}
                                    />
                                ))}
                            </Form.Field>

                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>
                                    {"Categories"}
                                </label>
                                <Field
                                    multiselect={true}
                                    component={SelectInput}
                                    options={categories
                                        .slice()
                                        .sort((a, b) => a.description > b.description ? 1 : -1).map((x) => ({
                                            key: x.id,
                                            value: x.id,
                                            text: x.description,
                                        }))}
                                    name="ct_categories"
                                    placeholder={"Select category(ies)"}
                                />
                                <Label
                                    className="button-as-link clickable blueColor"
                                    content="Create New Category"
                                    onClick={() => {
                                        openSecondModal(
                                            <CreateUpdateCategoryWidget
                                                categories={categories}
                                                showCloseIcon={false}
                                                close={closeModal}
                                            />,
                                            "mini",
                                            "Create new Category",
                                            true,
                                            false,
                                            () => {
                                                refreshCategories();
                                            }
                                        );
                                    }}
                                />
                            </Form.Field>

                        </Form.Group>

                        <Form.Group widths={'equal'}>
                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>
                                    {"Measurment"}
                                </label>
                                <Field
                                    component={NumericInput}
                                    name="measurment"
                                    placeholder={"Measurment e.g. 700"}
                                />

                                <Label
                                    color="blue"
                                    basic
                                    size="small"
                                    style={{ marginTop: "4px" }}
                                    content="100"
                                    onClick={() =>
                                        form.change(
                                            "measurment",
                                            100
                                        )
                                    }
                                />
                                <Label
                                    color="blue"
                                    basic
                                    size="small"
                                    style={{ marginTop: "4px" }}
                                    content="500"
                                    onClick={() =>
                                        form.change(
                                            "measurment",
                                            500
                                        )
                                    }
                                />
                                <Label
                                    color="blue"
                                    basic
                                    size="small"
                                    style={{ marginTop: "4px" }}
                                    content="750"
                                    onClick={() =>
                                        form.change(
                                            "measurment",
                                            750
                                        )
                                    }
                                />
                                <Label
                                    color="blue"
                                    size="small"
                                    basic
                                    style={{ marginTop: "4px" }}
                                    content="1000"
                                    onClick={() =>
                                        form.change(
                                            "measurment",
                                            1000
                                        )
                                    }
                                />

                            </Form.Field>
                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>
                                    {"Measurement Unit"}
                                </label>
                                <Field
                                    component={SelectInput}
                                    options={measurements?.map((x) => ({
                                        key: x.key,
                                        value: x.key,
                                        text: x.description,
                                    }))}
                                    name="measurementUnit"
                                    placeholder={"Measurement Unit"}
                                />
                                <div className='fxFixed fxDisplay fxJustifyBetween'>
                                    <div className='fxStretch'>
                                        <Label
                                            color="blue"
                                            size="small"
                                            basic
                                            style={{ marginTop: "4px" }}
                                            content="gr"
                                            onClick={() =>
                                                form.change("measurementUnit", "grams")
                                            }
                                        />
                                        <Label
                                            color="blue"
                                            size="small"
                                            basic
                                            style={{ marginTop: "4px" }}
                                            content="ml"
                                            onClick={() =>
                                                form.change("measurementUnit", "ml")

                                            }
                                        />
                                    </div>
                                    <div style={{ margin: "auto" }}>
                                        <Label
                                            className="button-as-link clickable blueColor"
                                            content="Create"
                                            onClick={() => {
                                                openSecondModal(
                                                    <CreateUpdateMeasurementWidget />,
                                                    "mini",
                                                    "Create new Measurement Unit",
                                                    true,
                                                    true,
                                                    () => {
                                                        refreshMeasurements();
                                                    }
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </Form.Field>
                        </Form.Group>
                    </div>

                    {submitError && !dirtySinceLastSubmit && (
                        <ErrorMessage error={submitError} />
                    )}
                    <div style={{ textAlign: "center" }}>
                        <Button.Group style={{ paddingTop: "15px" }}>
                            <Button
                                color="blue"
                                disabled={(invalid && !dirtySinceLastSubmit)}
                                loading={submitting}
                                content={"Save"}
                            />
                            <Button.Or />
                            <Button
                                onClick={(event) => {
                                    event.preventDefault();
                                    closeModal();
                                }}
                                content={"Cancel"}
                            />
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    )
}

export default observer(BulkUpdateItemsWidget)