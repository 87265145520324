import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Button,
  Dimmer,
  Divider,
  List,
  Loader,
  Menu,
  Segment,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import InviteUserWidget from "./Components/InviteUserWidget";
import ShopUserListItem from "./Components/ShopUserListItem";

interface IProps {
  shopId?: string;
}

const Users: React.FC<IProps> = ({ shopId }) => {
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const { setPage } = context.analyticsStore;
  const { openModal } = context.modalStore;
  const { isShopAdmin, isOwner } = context.userStore;
  const { loading, users, load, hasMore, dispose } = context.shopUserStore;

  useEffect(() => {
    setPage("Users", window.location.pathname);

    load(shopId);

    return () => {
      dispose();
    };
  }, [load, dispose, setPage]);

  return (
    <div style={{ marginRight: "20px" }}>
      <Menu
        secondary
        size={isMobile ? "massive" : "small"}
        style={{ margin: "5px 0" }}
      >
        <Menu.Menu position="right">
          <Button
            className="ct-green-button-active"
            disabled={!isShopAdmin && !isOwner}
            icon={"plus"}
            content={"Invite"}
            onClick={() => {
              openModal(
                <InviteUserWidget />,
                "small",
                "Invite a new user to your shop",
                true,
                false
              );
            }}
          />
        </Menu.Menu>
      </Menu>
      <Divider style={isMobile ? { padding: "0", margin: "0" } : undefined} />
      {!loading || users.length > 0 ? (
        <div>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={users.length} //This is important field to render the next data
            next={() => load()}
            hasMore={hasMore}
            loader={loading ? <h4>Loading...</h4> : <div />}
          >
            <List
              divided
              relaxed
              style={isMobile ? { padding: "7px" } : undefined}
            >
              {users.map((x) => (
                <ShopUserListItem key={x.id} item={x} />
              ))}
            </List>
          </InfiniteScroll>
        </div>
      ) : (
        <Segment
          style={{
            minHeight: "55vh",
            boxShadow: "none",
            border: "none",
          }}
        >
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      )}
    </div>
  );
};

export default observer(Users);
