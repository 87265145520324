import { action, makeObservable, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../../../api/agent";
import { IShopUserForListDto, IUserForInvitation } from "../../../interfaces/user";
import { RootStore } from "../../../stores/RootStoreContext";

export class ShopUserStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable shopId?: string = undefined;
    @observable hasMore: boolean = false;
    @observable users: IShopUserForListDto[] = []

    @action setLoading = (value: boolean) => this.loading = value;
    @action setShopId = (value?: string) => this.shopId = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetUsers = (value: IShopUserForListDto[]) => this.users = value;
    @action setUsers = (value: IShopUserForListDto[]) => {
        var existing = this.users.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.users.push(...missing);
    };

    @action updateUser = async (newItem: IShopUserForListDto) => {
        return agent.Shop.Manage.updateUser(newItem.id, {
            roleId: newItem.roleId
        }).then(() => {
            var idx = this.users.findIndex(f => f.id === newItem.id);
            this.users[idx] = { ...newItem }
            this.resetUsers(this.users)
            toast.success(`User '${newItem.firstName}' update successfully`);
        })
    }

    @action unlinkUser = async (userId: string) => {
        return agent.Shop.Manage.unlinkUser(userId).then(() => {
            this.resetUsers(this.users.filter(f => f.id !== userId))
            toast.success(`User has no more access to your shop`);
        })
    }

    @action deleteUser = async (userId: string) => {
        return agent.Shop.Manage.deleteUser(userId).then(() => {
            this.resetUsers(this.users.filter(f => f.id !== userId))
            toast.success(`User has no more access to Cheap Trolley and your shop`);
        })
    }

    @action load = async (shopId?: string) => {
        try {
            shopId = shopId ?? this.shopId
            this.setLoading(true);
            this.setShopId(shopId);

            var response = await agent.Shop.Manage.getUsers(this.shopId ?? shopId, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.hasMore);
            this.setUsers(response.data);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action inviteUser = async (user: IUserForInvitation) => {
        user.accountId = this.shopId;
        const invitation = await agent.Shop.Manage.invite(user);

        runInAction(() => {
            this.users.unshift({
                id: invitation,
                ...user
            });
        });
    }

    @action dispose = () => {
        this.setShopId(undefined);
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetUsers([]);
        this.setHasMore(false);
    }
}