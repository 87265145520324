import { observer } from 'mobx-react-lite'
import React, { Fragment, SyntheticEvent, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import agent from '../../../api/agent';
import { IAssociationAnalysis } from '../../../interfaces/association';
import { Button, Divider, Form, Grid, Header, Icon, Menu, Table } from 'semantic-ui-react';
import ImageCarousel from '../../../components/ImageCarousel/ImageCarousel';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { ButtonTypes } from '../../confirmationModal/functions/ButtonTypes';
import ReactJson, { InteractionProps } from 'react-json-view';
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR, FormApi } from "final-form";
import TextInput from '../../../form/TextInput';
import CheckBox from '../../../form/CheckBox';
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import NumericInput from '../../../form/NumericInput';
import TextAreaInput from '../../../form/TextAreaInput';
import SelectInput from '../../../form/SelectInput';
import { IMeasurement } from '../../../interfaces/measurements';
import { toast } from 'react-toastify';

const analysis_nutrients = [
    "added_sugar",
    "carbohydrates",
    "cholesterol",
    "dietary_fibre",
    "energy_kcal",
    "energy_kj",
    "energy",
    "calories",
    "fat",
    "salt",
    "protein",
    "saturated_fat",
    "sodium",
    "sugar",
    "trans_fat"
];

interface FormProps {
    item: IAssociationAnalysis | undefined;
    measurements: IMeasurement[];
    formInstance: FormApi,
    setFormInstance: (value: FormApi) => void;
}

const MyForm = React.memo(({ item, measurements, formInstance, setFormInstance }: FormProps) => (
    <FinalForm
        initialValues={item?.analysis_data ? item : {
            "analysis_data": {},
            "additional_info": "",
            "isAnalyzed": false,
        }}
        mutators={{
            ...arrayMutators,
        }}
        onSubmit={(values: any) =>
            agent.Associations.Analysis.update(item!.id, values)
                .catch((error) => ({
                    [FORM_ERROR]: error,
                }))
        }
        render={({
            handleSubmit,
            submitting,
            invalid,
            dirtySinceLastSubmit,
            values,
            form: {
                mutators: { push, remove },
            },
            form,
        }) => {
            if (!formInstance) {
                setFormInstance(form);
            }

            return <Form
                onSubmit={handleSubmit}
                error
                className="attached fluid " //segment
            >
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Field>
                                <label style={{ fontSize: "13px" }}>{"Description"}</label>
                                <Field
                                    name="analysis_data.description"
                                    component={TextAreaInput}
                                    placeholder={"Description"}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{ fontSize: "13px" }}>{"Additives"}</label>
                                <Field
                                    name="analysis_data.additives"
                                    component={TextAreaInput}
                                    placeholder={"Additives"}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{ fontSize: "13px" }}>{"Ingredients"}</label>
                                <Field
                                    name="analysis_data.ingredients"
                                    component={TextAreaInput}
                                    placeholder={"Ingredients"}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{ fontSize: "13px" }}>{"Produced By"}</label>
                                <Field
                                    name="analysis_data.produced_by"
                                    component={TextAreaInput}
                                    placeholder={"Produced By"}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{ fontSize: "13px" }}>{"Additional Info"}</label>
                                <Field
                                    name="additional_info"
                                    component={TextAreaInput}
                                    placeholder={"Additional Info"}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label style={{ fontSize: "13px" }}>{"Allergies"}</label>
                                <Field
                                    name="analysis_data.allergies"
                                    component={TextAreaInput}
                                    placeholder={"Allergies"}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{ fontSize: "13px" }}>{"Diets"}</label>
                                <Field
                                    name="analysis_data.diets"
                                    component={TextAreaInput}
                                    placeholder={"Diets"}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{ fontSize: "13px" }}>{"Vitamins"}</label>
                                <Field
                                    name="analysis_data.vitamins"
                                    component={TextAreaInput}
                                    placeholder={"Vitamins"}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{ fontSize: "13px" }}>{"Storage Instructions"}</label>
                                <Field
                                    name="analysis_data.storage_instructions"
                                    component={TextAreaInput}
                                    placeholder={"Storage Instructions"}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{ fontSize: "13px" }}>{"Packaging"}</label>
                                <Field
                                    name="analysis_data.packaging"
                                    component={TextAreaInput}
                                    placeholder={"Packaging"}
                                />
                            </Form.Field>
                            <Form.Group>
                                <Form.Field>
                                    <label style={{ fontSize: "13px" }}>{"Measurement"}</label>
                                    <Field
                                        name="measurment"
                                        component={NumericInput}
                                        placeholder={"Measurement"}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label style={{ fontSize: "13px" }}>{"Measurement Unit"}</label>
                                    <Field
                                        component={SelectInput}
                                        options={measurements?.map((x) => ({
                                            key: x.key,
                                            value: x.key,
                                            text: x.description,
                                        }))}
                                        name="measurementUnit"
                                        placeholder={"Measurement Unit"}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <span style={{ marginLeft: "18px" }}>{analysis_nutrients.filter(f => values.analysis_data?.nutrients?.map((z: any) => z['nutrient']).includes(f) === false).slice().sort((a, b) => (a > b ? 1 : -1)).join("||")}</span>
                        <Grid.Column style={{ overflowX: "auto", width: "100%" }}>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{ width: "20%" }}>
                                            Nutrient
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: "20%" }}>
                                            Volume
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: "20%" }}>
                                            Measurement
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: "20%" }}>
                                            %
                                        </Table.HeaderCell>
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body >
                                    <FieldArray name="analysis_data.nutrients">
                                        {({ fields }) =>
                                            fields.map((name, index) => (
                                                <Table.Row key={index}>
                                                    <Table.Cell >
                                                        <Field
                                                            width="20%"
                                                            component={TextInput}
                                                            name={`${name}.nutrient`}
                                                            placeholder={"Nutrient"}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell >
                                                        <Field
                                                            width="20%"
                                                            component={NumericInput}
                                                            name={`${name}.volume`}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell >
                                                        <Field
                                                            width="20%"
                                                            component={TextInput}
                                                            name={`${name}.measurement`}
                                                            placeholder={"Measurement"}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell >
                                                        <Field
                                                            width="20%"
                                                            component={TextInput}
                                                            name={`${name}.percentage`}
                                                            placeholder={"Percentage"}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Button
                                                            color="red"
                                                            icon="minus"
                                                            circular
                                                            size="mini"
                                                            onClick={(event: SyntheticEvent) => {
                                                                remove("analysis_data.nutrients", index);
                                                                event.preventDefault();
                                                            }}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))
                                        }
                                    </FieldArray>
                                </Table.Body>

                                <Table.Footer fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan="9">
                                            <Button
                                                floated="right"
                                                basic
                                                color="blue"
                                                onClick={(event: SyntheticEvent) => {
                                                    push("analysis_data.nutrients", {});
                                                    event.preventDefault();
                                                }}
                                            >
                                                <Icon name="add" />{" "}
                                                Add
                                            </Button>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} textAlign='center'>
                        <Grid.Column>
                            <Form.Field>
                                <Field
                                    name="isAnalyzed"
                                    component={CheckBox}
                                    type="checkbox"
                                    label={
                                        <span>Analysis Complete</span>
                                    }
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div style={{ textAlign: "center", marginTop: "7px" }}>
                    <Button
                        disabled={invalid && !dirtySinceLastSubmit}
                        loading={submitting}
                        className="ct-green-button-active"
                        content={"Save"}
                    />
                </div>
            </Form>

        }
        }
    />
));

const ProductAnalysisDetail = () => {
    let { id } = useParams<"id">();
    const context = useContext(RootStoreContext);
    const { openConfirmationModal, closeConfirmationalModal } =
        context.confirmationModalStore;

    const [item, setItem] = useState<IAssociationAnalysis | undefined>(undefined);
    const [measurements, setMeasurements] = useState<IMeasurement[]>([]);
    const [ai_response, setAiResponse] = useState<any[]>([]);
    const [ai_response_copy, setAiResponseCopy] = useState<any[]>([]);
    const [processing, setProcessing] = useState<boolean>(false);
    const [formInstance, setFormInstance] = useState<any>(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageHidden, setImageHidden] = useState(false);

    const loadDetail = async (id: string) => {
        if (measurements.length === 0) {
            agent.ProductMeasurements.get().then(value => setMeasurements(value)).catch(() => { });
        }
        var result = await agent.Associations.Analysis.get_detail(id);
        setItem(result);
    }

    const next = async () => {

        var result = await agent.Associations.Analysis.next();
        const newId = result.id;
        const newUrl = window.location.origin + window.location.pathname.replace(/\/[^/]+$/, `/${newId}`);
        window.history.pushState({ path: newUrl }, '', newUrl);

        setAiResponse([]);
        setAiResponseCopy([]);
        setItem(result);
    }

    useEffect(() => {
        loadDetail(id as string);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleImageChange = (index: number) => {
        setCurrentImageIndex(index);
    };

    const analyze = () => {
        if ((item?.ai_images?.length ?? 0) > 0) {
            setProcessing(true);
            var imageUrl = item!.ai_images![currentImageIndex] ?? undefined;
            var finalUrl = `${process.env.REACT_APP_CLOUDFRONT}/${imageUrl}`;
            agent.AWS.Bucket.analyze(finalUrl).then((response) => {
                // console.log(response[0])

                if (ai_response.length === 0)
                    setAiResponse(response);
                else {
                    // console.log("first time!")
                    setAiResponse([...ai_response, response[0]]);
                }

                if (ai_response_copy.length === 0)
                    setAiResponseCopy(response);
                else {
                    // console.log("first time copy!")
                    setAiResponseCopy([...ai_response_copy, response[0]]);
                }

                setProcessing(false);
            }).catch(() => setProcessing(false));
        }
    };

    const analyze_all = async () => {
        if (!item?.ai_images) return;

        const toastId = toast('Starting process...', { autoClose: false });

        let responses: any[] = [];
        let progress = 0;

        const analyzeImages = item?.ai_images?.map(async (imageUrl, index) => {
            var finalUrl = `${process.env.REACT_APP_CLOUDFRONT}/${imageUrl}`;
            var response = await agent.AWS.Bucket.analyze(finalUrl).catch(() => toast.error(`Issue with image : ${imageUrl}`));

            responses.push(response[0]);

            progress = (index + 1) / (item?.ai_images?.length ?? 1);
            toast.update(toastId, {
                render: `Progress: ${(progress * 100).toFixed(2)}%`,
                type: toast.TYPE.INFO,
            });
        });

        await Promise.all(analyzeImages);

        toast.update(toastId, {
            render: 'Process completed!',
            type: toast.TYPE.SUCCESS,
            autoClose: 5000,
        });

        setAiResponse(responses);
        setAiResponseCopy(responses);
    };

    const extract_key = (field: string, key: string, obj: any): any => {
        try {
            var val = undefined;
            if (key.includes(".")) {
                var keys = key.split(".");
                if (keys.length === 2) {
                    val = obj[keys[0]][keys[1]]
                }
                if (keys.length === 3) {
                    val = obj[keys[0]][keys[1]][keys[2]]
                }
            }
            else {
                val = obj[key]
            }

            if (Array.isArray(val) && (val as any[]).length > 0) {
                if (typeof val[0] === 'string') {
                    val = val?.map(x => x.replaceAll("-", "_").replaceAll(" ", "_").toLowerCase()).join(",");
                }
            }
            else if (Array.isArray(val) && (val as any[]).length === 0) {
                val = "";
            }

            if (["not_specified", "not_provided"].includes(val?.trim().replaceAll(" ", "_").toLowerCase())) { } else
                if (val?.trim() !== "")
                    if (formInstance) {
                        var existing = (formInstance.getFieldState(`analysis_data.${field}`)).value;
                        if (existing)
                            (formInstance as any).change(`analysis_data.${field}`, existing + '\n' + val);
                        else
                            (formInstance as any).change(`analysis_data.${field}`, val);
                    }
        } catch (error) {
            toast.error(`Issue with ${field}, ${key}, ${JSON.stringify(obj)}`)
        }

    }

    const processNutrientValue = (value: string) => {
        // Regular expression to match the value and percentage parts
        var parts = value.split(" ")
        var percent_parts = parts.filter(f => f.includes("%"));

        var amount_str = value.split(" ")[0];
        var measuremt_unit = (amount_str.match(/[a-zA-Z]+/g) || []).join('').toLowerCase() ?? "";
        var amount = amount_str.replace(measuremt_unit, "");

        if (percent_parts.length > 0) {
            return {
                amount: ((amount.match(/-?\d+(\.\d+)?/g) || [])[0] as any) * 1,   // e.g., "0.4 g"
                percentage: (percent_parts[0].replace("*", "").replace("(", "").replace(")", "").replace("<", "").replace(">", "").replace("%", "") as any) * 1 // e.g., 0.5
            };
        } else {
            return {
                amount: ((amount.match(/-?\d+(\.\d+)?/g) || [])[0] as any) * 1,   // e.g., "0.4 g"
                percentage: undefined // e.g., 0.5
            };
        }
    }

    const process_ai_response = (response: any[]) => {
        if (item && !item.analysis_data)
            item.analysis_data = {};

        response.forEach(prop => {
            var identifiers = Object.keys(prop);

            if (identifiers.filter(key => key.includes("measurem")).length > 0) {
                var measur = prop[identifiers.filter(key => key.includes("measurem"))[0]]
                if (measur !== "not specified") {
                    var meas_unit = (measur?.match(/[a-zA-Z]+/g) || []).join('').toLowerCase();
                    var meas_val = ((measur?.match(/-?\d+(\.\d+)?/g) || [])[0] as any) * 1;
                    if (meas_unit.trim().toLowerCase() === "l") {
                        meas_val = meas_val * 1000;
                    }
                    var possible_units = measurements.filter(f => f.key.includes(meas_unit));
                    var actual_unit = possible_units.length > 0 ? possible_units[0].key : undefined;

                    if (formInstance) {
                        (formInstance as any).change(`measurment`, meas_val);
                        (formInstance as any).change("measurementUnit", actual_unit);
                    }
                }
            }
            if (identifiers.filter(key => key.includes("allerg")).length > 0) {
                extract_key("allergies", identifiers.filter(key => key.includes("allerg"))[0], prop);

            }
            if (identifiers.filter(key => key.includes("title")).length > 0) {
                extract_key("description", identifiers.filter(key => key.includes("title"))[0], prop);
            } else if (identifiers.filter(key => key.includes("descript")).length > 0) {
                extract_key("description", identifiers.filter(key => key.includes("descript"))[0], prop);
            }
            if (identifiers.filter(key => key.includes("diet")).length > 0) {
                extract_key("diets", identifiers.filter(key => key.includes("diet"))[0], prop);
            }
            if (identifiers.filter(key => key.includes("ingredie")).length > 0) {
                extract_key("ingredients", identifiers.filter(key => key.includes("ingredie"))[0], prop);
            }
            if (identifiers.filter(key => key.includes("additiv")).length > 0) {
                extract_key("additives", identifiers.filter(key => key.includes("additiv"))[0], prop);
            }
            if (identifiers.filter(key => key.includes("vitam")).length > 0) {
                extract_key("vitamins", identifiers.filter(key => key.includes("vitam"))[0], prop);
            }
            if (identifiers.filter(key => key.includes("packag")).length > 0) {
                var pack_key = identifiers.filter(key => key.includes("packag"))[0];
                var pack_obj = prop[pack_key];
                if (pack_obj?.hasOwnProperty("material"))
                    extract_key("packaging", `${pack_key}.material`, prop);
                else {
                    extract_key("packaging", `${pack_key}`, prop);
                }
            }
            if (identifiers.filter(key => key.includes("storag")).length > 0) {
                extract_key("storage_instructions", identifiers.filter(key => key.includes("storag"))[0], prop);
            }
            if (identifiers.filter(key => key.includes("produc")).length > 0) {
                extract_key("produced_by", identifiers.filter(key => key.includes("produc"))[0], prop);
            }
            if (identifiers.filter(key => key.includes("manufact")).length > 0) {
                extract_key("produced_by", identifiers.filter(key => key.includes("manufact"))[0], prop);
            }

            if (identifiers.filter(key => key.includes("nutrients_per_100")).length > 0) {
                var nutrients_tbl: any[] = [];
                var nutrients_obj = prop[identifiers.filter(key => key.includes("nutrients_per_100"))[0]];

                if (Array.isArray(nutrients_obj)) {
                    nutrients_obj = nutrients_obj.reduce((acc, nutrient) => {
                        const key = Object.keys(nutrient)[0];
                        acc[key.replaceAll(' ', "_").toLowerCase()] = nutrient[key];
                        return acc;
                    }, {});
                }

                // console.log(nutrients_obj);

                var nutrients_obj_identifiers = Object.keys(nutrients_obj);
                nutrients_obj_identifiers.forEach(nutri_idf => {
                    nutri_idf = nutri_idf.replaceAll(" ", "_").toLocaleLowerCase();
                    if (nutrients_obj_identifiers.filter(key => key.includes(nutri_idf)).length > 0) {
                        var nutr_key = nutrients_obj_identifiers.filter(key => key.includes(nutri_idf))[0];
                        var nutrient_val = nutrients_obj[nutr_key].replace("<not specified>", "")
                        var nutrient_idf = nutri_idf;
                        var processing = processNutrientValue(nutrient_val);

                        nutrients_tbl.push({
                            measurement: ((nutrient_val as string).match(/[a-zA-Z]+/g) || []).join('').toLowerCase() ?? undefined,
                            percentage: processing !== undefined ? processing['percentage'] : undefined, // 45
                            nutrient: nutrient_idf, // e.g. fat
                            volume: processing !== undefined ? processing['amount'] : undefined, // 45
                        })
                    }
                })

                if (formInstance) {
                    (formInstance as any).change(`analysis_data.nutrients`, nutrients_tbl);
                }
                // if (item)
                //     item.analysis_data["nutrients"] = nutrients_tbl;
            }

            if (identifiers.filter(key => key.includes("additional")).length > 0) {
                var additional_key = identifiers.filter(key => key.includes("additional"))[0]
                var additional_identifiers = Object.keys(prop[additional_key]);

                if (additional_identifiers.filter(key => key.includes("packag")).length > 0) {
                    var packaging_key = additional_identifiers.filter(key => key.includes("packag"))[0];
                    var packaging_obj = prop[additional_key][packaging_key];
                    if (packaging_obj.hasOwnProperty("material"))
                        extract_key("packaging", `${additional_key}.${packaging_key}.material`, prop);
                    else {
                        extract_key("packaging", `${additional_key}.${packaging_key}`, prop);
                    }
                }
                if (additional_identifiers.filter(key => key.includes("storag")).length > 0) {
                    extract_key("storage_instructions", `${additional_key}.${additional_identifiers.filter(key => key.includes("storag"))[0]}`, prop);
                }
                if (additional_identifiers.filter(key => key.includes("produc")).length > 0) {
                    extract_key("produced_by", `${additional_key}.${additional_identifiers.filter(key => key.includes("produc"))[0]}`, prop);
                }
                if (additional_identifiers.filter(key => key.includes("manufact")).length > 0) {
                    extract_key("produced_by", `${additional_key}.${additional_identifiers.filter(key => key.includes("manufact"))[0]}`, prop);
                }

            }
        });
    }

    const handleEdit = (edit: InteractionProps) => {
        setAiResponseCopy(edit.updated_src as any[]);
    };

    const handleAdd = (add: InteractionProps) => {
        setAiResponseCopy(add.updated_src as any[]);
    };

    const handleDelete = (del: InteractionProps) => {
        setAiResponseCopy(del.updated_src as any[]);
    };

    const handleReset = () => {
        setAiResponseCopy(ai_response);
    };

    return (
        <div className="simply-paddedPage">
            <Menu
                secondary
                size={"small"}
            >
                <Menu.Item>
                    <Header className='no-padding no-margin' content={item?.title} />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Button
                                className='ct-green-button'
                                content="Process"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    process_ai_response(ai_response);
                                }} />
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Button
                                className={imageHidden ? 'ct-green-button-active' : 'ct-green-button'}
                                content={imageHidden ? "Show Image" : "Hide Image"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setImageHidden(!imageHidden);
                                }} />
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Item>
                        <Button
                            className='ct-green-button'
                            content="Next"
                            onClick={next} />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider />
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column className='no-padding' textAlign='center'>
                        {!imageHidden ? <Fragment>
                            <div style={{ padding: "4px", left: "48%", position: "absolute", zIndex: 2 }} className='fxDisplay fxFixed'>
                                {(item?.ai_images?.length ?? 0) > 0 && <Fragment><Icon size='large' name="trash alternate" color='red' inverted circular onClick={() => {
                                    openConfirmationModal(
                                        "Confirm image deletion",
                                        "Are you sure you want to delete this image? This action is non reversable!",
                                        () =>
                                            agent.AWS.Bucket.delete(item!.ai_images![currentImageIndex]).then(() =>
                                                closeConfirmationalModal()
                                            ),
                                        "Cancel",
                                        "Delete",
                                        ButtonTypes.None,
                                        ButtonTypes.Negative
                                    );
                                }} />
                                    <Icon disabled={processing} loading={processing} size='large' name={processing ? "circle notch" : "lab"} color='pink' inverted circular onClick={() => {
                                        analyze();
                                    }} />
                                    <div style={{ position: "relative", display: "inline-block" }} onClick={() => {
                                        analyze_all();
                                    }}>
                                        <Icon disabled={processing} style={{ position: "absolute", zIndex: 3 }} size='large' name='lab' circular inverted color='yellow' />
                                        <Icon disabled={processing} style={{ position: "absolute", zIndex: 4, left: 25, top: 10, color: "red" }} name='add' />
                                    </div>

                                </Fragment>}
                            </div>
                            <ImageCarousel images={item?.ai_images?.map(x => (`${process.env.REACT_APP_CLOUDFRONT}/${x}`)) ?? []} onImageChange={handleImageChange} />
                        </Fragment> : <div style={{ maxHeight: "85vh", overflow: "auto", textAlign: "left" }}>
                            <div style={{ position: "absolute", right: 17, top: 5, zIndex: 2 }}>
                                <Icon size='large' name={"undo"} color='blue' inverted circular onClick={() => {
                                    handleReset();
                                }} />
                            </div>
                            <ReactJson
                                theme={"solarized"}
                                src={ai_response_copy}
                                onEdit={handleEdit}
                                onAdd={handleAdd}
                                onDelete={handleDelete}
                            />
                        </div>}
                    </Grid.Column>
                    <Grid.Column>
                        {!imageHidden && <div style={{ maxHeight: "45vh", overflow: "auto" }}>
                            <div style={{ position: "absolute", right: 17, top: 5, zIndex: 2 }}>
                                <Icon size='large' name={"undo"} color='blue' inverted circular onClick={() => {
                                    handleReset();
                                }} />
                            </div>
                            <ReactJson
                                theme={"solarized"}
                                src={ai_response_copy}
                                onEdit={handleEdit}
                                onAdd={handleAdd}
                                onDelete={handleDelete}
                            />
                        </div>}
                        <div style={{ marginTop: "4px", maxHeight: "85vh", overflowY: "auto", overflowX: "hidden" }}>
                            <MyForm item={item} formInstance={formInstance} setFormInstance={setFormInstance} measurements={measurements} />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div >
    )
}

export default observer(ProductAnalysisDetail)

