import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, Fragment, useContext, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router';
import { Button, Checkbox, Dimmer, Divider, Icon, Input, Loader, Menu, Segment, Table } from 'semantic-ui-react';
import { RootStoreContext } from '../../stores/RootStoreContext';
import LabelTableRow from './components/LabelTableRow';

const LabelsList = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const {
        load,
        labels,
        dispose,
        loading,
        hasMore,
        search,
        searchText,
        setReviewed,
        count
    } = context.labelListStore;


    const [timer, setTimer] = React.useState(0);

    useEffect(() => {
        load();

        return () => {
            dispose();
        }
    }, [load, dispose])


    return (
        <div>
            <Fragment>
                <Menu secondary>
                    <Menu.Item
                        className={!isMobile ? "no-padding" : ""}
                        style={{ flex: isMobile ? 1 : "" }}
                    >
                        <Input
                            fluid={isMobile}
                            // disabled={loading || !search}
                            transparent={isMobile}
                            size={isMobile ? "large" : "small"}
                            icon={
                                !isMobile ? <Icon name="search" color="blue" /> : undefined
                            }
                            placeholder={"Search..."}
                            input={
                                <input className={isMobile ? "" : "bluePlaceholder"} />
                            }
                            onChange={(event: ChangeEvent, data: any) => {
                                if (!search) return;
                                if (timer != null) {
                                    clearTimeout(timer);
                                    setTimer(0);
                                }

                                setTimer(
                                    setTimeout(
                                        (callback: (text: string) => void, text: string) => {
                                            callback(text);
                                            clearTimeout(timer);
                                        },
                                        500,
                                        search,
                                        data.value
                                    )
                                );
                            }}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Checkbox
                            label="Reviewed"
                            toggle
                            onChange={(e, data) => {
                                setReviewed(data.checked ?? false);
                                search(searchText);
                            }}
                        />
                    </Menu.Item>
                    <Menu.Item>{loading ? "---" : <b>{count}</b>}</Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Button
                                color="blue"
                                basic
                                icon={"wizard"}
                                content={"Labels Wizard"}
                                onClick={() => {
                                    navigate("/labels-dictionary");
                                }}
                            />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Divider />
                {(!loading || labels.length > 0) ? (
                    <div>
                        <InfiniteScroll
                            style={{ overflow: "hidden" }}
                            dataLength={labels.length} //This is important field to render the next data
                            next={() => load()}
                            hasMore={hasMore}
                            loader={loading ? <h4>Loading...</h4> : <div />}

                        >

                            <Table basic="very" celled>
                                <Table.Header>
                                    <Table.Row textAlign='center'>
                                        <Table.HeaderCell>Reviewed</Table.HeaderCell>
                                        <Table.HeaderCell>Ignore</Table.HeaderCell>
                                        <Table.HeaderCell>Stems</Table.HeaderCell>
                                        <Table.HeaderCell>Originals</Table.HeaderCell>
                                        <Table.HeaderCell>Translations/Conversions</Table.HeaderCell>
                                        <Table.HeaderCell>Info</Table.HeaderCell>
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {labels.map((x) => (
                                        <LabelTableRow label={x} />
                                    ))}
                                </Table.Body>
                            </Table>
                        </InfiniteScroll>
                    </div>
                ) : (
                    <Segment
                        style={{
                            minHeight: "55vh",
                            boxShadow: "none",
                            border: "none",
                        }}
                    >
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    </Segment>
                )}
            </Fragment>

        </div>
    )
}


export default observer(LabelsList)