import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Header, Table, Image, Popup, Icon } from "semantic-ui-react";
import { ITrackerProductForList } from "../../../interfaces/product";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { currencyFormat } from "../../products/functions/productHelper";
import PriceTrackerDetail from "./PriceTrackerDetail";
import { ISuperMarket } from "../../../interfaces/supermarket";
import { getImageUrlPlain } from "../../../helpers/productImage";

interface IProps {
  product: ITrackerProductForList;
  accountId: string;
}

const PriceTrackerTableRow: React.FC<IProps> = ({ product, accountId }) => {
  const context = useContext(RootStoreContext);
  const { openModal } = context.modalStore;
  const { isAdmin } = context.userStore;
  const { isDeliveryEnabled } = context.accountPageStore;
  const { markets, selectedMarketIds, percentage, shopCategories } = context.priceTrackerStore;
  const {
    toggleDelivery,
    updatingDelivery } = context.inventoryStore;

  function getPriceDiffToShow(x: ISuperMarket, y: number) {
    if (y <= 0) return undefined;

    var price = product.others?.filter(f => f.marketId === x.type_id).map(z => z.currentPrice).sort((a, b) => (a < b ? -1 : 1))[0]
    if (price && price > 0 && y > price) {
      return percentage ? `${((price - y) / price * 100).toFixed(2)}%` : currencyFormat(price - y, true);
    }
  }

  function hasOffers(x: ISuperMarket) {
    return (product.others?.filter(f => f.marketId === x.type_id && f.textOffer) ?? []).length > 0;
  }

  function getOffers(x: ISuperMarket) {
    return product.others?.filter(f => f.marketId === x.type_id && f.textOffer) ?? [];
  }

  return (
    <Table.Row>
      {isAdmin && isDeliveryEnabled && <Table.Cell textAlign="center">
        <Icon
          inverted={updatingDelivery !== product.id}
          name={updatingDelivery === product.id ? "circle notch" : "truck"}
          loading={updatingDelivery === product.id}
          disabled={!product.associationId || (product.locations?.length ?? 0) === 0 || updatingDelivery !== ''}
          title={(!product.associationId || (product.locations?.length ?? 0) === 0) ? "The product needs to be grouped & have an outlet before being available for delivery - Contact admin for more details" : (product.delivery ? "Disable delivery" : "Enable delivery")}
          color={(product.delivery ?? false) ? "green" : "grey"}
          circular
          onClick={() => {
            toggleDelivery(product.id).then(() => { product.delivery = !(product.delivery ?? false) });
          }}
        />
      </Table.Cell>}
      <Table.Cell error={product.discontinued}>
        <div className="fxDisplay fxStretch fxFixed">
          <Popup
            trigger={
              <Image
                src={getImageUrlPlain(product.marketId, product?.imageUrl)}
                alt="Product Logo"
                size="mini"
                style={{ objectFit: "contain" }}
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                }}
              />
            }
            content={
              <Image
                src={product?.imageUrl}
                alt="Product Logo"
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                }}
              />
            }
          />

          <Header as="h4" style={{ padding: "0", margin: "0", paddingLeft: "7px", minWidth: "180px" }}>
            {product?.itemDesc}
            <Header.Subheader className="fluid">{product?.sku}</Header.Subheader>
            {isAdmin && <Header.Subheader className="fluid">{shopCategories.filter(f => f.internalId === product?.categoryId)[0]?.internalName}</Header.Subheader>}
          </Header>
        </div>
      </Table.Cell>
      <Table.Cell singleLine textAlign="center" positive={(product.others?.length ?? 0) > 0 && (product.others?.filter(f => f.currentPrice <= product.currentPrice).length ?? -1) === 0}>
        {currencyFormat(product.currentPrice, true)}
        {product && product.textOffer &&
          <Fragment>
            <br />
            <Popup
              trigger={
                <span title={product.textOffer} className="linkable-with-decoration"><Icon fitted size="small" name="percent" title={product.textOffer} /> Offer</span>
              }
              content={product.textOffer}
              on="click"
            />
          </Fragment>
        }
      </Table.Cell>
      {(selectedMarketIds.length > 0 ? markets.filter(f => selectedMarketIds.includes(f.type_id)) : markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1))
        .filter(f => f.id !== accountId && !f.shutDown)
        .map(x =>
          <Popup
            key={x.id}
            on='hover'
            position="left center"
            content={hasOffers(x) ? <div><b>{x.title}</b><p>{getOffers(x).map(prd =>
              <p key={prd.id}>{prd.textOffer}</p>
            )}</p></div> : x.title}
            trigger={
              <Table.Cell singleLine textAlign="center" negative={(product.others?.filter(f => f.marketId === x.type_id && f.currentPrice < product.currentPrice).length ?? 0) > 0}
              >
                {product.others
                  ?.filter(f => f.marketId === x.type_id)
                  .map(z => currencyFormat(z.currentPrice, true)).sort((a, b) => (a < b ? -1 : 1))[0]
                }
                <br />
                <span style={{ fontSize: '13px' }}>{getPriceDiffToShow(x, product.currentPrice)}</span>
                {hasOffers(x) && <Fragment>
                  <br />
                  <span title={product?.textOffer} className="linkable-with-decoration">
                    <Icon fitted size="small" name="percent" title={product?.textOffer} />
                    {" "}Offer
                  </span>
                </Fragment>}
              </Table.Cell>
            }
          />
        )}
      <Table.Cell singleLine>
        <Icon
          inverted
          name="info"
          color="blue"
          circular
          disabled={product.others === undefined || product.others.length === 0}
          onClick={() => {
            openModal(<PriceTrackerDetail id={product.id} accountId={accountId} />, "large", "Price Comparison", true, false)
          }}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default observer(PriceTrackerTableRow);
