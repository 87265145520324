import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Header } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";

const DownloadAppButton = () => {
  const context = useContext(RootStoreContext);
  const { os, downloadAppWeb } = context.deviceStore;
  const { closeModal } = context.modalStore;
  return (
    <div>
      <Header size="large" textAlign="center" color="black">
        You can now <span className="ct-green-text">download</span> our{" "}
        <span className="ct-green-text">mobile app</span> for free!
      </Header>
      <div style={{ textAlign: "center" }}>
        <img
          style={{
            width: "200px",
            padding: "5px",
          }}
          alt="app store button"
          src={os === "android" ? "/stores/android.png" : "/stores/ios.png"}
          onClick={() => {
            window.localStorage.setItem("app-popup", "0");
            downloadAppWeb(os ?? "android");
          }}
        />
        <div className="clickable" onClick={() => {
          window.localStorage.setItem("app-popup", "0");
          closeModal()
        }}>
          Continue on browser
        </div>
      </div>
    </div>
  );
};

export default observer(DownloadAppButton);
