import { formatRelative } from "date-fns";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { FormApi, FORM_ERROR } from "final-form";
import arrayMutators from "final-form-arrays";
import React, { ChangeEvent, Fragment, useContext, useEffect } from "react";
import {
  Dimmer,
  Divider,
  Dropdown,
  DropdownProps,
  Icon,
  Input,
  Loader,
  Menu,
  Segment,
  Image,
  Label,
  Button,
  Form,
  Checkbox,
} from "semantic-ui-react";
import { LoadingParagraph } from "../../components/LoadingParagraph";
import ErrorMessage from "../../form/ErrorMessage";
import { getImageUrlPlain } from "../../helpers/productImage";
import { getMarketImageUrl } from "../../helpers/supermarketImage";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { currencyFormat } from "./functions/productHelper";
import { IProductDetail } from "../../interfaces/product";
import TextInput from "../../form/TextInput";
import NumericInput from "../../form/NumericInput";
import SelectInput from "../../form/SelectInput";
import { combineValidators } from "revalidate";
import { compartments } from "../../options/compartments";
import CheckBox from "../../form/CheckBox";
import CreateUpdateCategoryWidget from "../categories/CreateUpdateCategoryWidget";
import CreateUpdateMeasurementWidget from "../measurementUnits/CreateUpdateMeasurementWidget";
import { ProductReviewOptions } from "../../options/productReviewOptions";

const validate = combineValidators({
  //   title: isRequired(""),
});

interface IProps {
  id?: string;
}

const VerifyProducts: React.FC<IProps> = ({ id }) => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const { openModal, closeModal } = context.modalStore;
  const {
    loadNext,
    dispose,
    loading,
    product,
    count,
    search,
    save,
    searchText,
    selectedMarketIds,
    setSelectedMarketIds,
    selectedCategoryIds,
    setSelectedCategoryIds,
    categories,
    refreshCategories,
    setDiscontinued,
    measurements,
    refreshMeasurements,
    setSelectedReviewIds,
    selectedReviewIds,
    unverifiedOnly,
    setUnverified
  } = context.unverifiedStore;

  const [timer, setTimer] = React.useState(0);
  // const [preferedCategories, setTimer] = React.useState(0);
  // const [timer, setTimer] = React.useState(0);

  useEffect(() => {
    loadNext(id);
    return () => {
      dispose();
    };
  }, [loadNext, dispose, id]);

  return (
    <div>
      <Segment>
        {loading && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}

        {id === undefined && <Fragment>
          <Menu secondary>
            <Menu.Item>
              <Input
                fluid={false}
                transparent={false}
                size={"small"}
                icon={<Icon name="search" color="blue" />}
                placeholder={"Search..."}
                input={<input className={"bluePlaceholder"} />}
                onChange={(event: ChangeEvent, data: any) => {
                  if (!search) return;
                  if (timer != null) {
                    clearTimeout(timer);
                    setTimer(0);
                  }

                  setTimer(
                    setTimeout(
                      (callback: (text: string) => void, text: string) => {
                        callback(text);
                        clearTimeout(timer);
                      },
                      500,
                      search,
                      data.value
                    )
                  );
                }}
              />
            </Menu.Item>
            <Menu.Item style={{ padding: "0px" }}>
              <Dropdown
                placeholder={"Filter stores"}
                value={selectedMarketIds}
                closeOnBlur
                selection
                multiple
                clearable
                search
                onChange={(ev, data: DropdownProps) => {
                  setSelectedMarketIds((data?.value ?? []) as string[]);
                  if (!search) return;
                  if (timer != null) {
                    clearTimeout(timer);
                    setTimer(0);
                  }

                  setTimer(
                    setTimeout(
                      (callback: (text: string) => void, text: string) => {
                        callback(searchText);
                        clearTimeout(timer);
                      },
                      500,
                      search
                    )
                  );
                }}
                options={markets
                  ?.slice()
                  .sort((a, b) => (a.title < b.title ? -1 : 1))
                  .map((x) => ({
                    key: x.type_id,
                    text: x.title,
                    value: x.type_id,
                  }))}
              />
            </Menu.Item>
            <Menu.Item style={{ padding: "0px" }}>
              <Dropdown
                placeholder={"Filter categories"}
                value={selectedCategoryIds}
                closeOnBlur
                selection
                multiple
                clearable
                search
                onChange={(ev, data: DropdownProps) => {
                  setSelectedCategoryIds((data?.value ?? []) as string[]);
                  if (!search) return;
                  if (timer != null) {
                    clearTimeout(timer);
                    setTimer(0);
                  }

                  setTimer(
                    setTimeout(
                      (callback: (text: string) => void, text: string) => {
                        callback(searchText);
                        clearTimeout(timer);
                      },
                      500,
                      search
                    )
                  );
                }}
                options={categories
                  ?.slice()
                  .sort((a, b) => (a.order < b.order ? -1 : 1))
                  .map((x) => ({
                    key: x.internalId,
                    text: x.description,
                    value: x.internalId,
                  }))}
              />
            </Menu.Item>
            <Menu.Item style={{ padding: "0px" }}>
              <Dropdown
                placeholder={"Filter Review Reasons"}
                value={selectedReviewIds}
                closeOnBlur
                selection
                multiple
                clearable
                search
                onChange={(ev, data: DropdownProps) => {
                  setSelectedReviewIds((data?.value ?? []) as string[]);
                  if (!search) return;
                  if (timer != null) {
                    clearTimeout(timer);
                    setTimer(0);
                  }

                  setTimer(
                    setTimeout(
                      (callback: (text: string) => void, text: string) => {
                        callback(searchText);
                        clearTimeout(timer);
                      },
                      500,
                      search
                    )
                  );
                }}
                options={ProductReviewOptions}
              />
            </Menu.Item>
            <Menu.Item>
              <Checkbox
                label="Include discontinued"
                toggle
                onChange={(e, data) => {
                  setDiscontinued(data.checked ?? false);
                  search(searchText);
                }}
              />
            </Menu.Item>
            <Menu.Item>
              <Checkbox
                defaultChecked={unverifiedOnly}
                label="Unverified only"
                toggle
                onChange={(e, data) => {
                  setUnverified(data.checked ?? false);
                  search(searchText);
                }}
              />
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>{loading ? "---" : <b>{count}</b>} op</Menu.Item>
            </Menu.Menu>
          </Menu>
          <Divider />
        </Fragment>}
        {markets.length > 0 && categories?.length > 0 && product && (
          <div className="fxDisplay fxFixed">
            <div className="fxDisplay fxFixed">
              {product ? (
                <Image
                  alt="Product Logo"
                  src={
                    getImageUrlPlain(product?.marketId, product?.imageUrl) ??
                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                  }
                  onError={({ currentTarget }: any) => {
                    currentTarget.onerror = null;
                    currentTarget.src =
                      `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                  }}
                  className="fxFixed"
                  style={{
                    maxWidth: "225px",
                    maxHeight: "150px",
                    alignSelf: "center",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Image
                  src={
                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                  }
                />
              )}
            </div>

            <div className="fxStretch fxDisplay  fxDirCol">
              {loading && <LoadingParagraph lines={7} />}
              <p>
                <b style={{ color: "red" }}> {product.itemDesc}</b>
              </p>
              <div className="fxDisplay">
                <div className="subText">
                  <p>
                    <b>_id :</b> {product.id}
                  </p>
                  <p>
                    <b>Association Id :</b> {product.associationId ?? "-"}
                  </p>
                  {product.lastUpdated && (
                    <p>
                      <b>Last Update :</b>{" "}
                      {formatRelative(
                        new Date(product.lastUpdated * 1000),
                        new Date(),
                        {}
                      )}
                    </p>
                  )}
                  <p>
                    <b>Internal Id :</b> {product.internalId}
                  </p>
                </div>

                <div style={{ marginLeft: "25px" }}>
                  <p>
                    <b>Category Id :</b> {product.categoryId ?? "-"}
                  </p>
                </div>

                <div style={{ marginLeft: "25px" }}>
                  <p>
                    <b>Price :</b> {currencyFormat(product.currentPrice)}
                  </p>
                  <p>
                    <b>Units :</b>{" "}
                    {product.units
                      ?.map((x) => `${x.value ?? 0}/${x.unit ?? "-"}`)
                      .join(", ") ?? "-"}
                  </p>
                  {product.discontinued && (
                    <p>
                      <Label color="red" basic content="Discontinued" />
                    </p>
                  )}
                </div>
              </div>
            </div>
            <hr />
            <div
              className="fxFixed leftSection fxAlignCenter"
              style={{ width: "10%" }}
            >
              <div style={{ marginBottom: "0.5em" }}>
                {product ? (
                  <Fragment>
                    <Image
                      alt="Product Logo"
                      src={getMarketImageUrl(
                        markets?.filter(
                          (f) => f.type_id === product.marketId
                        )[0]
                      )}
                    />
                    <p>
                      {
                        markets?.filter(
                          (f) => f.type_id === product.marketId
                        )[0].title
                      }
                    </p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Image
                      alt="Product Logo"
                      centered
                      style={{ padding: "3px" }}
                      size="tiny"
                    />
                    <LoadingParagraph />
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        )}
        <Divider />
        {markets.length > 0 && categories?.length > 0 && product && (
          <FinalForm
            onSubmit={(values: IProductDetail) =>
              save(values).catch((error: any) => ({
                [FORM_ERROR]: error,
              }))
            }
            initialValues={product}
            validate={validate}
            mutators={{
              ...arrayMutators,
            }}
            render={({
              handleSubmit,
              submitError,
              dirtySinceLastSubmit,
              submitting,
              form,
              values,
              form: {
                mutators: { push, remove },
              },
            }) => {
              (window as any).groupForm = form;

              return (
                <Form onSubmit={handleSubmit} error>
                  <div>
                    <div style={{ textAlign: "right" }}>
                      {values?.imageUrl && (
                        <span
                          title="Remove Image"
                          className="item linkable"
                          onClick={() => {
                            ((window as any).groupForm as FormApi).change(
                              "imageUrl",
                              undefined
                            );
                          }}
                        >
                          <i
                            aria-hidden="true"
                            className="red large fitted icon file image outline"
                          ></i>{" "}
                          Remove Image
                        </span>
                      )}
                    </div>

                    <div
                      className="fxDirCol fxStretch fxDisplay"
                      style={{ padding: "7px" }}
                    >
                      <Form.Group widths="equal">
                        <Form.Field style={{ textAlign: "left" }}>
                          <label style={{ fontSize: "13px" }}>
                            {"Brand Name"}
                          </label>
                          <Field
                            loading={loading}
                            component={TextInput}
                            name="brandName"
                            placeholder={"Brand Name"}
                          />
                          {product.itemDesc.split(" ").map((f, i) =>
                            i < 3 ? (
                              <Label
                                color="blue"
                                basic
                                size="small"
                                style={{ marginTop: "4px" }}
                                content={product.itemDesc.split(" ")[i]}
                                onClick={() => {
                                  ((window as any).groupForm as FormApi).change(
                                    "brandName",
                                    (values.brandName ?? "") +
                                    " " +
                                    product.itemDesc.split(" ")[i]
                                  );
                                }}
                              />
                            ) : undefined
                          )}
                        </Form.Field>
                        <Form.Field style={{ textAlign: "left" }}>
                          <label style={{ fontSize: "13px" }}>
                            {"Product SKU"}
                          </label>
                          <Field
                            loading={loading}
                            component={TextInput}
                            name="sku"
                            placeholder={"Product SKU"}
                          />
                          {product.sku && (
                            <a
                              className="grey"
                              href={`https://www.google.com/search?q=${product.sku}&sourceid=chrome&ie=UTF-8`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Google Search
                            </a>
                          )}
                        </Form.Field>
                      </Form.Group>

                      <Form.Group widths="equal">
                        <Form.Field style={{ textAlign: "left" }}>
                          <label style={{ fontSize: "13px" }}>
                            {"Cheap Trolley Categories"}
                          </label>
                          <Field
                            loading={loading}
                            multiselect={true}
                            component={SelectInput}
                            options={categories.map((x) => ({
                              key: x.internalId,
                              value: x.internalId,
                              text: x.description,
                            }))}
                            name="ct_categories"
                            placeholder={"Select category(ies)"}
                          />
                          <Label
                            className="button-as-link clickable"
                            content="Create New Category"
                            onClick={() => {
                              openModal(
                                <CreateUpdateCategoryWidget
                                  categories={categories}
                                  showCloseIcon={false}
                                  close={closeModal}
                                />,
                                "mini",
                                "Create new Category",
                                true,
                                false,
                                () => {
                                  refreshCategories();
                                }
                              );
                            }}
                          />
                        </Form.Field>

                        <Form.Field style={{ textAlign: "left" }}>
                          <label style={{ fontSize: "13px" }}>
                            {"Compartment"}
                          </label>
                          <Field
                            loading={loading}
                            component={SelectInput}
                            options={compartments}
                            name="compartment"
                            placeholder={"Select compartment"}
                          />
                          {compartments.map((f) => (
                            <Label
                              color="blue"
                              basic
                              size="small"
                              style={{ marginTop: "4px" }}
                              content={f.text}
                              onClick={() => {
                                ((window as any).groupForm as FormApi).change(
                                  "compartment",
                                  f.value
                                );
                              }}
                            />
                          ))}
                        </Form.Field>
                        <Form.Field>
                          <label style={{ fontSize: "13px" }}>{"Labels"}</label>
                          <Field
                            loading={loading}
                            multiselect
                            component={SelectInput}
                            allowAdditions={true}
                            onAddItem={(item: any) => console.log(item)}
                            options={
                              values.labels?.map((x: string) => ({
                                key: x,
                                value: x,
                                text: x,
                              })) ?? []
                            }
                            name="labels"
                            placeholder={"Labels"}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field style={{ textAlign: "left" }}>
                          <label style={{ fontSize: "13px" }}>
                            {"Measurement Unit"}
                          </label>
                          <Field
                            loading={loading}
                            component={SelectInput}
                            options={measurements?.map((x) => ({
                              key: x.key,
                              value: x.key,
                              text: x.description,
                            }))}
                            name="measurementUnit"
                            placeholder={"Measurement Unit"}
                          />
                          <Label
                            className="button-as-link clickable"
                            content="Create New Measurement Unit"
                            onClick={() => {
                              openModal(
                                <CreateUpdateMeasurementWidget />,
                                "mini",
                                "Create new Measurement Unit",
                                true,
                                true,
                                () => {
                                  refreshMeasurements();
                                }
                              );
                            }}
                          />
                          {(product.units?.length ?? 0) > 0 && (
                            <Label
                              color="blue"
                              basic
                              size="small"
                              style={{ marginTop: "4px" }}
                              content={product.units[0].unit}
                              onClick={() => {
                                ((window as any).groupForm as FormApi).change(
                                  "measurementUnit",
                                  product.units[0].unit
                                );
                              }}
                            />
                          )}
                        </Form.Field>
                        <Form.Field style={{ textAlign: "left" }}>
                          <label style={{ fontSize: "13px" }}>
                            {"Measurment"}
                          </label>
                          {loading ? (
                            <LoadingParagraph />
                          ) : (
                            <Field
                              component={NumericInput}
                              name="measurment"
                              placeholder={"Measurment e.g. 700"}
                            />
                          )}
                          {(product.units?.length ?? 0) > 0 ? (
                            <Label
                              color="blue"
                              basic
                              size="small"
                              style={{ marginTop: "4px" }}
                              content={product.units[0].value}
                              onClick={() => {
                                ((window as any).groupForm as FormApi).change(
                                  "measurment",
                                  product.units[0].value
                                );
                              }}
                            />
                          ) : (
                            <Fragment>
                              <Label
                                color="blue"
                                basic
                                size="small"
                                style={{ marginTop: "4px" }}
                                content="100"
                                onClick={() =>
                                  ((window as any).groupForm as FormApi).change(
                                    "measurment",
                                    100
                                  )
                                }
                              />
                              <Label
                                color="blue"
                                basic
                                size="small"
                                style={{ marginTop: "4px" }}
                                content="500"
                                onClick={() =>
                                  ((window as any).groupForm as FormApi).change(
                                    "measurment",
                                    500
                                  )
                                }
                              />
                              <Label
                                color="blue"
                                basic
                                size="small"
                                style={{ marginTop: "4px" }}
                                content="750"
                                onClick={() =>
                                  ((window as any).groupForm as FormApi).change(
                                    "measurment",
                                    750
                                  )
                                }
                              />
                              <Label
                                color="blue"
                                size="small"
                                basic
                                style={{ marginTop: "4px" }}
                                content="1000"
                                onClick={() =>
                                  ((window as any).groupForm as FormApi).change(
                                    "measurment",
                                    1000
                                  )
                                }
                              />
                            </Fragment>
                          )}
                        </Form.Field>

                        <Form.Field style={{ textAlign: "left" }}>
                          <label style={{ fontSize: "13px" }}>
                            {"Price Per Measurement Unit"}
                          </label>
                          {loading ? (
                            <LoadingParagraph />
                          ) : (
                            <Field
                              component={NumericInput}
                              name="pricePerUnit"
                              placeholder={"Price / Measurment"}
                            />
                          )}
                          <Label
                            color="blue"
                            basic
                            size="small"
                            style={{ marginTop: "4px" }}
                            content="Auto"
                            onClick={() =>
                              ((window as any).groupForm as FormApi).change(
                                "pricePerUnit",
                                values.currentPrice / (values.measurment ?? 1)
                              )
                            }
                          />
                        </Form.Field>
                        <Form.Field style={{ textAlign: "center" }}>
                          <label style={{ fontSize: "13px" }}>
                            {"By Weight"}
                          </label>
                          {loading ? (
                            <LoadingParagraph />
                          ) : (
                            <Field
                              type="checkbox"
                              loading={loading}
                              component={CheckBox}
                              toggle
                              name="byWeight"
                            />
                          )}
                        </Form.Field>
                        <Form.Field style={{ textAlign: "left" }}>
                          <label style={{ fontSize: "13px" }}>{"Step"}</label>
                          {loading ? (
                            <LoadingParagraph />
                          ) : (
                            <Field
                              component={NumericInput}
                              disabled={!values.byWeight}
                              name="step"
                              placeholder={"e.g. 100"}
                            />
                          )}
                          {values.byWeight && (
                            <Fragment>
                              <Label
                                color="blue"
                                basic
                                size="small"
                                style={{ marginTop: "4px" }}
                                content={`0.1 of ${values.measurment ?? 0}`}
                                onClick={() =>
                                  ((window as any).groupForm as FormApi).change(
                                    "step",
                                    0.1
                                  )
                                }
                              />
                              <Label
                                color="blue"
                                basic
                                size="small"
                                style={{ marginTop: "4px" }}
                                content={`1 of ${values.measurment ?? 0}`}
                                onClick={() =>
                                  ((window as any).groupForm as FormApi).change(
                                    "step",
                                    1
                                  )
                                }
                              />
                            </Fragment>
                          )}
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field>
                          <label style={{ fontSize: "13px" }}>
                            {"Reviewed For"}
                          </label>
                          <Field
                            loading={loading}
                            multiselect
                            component={SelectInput}
                            allowAdditions={true}
                            onAddItem={(item: any) => console.log(item)}
                            options={ProductReviewOptions}
                            name="reviewedFor"
                            placeholder={"Reviewed For"}
                          />
                          <Label
                            color="blue"
                            basic
                            size="small"
                            style={{ marginTop: "4px" }}
                            content={`All`}
                            onClick={() =>
                              ((window as any).groupForm as FormApi).change(
                                "reviewedFor",
                                ProductReviewOptions.map((x) => x.key)
                              )
                            }
                          />
                          {ProductReviewOptions.filter(
                            (f) =>
                              (values.reviewedFor ?? []).findIndex(
                                (x: string) => x === f.value
                              ) < 0
                          ).map((f) => (
                            <Label
                              color="blue"
                              basic
                              size="small"
                              style={{ marginTop: "4px" }}
                              content={f.text}
                              onClick={() => {
                                var idx = (values.reviewedFor ?? []).findIndex(
                                  (x: string) => x === f.value
                                );
                                if (idx > -1) remove("reviewedFor", idx);
                                else push("reviewedFor", f.value);
                              }}
                            />
                          ))}
                        </Form.Field>

                        <Form.Field style={{ textAlign: "center" }}>
                          <label style={{ fontSize: "13px" }}>
                            {"Verified"}
                          </label>
                          {loading ? (
                            <LoadingParagraph />
                          ) : (
                            <Field
                              type="checkbox"
                              loading={loading}
                              component={CheckBox}
                              toggle
                              name="verified"
                            />
                          )}
                        </Form.Field>
                      </Form.Group>
                    </div>
                  </div>
                  {submitError && !dirtySinceLastSubmit && (
                    <ErrorMessage error={submitError} />
                  )}

                  <div style={{ textAlign: "center" }}>
                    <Button
                      color="blue"
                      loading={submitting}
                      content={"Save"}
                    />
                  </div>
                </Form>
              );
            }}
          />
        )}
      </Segment>
    </div>
  );
};

export default observer(VerifyProducts);
