import { action, makeAutoObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IShopLocation } from "../../../interfaces/locations";

class DeliveryScheduler {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable editingItem?: any = undefined;
    @observable locations: IShopLocation[] = [];

    @action setLocations = (value: IShopLocation[]) => this.locations = value;
    @action setEditingItem = (value: any) => this.editingItem = value;

    @action load = async (countryId: string) => {
        this.loading = true;

        try {
            var locations = await agent.Shop.Locations.Admin.all();
            this.setLocations(locations);

            var config = await agent.GlobalConfig.Countries.Schedule.get(countryId);
            this.setEditingItem(config);

        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    @action saveItem = async (countryId: string, values: any) => {
        return agent.GlobalConfig.Countries.Schedule.update(countryId, values);
    };
}


export const DeliverySchedulerStore = new DeliveryScheduler();