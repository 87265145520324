import { SemanticCOLORS } from "semantic-ui-react";

export function getPromoStatus(startsOn: number, endsOn: number): string {

    var today = new Date().getTime() / 1000;

    if (today < startsOn)
        return "Scheduled";
    if (today >= endsOn)
        return "Completed";
    if (today >= startsOn && today <= endsOn)
        return "Active";
    else return "Unkown Status";
}

export function getPromoColor(startsOn: number, endsOn: number): SemanticCOLORS {
    var today = new Date().getTime() / 1000;

    if (today < startsOn)
        return "blue";
    if (today >= endsOn)
        return "grey";
    if (today >= startsOn && today <= endsOn)
        return "green";
    else return "red";
}