import { action, makeObservable, observable } from "mobx";
import { toast } from "react-toastify";
import agent from "../../../api/agent";
import { IPromoCodeForAdd, IPromoCodeForList, IPromoCodeForValidation } from "../../../interfaces/promos";
import { RootStore } from "../../../stores/RootStoreContext";

export class PromoCodesStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable promoCodes: IPromoCodeForList[] = []

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action setHasMore = (value: boolean) => { this.hasMore = value };
    @action resetCodes = (value: IPromoCodeForList[]) => { this.promoCodes = value };
    @action setPromoCodes = (value: IPromoCodeForList[]) => {
        var existing = this.promoCodes.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.promoCodes.push(...missing);
    };

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.PromoCodes.get(this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setPromoCodes(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action validateCode = async (item: IPromoCodeForValidation) => {
        return agent.PromoCodes.validate(item.code);
    }


    @action create = async (item: IPromoCodeForAdd) => {
        if (item.activeFrom instanceof (Date)) {
            item.activeFrom = (item.activeFrom as any).getTime() / 1000;
        }
        if (item.activeTo instanceof (Date)) {
            item.activeTo = (item.activeTo as any).getTime() / 1000;
        }

        return agent.PromoCodes.create(item).then((newId) => {
            this.promoCodes.unshift({
                id: newId,
                code: item.code,
                discount: item.discount,
                fixedDiscount: item.fixedDiscount,
                maxHits: item.maxHits,
                maxTotalHits: item.maxTotalHits ?? 0,
                hitCount: 0,
                activeFrom: item.activeFrom as number,
                activeTo: item.activeTo as number,
            });
        })
    }

    @action disable = async (id: string) => {
        return agent.PromoCodes.disable(id).then(() => {
            var idx = this.promoCodes.findIndex(f => f.id === id);
            this.promoCodes[idx].isDeleted = true;
            this.resetCodes(this.promoCodes)
            toast.success(`Promotional code was disabled successfully`);
        });
    }


    @action update = async (item: IPromoCodeForList) => {
        if ((item as any).activeFrom instanceof (Date)) {
            item.activeFrom = (item.activeFrom as any).getTime() / 1000;
        }
        if ((item as any).activeTo instanceof (Date)) {
            item.activeTo = (item.activeTo as any).getTime() / 1000;
        }

        return agent.PromoCodes.update(item.id, item).then(() => {
            var idx = this.promoCodes.findIndex(f => f.id === item.id);
            this.promoCodes[idx] = { ...item }
            this.resetCodes(this.promoCodes)
            toast.success(`Promotional code '${item.code}' was updated successfully`);
        })
    }

    @action dispose = () => {
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetCodes([]);
        this.setHasMore(false);
    }
}