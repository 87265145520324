import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IShopForListDto } from "../../../interfaces/shop";
import { RootStore } from "../../../stores/RootStoreContext";

export class ShopListStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable shops: IShopForListDto[] = []
    @observable searchText: string = "";

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action setHasMore = (value: boolean) => { this.hasMore = value };
    @action resetShops = (value: IShopForListDto[]) => { this.shops = value };
    @action setShops = (value: IShopForListDto[]) => {
        var existing = this.shops.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.shops.push(...missing);
    };

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Shop.getMany(this.searchText, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setShops(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetShops([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetShops([]);
        this.setHasMore(false);
        this.searchText = ""
    }
}