import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { Button, Message, Divider } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { requestUserSession } from "../registration/functions/sessionFnc";
import AccountDetails from "./AccountDetails";
import ClaimShopWidget from "./Components/ClaimShopWidget";
import RequestShopIntegrationWidget from "./Components/RequestShopIntegrationWidget";

interface IProps {
  mode: "new" | "claim" | "integrate";
}

const OpenNewAccount: React.FC<IProps> = ({ mode }) => {
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const { user } = context.userStore;
  const { isLoggedIn } = context.sessionStore;
  const { setPage } = context.analyticsStore;
  const { openModal, closeModal } = context.modalStore;

  useEffect(() => {
    setPage("Open New Shop", window.location.pathname);

    return () => {};
  }, [setPage]);

  return (
    <div
      className="fxFixed fxDisplay fxDirCol fxAlignCenter"
      style={isMobile ? { paddingLeft: "5px", paddingRight: "5px" } : undefined}
    >
      {(!isLoggedIn || !user) && (
        <div>
          <div style={{ padding: "5px" }}>
            <Message
              icon="user"
              info
              header="A free account is required to access this page!"
              content={
                <div>
                  {
                    "Hi there, thank you for being here, you need to create a free account to access this page. Your user account will always be free and we will not ask you for any money!"
                  }
                  <Divider hidden />
                  <div style={{ textAlign: "center" }}>
                    <Button
                      content="Sign In"
                      className="ct-green-button-active"
                      onClick={() => {
                        requestUserSession(
                          isMobile,
                          openModal,
                          closeModal,
                          () => {
                            closeModal();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      )}
      {user &&
        isLoggedIn &&
        (mode === "new" ? (
          <AccountDetails />
        ) : mode === "claim" ? (
          <ClaimShopWidget />
        ) : (
          <RequestShopIntegrationWidget />
        ))}
    </div>
  );
};

export default observer(OpenNewAccount);
