import { observer } from "mobx-react";
import { Button, Form, Label } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useContext } from "react";
import {
  combineValidators,
  composeValidators,
  createValidator,
  isRequired,
  matchesField,
} from "revalidate";
import { toast } from "react-toastify";
import { RootStoreContext } from "../../stores/RootStoreContext";
import agent from "../../api/agent";
import TextInput from "../../form/TextInput";
import PasswordInput from "../../form/PasswordInput";
import CheckBox from "../../form/CheckBox";
import LegalDocuments from "../legal/LegalDocuments";
import SelectInput from "../../form/SelectInput";
import { Localities } from "../../options/Localities";

interface IProps {
  callbackOnSuccess?: (...args: any[]) => void;
}

const isChecked = (n: boolean) =>
  createValidator(
    (message) => (value: boolean) => {
      if (value !== n) {
        return message;
      }
    },
    (field) => "x"
  );

const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  (field) => "x"
);

const isValidPassword = createValidator(
  (message) => (value) => {
    if (
      value &&
      !/^(?=.*[A-Z])(?=.*[!=@#$%^&*()\-__+.£?])(?=.*[0-9])(?=.*[a-z]).{8,25}$/i.test(
        value
      )
    ) {
      return message;
    }
  },
  (field) => "x"
);

const validate = combineValidators({
  termsAndConditions: isChecked(true)(""),
  firstName: isRequired(""),
  lastName: isRequired(""),
  email: composeValidators(isRequired, isValidEmail)(""),
  password: composeValidators(isRequired, isValidPassword)(""),
  passwordConfirm: composeValidators(
    isRequired(""),
    matchesField("password", "")
  )(""),
  localityId: isRequired(""),
});

const Registration: React.FC<IProps> = ({ callbackOnSuccess }) => {
  const context = useContext(RootStoreContext);
  const { openSecondModal } = context.modalStore;
  const { setEventCustom } = context.analyticsStore;
  const { referrals } = context.cacheStore;
  const { login } = context.sessionStore;
  const { setUser } = context.userStore;

  return (
    <div className="">
      <div style={{ textAlign: "center" }}>
        <img
          src="/cheaptrolley_slogan.svg"
          alt="Cheap Trolley Logo"
          style={{ maxHeight: "80px", maxWidth: "90%" }}
        />
      </div>
      <FinalForm
        validate={validate}
        initialValues={{
          ref: localStorage.getItem("ref")
        }}
        onSubmit={(values: any) =>
          agent.Auth.register(values)
            .then((userId: string) => {
              if (localStorage.getItem('ref') !== null || values.ref !== undefined || values.ref !== null)
                agent.Referrals.create({
                  userId: userId,
                  ref: localStorage.getItem('ref') ?? values.ref
                });
              setUser({ ...values, id: userId });
            })
            .then(() =>
              login({
                username: values.email,
                password: values.password,
                rememberMe: false,
              })
            )
            .then(() =>
              toast.success(
                "We have sent you an email to verify your address. Kindly check your inbox and spam folder to activate your account."
              )
            )
            .then(() => setEventCustom("user_registration"))
            .then(callbackOnSuccess)
            .catch((error) => ({
              [FORM_ERROR]: error,
            }))
        }
        render={({
          handleSubmit,
          submitting,
          invalid,
          dirtySinceLastSubmit,
          values,
        }) => (
          <Form
            onSubmit={handleSubmit}
            error
            className="attached fluid " //segment
          >
            <Form.Field>
              <Field
                name="firstName"
                component={TextInput}
                labelText={"First Name"}
                placeholder={"First Name"}
              />
            </Form.Field>
            <Form.Field>
              <Field
                name="lastName"
                component={TextInput}
                labelText={"Last Name"}
                placeholder={"Last Name"}
              />
            </Form.Field>
            <Form.Field>
              <Field
                name="email"
                component={TextInput}
                labelText={"E-mail"}
                placeholder={"E-mail"}
              />
            </Form.Field>
            <Form.Field>
              <Field
                name="password"
                component={PasswordInput}
                labelText={"Password"}
                placeholder={"Password"}
              />
              <Label className="ct-green-text" basic pointing>
                Password has to be at least 8 characters long and contain 1
                capital letter, 1 lowercase letter, 1 number and a special
                character. e.g. Pass123$
              </Label>
            </Form.Field>
            <Form.Field>
              <Field
                name="passwordConfirm"
                component={PasswordInput}
                labelText={"Confirm Password"}
                placeholder={"Confirm Password"}
              />
            </Form.Field>

            <Form.Field>
              <Field
                fluid
                name={`localityId`}
                component={SelectInput}
                options={Localities}
                placeholder={"Locality"}
                changeFinished={() => { }}
              />
            </Form.Field>

            {referrals.length > 0 && <Form.Field>
              <Field
                fluid
                name={`ref`}
                component={SelectInput}
                options={referrals.map((x) => ({
                  'key': x.key,
                  'value': x.key,
                  'text': x.title
                }))}
                placeholder={"Referred By"}
                changeFinished={() => { }}
              />
            </Form.Field>}

            <Form.Field>
              <Field
                name="termsAndConditions"
                component={CheckBox}
                type="checkbox"
                label={
                  <a
                    className="ct-green-text"
                    href="#/"
                    referrerPolicy="no-referrer"
                    onClick={() => {
                      openSecondModal(<LegalDocuments mode="b2c" />, "small");
                    }}
                  >
                    {"I accept the Cheap Trolley Policy and Terms & Conditions"}
                  </a>
                }
              />
            </Form.Field>

            <div style={{ textAlign: "center" }}>
              <Button
                disabled={invalid && !dirtySinceLastSubmit}
                loading={submitting}
                className="ct-green-button-active"
                content={"Sign Up"}
              />
            </div>
          </Form>
        )}
      />

      <p
        className="clickable"
        style={{ marginTop: "6px", fontSize: "13px" }}
        onClick={() => { window.open("https://wa.me/+35627995146", '_blank'); }}
      >
        Do you need help? Chat with us on <span style={{ fontWeight: "bolder", color: "#086c41" }}>WhatsApp</span>!
      </p>
    </div>
  );
};

export default observer(Registration);
