import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { Guid } from "../../../helpers/guid";
import { IShopForDetail, IShopForSave } from "../../../interfaces/shop";
import { RootStore } from "../../../stores/RootStoreContext";

export class OnlineShopStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable loading: boolean = true;
    @observable editingItem?: IShopForDetail = undefined;

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setEditingItem = (value: IShopForDetail) => { this.editingItem = value };

    @action requestActivation = () => {
        agent.Shop.requestActivation().then(() => {
            runInAction(() => {
                if (!this.editingItem) return;
                this.editingItem.aprovalStatus = "requested"
            })
        })
    }

    @action load = async (shopId?: string) => {
        this.setLoading(true)
        try {
            const { accountId } = this.rootStore.userStore;

            if (!accountId && !shopId) return;

            var account = shopId ? await agent.Shop.Admin.get(shopId) : await agent.Shop.get();

            this.setEditingItem(account);

        } catch (error) {

        }
        finally {
            this.setLoading(false)
        }
    }

    @action save = async (values: IShopForSave) => {
        const { setAccountId, accountId, isOwner } = this.rootStore.userStore;
        const { setToken } = this.rootStore.sessionStore;

        if (!accountId && !isOwner)
            return agent.Shop.create(values).then((newAccId) => {
                if (this.rootStore.userStore.user?.id)
                    agent.Users.update(this.rootStore.userStore.user?.id, { accountId: newAccId, roleId: 1 }).then(() => {
                        if (window.localStorage.getItem("jwt_r"))
                            agent.Auth.refreshToken(
                                window.localStorage.getItem("jwt_r")!
                            ).then((response) => {
                                window.localStorage.setItem(
                                    "jwt",
                                    response.token_type + " " + response.access_token
                                );

                                window.localStorage.setItem("jwt_r", response.refresh_token);
                                setToken(response);
                                setAccountId(newAccId)
                            });
                    })
            })
        else if (accountId) {
            return agent.Shop.update(values)
        }
    }

    @action updateImage = async (fileName: string) => {
        return agent.Shop.updateLogo(fileName);
    }


    @action dispose = () => {
        this.loading = false;
        // this.editingItem = undefined;
    }

    @action handleButtonClick = () => {
        if (!this.editingItem) return;

        if (this.editingItem.aprovalStatus === "requested")
            agent.Shop.Admin.activate(this.editingItem.id).then(() => {
                if (!this.editingItem) return;

                this.editingItem.aprovalStatus = "completed"
            })
        else
            agent.Shop.Admin.deactivate(this.editingItem.id).then(() => {
                if (!this.editingItem) return;

                this.editingItem.aprovalStatus = "requested"
            })
    }

    @action handleHideButtonClick = () => {
        if (!this.editingItem) return;

        agent.Shop.Admin.hide(this.editingItem.id).then(() => {
            if (!this.editingItem) return;

            this.editingItem.aprovalStatus = "completed"
        })
    }


    @action uploadFile = async (file: object) => {
        var type = (file as any).path.split('.').slice(-1)[0]

        var fileName = `${Guid.newGuid()}.${type}`;

        var response = await agent.AWS.Bucket.createPresignedUrl(fileName)
        await agent.AWS.Bucket.uploadFile(file as Blob, response)

        return fileName;
    };
}