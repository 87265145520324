import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ICatalogueForOrder, ICatalogueForOrderList } from "../../../interfaces/catalogue";
import { RootStore } from "../../../stores/RootStoreContext";
import agent from "../../../api/agent";
import { IBasketItem, ISavings } from "../../../interfaces/shoping";
import { IFeeConfig } from "../../../interfaces/fees";
import { IPromoCodeForCheckout } from "../../../interfaces/promos";
import { IAddress } from "../../../interfaces/user";
import { IOrderForDetail } from "../../../interfaces/order";
import { toast } from "react-toastify";

export class ShoppingCartStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable loadingShoppingCart: boolean = false;
    @observable basketItems: IBasketItem[] = []
    @observable deliverySlotId?: string = undefined
    @observable catalogueItems: ICatalogueForOrder[] = []
    @observable fees: IFeeConfig[] = [];
    @observable promoCode?: IPromoCodeForCheckout = undefined;
    @observable checkoutType: "delivery" | "pickup" = "delivery";
    @observable deliveryAddress?: IAddress = undefined;
    @observable paymentErrorCode: number = 0;
    @observable orderNotes: string = "";
    @observable checkOutStatus?: "placing" | "pending_payment" | "completed" | "error" = undefined;
    @observable paymentResult: boolean = false;
    @observable loadingOrder: boolean = false;
    @observable isVisible: boolean = false;
    @observable syncing: boolean = false;
    @observable loadingSavings: boolean = false;
    @observable validating: boolean = false;
    @observable validatingLocation: boolean = false;
    @observable orderDetails?: IOrderForDetail = undefined;
    @observable orderLocations: string[] = [];
    @observable savings?: ISavings = undefined;

    @action setOrderDetail = (value?: IOrderForDetail) => { this.orderDetails = value };
    @action setLoading = (value: boolean) => this.loadingShoppingCart = value;
    @action setValidatingLocation = (value: boolean) => this.validatingLocation = value;
    @action setValidating = (value: boolean) => this.validating = value;
    @action setOrderLocations = (value: string[]) => this.orderLocations = value;
    @action setIsVisible = (value: boolean) => this.isVisible = value;

    @action isSyncing = (value: boolean) => this.syncing = value;
    @action setFees = (value: IFeeConfig[]) => this.fees = value;
    @action setPromoCode = (value?: IPromoCodeForCheckout) => this.promoCode = value;
    @action setDeliverySlot = (value?: string) => this.deliverySlotId = value;
    @action setAddress = (value?: IAddress) => this.deliveryAddress = value;
    @action setOrderNotes = (value: string) => { this.orderNotes = value };
    @action setAddressLocation = (lat: number, lng: number) => {
        if (!this.deliveryAddress?.geolocation) {
            return;
        }

        this.deliveryAddress.geolocation.lat = lat;
        this.deliveryAddress.geolocation.lng = lng;
    }
    @action setPaymentErrorCode = (value: number) => this.paymentErrorCode = value;
    @action setCheckoutStatus = (value?: "error" | "placing" | "pending_payment" | "completed") => { this.checkOutStatus = value };
    @action setPaymentResult = (value: boolean) => { this.paymentResult = value };
    @action setLoadingOrder = (value: boolean) => { this.loadingOrder = value };
    @action setLoadingSavings = (value: boolean) => { this.loadingSavings = value };
    @action setSavings = (value: ISavings) => { this.savings = value };
    @action setCheckoutType = (value: "delivery" | "pickup") => { this.checkoutType = value };


    @action loadCart = async (userId?: string | null) => {
        if (this.rootStore.userStore.isAdmin && (userId ?? null) === null) {
            return;
        }

        this.getSavings();

        return agent.Cart.V2.get(userId)
            .then((result) => {
                runInAction(() => {
                    this.basketItems = result.items;
                    this.catalogueItems = result.products;
                })
            })
    }

    @action emptyCart = async () => {
        return agent.Cart.V2.empty(this.rootStore.userStore.user!.id).then(() => {
            this.disposeCart();
        })
    }


    @action addToCart = (catalogueItem: ICatalogueForOrderList) => {
        // check the global limit
        if (catalogueItem.largeContainer) {
            var largeItems = this.catalogueItems.filter(f => f.largeContainer === true).map(x => x.id);
            var currentCount = this.basketItems.filter(f => largeItems.includes(f.productId)).reduce((acc, obj) => acc + obj.quantity, 0) + 1;
            if (currentCount > this.rootStore.globalConfig.sixPackCount) {
                toast.info(`Currently you can order up to ${this.rootStore.globalConfig.sixPackCount} large items.`);
                return;
            }
        }

        if (catalogueItem.bottleLimit) {
            var bottleItems = this.catalogueItems.filter(f => f.bottleLimit === true).map(x => x.id);
            var currentBottleCount = this.basketItems.filter(f => bottleItems.includes(f.productId)).reduce((acc, obj) => acc + obj.quantity, 0) + 1;
            if (currentBottleCount > this.rootStore.globalConfig.bottleLimitCount) {
                toast.info(`Currently you can order up to ${this.rootStore.globalConfig.bottleLimitCount} single bottles.`);
                return;
            }
        }

        runInAction(() => {
            var index = this.basketItems.findIndex(x => x.productId === catalogueItem.id) ?? -1;
            if (index > -1)
                this.basketItems[index] = {
                    productId: catalogueItem.id,
                    quantity: this.basketItems[index].quantity + 1
                }
            else {
                this.basketItems.push({ productId: catalogueItem.id, quantity: 1 });
                if (this.catalogueItems.filter(f => f.id === catalogueItem.id).length === 0)
                    this.catalogueItems.push({
                        ...catalogueItem,
                        active: true
                    })
            }
        })
    }

    @action incereaseQuantity = (id: string) => {
        // check the global limit
        var catalogueItem = this.catalogueItems.filter(f => f.id === id)[0];
        if (catalogueItem.largeContainer) {
            var largeItems = this.catalogueItems.filter(f => f.largeContainer === true).map(x => x.id);
            var currentCount = this.basketItems.filter(f => largeItems.includes(f.productId)).reduce((acc, obj) => acc + obj.quantity, 0) + 1;
            if (currentCount > this.rootStore.globalConfig.sixPackCount) {
                toast.info(`Currently you can order up to ${this.rootStore.globalConfig.sixPackCount} large items.`);
                return;
            }
        }

        if (catalogueItem.bottleLimit) {
            var bottleLimit = this.catalogueItems.filter(f => f.bottleLimit === true).map(x => x.id);
            var bottleLimitCount = this.basketItems.filter(f => bottleLimit.includes(f.productId)).reduce((acc, obj) => acc + obj.quantity, 0) + 1;
            if (bottleLimitCount > this.rootStore.globalConfig.bottleLimitCount) {
                toast.info(`Currently you can order up to ${this.rootStore.globalConfig.bottleLimitCount} single bottles.`);
                return;
            }
        }

        runInAction(() => {
            var index = this.basketItems.findIndex(x => x.productId === id) ?? -1;
            if (index > -1)
                this.basketItems[index] = { productId: id, quantity: this.basketItems[index].quantity + 1 }

        })
        // this.rootStore.analyticsStore.setEventCustom("incease_cart");
    }

    @action syncCart = async (catalogueItemId: string) => {
        this.isSyncing(true);
        var items = this.basketItems.filter(x => x.productId === catalogueItemId);

        agent.Cart.V2
            .update(catalogueItemId, items.length > 0 ? items[0].quantity : 0, this.rootStore.userStore.user!.id)
            .then(() => {
                this.getSavings();
            });
    }

    @action getSavings = async () => {
        this.setLoadingSavings(true);

        agent.Cart.V2.calculate_savings().then((savings) => {
            this.setSavings(savings);
            this.setLoadingSavings(false);
        })
    }

    @action removeFromCart = (id: string) => {
        var index = this.basketItems.findIndex(x => x.productId === id) ?? -1;

        if (index > -1) {
            if (this.basketItems[index].quantity - 1 === 0)
                this.basketItems = this.basketItems.filter(f => f.productId !== id);
            else
                this.basketItems[index].quantity = this.basketItems[index].quantity - 1
        }

        // this.rootStore.analyticsStore.setEventCustom("remove_from_cart");
    }

    @computed get largeItemLimit() {
        var largeItems = this.catalogueItems.filter(f => f.largeContainer === true).map(x => x.id);
        var currentCount = this.basketItems.filter(f => largeItems.includes(f.productId)).reduce((acc, obj) => acc + obj.quantity, 0);

        return currentCount >= this.rootStore.globalConfig.sixPackCount
    }

    @computed get largeItemLimitPass() {
        var largeItems = this.catalogueItems.filter(f => f.largeContainer === true).map(x => x.id);
        var currentCount = this.basketItems.filter(f => largeItems.includes(f.productId)).reduce((acc, obj) => acc + obj.quantity, 0);

        return currentCount > this.rootStore.globalConfig.sixPackCount
    }

    @computed get bottleLimit() {
        var largeItems = this.catalogueItems.filter(f => f.bottleLimit === true).map(x => x.id);
        var currentCount = this.basketItems.filter(f => largeItems.includes(f.productId)).reduce((acc, obj) => acc + obj.quantity, 0);

        return currentCount >= this.rootStore.globalConfig.bottleLimitCount
    }

    @computed get bottleLimitPass() {
        var largeItems = this.catalogueItems.filter(f => f.bottleLimit === true).map(x => x.id);
        var currentCount = this.basketItems.filter(f => largeItems.includes(f.productId)).reduce((acc, obj) => acc + obj.quantity, 0);

        return currentCount > this.rootStore.globalConfig.bottleLimitCount;
    }

    @action removeItemFromCart = (id: string) => {
        var index = this.basketItems.findIndex(x => x.productId === id) ?? -1;
        if (index > -1) {
            this.basketItems[index] = { productId: id, quantity: 0 }
            if (this.basketItems[index].quantity === 0) {
                this.basketItems = this.basketItems.filter(f => f.quantity > 0);
            }
            this.syncCart(id);
        }
        // this.rootStore.analyticsStore.setEventCustom("remove_from_cart");
    }

    @computed get itemsForDisplay() {
        var ctitems = this.catalogueItems.filter(f => (this.basketItems.filter(z => z.productId === f.id)[0]?.quantity ?? 0) > 0);
        return ctitems.slice().sort((a, b) => (a.title < b.title ? -1 : 1))
    }

    @computed get minimumOrder() {
        if (this.fees.length === 0) {
            return 0;
        }
        return this.checkoutType === "delivery" ? this.fees.filter(f => f.key === "minOrder")[0]?.value : this.fees.filter(f => f.key === "minPickup")[0]?.value;
    }

    @computed get deliveryFee() {
        if (this.orderDetails !== undefined) {
            return this.orderDetails.deliveryFee
        }

        return this.fees.length > 0 ? this.fees.filter(f => f.key === "delivery")[0]?.value - (this.savings?.discount ?? 0) : 0;
    }

    @computed get calculateDiscount() {
        if (this.orderDetails !== undefined) {
            return this.orderDetails.discount
        }

        if (!this.promoCode) return 0;
        var currentPrice = this.getShoppingCartCost;
        var discount = ((this.promoCode.discount ?? 0) > 0
            ? ((this.promoCode.discount! / 100) * currentPrice)
            : this.promoCode.fixedDiscount!
        )

        return discount;
    }


    @action validateCart = async () => {
        if (this.basketItems.length === 0) return false;;
        if (this.getShoppingCartCost < this.minimumOrder) return false;

        this.setValidating(true)

        return agent.Orders.validate({
            checkoutType: this.checkoutType,
            promoCode: this.promoCode?.code,
            credits: this.availableCredits,
            subtotal: this.getShoppingCartCost,
            deliveryFee: this.checkoutType === "delivery" ? this.deliveryFee : 0,
            serviceFee: this.serviceFee,
            totalCost: this.calculateTotalPrice,
        })
            .then((result) => {
                if (result.isValid) {
                    return true;
                } else {
                    toast.info(result.message);
                    return false;
                }
            })
            .catch(() => {
                toast.info("Kindly refresh your screen to update your cart.")
                return false;
            })
            .finally(() => {
                this.setValidating(false);
            })
    }

    @action cartChanged = () => {
        if (this.syncing) {
            this.isSyncing(false);
            return;
        }
        this.loadCart();
    }

    @action getProductQuantity = (catalogueItemId: string) => {
        return this.basketItems.filter(f => f.productId === catalogueItemId)[0]?.quantity ?? 0;
    }

    @computed get getItemsCount() {
        var count = 0;
        this.basketItems.forEach(item => {
            var cartItems = this.catalogueItems?.filter((f) => f.id === item.productId) ?? [];
            if (cartItems.length > 0) {
                count += Math.ceil(item.quantity) //* (cartItems[0].step ?? 1)
            }

        })
        // var count = this.shopingCart?.items.slice().reduce((a, b) => a + Math.ceil(b.quantity), 0) ?? 0;
        if (count >= 100)
            return "99+";

        if (count < 0)
            count = 0;

        return count.toFixed(0);
    }

    @action getItemCost = (id: string) => {
        var items = this.basketItems.filter(f => f.productId === id);

        if (items.length === 0) return 0;

        var cartItems = this.catalogueItems?.filter((f) => f.id === items[0].productId) ?? [];

        if (cartItems.length === 0) return 0;

        var price = cartItems[0].price ?? 0
        return items[0].quantity * price;
    }

    @computed get getShoppingCartCost() {
        if (this.orderDetails !== undefined) {
            return this.orderDetails.originalCost
        }
        var cost = 0.0;
        this.basketItems.forEach(item => {
            var cartItems = this.catalogueItems?.filter((f) => f.id === item.productId) ?? [];
            if (cartItems.length > 0) {
                var price = cartItems[0].price ?? 0
                cost += item.quantity * price //* (cartItems[0].step ?? 1)
            }

        })

        return parseFloat(cost.toFixed(2));
    }

    @computed get calculateTotalPrice() {
        if (this.orderDetails !== undefined) {
            return this.orderDetails.payableCost
        }

        var currentPrice = this.getShoppingCartCost;

        var discount = 0;
        if (this.promoCode) {
            discount = ((this.promoCode.discount ?? 0) > 0
                ? ((this.promoCode.discount! / 100) * currentPrice)
                : this.promoCode.fixedDiscount!
            )
        }

        var retVal = currentPrice - discount - this.availableCredits + (this.checkoutType === "delivery" ? this.deliveryFee : 0) + this.serviceFee;
        return parseFloat(retVal.toFixed(2));
    }

    @computed get serviceFee() {
        if (this.orderDetails !== undefined) {
            return this.orderDetails.serviceFee
        }

        return this.fees.length > 0 ? this.fees.filter(f => f.key === "service")[0]?.value : 0;
    }

    @computed get availableCredits() {
        if (this.orderDetails !== undefined) {
            return this.orderDetails.creditsUsed ?? 0;
        }

        const { balance } = this.rootStore.clientCreditStore;
        var itemsTotal = this.getShoppingCartCost - this.calculateDiscount;
        var total = itemsTotal + (this.checkoutType === "delivery" ? this.deliveryFee : 0) + this.serviceFee;

        return total > balance ? balance : total;
    }

    @action disposeCart = () => {
        this.basketItems = [];
        this.catalogueItems = [];
        this.setOrderDetail(undefined);
        this.setAddress(undefined);
        this.setPromoCode(undefined);
        this.setOrderNotes("");
        this.setPaymentErrorCode(0);
        this.setCheckoutStatus(undefined);
        this.setPaymentResult(false);
    }

    @computed get issues() {
        var retVal: string[] = [];

        var { user } = this.rootStore.userStore;
        if (!user) {
            retVal.push("login");
            return retVal;
        }

        if (this.getShoppingCartCost < this.minimumOrder)
            retVal.push("minimumOrder");
        if ((user?.phoneNumber ?? "") === "")
            retVal.push("phone");
        // if ((user?.emailConfirmed ?? false) === false)
        //     retVal.push("email");
        if (this.checkoutType === "delivery" && this.deliveryAddress && !this.deliverySlotId && this.getShoppingCartCost >= this.minimumOrder)
            retVal.push("timeslot");
        if (this.checkoutType === "delivery" && !this.deliveryAddress)
            retVal.push("address");
        if (this.deliveryAddress && !this.deliveryAddress.addressLine1)
            retVal.push("addressLine1");
        // if (this.deliveryAddress && !this.deliveryAddress.geolocation?.lat)
        //     retVal.push("geolocation");
        // if (this.checkoutType === "delivery" && this.deliveryAddress && !this.validLocation && !retVal.includes("geolocation"))
        //     retVal.push("geolocationDelivery");
        if (this.catalogueItems.filter(f => this.basketItems.filter(z => z.productId === f.id && z.quantity > 0 && (!f.active || f.deleted)).length > 0).length > 0)
            retVal.push("discontinued");
        if (this.paymentErrorCode > 0)
            retVal.push("paymentIssue");
        if (this.largeItemLimitPass)
            retVal.push("largeItemLimitPass");
        if (this.bottleLimitPass)
            retVal.push("bottleLimitPass");

        return retVal;
    }

    @action oneTimeLoad = async (searchParams?: any) => {
        try {
            agent.Orders.lastAddress().then((result) => {
                this.setAddress(result);
                if (result && result.geolocation)
                    this.setAddressLocation(result!.geolocation!.lat, result!.geolocation!.lng);
            })
            await agent.Fees.get().then((result) => this.setFees(result));

            if (searchParams) {
                var trns = searchParams.get("t");
                var sId = searchParams.get("s");
                var eventId = searchParams.get("eventId");
                // var eciCode = searchParams.get("eci");
                this.setPaymentErrorCode(eventId * 1);

                if (trns && sId && eventId === "0") {
                    const { protocol, hostname, port } = window.location;
                    const portPart = port ? `:${port}` : '';
                    const newUrl = `${protocol}//${hostname}${portPart}/my-shopping-list`;
                    window.history.pushState({ path: newUrl }, '', newUrl);
                    this.setPaymentResult(true);

                    try {
                        this.setLoadingOrder(true);

                        agent.Orders.getDetailByPaymentId(sId)
                            .then((orderDetail) => {
                                agent.Orders.updateOrderTransactionId(orderDetail.id, trns);
                                this.setOrderDetail(orderDetail);
                                this.setAddress(orderDetail.address);
                                this.setCheckoutType(orderDetail.checkoutType);
                                this.setOrderNotes(orderDetail.notes ?? "");
                                if (orderDetail.checkoutType === "delivery")
                                    this.setAddressLocation(orderDetail.address!.geolocation!.lat, orderDetail.address!.geolocation!.lng);
                                if (orderDetail.promoCode)
                                    agent.PromoCodes.getForRetry(orderDetail.promoCode).then((promoCode) => this.setPromoCode(promoCode));
                                this.setLoadingOrder(false);
                            })

                    } catch (error) { }
                }
                else if (sId && eventId) {
                    const { protocol, hostname, port } = window.location;
                    const portPart = port ? `:${port}` : '';
                    const newUrl = `${protocol}//${hostname}${portPart}/my-shopping-list`;
                    window.history.pushState({ path: newUrl }, '', newUrl);
                    this.setCheckoutStatus("error");
                    agent.Orders.getDetailByPaymentId(sId).then((orderDetail) => {
                        this.setOrderDetail(orderDetail)
                        this.setCheckoutType(orderDetail.checkoutType);
                        this.setAddress(orderDetail.address);
                        this.setAddressLocation(orderDetail.address!.geolocation!.lat, orderDetail.address!.geolocation!.lng)
                        this.setOrderNotes(orderDetail.notes ?? "");

                        if (orderDetail.promoCode)
                            agent.PromoCodes.getForRetry(orderDetail.promoCode).then((promoCode) => this.setPromoCode(promoCode))
                    })
                }

            }

            // this.loadMinimumOrder();
        } catch (error) {
        }
    }

    @action retryOrder = async () => {
        if (!this.orderDetails) return;
        agent.Orders.retry(this.orderDetails.id).then((redirectUrl) => {
            this.rootStore.analyticsStore.setEventCustom("retry_payment");
            this.rootStore.clientCreditStore.getWalletBalance();
            this.setCheckoutStatus("pending_payment")
            window.location.href = redirectUrl;
        }).catch(() => {
            this.setCheckoutStatus(undefined)
        });
    }

    @computed get checkOutButtonText() {
        if (this.orderDetails) {
            switch (this.orderDetails.status) {
                case "pending_payment":
                    return "Retry";
                case "pending_processing":
                    return "Payment Complete";
            }
        }
        switch (this.checkOutStatus) {
            case "placing":
                return "Placing order...";
            case "pending_payment":
                return "Payment processing...";
            case "completed":
                return "Completed";
            case "error":
                return "Retry";

            default:
                return "Place Order";
        }
    }

    @computed get checkOutButtonColor() {
        if (this.orderDetails) {
            switch (this.orderDetails.status) {
                case "pending_payment":
                    return "olive";
                case "pending_processing":
                    return "green";
            }
        }
        switch (this.checkOutStatus) {
            case "placing":
                return "blue";
            case "pending_payment":
                return "olive";
            case "completed":
                return "green";

            default:
                return "blue";
        }
    }

    @action refreshDeliveryAddress = async () => {
        if (!this.deliveryAddress) {
            return;
        }
        agent.Users.Address.get_detail(this.deliveryAddress?.id).then((result) => this.setAddress(result));
    }

    @action placeOrder = async () => {
        this.setCheckoutStatus("placing");

        var result = await this.validateCart();

        if (!result) {
            this.setCheckoutStatus(undefined);
            return;
        }

        await agent.Orders.create({
            checkoutType: this.checkoutType,
            platform: "web",
            address: this.deliveryAddress!,
            notes: this.orderNotes,
            promoCode: this.promoCode?.code,
            credits: this.availableCredits,
            deliverySlotId: this.deliverySlotId!,
        }).then((redirectUrl) => {
            if (redirectUrl !== undefined) {
                this.rootStore.analyticsStore.setEventCustom("created_order");
                this.rootStore.clientCreditStore.getWalletBalance();
                this.setCheckoutStatus("pending_payment")
                window.location.href = redirectUrl;
            }
            else {
                this.setCheckoutStatus(undefined);
            }
        }).catch(() => {
            this.setCheckoutStatus(undefined)
        })
    }
}
