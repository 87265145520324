import React from "react";

const BusinessPrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy policy</h1>
      <p>
        This privacy policy (&quot;Policy&quot;) describes how website Developer
        (&quot;website Developer&quot;, &quot;we&quot;, &quot;us&quot; or
        &quot;our&quot;) collects, protects and uses the personally identifiable
        information (&quot;Personal Information&quot;) you (&quot;User&quot;,
        &quot;you&quot; or &quot;your&quot;) may provide in the Cheap Trolley
        website and any of its products or services (collectively,
        &quot;website&quot; or &quot;Services&quot;). It also describes the
        choices available to you regarding our use of your Personal Information
        and how you can access and update this information. This Policy does not
        apply to the practices of companies that we do not own or control, or to
        individuals that we do not employ or manage.
      </p>
      <h2>Automatic collection of information</h2>
      <p>
        When you open the website our servers automatically record information
        that your device sends. This data may include information such as your
        device's IP address and location, device name and version, operating
        system type and version, language preferences, information you search
        for in our website, access times and dates, and other statistics.
      </p>
      <h2>Collection of personal information</h2>
      <p>
        You can visit the website without telling us who you are or revealing
        any information by which someone could identify you as a specific,
        identifiable individual. If, however, you wish to use some of the
        website's features, you will be asked to provide certain Personal
        Information (for example, your name and e-mail address). We receive and
        store any information you knowingly provide to us when you publish
        content, make a purchase, or fill any online forms in the Mobile
        Application. You can choose not to provide us with your Personal
        Information, but then you may not be able to take advantage of some of
        the website's features. Users who are uncertain about what information
        is mandatory are welcome to contact us.
      </p>
      <h2>Use and processing of collected information</h2>
      <p>
        Any of the information we collect from you may be used to personalize
        your experience; improve our website; improve customer service and
        respond to queries and emails of our customers; process transactions;
        send newsletters; send notification emails such as password reminders,
        updates, etc; run and operate our website and Services. Information
        collected automatically is used only to identify potential cases of
        abuse and establish statistical information regarding website traffic
        and usage. This statistical information is not otherwise aggregated in
        such a way that would identify any particular user of the system.
      </p>
      <p>
        We may process Personal Information related to you if one of the
        following applies: (i) You have given your consent for one or more
        specific purposes. Note that under some legislations we may be allowed
        to process information until you object to such processing (by opting
        out), without having to rely on consent or any other of the following
        legal bases below. This, however, does not apply, whenever the
        processing of Personal Information is subject to European data
        protection law; (ii) Provision of information is necessary for the
        performance of an agreement with you and/or for any pre-contractual
        obligations thereof; (iii) Processing is necessary for compliance with a
        legal obligation to which you are subject; (iv) Processing is related to
        a task that is carried out in the public interest or in the exercise of
        official authority vested in us; (v) Processing is necessary for the
        purposes of the legitimate interests pursued by us or by a third party.
        In any case, we will be happy to clarify the specific legal basis that
        applies to the processing, and in particular whether the provision of
        Personal Information is a statutory or contractual requirement, or a
        requirement necessary to enter into a contract.
      </p>
      <h2>Information transfer and storage</h2>
      <p>
        Depending on your location, data transfers may involve transferring and
        storing your information in a country other than your own. You are
        entitled to learn about the legal basis of information transfers to a
        country outside the European Union or to any international organization
        governed by public international law or set up by two or more countries,
        such as the UN, and about the security measures taken by us to safeguard
        your information. If any such transfer takes place, you can find out
        more by checking the relevant sections of this document or inquire with
        us using the information provided in the contact section.
      </p>
      <h2>The rights of users</h2>
      <p>
        You may exercise certain rights regarding your information processed by
        us. In particular, you have the right to do the following: (i) you have
        the right to withdraw consent where you have previously given your
        consent to the processing of your information; (ii) you have the right
        to object to the processing of your information if the processing is
        carried out on a legal basis other than consent; (iii) you have the
        right to learn if information is being processed by us, obtain
        disclosure regarding certain aspects of the processing and obtain a copy
        of the information undergoing processing; (iv) you have the right to
        verify the accuracy of your information and ask for it to be updated or
        corrected; (v) you have the right, under certain circumstances, to
        restrict the processing of your information, in which case, we will not
        process your information for any purpose other than storing it; (vi) you
        have the right, under certain circumstances, to obtain the erasure of
        your Personal Information from us; (vii) you have the right to receive
        your information in a structured, commonly used and machine readable
        format and, if technically feasible, to have it transmitted to another
        controller without any hindrance. This provision is applicable provided
        that your information is processed by automated means and that the
        processing is based on your consent, on a contract which you are part of
        or on pre-contractual obligations thereof.
      </p>
      <h2>The right to object to processing</h2>
      <p>
        Where Personal Information is processed for the public interest, in the
        exercise of an official authority vested in us or for the purposes of
        the legitimate interests pursued by us, you may object to such
        processing by providing a ground related to your particular situation to
        justify the objection. You must know that, however, should your Personal
        Information be processed for direct marketing purposes, you can object
        to that processing at any time without providing any justification. To
        learn, whether we are processing Personal Information for direct
        marketing purposes, you may refer to the relevant sections of this
        document.
      </p>
      <h2>How to exercise these rights</h2>
      <p>
        Any requests to exercise User rights can be directed to the Owner
        through the contact details provided in this document. These requests
        can be exercised free of charge and will be addressed by the Owner as
        early as possible.
      </p>
      <h2>Billing and payments</h2>
      <p>
        In case of services requiring payment, we request credit card or other
        payment account information, which will be used solely for processing
        payments. Your purchase transaction data is stored only as long as is
        necessary to complete your purchase transaction. After that is complete,
        your purchase transaction information is deleted. All direct payment
        gateways adhere to the latest security standards as managed by the PCI
        Security Standards Council, which is a joint effort of brands like Visa,
        MasterCard, American Express and Discover. Sensitive and private data
        exchange happens over a SSL secured communication channel and is
        encrypted and protected with digital signatures.
      </p>
      <h2>Privacy of children</h2>
      <p>
        We take many special precautions to protect the privacy of children
        under 13. We do not require any Personal Information from them at any
        time. We encourage children to consult with their parents before
        submitting any information to any website. We believe parents should be
        involved in the online activities of their children and suggest that
        parents do their best to provide their children with a safe and friendly
        online environment.
      </p>
      <h2>Newsletters</h2>
      <p>
        We offer electronic newsletters to which you may voluntarily subscribe
        at any time. You may choose to stop receiving our newsletter or
        marketing emails by following the unsubscribe instructions included in
        these emails or by contacting us. However, you will continue to receive
        essential transactional emails.
      </p>
      <h2>Remarketing</h2>
      <p>
        We also may permit certain third-party companies to help us tailor
        advertising that we think may be of interest to users and to collect and
        use other data about user activities in the website. These companies may
        deliver ads that might place cookies and otherwise track user behavior.
      </p>
      <h2>Affiliates</h2>
      <p>
        We may disclose information about you to our affiliates for the purpose
        of being able to offer you related or additional products and services.
        Any information relating to you that we provide to our affiliates will
        be treated by those affiliates in accordance with the terms of this
        Privacy Policy.
      </p>
      <h2>Links to other websites</h2>
      <p>
        Our website contains links to other websites that are not owned or
        controlled by us. Please be aware that we are not responsible for the
        privacy practices of such other websites or third-parties. We encourage
        you to be aware when you leave our Mobile Application and to read the
        privacy statements of each and every mobile application that may collect
        Personal Information.
      </p>
      <h2>Information security</h2>
      <p>
        We secure information you provide on computer servers in a controlled,
        secure environment, protected from unauthorized access, use, or
        disclosure. We maintain reasonable administrative, technical, and
        physical safeguards in an effort to protect against unauthorized access,
        use, modification, and disclosure of Personal Information in its control
        and custody. However, no data transmission over the Internet or wireless
        network can be guaranteed. Therefore, while we strive to protect your
        Personal Information, you acknowledge that (i) there are security and
        privacy limitations of the Internet which are beyond our control; (ii)
        the security, integrity, and privacy of any and all information and data
        exchanged between you and our website cannot be guaranteed; and (iii)
        any such information and data may be viewed or tampered with in transit
        by a third-party, despite best efforts.
      </p>
      <h2>Data breach</h2>
      <p>
        In the event we become aware that the security of the website has been
        compromised or users Personal Information has been disclosed to
        unrelated third parties as a result of external activity, including, but
        not limited to, security attacks or fraud, we reserve the right to take
        reasonably appropriate measures, including, but not limited to,
        investigation and reporting, as well as notification to and cooperation
        with law enforcement authorities. In the event of a data breach, we will
        make reasonable efforts to notify affected individuals if we believe
        that there is a reasonable risk of harm to the user as a result of the
        breach or if notice is otherwise required by law. When we do, we will
        send you an email.
      </p>
      <h2>Legal disclosure</h2>
      <p>
        We will disclose any information we collect, use or receive if required
        or permitted by law, such as to comply with a subpoena, or similar legal
        process, and when we believe in good faith that disclosure is necessary
        to protect our rights, protect your safety or the safety of others,
        investigate fraud, or respond to a government request. In the event we
        go through a business transition, such as a merger or acquisition by
        another company, or sale of all or a portion of its assets, your user
        account, and Personal Information will likely be among the assets
        transferred.
      </p>
      <h2>Changes and amendments</h2>
      <p>
        We may update this Privacy Policy from time to time in our discretion
        and will notify you of any material changes to the way in which we treat
        Personal Information. When changes are made, we will send you an email
        to notify you. We may also provide notice to you in other ways in our
        discretion, such as through contact information you have provided. Any
        updated version of this Privacy Policy will be effective immediately
        upon the posting of the revised Privacy Policy unless otherwise
        specified. Your continued use of the website or Services after the
        effective date of the revised Privacy Policy (or such other act
        specified at that time) will constitute your consent to those changes.
        However, we will not, without your consent, use your Personal Data in a
        manner materially different than what was stated at the time your
        Personal Data was collected.
      </p>
      <h2>Acceptance of this policy</h2>
      <p>
        You acknowledge that you have read this Policy and agree to all its
        terms and conditions. By using the website or its Services you agree to
        be bound by this Policy. If you do not agree to abide by the terms of
        this Policy, you are not authorized to use or access the Mobile
        Application and its Services.
      </p>
      <h2>Contacting us</h2>
      <p>
        If you would like to contact us to understand more about this Policy or
        wish to contact us concerning any matter relating to individual rights
        and your Personal Information, you may do so via the{" "}
        <a
          target="_blank"
          href="https://cheaptrolley.com/about"
          rel="noreferrer"
        >
          about page
        </a>{" "}
        or send an email to{" "}
        <a target="_blank" rel="noreferrer" href="mailto:info@cheaptrolley.com">
          info@cheaptrolley.com
        </a>
      </p>
      <p>This document was last updated on March 18, 2022</p>
    </div>
  );
};

export default BusinessPrivacyPolicy;
