import { action, makeObservable, observable } from "mobx";
import { toast } from "react-toastify";
import agent from "../../../api/agent";
import { IAssociation, IAssociationForSave } from "../../../interfaces/association";
import { IProduct, IProductForList } from "../../../interfaces/product";
import { RootStore } from "../../../stores/RootStoreContext";

export class ProductAssociationStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }


    @observable loading: boolean = false;
    @observable tempListForGroup: string[] = [];
    @observable currentlyEditingItem?: IAssociation = undefined;
    @observable productIdToExpand?: string = undefined;
    @observable originalAssociationIdToMerge?: string = undefined;
    @observable possibleAssociationIdToExpand?: string = undefined;
    @observable editingGroupId?: string = undefined;

    @action clearTempList = () => this.tempListForGroup = [];
    @action setLoading = (value: boolean) => this.loading = value;
    @action setProductIdToExpand = (value?: string) => this.productIdToExpand = value;
    @action setOriginalAssociationIdToMerge = (value?: string) => this.originalAssociationIdToMerge = value;
    @action setPossibleAssociationIdToExpand = (value?: string) => this.possibleAssociationIdToExpand = value;
    @action setEditingGroupId = (value?: string) => this.editingGroupId = value;
    @action setCurrentlyEditingItem = (value?: IAssociation) => this.currentlyEditingItem = value;

    @action dispose = () => {
        this.setEditingGroupId(undefined);
        this.setCurrentlyEditingItem(undefined);
        this.setLoading(false);
    }

    @action load = async (id?: string) => {
        if (!id) return;
        this.setLoading(true);
        var item = await agent.Associations.getDetail(id);
        this.setCurrentlyEditingItem(item);
        this.setLoading(false);
    }

    @action getComparisonOptions = (products: IProductForList[]) => {
        return products.slice()
            .sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1));
    }

    @action toggleFromList = (id: string) => {
        var inx = this.tempListForGroup.findIndex(f => f === id);

        if (inx < 0)
            this.tempListForGroup.push(id);
        else
            this.tempListForGroup = this.tempListForGroup.filter(f => f !== id)
    }

    @action save = async (item: IAssociationForSave) => {
        var idRet: string = "";
        if (!this.currentlyEditingItem) {
            var id = await agent.Associations.create(item);
            idRet = id;
            toast.success(`Association ${item.title} created successfully :: id : ${id}`)
            this.rootStore.productStore.updateAssociationIdLocal(item.productIds, id);
        }
        else {
            var toupdate = { ...item };
            toupdate.productIds = this.currentlyEditingItem.products.map(x => x.id);
            idRet = this.currentlyEditingItem.id;
            await agent.Associations.update(this.currentlyEditingItem.id, toupdate).then(() => {
                this.rootStore.associationStore.updateAssociation(this.currentlyEditingItem!.id, toupdate);
                toast.success(`Association ${toupdate.title} updated successfully`)
            });
        }

        this.rootStore.modalStore.closeModal();

        return idRet;
    }

    @action deleteAssociation = async (id: string) => {
        await agent.Associations.delete(id).then(() => {
            this.rootStore.associationStore.removeAssociation(id);
            toast.success(`Association delete successfully`)
        });
    }

    @action stabbilitaUpdateAssociation = async (assId: string) => {
        if (this.productIdToExpand) {
            this.tempListForGroup.push(this.productIdToExpand);
        }

        if (this.tempListForGroup.length !== 1 || !assId) return;

        await agent.Stabbilta.updateAssociation(assId, this.tempListForGroup[0]);

        this.setProductIdToExpand(undefined);
        this.clearTempList();
        this.rootStore.modalStore.closeModal();
    }

    @action addToAssociation = async (assId: string) => {
        if (this.productIdToExpand) {
            this.tempListForGroup.push(this.productIdToExpand);
        }

        if (this.tempListForGroup.length === 0 || !assId) return;

        await agent.Associations.addManyToAssociation(assId, this.tempListForGroup);
        // await agent.Associations.addToAssociation(assId, this.productIdToExpand);

        // var products = this.rootStore.productStore.products.filter(f => f.id === this.productIdToExpand);

        // if (products.length > 0)
        // toast.success(`${products[0].itemDesc} was added succesfully to association with Id : ${assId}`)
        // else
        //     toast.success(`Items was added succesfully to association with Id : ${assId}`)

        try {
            this.rootStore.productStore.updateAssociationIdLocal(this.tempListForGroup, assId);
        } catch (error) { }

        this.setProductIdToExpand(undefined);
        this.clearTempList();
        this.rootStore.modalStore.closeModal();
    }

    @action getSelectedItem = (products: IProduct[]) => {
        var cart = this.rootStore.shoppingListStore.shopingCart;

        if (!cart) return undefined;

        var selected = cart?.items.filter(
            (x) =>
                products.findIndex((z) => z.id === x.productId) > -1
        );

        if (selected.length === 0) return undefined;
        if (selected.length === 1) return products.filter(f => f.id === selected[0].productId)[0];

        return undefined;
    }

    @action mergeToAssociation = async (assId: string) => {

        if (!this.possibleAssociationIdToExpand || !assId) return;

        await agent.PossibleAssociations.v2.merge(this.possibleAssociationIdToExpand, assId);

        toast.success(`Possible association was merged succesfully to association with Id : ${assId}`)

        this.setPossibleAssociationIdToExpand(undefined);
        this.rootStore.modalStore.closeModal();
    }

    @action mergeToReceiver = async (assId: string) => {

        if (!this.originalAssociationIdToMerge || !assId) return;

        await agent.Associations.merge(this.originalAssociationIdToMerge, assId);

        toast.success(`Association was merged succesfully to association with Id : ${assId}`)

        this.setOriginalAssociationIdToMerge(undefined);

        const { searchText, search } = this.rootStore.associationStore;
        search(searchText);

        this.rootStore.modalStore.closeModal();

    }

    @action removeTemporaryFromAssociation = async (assId: string) => {
        if (!assId) return;

        await agent.Associations.remove_temporary(assId);
        this.rootStore.associationStore.remove_tmp_flag(assId);

        toast.success(`Success, refresh the screen`)

    }
}