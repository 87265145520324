import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import CardWithUpload from '../../../components/photoUpload/CardWithUpload';
import { FormApi } from 'final-form';
import { toast } from 'react-toastify';
import { IBrand } from '../../../interfaces/brand';

interface IProps {
    form: FormApi<IBrand, Partial<IBrand>>
}

const BrandImageSelection: React.FC<IProps> = ({ form }) => {
    const context = useContext(RootStoreContext);
    const { uploadFile } = context.catalogueAdminStore;

    return (
        <div style={{ textAlign: "center" }}>
            <CardWithUpload
                loading={false}
                uploadImage={(file: object) => {
                    uploadFile(file).then((fileName) => {
                        form.change("imageUrl", fileName);
                        toast.success(fileName);
                    });

                    return Promise.resolve();
                }} />
        </div >
    )
}


export default observer(BrandImageSelection);