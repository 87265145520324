import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { LabelStat } from "../../../interfaces/labelStats";
import { toast } from "react-toastify";

export class LabelListStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable searchText: string = "";
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable labels: LabelStat[] = []
    @observable togroup: string[] = []
    @observable toaster?: React.ReactText = undefined;
    @observable reviewed: boolean = false;
    @observable count: number = 0;

    @action setCount = (value: number) => this.count = value;
    @action setReviewed = (value: boolean) => this.reviewed = value;
    @action setLoading = (value: boolean) => this.loading = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action resetLabels = (value: LabelStat[]) => this.labels = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setToaster = (value?: React.ReactText) => this.toaster = value;
    @action setLabels = (value: LabelStat[]) => {
        var existing = this.labels.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.labels.push(...missing);
    };


    @action load = async () => {
        try {

            this.setLoading(true);

            var response =
                await agent.Labels.get(this.searchText, this.reviewed, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setLabels(response.Items);

            agent.Labels.count().then((count) => this.setCount(count))
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetLabels([]);
            this.setCurrentPage(0)

            this.load();

            if (text !== "" && text !== undefined)
                this.rootStore.analyticsStore.setSearchEvent("Online Shop", text);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action merge = async (id: string) => {
        try {
            if (this.togroup.includes(id)) {
                this.togroup = this.togroup.filter(f => f !== id);
                return;
            }
            else {
                this.togroup.push(id);
            }

            if (this.togroup.length > 1) {
                agent.Labels.group(this.togroup).then(() => {
                    toast.success("Group is done");
                    runInAction(() => {
                        this.togroup = [];
                    })
                    this.search(this.searchText);
                })
            }

            agent.Labels.count().then((count) => this.setCount(count))

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action dispose = () => {
        this.setLoading(false);
        this.setCurrentPage(0);
        this.setHasMore(false);
    }
}