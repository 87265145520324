import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Button, Divider, Dropdown, DropdownProps, Icon, Menu, Segment, Sidebar, Table } from 'semantic-ui-react'
import { RootStoreContext } from '../../stores/RootStoreContext'
import { Countries } from '../../options/Countries'
import { VatCategoriesStore } from './functions/VatCategoriesStore'
import VatDetailWidget from './components/VatDetailWidget'

const VATCategories = () => {
    const context = useContext(RootStoreContext)
    const { isMobile } = context.deviceStore;
    const { load, vatCategories, save, editingItem, setEditingItem, countryId } = VatCategoriesStore;

    return (
        <div>
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "0 0 5px 0" }}
            >
                <Menu.Item style={{ padding: "0px" }}>
                    <Dropdown
                        placeholder={"Select Country.."}
                        closeOnBlur
                        selection
                        clearable
                        search
                        onChange={(ev, data: DropdownProps) => {
                            load(data?.value as number)
                        }}
                        options={Countries.filter((f) => f.active === true)
                            ?.slice()
                            .sort((a, b) => (a.text < b.text ? -1 : 1))
                        }
                    />
                </Menu.Item>
                <Menu.Item>
                    <Button
                        color="green"
                        icon='refresh'
                        basic
                        className="no-margin"
                        onClick={() => load(countryId)}
                    />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Button
                            content="New"
                            color="blue"
                            basic
                            className="no-margin"
                            onClick={() => setEditingItem({} as any)}
                        />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider />

            <Sidebar.Pushable style={{ padding: "10px", minHeight: "450px" }}>
                <Sidebar
                    as={Segment}
                    animation={'overlay'}
                    direction={'right'}
                    width="very wide"
                    visible={editingItem !== undefined}
                // onHidden={load()}
                >
                    <VatDetailWidget data={editingItem} save={save} setData={setEditingItem} />
                </Sidebar>
                <Sidebar.Pusher style={{ overflow: 'auto', maxHeight: "88vh" }}>
                    <Table basic="very" celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell textAlign='center'>Country</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Code</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Rate</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Description</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Allow Discount</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Has Profit</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {vatCategories.slice().sort((a, b) => a.rate > b.rate ? 1 : -1).map((x) => (
                                <Table.Row error={!x.active} key={x.id!} onDoubleClick={() => setEditingItem(x)}>
                                    <Table.Cell>{Countries.filter(f => f.value === x.countryId)[0].text}</Table.Cell>
                                    <Table.Cell>{x.code}</Table.Cell>
                                    <Table.Cell textAlign='right'>{x.rate}</Table.Cell>
                                    <Table.Cell>{x.title}</Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <Icon
                                            name={x.apply_discount ? "check" : "close"}
                                            color={x.apply_discount ? "green" : "red"}
                                        />
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <Icon
                                            name={x.has_profit ? "check" : "close"}
                                            color={x.has_profit ? "green" : "red"}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </div >
    )
}

export default observer(VATCategories)