import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { LabelStat } from "../../../interfaces/labelStats";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import LabelsDictionary from "../LabelsDictionary";
import agent from "../../../api/agent";
import { toast } from "react-toastify";

interface IProps {
    label: LabelStat;
}

const LabelTableRow: React.FC<IProps> = ({ label }) => {
    const context = useContext(RootStoreContext);
    const { openModal } = context.modalStore;
    const { merge, togroup } = context.labelListStore;

    return (
        <Table.Row negative={togroup.includes(label.id)}>
            <Table.Cell textAlign="center" positive={label.reviewed}>
                <Icon color={label.reviewed ? 'green' : 'red'} name={label.reviewed ? 'check' : 'delete'} />
                <br />
                <Button basic color="blue" content="Toggle" onClick={() => {
                    label.reviewed = !label.reviewed;
                    agent.Labels.update(label.id, label).then(() => toast.success("Updated"));
                }} />
            </Table.Cell>
            <Table.Cell textAlign="center" positive={label.ignore}>
                <Icon color={label.ignore ? 'green' : 'red'} name={label.ignore ? 'check' : 'delete'} />
                <br />
                <Button basic color="blue" content="Toggle" onClick={() => {
                    label.ignore = !label.ignore;
                    agent.Labels.update(label.id, label).then(() => toast.success("Updated"));
                }} />
            </Table.Cell>
            <Table.Cell>
                {label.stems.join(', ')}
            </Table.Cell>
            <Table.Cell>
                {label.originals.join(', ')}
            </Table.Cell>
            <Table.Cell>
                <Fragment>
                    {label.translations && <p>
                        [T]: {" "}
                        {label.translations?.join(', ')}
                    </p>}
                    {label.convert_to && <p>
                        [C]: {" "}
                        {label.convert_to?.join(', ')}
                    </p>}
                </Fragment>
            </Table.Cell>
            <Table.Cell>
                <Table>
                    <Table.Header>
                        <Table.HeaderCell textAlign="center">Organic</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Gluten F</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Lactose F</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Is Brand</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell textAlign="center" positive={label.organic}>
                                <Icon color={label.organic ? 'green' : 'red'} name={label.organic ? 'check' : 'delete'} />
                            </Table.Cell>
                            <Table.Cell textAlign="center" positive={label.glutenf}>
                                <Icon color={label.glutenf ? 'green' : 'red'} name={label.glutenf ? 'check' : 'delete'} />
                            </Table.Cell>
                            <Table.Cell textAlign="center" positive={label.lactosef}>
                                <Icon color={label.lactosef ? 'green' : 'red'} name={label.lactosef ? 'check' : 'delete'} />
                            </Table.Cell>
                            <Table.Cell textAlign="center" positive={label.isbrand}>
                                <Icon color={label.isbrand ? 'green' : 'red'} name={label.isbrand ? 'check' : 'delete'} />
                                {label.isbrand && <p>{label.brand}</p>}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Table.Cell>
            <Table.Cell textAlign="center">
                <Icon name="pencil" color="blue" onClick={() => openModal(<LabelsDictionary editLabel={label} />)} />
                <Icon name="object ungroup outline" color="blue" onClick={() => merge(label.id)} />
            </Table.Cell>
        </Table.Row>
    );
};

export default observer(LabelTableRow);
