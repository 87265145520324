export const Localities =
    [
        { "value": -1, "key": "-1", "text": "MALTA", "className": "drpHeader", "disabled": true },
        { "value": 1, "key": "1", "description": "Ħ'Attard", "text": "Attard", "className": "drpItem" },
        { "value": 2, "key": "2", "description": "Ħal Balzan", "text": "Hal Balzan", "className": "drpItem" },
        { "value": 3, "key": "3", "description": "Il-Bidnija", "text": "Bidnija", "className": "drpItem" },
        { "value": 4, "key": "4", "description": "Il-Birgu (Vittoriosa)", "text": "Birgu", "className": "drpItem" },
        { "value": 5, "key": "5", "description": "Birkirkara", "text": "Birkirkara", "className": "drpItem" },
        { "value": 6, "key": "6", "description": "Birżebbuġa", "text": "Birzebbuga", "className": "drpItem" },
        { "value": 7, "key": "7", "description": "Bormla (Cospicua)", "text": "Bormla", "className": "drpItem" },
        { "value": 8, "key": "8", "description": "Ħad-Dingli", "text": "Dingli", "className": "drpItem" },
        { "value": 9, "key": "9", "description": "Il-Fgura", "text": "Fgura", "className": "drpItem" },
        { "value": 10, "key": "10", "description": "Il-Furjana", "text": "Furjana", "className": "drpItem" },
        { "value": 11, "key": "11", "description": "Ħal Għargħur", "text": "Gharghur", "className": "drpItem" },
        { "value": 12, "key": "12", "description": "Ħal Għaxaq", "text": "Hal Ghaxaq", "className": "drpItem" },
        { "value": 13, "key": "13", "description": "Il-Gudja", "text": "Gudja", "className": "drpItem" },
        { "value": 14, "key": "14", "description": "Il-Gżira", "text": "Gzira", "className": "drpItem" },
        { "value": 15, "key": "15", "description": "Il-Ħamrun", "text": "Hamrun", "className": "drpItem" },
        { "value": 16, "key": "16", "description": "L-Iklin", "text": "Iklin", "className": "drpItem" },
        { "value": 17, "key": "17", "description": "Il-Kalkara", "text": "Kalkara", "className": "drpItem" },
        { "value": 18, "key": "18", "description": "Ħal Kirkop", "text": "Kirkop", "className": "drpItem" },
        { "value": 19, "key": "19", "description": "Ħal Lija", "text": "Lija", "className": "drpItem" },
        { "value": 20, "key": "20", "description": "Ħal Luqa", "text": "Luqa", "className": "drpItem" },
        { "value": 21, "key": "21", "description": "Il-Marsa", "text": "Marsa", "className": "drpItem" },
        { "value": 22, "key": "22", "description": "Marsaskala or Wied il-Għajn", "text": "Marsaskala", "className": "drpItem" },
        { "value": 23, "key": "23", "description": "Marsaxlokk", "text": "Marsaxlokk", "className": "drpItem" },
        { "value": 24, "key": "24", "description": "L-Imdina", "text": "Imdina", "className": "drpItem" },
        { "value": 25, "key": "25", "description": "Il-Mellieħa", "text": "Mellieha", "className": "drpItem" },
        { "value": 26, "key": "26", "description": "L-Imġarr", "text": "Imgarr", "className": "drpItem" },
        { "value": 27, "key": "27", "description": "Il-Mosta", "text": "Mosta", "className": "drpItem" },
        { "value": 28, "key": "28", "description": "L-Imqabba", "text": "Imqabba", "className": "drpItem" },
        { "value": 29, "key": "29", "description": "L-Imsida", "text": "Imsida", "className": "drpItem" },
        { "value": 30, "key": "30", "description": "L-Imtarfa", "text": "Imtarfa", "className": "drpItem" },
        { "value": 31, "key": "31", "description": "In-Naxxar", "text": "Naxxar", "className": "drpItem" },
        { "value": 32, "key": "32", "description": "Raħal Ġdid (Paola)", "text": "Paola", "className": "drpItem" },
        { "value": 33, "key": "33", "description": "Pembroke", "text": "Pembroke", "className": "drpItem" },
        { "value": 34, "key": "34", "description": "Tal-Pietà", "text": "Pieta", "className": "drpItem" },
        { "value": 35, "key": "35", "description": "Ħal Qormi", "text": "Qormi", "className": "drpItem" },
        { "value": 36, "key": "36", "description": "Il-Qrendi", "text": "Qrendi", "className": "drpItem" },
        { "value": 37, "key": "37", "description": "Ir-Rabat, Malta", "text": "Rabat, Malta", "className": "drpItem" },
        { "value": 38, "key": "38", "description": "Ħal Safi", "text": "Safi", "className": "drpItem" },
        { "value": 39, "key": "39", "description": "San Ġwann", "text": "San Gwann", "className": "drpItem" },
        { "value": 40, "key": "40", "description": "Santa Luċija", "text": "Santa Lucija", "className": "drpItem" },
        { "value": 41, "key": "41", "description": "Santa Venera", "text": "Santa Venera", "className": "drpItem" },
        { "value": 42, "key": "42", "description": "L-Isla (Senglea)", "text": "Isla", "className": "drpItem" },
        { "value": 43, "key": "43", "description": "Is-Siġġiewi", "text": "Siggiewi", "className": "drpItem" },
        { "value": 44, "key": "44", "description": "Tas-Sliema", "text": "Sliema", "className": "drpItem" },
        { "value": 45, "key": "45", "description": "San Ġiljan", "text": "Giljan", "className": "drpItem" },
        { "value": 46, "key": "46", "description": "San Pawl il-Baħar", "text": "San Pawl Bay", "className": "drpItem" },
        { "value": 47, "key": "47", "description": "Is-Swieqi", "text": "Swieqi", "className": "drpItem" },
        { "value": 48, "key": "48", "description": "Ħal Tarxien", "text": "Tarxien", "className": "drpItem" },
        { "value": 49, "key": "49", "description": "Ta' Xbiex", "text": "Ta' Xbiex", "className": "drpItem" },
        { "value": 50, "key": "50", "description": "Il-Belt Valletta", "text": "Valletta", "className": "drpItem" },
        { "value": 51, "key": "51", "description": "Ix-Xgħajra", "text": "Xghajra", "className": "drpItem" },
        { "value": 52, "key": "52", "description": "Ħaż-Żabbar", "text": "Zabbar", "className": "drpItem" },
        { "value": 53, "key": "53", "description": "Ħaż-Żebbuġ (Malta)", "text": "Zebbug", "className": "drpItem" },
        { "value": 54, "key": "54", "description": "Iż-Żejtun", "text": "Zejtun", "className": "drpItem" },
        { "value": 55, "key": "55", "description": "Iż-Żurrieq", "text": "Zurrieq", "className": "drpItem" },
        { "value": -2, "key": "-2", "text": "GOZO", "className": "drpHeader", "disabled": true },
        { "value": 56, "key": "56", "description": "Il-Fontana or It-Triq tal-Għajn", "text": " Fontana", "className": "drpItem" },
        { "value": 57, "key": "57", "description": "Għajnsielem", "text": " Ghajnsielem", "className": "drpItem" },
        { "value": 58, "key": "58", "description": "L-Għarb", "text": "Gharb", "className": "drpItem" },
        { "value": 59, "key": "59", "description": "L-Għasri", "text": "Ghasri", "className": "drpItem" },
        { "value": 60, "key": "60", "description": "Ta' Kerċem", "text": " Ta' Kercem", "className": "drpItem" },
        { "value": 61, "key": "61", "description": "Il-Munxar and Ix-Xlendi", "text": "Munxar and Xlendi", "className": "drpItem" },
        { "value": 62, "key": "62", "description": "In-Nadur", "text": " Nadur", "className": "drpItem" },
        { "value": 63, "key": "63", "description": "Il-Qala", "text": " Qala", "className": "drpItem" },
        { "value": 64, "key": "64", "description": "Ir-Rabat", "text": " Rabat", "className": "drpItem" },
        { "value": 65, "key": "65", "description": "San Lawrenz", "text": "San Lawrenz", "className": "drpItem" },
        { "value": 66, "key": "66", "description": "Ta' Sannat", "text": "Sannat", "className": "drpItem" },
        { "value": 67, "key": "67", "description": "Ix-Xagħra", "text": "Xaghra", "className": "drpItem" },
        { "value": 68, "key": "68", "description": "Ix-Xewkija", "text": "Xewkija", "className": "drpItem" },
        { "value": 69, "key": "69", "description": "Iż-Żebbuġ and Marsalforn", "text": "Zebbug and Marsalforn", "className": "drpItem" },
    ]