import { observer } from "mobx-react-lite";
import React, { ChangeEvent, Fragment, useContext, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Card,
  Divider,
  Menu,
  Input,
  Icon,
  Button,
  Dimmer,
  Loader,
  Message,
  Segment,
  Dropdown,
  DropdownProps,
  Label,
  List,
  Popup,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { requestUserSession } from "../registration/functions/sessionFnc";
import CatalogueOfferCard from "./components/CatalogueOfferCard";
import { NoResults } from "../../common/NoResults";

const Offers = () => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const {
    dispose,
    load,
    offers,
    loading,
    search,
    hasMore,
    oneTimeLoad,
    searchText,
    changeOrder,
    orderByDrop,
  } = context.offersStore;
  const { selectedMarketIds, setSelectedMarketIds } = context.commonStore;
  const [timer, setTimer] = React.useState(0);
  const { setPage } = context.analyticsStore;
  const { user } = context.userStore;
  const { openModal, closeModal } = context.modalStore;
  const { isLoggedIn } = context.sessionStore;
  const { isMobile } = context.deviceStore;

  const [advancedSearch, setAdvanceSearch] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    oneTimeLoad();
    setPage("Price Drop", window.location.pathname);

    if (user) load();
    return () => {
      dispose();
    };
  }, [load, dispose, oneTimeLoad, setPage, user]);

  return (
    <div className="simply-paddedPage">
      <Fragment>
        <div>
          <Menu
            secondary
            size={isMobile ? "massive" : "small"}
            style={{ margin: "0 0 5px 0" }}
          >
            <Menu.Item
              className={!isMobile ? "no-padding" : ""}
              style={{ flex: isMobile ? 1 : "" }}
            >
              <Input
                fluid={isMobile}
                // disabled={loading || !search}
                transparent={isMobile}
                size={isMobile ? "large" : "small"}
                icon={
                  !isMobile ? <Icon name="search" className="ct-green-text" /> : undefined
                }
                placeholder={"Search..."}
                input={<input className={isMobile ? "" : "ctGreenPlaceholder"} />}
                onChange={(event: ChangeEvent, data: any) => {
                  if (!search) return;
                  if (timer != null) {
                    clearTimeout(timer);
                    setTimer(0);
                  }

                  setTimer(
                    setTimeout(
                      (callback: (text: string) => void, text: string) => {
                        callback(text);
                        clearTimeout(timer);
                      },
                      500,
                      search,
                      data.value
                    )
                  );
                }}
              />
            </Menu.Item>
            {isMobile ? (
              <Fragment>
                <Menu.Item icon style={{ margin: 0 }}>
                  <Icon.Group>
                    <Icon
                      fitted
                      onClick={() => {
                        setAdvanceSearch(!advancedSearch);
                      }}
                      name="filter"
                      color={advancedSearch ? "green" : "black"}
                      disabled={loading}
                    />
                    {selectedMarketIds.length > 0 && (
                      <Label
                        circular
                        className="ct-green-text"
                        floating
                        size="mini"
                        style={{ marginLeft: "1px", margingBottom: "1px" }}
                        onClick={() => setAdvanceSearch(!advancedSearch)}
                      >
                        {selectedMarketIds.length}
                      </Label>
                    )}
                  </Icon.Group>
                </Menu.Item>
                <Menu.Item icon style={{ margin: "0px" }}>
                  <Icon
                    fitted
                    onClick={() => {
                      changeOrder();
                    }}
                    name="sort numeric ascending"
                    color={orderByDrop ? "green" : "black"}
                    disabled={loading}
                  />
                </Menu.Item>
              </Fragment>
            ) : (
              <Menu.Menu position="right">
                <Menu.Item>
                  <Button
                    color="green"
                    basic={!orderByDrop}
                    icon={"sort numeric ascending"}
                    active={orderByDrop}
                    content={"Order by price change"}
                    onClick={() => {
                      changeOrder();
                    }}
                  />
                </Menu.Item>
                <Menu.Item style={{ paddingRight: "0px", marginRight: "0px" }}>
                  <div className="fxFixed fxDisplay fxAlignCenter">
                    <div className="catalogue-sort-by-holder">Shops |</div>
                    <Popup
                      on="click"
                      closeOnTriggerClick
                      closeOnDocumentClick
                      closeOnPortalMouseLeave
                      closeOnTriggerBlur
                      closeOnTriggerMouseLeave
                      closeOnEscape
                      position="bottom center"
                      trigger={
                        <div className="fxFixed fxDisplay">
                          <div className="fxStrech catalogue-sort-by-selected">
                            {selectedMarketIds.length === 0 ? "All" : selectedMarketIds.length > 2 ? `${selectedMarketIds.length} shops` :
                              markets.filter(f => selectedMarketIds.includes(f.type_id)).map(x => x.title).join(',')}
                          </div>
                          <Icon name="angle down" />
                        </div>}
                      content={
                        <List size="medium">
                          {markets.filter(f => !f.shutDown)
                            ?.slice()
                            .sort((a, b) => (a.title < b.title ? -1 : 1)).map((f, i) => (
                              <List.Item
                                key={f.type_id}
                                onClick={() => {
                                  if (selectedMarketIds.includes(f.type_id)) {
                                    setSelectedMarketIds(selectedMarketIds.filter(x => x !== f.type_id));
                                  }
                                  else {
                                    setSelectedMarketIds([...selectedMarketIds, f.type_id])
                                  }

                                  if (!search) return;
                                  if (timer != null) {
                                    clearTimeout(timer);
                                    setTimer(0);
                                  }

                                  setTimer(
                                    setTimeout(
                                      (callback: (text: string) => void, text: string) => {
                                        callback(searchText);
                                        clearTimeout(timer);
                                      },
                                      500,
                                      search
                                    )
                                  );
                                }}
                                className={`${i !== 0 ? "catalogue-sort-by-option" : ""} clickable`}
                              >
                                <div className={`fxDisplay fxFixed ${selectedMarketIds.includes(f.type_id) ? "ct-green-text-dark" : ""}`}  >
                                  <div style={{ width: "18px", textAlign: "start" }}>{selectedMarketIds.includes(f.type_id) && <Icon name={"check"} />}</div>
                                  <div style={{ marginLeft: "5px" }}>{f.title}</div>
                                </div>
                              </List.Item>
                            ))}
                        </List>
                      }
                    />
                  </div>
                </Menu.Item>

              </Menu.Menu>
            )}
          </Menu>
          <CSSTransition
            unmountOnExit
            in={advancedSearch}
            timeout={300}
            classNames="fluid headerWithSearchAdvancedSearch transition"
          >
            <Menu secondary compact={isMobile} widths={1}>
              <Menu.Item>
                <Dropdown
                  placeholder={
                    !user ? "Sign in to filter stores" : "Filter stores"
                  }
                  value={selectedMarketIds}
                  closeOnBlur
                  selection
                  multiple
                  fluid
                  clearable
                  search
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  renderLabel={(
                    item: any,
                    index: number,
                    defaultLabelProps: any
                  ) => {
                    if (index === 0) return { content: item.text };
                    if (index === 1) return { content: item.text };

                    return index === 2
                      ? {
                        color: defaultLabelProps.color,
                        content: `and ${selectedMarketIds.length - 2
                          } more supermarket(s)`,
                      }
                      : undefined;
                  }}
                  onChange={(ev, data: DropdownProps) => {
                    setSelectedMarketIds((data?.value ?? []) as string[]);
                    if (!search) return;
                    if (timer != null) {
                      clearTimeout(timer);
                      setTimer(0);
                    }

                    setTimer(
                      setTimeout(
                        (callback: (text: string) => void, text: string) => {
                          callback(searchText);
                          clearTimeout(timer);
                        },
                        500,
                        search
                      )
                    );
                  }}
                  options={markets
                    ?.slice()
                    .sort((a, b) => (a.title < b.title ? -1 : 1))
                    .map((x) => ({
                      key: x.type_id,
                      text: x.title,
                      value: x.type_id,
                      disabled: user === undefined,
                    }))}
                />
              </Menu.Item>
            </Menu>
          </CSSTransition>
        </div>
        <Divider style={isMobile ? { padding: "0", margin: "0" } : undefined} />

        {!loading && (!isLoggedIn || !user) && (
          <div>
            <div style={{ padding: "5px" }}>
              <Message
                icon="user"
                info
                header="A free account is required to access this page!"
                content={
                  <div>
                    {
                      "Hi there, thank you for being here, we have already grouped many products for your convenience to see which grocery shop has the cheapest but you need to create a free account. Your account will always be free and we will not ask you for any money!"
                    }
                    <Divider hidden />
                    <div style={{ textAlign: "center" }}>
                      <Button
                        content="Sign In"
                        basic
                        className="ct-green-button-active"
                        onClick={() => {
                          requestUserSession(
                            isMobile,
                            openModal,
                            closeModal,
                            () => {
                              closeModal();
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        )}
        {!loading && user && isLoggedIn && offers.length === 0 && (
          <NoResults />
        )}
        {(!loading || offers.length > 0) && markets.length > 0 ? (
          <div>
            {user && isLoggedIn && offers.length > 0 && markets.length > 0 && (
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={offers.length} //This is important field to render the next data
                next={() => load()}
                hasMore={hasMore}
                loader={loading ? <h4>Loading...</h4> : <div />}

              >
                <Card.Group
                  centered
                  className="productPage"
                  itemsPerRow={isMobile ? 1 : undefined}
                  style={
                    offers.length === 0
                      ? { margin: "0px", padding: "5px" }
                      : { padding: "5px" }
                  }
                >
                  {offers.map((x, i) => <CatalogueOfferCard key={x.productId} offer={x} />)}

                  {/* {offers.map((x, i) =>
                    i % inlineOccurence === 0 && i > 0 ? (
                      isMobile ? (
                        <Fragment key={`${x.productId}_${i}_add_fr`}>
                          {showAdds && (
                            <InlineAdd key={`${x.productId}_${i}_add`} />
                          )}
                          <OfferCard key={x.productId} offer={x} />
                        </Fragment>
                      ) : (
                        <Fragment key={`${x.productId}_${i}_add_fr`}>
                          {showAdds && (
                            <InlineAdd key={`${x.productId}_${i}_add`} />
                          )}
                          <OfferCard key={x.productId} offer={x} />
                        </Fragment>
                      )
                    ) : isMobile ? (
                      <OfferCard key={x.productId} offer={x} />
                    ) : (
                      <OfferCard key={x.productId} offer={x} />
                    )
                  )} */}
                </Card.Group>
              </InfiniteScroll>
            )}
          </div>
        ) : (
          <Segment
            style={{
              minHeight: "55vh",
              boxShadow: "none",
              border: "none",
            }}
          >
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
      </Fragment>
    </div>
  );
};

export default observer(Offers);
