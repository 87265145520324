import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Header, Label, Table } from "semantic-ui-react";
import {
  getPromoColor,
  getPromoStatus,
} from "../../../helpers/promoDateHelper";
import { IPromoCodeForList } from "../../../interfaces/promos";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import PromoDetailWidget from "./PromoDetailWidget";

interface IProps {
  promoCode: IPromoCodeForList;
}

const PromoCodeTableRow: React.FC<IProps> = ({ promoCode }) => {
  const context = useContext(RootStoreContext);
  const { openModal } = context.modalStore;

  return (
    <Table.Row
      onClick={() => {
        openModal(
          <PromoDetailWidget editingItem={promoCode} />,
          "mini",
          `Edit promotional code : ${promoCode.code}`,
          true,
          false
        );
      }}
    >
      <Table.Cell>{promoCode.code}</Table.Cell>
      <Table.Cell textAlign="right">
        {" "}
        {(promoCode.discount ?? 0) > 0
          ? `${promoCode.discount} %`
          : `${promoCode.fixedDiscount} €`}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {`${promoCode.hitCount ?? 0} ${
          (promoCode.maxTotalHits ?? 0) > 0
            ? ` - (${promoCode.maxTotalHits! - promoCode.hitCount!} remaining)`
            : ""
        }`}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Label
          size="large"
          color={
            promoCode.isDeleted
              ? "grey"
              : getPromoColor(promoCode.activeFrom, promoCode.activeTo)
          }
        >
          {promoCode.isDeleted
            ? "Deleted"
            : getPromoStatus(promoCode.activeFrom, promoCode.activeTo)}
        </Label>
      </Table.Cell>
      <Table.Cell>
        {format(new Date(promoCode.activeTo * 1000), "EEEE, do MMMM yyyy")}
      </Table.Cell>
    </Table.Row>
  );
};

export default observer(PromoCodeTableRow);
