import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Button, Header, Icon } from 'semantic-ui-react'
import { RootStoreContext } from '../../../stores/RootStoreContext';

const MetricSelection = () => {
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const {
        setProfile,
        profile,
        nextStep,
        previousStep
    } = context.userDietProfileStore;

    return (
        <div
            className='fxFixed fxDisplay fxAlignCenter fxStretch fxDirCol'
            style={{ marginTop: "55px" }}>
            <Header
                size='large'
                textAlign='left'
                content="How do you measure things?"
                subheader="We will use these preferences to display things to you!"
                style={isMobile ? { padding: "15px" } : {
                    marginBottom: "25px"
                }}
            />

            <div style={isMobile ? { padding: "10px", width: "100%" } : { maxWidth: "550px", width: "550px" }}>
                <div className='fxFixed fxDisplay fxJustifyBetween'>
                    <div className='fxStretch' style={{ margin: "auto" }}>Measurement Unit</div>
                    <div className=''>
                        <Button.Group>
                            <Button onClick={() => setProfile({ ...profile, meas_type: "us_standard" })} className={profile?.meas_type === "us_standard" ? 'ct-green-button-active' : 'ct-green-button'} content="U.S. Standart" active={profile?.meas_type === "us_standard"} />
                            <Button onClick={() => setProfile({ ...profile, meas_type: "metric" })} className={profile?.meas_type === "metric" ? 'ct-green-button-active' : 'ct-green-button'} content="Metric" active={profile?.meas_type === "metric"} />
                        </Button.Group>
                    </div>
                </div>
                <div className='fxDisplay' style={{ marginTop: "30px" }}>
                    <div className='fxStretch' style={{ margin: "auto" }}>Energy Unit</div>
                    <div className=''>
                        <Button.Group>
                            <Button onClick={() => setProfile({ ...profile, en_meas_type: "calories" })} className={profile?.en_meas_type === "calories" ? 'ct-green-button-active' : 'ct-green-button'} content="Calories" active={profile?.en_meas_type === "calories"} />
                            <Button onClick={() => setProfile({ ...profile, en_meas_type: "kilojoules" })} className={profile?.en_meas_type === "kilojoules" ? 'ct-green-button-active' : 'ct-green-button'} content="Kilojoules" active={profile?.en_meas_type === "kilojoules"} />
                        </Button.Group>
                    </div>
                </div>
            </div>


            <div className='fxDisplay fxFixed fxJustifyBetween'
                style={isMobile ? {
                    marginBottom: "15px",
                    width: "100%",
                    paddingLeft: "10px",
                    paddingRight: "10px"
                } : {
                    width: "550px",
                    marginBottom: "15px"
                }}>
                <div
                    className={`diet-continue-button clickable`}
                    style={{ marginTop: "22px", }}
                    onClick={() => {
                        previousStep()
                    }}
                >
                    Back
                    <Icon name='angle left' />
                </div>
                <div
                    className={`diet-continue-button clickable`}
                    style={{ marginTop: "22px", }}
                    onClick={() => {
                        nextStep()
                    }}
                >
                    <Icon name='angle right' />
                    Continue
                </div>
            </div>
        </div>
    )
}

export default observer(MetricSelection)