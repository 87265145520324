import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Dimmer, Loader, Segment, Table } from 'semantic-ui-react'
import { RootStoreContext } from '../../stores/RootStoreContext'
import LastSyncTableRow from './components/LastSyncTableRow'

const LastSync = () => {
    const context = useContext(RootStoreContext);
    const { markets } = context.cacheStore;
    const {
        load,
        items,
        dispose,
        loading,
        hasMore,
    } = context.lastSyncStore;

    useEffect(() => {
        load();

        return () => {
            dispose();
        }
    }, [load, dispose])

    return (
        <div className="simply-paddedPage">
            {((!loading || items.length > 0) && markets.length > 0) ? (
                <div>
                    <InfiniteScroll
                        style={{ overflow: "hidden" }}
                        dataLength={items.length}
                        next={() => load()}
                        hasMore={hasMore}
                        loader={loading ? <h4>Loading...</h4> : <div />}

                    >

                        <Table basic="very" celled>
                            <Table.Header>
                                <Table.Row textAlign='center'>
                                    <Table.HeaderCell>Product</Table.HeaderCell>
                                    <Table.HeaderCell>Shop</Table.HeaderCell>
                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                    <Table.HeaderCell>Grouped</Table.HeaderCell>
                                    <Table.HeaderCell>Discontinued</Table.HeaderCell>
                                    <Table.HeaderCell>Price</Table.HeaderCell>
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {items.map((x) => (
                                    <LastSyncTableRow key={x.id} item={x} markets={markets} />
                                ))}
                            </Table.Body>
                        </Table>
                    </InfiniteScroll>
                </div>
            ) : (
                <Segment
                    style={{
                        minHeight: "55vh",
                        boxShadow: "none",
                        border: "none",
                    }}
                >
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                </Segment>
            )}
        </div>
    )
}

export default observer(LastSync)