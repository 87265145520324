import React, { useContext, useEffect } from 'react'
import { DeliveriesOverviewStore } from '../functions/DeliveriesOverviewStore';
import { Button, Dimmer, Divider, Header, Icon, Loader, Menu, Segment, Table } from 'semantic-ui-react';
import InfiniteScroll from "react-infinite-scroll-component";
import { observer } from 'mobx-react-lite';
import { format, formatRelative } from 'date-fns';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import OpenDeliverySlots from './OpenDeliverySlots';
import { ButtonTypes } from '../../confirmationModal/functions/ButtonTypes';
import { NoResults } from '../../../common/NoResults';

const DeliveriesOverviewWidget = () => {
    const context = useContext(RootStoreContext);
    const { openModal } = context.modalStore;
    const { openConfirmationModal, closeConfirmationalModal } =
        context.confirmationModalStore;
    const {
        dispose,
        slots,
        load,
        loading,
        reload,
        delete_slot,
        update_status,
        includeClosed,
        refresh_slot,
        toggleIncludeClosed,
        closePast,
        hasMore } = DeliveriesOverviewStore;

    useEffect(() => {
        load();
        return () => {
            dispose()
        };
    }, [load, dispose]);

    return (
        <div>{!loading || slots.length > 0 ? (
            <div>
                <Menu secondary size={"small"} style={{ margin: "5px 0" }}>
                    <Menu.Item style={{ padding: "0px" }}>
                        <Button
                            className={includeClosed ? "ct-green-button-active" : 'ct-green-button'}
                            onClick={() => {
                                toggleIncludeClosed();
                            }}
                        >
                            Include Stopped
                        </Button>
                        <Button
                            className={includeClosed ? "ct-green-button-active" : 'ct-green-button'}
                            style={{ marginLeft: "7px" }}
                            onClick={() => {
                                closePast();
                            }}
                        >
                            Close Past
                        </Button>
                    </Menu.Item>

                    <Menu.Menu position="right">
                        <Menu.Item style={{ padding: "0px" }}>
                            <Button
                                className='ct-green-button'
                                onClick={() => {
                                    openModal(
                                        <OpenDeliverySlots />,
                                        "large",
                                        "Open Delivery Slots",
                                        true,
                                        false, () => {
                                            reload();
                                        }
                                    );
                                }}
                            >
                                Open Delivery Slots
                            </Button>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Divider />
                <InfiniteScroll
                    style={{ overflow: "hidden" }}
                    dataLength={slots.length} //This is important field to render the next data
                    next={() => load()}
                    hasMore={hasMore}
                    loader={loading ? <h4>Loading...</h4> : <div />}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            {slots.length > 0 ? (
                                <b></b>
                            ) : (
                                <NoResults />
                            )}
                        </p>
                    }
                >
                    <Table basic="very" celled compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>From/To</Table.HeaderCell>
                                <Table.HeaderCell>Reserved</Table.HeaderCell>
                                <Table.HeaderCell>Confirmed</Table.HeaderCell>
                                <Table.HeaderCell>Limit</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {slots.map((x) => (
                                <Table.Row key={x.id} positive={x.status === "open"} negative={x.status === "close"}>
                                    <Table.Cell>
                                        <Header size='small'>
                                            {`${x.name} - ${formatRelative(new Date(x.startsOn * 1000), new Date())}`}
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {`${format(new Date(x.from * 1000), 'HH:mm')} to
                                         ${format(new Date(x.to * 1000), 'HH:mm')} h`}
                                    </Table.Cell>
                                    <Table.Cell error={x.reserved > 0}>
                                        {x.reserved}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {x.confirmed}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {x.limit}
                                    </Table.Cell>
                                    <Table.Cell>


                                        <Icon name={x.status === "open" ? "stop" : "play"}
                                            circular
                                            color={x.status === "open" ? "red" : "green"}
                                            inverted
                                            onClick={() => {
                                                update_status(x.id, x.status === "open" ? "close" : "open");
                                            }} />

                                        <Icon
                                            style={{ marginLeft: "7px", marginRight: "7px" }}
                                            name="refresh"
                                            circular
                                            color="blue"
                                            inverted
                                            onClick={() => {
                                                refresh_slot(x.id);
                                            }} />

                                        <Icon name="delete" circular color='red' inverted onClick={() => {
                                            openConfirmationModal(
                                                "Confirm Delivery Slot deletion",
                                                "Are you sure you want to delete this delivery slot?",
                                                () =>
                                                    delete_slot(x.id).then(() =>
                                                        closeConfirmationalModal()
                                                    ),
                                                "Cancel",
                                                "Delete",
                                                ButtonTypes.None,
                                                ButtonTypes.Negative
                                            );
                                        }} />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </InfiniteScroll>
            </div>
        ) : (
            <Segment
                style={{
                    minHeight: "55vh",
                    boxShadow: "none",
                    border: "none",
                }}
            >
                <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            </Segment>
        )
        }</div>
    )
}

export default observer(DeliveriesOverviewWidget)