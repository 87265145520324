import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect } from 'react'
import { IChefAIProductLookUp, IMessageContent } from '../../../interfaces/chat'
import { Card, Divider, Icon, List } from 'semantic-ui-react'
import agent from '../../../api/agent'
import ChefCatalogueCard from './ChefCatalogueCard'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import ResponseFeedback from './ResponseFeedback'

interface IProps {
    msg_id: string;
    message: IMessageContent;
    scroll: () => void;
    hideMessage?: boolean;
    query?: string;
    status?: boolean;
}

const ProductsMessage: React.FC<IProps> = ({ message, scroll, hideMessage = false, query, msg_id, status }) => {
    const context = useContext(RootStoreContext);
    const { brands, smartCategories } = context.cacheStore;
    const { conversationId } = context.chatUIStore;

    const [loading, setLoading] = React.useState(true);
    const [response, setResponse] = React.useState<IChefAIProductLookUp>({
        brands: [],
        products: [],
        categories: []
    });

    const load = async () => {
        try {
            const result = await agent.Catalogue.Order.getForChef(message.products.map(x => x.id));
            setResponse(result);
            setLoading(false);
            scroll();
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        load()

        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            {!hideMessage && message.message}

            {!hideMessage && <Divider hidden />}
            {loading && <div className='ct-green-text-dark' style={{ textAlign: "center" }}>
                <Icon name='circle notch' loading size='big' />
                <p style={{ paddingTop: "5px" }}>Loading product details....</p>
            </div>}
            <Card.Group style={{ paddingLeft: "13px" }}>
                {response.products.length > 0 && response.products.filter(
                    (f) => (f.price ?? 0) > 0).length === 0 && !loading &&
                    <div>
                        It seems that there have been changes since I was last trained and all related products are out of stock.
                        It's better if you manually search for the products by clicking
                        <span
                            className='clickable ct-green-text'
                            onClick={() => {
                                window.open(`${window.location.origin}/online-shop?q=${message.query?.map((x) => x.Title).join(" ") ?? query}`, "_blank");
                            }}>
                            {" "} here
                        </span>!
                    </div>
                }
                {response.products.length === 0 && !loading && <div>
                    no such product available
                </div>}
                {response.products
                    .filter(
                        (f) => (f.price ?? 0) > 0)
                    .map(
                        (x, i) => (
                            <ChefCatalogueCard
                                key={`${x.id} _${i}`}
                                product={x} />
                        )
                    )}
                {/* {!hasMore && <InlineAdd key={"last_ad"} />} */}
            </Card.Group>
            {(response.brands?.length > 0 || response.categories?.length) &&
                <div style={{ paddingTop: "15px" }}>
                    {response.brands &&
                        <div>
                            <span className='ct-green-text-dark' style={{ fontWeight: "600", fontSize: "14px" }}>Brands :</span>
                            <List style={{ paddingLeft: "5px" }} horizontal>{brands.filter(f => response.brands.includes(f.id)).map((x, i) =>
                                <List.Item
                                    className='clickable'
                                    style={{ fontSize: "13px", fontStyle: "italic", marginLeft: "3px" }}
                                    onClick={() => {
                                        window.open(`${window.location.origin}/online-shop?brand=${x.id}`, "_blank");
                                    }}>
                                    {x.title}
                                    {(i < (response.brands.length - 1)) && <span>,</span>}
                                </List.Item>)}</List>
                        </div>}
                    {response.categories &&
                        <div>
                            <span className='ct-green-text-dark' style={{ fontWeight: "600", fontSize: "14px" }}>Categories :</span>
                            <List style={{ paddingLeft: "5px" }} horizontal>{smartCategories.filter(f => response.categories.includes(f.id)).map((x, i) =>
                                <List.Item
                                    className='clickable'
                                    style={{ fontSize: "13px", fontStyle: "italic", marginLeft: "3px" }}
                                    onClick={() => {
                                        window.open(`${window.location.origin}/online-shop?c=${x.id}`, "_blank");
                                    }}>
                                    {x.description}
                                    {(i < (response.categories.length - 1)) && <span>,</span>}
                                </List.Item>)}
                            </List>
                        </div>}
                </div>}
            {msg_id !== '' && <ResponseFeedback chat_id={conversationId!} msg_id={msg_id} initialStatus={status} />}
        </Fragment >
    )
}

export default observer(ProductsMessage)