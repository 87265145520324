import { action, observable, makeObservable } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { ILocation, ILocationForList } from "../../../interfaces/locations";

export default class LocationsManagementStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable selectedAccountId?: string = undefined;
    @observable loading: boolean = true;
    @observable loadingDetail: boolean = false;
    @observable searching: boolean = false;
    @observable lastId: string | null = null;
    @observable lastSearch: string = "";
    @observable items: ILocationForList[] = [];
    @observable editingItem?: ILocation = undefined;

    @action setHasMore = (value: boolean) => this.hasMore = value;

    @action setEditingItem = (value: ILocation) => this.editingItem = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setLoading = (value: boolean) => this.loading = value;
    @action setLoadingDetail = (value: boolean) => this.loadingDetail = value;
    @action setSearching = (value: boolean) => this.searching = value;
    @action setItems = (value: ILocationForList[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action oneTimeLoad = async (selectedAccountId: string) => {
        try {
            this.loading = true;
            this.selectedAccountId = selectedAccountId;
        } catch (error) {
        }
    }

    @action loadData = async () => {
        try {
            if (!this.selectedAccountId) return;

            this.loading = true;
            var response = await agent.Shop.Locations.get(
                this.selectedAccountId,
                this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
            console.log(error)
        }
    }

    @action loadDetail = async (locationId?: string) => {
        try {
            if (!this.selectedAccountId || !locationId) return;

            this.loadingDetail = true;
            var response = await agent.Shop.Locations.getDetail(this.selectedAccountId, locationId);

            this.setEditingItem(response);
        } catch (error) {
            console.log(error)
        }
        finally {
            this.setLoadingDetail(false);
        }
    }

    @action reload = async () => {
        try {
            if (!this.selectedAccountId) return;

            this.currentPage = 0;
            this.hasMore = true;
            this.items = [];

            this.loadData();
        } catch (error) {
            console.log(error)
        }
    }

    @action saveLocation = async (values: ILocation) => {
        return (this.editingItem ?
            agent.Shop.Locations.update(this.selectedAccountId!, values) :
            agent.Shop.Locations.add(this.selectedAccountId!, values))
            .then(() => {
                this.reload();
            }).then(() => {
                this.rootStore.modalStore.closeModal();
            })
    }

    @action dispose = () => {
        this.selectedAccountId = undefined;
        this.currentPage = 0;
        this.loading = true;
        this.searching = false;
        this.lastId = null;
        this.hasMore = false;
        this.lastSearch = "";
        this.items = [];
    }

    @action disposeDetail = () => {
        this.editingItem = undefined;
        this.loadingDetail = false;
    }

}