import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { Card, Dimmer, Divider, Header, Loader, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";

const AdminPanel = () => {
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const {
    load,
    loading,
    get_counter,
    items
  } = context.adminPanelStore;

  useEffect(() => {
    load();

    return () => {
    };
  }, [load]);


  const getItems = (type: string) => {
    return items.filter((f) => f.type === type).map((item) => ({
      ...item,
      onClick: () => navigate(item.navigate),
      color: item.metaCode !== undefined && item.hasColor === true ? (get_counter(item.metaCode) > 0 ? "red" : undefined) : undefined,
      meta: item.metaCode !== undefined ? item.meta?.replace(`[[${item.metaCode}]]`, get_counter(item.metaCode).toFixed()) : undefined
    }))
  }

  return (
    loading ? <Segment>
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </Segment> : <div className="simply-paddedPage">
      {items.filter((f) => f.type === "orders").length > 0 && <Fragment>
        <Header color="blue" content="Order Management" />
        <Divider />
        <Card.Group
          itemsPerRow={5}
          centered={isMobile}
          items={getItems("orders")}
        />
      </Fragment>}

      {items.filter((f) => f.type === "updates").length > 0 && <Fragment>
        <Header color="blue" content="Products" />
        <Divider />
        <Card.Group
          itemsPerRow={5}
          centered={isMobile}
          items={getItems("updates")}
        />
      </Fragment>}
      {items.filter((f) => f.type === "owner").length > 0 && (<Fragment>
        <Header color="blue" content="Owner Area" />
        <Divider />
        <Card.Group
          itemsPerRow={5}
          centered={isMobile}
          items={getItems("owner")}
        />
      </Fragment>)}
      {items.filter((f) => f.type === "product_grouping").length > 0 && <Fragment>
        <Header color="blue" content="Product Grouping" />
        <Divider />
        <Card.Group
          itemsPerRow={5}
          centered={isMobile}
          items={getItems("product_grouping")}
        /></Fragment>}
      {items.filter((f) => f.type === "others").length > 0 && <Fragment>
        <Header color="blue" content="Others" />
        <Divider />
        <Card.Group
          itemsPerRow={5}
          centered={isMobile}
          items={getItems("others")}
        />
      </Fragment>}
    </div>
  );
};

export default observer(AdminPanel);
