import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import ClientOrderBubble from "./ClientOrderBubble";
import { FloatMenuPosition } from "../floatingMenu/functions/FloatMenuTypes";

const AllClientOrderBubbles = () => {
  const context = useContext(RootStoreContext);
  const {
    load,
    orders,
  } = context.allClientOrderBubblesStore;

  useEffect(() => {
    load();
  }, [load]);
  function returnPosition(index: number): FloatMenuPosition {
    return {
      xAlign: "right",
      yUnit: "px",
      xPos: 80,
      yPos: 100 + (70 * index),
    };
  }
  return (
    <div>
      {orders.map((x, i) => (
        <ClientOrderBubble
          key={x.id}
          activeOrder={{
            id: x.id,
            checkoutType: x.checkoutType,
            status: x.status,
            displayId: x.displayId,
            payableCost: x.payableCost,
            address: x.address,
            boxes: x.boxes,
            parcelTrackingNumber: x.parcelTrackingNumber,
            notes: x.notes,
          }}
          position={returnPosition(i)}
          redirectUrl={undefined}
        />
      ))}
    </div>
  );
};

export default observer(AllClientOrderBubbles);
