import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IDietaryCategory } from "../../../interfaces/dietaryCategory";

class DietaryCategories {
    @observable dietaryCategories: IDietaryCategory[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @action setCategories = (value: IDietaryCategory[]) => this.dietaryCategories = value;

    @action load = () => {
        agent.DietaryCategories.get().then(value => this.setCategories(value));
    }

    @action save = async (values: IDietaryCategory) => {
        return agent.DietaryCategories.create(values);
    }
}

export const DietaryCategoriesStore = new DietaryCategories();