import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { Menu, Input, Icon, Button, Divider, Sidebar, Segment, Table, Dimmer, Loader, Dropdown, DropdownProps } from 'semantic-ui-react';
import CategoryTableRow from './componenets/CategoryTableRow';
import CreateUpdateCategoryWidget from '../categories/CreateUpdateCategoryWidget';

const ProductCategories = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isMobile } = context.deviceStore;
    const { openModal, closeModal } = context.modalStore;
    const { loading,
        categories,
        load,
        dispose,
        search,
        searchText,
        search_categories,
        setSelectedCategories,
        selected_categories
    } = context.categoriesAdminStore;

    const [timer, setTimer] = React.useState(0);
    const [editingId, setEditingId] = React.useState<string | undefined>(undefined);


    useEffect(() => {
        load();
        return () => {
            dispose();
        }
    }, [load, dispose, navigate])

    return <div className="simply-paddedPage">
        <Menu
            secondary
            size={isMobile ? "massive" : "small"}
            style={{ margin: "5px 0" }}
        >
            <Menu.Item
                className={!isMobile ? "no-padding" : ""}
                style={{ flex: isMobile ? 1 : "" }}
            >
                <Input
                    fluid={isMobile}
                    className='ct-green-placeholder'
                    transparent={isMobile}
                    size={isMobile ? "large" : "small"}
                    icon={!isMobile ? <Icon name="search" className="ct-green-text" /> : undefined}
                    placeholder={"Search..."}
                    input={<input className={isMobile ? "" : "ctGreenPlaceholder"} />}
                    onChange={(event: ChangeEvent, data: any) => {
                        if (!search) return;
                        if (timer != null) {
                            clearTimeout(timer);
                            setTimer(0);
                        }
                        setTimer(
                            setTimeout(
                                (callback: (text: string) => void, text: string) => {
                                    callback(text);
                                    clearTimeout(timer);
                                },
                                500,
                                search,
                                data.value
                            )
                        );
                    }}
                />
            </Menu.Item>
            <Menu.Item style={{ padding: "0px" }}>
                <Dropdown
                    placeholder={
                        "Filter Parent Categories"
                    }
                    value={selected_categories}
                    closeOnBlur
                    selection
                    multiple
                    clearable
                    search
                    onChange={(ev, data: DropdownProps) => {
                        setSelectedCategories((data?.value ?? []) as string[]);
                        if (!search) return;
                        if (timer != null) {
                            clearTimeout(timer);
                            setTimer(0);
                        }

                        setTimer(
                            setTimeout(
                                (callback: (text: string) => void, text: string) => {
                                    callback(searchText);
                                    clearTimeout(timer);
                                },
                                500,
                                search
                            )
                        );
                    }}
                    options={categories?.map((x) => ({
                        key: x.id,
                        text: x.description,
                        value: x.id,
                    }))}
                />
            </Menu.Item>
            <Menu.Menu position='right'>
                <Menu.Item>
                    <Button
                        className='ct-green-button'
                        content="New"
                        icon="plus"
                        onClick={() =>
                            openModal(
                                <CreateUpdateCategoryWidget
                                    showCloseIcon={false}
                                    close={closeModal}
                                    categories={categories} />,
                                "mini",
                                "Create new Brand",
                                true,
                                true,
                            )}
                    />
                </Menu.Item>
            </Menu.Menu>
        </Menu>
        <Divider style={isMobile ? { padding: "0", margin: "0" } : undefined} />


        {!loading || (search_categories ?? categories).length > 0 ? (
            <div>
                <Sidebar
                    as={Segment}
                    animation={'scale down'}
                    direction={'right'}
                    width="very wide"
                    visible={editingId !== undefined}
                    style={{ paddingTop: "100px" }}
                >
                    <CreateUpdateCategoryWidget
                        categories={categories}
                        showCloseIcon={true}
                        close={() => setEditingId(undefined)}
                        editingItem={categories.filter(f => f.id === editingId)[0]}
                    />
                </Sidebar>

                <Table basic="very" celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Parents</Table.HeaderCell>
                            <Table.HeaderCell>Search Term</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {(search_categories ?? categories).map((x: any) =>
                            <CategoryTableRow category={x} editingId={editingId} setEditingId={setEditingId} />
                        )}
                    </Table.Body>
                </Table>
            </div>
        ) : (
            <Segment
                style={{
                    minHeight: "55vh",
                    boxShadow: "none",
                    border: "none",
                }}
            >
                <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            </Segment>
        )}
    </div >
}

export default observer(ProductCategories);