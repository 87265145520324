import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { PricingStrategyStore } from '../functions/PricingStrategyStore';
import { Button, Form } from 'semantic-ui-react'
import { Form as FinalForm, Field } from "react-final-form";
import SelectInput from '../../../form/SelectInput'
import { combineValidators, isRequired } from 'revalidate';
import { FORM_ERROR } from "final-form";
import { toast } from 'react-toastify';
import ErrorMessage from '../../../form/ErrorMessage';
import { PriceStreategies, ProfitTypes } from '../../../options/PricingOptions';
import NumericInput from '../../../form/NumericInput';
import { IPricingDetails } from '../../../interfaces/billing';

interface IProps {
    shopId: string;
}

const validate = combineValidators({
    accountId: isRequired(""),
    profitType: isRequired(""),
    priceStrategy: isRequired(""),
    rate: isRequired(""),
});

const PricingModuleWidget: React.FC<IProps> = ({ shopId }) => {
    const { load, dispose, save, pricingDetails, loading } = PricingStrategyStore;

    useEffect(() => {
        load(shopId);
        return () => {
            dispose();
        }
    }, [load, dispose, shopId])

    return (
        <div
            className="fxFixed fxDisplay fxDirCol fxAlignCenter"
        >
            <FinalForm
                onSubmit={(values: IPricingDetails) =>
                    save(values)
                        .then(() => toast.success("Saved", { autoClose: 1000 }))
                        .catch((error) => ({
                            [FORM_ERROR]: error,
                        }))
                }
                initialValues={pricingDetails ?? {
                    accountId: shopId
                }}
                validate={validate}
                render={({
                    handleSubmit,
                    submitError,
                    dirtySinceLastSubmit,
                    submitting,
                    invalid,
                    pristine,
                    values,
                    form,
                }) => (
                    <Form onSubmit={handleSubmit} error>
                        <Form.Group widths={'equal'}>
                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>
                                    {"Profit Type"}
                                </label>
                                <Field
                                    loading={loading}
                                    component={SelectInput}
                                    options={ProfitTypes.map(v => ({
                                        'key': v.key,
                                        'value': v.key,
                                        'text': v.text
                                    }))}
                                    name="profitType"
                                    placeholder={"Select..."}
                                />

                            </Form.Field>
                        </Form.Group>

                        <Form.Field style={{ textAlign: "left" }}>
                            <label style={{ fontSize: "13px" }}>
                                {"Price Strategy"}
                            </label>
                            <Field
                                loading={loading}
                                component={SelectInput}
                                options={PriceStreategies.map(v => ({
                                    'key': v.key,
                                    'value': v.key,
                                    'text': v.text
                                }))}
                                name="priceStrategy"
                                placeholder={"Select..."}
                            />

                        </Form.Field>

                        <Form.Field style={{ textAlign: "left" }}>
                            <label style={{ fontSize: "13px" }}>
                                {"Max Discount [Applies for Cheapest Price Strategy]"}
                            </label>
                            <Field
                                component={NumericInput}
                                name="maxDiscount"
                                placeholder={"e.g. 0.15"}
                            />
                        </Form.Field>

                        <Form.Field style={{ textAlign: "left" }}>
                            <label style={{ fontSize: "13px" }}>
                                {"Commission on Offers"}
                            </label>
                            <Field
                                component={NumericInput}
                                name="offers_rate"
                                placeholder={"e.g. 0.05"}
                            />
                        </Form.Field>

                        <Form.Field style={{ textAlign: "left" }}>
                            <label style={{ fontSize: "13px" }}>
                                {"Commission"}
                            </label>
                            <Field
                                component={NumericInput}
                                name="rate"
                                placeholder={"e.g. 0.05"}
                            />
                        </Form.Field>

                        {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage error={submitError} />
                        )}
                        <div style={{ textAlign: "center" }}>
                            <Button.Group style={{ paddingTop: "15px" }}>
                                <Button
                                    color="blue"
                                    disabled={(invalid && !dirtySinceLastSubmit)}
                                    loading={submitting}
                                    content={"Save"}
                                />
                                <Button.Or />
                                <Button
                                    onClick={(event) => {
                                        event.preventDefault();
                                        form.reset(pricingDetails ?? {
                                            accountId: shopId
                                        });
                                    }}
                                    content={"Cancel"}
                                />
                            </Button.Group>
                        </div>
                    </Form>
                )} />
        </div>
    );
}


export default observer(PricingModuleWidget)