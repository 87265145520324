import { formatRelative } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Header, Icon, Table, Image, Popup, Label } from "semantic-ui-react";
import { IInventoryProductForList } from "../../../interfaces/product";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { ButtonTypes } from "../../confirmationModal/functions/ButtonTypes";
import { currencyFormat } from "../../products/functions/productHelper";
import { ISuperMarket } from "../../../interfaces/supermarket";
import { getImageUrlPlain } from "../../../helpers/productImage";

interface IProps {
  market: ISuperMarket;
  product: IInventoryProductForList;
}

const InventoryProductTableRow: React.FC<IProps> = ({ product, market }) => {
  const context = useContext(RootStoreContext);
  const { discontinueProduct,
    setEditingId,
    locations,
    toggleDelivery,
    editingId,
    updatingDelivery } = context.inventoryStore;
  const { isDeliveryEnabled, isBranchesEnabled } = context.accountPageStore;
  const { isAdmin } = context.userStore;
  const { openConfirmationModal, closeConfirmationalModal } = context.confirmationModalStore;

  return (
    <Table.Row active={editingId === product.id} onClick={() => {
      if (editingId !== undefined)
        setEditingId(product.id);
    }}>
      <Table.Cell>
        <Header as="h4" image>
          <Popup
            trigger={
              <Image
                src={getImageUrlPlain(market.type_id, product?.imageUrl)}
                disabled={product.discontinued}
                alt={product.imageUrl !== undefined ? product.itemDesc : "Image not found"}
                size="mini"
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                }}
              />
            }
            content={
              <Image
                src={getImageUrlPlain(market.type_id, product?.imageUrl)}
                alt={product.imageUrl !== undefined ? product.itemDesc : "Image not found"}
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                }}
              />
            }
          />

          <Header.Content className={product?.discontinued ? "disabled-text" : ""}>
            {product.itemDesc}
            {product.sku && <Header.Subheader className="fluid">
              {product.sku} - {product.offerItem === true && <Label color="green">Offer</Label>}
            </Header.Subheader>}
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell>
        <div
          style={{
            marginBottom: "0.5em",
            fontSize: "17px",
            fontWeight: "bolder",
          }}
        >
          <Icon name="euro" />
          {currencyFormat(product.currentPrice, false)}
        </div>
      </Table.Cell>
      {isAdmin && <Table.Cell>
        <Label
          icon={product.associationId ? 'external alternate' : undefined}
          onClick={() => {
            if (product.associationId)
              window.open(`${window.location.origin}/compare?id=${product.associationId}`, "_blank")
          }}
          content={`${(product.associationId ?? "") === "" ? "No" : "Yes"}`}
          color={(product.associationId ?? "") === "" ? "red" : "green"} />
      </Table.Cell>}
      {isBranchesEnabled && <Table.Cell>{(product.locations?.length ?? 0) > 0 ? locations.filter(f => product.locations?.includes(f.id)).map(x => x.title).join(',') : '-'}</Table.Cell>}
      <Table.Cell>
        {formatRelative(new Date(product.lastUpdated * 1000), new Date())}
      </Table.Cell>
      <Table.Cell singleLine>
        {isDeliveryEnabled && isAdmin && <Icon
          inverted={updatingDelivery !== product.id}
          name={updatingDelivery === product.id ? "circle notch" : "truck"}
          loading={updatingDelivery === product.id}
          disabled={!product.associationId || (product.locations?.length ?? 0) === 0 || updatingDelivery !== ''}
          title={(!product.associationId || (product.locations?.length ?? 0) === 0) ? "The product needs to be grouped & have an outlet before being available for delivery - Contact admin for more details" : (product.delivery ? "Disable delivery" : "Enable delivery")}
          color={product.delivery ? "green" : "grey"}
          circular
          onClick={() => {
            toggleDelivery(product.id);
          }}
        />}
        <Icon
          inverted
          name="pencil"
          color="blue"
          circular
          onClick={() => {
            setEditingId(product.id);
          }}
        />
        {!product.discontinued && (
          <Icon
            inverted
            name="trash alternate outline"
            color="red"
            circular
            onClick={() => {
              openConfirmationModal(
                "Confirm product removal",
                <div>
                  Are you sure you want to discontinue this product?
                  <p>
                    Your product will not be deleted, you will still be able to
                    access it and update it. Anyone else on Cheap Trolley will
                    not be able to see your product.
                  </p>
                </div>,
                () =>
                  discontinueProduct(product.id).then(() =>
                    closeConfirmationalModal()
                  ),
                "Cancel",
                "Remove",
                ButtonTypes.None,
                ButtonTypes.Negative
              );
              // removeError(x.internalId)
            }}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default observer(InventoryProductTableRow);
