
export type CourrierStatusType = 'data_received' |
    'dispatched' |
    'picked_up' |
    'arrived_malta' |
    'left_malta' |
    'arrived_gozo' |
    'client_unavailable' |
    'awaiting_collection' |
    'out_for_delivery' |
    'custom_Forwarded for Delivery in Gozo' |
    'delivered';


export const CourrierStatusTypes: Array<{
    value: CourrierStatusType,
    key: CourrierStatusType,
    text: string,
}> =
    [
        {
            value: 'data_received',
            key: 'data_received',
            text: 'Order data received'
        },
        {
            value: 'dispatched',
            key: 'dispatched',
            text: 'Dispatched to courier'
        },
        {
            value: 'picked_up',
            key: 'picked_up',
            text: 'Order picked up'
        },
        {
            value: 'arrived_malta',
            key: 'arrived_malta',
            text: 'Arrived at Operations Facility - Malta'
        },
        {
            value: 'custom_Forwarded for Delivery in Gozo',
            key: 'custom_Forwarded for Delivery in Gozo',
            text: "Forwarded for Delivery in Gozo"
        },
        {
            value: 'left_malta',
            key: 'left_malta',
            text: 'Departed Operations Facility - Malta'
        },
        {
            value: 'arrived_gozo',
            key: 'arrived_gozo',
            text: 'Arrived at Operations Facility - Gozo'
        },
        {
            value: 'client_unavailable',
            key: 'client_unavailable',
            text: 'Attempted Delivery / Client not home'
        },
        {
            value: 'awaiting_collection',
            key: 'awaiting_collection',
            text: 'Awaiting collection by recipient as requested'
        },
        {
            value: 'out_for_delivery',
            key: 'out_for_delivery',
            text: 'Out for Delivery'
        },
        {
            value: 'delivered',
            key: 'delivered',
            text: 'Delivered'
        },
    ]

