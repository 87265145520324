import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";

export class BrandSelectorStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable isLoading: boolean = false;
    @observable brands: any[] = [];
    @observable currentPage: number = 0;
    @observable hasMore: boolean = true;
    @observable searchText?: string = undefined;

    @action setLoading = (value: boolean) => this.isLoading = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetBrands = (value: any[]) => this.brands = value;
    @action setBrands = (value: any[]) => {
        var existing = this.brands.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.brands.push(...missing);
    };

    @action load = async (defaultValue?: string[], force = false) => {
        try {
            if (this.hasMore === false && this.currentPage !== 0 && !force) return;

            if (force) {
                this.currentPage = 0
            }

            if (force)
                this.setLoading(true);

            var response = await agent.Brands.get_dropdown(this.searchText, defaultValue, this.currentPage);
            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setBrands(response.Items);
        } catch (error) {
        }
        finally {
            if (force)
                this.setLoading(false);
        }

    };

    @action search = async (text?: string, ids?: string[]) => {
        try {
            this.searchText = text;

            this.resetBrands([]);
            this.setCurrentPage(0)

            this.load(ids);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

}
