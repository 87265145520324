import { observer } from "mobx-react";
import React, { useContext } from "react";
import { combineValidators, isRequired } from "revalidate";
import { Button, Grid, Header } from "semantic-ui-react";
import { FORM_ERROR } from "final-form";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import ProfileFieldUpdate from "../../profile/Components/ProfileFieldUpdate";
import { IBillingDetails } from "../../../interfaces/billing";

const BillingDetailsContactInfoWidget = () => {
  const context = useContext(RootStoreContext);
  const { isAdmin, isShopAdmin } = context.userStore;
  const {
    billingDetails,
    currentlyEditing,
    setCurrentlyEditing,
    loading,
    updateBillingEmail,
    updateBillingPhone,
  } = context.billingDetailsStore;

  return (
    <Grid textAlign="center" className="small-top-padding">
      <Grid.Row columns={1}>
        <Grid.Column width={16}>
          <Grid divided="vertically" textAlign="center">
            <Grid.Row columns={1} centered style={{ paddingBottom: "0px" }}>
              <Grid.Column textAlign="center" style={{ marginBottom: "0px" }}>
                <Header className="blueColor" as="h2" textAlign="left">
                  {"Contact"}
                </Header>
              </Grid.Column>
            </Grid.Row>
            {/*Email */}
            <ProfileFieldUpdate
              allowEdit={isAdmin || isShopAdmin}
              fieldName="email"
              loading={loading}
              groupFieldLabel={"E-mail"}
              editMode={currentlyEditing === "email"}
              editClicked={(value: string) => {
                setCurrentlyEditing(value);
              }}
              cancelClicked={() => {
                setCurrentlyEditing("");
              }}
              update={(billingDetails: IBillingDetails) =>
                updateBillingEmail(billingDetails.email!).catch(
                  (error: any) => ({
                    [FORM_ERROR]: error,
                  })
                )
              }
              validate={combineValidators({
                email: isRequired("Email"),
              })}
              initialValues={billingDetails ?? undefined}
              displayValue={
                billingDetails && billingDetails.email! ? (
                  <Button
                    as="a"
                    to={`mailto:${billingDetails.email!}`}
                    href={`mailto:${billingDetails.email!}`}
                    target="_blank"
                    className="button-as-link orangeColor"
                  >
                    {billingDetails.email!}
                  </Button>
                ) : (
                  "-"
                )
              }
              fields={[
                {
                  display: "",
                  placeholder: "E-mail",
                  name: "email",
                  showDisplay: false,
                },
              ]}
            />
            {/*Company Phone */}
            <ProfileFieldUpdate
              allowEdit={isAdmin || isShopAdmin}
              fieldName="phone"
              loading={loading}
              groupFieldLabel={"Phone"}
              editMode={currentlyEditing === "phone"}
              editClicked={(value: string) => {
                setCurrentlyEditing(value);
              }}
              cancelClicked={() => {
                setCurrentlyEditing("");
              }}
              update={(billingDetails: IBillingDetails) =>
                updateBillingPhone(billingDetails?.phoneNumber).catch(
                  (error: any) => ({
                    [FORM_ERROR]: error,
                  })
                )
              }
              validate={combineValidators({
                phoneNumber: isRequired(""),
              })}
              initialValues={billingDetails!}
              displayValue={
                billingDetails?.phoneNumber ? (
                  <Button
                    as="a"
                    to={`tel:${billingDetails.phoneNumber}`}
                    href={`tel:${billingDetails.phoneNumber}`}
                    target="_blank"
                    className="button-as-link orangeColor"
                  >
                    {billingDetails.phoneNumber}
                  </Button>
                ) : (
                  "+ (###) #######"
                )
              }
              fields={[
                {
                  display: "Number",
                  placeholder: "Number",
                  name: "phoneNumber",
                  showDisplay: false,
                },
              ]}
            />
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(BillingDetailsContactInfoWidget);
