import { observer } from "mobx-react-lite";
import useDragger from "../floatingMenu/hooks/useDragger";
import { FloatMenuPosition } from "../floatingMenu/functions/FloatMenuTypes";
import { Icon } from "semantic-ui-react";
import { GetFormattedAddress } from "../../helpers/addressHelper";
import agent from "../../api/agent";
import { useContext } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import React from "react";
import { ActiveOrder } from "../../interfaces/order";
interface IProps {
  activeOrder: ActiveOrder;
  position: FloatMenuPosition | undefined;
  redirectUrl: string | undefined;
}
const ClientsOrdersBubble: React.FC<IProps> = ({
  activeOrder,
  position,
  redirectUrl,
}) => {
  const context = useContext(RootStoreContext);
  const { setEventCustom } = context.analyticsStore;
  const [localRedirectUrl] = React.useState<string>(
    typeof redirectUrl === "undefined" || redirectUrl === ""
      ? "/my-orders"
      : redirectUrl
  );
  const [localPosition] = React.useState<FloatMenuPosition | undefined>(
    position ?? {
      xAlign: "right",
      yUnit: "%",
      xPos: 80,
      yPos: 10,
    }
  );

  const colorProperties = {
    bubbleError: "linear-gradient(to bottom , #f35727, #fe0533)",
    bubbleDefault: "linear-gradient(to bottom , #086a3d, #22b374)",
    bubbleFinished: "linear-gradient(to bottom , #10a976, #04c80a)",
    bubblePending: "linear-gradient(to bottom , #086a3d, #22b374)",
    bubbleCancel: "#A9A9A9",
    detailPaneDefault: "linear-gradient(to bottom , #086a3d, #22b374)",
    detailPaneError: "linear-gradient(to bottom , #bb252e, #830751)",
  };

  useDragger(`bubble${activeOrder.id}`, localPosition);

  let formattedStatus = "";
  let statusColor = colorProperties.bubbleDefault;

  let paymentTab = (
    <div
      className="order-line smaller-text"
      onClick={() => {
        agent.Orders.retry(activeOrder.id)
          .then((redirectUrl) => {
            setEventCustom("retry_payment");
            if (redirectUrl) {
              window.location.href = redirectUrl;
            }
          })
          .catch(() => { });
      }}
    >
      <span>
        <Icon name="exclamation circle" />
        Click here to retry the payment!{" "}
      </span>
    </div>
  );

  const processingMessage: string = "Almost there! Your order is processing.";

  let deliveryTab = (
    <span>
      {" "}
      {activeOrder.status === "completed" ? (
        <span style={{ fontSize: "11px", lineHeight: "1px" }}>
          Thank you for choosing us!
        </span>
      ) : activeOrder.status === "pending_processing" ? (
        processingMessage
      ) : (
        `To deliver to ${GetFormattedAddress(activeOrder.address, [
          "city",
          "state",
          "country",
          "postCode"
        ])}`
      )}{" "}
    </span>
  );

  let pickupTab = (
    <span
      onClick={() => {
        if (activeOrder.status === "ready_for_pickup")
          window.open("https://www.google.com/maps/place/Connect+Services+Ltd/@35.8897975,14.4630538,17z/data=!3m1!4b1!4m6!3m5!1s0x130e51473b5e2ae7:0xf64016b0e07db6d4!8m2!3d35.8897932!4d14.4656341!16s%2Fg%2F11pwzzf7b4?entry=ttu", "_blank");
      }}
    >
      {" "}
      {activeOrder.status === "ready_for_pickup" ? (
        <span>
          Your order is ready for pickup. <u>Get Directions</u>
        </span>
      ) : (
        processingMessage
      )}{" "}
    </span>
  );

  switch (activeOrder.status) {
    case "pending_payment":
      formattedStatus = "Pending payment";
      statusColor = colorProperties.bubbleError;
      break;
    case "pending_processing":
      formattedStatus = "Processing";
      statusColor = colorProperties.bubblePending;
      break;
    case "payment_failed":
      formattedStatus = "Payment has failed";
      statusColor = colorProperties.bubbleError;
      break;
    case "sent_to_market":
      formattedStatus = "Preparing Order";
      break;
    case "ready_for_pickup":
      formattedStatus = "Ready for Pickup";
      statusColor = colorProperties.bubbleFinished;
      break;
    case "ready_for_delivery":
      formattedStatus = "Awaiting courier pickup";
      break;
    case "out_for_delivery":
      formattedStatus = "On the way!";
      break;
    case "completed":
      formattedStatus = "Order Arrived!";
      statusColor = colorProperties.bubbleFinished;
      break;
    default:
      formattedStatus = "Order In Progress";
      break;
  }
  // function googleLinkGeoFormat() {
  //   if (!activeOrder.address?.geolocation) {
  //     return;
  //   }
  //   const geoString = encodeURI(
  //     `${activeOrder.address.geolocation.lat},${activeOrder.address.geolocation.lng}`
  //   );
  //   return `https://www.google.com/maps/search/?api=1&query=${geoString}`;
  // }

  function closeBubble() {
    const bubble = document.getElementById(`bubble${activeOrder.id}`);
    if (bubble === null) {
      return;
    }
    bubble.remove();
  }
  return (
    <div id={`bubble${activeOrder.id}`} className="draggable-bubble">
      <div className="bubble-content" style={{ backgroundImage: statusColor }}>
        <svg
          className="bubble-exit"
          onClick={closeBubble}
          xmlns="http://www.w3.org/2000/svg"
          fill={colorProperties.bubbleCancel}
          height="800px"
          width="800px"
          version="1.1"
          id="Layer_1"
          viewBox="0 0 300.003 300.003"
        >
          <g>
            <g>
              <path d="M150,0C67.159,0,0.001,67.159,0.001,150c0,82.838,67.157,150.003,149.997,150.003S300.002,232.838,300.002,150    C300.002,67.159,232.839,0,150,0z M206.584,207.171c-5.989,5.984-15.691,5.984-21.675,0l-34.132-34.132l-35.686,35.686    c-5.986,5.984-15.689,5.984-21.672,0c-5.989-5.991-5.989-15.691,0-21.68l35.683-35.683L95.878,118.14    c-5.984-5.991-5.984-15.691,0-21.678c5.986-5.986,15.691-5.986,21.678,0l33.222,33.222l31.671-31.673    c5.986-5.984,15.694-5.986,21.675,0c5.989,5.991,5.989,15.697,0,21.678l-31.668,31.671l34.13,34.132    C212.57,191.475,212.573,201.183,206.584,207.171z" />
            </g>
          </g>
        </svg>
        {activeOrder.status === "payment_failed" ||
          activeOrder.status === "pending_payment" ? (
          <div className="order-bubble-icon">
            <Icon name="exclamation" />
          </div>
        ) : (
          <div className="order-bubble-icon">
            {activeOrder.checkoutType === "delivery" ? (
              <Icon name="truck" />
            ) : (
              <Icon name="shopping basket" />
            )}
          </div>
        )}
        <div className="order-icon-text">{activeOrder.displayId}</div>
      </div>
      <ul>
        <li
          className="bubble-details-pane"
          style={{
            backgroundImage:
              activeOrder.status === "payment_failed" ||
                activeOrder.status === "pending_payment"
                ? colorProperties.detailPaneError
                : colorProperties.detailPaneDefault,
          }}
          onClick={() => {
            activeOrder.status === "payment_failed" ||
              activeOrder.status === "pending_payment"
              ? agent.Orders.retry(activeOrder.id)
                .then((redirectUrl) => {
                  setEventCustom("retry_payment");
                  window.location.href = redirectUrl;
                })
                .catch(() => { })
              : window.open(localRedirectUrl, "_self");
          }}
        >
          <div className="status-line order-border-bottom">
            <span>{formattedStatus}</span>
          </div>
          {activeOrder.status === "payment_failed" ||
            activeOrder.status === "pending_payment" ? (
            paymentTab
          ) : (
            <div className="order-partial-container">
              <div className="order-line small-text">
                {activeOrder.checkoutType === "delivery"
                  ? deliveryTab
                  : pickupTab}
              </div>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default observer(ClientsOrdersBubble);
