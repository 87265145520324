import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { UserLastestRequests, UserRequest } from "../../../interfaces/userRequests";

class UserRequests {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable searchText?: string = undefined;
    @observable dateFrom?: number = undefined;
    @observable dateTo?: number = undefined;
    @observable downloading: boolean = false;
    @observable hasMore: boolean = false;
    @observable items: UserRequest[] = []
    @observable leaderboard: UserLastestRequests[] = []

    @action setLoading = (value: boolean) => this.loading = value;
    @action setSearchText = (value?: string) => this.searchText = value;
    @action setDateFrom = (value?: number) => this.dateFrom = value;
    @action setDateTo = (value?: number) => this.dateTo = value;
    @action setDownloading = (value: boolean) => this.downloading = value;
    @action resetItems = (value: UserRequest[]) => this.items = value;
    @action setLeaderBoard = (value: UserLastestRequests[]) => this.leaderboard = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setItems = (value: UserRequest[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action search = async (searchText?: string) => {
        try {
            this.resetItems([]);
            this.setCurrentPage(0)
            this.setSearchText(searchText)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.UserRequests.get(this.searchText, this.dateFrom, this.dateTo, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action loadLeaderView = async () => {
        try {
            this.setLoading(true);

            var response = await agent.UserRequests.get_latest();

            this.setLeaderBoard(response);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action dispose = () => {
        this.setCurrentPage(0);
        this.setDateFrom(undefined);
        this.setDateTo(undefined);
        this.setSearchText(undefined);
        this.setHasMore(false);
        this.setDownloading(false);
        this.items = [];
        this.setLeaderBoard([]);
    }
}


export const UserRequestsStore = new UserRequests();