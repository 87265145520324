import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { ILocationPoint } from '../../interfaces/locations'
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { RootStoreContext } from '../../stores/RootStoreContext';

interface IProps {
    point?: ILocationPoint,
    height?: string,
    allowMove?: boolean,
    updateCenter: (lng: number, lat: number) => void
}

const LocationIQ: React.FC<IProps> = ({ point, height, updateCenter, allowMove = true }) => {
    const context = useContext(RootStoreContext)
    const { loading, mapKey, initialize } = context.locationIQStore;
    const [myMap, setMap] = useState();

    useEffect(() => {
        if (!mapKey) {
            initialize();
            return;
        }

        var map = new (window as any).maplibregl.Map({
            container: 'map',
            style: 'https://tiles.locationiq.com/v3/streets/vector.json?key=' + mapKey,
            zoom: 9,
            center: [14.375931, 35.944028],
        });

        map.setLanguage('en');
        map.scrollZoom.disable();
        map.addControl(new (window as any).maplibregl.NavigationControl());

        map.on('dragend', function () {
            const center: any = (myMap ?? map).getCenter();
            updateCenter(center.lng, center.lat);
        });
        map.on('zoom', function () {
            const center: any = (myMap ?? map).getCenter();
            // if (point && (point.lat !== center.lat || point.lng !== center.lng)) {
            // console.log(center);
            updateCenter(center.lng, center.lat);
            // }
        });

        setMap(map);
        if (point) {
            if (!allowMove) {
                var el2 = document.createElement('div');
                el2.className = 'hub-marker';
                el2.style.backgroundImage = 'url(https://tiles.locationiq.com/static/images/marker50px.png)';
                el2.style.width = '30px';
                el2.style.backgroundSize = 'contain';
                el2.style.backgroundRepeat = 'no-repeat';
                el2.style.marginBottom = '15px';
                el2.style.paddingBottom = '15px';
                el2.style.height = '30px';

                // add marker to map
                new (window as any).maplibregl.Marker(el2)
                    .setLngLat([point.lng, point.lat])
                    .addTo(map);
            }
            (map as any)?.jumpTo({ center: [point.lng, point.lat], zoom: 18 })
        }
        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapKey])

    useEffect(() => {
        if (!point) return;
        // const marker = points[0];
        // // points?.forEach((marker) => {
        // var el = document.createElement('div');
        // el.className = 'locationIQ-marker';
        // el.style.backgroundImage = 'url("maps/select-point.png")';

        // el.addEventListener('click', function () {
        //     window.alert(marker.display_name);
        // });

        // new (window as any).maplibregl.Marker(el)
        //     .setLngLat([marker.lon, marker.lat])
        //     .addTo(myMap);

        // var verPoint = document.createElement('div');
        // verPoint.className = 'marker';

        // new (window as any).maplibregl.Marker(verPoint)
        //     .setLngLat([marker.lon, marker.lat])
        //     .addTo(myMap);
        // });
        updateCenter(point.lng, point.lat);
        (myMap as any)?.jumpTo({ center: [point.lng, point.lat], zoom: 18 })

        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [point])


    return (
        <div style={{ position: 'relative' }}>
            {loading &&
                <Segment>
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>

                    {/* <Image src='/images/wireframe/short-paragraph.png' /> */}
                </Segment>}
            {point && allowMove && <img alt="selected" className='locationIQ-marker'
                style={{
                    position: 'absolute',
                    bottom: '50%',
                    right: '50%',
                    zIndex: 1
                }}
                src='/maps/select-point.png'></img>}
            <div style={{
                height: height ?? '300px',
                width: '100%'
            }} id="map"></div>
        </div>
    )
}


export default observer(LocationIQ);