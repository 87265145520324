import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import {
  Button,
  Divider,
  Icon,
  Image,
  List,
  Popup,
  Transition,
} from "semantic-ui-react";
import { getImageUrlPlain } from "../../../helpers/productImage";
import { getMarketImageUrl } from "../../../helpers/supermarketImage";
import { IOfferForList } from "../../../interfaces/offer";
import { IProductForComparePrice } from "../../../interfaces/product";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { currencyFormat } from "../../products/functions/productHelper";

interface IProps {
  offer: IOfferForList;
}

const CatalogueOfferCard: React.FC<IProps> = ({ offer }) => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const {
    addToCart,
    removeFromCart,
    getProductQuantity,
    syncCart,
    basketItems
  } = context.shoppingCartStore;
  const [hide] = React.useState(500);
  const [show] = React.useState(500);
  const [timer, setTimer] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);

  const [cpPrice] = React.useState(offer.catalogue?.price ?? 0);

  const getComparisonOptions = () => {
    var options: any[] = [];

    if (!offer || !offer.products || offer.products.length === 0)
      return options;

    var allitems = [
      ...offer.products,
      {
        marketId: offer.marketId,
        currentPrice: offer.currentPrice,
        byWeight: offer.byWeight,
        step: offer.step,
        productId: offer.productId,
      },
    ];

    if (offer.catalogue && cpPrice > 0) {
      allitems.push({
        marketId: "CheapTrolley",
        currentPrice: cpPrice,
        byWeight: offer.byWeight,
        step: offer.step,
        productId: "",
      })
    }

    allitems.forEach((x: IProductForComparePrice) => {
      var market = markets.filter((f) => f.type_id === x.marketId)[0];

      options.push({
        key: market?.type_id ?? "",
        value: market?.type_id ?? "",
        marketId: market?.type_id ?? "",
        currentPrice: x.currentPrice,
        text: currencyFormat(x.currentPrice, true),
        image: getMarketImageUrl(market),
        tooltip: market?.title ?? "Issue getting the market",
        byWeight: x.byWeight,
        step: x.step,
        productId: x.productId,
      });
    });

    return options
      .slice()
      .sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1));
  };


  const getCheapestPrice = () => {
    var allitems = [
      ...(offer.products ?? []),
      {
        marketId: offer.marketId,
        currentPrice: offer.currentPrice,
        byWeight: offer.byWeight,
        step: offer.step,
        productId: offer.productId,
      },
    ];

    if (allitems.length === 0) {
      return cpPrice;
    }

    var cheapest = allitems.sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1))[0];

    if (cpPrice === 0 || cpPrice > cheapest.currentPrice)
      return cheapest.currentPrice;

    else
      return cpPrice;
  }


  const syncCartHandle = () => {
    if (offer.catalogue)
      syncCart(offer.catalogue.id).then(() => clearTimeout(timer))
  }

  const handleChangeValue = (type: 'add' | 'remove') => {
    if (!offer.catalogue) return;

    if (type === "add")
      addToCart(offer.catalogue);
    else
      removeFromCart(offer.catalogue.id);

    if (timer != null) {
      clearTimeout(timer);
      setTimer(0);
    }

    setTimer(
      setTimeout(syncCartHandle, 1000, null)
    );
  }

  return (
    <div className="ui card" style={{ padding: "7px", maxHeight: "400px" }}>
      <Transition
        animation="scale"
        duration={{ hide, show }}
        visible={!expanded}
      >
        <div style={expanded ? { position: "absolute" } : undefined}>
          <Image
            src={
              getImageUrlPlain(offer.marketId, offer?.imageUrl) ??
              `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
            }
            onError={({ currentTarget }: any) => {
              currentTarget.onerror = null;
              currentTarget.src =
                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
            }}
            alt={offer.itemDesc}
            className="grouped-product-image"
          />

          <div
            title={offer.itemDesc}
            style={{ height: "47px" }}
            className="grouped-product-title fxStretch "
          >
            {offer.itemDesc}
          </div>

          <div
            className="fxDisplay fxAlignCenter fxJustifyEven "
            style={{
              marginTop: "7px",
              height: "50px",
            }}
          >
            <div
              style={{
                fontSize: "19px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <Icon name="euro" />
              {currencyFormat(offer.currentPrice, false)}

              <div
                style={{
                  color: "green",
                  textAlign: "center",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                -{currencyFormat(offer.drop)}
              </div>

              {(offer.products?.length ?? 0) > 0 && (
                <div
                  className="group-compare-new"
                  onClick={() => setExpanded(!expanded)}
                >
                  <Icon name="exchange" />
                  Compare
                </div>
              )}
              {/* {offer.catalogue && cpPrice > getCheapestPrice() && <div style={{ fontSize: "14px", fontWeight: "400" }}>Available at {currencyFormat(cpPrice, true)}</div>} */}
              {offer.catalogue && cpPrice > getCheapestPrice() && <div style={{ fontSize: "14px", fontWeight: "400" }}>Available at {currencyFormat(cpPrice, true)}</div>}
              {offer.catalogue && cpPrice <= getCheapestPrice() && <div style={{ fontSize: "14px", fontWeight: "400" }} className="ct-green-text">Available at lowest price!</div>}
            </div>

            {offer && (
              <div>
                <Image
                  centered
                  alt={
                    markets.filter((f) => f.type_id === offer.marketId)[0].title
                  }
                  title={
                    markets.filter((f) => f.type_id === offer.marketId)[0].title
                  }
                  src={getMarketImageUrl(
                    markets.filter((f) => f.type_id === offer.marketId)[0]
                  )}
                  size="tiny"
                  style={{ maxHeight: "45px", objectFit: "contain" }}
                />
              </div>
            )}
          </div>

          <Divider hidden={!offer.catalogue}
            style={
              (offer.catalogue ? getProductQuantity(offer.catalogue?.id) : 0) > 0
                ? { marginBottom: "4px" }
                : undefined
            }
          />

          {offer.catalogue ? <div>
            {getProductQuantity(offer.catalogue.id) > 0 ? (
              <div
                className={`fxDisplay fxFixed fxJustifyBetween`}
                style={{ paddingLeft: "8px", paddingRight: "8px" }}
              >
                <div className={`item-animate minus`}>
                  <Popup
                    trigger={
                      <Button
                        size="tiny"
                        circular
                        className="ct-green-button-active"
                        icon={"minus"}
                        onClick={() => {
                          handleChangeValue("remove");
                        }}
                      />
                    }
                    content={"Remove from cart"}
                    on="hover"
                  />
                </div>

                <div
                  className={`item-animate number `}
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    marginTop: "5px",
                  }}
                >
                  {getProductQuantity(offer.catalogue.id)}
                </div>

                <div className={`item-animate-fast plus`}>
                  <Popup
                    trigger={
                      <Button
                        size="tiny"
                        circular
                        className="ct-green-button-active"
                        icon={"plus"}
                        onClick={() => {
                          handleChangeValue("add");
                        }}
                      />
                    }
                    content={"Add more"}
                    on="hover"
                  />
                </div>

              </div>
            ) :
              <div
                className={`fxDisplay fxFixed fxJustifyEnd item-fade-out`}
                style={{ paddingRight: "12px", marginTop: "-5px" }}
              >
                <Icon
                  className="linkable ct-green-text"
                  size="large"
                  name={"plus cart"}
                  onClick={() => {
                    handleChangeValue("add");
                  }}
                ></Icon>
              </div>
            }
          </div> :
            <div className={`request-for-inventory fxDisplay fxFixed fxJustifyCenter item-fade-out`}
              style={{ paddingRight: "12px", marginTop: "-7px" }}></div>}
        </div>
      </Transition>
      <Transition
        animation="scale"
        duration={{ hide, show }}
        visible={expanded}
      >
        <div style={!expanded ? { position: "absolute" } : undefined}>
          <div style={{ textAlign: "center" }}>
            <Button
              icon="exchange"
              content="Turn back"
              className="button-as-link"
              onClick={() => setExpanded(!expanded)}
              style={{ color: "#21973f" }}
              cont="true"
            />
            <Divider />
            {offer.itemDesc}
          </div>
          <div
            style={{
              overflow: "hidden",
              overflowY: "auto",
              maxHeight: "270px",
            }}
          >
            <Divider />
            <List divided verticalAlign="middle">
              {getComparisonOptions().map((x, i) => (
                <List.Item key={i}>
                  {markets.filter(f => f.type_id === x.marketId).length > 0 ? <Fragment>
                    <List.Content floated="right" style={{ paddingTop: "6px" }}>
                      {currencyFormat(x.currentPrice, true)}
                    </List.Content>
                    <Image
                      src={getMarketImageUrl(
                        markets.filter((f) => f.type_id === x.marketId)[0]
                      )}
                      size="tiny"
                      className={`inline-superMarketImage`}
                      alt={
                        markets.filter((f) => f.type_id === x.marketId)[0].title
                      }
                      title={
                        markets.filter((f) => f.type_id === x.marketId)[0].title
                      }
                    />
                  </Fragment> :
                    <Fragment>
                      <List.Content floated="right" style={{ paddingTop: "6px" }}>
                        <div className="fxDisplay fxFixed fxJustifyEnd">
                          {offer.catalogue && <div
                            className="fxDisplay fxFixed fxJustifyBetween">
                            <div>
                              <Popup
                                trigger={
                                  <Button
                                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                                    className='borderless'
                                    size="mini"
                                    inverted
                                    color="blue"
                                    icon={"plus"}
                                    onClick={() => {
                                      handleChangeValue("add");
                                    }}
                                  />
                                }
                                content={"Add more"}
                                on="hover"
                              />
                            </div>

                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "400",
                              }}
                            >
                              {basketItems.filter(f => f.productId === offer.catalogue?.id)[0]?.quantity ?? 0}
                            </div>

                            <div>
                              <Popup
                                trigger={
                                  <Button
                                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                                    size="mini"
                                    className='borderless'
                                    inverted
                                    disabled={(basketItems.filter(f => f.productId === offer.catalogue?.id)[0]?.quantity ?? 0) === 0}
                                    color="blue"
                                    icon={"minus"}
                                    onClick={() => {
                                      handleChangeValue("remove");
                                    }}
                                  />
                                }
                                content={"Remove from cart"}
                                on="hover"
                              />
                            </div>
                          </div>}
                          {currencyFormat(x.currentPrice, true)}
                        </div>
                      </List.Content>
                      <Image
                        src={"/early.svg"}
                        size="tiny"
                        className={`inline-superMarketImage`}
                        alt={
                          "Cheap Trolley Logo"
                        }
                        title={
                          "Cheap Trolley"
                        }
                      />
                    </Fragment>}
                </List.Item>
              ))}
            </List>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default observer(CatalogueOfferCard);
