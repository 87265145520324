import { action, computed, makeObservable, observable } from "mobx";
import { SemanticWIDTHS } from "semantic-ui-react";
import agent from "../../../api/agent";
import { IOfferForList } from "../../../interfaces/offer";
import { IProductForComparePrice } from "../../../interfaces/product";
import { RootStore } from "../../../stores/RootStoreContext";

export class OffersStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable offers: IOfferForList[] = []
    @observable searchText: string = "";
    @observable selectedIds: string[] = [];
    @observable toaster?: React.ReactText = undefined;
    @observable timer?: any = undefined;
    @observable orderByDrop: boolean = false;

    @action setOrderByDrop = (value: boolean) => this.orderByDrop = value;
    @action setLoading = (value: boolean) => this.loading = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setToaster = (value?: React.ReactText) => this.toaster = value;
    @action resetOffers = (value: IOfferForList[]) => this.offers = value;
    @action setOffers = (value: IOfferForList[]) => {
        var existing = this.offers.map(f => f.productId)
        var missing = value.filter(f => existing.indexOf(f.productId) < 0);

        this.offers.push(...missing);
    };

    @action oneTimeLoad = async () => {
        try {
            if (this.rootStore.cacheStore.markets.length !== 0) return;
            var markets = await agent.Supermarkets.get();
            this.rootStore.cacheStore.setMarkets(markets);
        } catch (error) {
            // toast.error("Oops, it looks like we are facing an issue. ")
        }
    }

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Offers.get(this.searchText, this.rootStore.commonStore.selectedMarketIds, this.orderByDrop, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setOffers(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action getSelectedItem = (products: IProductForComparePrice[]) => {
        var cart = this.rootStore.shoppingListStore.shopingCart;

        if (!cart) return undefined;

        var selected = cart?.items.filter(
            (x) =>
                products.findIndex((z) => z.productId === x.productId) > -1
        );

        if (selected.length === 0) return undefined;
        if (selected.length === 1) return products.filter(f => f.productId === selected[0].productId)[0];

        return undefined;
    }

    @action changeOrder = async () => {
        try {
            this.setOrderByDrop(!this.orderByDrop);

            this.resetOffers([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetOffers([]);
            this.setCurrentPage(0)

            this.load();

            if (text !== "" && text !== undefined)
                this.rootStore.analyticsStore.setSearchEvent("Offers", text);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @computed get getItemsPerRow(): SemanticWIDTHS {
        const { isMobile, isPortrait, isTablet } = this.rootStore.deviceStore;

        var retVal: SemanticWIDTHS = 2;
        if ((isMobile || isTablet) && isPortrait)
            retVal = 1;
        if (isTablet && !isPortrait)
            retVal = 2;
        if (isMobile && !isPortrait)
            retVal = 2;

        return retVal;
    }

    @action dispose = () => {
        this.searchText = ""
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetOffers([]);
        this.setHasMore(false);
    }
}