import { action, makeObservable } from "mobx";
import { RootStore } from "./RootStoreContext";

export class AnalyticsStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @action intialize = () => {
        if (this.rootStore.userStore.isAdmin) return;

        if (process.env.NODE_ENV !== 'development') {
            const script = document.createElement("script");
            script.innerHTML = 'function loadScript(a) { var b = document.getElementsByTagName("head")[0], c = document.createElement("script"); c.type = "text/javascript", c.src = "https://tracker.metricool.com/resources/be.js", c.onreadystatechange = a, c.onload = a, b.appendChild(c) } loadScript(function () { beTracker.t({ hash: "1112b8cf12c4bb81ffb027e5f34ecad6" }) });';
            script.async = true;
            script.id = "metricoolAnalytics";
            document.body.appendChild(script);
        }

        if (!document.getElementById("googleAnalyticsTracker") && process.env.REACT_APP_GOOGLEANALYTICS_SITEID) {
            try {
                const ga_script = document.createElement("script");
                ga_script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLEANALYTICS_SITEID}`;
                ga_script.async = true;
                ga_script.id = "googleAnalyticsTracker";
                document.head.appendChild(ga_script);

                const ga_runscript = document.createElement("script");
                ga_runscript.innerHTML = `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.REACT_APP_GOOGLEANALYTICS_SITEID}', {send_page_view: false});`;
                document.head.appendChild(ga_runscript);
            } catch (error) {
                console.log("Google Analytics failed to initialize.", error);
            }
        }
    }

    @action setPage = (name: string, url: string) => {
        if (this.rootStore.userStore.isAdmin) return;
        //@ts-ignore
        if (process.env.REACT_APP_GOOGLEANALYTICS_SITEID && window.gtag)
            try {
                //@ts-ignore
                gtag("event", "page_view", {
                    page_title: name,
                    page_location: url,
                });
            } catch (error) {
                console.log(error);
            }
    }

    @action setSearchEvent = (screen_name: string, value: string) => {
        if (this.rootStore.userStore.isAdmin) return;
        //@ts-ignore
        if (process.env.REACT_APP_GOOGLEANALYTICS_SITEID && window.gtag)
            try {
                //@ts-ignore
                gtag("event", "search", {
                    'screen_name': screen_name,
                    'search_term': value
                });
            } catch (error) {
                console.log(error);
            }
    }

    @action setCategoryClickEvent = (value: string) => {
        if (this.rootStore.userStore.isAdmin) return;
        //@ts-ignore
        if (process.env.REACT_APP_GOOGLEANALYTICS_SITEID && window.gtag)
            try {
                //@ts-ignore
                gtag("event", "category_filter", {
                    // 'screen_name': screen_name,
                    'value': value
                });
            } catch (error) {
                console.log(error);
            }
    }

    @action setEventCustom = (name: string) => {
        if (this.rootStore.userStore.isAdmin) return;
        //@ts-ignore
        if (process.env.REACT_APP_GOOGLEANALYTICS_SITEID && window.gtag)
            try {
                //@ts-ignore
                gtag("event", name, {
                    'event_category': name,
                    'event_label': name,
                });
            } catch (error) {
                console.log(error);
            }
    }


    @action dispose = () => {
    }
}