import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useContext, useEffect } from 'react'
import { Divider, Icon, Input, Menu, Table } from 'semantic-ui-react';
import { RootStoreContext } from '../../stores/RootStoreContext';
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from 'react-router';

const ProductAnalysis = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const { hasMore, loading, items, load, dispose, search } = context.productAnalysisStore;

    useEffect(() => {
        window.scrollTo(0, 0);
        load();
        return () => {
            dispose();
        };
    }, [dispose, load]);


    const [timer, setTimer] = React.useState(0);

    return (
        <div className="simply-paddedPage">
            <div>
                <Menu
                    secondary
                    size={isMobile ? "massive" : "small"}
                    style={{ margin: "0 0 5px 0" }}
                >
                    <Menu.Item
                        className={!isMobile ? "no-padding" : ""}
                        style={{ flex: isMobile ? 1 : "" }}
                    >
                        <Input
                            fluid={isMobile}
                            transparent={isMobile}
                            size={isMobile ? "large" : "small"}
                            icon={!isMobile ? <Icon name="search" className="ct-green-text" /> : undefined}
                            placeholder={"Search..."}
                            input={<input className={isMobile ? "" : "ctGreenPlaceholder"} />}
                            onChange={(event: ChangeEvent, data: any) => {
                                if (!search) return;
                                if (timer != null) {
                                    clearTimeout(timer);
                                    setTimer(0);
                                }

                                setTimer(
                                    setTimeout(
                                        (callback: (text: string) => void, text: string) => {
                                            callback(text);
                                            clearTimeout(timer);
                                        },
                                        500,
                                        search,
                                        data.value
                                    )
                                );
                            }}
                        />
                    </Menu.Item>
                </Menu>
                <Divider />
                <InfiniteScroll
                    style={{ overflow: "hidden" }}
                    dataLength={items.length} //This is important field to render the next data
                    next={() => load()}
                    hasMore={hasMore}
                    loader={loading ? <h4>Loading...</h4> : <div />}
                >

                    <Table basic="very" celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Product</Table.HeaderCell>
                                <Table.HeaderCell>Images</Table.HeaderCell>
                                <Table.HeaderCell>Analyzed</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {items.map((x) => (
                                <Table.Row key={x.id} onDoubleClick={() => { navigate(`${x.id}`) }}>
                                    <Table.Cell>
                                        {x.title}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {x.ai_images?.length ?? 0}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon
                                            size='large'
                                            name={x.isAnalyzed === true ? "check" : "remove"}
                                            color={x.isAnalyzed === true ? "green" : "red"}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon name='pencil' color='blue' onClick={() => { navigate(`${x.id}`) }} />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default observer(ProductAnalysis)