export const ShopUserRoleOptions: Array<{
    value: number,
    key: number,
    text: string,
    description?: string,
    title?: string,
    icon?: any,
    label?: any
}> =
    [
        {
            "value": 1,
            "key": 1,
            "text": "Admin",
            "description": "Full access."
        },
        {
            "value": 2,
            "key": 2,
            'icon': 'comment',
            "text": "Tablet User",
            "description": "Inventory and orders",
        },
        {
            "value": 3,
            "key": 3,
            'icon': 'comment',
            "text": "Commercial User",
            "description": "Price Tracker & Global Inventory",
        }
    ]

