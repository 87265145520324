import { IDietType, IFoodCategory, IFoodType } from "../interfaces/dietprofile";



export const FoodCategories: IFoodCategory[] = [
    {
        title: "Common Exclusions",
        type: "common"
    },
    {
        type: "dairy",
        title: "Dairy",
    },
    {
        type: "eggs",
        title: "Eggs",
    },
    {
        type: "grains",
        title: "Grains",
    },
    {
        type: "soy",
        title: "Soy",
    },
    {
        type: "red_meat",
        title: "Red Meat",
    },
    {
        type: "poultry",
        title: "Poultry",
    },
    {
        type: "fish",
        title: "Fish",
    },
    {
        type: "shellfish",
        title: "Shellfish",
    },
    {
        type: "mayo",
        title: "Mayo",
    },
    {
        type: "fats_nuts",
        title: "Fats & Nuts",
    },
    {
        type: "legumes",
        title: "Legumes",
    },
    {
        type: "fruit",
        title: "Fruit",
    },
    {
        type: "vegetables",
        title: "Vegetables",
    },
    {
        type: "starchy_vegetables",
        title: "Starchy Vegetables",
    },
    {
        type: "honey",
        title: "Honey",
    }
]

export const FoodTypes: IFoodType[] = [
    {
        type: "gluten",
        title: "Gluten",
        categories: ["common"]
    },
    {
        type: "peanuts",
        title: "Peanuts",
        categories: ["common"]
    },
    {
        type: "all_eggs",
        title: "Eggs",
        categories: ["eggs", "common"],
        related_type: "eggs",
    },
    {
        type: "all_fish",
        title: "Fish",
        categories: ["fish", "common"],
        related_type: "fish",
        is_all: true
    },
    {
        type: "all_fats_nuts",
        title: "Fats & Nuts",
        categories: ["fats_nuts", "common"],
        related_type: "fats_nuts",
        is_all: true
    },
    {
        type: "all_shellfish",
        title: "Shellfish",
        categories: ["shellfish", "common"],
        related_type: "shellfish"
    },
    {
        type: "all_dairy",
        title: "Dairy",
        categories: ["dairy", "common"],
        related_type: "dairy",
        is_all: true,
    },
    {
        type: "milk",
        title: "Milk",
        categories: ["dairy"]
    },
    {
        type: "cream",
        title: "Cream",
        categories: ["dairy"]
    },
    {
        type: "cheese",
        title: "Cheese",
        categories: ["dairy"]
    },
    {
        type: "yogurt",
        title: "Yogurt",
        categories: ["dairy"]
    },
    {
        type: "cottage_cheese",
        title: "Cottage Cheese",
        categories: ["dairy"]
    },
    {
        type: "whey_powder",
        title: "Whey Powder",
        categories: ["dairy"]
    },
    {
        type: "all_grains",
        title: "All",
        categories: ["grains"],
        is_all: true
    },
    {
        type: "breakfast_cereals",
        title: "Breakfast Cereals",
        categories: ["grains"]
    },
    {
        type: "pasta",
        title: "Pastas",
        categories: ["grains"]
    },
    {
        type: "breads",
        title: "Breads",
        categories: ["grains"]
    },
    {
        type: "rice",
        title: "Rice",
        categories: ["grains"]
    },
    {
        type: "oatmeal",
        title: "Oatmeal",
        categories: ["grains"]
    },
    {
        type: "sugar",
        title: "Sugar",
        categories: ["grains"]
    },
    {
        type: "all_soy",
        title: "Soy",
        categories: ["soy", "common"],
        related_type: "soy",
        is_all: true,
    },
    {
        type: "tofu",
        title: "Tofu",
        categories: ["soy"]
    },
    {
        type: "soy_milk",
        title: "Soy Milk",
        categories: ["soy"]
    },
    {
        type: "all_red_meat",
        title: "All",
        categories: ["red_meat"],
        is_all: true
    },
    {
        type: "beef",
        title: "Beef",
        categories: ["red_meat"]
    },
    {
        type: "pork_bacon",
        title: "Pork/Bacon",
        categories: ["red_meat"]
    },
    {
        type: "lamb",
        title: "Lamb",
        categories: ["red_meat"]
    },
    {
        type: "veal",
        title: "Veal",
        categories: ["red_meat"]
    },
    {
        type: "all_poultry",
        title: "All",
        categories: ["poultry"],
        is_all: true
    },
    {
        type: "chicken",
        title: "Chicken",
        categories: ["poultry"]
    },
    {
        type: "turkey",
        title: "Turkey",
        categories: ["poultry"]
    },
    {
        type: "salmon",
        title: "Salmon",
        categories: ["fish"]
    },
    {
        type: "tuna",
        title: "Tuna",
        categories: ["fish"]
    },
    {
        type: "sardines",
        title: "Sardines",
        categories: ["fish"]
    },
    {
        type: "trout_snapper",
        title: "Trout & Snapper",
        categories: ["fish"]
    },
    {
        type: "all_mayo",
        title: "All",
        categories: ["mayo"],
    },
    {
        type: "avocado",
        title: "Avocado",
        categories: ["fats_nuts"]
    },
    {
        type: "almonds",
        title: "Almonds",
        categories: ["fats_nuts"]
    },
    {
        type: "walnuts",
        title: "Walnuts",
        categories: ["fats_nuts"]
    },
    {
        type: "pecans",
        title: "Pecans",
        categories: ["fats_nuts"]
    },
    {
        type: "all_legumes",
        title: "All",
        categories: ["legumes"],
        is_all: true
    },
    {
        type: "beans",
        title: "Beans",
        categories: ["legumes"]
    },
    {
        type: "lentils",
        title: "Lentils",
        categories: ["legumes"]
    },
    {
        type: "peas",
        title: "Peas",
        categories: ["legumes"]
    },
    {
        type: "all_fruit",
        title: "All",
        categories: ["fruit"],
        is_all: true
    },
    {
        type: "apple",
        title: "Apple",
        categories: ["fruit"]
    },
    {
        type: "banana",
        title: "Banana",
        categories: ["fruit"]
    },
    {
        type: "grapes",
        title: "Grapes",
        categories: ["fruit"]
    },
    {
        type: "orange",
        title: "Orange",
        categories: ["fruit"]
    },
    {
        type: "strawberries",
        title: "Strawberries",
        categories: ["fruit"]
    },
    {
        type: "raspberries",
        title: "Raspberries",
        categories: ["fruit"]
    },
    {
        type: "blueberries",
        title: "Blueberries",
        categories: ["fruit"]
    },
    {
        type: "fruit_juice",
        title: "Fruit Juice",
        categories: ["fruit"]
    },
    {
        type: "all_vegetables",
        title: "All",
        categories: ["vegetables"],
        is_all: true
    },
    {
        type: "artichoke",
        title: "Artichoke",
        categories: ["vegetables"]
    },
    {
        type: "asparagus",
        title: "Asparagus",
        categories: ["vegetables"]
    },
    {
        type: "beets",
        title: "Beets",
        categories: ["vegetables"]
    },
    {
        type: "broccoli",
        title: "Broccoli",
        categories: ["vegetables"]
    },
    {
        type: "carrots",
        title: "Carrots",
        categories: ["vegetables"]
    },
    {
        type: "sprouts",
        title: "Sprouts",
        categories: ["vegetables"]
    },
    {
        type: "celery",
        title: "Celery",
        categories: ["vegetables"]
    },
    {
        type: "peppers",
        title: "Peppers",
        categories: ["vegetables"]
    },
    {
        type: "tomato",
        title: "Tomato",
        categories: ["vegetables"]
    },
    {
        type: "eggplant",
        title: "Eggplant",
        categories: ["vegetables"]
    },
    {
        type: "all_startchy_vegetables",
        title: "All",
        categories: ["starchy_vegetables"],
        is_all: true
    },
    {
        type: "potatoes_yams",
        title: "Potatoes & Yams",
        categories: ["starchy_vegetables"]
    },
    {
        type: "corn",
        title: "Corn",
        categories: ["starchy_vegetables"]
    },
    {
        type: "all_honey",
        title: "All",
        categories: ["honey"],
    },
]

export const DietTypes: IDietType[] = [
    {
        type: "any",
        title: "Anything",
        description: "You can fine-tune your preferences at later step",
        exludes: [],
        descInfo: "",
        titleInfo: ""
    },
    {
        type: "mediterranean",
        title: "Mediterranean",
        description: "Excludes or limits processed foods, refined grains, added sugars, red meats, and unhealthy fats",
        exludes: [
            ...FoodTypes.filter(f => f.categories.includes("red_meat")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("starchy_vegetables")).map(x => x.type),
            "fruit_juice"

        ],
        titleInfo: "The Mediterranean diet is a heart-healthy eating plan rich in fruits, vegetables, whole grains, olive oil, and lean proteins like fish, inspired by the traditional dietary patterns of Mediterranean countries.",
        descInfo: "The Mediterranean diet generally excludes or limits processed foods, refined grains, added sugars, red meats, and unhealthy fats, like trans fats and excessive saturated fats. Instead, it emphasizes natural, whole foods and healthy fats primarily from olive oil, nuts, and fish.",
    },
    {
        type: "vegan",
        title: "Vegan",
        description: "Excludes all animal-derived products, including meat, dairy, eggs, and honey",
        exludes: [
            ...FoodTypes.filter(f => f.categories.includes("dairy")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("red_meat")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("fish")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("shellfish")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("poultry")).map(x => x.type),
            "all_eggs",
            "all_mayo",
            "all_honey"
        ],
        titleInfo: "The vegan diet is a plant-based eating plan that excludes all animal products, focusing on fruits, vegetables, grains, legumes, nuts, and seeds for a sustainable and compassionate approach to nutrition.",
        descInfo: "The vegan diet excludes all animal-derived products, including meat, dairy, eggs, and honey. It also typically avoids foods that contain any animal-based ingredients, such as gelatin and certain additives like casein or whey.",
    },
    {
        type: "keto",
        title: "Keto",
        description: "Excludes or limits most carbohydrate-rich foods, including grains, some fruits, starchy vegetables, legumes, and sugary foods or drinks.",
        exludes: [
            ...FoodTypes.filter(f => f.categories.includes("starchy_vegetables")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("legumes")).map(x => x.type),
        ],
        titleInfo: "The keto diet is a high-fat, low-carbohydrate eating plan that promotes fat as the primary fuel source, aiming to put the body into a state of ketosis for weight loss and energy stability.",
        descInfo: "The keto diet excludes or limits most carbohydrate-rich foods, including grains, fruits (except small amounts of berries), starchy vegetables, legumes, and sugary foods or drinks, focusing instead on foods high in healthy fats and moderate in protein.",
    },
    {
        type: "paleo",
        title: "Paleo",
        description: "Excludes processed foods, grains, legumes, dairy, refined sugars, and most vegetable oils",
        exludes: [
            ...FoodTypes.filter(f => f.categories.includes("soy")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("dairy")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("grains")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("legumes")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("starchy_vegetables")).map(x => x.type),
        ],
        titleInfo: "The paleo diet focuses on eating whole, unprocessed foods that resemble what early humans would have eaten, like meats, fish, vegetables, fruits, nuts, and seeds.",
        descInfo: "The paleo diet excludes processed foods, grains, legumes, dairy, refined sugars, and most vegetable oils, aiming to avoid foods that were unavailable to pre-agricultural societies.",
    },
    {
        type: "vegetarian",
        title: "Vegetarian",
        description: "Excludes meat, poultry, and fish",
        exludes: [
            ...FoodTypes.filter(f => f.categories.includes("red_meat")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("fish")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("shellfish")).map(x => x.type),
            ...FoodTypes.filter(f => f.categories.includes("poultry")).map(x => x.type),
        ],
        titleInfo: "The vegetarian diet emphasizes plant-based foods like fruits, vegetables, grains, nuts, and seeds, while allowing for dairy and eggs, depending on the type of vegetarianism.",
        descInfo: "The vegetarian diet excludes meat, poultry, and fish. Variations, such as lacto-ovo-vegetarianism, also avoid animal products except for dairy and eggs, while stricter types (like lacto-vegetarian) exclude eggs or (ovo-vegetarian) exclude dairy.",
    }
];