import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IPricingDetails } from "../../../interfaces/billing";

class PricingStrategy {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable pricingDetails?: IPricingDetails = undefined;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setEditingItem = (value?: IPricingDetails) => this.pricingDetails = value;

    @action load = async (shopId: string) => {
        try {
            if (!shopId) return;

            this.setLoading(true);

            var response = await agent.Shop.Billing.Pricing.get(shopId);

            this.setEditingItem(response);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action save = async (values: IPricingDetails) => {
        if (this.pricingDetails?.id) {
            return agent.Shop.Billing.Pricing.update(values.accountId, values);
        }
        else {
            return agent.Shop.Billing.Pricing.create(values.accountId, values);
        }
    }

    @action dispose = () => {
        this.setLoading(false);
        this.setEditingItem(undefined)
    }
}


export const PricingStrategyStore = new PricingStrategy();