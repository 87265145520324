
export type WalletTransactionType = "refund" | "order_debit" | "place_order" | "manual_debit" | "manual_credit";

export const WalletTransactionTypes: Array<{
    value: WalletTransactionType,
    key: WalletTransactionType,
    text: string,
}> =
    [
        {
            "value": "refund",
            "key": "refund",
            "text": "Refund from order",
        },
        {
            "value": "place_order",
            "key": "place_order",
            "text": "Place Order",
        },
        {
            "value": "manual_debit",
            "key": "manual_debit",
            "text": "Debit",
        },
        {
            "value": "manual_credit",
            "key": "manual_credit",
            "text": "Credit",
        },
        {
            "value": "order_debit",
            "key": "order_debit",
            "text": "Order Debit",
        },
    ]