import React, { useContext, useEffect, useState } from 'react';
import { Image, Grid, Divider } from 'semantic-ui-react';
import { RootStoreContext } from '../../stores/RootStoreContext';

type SquareValue = 'X' | 'O' | null;


const NotFoundPage = () => {
  const context = useContext(RootStoreContext)
  const { isMobile } = context.deviceStore;
  const [squares, setSquares] = useState<Array<SquareValue>>(Array(9).fill(null));
  const [xIsNext, setXIsNext] = useState<boolean>(true);

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (!xIsNext && !calculateWinner(squares) && squares.includes(null)) {
      const makeComputerMove = () => {
        let availableSquares = squares
          .map((square, index) => (square === null ? index : null))
          .filter((index) => index !== null) as number[];

        let randomIndex = availableSquares[Math.floor(Math.random() * availableSquares.length)];
        if (randomIndex !== undefined) {
          handleClick(randomIndex);
        }
      };

      const timer = setTimeout(makeComputerMove, 500); // 500 ms delay for the computer's move
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squares, xIsNext]);

  const handleClick = (index: number) => {
    const newSquares = squares.slice();
    if (calculateWinner(squares) || squares[index]) {
      return;
    }
    newSquares[index] = xIsNext ? 'X' : 'O';
    setSquares(newSquares);
    setXIsNext(!xIsNext);
  };

  const renderSquare = (index: number) => {
    let squareStyle = squares[index] === 'X' ?
      { color: 'red' } :
      { color: 'blue' };

    return (
      <button
        style={squareStyle}
        onClick={() => xIsNext && handleClick(index)}
      >
        {squares[index]}
      </button>
    );
  };

  const resetGame = () => {
    setSquares(Array(9).fill(null));
    setXIsNext(true);
  };

  const winner = calculateWinner(squares);
  const isGameOver = winner !== null || !squares.includes(null);
  const status = winner ? `Winner: ${winner}` : (isGameOver ? "Draw" : `Next player: ${xIsNext ? 'You' : 'Cheap Trolley'}`);

  return (
    <Grid columns={2} stackable stretched>
      <Grid.Row>
        <Grid.Column>
          {isMobile && <div className="game-header">
            <h1>Looks like we're both in <br /> the same boat of confusion!</h1>
          </div>}
          <Image alt="cute litttle kitten" style={{ maxHeight: "80vh", objectFit: "cover" }} src="/not_found.png" />
        </Grid.Column>
        <Grid.Column>
          <div className="game-container">
            <div className="game-header">
              {!isMobile && <h1>Looks like we're both in <br /> the same boat of confusion!</h1>}
              <div className="subheader">
                Oops, something went a bit haywire! But no stress – you've got options.
                Feel free to enjoy a game right here, or give your browser a quick  <button className='button-as-link blueColor' onClick={refreshPage}>
                  refresh
                </button> to fix things up.
                If the issue persists after a refresh, we're just a chat away on our  <a
                  className="blue"
                  href={`https://www.facebook.com/cheaptrolley`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook page
                </a> or an <a
                  className="blue"
                  href={`mailto:info@cheaptrolley.com`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  email
                </a>.
                We're here to help!
              </div>
            </div>
            <Divider />
            <div className="game-status">{status}</div>
            {isGameOver && (
              <button className="replay-button" onClick={resetGame}>
                Play again!
              </button>
            )}
            <div className="game-board">
              {renderSquare(0)}{renderSquare(1)}{renderSquare(2)}
              {renderSquare(3)}{renderSquare(4)}{renderSquare(5)}
              {renderSquare(6)}{renderSquare(7)}{renderSquare(8)}
            </div>
            <Divider />
            <button className="refresh-button" onClick={refreshPage}>
              Refresh Page
            </button>
          </div>
        </Grid.Column>

      </Grid.Row>
    </Grid >
  );
};

const calculateWinner = (squares: Array<SquareValue>): SquareValue => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
};

export default NotFoundPage;
