import { action, makeObservable, observable } from "mobx";
import { RootStore } from "../../../stores/RootStoreContext";
import agent from "../../../api/agent";
import { IClientOrdersToBulk, IHubOrder, IProposedHubOrder, IProposedHubOrderProductList } from "../../../interfaces/order";
import { IDeliveryForOrderList } from "../../../interfaces/delivery";
import { IShopLocation } from "../../../interfaces/locations";

export class HubOrdersStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loadingClientOrders: boolean = false;
    @observable loadingProposedOrders: boolean = false;
    @observable loadingActiveHubOrders: boolean = false;
    @observable creatingHubOrders: boolean = false;
    @observable clientOrders: IClientOrdersToBulk[] = [];
    @observable proposedHubOrders: IProposedHubOrder[] = [];
    @observable deliverySlots: IDeliveryForOrderList[] = [];
    @observable locations: IShopLocation[] = [];
    @observable hubOrders: IHubOrder[] = [];
    @observable products: IProposedHubOrderProductList[] = [];

    @action setLoadingClientOrders = (value: boolean) => this.loadingClientOrders = value;
    @action setCreatingHubOrders = (value: boolean) => this.creatingHubOrders = value;
    @action setLoadingProposedOrders = (value: boolean) => this.loadingProposedOrders = value;
    @action setLoadingActiveHubOrders = (value: boolean) => this.loadingActiveHubOrders = value;
    @action setClientOrders = (value: IClientOrdersToBulk[]) => this.clientOrders = value;
    @action setProposedHubOrders = (value: IProposedHubOrder[]) => this.proposedHubOrders = value;
    @action setProposedHubOrdersProducts = (value: IProposedHubOrderProductList[]) => this.products = value;
    @action setDeliverySlots = (value: IDeliveryForOrderList[]) => this.deliverySlots = value;
    @action setLocations = (value: IShopLocation[]) => this.locations = value;
    @action setHubOrders = (value: IHubOrder[]) => this.hubOrders = value;

    @action loadClientOrders = async () => {
        this.setLoadingClientOrders(true);

        try {
            var response = await agent.HubOrders.Prepare.get();

            this.setClientOrders(response.orders)
            this.setDeliverySlots(response.deliverySlots);
        } catch (error) {

        }
        finally {
            this.setLoadingClientOrders(false);
        }

    }

    @action loadLocations = async () => {
        var locations = await agent.Shop.Locations.Admin.all();
        this.setLocations(locations);
    }

    @action loadProposedOrders = async () => {
        this.setLoadingProposedOrders(true);

        try {
            await this.loadLocations();

            var response = await agent.HubOrders.Prepare.get_proposed();
            this.setProposedHubOrders(response.orders);
            this.setProposedHubOrdersProducts(response.productDetails);
        } catch (error) {

        }
        finally {
            this.setLoadingProposedOrders(false);
        }

    }

    @action createHubOrders = async () => {
        this.setCreatingHubOrders(true);

        try {
            var orderIds: string[] = []
            this.proposedHubOrders.forEach((x) => {
                orderIds.push(...x.orderIds)
            });

            await agent.HubOrders.Prepare.create(Array.from(new Set(orderIds)))
                .then(() => this.loadClientOrders())
                .then(() => this.loadProposedOrders())
                .then(() => this.loadActiveHubOrders());

        } catch (error) {

        }
        finally {
            this.setCreatingHubOrders(false);
        }

    }

    @action loadActiveHubOrders = async () => {
        this.setLoadingActiveHubOrders(true);

        try {
            var response = await agent.HubOrders.get(["sent_to_market", "in_process", "ready_for_delivery", "received", "seen"]);
            this.setHubOrders(response);
        } catch (error) {

        }
        finally {
            this.setLoadingActiveHubOrders(false);
        }

    }

    @action dispose = () => {
        this.setLoadingClientOrders(false);
        this.setCreatingHubOrders(false);
        this.setLoadingProposedOrders(false);
        this.setLoadingActiveHubOrders(false);
        this.setClientOrders([]);
        this.setProposedHubOrders([]);
        this.setDeliverySlots([]);
        this.setHubOrders([]);
    }
}