import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";

import { RootStoreContext } from "../../stores/RootStoreContext";
import OneByOne from "./components/OneByOne";
import { Button, Menu } from "semantic-ui-react";
import ListViewAssociations from "./components/ListViewAssociations";

const PossibleAssociationsV2 = () => {
  const context = useContext(RootStoreContext);
  const {
    oneTimeLoad,
    dispose,
    loading,
    listView,
    possibleAssociationsCount,
    toggleListView
  } = context.possibleAssociationsV2Store;

  useEffect(() => {
    oneTimeLoad();

    return () => {
      dispose();
    };
  }, [oneTimeLoad, dispose]);

  return (
    <div>
      <Menu
        secondary
        size={"small"}
        style={{ margin: "0 0 5px 0" }}
      >
        <Menu.Item>
          {loading ? "---" : <b>{possibleAssociationsCount}</b>} pa
        </Menu.Item>

        <Menu.Menu position="right">
          <Button
            color="blue"
            basic={!listView}
            icon={"list ul"}
            active={listView}
            content={"List View"}
            onClick={() => {
              toggleListView();
            }}
          />
        </Menu.Menu>
      </Menu>

      {listView ? <ListViewAssociations /> : <OneByOne />}
    </div>
  );
};

export default observer(PossibleAssociationsV2);
