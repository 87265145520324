import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { Button, Label, List } from "semantic-ui-react";
import { IShopOrderForList } from "../../../interfaces/order";
import { LocationOrderStatusOptions } from "../../../options/LocationOrderStatusOptions";

interface IProps {
    order: IShopOrderForList;
    onClick: () => void;
}

const ShopOrderItem: React.FC<IProps> = ({ order, onClick }) => {
    return (
        <List.Item
            onClick={onClick}
        >
            <List.Content floated="right">
                <Fragment>
                    <Button
                        size="large"
                        className="button-as-link greenColor"
                        icon={"angle right"}
                        onClick={() => { }}
                    />
                </Fragment>
            </List.Content>
            <List.Content>
                <List.Header>
                    <Label
                        basic
                        size="small"
                        color={
                            LocationOrderStatusOptions.filter((f) => order.status.includes(f.value))[0].color2
                        }
                    >
                        {LocationOrderStatusOptions.filter((f) => order.status.includes(f.value)).map(f => f.text2).join(", ")}
                    </Label> - {order.displayId}
                </List.Header>
                <List.Description style={{ marginTop: "3px" }}>
                    <div className="fxDisplay fxFixed ">
                        <div>{format(order.createdOn * 1000, "dd/MM/yy, HH:mm")}</div>
                    </div>
                </List.Description>
            </List.Content>
        </List.Item>
    );
};

export default observer(ShopOrderItem);
