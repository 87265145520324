import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Header, Icon, Popup, Image } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { DietTypes } from '../../../options/diets';


const DietSelection = () => {
    const context = useContext(RootStoreContext);
    const {
        profile,
        setProfile,
        nextStep
    } = context.userDietProfileStore;
    const { isMobile } = context.deviceStore;

    return (
        <div
            className='fxFixed fxDisplay fxAlignCenter fxStretch fxDirCol'
            style={isMobile ? { marginTop: "15px" } : { marginTop: "55px" }}>
            <Header
                size='large'
                textAlign='left'
                content="What would you like to eat?"
                subheader="Select from a pre-set diet below, you can adjust specific preferences later!"
                style={isMobile ? { padding: "15px" } : {
                    marginBottom: "25px"
                }}
            />

            <div style={isMobile ? { padding: "10px" } : { maxWidth: "550px" }}>
                {DietTypes.map(diet =>
                    <div
                        key={diet.type}
                        className='fxFixed fxDisplay setup-diet-box clickable'
                        onClick={() => setProfile({
                            ...profile,
                            dietType: diet.type,
                            avoid: diet.exludes,
                        })}
                    >
                        <div className="diet-info">
                            <Popup
                                on={"click"}
                                position='bottom right'
                                trigger={<Icon style={{
                                    color: "white",
                                    paddingLeft: "5px"
                                }} name='info' />}
                                content={
                                    <div>
                                        <p>{diet.titleInfo}</p>
                                        <p>{diet.descInfo}</p>
                                    </div>
                                }
                            />

                        </div>
                        <ol className="timeline" style={{ margin: "auto", maxWidth: "30px", maxHeight: "30px" }}>
                            <li className={`${profile?.dietType === diet.type ? "completed" : ""}`}>
                                <div
                                    className={`checkbox ${profile?.dietType === diet.type ? "completed" : ""}`}>
                                    <div style={{
                                        border: "solid",
                                        borderColor: "white",
                                        borderWidth: "2px",
                                        color: "white",
                                        width: "19px",
                                        height: "19px",
                                        borderRadius: "50%"
                                    }} />
                                </div>
                            </li>
                        </ol>
                        <div>
                            <Image className='diet-image' src={`./diets/${diet.type}.png`} />
                        </div>
                        <div className='fxStretch' style={{ marginLeft: "10px" }}>
                            <Header
                                size='medium'
                                textAlign='left'
                                content={diet.title}
                                subheader={diet.description}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div
                className='fxDisplay fxDirCol fxStretch'
                style={isMobile ? {
                    marginBottom: "15px",
                    width: "100%",
                    alignItems: "flex-end",
                    paddingRight: "10px"
                } : {
                    width: "550px",
                    textAlign: "center",
                    alignItems: "flex-end",
                    marginBottom: "15px"
                }}>
                <div
                    className={`diet-continue-button ${(profile && profile.dietType) ? "clickable" : "disabled"} `}
                    style={{ marginTop: "22px", }}
                    onClick={() => {
                        if (profile && profile.dietType)
                            nextStep()
                    }}
                >
                    <Icon name='angle right' />
                    Continue
                </div>
            </div>
        </div>
    )
}

export default observer(DietSelection);