import React from 'react';
import { toast } from 'react-toastify';
import NotFoundPage from '../features/404/NotFoundPage';

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        // You can log the error to an error reporting service
        // TODO: logErrorToMyService(error, errorInfo);
        toast.error(`Something went wrong but we are looking into it. [${error.message}]`)
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <NotFoundPage />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
