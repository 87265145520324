import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent, useContext, useEffect } from 'react'
import { Button, Form, Icon, Table } from 'semantic-ui-react';
import { Form as FinalForm, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FORM_ERROR } from "final-form";
import { FieldArray } from 'react-final-form-arrays';
import { DeliverySchedulerStore } from '../functions/DeliverySchedulerStore';
import SelectInput from '../../../form/SelectInput';
import { DaysOfWeek, HoursOfDay } from '../../../options/DaysOfWeek';
import NumericInput from '../../../form/NumericInput';
import ErrorMessage from '../../../form/ErrorMessage';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { Localities } from '../../../options/Localities';
import { toast } from 'react-toastify';
import TextInput from '../../../form/TextInput';

interface IProps {
    countryId: string
}

const DeliveryConfigurationWidget: React.FC<IProps> = ({ countryId }) => {
    const context = useContext(RootStoreContext);
    const { closeModal } = context.modalStore;

    const { editingItem, saveItem, load, loading, locations } = DeliverySchedulerStore;

    useEffect(() => {
        load(countryId);
        return () => {
        };
    }, [load, countryId]);

    return (
        <FinalForm
            mutators={{
                ...arrayMutators,
            }}
            onSubmit={(values: any) =>
                saveItem(countryId, { ...values })
                    .then(() => toast.success("Saved!"))
                    .catch((error) => ({
                        [FORM_ERROR]: error,
                    }))
            }
            initialValues={editingItem ?? {}}
            render={({
                handleSubmit,
                submitting,
                submitError,
                dirtySinceLastSubmit,
                invalid,
                form,
                form: {
                    mutators: { push, remove },
                },
                values,
            }) => <Form onSubmit={handleSubmit} error>
                    <Form.Field width={16}>
                        <Table celled color='teal'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='5'>
                                        Scheduled Pickup Times
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Day
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Locations
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Slot Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <FieldArray name="pickups">
                                    {({ fields }) =>
                                        fields.map((name, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    <Field
                                                        component={SelectInput}
                                                        options={DaysOfWeek}
                                                        multiselect={true}
                                                        name={`${name}.dayOfWeek`}
                                                        loading={loading}
                                                        placeholder={"Select day"}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Field
                                                        name={`${name}.locations`}
                                                        loading={loading}
                                                        component={SelectInput}
                                                        multiselect={true}
                                                        options={locations.filter(f => f.hasDelivery).map((x) => ({
                                                            key: x.id,
                                                            value: x.id,
                                                            text: x.title
                                                        }))}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Field
                                                        name={`${name}.scheduledTime`}
                                                        component={SelectInput}
                                                        options={HoursOfDay}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Field
                                                        name={`${name}.deliverySlot`}
                                                        component={SelectInput}
                                                        multiselect={true}
                                                        options={values.deliverySlots?.map((x: any) => ({
                                                            key: x.slotId,
                                                            value: x.slotId,
                                                            text: `${x.slotId}`
                                                        }))}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell disabled={loading}>
                                                    <Button
                                                        color="red"
                                                        icon="minus"
                                                        circular
                                                        size="mini"
                                                        onClick={(event: SyntheticEvent) => {
                                                            remove("pickups", index);
                                                            event.preventDefault();
                                                        }}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </FieldArray>
                            </Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="9">
                                        <Button
                                            disabled={loading}
                                            floated="right"
                                            basic
                                            color="blue"
                                            onClick={(event: SyntheticEvent) => {
                                                push("pickups", {});
                                                event.preventDefault();
                                            }}
                                        >
                                            <Icon name="add" />{" "}
                                            Add
                                        </Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>

                        <Table celled color='teal'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='8'>
                                        Delivery Slots
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Slot Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Slot Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Localities
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Day
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        From
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        To
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Limit
                                    </Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <FieldArray name="deliverySlots">
                                    {({ fields }) =>
                                        fields.map((name, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell style={{ minWidth: "80px" }}>
                                                    <Field
                                                        name={`${name}.slotId`}
                                                        component={NumericInput}
                                                        loading={loading}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell style={{ minWidth: "280px" }}>
                                                    <Field
                                                        name={`${name}.name`}
                                                        component={TextInput}
                                                        loading={loading}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Field
                                                        component={SelectInput}
                                                        options={Localities}
                                                        multiselect={true}
                                                        name={`${name}.localities`}
                                                        loading={loading}
                                                        placeholder={"Select Localities..."}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Field
                                                        component={SelectInput}
                                                        options={DaysOfWeek}
                                                        multiselect={true}
                                                        name={`${name}.dayOfWeek`}
                                                        loading={loading}
                                                        placeholder={"Select day"}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Field
                                                        name={`${name}.from`}
                                                        component={SelectInput}
                                                        loading={loading}
                                                        options={HoursOfDay}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Field
                                                        name={`${name}.to`}
                                                        component={SelectInput}
                                                        loading={loading}
                                                        options={HoursOfDay}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell style={{ minWidth: "80px" }}>
                                                    <Field
                                                        name={`${name}.limit`}
                                                        component={NumericInput}
                                                        loading={loading}
                                                    />
                                                </Table.Cell>

                                                <Table.Cell disabled={loading}>
                                                    <Button
                                                        color="red"
                                                        icon="minus"
                                                        circular
                                                        size="mini"
                                                        onClick={(event: SyntheticEvent) => {
                                                            remove("deliverySlots", index);
                                                            event.preventDefault();
                                                        }}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </FieldArray>
                            </Table.Body>

                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="9">
                                        <Button
                                            disabled={loading}
                                            floated="right"
                                            basic
                                            color="blue"
                                            onClick={(event: SyntheticEvent) => {
                                                push("deliverySlots", {});
                                                event.preventDefault();
                                            }}
                                        >
                                            <Icon name="add" />{" "}
                                            Add
                                        </Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>

                        {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage error={submitError} />
                        )}

                        <div style={{ textAlign: "center" }}>
                            <Button.Group style={{ paddingTop: "15px" }}>
                                <Button
                                    color="blue"
                                    disabled={(invalid && !dirtySinceLastSubmit)}
                                    loading={submitting}
                                    content={"Save"}
                                />
                                <Button.Or />
                                <Button
                                    onClick={(event) => {
                                        event.preventDefault();
                                        closeModal();
                                    }}
                                    content={"Cancel"}
                                />
                            </Button.Group>
                        </div>
                    </Form.Field>

                </Form>
            } />
    )
}

export default observer(DeliveryConfigurationWidget)