import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { Dimmer, Divider, Icon, Loader, Menu, Segment, Table } from "semantic-ui-react";
import ShopOrderItemTableRow from "./ShopOrderItemTableRow";
import { currencyFormat } from "../../products/functions/productHelper";
import { useLocation, useParams } from "react-router";
import CsvExport from "../../../common/csvExport";


const ShopOrderDetailWidget = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const displayId = searchParams.get('displayId');
    let { locationId } = useParams<"locationId">();
    let { orderId } = useParams<"orderId">();
    const context = useContext(RootStoreContext);
    const {
        loadItems,
        loadingItems,
        orderItems,
        dispose,
        exportDataForCsv,
        downloading
    } = context.shopOrderDetailStore;

    useEffect(() => {
        loadItems(orderId!, displayId!, locationId!);

        return () => {
            dispose();
        };
    }, [dispose, orderId, loadItems, displayId, locationId]);

    return (
        <div className="simply-paddedPage">
            <Menu secondary>
                <Menu.Menu position='right'>
                    <Menu.Item icon title="Export" style={{ padding: "0px" }}>
                        <CsvExport
                            onClick={exportDataForCsv}
                            content={
                                <Fragment>
                                    <Icon name="file excel outline" /> Export
                                </Fragment>
                            }
                            disabled={orderItems.length === 0 || downloading}
                            loading={downloading}
                        />
                    </Menu.Item>
                </Menu.Menu>

            </Menu>
            <Divider />
            {loadingItems ? <Segment>
                <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            </Segment> :
                <div>
                    <Table basic='very' celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Product</Table.HeaderCell>
                                <Table.HeaderCell>Shop Status</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Quantities</Table.HeaderCell>
                                <Table.HeaderCell>Unit Net</Table.HeaderCell>
                                <Table.HeaderCell>Unit Vat</Table.HeaderCell>
                                <Table.HeaderCell>Unit Total</Table.HeaderCell>
                                <Table.HeaderCell>Total Net</Table.HeaderCell>
                                <Table.HeaderCell>Total Vat</Table.HeaderCell>
                                <Table.HeaderCell>Total</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {orderItems.map(x => <ShopOrderItemTableRow key={x.product.id} item={x} />)}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell textAlign="right" style={{
                                    fontSize: "17px",
                                    fontWeight: "bolder",
                                }}>Totals:</Table.HeaderCell>
                                <Table.HeaderCell textAlign="right" style={{
                                    fontSize: "17px",
                                    fontWeight: "bolder",
                                }}>
                                    {currencyFormat(orderItems?.reduce((a, b) => a + (b.unitNet * (b.receivedAmount ?? b.packedAmount ?? b.quantity)), 0), true)}
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="right" style={{
                                    fontSize: "17px",
                                    fontWeight: "bolder",
                                }}>
                                    {currencyFormat(orderItems?.reduce((a, b) => a + (b.unitVat * (b.receivedAmount ?? b.packedAmount ?? b.quantity)), 0), true)}
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="right" style={{
                                    fontSize: "17px",
                                    fontWeight: "bolder",
                                }}>
                                    {currencyFormat(orderItems?.reduce((a, b) => a + ((b.unitNet + b.unitVat) * (b.receivedAmount ?? b.packedAmount ?? b.quantity)), 0), true)}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>

                    </Table>
                </div>
            }
        </div>
    );
};

export default observer(ShopOrderDetailWidget);
