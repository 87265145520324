export function getBase64(file: any, cb: (data: string) => void) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result as string)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export function downloadURI(uri: string, name: string) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const saveByteArray = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    (a.style as any) = "display: none";

    return function (data: any, name: any) {
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = name;
        a.click();
    };
})();