import { observer } from 'mobx-react-lite'
import React, { Fragment } from 'react'
import { FORM_ERROR } from "final-form";
import { Form as FinalForm, Field } from "react-final-form";
import { toast } from 'react-toastify';
import { Button, Icon, Label, Form } from 'semantic-ui-react';
import NumericInput from '../../../form/NumericInput';
import SelectInput from '../../../form/SelectInput';
import TextInput from '../../../form/TextInput';
import ErrorMessage from '../../../form/ErrorMessage';
import { IVATCategory } from '../../../interfaces/vat';
import { combineValidators, isRequired } from 'revalidate';
import { Countries } from '../../../options/Countries';
import CheckBox from '../../../form/CheckBox';

interface IProps {
    data?: IVATCategory;
    setData: (values?: IVATCategory) => void;
    save: (values: IVATCategory) => Promise<void>;
}

const validate = combineValidators({
    title: isRequired(""),
    code: isRequired(""),
    rate: isRequired(""),
    countryId: isRequired(""),
});

const VatDetailWidget: React.FC<IProps> = ({ data, setData, save }) => {
    return (
        <Fragment>
            <Icon style={{ position: "absolute" }} size='large' name='close' onClick={() => setData(undefined)} />

            <div
                className="fxFixed fxDisplay fxDirCol fxAlignCenter"
            >
                <FinalForm
                    onSubmit={(values: IVATCategory) =>
                        save(values)
                            .then(() => toast.success("Saved", { autoClose: 1000 }))
                            .catch((error: any) => ({
                                [FORM_ERROR]: error,
                            }))
                    }
                    initialValues={data ?? {}}
                    validate={validate}
                    render={({
                        handleSubmit,
                        submitError,
                        dirtySinceLastSubmit,
                        submitting,
                        invalid,
                        pristine,
                        values,
                        form,
                    }) => (
                        <Form onSubmit={handleSubmit} error>

                            <div style={{ minWidth: "400px", paddingTop: "15px" }}>
                                <Form.Group widths={'equal'}>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>{"Title"}</label>
                                        <Field
                                            component={TextInput}
                                            name="title"
                                            placeholder={"Title"}
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <Form.Group widths={'equal'}>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>{"Code"}</label>
                                        <Field
                                            component={TextInput}
                                            name="code"
                                            placeholder={"e.g. F"}
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <Form.Group widths={'equal'}>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>{"Rate"}</label>
                                        <Field
                                            component={NumericInput}
                                            name="rate"
                                            placeholder={"e.g. 0.05"}
                                        /> {`${(values.rate ?? 0) * 100}%`}
                                    </Form.Field>
                                </Form.Group>

                                <Form.Group widths={'equal'}>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>
                                            {"Country"}
                                        </label>
                                        <Field
                                            component={SelectInput}
                                            options={Countries.filter((f) => f.active === true)}
                                            name="countryId"
                                            placeholder={"Select Country..."}
                                        />
                                        <Label
                                            color="blue"
                                            basic
                                            size="small"
                                            style={{ marginTop: "4px" }}
                                            content={"Malta"}
                                            onClick={() => {
                                                form.change(
                                                    "countryId",
                                                    129
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <Form.Field style={{ textAlign: "left" }}>
                                    <Field
                                        name="apply_discount"
                                        component={CheckBox}
                                        type="checkbox"
                                        label={
                                            <span>Allow Discounts</span>
                                        }
                                    />
                                </Form.Field>

                                <Form.Field style={{ textAlign: "left" }}>
                                    <Field
                                        name="has_profit"
                                        component={CheckBox}
                                        type="checkbox"
                                        label={
                                            <span style={{ color: "green" }}>Has Profit</span>
                                        }
                                    />
                                </Form.Field>

                                <Form.Field style={{ textAlign: "left" }}>
                                    <Field
                                        name="active"
                                        component={CheckBox}
                                        type="checkbox"
                                        label={
                                            <span style={{ color: "green" }}>Active</span>
                                        }
                                    />
                                </Form.Field>
                            </div>

                            {submitError && !dirtySinceLastSubmit && (
                                <ErrorMessage error={submitError} />
                            )}
                            <div style={{ textAlign: "center" }}>
                                <Button.Group style={{ paddingTop: "15px" }}>
                                    <Button
                                        color="blue"
                                        disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                        loading={submitting}
                                        content={"Save"}
                                    />
                                    <Button.Or />
                                    <Button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setData(undefined);
                                        }}
                                        content={"Cancel"}
                                    />
                                </Button.Group>
                            </div>
                        </Form>
                    )}
                />
            </div>
        </Fragment>
    )
}

export default observer(VatDetailWidget)