import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Button, Form, } from "semantic-ui-react";
import {
    combineValidators,
    isRequired,
} from "revalidate";
import { RootStoreContext } from '../../../stores/RootStoreContext';
import TextInput from '../../../form/TextInput';
import TextAreaInput from '../../../form/TextAreaInput';
import ErrorMessage from '../../../form/ErrorMessage';

interface IProps {
    itemId?: string;
}

const validate = combineValidators({
    title: isRequired(""),
    skus: isRequired(""),
});


const CreateUpdateProductList: React.FC<IProps> = ({ itemId }) => {
    const context = useContext(RootStoreContext);
    const { closeModal } = context.modalStore;
    const { createList, getList, updateList } = context.productListStore;

    useEffect(() => {
        if (itemId) {
            getList(itemId).then((value) => setEditingItem(value));
        }

        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [editingItem, setEditingItem] = React.useState<any>(undefined)

    return (
        <FinalForm
            validate={validate}
            initialValues={editingItem ?? undefined}
            onSubmit={(values: any) =>
                itemId === undefined ?
                    createList(values)
                        .then(() => closeModal())
                        .catch((error) => ({
                            [FORM_ERROR]: error,
                        })) : updateList(values)
                            .then(() => closeModal())
                            .catch((error) => ({
                                [FORM_ERROR]: error,
                            }))
            }
            render={({
                handleSubmit,
                submitting,
                dirtySinceLastSubmit,
                submitError,
            }) => (
                <Form onSubmit={handleSubmit} error className="attached fluid">
                    <Form.Field>
                        <label style={{ fontSize: "13px" }}>{"Title"}</label>
                        <Field
                            name="title"
                            component={TextInput}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label style={{ fontSize: "13px" }}>{"Skus (comma separated - up to 1000 different items)"}</label>
                        <Field
                            name="skus"
                            component={TextAreaInput}
                        />
                    </Form.Field>


                    {submitError && !dirtySinceLastSubmit && (
                        <ErrorMessage error={submitError} />
                    )}

                    <div style={{ marginTop: "7px", textAlign: "center" }}>
                        <Button.Group>
                            <Button
                                loading={submitting}
                                className="ct-green-button-active"
                                content={"Save"}
                            />
                            <Button.Or />
                            <Button
                                disabled={submitting}
                                content={"Cancel"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeModal();
                                }}
                            />
                        </Button.Group>

                    </div>
                </Form>
            )}
        />
    )
}


export default observer(CreateUpdateProductList);