import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IDietaryCategory } from "../../../interfaces/dietaryCategory";
import { IProductForDescription } from "../../../interfaces/product";
import { IBrand, IBrandForSave } from "../../../interfaces/brand";

class BrandDetail {
    @observable dietaryCategories: IDietaryCategory[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @observable products: IProductForDescription[] = []
    @observable loadingProducts: boolean = false;
    @observable loading: boolean = false;
    @observable editingItem?: IBrand = undefined
    @observable loadingDetail: boolean = false;
    @observable hasMoreProducts: boolean = false;
    @observable currentPageProducts: number = 0;

    @action setEditingItem = (value?: IBrand) => this.editingItem = value;
    @action setLoadingProducts = (value: boolean) => this.loadingProducts = value;
    @action setLoadingDetail = (value: boolean) => this.loadingDetail = value;
    @action setLoading = (value: boolean) => this.loading = value;
    @action resetProducts = (value: IProductForDescription[]) => this.products = value
    @action setHasMoreProducts = (value: boolean) => this.hasMoreProducts = value;
    @action setCurrentPageProducts = (value: number) => this.currentPageProducts = value;
    @action setProducts = (value: IProductForDescription[]) => {
        var existing = this.products.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.products.push(...missing);
    };

    @action loadDetail = async (id?: string) => {
        if (!id) return;

        this.setLoadingDetail(true);
        this.loadProducts(id);

        agent.Brands.Admin.detail(id).then((result) => {
            this.setEditingItem(result);
            this.setLoadingDetail(false);
        })
    }

    @action loadProducts = async (brandId?: string) => {
        try {
            if (!brandId) return;

            this.setLoadingProducts(true);

            var response = await agent.Brands.Admin.get_products(brandId, this.currentPageProducts);

            this.setCurrentPageProducts(this.currentPageProducts + 1);
            this.setHasMoreProducts(response.HasMore);
            this.setProducts(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoadingProducts(false);
        }

    };

    @action create = async (brand: IBrandForSave) => {
        return await agent.Brands.Admin.create(brand);
    }

    @action update = async (brand: IBrand, updateCallback?: (item: IBrand) => void) => {
        var vals = {
            'title': brand.title,
            'tags': brand.tags,
            'imageUrl': brand.imageUrl
        }
        await agent.Brands.Admin.update(brand.id, vals).then(() => {
            if (updateCallback)
                updateCallback(brand);
        })
    }

    @action removeProductBrand = async (productId: string, brandId: string) => {
        try {
            if (!brandId) return;

            await agent.Brands.Admin.removeProduct(productId, brandId);

            this.resetProducts(this.products.filter(f => f.id !== productId))
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
        }

    };

    @action disposeDetail = () => {
        this.products = []
        this.setLoadingDetail(false);
        this.setEditingItem(undefined)
        this.setHasMoreProducts(false);
        this.setLoadingProducts(false);
        this.setCurrentPageProducts(0);
    }
}

export const BrandDetailStore = new BrandDetail();