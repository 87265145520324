import React, { Fragment } from "react";
import { AxiosResponse } from "axios";
import { Message } from "semantic-ui-react";

interface IProps {
  error?: AxiosResponse;
  text?: string;
}

const ErrorMessage: React.FC<IProps> = ({ error, text }) => {
  return (error && error.data && Object.keys(error.data.errors).length > 0) ||
    text ? (
    <Message error>
      {error && <Message.Header>{error.statusText}</Message.Header>}
      {error && error.data && Object.keys(error.data.errors).length > 0 && (
        <Message.List>
          {Object.values(error.data.errors.filter((x: string) => x !== ""))
            .flat()
            .map((err: any, i) => (
              <Message.Item key={i}>{err}</Message.Item>
            ))}
        </Message.List>
      )}
      {text && <Message.Content content={text} />}
    </Message>
  ) : (
    <Fragment />
  );
};

export default ErrorMessage;
