import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router';
import { Button, Dimmer, Divider, Grid, Header, Icon, Input, Label, List, Loader, Menu, Segment } from 'semantic-ui-react';
import { CampaignsDetailStore } from '../functions/CampaignsDetailStore';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ConstractFullName } from '../../../helpers/textHelper';
import { format } from 'date-fns';

const MarketingCampaignDetail = () => {
    const navigate = useNavigate();
    let { id } = useParams<"id">();

    const {
        users,
        loadUsers,
        loadingUsers,
        hasMoreUsers,
        dispose,
        search,
        loadCampaignUsers,
        campaignUsers,
        loadingCampaignUsers,
        hasMoreCampaignUsers,
        addUser,
        addUsers,
        removeUser
    } = CampaignsDetailStore;

    const [timer, setTimer] = React.useState(0);

    useEffect(() => {
        loadUsers();
        loadCampaignUsers(id!, undefined);

        return () => {
            dispose();
        }
    }, [dispose, loadUsers, loadCampaignUsers, id])


    return (
        <div>
            <Menu secondary
                size={"small"}
                style={{ margin: "5px 0" }}>
                <Menu.Menu position='right'>
                    <Menu.Item style={{ padding: "0px" }}>
                        <Button
                            basic
                            color="blue"
                            onClick={() => {
                                navigate(`/marketing-campaigns/${id}/sending`);
                            }}
                        >
                            Send Emails
                        </Button>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8} >
                        <Header textAlign='center'>
                            <div style={{ position: "absolute", right: "0" }}>
                                <Icon
                                    onClick={() => { addUsers(id!) }}
                                    className='clickable'
                                    title="All Users"
                                    name={"user plus"}
                                    // name={gettingUsers ? 'circle notch' : 'share'}
                                    // loading={gettingUsers}
                                    color='orange'
                                    size='large'
                                />
                            </div>
                            Users
                        </Header>
                        <Divider />
                        <Input
                            size={"small"}
                            fluid
                            icon={<Icon name="search" color="blue" />}
                            placeholder={"Search..."}
                            input={
                                <input className={"bluePlaceholder"} />
                            }
                            onChange={(event: ChangeEvent, data: any) => {
                                if (!search) return;
                                if (timer != null) {
                                    clearTimeout(timer);
                                    setTimer(0);
                                }

                                setTimer(
                                    setTimeout(
                                        (callback: (text: string) => void, text: string) => {
                                            callback(text);
                                            clearTimeout(timer);
                                        },
                                        500,
                                        search,
                                        data.value
                                    )
                                );
                            }}
                        />
                        <Divider />
                        {!loadingUsers || users.length > 0 ? (
                            <InfiniteScroll
                                style={{ overflow: "auto", maxHeight: "67vh" }}
                                dataLength={users.length} //This is important field to render the next data
                                next={() => loadUsers()}
                                hasMore={hasMoreUsers}
                                loader={loadingUsers ? <h4>Loading...</h4> : <div />}
                            >
                                <List
                                    divided
                                    relaxed
                                    selection
                                >
                                    {users.map((x) => (
                                        <List.Item key={`user_${x.id}`} onDoubleClick={() => addUser(id!, x.id)}>
                                            <List.Content>
                                                <List.Header>
                                                    {ConstractFullName(x.firstName, x.lastName, x.middleName)}
                                                    {x.lockoutEnd && (
                                                        <Label
                                                            color="red"
                                                            basic
                                                            content={`LockedOut : ${format(
                                                                new Date(x.lockoutEnd),
                                                                "dd/MM/yyyy HH:mm"
                                                            )}`}
                                                        />
                                                    )}
                                                </List.Header>
                                                {x.registeredOn && (
                                                    <List.Description>
                                                        <p>
                                                            <span
                                                                style={{
                                                                    color: `${x.emailConfirmed ? "green" : "red"}`,
                                                                }}
                                                            >
                                                                {x.email}
                                                            </span>
                                                            {` - [${x.id}]`}
                                                        </p>
                                                    </List.Description>
                                                )}
                                            </List.Content>
                                        </List.Item>
                                    ))}
                                </List>
                            </InfiniteScroll>
                        ) : (
                            <Segment>
                                <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer>
                            </Segment>
                        )}

                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Header content="Selected Users" textAlign='center' />
                        <Divider />
                        <Input
                            size={"small"}
                            disabled
                            fluid
                            icon={<Icon name="search" color="blue" />}
                            placeholder={"Search..."}
                            input={
                                <input className={"bluePlaceholder"} />
                            }
                            onChange={(event: ChangeEvent, data: any) => {
                                if (!search) return;
                                if (timer != null) {
                                    clearTimeout(timer);
                                    setTimer(0);
                                }

                                setTimer(
                                    setTimeout(
                                        (callback: (text: string) => void, text: string) => {
                                            callback(text);
                                            clearTimeout(timer);
                                        },
                                        500,
                                        search,
                                        data.value
                                    )
                                );
                            }}
                        />
                        <Divider />
                        <div style={{ maxHeight: "70vh" }}>
                            {!loadingCampaignUsers || campaignUsers.length > 0 ? (
                                <InfiniteScroll
                                    style={{ overflow: "auto", maxHeight: "67vh" }}
                                    dataLength={campaignUsers.length} //This is important field to render the next data
                                    next={() => loadCampaignUsers(id!, undefined)}
                                    hasMore={hasMoreCampaignUsers}
                                    loader={loadingCampaignUsers ? <h4>Loading...</h4> : <div />}
                                >
                                    <List
                                        divided
                                        relaxed
                                        selection
                                    >
                                        {campaignUsers.map((x) => (
                                            <List.Item>
                                                <List.Content>
                                                    <List.Content floated="right">
                                                        <Button basic circular color='red' icon="trash alternate outline" onClick={() => removeUser(id!, x.id)} />
                                                    </List.Content>
                                                    <List.Header>
                                                        {ConstractFullName(x.firstName, x.lastName, x.middleName)}
                                                    </List.Header>
                                                    <List.Description>
                                                        <p>
                                                            <span>
                                                                {x.email}
                                                            </span>
                                                            <span style={{ paddingLeft: "3px", color: (x.status === "sent" ? "green" : "red") }}>
                                                                {` - [${x.status}]`}
                                                            </span>
                                                        </p>
                                                    </List.Description>
                                                </List.Content>
                                            </List.Item>
                                        ))}
                                    </List>
                                </InfiniteScroll>
                            ) : (
                                <Segment>
                                    <Dimmer active inverted>
                                        <Loader inverted>Loading</Loader>
                                    </Dimmer>
                                </Segment>
                            )}
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div >
    )
}

export default observer(MarketingCampaignDetail)