import { action, makeObservable, observable, runInAction } from "mobx";
import { RootStore } from "../../../stores/RootStoreContext";
import { IUserMinimal, IUserProfile } from "../../../interfaces/user";
import agent from "../../../api/agent";

export class ProfileStore {
    rootStore: RootStore;

    @observable currentlyEditing: string = "";
    @observable profile?: IUserProfile = undefined;
    @observable loading: boolean = false;
    @observable preferredStores: string[] = [];
    @observable saving: boolean = false;
    @observable delivery: boolean = false;
    @observable chefAI: boolean = false;

    @action setSaving = (value: boolean) => this.saving = value;
    @action setDelivery = (value: boolean) => this.delivery = value;
    @action setChefAI = (value: boolean) => this.chefAI = value;
    @action setCurrentlyEditing = (value: string) => this.currentlyEditing = value;
    @action setUser = (values?: IUserMinimal) => this.profile = values;
    @action setLoading = (value: boolean) => this.loading = value;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @action getUser = async (userId: string) => {
        try {
            this.setLoading(true);

            if (this.rootStore.userStore.isOwner) {
                agent.GlobalConfig.isUserDeliveryEnabled(userId).then((value) => this.setDelivery(value ?? false));
                agent.GlobalConfig.isUserChefEnabled(userId).then((value) => this.setChefAI(value ?? false));
            }

            var user = await agent.Users.getProfile(userId);

            var preferredMarkets = await agent.Users.getPreferredStore(userId);


            runInAction(() => {
                this.preferredStores = preferredMarkets
            })

            this.setUser(user);

        } catch (error) {
            this.setUser(undefined);
            this.rootStore.sessionStore.logout();
            console.log(error);
        } finally {
            this.setLoading(false);
        }
    }

    @action updateUserName = async (
        firstName: string,
        lastName: string,
        middleName?: string
    ) => {
        await agent.Users.update(this.profile!.id, {
            firstName: firstName,
            lastName: lastName,
            middleName: middleName === undefined ? "" : middleName,
        });

        runInAction(() => {
            this.profile!.firstName = firstName;
            this.profile!.lastName = lastName;
            this.profile!.middleName = middleName;

            if (this.rootStore.userStore.user?.id === this.profile?.id) {
                this.rootStore.userStore.user!.firstName = firstName;
                this.rootStore.userStore.user!.lastName = lastName;
                this.rootStore.userStore.user!.middleName = middleName;
            }
        });

        this.setCurrentlyEditing("");
    };

    @action setPreferredStores = async (values: string[]) => {
        this.setSaving(true);
        try {
            await agent.Users.updatePreferredStore(values, this.profile?.id);

            runInAction(() => {
                this.preferredStores = values
            });

            this.rootStore.commonStore.setSelectedMarketIds(values);
        } catch (error) {
            console.log(error);

        }
        finally { this.setSaving(false); }
    };

    @action dispose = () => {
        this.setUser(undefined);
        this.setLoading(false);
    }

    @action updateEmail = async (email: string) => {
        await agent.Users.update(this.profile!.id, {
            email: email,
        });

        runInAction(() => {
            this.profile!.email = email;
            if (this.rootStore.userStore.user)
                this.rootStore.userStore.user.email = email
        });

        this.setCurrentlyEditing("");
    };

    @action updateDelivery = async (value: boolean) => {
        await agent.GlobalConfig.updateDeliveryEnabled(this.profile!.id, value);

        this.setDelivery(value);
    };

    @action updateChefAI = async (value: boolean) => {
        await agent.GlobalConfig.updateChefAI(this.profile!.id, value);

        this.setChefAI(value);
    };

    @action updatePassword = async (changePassword: any) => {
        await agent.Auth.changePassword(this.profile!.id, changePassword);

        this.setCurrentlyEditing("");
    };

    @action updatePhone = async (
        phoneNumber?: string
    ) => {
        await agent.Users.update(this.profile!.id, {
            phoneNumber: phoneNumber === undefined ? "" : phoneNumber,
        });

        runInAction(() => {
            this.profile!.phoneNumber = phoneNumber;
            if (this.rootStore.userStore.user)
                this.rootStore.userStore.user.phoneNumber = phoneNumber
        });

        this.setCurrentlyEditing("");
    };
}