import { action, computed, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IAssociationForView } from "../../../interfaces/association";
import { IProductPriceInfo, IProductWithHistory } from "../../../interfaces/product";
import { currencyFormat } from "../../products/functions/productHelper";
import { ISuperMarket } from "../../../interfaces/supermarket";

class AssociationAnalysis {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable filterData?: any = undefined;
    @observable discontinued: boolean = false;
    @observable selectedProducts: string[] = [];
    @observable association?: IAssociationForView = undefined;
    @observable downloading: boolean = false;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setSelectedProducts = (value: string[]) => this.selectedProducts = value;
    @action setAssociation = (value?: IAssociationForView) => this.association = value;
    @action setDownloading = (value: boolean) => this.downloading = value;
    @action setFilterData = (value?: any) => this.filterData = value;


    @action toggleProductItem = (id: string) => {
        if (this.selectedProducts.includes(id)) {
            this.setSelectedProducts(this.selectedProducts.filter(f => f !== id))
        }
        else {
            this.setSelectedProducts([...this.selectedProducts, id])
        }
    }

    @action toggleAll = () => {
        if (this.association?.products.length === this.selectedProducts.length) {
            this.setSelectedProducts([]);
        }
        else {
            this.setSelectedProducts(this.association?.products.map(x => x.id) ?? [])
        }
    }

    @action toggleDiscontinued = (id?: string) => {
        try {
            this.dispose();
            this.discontinued = !this.discontinued;

            this.load(id);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    }

    @action load = async (associationId?: string) => {
        try {
            if (!associationId) return;
            this.setSelectedProducts([]);

            this.setLoading(true);

            var response = await agent.Associations.getDetailView(associationId, this.discontinued);

            // add date 0 
            response.products.forEach(x => {
                if (x.priceHistory?.length > 0) {
                    x.priceHistory = x.priceHistory.slice().sort((a, b) => a.untilDateNum - b.untilDateNum);

                    x.priceHistory.unshift({
                        priceBefore: x.priceHistory[0].priceBefore,
                        untilDateNum: x.priceHistory[0].untilDateNum - (86400 * 2),
                    })
                    var today_dt = new Date()
                    today_dt.setHours(6)
                    var today_epoch = today_dt.getTime() / 1000

                    x.priceHistory.push({
                        priceBefore: x.currentPrice,
                        untilDateNum: today_epoch,
                    })

                    for (let i = 0; i < x.priceHistory.length - 1; i++) {
                        x.priceHistory[i].priceBefore = x.priceHistory[i + 1].priceBefore;
                    }
                }
                else {
                    x.priceHistory.push({
                        priceBefore: x.currentPrice,
                        untilDateNum: x.lastUpdated - 86400,
                    })
                }
            })

            var prdIds = response.products.map(x => x.id);
            this.setSelectedProducts(prdIds);
            this.setAssociation(response);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
            // this.setData(this.priceHistoryArray());
        }

    };

    @action formatDate = (timestamp: number): number => {
        const date = new Date(timestamp * 1000);
        date.setHours(3);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date.getTime() / 1000 //.toLocaleDateString('en-GB'); // Formats to dd/MM/yyyy
    }


    @action getPriceForDate(product: IProductWithHistory, date: number): any | null {
        if (product.discontinued && product.lastUpdated < date) {
            return null; // Product discontinued before the date
        }

        // descending sorting
        const sortedHistory = product.priceHistory.slice().sort((a, b) => b.untilDateNum - a.untilDateNum);
        // Find the most recent price before the given date
        let applicablePrice = product.currentPrice;

        var oldestValue = sortedHistory[sortedHistory.length - 1].untilDateNum

        if (date < oldestValue)
            return {
                id: product.id, marketId: product.marketId
            };

        for (const history of sortedHistory) {
            if (history.untilDateNum <= date) {
                applicablePrice = history.priceBefore;
                break;
            }
        }

        return { id: product.id, marketId: product.marketId, price: applicablePrice };
    }

    @action generatePriceList = (products: IProductWithHistory[]): Map<number, IProductPriceInfo[]> => {
        const numdates = new Set<number>();
        const priceMap = new Map<number, IProductPriceInfo[]>();

        // Extract dates
        products.forEach(product => {
            if (this.discontinued === false && product.discontinued) {
                return;
            }
            if (!this.selectedProducts.includes(product.id)) {
                return;
            }
            product.priceHistory.forEach(history => {
                numdates.add(history.untilDateNum);
            });
        });

        var sortedDates = Array.from(numdates).sort((a, b) => (a > b ? 1 : -1));

        // Iterate over each date and calculate prices
        sortedDates.forEach(dt => {
            const prices = products.map(product => this.getPriceForDate(product, dt)).filter(priceInfo => priceInfo !== null);
            priceMap.set(this.formatDate(dt), prices as IProductPriceInfo[]);
        });

        return priceMap;
    }

    @computed get priceHistoryArray() {
        if (!this.association) return [];

        const priceList = this.generatePriceList(this.association.products.filter(f => this.selectedProducts.includes(f.id)));

        var retval: any[] = []
        priceList.forEach((values, date) => {
            var entry: any = {
                name: date,
            }

            var sortedValues = values.sort((a, b) => (a.price > b.price ? 1 : -1))

            sortedValues.forEach(x => {
                var propName: string = `${x.id}`
                entry[propName] = x.price !== undefined ? currencyFormat(x.price, false) : undefined
            })

            retval.push(entry)
        })

        if (this.selectedProducts.length === 0)
            return retval;

        return retval;
    }

    @action exportDataForCsv = async (markets: ISuperMarket[]) => {
        if (!this.association) return;

        this.setDownloading(true);
        try {
            var csvData: any[] = [];

            var expData = this.filterData ?? this.priceHistoryArray ?? [];
            // Extracting all the names
            var headers = ["Shop"]

            // Printing the first line
            expData.forEach((entry: any) => headers.push(new Date(entry.name * 1000).toLocaleDateString('en-GB')));

            csvData.push(headers);
            // Extracting all keys (excluding 'name') from the first object
            const keys = Object.keys(expData[0]).filter(key => key !== 'name');

            // Loop through each key and print its values across all data entries
            keys.forEach(key => {
                var rowData = []
                const values = expData.map((entry: any) => entry[key] || 'N/A');
                var product = this.association?.products.filter(f => f.id === key)[0]
                var shop = markets.filter(f => f.type_id === product?.marketId)[0].title
                rowData.push(shop);
                values.forEach((p: any) => rowData.push(p))
                csvData.push(rowData);
            });

            return {
                data: csvData,
                filename: `${this.association.title}.csv`,
            };

        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownloading(false);
        }
    };

    @action dispose = () => {
        this.setLoading(false);
        this.setAssociation(undefined);
        this.setFilterData(undefined);
        this.discontinued = false;
        this.setSelectedProducts([]);
        this.setDownloading(false);
    }
}


export const AssociationAnalysisStore = new AssociationAnalysis();