import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Icon, List } from 'semantic-ui-react'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from 'react-device-detect';

const ChatConversations = () => {
    const context = useContext(RootStoreContext);
    const {
        showSidebar,
        setShowSidebar,
        conversations,
        loadingConversations,
        hasMoreConversations,
        loadConversations,
        setSelectedConversation,
        startNewChat,
        conversationId,
        processingMessage
    } = context.chatUIStore;

    const processTitle = (text?: string): string => {
        if (!text)
            return "Untitled"

        const words = text.split(' ').filter(f => f.trim() !== '');

        // Transform the words into camelCase
        let result = words
            .map((word: string, index: number) => {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join(' '); // Join the words back together

        return result
    }

    return (
        <div id="conversations-bar"
            className={`chef-sidebar ${showSidebar ? "open" : "closed"}`}
            style={isMobile ? { paddingTop: "5px" } : { paddingTop: "20px" }}
        >
            <div className='fxFixed fxDisplay fxJustifyBetween'>
                <Icon style={{ paddingTop: "2px" }} name='expand' size='large' onClick={() => setShowSidebar(false)} />
                <Icon disabled={processingMessage} name='edit outline' size='large' onClick={() => startNewChat()} />
            </div>
            <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={conversations.length} //This is important field to render the next data
                next={() => loadConversations()}
                hasMore={hasMoreConversations}
                scrollableTarget="conversations-bar"
                loader={loadingConversations ? <div className='ct-green-text-dark' style={{ textAlign: "center" }}>
                    <Icon name='circle notch' loading size='big' />
                    <p style={{ paddingTop: "5px" }}>We are loading your chats....</p>
                </div> : <div />}
            >
                <List
                    className='sidebar-chat-list'
                    relaxed
                    inverted
                >
                    {conversations.length === 0 && !loadingConversations && <li>Type a message to start a new chat</li>}
                    {conversations.map((x, i) => (
                        <List.Item
                            className='clickable'
                            active={conversationId === x.id}
                            onClick={() => setSelectedConversation(x.id)} key={x.id}
                            content={<div>
                                {processTitle(x.title)}
                                {(x.users?.length ?? 0) > 0 && <div className='fxDisplay'>
                                    {x.users?.map((u) => <div style={{ paddingRight: "3px" }}>
                                        <Icon name="user circle outline" />
                                        {`${u.firstName} ${u.lastName}`}
                                    </div>)}
                                </div>}
                            </div>}
                        />
                    ))}
                </List>
            </InfiniteScroll>
        </div>
    )
}

export default observer(ChatConversations)