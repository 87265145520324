import React, { Fragment, useContext, useEffect, useRef } from 'react';
import ChatConversations from './components/ChatConversations';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { observer } from 'mobx-react-lite';
import ChatMessages from './components/ChatMessages';
import { Icon } from 'semantic-ui-react';
import WelcomeScreen from './components/WelcomeScreen';
import RecipeDetail from './components/RecipeDetail';

interface IProps {
    adminMode?: boolean
}

const ChatUI: React.FC<IProps> = ({ adminMode = false }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const { setPage } = context.analyticsStore;
    const { isChefEnabled, isOwner } = context.userStore;
    const {
        showSidebar,
        sendMessage,
        setCurrentMessage,
        currentMessage,
        setShowSidebar,
        messages,
        startNewChat,
        dispose,
        selectedRecipe,
        load,
        loadingMessages,
        processingMessage
    } = context.chatUIStore;

    // Handle input change
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentMessage(event.target.value);
    };

    // Handle pressing the "Enter" key
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleNewMessage();
        }
    };

    const handleNewMessage = () => {
        if (!processingMessage)
            sendMessage().then(() => { scrollToBottomOfDiv(); });
    }

    function scrollToBottomOfDiv() {
        const divElement = document.getElementById("chat-messages-div");
        if (divElement) {
            divElement.scrollTop = divElement.scrollHeight;
        }
    }

    useEffect(() => {
        setPage("HabibAi-Chat", window.location.pathname);
        document.body.style.overflow = 'hidden'; // Disable background scroll

        if (isChefEnabled || isOwner) {

            window.scrollTo(0, 0);
            if (inputRef.current) {
                inputRef.current.focus();
            }
            load(adminMode);
        }

        return () => {
            document.body.style.overflow = 'auto';
            dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispose, load])


    return (
        <div className='fxDisplay'>
            {isChefEnabled && <ChatConversations />}


            <div className='fxStretch'>
                {isChefEnabled && <div
                    style={{
                        position: "absolute",
                        top: isMobile ? 5 : 65,
                        left: 0,
                        right: 0,
                        zIndex: 88800
                    }}>
                    {!showSidebar && !selectedRecipe && <Fragment>
                        <Icon style={{ paddingTop: "1px" }} name='expand' size='large' onClick={() => setShowSidebar(true)} />
                        <Icon disabled={processingMessage} name='edit outline' size='large' onClick={() => startNewChat()} />
                    </Fragment>}
                </div>}
                <nav className='fxDisplay fxDirCol'
                    style={isMobile ? {
                        height: "100%"
                    } : {
                        height: "100%"
                    }}>
                    <div className=''
                        style={{
                            overflowY: "auto",
                            flexGrow: 1
                        }}
                    >
                        {messages.length === 0 && !loadingMessages ? <WelcomeScreen /> : <ChatMessages />}
                    </div>
                    {isChefEnabled &&
                        <div
                            className='fxDisplay'
                            style={{
                                padding: isMobile ? "15px" : '10px',
                                marginBottom: "10px",
                                flexShrink: 0,
                            }}>
                            <input
                                type="text"
                                value={currentMessage}
                                ref={inputRef}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                placeholder="Send a message..."
                                style={{
                                    flex: 1,
                                    padding: '12px',
                                    paddingBottom: "9px",
                                    borderRadius: '25px',
                                    fontSize: '16px',
                                    backgroundColor: '#f4f4f4',
                                    color: 'black',
                                    border: 'none',
                                    outline: 'none',
                                    marginRight: '10px',
                                }}
                            />
                            <button disabled={processingMessage} onClick={handleNewMessage} style={{
                                padding: '12px',
                                borderRadius: '50%',
                                backgroundColor: '#086c41',
                                border: 'none',
                                cursor: 'pointer',
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    style={{ width: '20px', height: '20px' }}
                                >
                                    <path d="M2.01 21L23 12 2.01 3v7l15 2-15 2z" />
                                </svg>
                            </button>
                        </div>}
                </nav>
            </div>

            <div className={`panel ${isMobile ? 'mobile' : ''} ${selectedRecipe !== undefined ? 'open' : ''}`}>
                {selectedRecipe && <RecipeDetail />}
            </div>
        </div >
    );



};
export default observer(ChatUI);
