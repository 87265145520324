export const ProfitTypes = [
    {
        key: "in_price",
        text: "Commission in price",
    },
    {
        key: "on_top",
        text: "Commission on top of price",
    }
]


export const PriceStreategies = [
    {
        key: "as_is",
        text: "Same price of the shop",
    },
    {
        key: "cheapest",
        text: "Cheapest price of all shops",
    },
    {
        key: "best",
        // in this case we find the cheapest price and
        // the price of the shop and we auto caclulate the cheap trolley price
        text: "Calculate best price [only for On Top]",
    }
]


