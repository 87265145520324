import React, { Fragment, useContext } from "react";
import { IDeliveryAssociation } from "../../interfaces/association";
import { ICatalogueProductForCompare } from "../../interfaces/product";
import {
  Button,
  Divider,
  Icon,
  List,
  Popup,
  Transition,
  Image,
} from "semantic-ui-react";
import { currencyFormat } from "../products/functions/productHelper";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { getMarketImageUrl } from "../../helpers/supermarketImage";
import { observer } from "mobx-react-lite";
import agent from "../../api/agent";

interface IProps {
  association: IDeliveryAssociation;
}

const CatalogueGroupedProductCard: React.FC<IProps> = ({
  association,
}) => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const { requestToGet } = context.userAssociationStore;
  const {
    addToCart,
    removeFromCart,
    getProductQuantity,
    syncCart,
    basketItems,
    bottleLimitPass,
    largeItemLimit
  } = context.shoppingCartStore;

  const [timer, setTimer] = React.useState(0);
  const [hide] = React.useState(200);
  const [show] = React.useState(500);
  const [cpPrice] = React.useState(association.catalogue?.price ?? 0);
  const [expanded, setExpanded] = React.useState(false);
  const [loadingDetail, setLoadingDetail] = React.useState(false);
  const [items, setItems] = React.useState<ICatalogueProductForCompare[]>([]);

  const getCheapestStoreCount = () => {
    if (!association.product) {
      return -1;
    }

    return association.product.stores.length;
  }

  const getCheapestPrice = () => {
    if (!association.product) {
      return cpPrice;
    }

    if (cpPrice === 0 || cpPrice > association.product.currentPrice)
      return association.product.currentPrice;

    else
      return cpPrice;
  }

  const getOtherItems = async () => {
    try {
      setLoadingDetail(true);
      if (items.length > 0) {
        setExpanded(!expanded);
        setLoadingDetail(false);
        return;
      }

      return agent.Catalogue.Order.getComparison(association.id).then((response) => {
        if (association.catalogue && (association.catalogue.price ?? 0) > 0)
          response.push({
            id: "CheapTrolley",
            marketId: "Cheap Trolley",
            currentPrice: association.catalogue.price ?? 0
          })

        setItems(response.slice()
          .sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1)));
        setExpanded(true);
        setLoadingDetail(false);

      })

    } catch (error) {
      setLoadingDetail(false);
    }
    finally { }
  }

  const syncCartHandle = () => {
    if (association.catalogue)
      syncCart(association.catalogue.id).then(() => clearTimeout(timer))
  }

  const handleChangeValue = (type: 'add' | 'remove') => {
    if (!association.catalogue) return;

    if (type === "add")
      addToCart(association.catalogue);
    else
      removeFromCart(association.catalogue.id);

    if (timer != null) {
      clearTimeout(timer);
      setTimer(0);
    }

    setTimer(
      setTimeout(syncCartHandle, 1000, null)
    );
  }

  return (
    <div className="ui card" style={{ padding: "7px", maxHeight: "380px" }}>
      <Transition
        animation="scale"
        duration={{ hide, show }}
        visible={!expanded}
      >
        <div style={expanded ? { position: "absolute" } : undefined}>
          <Image
            src={association.imageUrl ?? association.catalogue?.imageUrl ??
              `${process.env.REACT_APP_CLOUDFRONT}/image-not-found-ctl.png`}
            onError={({ currentTarget }: any) => {
              currentTarget.onerror = null;
              currentTarget.src =
                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found-ctl.png`;
            }}
            alt={association.title}
            className={"grouped-product-image"}
          />
          <div
            title={association.title}
            style={{ height: "45px" }}
            className="grouped-product-title fxStretch"
          >
            {association.title}
          </div>

          <div
            className="fxDisplay fxAlignCenter fxJustifyEven "
            style={{
              marginTop: "7px",
              height: "50px",
            }}
          >
            <div
              style={{
                fontSize: "19px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <Icon name="euro" />
              {currencyFormat(getCheapestPrice(), false)}
              {(association.compare || getCheapestStoreCount() > 1) && <div
                className="group-compare-new"
                onClick={getOtherItems}
              >
                <Icon name={loadingDetail ? "circle notch" : "exchange"} loading={loadingDetail} />
                {loadingDetail ? "Loading" : "Compare"}
              </div>}
              {association.catalogue && cpPrice > getCheapestPrice() && <div style={{ fontSize: "14px", fontWeight: "400" }}>Available at {currencyFormat(cpPrice, true)}</div>}
              {association.catalogue && cpPrice <= getCheapestPrice() && <div style={{ fontSize: "14px", fontWeight: "400" }} className="ct-green-text">Available at lowest price!</div>}
            </div>
            <div>
              {
                getCheapestStoreCount() === -1 && <Image
                  src={"/cheaptrolley.svg"}
                  centered
                  size="tiny"
                  style={{ maxHeight: "45px", objectFit: "contain" }}
                  alt={
                    "Cheap Trolley Logo"
                  }
                  title={
                    "Cheap Trolley"
                  }
                />
              }
              {
                getCheapestStoreCount() === 1 && <Image
                  centered
                  alt={
                    markets.filter(
                      (f) => f.type_id === association.product!.stores[0]
                    )[0].title
                  }
                  title={
                    markets.filter(
                      (f) => f.type_id === association.product!.stores[0]
                    )[0].title
                  }
                  src={getMarketImageUrl(
                    markets.filter(
                      (f) => f.type_id === association.product!.stores[0]
                    )[0]
                  )}
                  size="tiny"
                  style={{ maxHeight: "45px", objectFit: "contain" }}
                />
              }
              {association.product && getCheapestStoreCount() > 1 &&
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  in{" "}
                  {
                    getCheapestStoreCount()
                  }{" "}
                  stores
                </div>
              }
            </div>
          </div>
          <Divider
            style={
              (association.catalogue ? getProductQuantity(association.catalogue?.id) : 0) > 0
                ? { marginBottom: "4px" }
                : undefined
            }
          />

          {association.catalogue ? <div>
            {getProductQuantity(association.catalogue.id) > 0 ? (
              <div
                className={`fxDisplay fxFixed fxJustifyBetween`}
                style={{ paddingLeft: "8px", paddingRight: "8px" }}
              >
                <div className={`item-animate minus`}>
                  <Popup
                    trigger={
                      <Button
                        size="tiny"
                        circular
                        className="ct-green-button-active"
                        icon={"minus"}
                        onClick={() => {
                          handleChangeValue("remove");
                        }}
                      />
                    }
                    content={"Remove from cart"}
                    on="hover"
                  />
                </div>

                <div
                  className={`item-animate number `}
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    marginTop: "5px",
                  }}
                >
                  {getProductQuantity(association.catalogue.id)}
                </div>

                <div className={`item-animate-fast plus`}>
                  <Popup
                    trigger={
                      <Button
                        size="tiny"
                        circular
                        className="ct-green-button-active"
                        icon={"plus"}
                        onClick={() => {
                          handleChangeValue("add");
                        }}
                      />
                    }
                    content={"Add more"}
                    on="hover"
                  />
                </div>

              </div>
            ) :
              <div
                className={`fxDisplay fxFixed fxJustifyEnd item-fade-out`}
                style={{ paddingRight: "12px", marginTop: "-5px" }}
              >
                <Icon
                  className="linkable ct-green-text"
                  size="large"
                  name={"plus cart"}
                  onClick={() => {
                    handleChangeValue("add");
                  }}
                ></Icon>
              </div>
            }
          </div> :
            association.requested === true ? <div className={`requested-for-inventory fxDisplay fxFixed fxJustifyCenter item-fade-out`}
              style={{ paddingRight: "12px", marginTop: "-7px" }} >
              <Image style={{ height: "20px", padding: "0px" }} src={"/bell.png"} />
              <span style={{ paddingLeft: "6px" }}>Noted! Your interest is registered.</span>
            </div> : <div
              className={`request-for-inventory fxDisplay fxFixed fxJustifyCenter item-fade-out`}
              style={{ paddingRight: "12px", marginTop: "-7px" }} onClick={() => {
                requestToGet(association.id)
              }
              }>
              <Image style={{ height: "20px", padding: "0px" }} src={"/bell.png"} />
              <span style={{ paddingLeft: "6px", paddingTop: "2px" }}>I Want This!</span>
            </div>}
        </div>
      </Transition >
      <Transition
        animation="scale"
        duration={{ hide, show }}
        visible={expanded}
      >
        <div style={!expanded ? { position: "absolute" } : undefined}>
          <div style={{ textAlign: "center" }}>
            <Button
              icon="exchange"
              content="Turn back"
              className="button-as-link"
              onClick={() => setExpanded(!expanded)}
              style={{ color: "#21973f" }}
              cont="true"
            ></Button>
            <Divider />
            {association.title}
          </div>
          <div
            style={{
              overflow: "hidden",
              overflowY: "auto",
              maxHeight: "270px",
            }}
          >
            <Divider style={{ marginBottom: "0px" }} />
            <List divided verticalAlign="middle" style={{ marginTop: "0px", paddingTop: "3px" }}>
              {items.map((x, i) => (
                <List.Item key={i}>
                  {markets.filter(f => f.type_id === x.marketId).length > 0 ? <Fragment>
                    <List.Content floated="right" style={{ paddingTop: "6px" }}>
                      {currencyFormat(x.currentPrice, true)}
                    </List.Content>
                    <Image
                      src={getMarketImageUrl(
                        markets.filter((f) => f.type_id === x.marketId)[0]
                      )}
                      size="tiny"
                      className={`inline-superMarketImage`}
                      alt={
                        markets.filter((f) => f.type_id === x.marketId)[0].title
                      }
                      title={
                        markets.filter((f) => f.type_id === x.marketId)[0].title
                      }
                    />
                  </Fragment> :
                    <Fragment>
                      <List.Content floated="right" style={{ paddingTop: "3px" }}>
                        <div className="fxDisplay fxFixed fxJustifyEnd">
                          {association.catalogue && <div
                            className="fxDisplay fxFixed fxJustifyBetween">
                            <div>
                              <Popup
                                trigger={
                                  <Button
                                    style={{ padding: "6px 5px" }}
                                    size="mini"
                                    className='borderless ct-green-button-active'
                                    inverted
                                    disabled={(basketItems.filter(f => f.productId === association.catalogue?.id)[0]?.quantity ?? 0) === 0}
                                    icon={"minus"}
                                    onClick={() => {
                                      handleChangeValue("remove");
                                    }}
                                  />
                                }
                                content={"Remove from cart"}
                                on="hover"
                              />
                            </div>

                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "400",
                                margin: "3px 3px 0px 0px",
                              }}
                            >
                              {basketItems.filter(f => f.productId === association.catalogue?.id)[0]?.quantity ?? 0}
                            </div>
                            <div>
                              <Popup
                                trigger={
                                  <Button
                                    style={{ padding: "6px 5px" }}
                                    className='borderless ct-green-button-active'
                                    disabled={(association.catalogue.largeContainer && largeItemLimit) || (association.catalogue.bottleLimit && bottleLimitPass)}
                                    size="mini"
                                    inverted
                                    icon={"plus"}
                                    onClick={() => {
                                      handleChangeValue("add");
                                    }}
                                  />
                                }
                                content={"Add more"}
                                on="hover"
                              />
                            </div>
                          </div>}
                          <div style={{ paddingTop: "3px" }}>
                            {currencyFormat(x.currentPrice, true)}
                          </div>
                        </div>
                      </List.Content>
                      <Image
                        src={"/cheaptrolley.svg"}
                        size="tiny"
                        className={`inline-superMarketImage`}
                        alt={
                          "Cheap Trolley Logo"
                        }
                        title={
                          "Cheap Trolley"
                        }
                      />
                    </Fragment>}
                </List.Item>
              ))}
            </List>
          </div>
        </div>
      </Transition>
    </div >
  );
};

export default observer(CatalogueGroupedProductCard);
