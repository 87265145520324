import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { Checkbox, Grid, Header, Placeholder } from "semantic-ui-react";

const DeliveryEditor = () => {
  const context = useContext(RootStoreContext);
  const {
    loading,
  } = context.profileStore;
  // const { user, isOwner } = context.userStore;
  const { delivery, updateDelivery } = context.profileStore;

  return (
    <Grid.Row columns={3} className="no-padding">
      <Grid.Column textAlign="left" width={4}>
        <Header as="h4">Allow Deliveries</Header>
      </Grid.Column>
      <Grid.Column textAlign="left" width={9}>
        {loading ? (
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        ) : (
          <Checkbox toggle defaultChecked={delivery} onChange={(event, data) => updateDelivery(data.checked ?? false)} />
        )}
      </Grid.Column>
      <Grid.Column width={3}>

      </Grid.Column>
    </Grid.Row>
  );
};

export default observer(DeliveryEditor);
