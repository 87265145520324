import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import ProfileFieldUpdate from "./ProfileFieldUpdate";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { ConstractFullName } from "../../../helpers/textHelper";

const NamesEditor = () => {
  const context = useContext(RootStoreContext);
  const {
    profile,
    loading,
    currentlyEditing,
    setCurrentlyEditing,
    updateUserName,
  } = context.profileStore;
  const { user } = context.userStore;
  const { isMobile } = context.deviceStore;

  return (
    <ProfileFieldUpdate
      fullWidthOnEdit={isMobile}
      loading={loading}
      allowEdit={profile?.id === user?.id}
      groupFieldLabel={"Name"}
      editMode={currentlyEditing === "name"}
      fieldName="name"
      editClicked={(value: string) => {
        setCurrentlyEditing(value);
      }}
      cancelClicked={() => {
        setCurrentlyEditing("");
      }}
      update={(user: any) =>
        updateUserName(user.firstName, user.lastName, user.middleName).catch(
          (error) => ({
            [FORM_ERROR]: error,
          })
        )
      }
      validate={combineValidators({
        firstName: isRequired("firstName"),
        lastName: isRequired("lastName"),
      })}
      initialValues={profile ?? undefined}
      displayValue={
        profile &&
        ConstractFullName(
          profile.firstName,
          profile.lastName,
          profile.middleName
        )
      }
      fields={[
        {
          display: "First Name",
          placeholder: "First Name",
          name: "firstName",
          showDisplay: true,
        },
        {
          display: "Middle Name",
          placeholder: "Middle Name",
          name: "middleName",
          showDisplay: true,
        },
        {
          display: "Last Name",
          placeholder: "Last Name",
          name: "lastName",
          showDisplay: true,
        },
      ]}
    />
  );
};

export default observer(NamesEditor);
