import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { Table, Image, Header, Popup, Button, Icon } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { currencyFormat } from '../../products/functions/productHelper';
import { generateQuantity } from '../../../helpers/productTitleHelper';

interface IProps {
    itemId: string
}

const ShoppingCartTableRow: React.FC<IProps> = ({ itemId }) => {
    const context = useContext(RootStoreContext);
    const {
        catalogueItems,
        syncCart,
        incereaseQuantity,
        removeFromCart,
        getItemCost,
        removeItemFromCart,
        basketItems,
        orderDetails,
        largeItemLimit,
        bottleLimit,
        largeItemLimitPass,
        bottleLimitPass
    } = context.shoppingCartStore;
    const { measurements } = context.cacheStore;

    const [timer, setTimer] = React.useState(0);
    const [product] = React.useState(catalogueItems.filter(f => f.id === itemId)[0])

    const syncCartHandle = () => {
        syncCart(itemId).then(() => clearTimeout(timer))
    }

    const handleChangeValue = (type: 'add' | 'remove') => {
        if (type === "add")
            incereaseQuantity(itemId);
        else {
            removeFromCart(itemId);
        }

        if (timer != null) {
            clearTimeout(timer);
            setTimer(0);
        }

        setTimer(
            setTimeout(syncCartHandle, 1000, null)
        );
    }

    return (
        basketItems.filter(f => f.productId === itemId).length > 0 ? <Table.Row>
            <Table.Cell style={{ fontWeight: "400", paddingTop: "0px", paddingBottom: "0px" }}>
                <Header as="h4" image style={{ marginBottom: "0px" }}>
                    <Popup
                        trigger={
                            <Image
                                style={{ height: "55px", maxHeight: "55px", objectFit: "contain" }}
                                // size='mini'
                                src={product.imageUrl ?? `${process.env.REACT_APP_CLOUDFRONT}/image-not-found-ctl.png`}
                                alt={product.imageUrl !== undefined ? product.title : "Image not found"}
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found-ctl.png`;
                                }}
                            />
                        }
                        position='right center'
                        content={
                            <Image
                                style={{ maxHeight: "300px" }}
                                src={product.imageUrl ?? `${process.env.REACT_APP_CLOUDFRONT}/image-not-found-ctl.png`}
                                alt={product.imageUrl !== undefined ? product.title : "Image not found"}
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found-ctl.png`;
                                }}
                            />
                        }
                    />

                    <Header.Content>
                        {`${product.title} - ${generateQuantity(measurements, product.measurment, product.measurementUnit)}`}
                        {(!product.active || product.deleted === true || getItemCost(itemId) === 0) && <Header.Subheader>
                            <span style={{ color: 'red' }} >This product is not available for order</span>
                        </Header.Subheader>}
                    </Header.Content>

                </Header>
            </Table.Cell>
            <Table.Cell>
                <div
                    className="fxDisplay fxFixed fxJustifyBetween"
                    style={{ paddingLeft: "8px", paddingRight: "8px" }}
                >
                    <div>
                        <Popup
                            trigger={
                                <Button
                                    className='borderless'
                                    size="mini"
                                    disabled={!product.active || getItemCost(itemId) === 0 || product.deleted === true || orderDetails !== undefined || (product.largeContainer && largeItemLimit) || (product.bottleLimit && bottleLimit)}
                                    inverted
                                    circular
                                    color="green"
                                    icon={"plus"}
                                    onClick={() => {
                                        handleChangeValue("add");
                                    }}
                                />
                            }
                            content={"Add more"}
                            on="hover"
                        />
                    </div>

                    <div
                        className={(!product.active || getItemCost(itemId) === 0 || product.deleted === true) ? 'disabled-text' : ''}
                        style={{
                            fontSize: "15px",
                            fontWeight: "400",
                            marginTop: "5px",
                            color: (product.largeContainer === true && largeItemLimitPass) || (product.bottleLimit === true && bottleLimitPass) ? "red" : "black"
                        }}
                    >
                        {basketItems.filter(f => f.productId === itemId)[0]?.quantity}
                    </div>

                    <div>
                        <Popup
                            trigger={
                                <Button
                                    size="mini"
                                    className='borderless'
                                    circular
                                    inverted
                                    disabled={(basketItems.filter(f => f.productId === itemId)[0]?.quantity ?? 0) === 0 || !product.active || getItemCost(itemId) === 0 || product.deleted === true || orderDetails !== undefined}
                                    color="red"
                                    icon={"minus"}
                                    onClick={() => {
                                        handleChangeValue("remove");
                                    }}
                                />
                            }
                            content={"Remove from cart"}
                            on="hover"
                        />
                    </div>
                </div>
            </Table.Cell>
            <Table.Cell textAlign='right'
                className={(!product.active || getItemCost(itemId) === 0 || product.deleted === true) ? 'disabled-text' : ''}
                style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    marginTop: "5px",
                }}>
                {currencyFormat(getItemCost(itemId), true)}
            </Table.Cell>
            <Table.Cell>
                <Icon name={"trash alternate outline"}
                    color='red'
                    className='clickable'
                    // circular={!product.active}
                    disabled={orderDetails !== undefined}
                    inverted={product.active && (product.deleted ?? false) === false && getItemCost(itemId) > 0}
                    onClick={() => removeItemFromCart(itemId)} />
            </Table.Cell>
        </Table.Row> : <Fragment />
    );
}

export default observer(ShoppingCartTableRow)