import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import {
  Dimmer,
  Divider,
  Dropdown,
  DropdownProps,
  List,
  Loader,
  Menu,
  Segment,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClaimRequestStatus } from "../../options/ClaimRequestStatus";
import IntegrationRequestCard from "./components/IntegrationRequestCard";

const IntegrationRequests = () => {
  const context = useContext(RootStoreContext);
  const {
    load,
    dispose,
    loading,
    integrations,
    hasMore,
    search,
    statuses,
    setStatuses,
  } = context.integrationRequestListStore;
  const { isMobile } = context.deviceStore;

  const [timer, setTimer] = React.useState(0);

  useEffect(() => {
    load();

    return () => {
      dispose();
    };
  }, [load, dispose]);

  return (
    <div className="simply-paddedPage">
      <Menu
        secondary
        size={isMobile ? "massive" : "small"}
        style={{ margin: "5px 0" }}
      >
        <Menu.Item style={{ padding: "0px" }}>
          <Dropdown
            placeholder="Select a status"
            value={statuses}
            closeOnBlur
            selection
            multiple
            clearable
            search
            onChange={(ev, data: DropdownProps) => {
              setStatuses((data?.value ?? []) as string[]);
              if (!search) return;
              if (timer != null) {
                clearTimeout(timer);
                setTimer(0);
              }

              setTimer(
                setTimeout(
                  (callback: () => void, text: string) => {
                    callback();
                    clearTimeout(timer);
                  },
                  500,
                  search
                )
              );
            }}
            options={ClaimRequestStatus}
          />
        </Menu.Item>
      </Menu>
      <Divider />

      {!loading || integrations.length > 0 ? (
        <div>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={integrations.length} //This is important field to render the next data
            next={() => load()}
            hasMore={hasMore}
            loader={loading ? <h4>Loading...</h4> : <div />}
          >
            <List
              divided
              relaxed
              style={isMobile ? { padding: "7px" } : undefined}
              selection
            >
              {integrations.map((x) => (
                <IntegrationRequestCard key={x.id} request={x} />
              ))}
            </List>
          </InfiniteScroll>
        </div>
      ) : (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      )}
    </div>
  );
};

export default observer(IntegrationRequests);
