import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import agent from '../../../api/agent'
import { IProposedSlot } from '../../../interfaces/delivery'
import { Button, Dropdown, DropdownProps, Header, Menu, Segment } from 'semantic-ui-react'
import { Countries } from '../../../options/Countries'
import { format } from 'date-fns'
import { RootStoreContext } from '../../../stores/RootStoreContext'


const OpenDeliverySlots = () => {
    const context = useContext(RootStoreContext)
    const { closeModal } = context.modalStore;

    const [slots, setSlots] = React.useState<IProposedSlot[]>([])

    useEffect(() => {
        return () => {
            setSlots([])
        }
    }, [])


    return (
        <div>
            <Menu secondary size='small'>
                <Menu.Item style={{ paddingLeft: "0px" }}>
                    <Dropdown
                        placeholder={"Select Country.."}
                        closeOnBlur
                        selection
                        clearable
                        search
                        onChange={(ev, data: DropdownProps) => {
                            if (data?.value === undefined || data?.value === '') {
                                setSlots([])
                                return;
                            }
                            agent.Scheduler.Slots.prepare(data?.value as number).then((result) => setSlots(result));
                        }}
                        options={Countries.filter((f) => f.active === true)
                            ?.slice()
                            .sort((a, b) => (a.text < b.text ? -1 : 1))
                        }
                    />
                </Menu.Item>

                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Button
                            disabled={slots.length === 0}
                            className='ct-green-button-active'
                            onClick={() => {
                                agent.Scheduler.Slots.create(slots).then(() => closeModal());
                            }}
                        >
                            {`Create (${slots.length})`}
                        </Button>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>


            {slots.map((x) =>
                <Segment key={x.id}>
                    <div className='fxFixed fxDisplay'>
                        <div className='fxStretch' style={{ paddingTop: "6px" }}>
                            <Header>
                                {format(new Date(x.startsOn * 1000), "EEEE, do MMM HH:mm")} - {format(new Date(x.endsOn * 1000), "HH:mm")}
                                <Header.Subheader>
                                    {x.name}
                                </Header.Subheader>
                            </Header>
                        </div>
                        <div style={{ paddingTop: "5px" }}>
                            <Button
                                color='red'
                                circular
                                size="small"
                                icon={"minus"}
                                onClick={() => {
                                    setSlots(slots.slice().filter(f => f.id !== x.id))
                                }}
                            />
                        </div>
                    </div>
                </Segment>)
            }
        </div >
    )
}

export default observer(OpenDeliverySlots)