import { action, makeObservable, observable } from "mobx";
import agent from "../api/agent";
import { getBase64 } from "../helpers/urlImageHelper";
import { ProductCategory, SmartProductCategory } from "../interfaces/categories";
import { IMeasurement } from "../interfaces/measurements";
import { ISuperMarket } from "../interfaces/supermarket";
import { RootStore } from "./RootStoreContext";
import { IBrandForList } from "../interfaces/brand";
import { Referral } from "../interfaces/referral";

export class CacheStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable savingsOpen: boolean = true;
    @observable markets: ISuperMarket[] = []
    @observable referrals: Referral[] = []
    @observable categories: ProductCategory[] = [];
    @observable smartCategories: SmartProductCategory[] = [];
    @observable measurements: IMeasurement[] = [];
    @observable brands: IBrandForList[] = [];

    @action setCategories = (value: ProductCategory[]) => this.categories = value.sort((n1, n2) => n1.order - n2.order);
    @action setSmartCategories = (value: SmartProductCategory[]) => this.smartCategories = value;
    @action setMeasurements = (value: IMeasurement[]) => this.measurements = value;
    @action setBrands = (value: IBrandForList[]) => this.brands = value;
    @action setReferrals = (value: Referral[]) => this.referrals = value;

    @action loadCache = async () => {
        await agent.Supermarkets.get().then((markets) => this.setMarkets(markets));
        if (this.categories.length === 0)
            agent.ProductCategories.getMain().then(value => this.setCategories(value));
        if (this.smartCategories.length === 0)
            agent.ProductCategories.get_smart().then(value => this.setSmartCategories(value));
        if (this.measurements.length === 0)
            agent.ProductMeasurements.get().then(value => this.setMeasurements(value));
        if (this.brands.length === 0)
            agent.Brands.get_all().then(value => this.setBrands(value));
        if (this.referrals.length === 0)
            agent.Referrals.getAll().then(value => {
                if (value !== undefined && value !== null && value.length > 0) {
                    value.unshift({
                        key: "none",
                        title: "None"
                    })
                }
                this.setReferrals(value)
            });


    }

    @action toggleSavings = () => {
        this.savingsOpen = !this.savingsOpen;
    }

    @action refreshCategories = async () => {
        agent.ProductCategories.getAll().then(value => this.setCategories(value));
    }

    @action setMarkets = (value: ISuperMarket[]) => {
        this.markets = value

        value.filter(f => f.createdOn).forEach(shop => {
            if (shop.imageUrl && !shop.imageUrl.startsWith("http")) {
                var storageItem = localStorage.getItem(shop.imageUrl);

                if (!storageItem)
                    agent.AWS.Bucket.getFileUrl(shop.imageUrl).then((dataUrl) => {
                        fetch(dataUrl).then((r) => {
                            r.blob().then((blobFile) =>
                                getBase64(blobFile, (base64: string) => {
                                    localStorage.setItem(
                                        shop.imageUrl!,
                                        JSON.stringify({
                                            value: base64,
                                            expiry: new Date().getTime() / 1000 + 86400 * 3,
                                        })
                                    );
                                })
                            );
                        });
                    });
            }
        });
    };
}