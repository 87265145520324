import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Header, Icon, Table, Image, Popup, List, Label } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { currencyFormat } from "../../products/functions/productHelper";
import { ICatalogueForList } from "../../../interfaces/catalogue";
import { Guid } from "../../../helpers/guid";
import CameraCapture from "../../../common/CameraCapture";

interface IProps {
  product: ICatalogueForList;
}

const AdminCatalogueTableRow: React.FC<IProps> = ({ product }) => {
  const context = useContext(RootStoreContext);
  const { openModal, closeModal } = context.modalStore;
  const { measurements } = context.cacheStore;
  const {
    setEditingId,
    locations,
    editingId,
    refreshCatalogue,
    refreshingId,
    changeAvailability,
    toggleHasImage,
    disposeDetail,
    categories,
    VATCategories
  } = context.catalogueAdminStore;

  const generateQuantity = (measurement?: number, measurementUnit?: string): string | undefined => {
    let measurementItem = measurements.find(f => f.key === measurementUnit)?.description ?? "-";
    let valueItem = measurement ?? 0;

    if (valueItem === 0) return undefined;

    let valueItemStr = `${valueItem}`
    if (valueItem >= 1000 && (measurementUnit === "grams" || measurementUnit === "ml")) {
      valueItemStr = (valueItem / 1000).toFixed(2); // shorthand for division and assignment
      switch (measurementUnit) {
        case "grams":
          measurementItem = "kg";
          break;
        case "ml":
          measurementItem = "Lt";
          break;
        default:
          break;
      }
    }

    return `${valueItemStr}${measurementItem}`;
  };

  return (
    <Table.Row key={Guid.newGuid()} error={product.deleted || !product.associationId} active={editingId === product.id} onClick={() => {
      if (editingId !== undefined) {
        disposeDetail();
        setEditingId(product.id);
      }
    }}>
      <Table.Cell style={{ paddingTop: "0px", paddingRight: "0px" }}>
        <Header as="h4" image style={{ width: "100%" }}>
          {(product.measurment ?? 0) > 0 && <div className="catalogue-quantity" style={{ top: "2px" }} >
            {generateQuantity(product.measurment, product.measurementUnit)}
          </div>}
          <Popup
            trigger={
              <Image
                src={product.imageUrl ?? `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`}
                disabled={product.deleted}
                alt={product.imageUrl !== undefined ? product.title : "Image not found"}
                size="mini"
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                }}
              />
            }
            content={
              <Image
                src={product.imageUrl ?? `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`}
                alt={product.imageUrl !== undefined ? product.title : "Image not found"}
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                }}
              />
            }
          />

          <Header.Content className={!product.active ? "disabled-text" : ""}>
            {product.title} {product.free_delivery && <Label color="purple" content="Free Delivery" />}
            <Header.Subheader>
              <span style={(product.brandTags?.length ?? 0) === 0 ? { color: "red" } : {}}><b>Brands :</b> {product.brandTags?.length ?? 0}</span>
              <span style={!product.vat ? { color: "red", marginLeft: "4px" } : { marginLeft: "4px" }}><b>Vat :</b> {VATCategories.filter(f => f.id === product.vat)[0]?.title ?? "-"}</span>
              {!product.associationId && <Label color="red" content="Related Association Deleted" />}
              {product.ct_categories && categories &&
                <Fragment>
                  <br />
                  <List horizontal> {product.ct_categories.map((x) =>
                    <List.Item key={`${product.id}_${x}`}>{categories.filter(f => f.id === x)[0]?.description ?? x}
                    </List.Item>)}
                  </List>
                </Fragment>}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell className={!product.active ? "disabled-text" : ""}>
        <List horizontal>
          {locations.length > 0 && product.prices.slice().sort((a, b) => a.price < b.price ? -1 : 1).map((x, i) =>
            <List.Item key={`${Guid.newGuid()}`} style={{ textAlign: 'center', marginRight: "10px" }} disabled={locations.filter(s => x.locations.includes(s.id!) && !s.hasDelivery).length > 0}>
              <div className="fxDirCol" style={{ width: "65px" }}>
                <div>{x.locations.map(l => locations.filter(s => s.id === l)[0].title)}</div>
                <div style={{ fontSize: "16px", marginTop: "4px", fontWeight: "600" }}>{currencyFormat(x.price, true)}</div>
                <div style={{ fontSize: "12px", fontWeight: "100", color: (x.profit && x.profit > 0 ? "green" : "red") }}>{currencyFormat(x.profit ?? 0, true)}</div>
              </div>
            </List.Item>
          )}</List>
      </Table.Cell>
      <Table.Cell textAlign='center'>
        <Icon name="camera" size='big' color={product.hasImage === true ? "green" : "red"} onClick={() => openModal(<CameraCapture catalogueId={product.id} closeModal={closeModal} />, "fullscreen", undefined, false, false)} />
        <Icon name="image" size='big' color={product.hasImage === true ? "green" : "red"} style={{ marginLeft: "10px" }} onClick={() => toggleHasImage(product.id)} />
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Icon
          className="clickable"
          size="large"
          name={product.available ? "check" : "close"}
          color={product.available ? "green" : "red"}
          onClick={() => {
            changeAvailability(product.id, !product.available)
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Icon
          className="clickable"
          size="large"
          name={product.verified ? "check" : "close"}
          color={product.verified ? "green" : "red"}
          onClick={() => {
            if (!product.verified)
              window.open(`${window.location.origin}/group-verification?associationId=${product.associationId}`, "_blank")
            else {
              window.open(`${window.location.origin}/compare?id=${product.associationId}`, "_blank")
            }
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="center" verticalAlign="middle">
        <Icon
          disabled={!product.associationId}
          inverted
          loading={product.id === refreshingId}
          name="refresh"
          color="green"
          circular
          onClick={() => {
            refreshCatalogue(product.id);
          }}
        />

        <Icon
          inverted
          name="pencil"
          color="blue"
          circular
          onClick={() => {
            setEditingId(product.id);
          }}
        />

        <Icon
          inverted
          name="external square alternate"
          color="orange"
          circular
          onClick={() => {
            window.open(`${window.location.origin}/compare?id=${product.associationId}`, "_blank")
          }}
        />
      </Table.Cell>
    </Table.Row >
  );
};

export default observer(AdminCatalogueTableRow);
