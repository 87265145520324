import React, { useContext } from "react";
import { observer } from "mobx-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { List, ListHeader, ListItem } from "semantic-ui-react";
import SearchSlider from "./SearchSlider";
interface Props {
  scroll?: () => void;
}
const SearchSuggestionBox: React.FC<Props> = ({ scroll }) => {
  const context = useContext(RootStoreContext);
  const { search, selectedBrands, selectedCategories } = context.shoppingCatalogueStore;
  const { suggestions, state, setDrawerState } = context.searchStore;
  function checkDrawerOnPost() {
    if (state.isDrawerOpen === true) {
      setDrawerState(false);
    }
  }
  function onClick(wordId?: string, term?: string) {
    // Post call for suggestion clicking goes here
    search(term, selectedBrands, selectedCategories, wordId ? [wordId] : undefined);
    if (scroll) {
      scroll();
      checkDrawerOnPost();
    }
  }
  let productsSection = (
    <List divided relaxed>
      {state.searchTerm !== "" ? (
        <ListItem key={state.searchTerm}>
          <ListHeader as="a" onClick={() => onClick(undefined, state.searchTerm)}>{state.searchTerm}</ListHeader>
        </ListItem>
      ) : (
        ""
      )}
      {suggestions.words.map((word, index: React.Key | null | undefined) => (
        <ListItem key={index}>
          <ListHeader as="a" onClick={() => onClick(word.id, word.key)}>{word.key}</ListHeader>
        </ListItem>
      ))}
    </List>
  );
  return (
    <div className={`drawer ${state.isDrawerOpen ? "active" : "inactive"}`}>
      {state.isDrawerOpen ? (
        <ul>
          <SearchSlider
            selectedBrands={suggestions.brands}
            selectedCategories={suggestions.categories}
            onBrandClick={
              (brandId: string) => {
                search(undefined, [brandId], undefined, undefined, suggestions.brands.filter(f => f.id === brandId))
                if (scroll) {
                  scroll();
                  checkDrawerOnPost()
                }
              }
            }
            onCategoryClick={
              (categoryId: string) => {
                search(undefined, undefined, [categoryId], undefined, undefined)
                if (scroll) {
                  scroll();
                  checkDrawerOnPost();
                }
              }
            }
          />
          {productsSection}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(SearchSuggestionBox);
