import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { ICatalogueForReplacement } from "../../../interfaces/catalogue";
import { IShopLocation } from "../../../interfaces/locations";
import { IExtraItem, IVATCategory } from "../../../interfaces/vat";
import { IHubOrder } from "../../../interfaces/order";

export class HubOrderReplacementStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable isLoading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable searchText: string = "";
    @observable products: ICatalogueForReplacement[] = []
    @observable hubOrders: IHubOrder[] = [];
    @observable locations: IShopLocation[] = [];
    @observable VATCategories: IVATCategory[] = [];
    @observable Extras: IExtraItem[] = [];

    @action setLoading = (value: boolean) => this.isLoading = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setProducts = (value: ICatalogueForReplacement[]) => {
        var existing = this.products.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.products.push(...missing);
    };
    @action setLocations = (value: IShopLocation[]) => this.locations = value;
    @action setHubOrders = (value: IHubOrder[]) => this.hubOrders = value;
    @action setVATCategories = (value: IVATCategory[]) => this.VATCategories = value;
    @action setExtraItems = (value: IExtraItem[]) => this.Extras = value;

    @action oneTimeLoad = async () => {
        var locations = await agent.Shop.Locations.Admin.all();
        this.setLocations(locations);

        var hub_orders = await agent.HubOrders.get(["sent_to_market", "in_process", "ready_for_delivery", "received", "seen"]);
        this.setHubOrders(hub_orders);
        agent.Catalogue.VAT.get(129).then(value => this.setVATCategories(value)).catch(() => { });
        agent.Catalogue.Extras.get(129).then(value => this.setExtraItems(value)).catch(() => { });
    }

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Catalogue.Admin.get_replacement(this.searchText, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setProducts(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }
    }

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.products = [];

            this.setCurrentPage(0)

            this.load();
        } catch (error) {
        }
    };
}
