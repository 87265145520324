import { action, makeObservable, observable } from "mobx";
import { RootStore } from "../../../stores/RootStoreContext";
import agent from "../../../api/agent";
import { IUserWallet } from "../../../interfaces/wallet";

export class ClientCreditStore {
    rootStore: RootStore;

    @observable currentlyEditing: string = "";
    @observable wallet?: IUserWallet = undefined;
    @observable loading: boolean = false;
    @observable balance: number = 0;
    @observable preferredStores: string[] = [];


    @action setWallet = (values?: IUserWallet) => this.wallet = values;
    @action setLoading = (value: boolean) => this.loading = value;
    @action setBalance = (value: number) => this.balance = value;


    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @action getWallet = async (userId?: string) => {
        try {
            this.setLoading(true);

            if (!userId) {
                userId = this.rootStore.userStore.user?.id;
            }

            if (!userId) return;

            var wallet = await agent.Users.Wallet.get(userId);

            this.setWallet(wallet);

        } catch (error) {
        } finally {
            this.setLoading(false);
        }
    }

    @action getWalletBalance = async (userId?: string) => {
        try {
            this.setLoading(true);

            if (!userId) {
                userId = this.rootStore.userStore.user?.id;
            }

            if (!userId) return;

            agent.Users.Wallet.getBalanceOnly(userId).then((balance) => this.setBalance(balance ?? 0));

        } catch (error) {
        } finally {
            this.setLoading(false);
        }
    }

    @action dispose = () => {
        this.setWallet(undefined);
        this.setLoading(false);
    }
}