import { observer } from "mobx-react-lite";
import React from "react";
import EmailEditor from "react-email-editor";
import { Button, Grid } from "semantic-ui-react";

interface IProps {
    closeCallback: () => void;
    form?: any;
    values?: any;
    design?: any;
    saveCallback: (data: any, form?: any, values?: any) => void;
}

const HTMLAdvancedEditor: React.FC<IProps> = ({
    closeCallback,
    design,
    saveCallback,
    form,
    values,
}) => {
    const emailEditorRef = React.useRef(null);

    const onLoad = () => {
        if (design && emailEditorRef.current) {
            (emailEditorRef.current as any).editor.loadDesign(design);
            // registerTool((emailEditorRef.current as any).editor);
        } else if (design)
            setTimeout(
                () => {
                    if (emailEditorRef.current) {
                        (emailEditorRef.current as any).editor.loadDesign(design);
                        // registerTool((emailEditorRef.current as any).editor);
                    }
                },
                1500,
                emailEditorRef
            );
    };

    return (
        <Grid centered>
            <Grid.Row centered>
                <Grid.Column width={16}>
                    <EmailEditor ref={emailEditorRef} onLoad={onLoad} />
                    <div style={{ textAlign: "center" }}>
                        <Button.Group style={{ paddingTop: "15px" }}>
                            <Button
                                color="blue"
                                content={"Save"}
                                onClick={() => {
                                    (emailEditorRef.current as any)?.editor.exportHtml(
                                        (data: any) => {
                                            saveCallback(data, form, values);
                                        }
                                    );
                                }}
                            />
                            <Button.Or />
                            <Button
                                onClick={() => closeCallback()}
                                content={'Cancel'}
                            />
                        </Button.Group>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default observer(HTMLAdvancedEditor);