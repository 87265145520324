import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, Fragment, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { Button, Dimmer, Divider, Dropdown, DropdownProps, Icon, Input, List, Loader, Menu, Popup, Segment, Table } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import PriceTrackerTableRow from './PriceTrackerTableRow';
import CsvExport from '../../../common/csvExport';
import { NoResults } from '../../../common/NoResults';

interface IProps {
    shopId?: string;
}

const PriceTracker: React.FC<IProps> = ({ shopId }) => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const { accountId, isAdmin } = context.userStore;
    const { toggleFullScreen, isDeliveryEnabled } = context.accountPageStore;
    const { setPage } = context.analyticsStore;
    const {
        selectedCategories,
        setSelectedCategories,
        searchText,
        load_once,
        loading,
        products,
        load,
        hasMore,
        dispose,
        search,
        setSelectedMarketIds,
        shopCategories,
        exportDataForCsv,
        downloading,
        discontinued,
        changeDiscontinued,
        percentage,
        changePercentage,
        groupedOnly,
        nonDelivery,
        setGroupedOnly,
        setNonDelivery,
        selectedMarketIds,
        markets
    } = context.priceTrackerStore;

    const [timer, setTimer] = React.useState(0);

    useEffect(() => {
        load_once(shopId);
        load(shopId);
        setPage("PriceTracker", window.location.pathname);

        return () => {
            dispose();
        };
    }, [load, navigate, dispose, setPage, load_once, shopId]);

    return (
        <div className="simply-paddedPage">
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "5px 0" }}
            >
                <Menu.Item
                    className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}
                >
                    <Input
                        fluid={isMobile}
                        transparent={isMobile}
                        size={isMobile ? "large" : "small"}
                        icon={!isMobile ? <Icon name="search" color="blue" /> : undefined}
                        placeholder={"Search..."}
                        input={<input className={isMobile ? "" : "bluePlaceholder"} />}
                        onChange={(event: ChangeEvent, data: any) => {
                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }
                            setTimer(
                                setTimeout(
                                    (callback: (text: string) => void, text: string) => {
                                        callback(text);
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search,
                                    data.value
                                )
                            );
                        }}
                    />
                </Menu.Item>

                <Menu.Item style={{ padding: "0px" }}>
                    <Dropdown
                        placeholder={"Filter categories"}
                        value={selectedCategories}
                        closeOnBlur
                        selection
                        multiple
                        clearable
                        search
                        renderLabel={(
                            item: any,
                            index: number,
                            defaultLabelProps: any
                        ) => {
                            if (index === 0) return { content: item.text };
                            if (index === 1) return { content: item.text };

                            return index === 2
                                ? {
                                    color: defaultLabelProps.color,
                                    content: `and ${selectedCategories.length - 2
                                        } more category(ies)`,
                                }
                                : undefined;
                        }}
                        onChange={(ev, data: DropdownProps) => {
                            setSelectedCategories((data?.value ?? []) as string[]);
                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: (text: string) => void, text: string) => {
                                        callback(searchText);
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search
                                )
                            );
                        }}
                        options={shopCategories
                            ?.slice()
                            .sort((a, b) => (a.internalId < b.internalId ? -1 : 1))
                            .map((x) => ({
                                key: x.internalId,
                                text: x.internalName,
                                value: x.internalId,
                            }))}
                    />
                </Menu.Item>
                <Menu.Item style={{ padding: "0px" }}>
                    <div className="fxFixed fxDisplay fxAlignCenter">
                        <div className="catalogue-sort-by-holder">Shops |</div>
                        <Popup
                            on="click"
                            closeOnTriggerClick
                            closeOnDocumentClick
                            closeOnPortalMouseLeave
                            closeOnTriggerBlur
                            closeOnTriggerMouseLeave
                            closeOnEscape
                            position="bottom center"
                            trigger={
                                <div className="fxFixed fxDisplay">
                                    <div className="fxStrech catalogue-sort-by-selected">
                                        {selectedMarketIds.length === 0 ? "All" : selectedMarketIds.length > 2 ? `${selectedMarketIds.length} shops` :
                                            markets.filter(f => selectedMarketIds.includes(f.type_id)).map(x => x.title).join(',')}
                                    </div>
                                    <Icon name="angle down" />
                                </div>}
                            content={
                                <List size="medium">
                                    {markets
                                        .filter(f => f.id !== accountId && !f.shutDown)
                                        ?.slice()
                                        .sort((a, b) => (a.title < b.title ? -1 : 1)).map((f, i) => (
                                            <List.Item
                                                key={f.type_id}
                                                onClick={() => {
                                                    if (selectedMarketIds.includes(f.type_id)) {
                                                        setSelectedMarketIds(selectedMarketIds.filter(x => x !== f.type_id));
                                                    }
                                                    else {
                                                        setSelectedMarketIds([...selectedMarketIds, f.type_id])
                                                    }

                                                    if (!search) return;
                                                    if (timer != null) {
                                                        clearTimeout(timer);
                                                        setTimer(0);
                                                    }

                                                    setTimer(
                                                        setTimeout(
                                                            (callback: (text: string) => void, text: string) => {
                                                                callback(searchText);
                                                                clearTimeout(timer);
                                                            },
                                                            500,
                                                            search
                                                        )
                                                    );
                                                }}
                                                className={`${i !== 0 ? "catalogue-sort-by-option" : ""} clickable`}
                                            >
                                                <div className={`fxDisplay fxFixed ${selectedMarketIds.includes(f.type_id) ? "ct-green-text-dark" : ""}`}  >
                                                    <div style={{ width: "18px", textAlign: "start" }}>{selectedMarketIds.includes(f.type_id) && <Icon name={"check"} />}</div>
                                                    <div style={{ marginLeft: "5px" }}>{f.title}</div>
                                                </div>
                                            </List.Item>
                                        ))}
                                </List>
                            }
                        />
                    </div>
                </Menu.Item>

                {isAdmin && <Fragment>
                    <Menu.Item className={!isMobile ? "no-padding" : ""}
                        style={{ flex: isMobile ? 1 : "" }}>
                        <Button
                            color={!groupedOnly ? "blue" : "green"}
                            basic={!groupedOnly}
                            content={"Grouped Only"}
                            onClick={() => {
                                setGroupedOnly(!groupedOnly);
                                search(searchText);
                            }}
                        />
                    </Menu.Item>
                    <Menu.Item className={!isMobile ? "no-padding" : ""}
                        style={{ flex: isMobile ? 1 : "" }}>
                        <Button
                            color={!nonDelivery ? "blue" : "green"}
                            basic={!nonDelivery}
                            content={"Without Delivery"}
                            onClick={() => {
                                setNonDelivery(!nonDelivery);
                                search(searchText);
                            }}
                        />
                    </Menu.Item>
                </Fragment>}


                <Menu.Menu position="right">
                    <Menu.Item icon title="See differences in %" style={{ padding: "0px" }}>
                        <Icon
                            fitted
                            onClick={() => {
                                changePercentage();
                            }}
                            name="percent"
                            color={percentage ? "green" : "black"}
                            disabled={loading}
                            title="See differences in %"
                        />
                    </Menu.Item>
                    <Menu.Item icon title="Include discontinued products" >
                        <Icon
                            fitted
                            onClick={() => {
                                changeDiscontinued();
                            }}
                            name="broken chain"
                            color={discontinued ? "red" : "black"}
                            disabled={loading}
                            title="Include discontinued products"
                        />
                    </Menu.Item>

                    <Menu.Item icon title="Export" style={{ padding: "0px" }}>
                        <CsvExport
                            onClick={exportDataForCsv}
                            content={
                                <Fragment>
                                    <Icon name="file excel outline" /> Export
                                </Fragment>
                            }
                            disabled={downloading}
                            loading={downloading}
                        />
                    </Menu.Item>
                    <Menu.Item icon title="Full screen">
                        <Icon name='expand'
                            color='blue'
                            size='large'
                            onClick={() => { toggleFullScreen() }} />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider style={isMobile ? { padding: "0", margin: "0" } : undefined} />
            {!loading || products.length > 0 ? (
                <div>
                    <InfiniteScroll
                        dataLength={products.length} //This is important field to render the next data
                        next={() => load()}
                        hasMore={hasMore}
                        loader={loading ? <h4>Loading...</h4> : <div />}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                {products.length > 0 ? (
                                    <b></b>
                                ) : (
                                    <NoResults />
                                )}
                            </p>
                        }
                    >
                        <Table celled compact>
                            <Table.Header className='tableTest'>
                                <Table.Row>
                                    {isAdmin && isDeliveryEnabled && <Table.HeaderCell>
                                        Delivery
                                    </Table.HeaderCell>}
                                    <Table.HeaderCell>Product</Table.HeaderCell>
                                    <Table.HeaderCell>{markets.filter(f => f.id === (shopId ?? accountId))[0].title}</Table.HeaderCell>
                                    {(selectedMarketIds.length > 0 ? markets.filter(f => selectedMarketIds.includes(f.type_id)) : markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1)).filter(f => f.id !== (shopId ?? accountId) && !f.shutDown).map(x => <Table.HeaderCell key={x.id}>{x.title}</Table.HeaderCell>)}
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {products.map((x) => (
                                    <PriceTrackerTableRow product={x} key={x.id} accountId={(shopId ?? accountId)!} />
                                ))}
                            </Table.Body>
                        </Table>
                    </InfiniteScroll>
                </div>
            ) : (
                <Segment
                    style={{
                        minHeight: "55vh",
                        boxShadow: "none",
                        border: "none",
                    }}
                >
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                </Segment>
            )}
        </div>
    )
}

export default observer(PriceTracker)