export const ClaimRequestStatus: Array<{
    value: string,
    key: string,
    text: string,
}> =
    [
        {
            "value": "pending_approval",
            "key": "pending_approval",
            "text": "Pending Approval",
        },
        {
            "value": "approved",
            "key": "approved",
            "text": "Approved",
        },
        {
            "value": "rejected",
            "key": "rejected",
            "text": "Rejected",
        }
    ]