import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { ProductCategoryForProcessing } from '../../../interfaces/categories';
import { Header, Icon, Label, Table } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';

interface IProps {
    category: ProductCategoryForProcessing;
    editingId?: string;
    setEditingId: (id: string) => void
}

const CategoryTableRow: React.FC<IProps> = ({ category, editingId, setEditingId }) => {
    const context = useContext(RootStoreContext);
    const { categories, childId, nest, setChildId } = context.categoriesAdminStore;
    return (
        <Table.Row active={editingId === category.id} onClick={() => {
            if (editingId !== undefined)
                setEditingId(category.id);
        }}>
            <Table.Cell>
                <Header as="h4" image>
                    {/* {category.imageUrl && <Popup
                        trigger={
                            <Image
                                src={brand.imageUrl ?? `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`}
                                alt={brand.imageUrl !== undefined ? brand.title : "Image not found"}
                                size="mini"
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                }}
                            />
                        }
                        content={
                            <Image
                                src={brand.imageUrl ?? `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`}
                                alt={brand.imageUrl !== undefined ? brand.title : "Image not found"}
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                }}
                            />
                        }
                    />} */}
                    <Header.Content >
                        {category.description ?? "-"}
                        <Header.Subheader>
                            {`${category.counter} product(s) - ${category.hits.toFixed(2)} hit(s) - Search ${category.smartSearch ? "Yes" : "No"}`}
                        </Header.Subheader>
                    </Header.Content>
                </Header>
            </Table.Cell>
            <Table.Cell>
                {category && category.parents && (category.parents.length ?? 0) > 0 ? <Label.Group>
                    {category.parents?.map(x => <Label content={categories?.filter(f => f.id === x)[0]?.description ?? "-"} color='brown' />)}
                </Label.Group> : <Label content="Is Main" color='green' />}
            </Table.Cell>
            <Table.Cell>
                {category.norm_desc ?? "-"}
            </Table.Cell>
            <Table.Cell singleLine>
                <Icon
                    name="pencil"
                    className="ct-green-button-active"
                    circular
                    onClick={() => {
                        setEditingId(category.id);
                    }}
                />
                <Icon
                    name={childId === category.id ? "minus" : "sitemap"}
                    color={childId === "" ? "green" : (childId === category.id ? "red" : "orange")}
                    // className="ct-green-button"
                    circular
                    inverted={childId !== ""}
                    onClick={() => {
                        if (childId === "") {
                            setChildId(category.id);
                        }
                        else if (childId === category.id) {
                            setChildId("");
                        }
                        else {
                            nest(childId, category.id);
                        }
                    }}
                />

            </Table.Cell>
        </Table.Row >
    );
}

export default observer(CategoryTableRow)