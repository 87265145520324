import { action, makeObservable, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../../../api/agent";
import { IAssociation, IAssociationForSave } from "../../../interfaces/association";
import { IProduct } from "../../../interfaces/product";
import { RootStore } from "../../../stores/RootStoreContext";

export class AssociationStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 1;
    @observable totalPages: number = 0;
    @observable hasMore: boolean = false;
    @observable discontinued: boolean = true;
    @observable mode: "stabbilita_select" | "select" | "group_select" | "group_merge" | "temporary" | undefined = undefined;
    @observable associations: IAssociation[] = []
    @observable selectedMarketIds: string[] = []
    @observable searchText: string = "";
    @observable selectedCategories: string[] = [];

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setTotalPages = (value: number) => { this.totalPages = value };
    @action setDiscontinued = (value: boolean) => { this.discontinued = value };
    @action setMode = (value: "stabbilita_select" | "select" | "group_select" | "group_merge" | "temporary" | undefined = undefined) => { this.mode = value };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action setHasMore = (value: boolean) => { this.hasMore = value };
    @action setSelectedMarketIds = (value: string[]) => { this.selectedMarketIds = value };

    @action setAssociations = (value: IAssociation[]) => this.associations = value;
    @action remove_tmp_flag = async (associationId: string) => {
        runInAction(() => {
            var idx = this.associations.findIndex(f => f.id === associationId);
            this.associations[idx].temporary = false;
        })
    }

    @action pageChange = (newPage: number) => {
        this.setCurrentPage(newPage)
        this.load();
    };

    @action resetAssociations = () => {
        this.associations = [];
    };

    @action removeAssociation = (id: string) => {
        this.associations = this.associations.filter(f => f.id !== id);
    };

    @action updateAssociation = (id: string, item: IAssociationForSave) => {
        var assIdx = this.associations.findIndex(f => f.id === id);

        if (assIdx > -1) {
            this.associations[assIdx].imageUrl = item.imageUrl;
            this.associations[assIdx].title = item.title;
        }
    }

    @action load = async (id?: string, mode?: "stabbilita_select" | "select" | "group_select" | "group_merge" | "temporary" | undefined) => {
        try {
            if (mode !== undefined)
                this.setMode(mode);
            this.setLoading(true);

            var response = (this.mode === "temporary") ?
                await agent.Associations.get_temporary(this.searchText, this.selectedMarketIds, this.currentPage) :
                await agent.Associations.get(this.searchText, this.discontinued, this.selectedMarketIds, this.selectedCategories, id, this.currentPage);

            agent.Associations
                .getTotalPages(this.searchText, this.discontinued, this.selectedMarketIds, this.selectedCategories, id, this.currentPage)
                .then((value) => this.setTotalPages(value));

            this.setHasMore(response.HasMore);
            this.setAssociations(response.Items);
        } catch (error: any) {
            console.log(error);
        }
        finally {
            this.setLoading(false);
        }

    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetAssociations();
            this.setCurrentPage(1)

            this.load(undefined);

            if (text !== "" && text !== undefined)
                this.rootStore.analyticsStore.setSearchEvent("Groups", text);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action removeFromAssociation = async (product: IProduct) => {
        var productId = product.id;
        var assId = product.associationId;

        if (!productId || !assId) return;

        await agent.Associations.removeFromAssociation(assId, productId);

        toast.success(`Item was succesfully removed from association with Id : ${assId}`)

        runInAction(() => {
            var assIdx = this.associations.findIndex(f => f.id === assId);
            this.associations[assIdx].products = this.associations[assIdx].products.filter(f => f.id !== productId);
        })
    }

    @action dispose = () => {
        this.searchText = ""
        this.setMode(undefined);
        this.discontinued = true;
        this.setLoading(false);
        this.setCurrentPage(1);
        this.resetAssociations();
        this.selectedCategories = [];
    }
}