import { action, computed, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IOfferForList } from "../../../interfaces/offer";
import { IProduct, IProductForComparePrice, IProductForList } from "../../../interfaces/product";
import { IShoppingCartForView } from "../../../interfaces/shoping";
import { RootStore } from "../../../stores/RootStoreContext";

export class ShoppingListStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

        var cart = window.localStorage.getItem("cart");
        if (cart)
            window.localStorage.removeItem("cart");

    }

    @action countdown = async () => {
        await this.loadCart();
    }

    @observable loading: boolean = false;
    @observable loadingOrder: boolean = false;
    @observable paymentResult: boolean = false;
    @observable menuLoading: boolean = false;
    @observable checkingOut: boolean = false;
    @observable shopingCart?: IShoppingCartForView = undefined;
    @observable products: IProduct[] = []
    @observable quantityInProgress: boolean = false;

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setLoadingOrder = (value: boolean) => { this.loadingOrder = value };
    @action setPaymentResult = (value: boolean) => { this.paymentResult = value };
    @action setCheckingOut = (value: boolean) => { this.checkingOut = value };
    @action setMenuLoading = (value: boolean) => { this.menuLoading = value };
    @action setQuantityInProgress = (value: boolean) => { this.quantityInProgress = value };
    @action setProducts = (value: IProduct[]) => {
        if (this.products.length === 0 || value.length === 0)
            this.products = value
        else {
            var existing = this.products.map(f => f.id)
            var missing = value.filter(f => existing.indexOf(f.id) < 0);

            this.products.push(...missing);
        }
    };
    @action resetProducts = (value: IProduct[]) => { this.products = value }
    @action setShoppingCart = (value?: IShoppingCartForView) => { this.shopingCart = value };

    @computed get getCurrentCount() {
        var count = 0;
        this.shopingCart?.items.forEach(item => {
            var cartItems = this.products?.filter((f) => f.id === item.productId) ?? [];
            if (cartItems.length > 0) {
                count += Math.ceil(item.quantity * (cartItems[0].step ?? 1))
            }

        })
        // var count = this.shopingCart?.items.slice().reduce((a, b) => a + Math.ceil(b.quantity), 0) ?? 0;
        if (count >= 100)
            return "99+";

        return count.toFixed(0);
    }

    @action emptyShoppingList = async (marketId: string) => {
        this.setQuantityInProgress(true);
        agent.Cart.empty(marketId).then(() => {
            runInAction(() => {
                if (!this.shopingCart) return;

                var toRemove = this.products.filter(f => f.marketId === marketId).map(f => f.id);
                this.shopingCart.items = this.shopingCart.items.filter(f => toRemove.indexOf(f.productId) < 0);
            })
            this.loadCart().finally(() => this.setQuantityInProgress(false));
        })
        this.rootStore.analyticsStore.setEventCustom("empty_shopping_list");
    }

    @action oneTimeLoad = async (searchParams?: any) => {
        try {
        } catch (error) {
        }
    }

    @action loadCart = async () => {
        try {
            this.setMenuLoading(true);

            var response = await agent.Cart.get();
            if (!response || (response?.items.length ?? 0) === 0) return

            var missingItems = response.items.map(x => x.productId).filter(f => this.products.filter(p => f === p.id).length === 0);
            var allProdsinCart = response.items.map(x => x.productId);

            if (missingItems.length > 0) {
                var newProducts = await agent.Products.getList(missingItems);
                // remove unused products
                this.resetProducts([...this.products, ...newProducts].filter(f => allProdsinCart.includes(f.id)))
                this.setShoppingCart(response);
            }
            else {
                this.setShoppingCart(response);
                // remove unused products
                this.resetProducts(this.products.filter(f => allProdsinCart.includes(f.id)));
            }



        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setMenuLoading(false);
        }
    }

    @action load = async () => {
        try {
            this.setLoading(true);

            agent.Cart.get().then((response) => {
                this.setShoppingCart(response);
                if (response.items.map(x => x.productId).length > 0)
                    agent.Products.getList(response.items.map(x => x.productId)).then((prods) => {
                        this.resetProducts(prods ?? []);
                        this.setLoading(false);
                    });
                else {
                    this.setLoading(false);
                }
            }).catch(() =>
                this.setLoading(false)
            );

        } catch (error) {
            this.setLoading(false);
        }
        finally {
        }

    };

    @action calculatePrice = (group: any) => {
        var currentPrice = 0;
        group.items.forEach((z: IProduct) => {
            currentPrice += z.currentPrice * this.getProductQuantityInt(z);
        });

        return currentPrice;
    }

    @computed get calculateAllPrice() {
        var currentPrice = 0;

        this.products.forEach((item: any) => {
            var cartItems = this.shopingCart?.items.filter((f) => f.productId === item.id) ?? [];
            if (cartItems.length > 0) {
                currentPrice += this.getProductQuantityInt(item) * item.currentPrice
            }
        });


        return currentPrice;
    }

    @computed get calculateSubTotalPrice() {
        const { markets } = this.rootStore.cacheStore;

        var serviceMarkets = markets.filter(f => f.offersDelivery === true).map(x => x.type_id);
        var currentPrice = 0;

        this.products.filter(f => serviceMarkets.includes(f.marketId)).forEach((item: any) => {
            var cartItems = this.shopingCart?.items.filter((f) => f.productId === item.id) ?? [];
            if (cartItems.length > 0) {
                currentPrice += this.getProductQuantityInt(item) * item.currentPrice
            }
        });


        return currentPrice;
    }

    @action groupBy = (xs: any[], key: string): any => {
        var groups = xs?.reduce(function (rv: any, x: any) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});

        var retVal = []
        for (const property in groups) {
            var market = this.rootStore.cacheStore.markets.filter(f => f.type_id === property)[0];
            if (groups[property].length > 0)
                retVal.push({
                    marketId: property,
                    offersDelivery: market?.offersDelivery ?? false,
                    items: groups[property]
                });
        }

        return retVal.sort((a, b) => (a.offersDelivery > b.offersDelivery ? -1 : 1));
    };

    @action updateAssociationIdLocal = (productIds: string[], associationId: string) => {
        runInAction(() => {
            productIds.forEach((x => {
                var index = this.products.findIndex(z => z.id === x);
                this.products[index].associationId = associationId;
            }))
        })
    }

    @action addToCard = (newProduct: IProductForList) => {
        if (!this.shopingCart)
            this.shopingCart = {
                items: []
            }

        this.setQuantityInProgress(true)
        agent.Cart.add(newProduct.id).then(() => {

            runInAction(() => {
                if (!this.shopingCart) return;
                var index = this.shopingCart.items.findIndex(x => x.productId === newProduct.id) ?? -1;

                if (index > -1)
                    this.shopingCart.items[index].quantity = this.shopingCart.items[index].quantity + 1
                else {
                    this.setProducts([newProduct as any]);
                    this.shopingCart.items.push({ productId: newProduct.id, quantity: 1 });
                }

                this.setShoppingCart(this.shopingCart);
                this.setQuantityInProgress(false);
            })
        })

        this.rootStore.analyticsStore.setEventCustom("add_to_cart");
    }

    @action getProductQuantity = (product: IProductForList | IOfferForList) => {
        var cartItems = []

        if ('productId' in product)
            cartItems = this.shopingCart?.items.filter((f) => f.productId === product?.productId) ?? [];
        else
            cartItems = this.shopingCart?.items.filter((f) => f.productId === product?.id) ?? [];

        if (cartItems.length === 0) return 0;

        var cartItem = cartItems[0];

        if ((product.byWeight ?? false) === false) {
            return cartItem.quantity.toFixed(0);
        }

        var grams = cartItem.quantity * (product.step ?? 1) * (product.measurment ?? 1)
        if ((grams / 1000) < 1) return `${grams.toFixed(0)}gr`

        return `${(grams / 1000).toFixed(2)}kg`;
    }

    @action getProductQuantityInt = (product: IProductForList | IOfferForList) => {
        var cartItems = []

        if ('productId' in product)
            cartItems = this.shopingCart?.items.filter((f) => f.productId === product?.productId) ?? [];
        else
            cartItems = this.shopingCart?.items.filter((f) => f.productId === product?.id) ?? [];

        if (cartItems.length === 0) return 0;

        var cartItem = cartItems[0];

        if ((product.byWeight ?? false) === false) {
            return cartItem.quantity;
        }

        return cartItem.quantity * (product.step ?? 1)
    }

    @action removeFromCart = (product: IProductForList) => {
        if (!this.shopingCart)
            return;

        this.setQuantityInProgress(true)
        agent.Cart.remove(product.id).then(() => {
            if (!this.shopingCart) return;

            var index = this.shopingCart.items.findIndex(x => x.productId === product.id) ?? -1;
            if (index > -1) {
                runInAction(() => {
                    if (!this.shopingCart) return;

                    this.shopingCart.items[index].quantity = this.shopingCart.items[index].quantity - 1;
                    if (this.shopingCart.items[index].quantity === 0) {
                        this.shopingCart.items = this.shopingCart.items.filter(f => f.productId !== product.id);
                        this.products = this.products.filter(f => f.id !== product.id);
                    }
                })
            }
            this.setQuantityInProgress(false)
        })

        this.rootStore.analyticsStore.setEventCustom("remove_from_cart");
    }

    @action addToGroupCard = (newProduct: IProduct | IProductForList) => {
        if (!this.shopingCart)
            this.shopingCart = {
                items: []
            }

        this.setQuantityInProgress(true)
        agent.Cart.add(newProduct.id).then(() => {
            if (!this.shopingCart) return;

            var index = this.shopingCart.items.findIndex(x => x.productId === newProduct.id) ?? -1;
            if (index > -1)
                this.shopingCart.items[index].quantity = this.shopingCart.items[index].quantity + 1
            else {
                this.setProducts([newProduct as any]);
                this.shopingCart.items.push({ productId: newProduct.id, quantity: 1 });
            }

            this.setQuantityInProgress(false)
        })

        this.rootStore.analyticsStore.setEventCustom("add_to_cart_association");
    }

    @action addToOfferCard = (item: IOfferForList | IProductForComparePrice) => {
        if (!this.shopingCart)
            this.shopingCart = {
                items: []
            }

        this.setQuantityInProgress(true)
        agent.Cart.add(item.productId).then(() => {
            runInAction(() => {
                if (!this.shopingCart) return;

                var index = this.shopingCart.items.findIndex(x => x.productId === item.productId) ?? -1;
                if (index > -1)
                    this.shopingCart.items[index].quantity = this.shopingCart.items[index].quantity + 1
                else {
                    var exisitng = this.products.filter(f => f.id === item.productId);
                    if (exisitng.length === 0)
                        agent.Products.getList([item.productId]).then((response) => {
                            runInAction(() => {
                                if (!this.shopingCart || response.length === 0) return;
                                this.setProducts(response)
                                this.shopingCart.items.push({ productId: response[0].id, quantity: 1 });
                            })
                        })
                    else {
                        this.shopingCart.items.push({ productId: item.productId, quantity: 1 });
                    }
                }
            })

            this.setQuantityInProgress(false)
        })

        this.rootStore.analyticsStore.setEventCustom("add_to_cart_offer");
    }

    @action removeFromOfferCart = async (offer: IOfferForList | IProductForComparePrice) => {
        if (!this.shopingCart)
            return;

        this.setQuantityInProgress(true)
        agent.Cart.remove(offer.productId).then(() => {
            if (!this.shopingCart) return;

            var index = this.shopingCart.items.findIndex(x => x.productId === offer.productId) ?? -1;
            if (index > -1) {
                runInAction(() => {
                    if (!this.shopingCart) return;

                    this.shopingCart.items[index].quantity = this.shopingCart.items[index].quantity - 1;
                    if (this.shopingCart.items[index].quantity === 0) {
                        this.shopingCart.items = this.shopingCart.items.filter(f => f.productId !== offer.productId);
                        this.products = this.products.filter(f => f.id !== offer.productId);
                    }
                })
            }
            this.setQuantityInProgress(false)
        })

        this.rootStore.analyticsStore.setEventCustom("remove_from_offers_cart");
    }

    @action removeFromGroupCart = async (newProduct: IProduct | IProductForList) => {
        if (!this.shopingCart)
            return;

        this.setQuantityInProgress(true)
        this.rootStore.analyticsStore.setEventCustom("remove_from_cart_association");

        return agent.Cart.remove(newProduct.id).then(() => {
            runInAction(() => {
                if (!this.shopingCart) return;

                var index = this.shopingCart.items.findIndex(x => x.productId === newProduct.id) ?? -1;
                if (index > -1) {
                    this.shopingCart.items[index].quantity = this.shopingCart.items[index].quantity - 1;

                    if (this.shopingCart.items[index].quantity <= Number.EPSILON) {
                        this.shopingCart.items = this.shopingCart.items.filter(f => f.productId !== newProduct.id);
                        this.products = this.products.filter(f => f.id !== newProduct.id);
                    }
                }
            })

            this.setQuantityInProgress(false)
        })
    }

    @action deleteFromCart = (id: string) => {
        if (!this.shopingCart)
            return;

        this.setQuantityInProgress(true)
        agent.Cart.remove(id, true).then(() => {
            if (!this.shopingCart) return;

            var index = this.shopingCart.items.findIndex(x => x.productId === id) ?? -1;
            if (index > -1) {
                this.shopingCart.items = this.shopingCart.items.filter(f => f.productId !== id);
                this.resetProducts(this.products.filter(f => f.id !== id));
                this.setShoppingCart(this.shopingCart);
            }
            this.setQuantityInProgress(false)
        })

        this.rootStore.analyticsStore.setEventCustom("delete_from_cart");
    }

    @action dispose = () => {
        this.setLoading(false);
        this.setCheckingOut(false);
        this.paymentDispose();
    }

    @action paymentDispose = () => {
        this.setPaymentResult(false);
        this.setLoadingOrder(false);
    }
}