import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { Menu, MenuItemProps, Segment } from 'semantic-ui-react';
import ProfilePage from './ProfilePage';
import ClientCredits from '../credits/ClientCredits';
import { RootStoreContext } from '../../stores/RootStoreContext';
import UserRoles from './Components/UserRoles';
import AllClientOrders from '../clientOrders/AllClientOrders';
import { useParams } from 'react-router';
import ShoppingCart from '../shopping-list/ShoppingCart';
import UserRequests from '../userRequests.tsx/UserRequests';
import UserDevices from '../devices/UserDevices';
import AdminDietProfile from '../mealplanner/components/AdminDietProfile';

const ManageUser = () => {
    let { userId } = useParams<"userId">();

    const context = useContext(RootStoreContext);
    const { isOwner } = context.userStore;

    const [activeTab, setActiveTab] = React.useState<"profile" | "wallet" | "roles" | "orders" | "activity" | "cart" | "devices" | "diet">("profile")

    return (
        <div className='simply-paddedPage'>
            <Menu attached="top" tabular>
                <Menu.Item
                    name="profile"
                    content={"Profile"}
                    active={activeTab === "profile"}
                    onClick={(e: any, data: MenuItemProps) => {
                        setActiveTab("profile");
                    }}
                />
                <Menu.Item
                    name="wallet"
                    content={"Wallet"}
                    active={activeTab === "wallet"}
                    onClick={(e: any, data: MenuItemProps) => {
                        setActiveTab("wallet");
                    }}
                />
                {isOwner && <Fragment>
                    <Menu.Item
                        name="diet"
                        content={"Diet & Nutrition"}
                        active={activeTab === "diet"}
                        onClick={(e: any, data: MenuItemProps) => {
                            setActiveTab("diet");
                        }}
                    />
                    <Menu.Item
                        name="roles"
                        content={"User Roles"}
                        active={activeTab === "roles"}
                        onClick={(e: any, data: MenuItemProps) => {
                            setActiveTab("roles");
                        }}
                    />
                    <Menu.Item
                        name="orders"
                        content={"Orders"}
                        active={activeTab === "orders"}
                        onClick={(e: any, data: MenuItemProps) => {
                            setActiveTab("orders");
                        }}
                    />
                    <Menu.Item
                        name="devices"
                        content={"Devices"}
                        active={activeTab === "devices"}
                        onClick={(e: any, data: MenuItemProps) => {
                            setActiveTab("devices");
                        }}
                    />
                    <Menu.Item
                        name="activity"
                        content={"Activity"}
                        active={activeTab === "activity"}
                        onClick={(e: any, data: MenuItemProps) => {
                            setActiveTab("activity");
                        }}
                    />
                    <Menu.Item
                        name="cart"
                        content={"Cart"}
                        active={activeTab === "cart"}
                        onClick={(e: any, data: MenuItemProps) => {
                            setActiveTab("cart");
                        }}
                    />
                </Fragment>}
            </Menu>

            <Segment padded={"very"} attached="bottom" style={{ minHeight: "100%" }}>
                {activeTab === "profile" && (
                    <ProfilePage />
                )}
                {activeTab === "wallet" && (
                    <ClientCredits />
                )}
                {activeTab === "diet" && (
                    <AdminDietProfile userId={userId} />
                )}
                {activeTab === "roles" && (
                    <UserRoles />
                )}
                {activeTab === "orders" && (
                    <AllClientOrders userId={userId} />
                )}
                {activeTab === "activity" && (
                    <UserRequests userId={userId} />
                )}
                {activeTab === "cart" && (
                    <ShoppingCart userId={userId} />
                )}
                {activeTab === "devices" && (
                    <UserDevices userId={userId} />
                )}
            </Segment>
        </div>
    )
}

export default observer(ManageUser);