import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { combineValidators, isRequired } from "revalidate";
import { FORM_ERROR } from "final-form";
import ProfileFieldUpdate from "./ProfileFieldUpdate";
import { RootStoreContext } from "../../../stores/RootStoreContext";

const EmailEditor = () => {
  const context = useContext(RootStoreContext);
  const {
    profile,
    loading,
    currentlyEditing,
    setCurrentlyEditing,
    updateEmail,
  } = context.profileStore;
  const { user } = context.userStore;
  const { isMobile } = context.deviceStore;

  return (
    <ProfileFieldUpdate
      fullWidthOnEdit={isMobile}
      loading={loading}
      allowEdit={profile?.id === user?.id}
      groupFieldLabel={"Email"}
      editMode={currentlyEditing === "email"}
      fieldName="email"
      editClicked={(value: string) => {
        setCurrentlyEditing(value);
      }}
      cancelClicked={() => {
        setCurrentlyEditing("");
      }}
      update={(user: any) =>
        updateEmail(user.email!).catch((error) => ({
          [FORM_ERROR]: error,
        }))
      }
      validate={combineValidators({
        email: isRequired("Email"),
      })}
      initialValues={profile ?? undefined}
      displayValue={profile && profile.email}
      fields={[
        {
          display: "E-mail",
          placeholder: "E-mail",
          name: "email",
          showDisplay: false,
        },
      ]}
    />
  );
};

export default observer(EmailEditor);
