import { createContext, useContext } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from "../../../stores/RootStoreContext";
import { SearchState, Suggestions } from '../../../interfaces/search';
import agent from '../../../api/agent';

export class SearchStore {
  rootStore: RootStore;
  state: SearchState = {
    searchTerm: '',
    isSticky: false,
    isSidebarSticky: true,
    isDrawerOpen: false,
  };
  suggestions: Suggestions = {
    brands: [],
    categories: [],
    words: []
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  loading: boolean = false;
  setSearchTerm(searchTerm: string) {
    this.state.searchTerm = searchTerm;
  }

  setSticky() {
    this.state.isSticky = true;
  }

  removeSticky() {
    this.state.isSticky = false;
  }

  setSidebarSticky() {
    this.state.isSidebarSticky = true;
  }

  removeSidebarSticky() {
    this.state.isSidebarSticky = false;
  }

  setDrawerState(isDrawerOpen: boolean) {
    this.state.isDrawerOpen = isDrawerOpen;
  }

  toggleDrawer() {
    this.state.isDrawerOpen = !this.state.isDrawerOpen;
  }

  updateSuggestions(newSuggestions: Suggestions) {
    this.suggestions = newSuggestions;
  }

  dispose_search() {
    this.state = {
      searchTerm: '',
      isSticky: false,
      isSidebarSticky: true,
      isDrawerOpen: false,
    };
    this.suggestions = {
      brands: [],
      categories: [],
      words: []
    };
  }

  async fetchSuggestions(searchTerm: string) {
    try {
      this.loading = true;
      if (searchTerm !== "" && searchTerm !== undefined)
        this.rootStore.analyticsStore.setSearchEvent("Search-Bar", searchTerm);
      const newSuggestions = await agent.Catalogue.Order.fetchSearchBarSuggestions(searchTerm);
      this.updateSuggestions(newSuggestions);

    } catch (error) {
      // toast.error(JSON.stringify(error));
    } finally {
      runInAction(() => {
        this.loading = false;
      })
    }
  }
}

const SearchContext = createContext<SearchStore | null>(null);

export const useSearchState = () => {
  const store = useContext(SearchContext);
  if (!store) {
    throw new Error('useSearchState must be used within an SearchProvider');
  }
  return store.state;
};

export const useSearchStore = () => {
  const store = useContext(SearchContext);
  if (!store) {
    throw new Error('useSearchStore must be used within an SearchProvider');
  }
  return store;
};

// export const SearchProvider: React.FC = ({ children }) => {
//   const store = new SearchStore(new RootStore());
//   return <SearchContext.Provider value={store}>{children}</SearchContext.Provider>;
// };
