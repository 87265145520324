export const ShoppingRecurrencyOptions = [
    {
        key: "daily",
        value: "daily",
        text: "Daily"
    },
    {
        key: "manyweek",
        value: "manyweek",
        text: "Several times a week"
    },
    {
        key: "week",
        value: "week",
        text: "Weekly"
    },
    {
        key: "biweek",
        value: "biweek",
        text: "Bi-weekly (every two weeks)"
    },
    {
        key: "month",
        value: "month",
        text: "Monthly"
    },
    {
        key: "lessmonth",
        value: "lessmonth",
        text: "Less than once a month"
    },
]

export const ShoppingWayOptions = [
    {
        key: "online",
        value: "online",
        text: "Online shopping"
    },
    {
        key: "instore",
        value: "instore",
        text: "In-Store shopping"
    },
    {
        key: "both",
        value: "both",
        text: "Both equally"
    },
]