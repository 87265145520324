import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Button, Divider, Popup } from "semantic-ui-react";
import agent from "../../../api/agent";
import { getMarketImageUrl } from "../../../helpers/supermarketImage";
import { IRequestForShopClaim } from "../../../interfaces/shopClaim";
import { IUserMinimal } from "../../../interfaces/user";
import { ClaimRequestStatus } from "../../../options/ClaimRequestStatus";
import { RootStoreContext } from "../../../stores/RootStoreContext";

interface IProps {
  request: IRequestForShopClaim;
}

const ClaimRequestCard: React.FC<IProps> = ({ request }) => {
  const context = useContext(RootStoreContext);
  const { oneTimeLoad } = context.productStore;
  const { markets } = context.cacheStore;
  const { reject, approve } = context.claimRequestStore;

  const [user, setUser] = React.useState<IUserMinimal>();
  const [admin, setAdmin] = React.useState<IUserMinimal>();

  const load = () => {
    agent.Users.getProfile(request.userId).then((minimal) => setUser(minimal));
    if (request.updatedFrom)
      agent.Users.getProfile(request.updatedFrom).then((minimal) =>
        setAdmin(minimal)
      );
  };

  useEffect(() => {
    oneTimeLoad();
    load();
    return () => {};
  }, [oneTimeLoad]);

  return (
    <div
      className="productCardItem ui card centered"
      style={{ width: "95%", marginTop: "7px" }}
    >
      <div className="fxDisplay fxJustifyEven">
        <div className=" fxDirCol" style={{ padding: "1em" }}>
          <h3>User Details</h3>
          <Divider />
          <div className="underTitle">
            Name :{" "}
            <span>{`${user?.firstName} ${user?.middleName ?? ""} ${
              user?.lastName
            }`}</span>
          </div>
          <div className="underTitle">
            Email :{" "}
            <span>
              <a
                className="grey"
                href={`mailto:${user?.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {user?.email}
              </a>
            </span>
          </div>
          <div className="underTitle">
            Phone : <span>{user?.phoneNumber ?? " - "}</span>
          </div>
        </div>
        <hr />
        {admin && (
          <Fragment>
            <div className=" fxDirCol" style={{ padding: "1em" }}>
              <h3>Updated</h3>
              <Divider />
              <div className="underTitle">
                Name :{" "}
                <span>{`${admin.firstName} ${admin.middleName ?? ""} ${
                  admin.lastName
                }`}</span>
              </div>
              <div className="underTitle">
                Email :{" "}
                <span>
                  <a
                    className="grey"
                    href={`mailto:${admin.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {admin.email}
                  </a>
                </span>
              </div>
              <div className="underTitle">
                Phone : <span>{admin.phoneNumber ?? " - "}</span>
              </div>
            </div>
            <hr />
          </Fragment>
        )}
        <div className=" fxDirCol" style={{ padding: "1em" }}>
          <h3>Request Details</h3>
          <Divider />
          {(markets?.length ?? 0) > 0 && (
            <div className="underTitle">
              Shop :{" "}
              <span>
                <img
                  style={{
                    width: "75px",
                    marginRight: "7px",
                    marginLeft: "7px",
                  }}
                  alt="Supermarket Logo"
                  src={getMarketImageUrl(
                    markets.filter((f) => f.id === request.shopId)[0]
                  )}
                />
              </span>
              <span>
                {markets.filter((f) => f.id === request.shopId)[0].title}
              </span>
            </div>
          )}
          <div className="underTitle">
            Vat : <span>{request.vat}</span>
          </div>
          <div className="underTitle">
            Email :{" "}
            <span>
              <a
                className="grey"
                href={`mailto:${request?.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {request?.email}
              </a>
            </span>
          </div>
          <div className="underTitle">
            Phone : <span>{request?.phone ?? " - "}</span>
          </div>
          <div className="underTitle">
            Status :{" "}
            <span>
              {
                ClaimRequestStatus.filter((f) => f.key === request.status)[0]
                  .text
              }
            </span>
          </div>
          <div className="underTitle">
            Created On :{" "}
            <span>
              {format(new Date(request.createdOn * 1000), "EEEE, do MMMM yyyy")}
            </span>
          </div>
        </div>
        <hr />
        <nav
          className={`fxFixed fxDisplay fxDirCol fxJustifyEven`}
          style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
        >
          <Popup
            trigger={
              <Button
                color="green"
                icon={"check"}
                content="Approve"
                disabled={request.status !== "pending_approval"}
                onClick={() => {
                  approve(request.id);
                }}
              />
            }
            content={"Remove from cart"}
            on="hover"
          />
          <Popup
            trigger={
              <Button
                color="red"
                icon={"delete"}
                disabled={request.status !== "pending_approval"}
                content="Reject"
                onClick={() => {
                  reject(request.id);
                }}
              />
            }
            content={"Remove from cart"}
            on="hover"
          />
        </nav>
      </div>
    </div>
  );
};

export default observer(ClaimRequestCard);
