import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { Image, Table } from 'semantic-ui-react';
import { getImageUrlPlain } from '../../../helpers/productImage';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { currencyFormat } from '../../products/functions/productHelper';
import { formatRelative } from 'date-fns';
import { ITrackerProductDetail } from '../../../interfaces/product';
import agent from '../../../api/agent';

interface IProps {
  id: string;
  accountId: string;
}

const PriceTrackerDetail: React.FC<IProps> = ({ id, accountId }) => {
  const context = useContext(RootStoreContext)
  const { markets } = context.priceTrackerStore;

  const [products, setProducts] = React.useState<ITrackerProductDetail[]>([]);

  const updateValues = (items: ITrackerProductDetail[]) => {
    setProducts(items?.slice()?.sort((a, b) => a.currentPrice < b.currentPrice ? -1 : 1) ?? undefined);
  }

  useEffect(() => {
    agent.PriceTracker.getDetail(id).then((values) => updateValues(values));
    return () => { }
  }, [id])


  return (
    <div>
      <Table>
        <Table.Body>
          {products?.map((product) =>
            <Table.Row style={product.marketId === (markets.filter(f => f.id === accountId)[0]?.type_id) ? { fontWeight: "bold" } : {}} warning={product.marketId === (markets.filter(f => f.id === accountId)[0]?.type_id)}>
              <Table.Cell>
                <Image
                  src={getImageUrlPlain(product.marketId, product.imageUrl)}
                  onError={({ currentTarget }: any) => {
                    currentTarget.onerror = null;
                    currentTarget.src =
                      `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                  }}
                  alt={product.itemDesc}
                  className="tracker-detail-product-image"
                />
              </Table.Cell>
              <Table.Cell>
                {product.itemDesc}
                {product.textOffer && <p>
                  <b style={{ color: "red" }}>Offer : {" "}</b>{product.textOffer}</p>}
              </Table.Cell>
              <Table.Cell>
                {markets.filter((f) => f.type_id === product.marketId)[0]
                  .title}
              </Table.Cell>
              <Table.Cell>
                {currencyFormat(product.currentPrice, true)}
              </Table.Cell>
              <Table.Cell>
                {product.lastUpdated && (
                  <p>
                    {formatRelative(
                      new Date(product.lastUpdated * 1000),
                      new Date(),
                      {}
                    )}
                  </p>
                )}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  )
}

export default observer(PriceTrackerDetail)