import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";

export default class LocationIQStoreInt {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
    }

    @observable mapKey?: string = undefined;
    @observable loading?: boolean;

    @action setMapKey = (value?: string) => this.mapKey = value;
    @action setLoading = (value?: boolean) => this.loading = value;

    @action initialize = () => {
        if (this.mapKey !== undefined) return;
        this.setLoading(true);

        try {
            if (process.env.NODE_ENV === 'development')
                this.setMapKey(process.env.REACT_APP_LOCATIONIQ);
            else {
                agent.LocationIQ.getKey().then((key) => {
                    this.setMapKey(key);
                });
            }
        } catch (error) {

        }
        finally {
            this.setLoading(false);
        }


    }
}
