import React, { useContext } from 'react';
import "./Search.css";
import { Button, ButtonContent, Icon, Input } from 'semantic-ui-react';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { observer } from 'mobx-react-lite';

interface Props {
  onEnter: (e: React.SyntheticEvent) => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
  showButtonText?: boolean;
  shadow?: string;
  showAnimation?: boolean;
  [key: string]: unknown;
}

const SearchBox: React.FC<Props> = ({
  onEnter,
  onChange,
  value,
  categoryType,
  buttonText,
  showButtonText,
  shadow,
  showAnimation,
  ...rest
}) => {
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const { sidebarOpen, setSidebarOpen } = context.shoppingCatalogueStore;

  return (
    <div className="styledForm">
      <Input
        style={isMobile ? { padding: "0px", paddingLeft: "5px" } : {}}
        autoComplete="off"
        transparent
        fluid={isMobile}
        onKeyDown={(e: any) => {
          if (e.key === "Enter") {
            // e.preventDefault();
            onEnter({} as any);
          }
        }}
        className='styledInput'
        type='search'
        onChange={onChange}
        value={value}
        autoFocus
        {...rest}
      />
      {(isMobile || !showAnimation) ?
        <Button className='styledSearchButton' style={isMobile ? { paddingLeft: "5px", paddingRight: "0px" } : {}}>
          <Icon name="search" onClick={() => { onEnter({} as any); }} />
          {isMobile && <Icon style={{ marginLeft: "6px" }} name="filter" onClick={() => setSidebarOpen(!sidebarOpen)} />}
          {showButtonText && buttonText}
        </Button> :
        <Button animated='vertical' className='styledSearchButton' style={isMobile ? { paddingLeft: "5px", paddingRight: "0px" } : {}} onClick={() => { onEnter({} as any); }} >
          <ButtonContent hidden style={{ fontSize: "13px", paddingRight: "26px" }}>Categories</ButtonContent>
          <ButtonContent visible>
            <Icon name="search" />
          </ButtonContent>
        </Button>}
    </div >
  );
};

export default observer(SearchBox)