import { observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "../stores/RootStoreContext";

const WindowFocusHandler = () => {
  const context = useContext(RootStoreContext);
  const { setAppFocused } = context.commonStore;

  const handleActivityFalse = () => handleActivity(false);
  const handleActivityTrue = () => handleActivity(true);

  function handleActivity(forcedFlag: any) {
    if (typeof forcedFlag === "boolean") {
      return forcedFlag ? setAppFocused(true) : setAppFocused(false);
    }
    return document.hidden ? setAppFocused(false) : setAppFocused(true);
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", handleActivity);
    document.addEventListener("blur", handleActivityFalse);
    window.addEventListener("blur", handleActivityFalse);
    window.addEventListener("focus", handleActivityTrue);
    document.addEventListener("focus", handleActivityTrue);

    handleActivityTrue();

    return () => {
      window.removeEventListener("blur", handleActivityFalse);
      document.removeEventListener("blur", handleActivityFalse);
      window.removeEventListener("focus", handleActivityTrue);
      document.removeEventListener("focus", handleActivityTrue);
      document.removeEventListener("visibilitychange", handleActivity);
    };
  }, []);

  return <></>;
};

export default observer(WindowFocusHandler);
