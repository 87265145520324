import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { ISaleReportItem } from "../../../interfaces/report";
import { currencyFormat } from "../../products/functions/productHelper";
import { format } from "date-fns";

class ReferralSales {
    constructor() {
        makeAutoObservable(this);
    }

    @observable shopId: string = "";
    @observable loading: boolean = false;
    @observable aggregateBy: "day" | "month" | "year" = "day";
    @observable currentPage: number = 0;
    @observable downloading: boolean = false;
    @observable hasMore: boolean = false;
    @observable items: ISaleReportItem[] = []
    @observable dateFrom?: number = undefined;
    @observable dateTo?: number = undefined;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setShopId = (value: string) => this.shopId = value;
    @action setAggregateBy = (value: "day" | "month" | "year") => this.aggregateBy = value;
    @action setDateFrom = (value?: number) => this.dateFrom = value;
    @action setDownloading = (value: boolean) => this.downloading = value;
    @action setDateTo = (value?: number) => this.dateTo = value;
    @action resetItems = (value: ISaleReportItem[]) => this.items = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setItems = (value: ISaleReportItem[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action search = async () => {
        try {
            this.resetItems([]);
            this.setCurrentPage(0)
            this.setHasMore(true)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Referrals.Reports.get(this.shopId, this.aggregateBy, this.dateFrom, this.dateTo, this.currentPage, 0);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action exportDataForCsv = async () => {
        this.setDownloading(true);
        try {
            var csvData: any[] = [];
            var titleText = this.aggregateBy === "day" ? "Day" : (this.aggregateBy === "month" ? "Month" : "Year")

            csvData.push([`Title`, `Sales Report By ${titleText}`])
            csvData.push([])
            csvData.push([])
            csvData.push(["Filters :"])
            csvData.push(["", `(Receipt) - Date From`, this.dateFrom ? format(this.dateFrom * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push(["", `(Receipt) - Date To`, this.dateTo ? format(this.dateTo * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])

            csvData.push([])
            csvData.push([])

            csvData.push([titleText, "Sold ex. VAT", "VAT", "Total",]);


            var response = await agent.Referrals.Reports.get(this.shopId, this.aggregateBy, this.dateFrom, this.dateTo, 0, 1);
            (response.Items as ISaleReportItem[]).forEach((x) => {
                csvData.push([
                    x.id,
                    currencyFormat(x.salesNet, true),
                    currencyFormat(x.vat, true),
                    currencyFormat(x.vat + x.salesNet, true),
                ])
            });

            csvData.push([
                "Totals :",
                currencyFormat(response.Items?.reduce((a, b) => a + b.salesNet, 0), true),
                currencyFormat(response.Items?.reduce((a, b) => a + b.vat, 0), true),
                currencyFormat(response.Items?.reduce((a, b) => a + b.salesNet + b.vat, 0), true),
            ])

            var today = new Date();
            var fileName = `${today.getFullYear()}_${today.getMonth()}_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()} `;

            return {
                data: csvData,
                filename: `cheap_trolley_profit_by_${this.aggregateBy}_${fileName}.csv`,
            };

        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownloading(false);
        }
    };

    @action dispose = () => {
        this.setDateFrom(undefined);
        this.setDateTo(undefined);
        this.setCurrentPage(0);
        this.setHasMore(false);
        this.setDownloading(false);
        this.setItems([]);
        this.setShopId("");
    }
}


export const ReferralSalesStore = new ReferralSales();