import React, { SyntheticEvent } from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Dropdown } from "semantic-ui-react";

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps {
  customSearchChange?: (event: SyntheticEvent, data: object) => Promise<any>;
  myDefaultValue?: number;
  piggyback: "left" | "right" | undefined;
}

const SelectInput: React.FC<IProps> = ({
  input: { name, value, onChange },
  width,
  options,
  placeholder,
  meta: { touched, error },
  changeFinished,
  formControlProps,
  multiselect /* multiple is not passed down to the field (bug in final form) so we changed the name */,
  allowEdit = true,
  fluid,
  search,
  compact,
  loading = false,
  customSearchChange,
  allowAdditions,
  onAddItem,
  additionLabel,
  automationid,
  piggyback,
  clearable = true,
  myDefaultValue,
  style,
}) => {
  const [ddloading, setLoading] = React.useState(false);
  const [timer, setTimer] = React.useState(0);

  return (
    <Form.Field {...formControlProps} error={touched && !!error} width={width} >
      <Dropdown
        style={style}
        automationid={automationid}
        lazyLoad
        additionLabel={additionLabel}
        loading={ddloading || loading}
        disabled={!allowEdit}
        compact={compact}
        multiple={multiselect}
        allowAdditions={allowAdditions}
        additionPosition="bottom"
        onAddItem={onAddItem}
        defaultValue={myDefaultValue}
        selection
        selectOnBlur={false}
        clearable={clearable}
        value={multiselect && !value ? [] : value}
        onChange={(e, data) => {
          onChange(data.value);
          changeFinished && changeFinished(data.value);
        }}
        placeholder={placeholder}
        options={options}
        search={search ?? true}
        // search
        onSearchChange={(event: SyntheticEvent, data: object) => {
          if (customSearchChange) setLoading(true);

          if (timer != null) {
            clearTimeout(timer);
            setTimer(0);
          }

          setTimer(
            setTimeout(
              (text: string) => {
                if (customSearchChange)
                  customSearchChange(event, data)
                    .catch(() => { })
                    .then(() => setLoading(false))
                    .then(() => {
                      clearTimeout(timer);
                      setTimer(0);
                    });
              },
              500,
              data,
              timer
            )
          );
        }}
        fluid={fluid}
        className={`${piggyback ? "piggyback " + piggyback : ""}`}
      />
    </Form.Field>
  );
};

export default SelectInput;
