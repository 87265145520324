import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "./RootStoreContext";

export class DeviceStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable screenType: "mobile" | "tablet" | "desktop" = "desktop";
    @observable isPortrait: boolean = false;
    @observable os?: "ios" | "android" = undefined;

    @computed get isMobile() { return this.screenType === "mobile" }
    @computed get isTablet() { return this.screenType === "tablet" }

    @action setScreenType = (device: "mobile" | "tablet" | "desktop") => {
        this.screenType = device;
    };

    @action setIsPortrait = (value: boolean) => {
        this.isPortrait = value;
    };

    @action setUpDevice = (isAndroid: boolean, isIOS: boolean) => {
        if (isAndroid) {
            this.os = "android"
        } else if (isIOS) {
            this.os = "ios"
        }
    }

    @action downloadAppWeb = (type: "android" | "ios") => {
        let androidLink = `https://play.google.com/store/apps/details?id=com.cheaptrolley.mobile`;
        let iosLink = `https://apps.apple.com/mt/app/cheap-trolley/id6445921477`;

        if (type === "android") {
            window.open(androidLink, "_blank")
        } else if (type === "ios") {
            window.open(iosLink, "_blank")
        }
    }

    @action dispose = () => {
    }
}