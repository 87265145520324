import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IAssociationForSave } from "../../../interfaces/association";
import { IOrphanProductRelation } from "../../../interfaces/product";
import { RootStore } from "../../../stores/RootStoreContext";

export class OrphanStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable associated?: boolean = true;
    @observable createOpen: boolean = true;
    @observable orphansCount: number = 0;
    @observable existingGroupsView: boolean = true;
    @observable byWeight: boolean = false;
    @observable expandedIds: string[] = [];
    @observable product?: IOrphanProductRelation = undefined;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setByWeight = (value: boolean) => this.byWeight = value;
    @action setCreateOpen = (value: boolean) => this.createOpen = value;
    @action setOrphansCount = (value: number) => this.orphansCount = value;
    @action toggleView = () => {
        if (!this.product) return;

        const { setProductIdToExpand } = this.rootStore.productAssociationStore;

        setProductIdToExpand(this.existingGroupsView ? this.product.id : undefined);
        this.existingGroupsView = !this.existingGroupsView;
    }
    @action setAssociated = (value?: boolean) => this.associated = value;
    @action setProduct = (value?: IOrphanProductRelation) => this.product = value;

    @action toggleExpanded = (groupId: string) => {
        var idx = this.expandedIds.findIndex(f => f === groupId);

        if (idx > -1)
            this.expandedIds = this.expandedIds.filter(f => f !== groupId);
        else
            this.expandedIds.push(groupId);
    }

    @action deleteProduct = async () => {
        if (!this.product) return;

        var root = this.byWeight ? agent.Products.byWeight : agent.Products.orphans;
        return root.delete(this.product.id).then(() => this.loadNext(this.byWeight))
    }

    @action convertToGroup = async (values: IAssociationForSave) => {
        if (!this.product) return;

        values.productIds = [this.product.id];
        return agent.Products.orphans.convert(this.product.id, values).then(() => this.loadNext(this.byWeight))
    }

    @action removeGroupRelation = async (groupId: string) => {
        if (!this.product) return;

        return agent.Products.orphans.removeRelation(this.product.id, groupId, false).then(() => {
            runInAction(() => {
                if (!this.product) return;

                this.product.groups = this.product.groups?.filter(f => f.id !== groupId)
            })
        })
    }

    @action approveGroupRelation = async (groupId: string) => {
        if (!this.product) return;

        await agent.Products.orphans.approveRelation(this.product.id, groupId, false)

        this.loadNext(this.byWeight)
    }

    @action removePossibleGroupRelation = async (groupId: string) => {
        if (!this.product) return;

        return agent.Products.orphans.removeRelation(this.product.id, groupId, true).then(() => {
            runInAction(() => {
                if (!this.product) return;

                this.product.possibleGroups = this.product.possibleGroups?.filter(f => f.id !== groupId)
            })
        })
    }

    @action approvePossibleGroupRelation = async (groupId: string, productId?: string) => {
        if (!this.product && !productId) return;

        await agent.Products.orphans.approveRelation(productId ?? this.product!.id, groupId, true)

        if (!productId)
            this.loadNext(this.byWeight)
    }

    @action loadNext = async (byWeight: boolean) => {
        try {
            this.setByWeight(byWeight);
            this.setLoading(true);
            this.existingGroupsView = true;

            const rootUrl = byWeight ? agent.Products.byWeight : agent.Products.orphans;
            rootUrl.count().then((count) => this.setOrphansCount(count))

            var response = await rootUrl.next();

            this.setProduct(response);
            if ((response.groups?.length ?? 0) === 0) {
                this.toggleView();
                this.setCreateOpen(true);
            }
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action dispose = () => {
        this.setLoading(false);
        this.setProduct(undefined);
        this.setByWeight(false);
    }
}