import { IMeasurement } from "../interfaces/measurements";

export const generateQuantity = (measurements: IMeasurement[], measurement?: number, measurementUnit?: string): string | undefined => {
    let measurementItem = measurements.find(f => f.key === measurementUnit)?.description ?? "-";
    let valueItem = measurement ?? 0;

    if (valueItem === 0) return undefined;

    let valueItemStr = `${valueItem}`
    if (valueItem >= 1000 && (measurementUnit === "grams" || measurementUnit === "ml")) {
        valueItemStr = (valueItem / 1000).toFixed(2); // shorthand for division and assignment
        switch (measurementUnit) {
            case "grams":
                measurementItem = "kg";
                break;
            case "ml":
                measurementItem = "Lt";
                break;
            default:
                break;
        }
    }

    return `${valueItemStr}${measurementItem}`;
};


export const hasUnitCost = (price?: number, measurement?: number, measurementUnit?: string): boolean => {
    if ((price ?? 0) === 0 || !price) return false;
    if ((measurement ?? 1) === 1 || !measurement) return false;
    if (!measurementUnit) return false;

    return true;
}

export const getUnitCost = (measurements: IMeasurement[], price?: number, measurement?: number, measurementUnit?: string): string => {
    if (!hasUnitCost(price, measurement, measurementUnit)) return "";

    let measurementItem = measurements.find(f => f.key === measurementUnit)?.description ?? "-";

    let pricePerUnit: number;
    let outputUnit: string;

    switch (measurementUnit) {
        case "grams":
            pricePerUnit = price! * 1000;
            outputUnit = "kg";
            break;
        case "ml":
            pricePerUnit = price! * 1000;
            outputUnit = "Lt";
            break;
        default:
            pricePerUnit = price!;
            outputUnit = ` ${measurementItem}`;
            break;
    }

    return `${pricePerUnit.toFixed(2)}/${outputUnit}`;
}
// currencyFormat(product.unit_price ?? 0, false)