import React from "react";

const BusinessTerms = () => {
  return (
    <div>
      <h1>Terms and conditions</h1>
      <p>
        These terms and conditions (&quot;Terms&quot;, &quot;Agreement&quot;)
        are an agreement between website Developer (&quot;Mobile Application
        Developer&quot;, &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and
        you (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;). This
        Agreement sets forth the general terms and conditions of your use of the
        Cheap Trolley website and any of its products or services (collectively,
        &quot;Mobile Application&quot; or &quot;Services&quot;).
      </p>
      <h2>Age requirement</h2>
      <p>
        You must be at least 13 years of age to use this website. By using this
        website and by agreeing to this Agreement you warrant and represent that
        you are at least 13 years of age.
      </p>
      <h2>User content</h2>
      <p>
        We do not own any data, information or material (&quot;Content&quot;)
        that you submit in the website in the course of using the Service. You
        shall have sole responsibility for the accuracy, quality, integrity,
        legality, reliability, appropriateness, and intellectual property
        ownership or right to use of all submitted Content. We may, but have no
        obligation to, monitor and review Content in the Mobile Application
        submitted or created using our Services by you. Unless specifically
        permitted by you, your use of the website does not grant us the license
        to use, reproduce, adapt, modify, publish or distribute the Content
        created by you or stored in your user account for commercial, marketing
        or any similar purpose. But you grant us permission to access, copy,
        distribute, store, transmit, reformat, display and perform the Content
        of your user account solely as required for the purpose of providing the
        Services to you.
      </p>
      <h2>Billing and payments</h2>
      <p>
        You shall pay all fees or charges to your account in accordance with the
        fees, charges, and billing terms in effect at the time a fee or charge
        is due and payable. Sensitive and private data exchange between the
        website and its Users happens over a SSL secured communication channel
        and is encrypted and protected with digital signatures. If, in our
        judgment, your purchase constitutes a high-risk transaction, we will
        require you to provide us with a copy of your valid government-issued
        photo identification, and possibly a copy of a recent bank statement for
        the credit or debit card used for the purchase. We reserve the right to
        change products and product pricing at any time.
      </p>
      <h2>Accuracy of information</h2>
      <p>
        Occasionally there may be information in the website that contains
        typographical errors, inaccuracies or omissions that may relate to
        product descriptions, pricing, availability, promotions and offers. We
        reserve the right to correct any errors, inaccuracies or omissions, and
        to change or update information or cancel orders if any information in
        the website or on any related Service is inaccurate at any time without
        prior notice (including after you have submitted your order). We
        undertake no obligation to update, amend or clarify information in the
        website including, without limitation, pricing information, except as
        required by law. No specified update or refresh date applied in the
        website should be taken to indicate that all information in the website
        or on any related Service has been modified or updated.
      </p>
      <h2>Third-party services</h2>
      <p>
        If you decide to enable, access or use third-party services, be advised
        that your access and use of such other services are governed solely by
        the terms and conditions of such other services, and we do not endorse,
        are not responsible or liable for, and make no representations as to any
        aspect of such other services, including, without limitation, their
        content or the manner in which they handle data (including your data) or
        any interaction between you and the provider of such other services. You
        irrevocably waive any claim against website Developer with respect to
        such other services. website Developer is not liable for any damage or
        loss caused or alleged to be caused by or in connection with your
        enablement, access or use of any such other services, or your reliance
        on the privacy practices, data security processes or other policies of
        such other services. You may be required to register for or log into
        such other services on their respective websites. By enabling any other
        services, you are expressly permitting website Developer to disclose
        your data as necessary to facilitate the use or enablement of such other
        service.
      </p>
      <h2>Backups</h2>
      <p>
        We are not responsible for Content residing in the website. In no event
        shall we be held liable for any loss of any Content. It is your sole
        responsibility to maintain appropriate backup of your Content.
        Notwithstanding the foregoing, on some occasions and in certain
        circumstances, with absolutely no obligation, we may be able to restore
        some or all of your data that has been deleted as of a certain date and
        time when we may have backed up data for our own purposes. We make no
        guarantee that the data you need will be available.
      </p>
      <h2>Links to other websites</h2>
      <p>
        Although this website may link to other websites, we are not, directly
        or indirectly, implying any approval, association, sponsorship,
        endorsement, or affiliation with any linked mobile application, unless
        specifically stated herein. Some of the links in the website may be
        &quot;affiliate links&quot;. This means if you click on the link and
        purchase an item, website Developer will receive an affiliate
        commission. We are not responsible for examining or evaluating, and we
        do not warrant the offerings of, any businesses or individuals or the
        content of their websites. We do not assume any responsibility or
        liability for the actions, products, services, and content of any other
        third-parties. You should carefully review the legal statements and
        other conditions of use of any website which you access through a link
        from this Mobile Application. Your linking to any other off-site
        websites is at your own risk.
      </p>
      <h2>Disclaimer of warranty</h2>
      <p>
        You agree that your use of our website or Services is solely at your own
        risk. You agree that such Service is provided on an &quot;as is&quot;
        and &quot;as available&quot; basis. We expressly disclaim all warranties
        of any kind, whether express or implied, including but not limited to
        the implied warranties of merchantability, fitness for a particular
        purpose and non-infringement. We make no warranty that the Services will
        meet your requirements, or that the Service will be uninterrupted,
        timely, secure, or error-free; nor do we make any warranty as to the
        results that may be obtained from the use of the Service or as to the
        accuracy or reliability of any information obtained through the Service
        or that defects in the Service will be corrected. You understand and
        agree that any material and/or data downloaded or otherwise obtained
        through the use of Service is done at your own discretion and risk and
        that you will be solely responsible for any damage to your computer
        system or loss of data that results from the download of such material
        and/or data. We make no warranty regarding any goods or services
        purchased or obtained through the Service or any transactions entered
        into through the Service. No advice or information, whether oral or
        written, obtained by you from us or through the Service shall create any
        warranty not expressly made herein.
      </p>
      <h2>Dispute resolution</h2>
      <p>
        The formation, interpretation, and performance of this Agreement and any
        disputes arising out of it shall be governed by the substantive and
        procedural laws of Northern, Malta without regard to its rules on
        conflicts or choice of law and, to the extent applicable, the laws of
        Malta. The exclusive jurisdiction and venue for actions related to the
        subject matter hereof shall be the state and federal courts located in
        Northern, Malta, and you hereby submit to the personal jurisdiction of
        such courts. You hereby waive any right to a jury trial in any
        proceeding arising out of or related to this Agreement. The United
        Nations Convention on Contracts for the International Sale of Goods does
        not apply to this Agreement.
      </p>
      <h2>Changes and amendments</h2>
      <p>
        We reserve the right to modify this Agreement or its policies relating
        to the website or Services at any time, effective upon posting of an
        updated version of this Agreement in the Mobile Application. When we do,
        we will send you an email to notify you. Continued use of the website
        after any such changes shall constitute your consent to such changes.
      </p>
      <h2>Acceptance of these terms</h2>
      <p>
        You acknowledge that you have read this Agreement and agree to all its
        terms and conditions. By using the website or its Services you agree to
        be bound by this Agreement. If you do not agree to abide by the terms of
        this Agreement, you are not authorized to use or access the website and
        its Services.
      </p>
      <h2>Contacting us</h2>
      <p>
        If you would like to contact us to understand more about this Agreement
        or wish to contact us concerning any matter relating to it, you may do
        so via the{" "}
        <a
          target="_blank"
          href="https://cheaptrolley.com/about"
          rel="noreferrer"
        >
          about page
        </a>{" "}
        or send an email to{" "}
        <a target="_blank" rel="noreferrer" href="mailto:info@cheaptrolley.com">
          info@cheaptrolley.com
        </a>
      </p>
      <p>This document was last updated on March 18, 2022</p>
    </div>
  );
};

export default BusinessTerms;
