import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, Fragment, SyntheticEvent, useContext, useEffect } from 'react'
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Button, Dimmer, Divider, Form, Header, Icon, Input, Label, List, Loader, Menu, Segment, Table } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { HubOrderReplacementStore } from '../functions/hubOrderReplacementStore';
import { NoResults } from '../../../common/NoResults';
import { Guid } from '../../../helpers/guid';
import { currencyFormat } from '../../products/functions/productHelper';
import { ICatalogueForReplacement } from '../../../interfaces/catalogue';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import SelectInput from '../../../form/SelectInput';
import arrayMutators from "final-form-arrays";
import { FieldArray } from 'react-final-form-arrays'
import NumericInput from '../../../form/NumericInput';
import { combineValidators, isRequired } from 'revalidate';

interface IProps {
    store: HubOrderReplacementStore,
    replacementProdId: String,
    replacementCatId: String,
    replaceFunction: (values: any) => Promise<void>;
}

const validate = combineValidators({
    price: isRequired(""),
    shopPrice: isRequired(""),
    vat: isRequired(""),
    hubOrderId: isRequired(""),
    catalogueId: isRequired(""),
    quantity: isRequired(""),
});

const HubOrderReplacement: React.FC<IProps> = ({ store, replaceFunction, replacementProdId, replacementCatId }) => {
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const { closeModal } = context.modalStore;

    useEffect(() => {
        store.oneTimeLoad()

        return () => {

        }
    }, [store])

    const [timer, setTimer] = React.useState(0);
    const [selectedItem, setSelectedItem] = React.useState<ICatalogueForReplacement | undefined>(undefined);

    return (
        <div>
            {!selectedItem &&
                <Fragment>
                    <Menu
                        secondary
                        size={isMobile ? "massive" : "small"}
                    >
                        <Menu.Item
                            className={!isMobile ? "no-padding" : ""}
                            style={{ flex: isMobile ? 1 : "" }}
                        >
                            <Input
                                fluid={isMobile}
                                transparent={isMobile}
                                size={isMobile ? "large" : "small"}
                                icon={!isMobile ? <Icon name="search" color="green" /> : undefined}
                                placeholder={"Search..."}
                                input={<input className={isMobile ? "" : "ctGreenPlaceholder"} />}
                                onChange={(event: ChangeEvent, data: any) => {
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }
                                    setTimer(
                                        setTimeout(
                                            (callback: (text: string) => void, text: string) => {
                                                callback(text);
                                                clearTimeout(timer);
                                            },
                                            500,
                                            store.search,
                                            data.value
                                        )
                                    );
                                }}
                            />
                        </Menu.Item>
                    </Menu>
                    <Divider />
                    <Segment
                        id="scrollableDiv"
                        style={{
                            boxShadow: "none",
                            border: "none",
                            padding: "0px",
                            height: 400, overflow: "auto"
                        }}
                    >
                        {store.isLoading && <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}

                        {store.products.length === 0 ? (store.isLoading ? <Fragment /> : <NoResults />) :

                            <InfiniteScroll style={{ overflow: "hidden" }}
                                dataLength={store.products.length}
                                scrollableTarget="scrollableDiv"
                                next={() => store.load()}
                                hasMore={store.hasMore}
                                loader={store.isLoading ? <h4>Loading...</h4> : <div />}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        {store.products.length > 0 ? (
                                            <b></b>
                                        ) : (
                                            <NoResults />
                                        )}
                                    </p>
                                }
                            >
                                <Table basic="very">
                                    {store.products.map((x, i) =>
                                        <Table.Row key={x.id} onDoubleClick={() => setSelectedItem(x)}>
                                            <Table.Cell>{x.title} - {x.measurment}{x.measurementUnit}</Table.Cell>
                                            <Table.Cell>
                                                <List horizontal>
                                                    {store.locations.length > 0 && x.prices.slice().sort((a, b) => a.price < b.price ? -1 : 1).map((x, i) =>
                                                        <List.Item key={`${Guid.newGuid()}`} style={{ textAlign: 'center', marginRight: "10px" }}>
                                                            <div className="fxDirCol" style={{ width: "65px" }}>
                                                                <div>{x.locations.map(l => store.locations.filter(s => s.id === l)[0].title)}</div>
                                                                <div style={{ fontSize: "16px", marginTop: "4px", fontWeight: "600" }}>{currencyFormat(x.price, true)}</div>
                                                                <div style={{ fontSize: "12px", fontWeight: "100", color: (x.profit && x.profit > 0 ? "green" : "red") }}>{currencyFormat(x.profit ?? 0, true)}</div>
                                                            </div>
                                                        </List.Item>
                                                    )}</List>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table>
                            </InfiniteScroll>
                        }
                    </Segment>
                </Fragment>}
            {selectedItem && <Fragment>
                <div className='fxDisplay fxFixed fxAlignCenter'>
                    <div className='fxStretch'>
                        <Header>{selectedItem.title}</Header>
                    </div>
                    <List horizontal>
                        {store.locations.length > 0 && selectedItem.prices.slice().sort((a, b) => a.price < b.price ? -1 : 1).map((x, i) =>
                            <List.Item key={`${Guid.newGuid()}`} style={{ textAlign: 'center', marginRight: "10px" }}>
                                <div className="fxDirCol" style={{ width: "65px" }}>
                                    <div>{x.locations.map(l => store.locations.filter(s => s.id === l)[0].title)}</div>
                                    <div style={{ fontSize: "16px", marginTop: "4px", fontWeight: "600" }}>{currencyFormat(x.price, true)}</div>
                                    <div style={{ fontSize: "12px", fontWeight: "100", color: (x.profit && x.profit > 0 ? "green" : "red") }}>{currencyFormat(x.profit ?? 0, true)}</div>
                                </div>
                            </List.Item>
                        )}</List>
                </div>

                <FinalForm
                    onSubmit={(values: any) =>
                        replaceFunction(values)
                            .then(() => toast.success("Saved", { autoClose: 1000 }))
                            .then(() => closeModal())
                            .catch((error) => ({
                                [FORM_ERROR]: error,
                            }))
                    }
                    initialValues={{
                        "catalogueId": selectedItem.id,
                        "replacementProdId": replacementProdId,
                        "replacementCatId": replacementCatId,
                    }}
                    validate={validate}
                    mutators={{
                        ...arrayMutators,
                    }}
                    render={({
                        handleSubmit,
                        dirtySinceLastSubmit,
                        submitting,
                        invalid,
                        values,
                        form,
                        form: {
                            mutators: { push, remove },
                        },
                    }) => (
                        <Form onSubmit={handleSubmit} error>
                            <Form.Group widths={'equal'}>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"Hub Order"}
                                    </label>
                                    <Field
                                        name={`hubOrderId`}
                                        component={SelectInput}
                                        options={store.hubOrders.map(x => ({
                                            key: x.id,
                                            value: x.id,
                                            text: x.displayId,
                                            description: store.locations.filter(f => f.accountId === x.accountId)[0].title
                                        }))}
                                    />
                                </Form.Field>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"Cost Price"}
                                    </label>
                                    <Field
                                        component={NumericInput}
                                        name={`shopPrice`}
                                    />
                                </Form.Field>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"Sale Price"}
                                    </label>
                                    <Field
                                        component={NumericInput}
                                        name={`price`}
                                    />
                                </Form.Field>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"Quantity"}
                                    </label>
                                    <Field
                                        component={NumericInput}
                                        name={`quantity`}
                                    />
                                </Form.Field>

                            </Form.Group>
                            <Form.Group widths={'equal'}>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"VAT Category"}
                                    </label>
                                    <Field
                                        component={SelectInput}
                                        options={store.VATCategories.map(v => ({
                                            'key': v.id!,
                                            'value': v.id!,
                                            'text': v.title
                                        }))}
                                        name="vat"
                                        placeholder={"Select VAT"}
                                    />
                                    {store.VATCategories.map((f) => (
                                        <Label
                                            color="blue"
                                            basic
                                            size="small"
                                            style={{ marginTop: "4px" }}
                                            content={f.title}
                                            onClick={() => {
                                                form.change(
                                                    "vat",
                                                    f.id!
                                                );
                                            }}
                                        />
                                    ))}
                                </Form.Field>
                                <Form.Field width={16}>
                                    <label style={{ fontSize: "13px" }}>
                                        Extras
                                    </label>
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    Item
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Quantity
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Unit Cost
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Total Cost
                                                </Table.HeaderCell>
                                                <Table.HeaderCell />
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            <FieldArray name="extras">
                                                {({ fields }) =>
                                                    fields.map((name, index) => (
                                                        <Table.Row key={index}>
                                                            <Table.Cell>
                                                                <Field
                                                                    name={`${name}.id`}
                                                                    component={SelectInput}
                                                                    options={store.Extras.map(x => ({ 'key': x.id, 'value': x.id, 'text': x.title }))}
                                                                    changeFinished={(value: any) => {
                                                                        if (value === "") {
                                                                            form.change(`${name}.fee`, 0);
                                                                            form.change(`${name}.vat_rate_id`, undefined);
                                                                        }
                                                                        else {
                                                                            form.change(`${name}.fee`, store.Extras.filter(f => f.id === value)[0].fee);
                                                                            form.change(`${name}.vat_rate_id`, store.Extras.filter(f => f.id === value)[0].vat_rate);
                                                                        }
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Field
                                                                    component={NumericInput}
                                                                    name={`${name}.quantity`}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Field
                                                                    component={NumericInput}
                                                                    name={`${name}.fee`}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {currencyFormat((values.extras[index]?.quantity ?? 0) * (values.extras[index]?.fee ?? 0), true)}
                                                            </Table.Cell>

                                                            <Table.Cell>
                                                                <Button
                                                                    color="red"
                                                                    icon="minus"
                                                                    circular
                                                                    size="mini"
                                                                    onClick={(event: SyntheticEvent) => {
                                                                        remove("extras", index);
                                                                        event.preventDefault();
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                }
                                            </FieldArray>
                                        </Table.Body>

                                        <Table.Footer fullWidth>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan="9">
                                                    <Button
                                                        floated="right"
                                                        basic
                                                        color="blue"
                                                        onClick={(event: SyntheticEvent) => {
                                                            push("extras", {});
                                                            event.preventDefault();
                                                        }}
                                                    >
                                                        <Icon name="add" />{" "}
                                                        Add
                                                    </Button>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                </Form.Field>
                            </Form.Group>

                            <div style={{ textAlign: "center" }}>
                                <Button.Group style={{ paddingTop: "15px" }}>
                                    <Button
                                        color="blue"
                                        disabled={(invalid && !dirtySinceLastSubmit)}
                                        loading={submitting}
                                        content={"Save"}
                                    />
                                    <Button.Or />
                                    <Button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            closeModal();
                                        }}
                                        content={"Cancel"}
                                    />
                                </Button.Group>
                            </div>
                        </Form>)}
                />
            </Fragment>}
        </div>
    )
}

export default observer(HubOrderReplacement)