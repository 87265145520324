import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Button, Divider, Form, Header, Message } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import {
  combineValidators,
  composeValidators,
  createValidator,
  isRequired,
} from "revalidate";
import { IShopForClaim } from "../../../interfaces/shopClaim";
import TextInput from "../../../form/TextInput";
import ErrorMessage from "../../../form/ErrorMessage";
import { useNavigate } from "react-router";
import SelectInput from "../../../form/SelectInput";
import CheckBox from "../../../form/CheckBox";
import LegalDocuments from "../../legal/LegalDocuments";

const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  (field) => "x"
);

const isChecked = (n: boolean) =>
  createValidator(
    (message) => (value: boolean) => {
      if (value !== n) {
        return message;
      }
    },
    (field) => "x"
  );

const validate = combineValidators({
  termsAndConditions: isChecked(true)(""),
  vat: isRequired(""),
  email: composeValidators(isRequired, isValidEmail)(""),
  phone: isRequired(""),
});

const ClaimShopWidget = () => {
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const { oneTimeLoad } = context.productStore;
  const { openClaim, load, loading, editingItem } = context.shopClaimStore;
  const { openModal } = context.modalStore;

  useEffect(() => {
    oneTimeLoad();
    load();

    return () => { };
  }, [oneTimeLoad, load]);

  return (
    <Fragment>
      {editingItem?.id && (
        <div style={{ maxWidth: "650px" }}>
          <Message
            info
            icon={"info"}
            header="We have received your request and we are currently process it!"
            content={
              <div>
                <p>
                  We have received your request and we are currently reviewing
                  it. If there is more than 48h passed and we did not contact
                  you kindly contact us at{" "}
                  <a href="mailto:info@cheaptrolley.com">
                    info@cheaptrolley.com
                  </a>
                </p>
              </div>
            }
          />
          <Divider />
        </div>
      )}
      <div style={{ textAlign: "center" }}>
        <Header
          content={
            editingItem?.id ? "Your claim request" : "Open a claim request"
          }
          color="blue"
        />
        <FinalForm
          onSubmit={(values: IShopForClaim) =>
            openClaim(values).catch((error) => ({
              [FORM_ERROR]: error,
            }))
          }
          initialValues={editingItem ?? {}}
          validate={validate}
          render={({
            handleSubmit,
            submitError,
            dirtySinceLastSubmit,
            submitting,
            invalid,
            pristine,
          }) => (
            <Form onSubmit={handleSubmit} error>
              <div style={{ minWidth: "350px", paddingTop: "15px" }}>
                <Form.Field style={{ textAlign: "left" }}>
                  <label style={{ fontSize: "13px" }}>{"Shop*"}</label>
                  <Field
                    loading={loading}
                    component={SelectInput}
                    options={markets
                      ?.slice()
                      .sort((a, b) => (a.title < b.title ? -1 : 1))
                      .map((x) => ({
                        key: x.id,
                        text: x.title,
                        value: x.id,
                      }))}
                    name="shopId"
                    placeholder={"Select a shop"}
                  />
                </Form.Field>
                <Form.Field style={{ textAlign: "left" }}>
                  <label style={{ fontSize: "13px" }}>{"VAT *"}</label>
                  <Field
                    component={TextInput}
                    name="vat"
                    placeholder={"VAT"}
                    loading={loading}
                  />
                </Form.Field>

                <Form.Field style={{ textAlign: "left" }}>
                  <label style={{ fontSize: "13px" }}>{"E-mail *"}</label>
                  <Field
                    component={TextInput}
                    loading={loading}
                    name="email"
                    placeholder={"E-mail"}
                  />
                </Form.Field>

                <Form.Field style={{ textAlign: "left" }}>
                  <label style={{ fontSize: "13px" }}>
                    {"Contact Number *"}
                  </label>
                  <Field
                    component={TextInput}
                    loading={loading}
                    name="phone"
                    placeholder={"phone"}
                  />
                </Form.Field>

                <Form.Field>
                  <Field
                    name="termsAndConditions"
                    component={CheckBox}
                    type="checkbox"
                    label={
                      <a
                        href="#/"
                        referrerPolicy="no-referrer"
                        onClick={() => {
                          openModal(<LegalDocuments mode="b2b" />, "small");
                        }}
                      >
                        {
                          "I accept the Cheap Trolley Policy and Terms & Conditions"
                        }
                      </a>
                    }
                  />
                </Form.Field>
              </div>

              {submitError && !dirtySinceLastSubmit && (
                <ErrorMessage error={submitError} />
              )}

              <div style={{ textAlign: "center" }}>
                <Button.Group style={{ paddingTop: "15px" }}>
                  <Button
                    color="blue"
                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                    loading={submitting}
                    content={"Submit Request"}
                  />
                  <Button.Or />
                  <Button
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(-1);
                    }}
                    content={"Cancel"}
                  />
                </Button.Group>
              </div>
            </Form>
          )}
        />
      </div>
    </Fragment>
  );
};

export default observer(ClaimShopWidget);
