import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Label, List } from "semantic-ui-react";
import { IShopForListDto } from "../../../interfaces/shop";
import { isWithinLast24Hours } from "../../../helpers/dateFormats";
import { format } from "date-fns";

interface IProps {
  shop: IShopForListDto;
}

export const ShopListItem: React.FC<IProps> = ({ shop }) => {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      // second;
    };
  }, []);

  return (
    <List.Item
      onClick={() => navigate(`/shop/${shop.id}`)}
      style={shop.update_results ? {
        backgroundColor: isWithinLast24Hours((shop.update_time ?? 0) * 1000) ? "#a0ffa0" : "#ff9e9e"
      } : {}}
    >
      <List.Content floated="right">
        {shop.update_results &&
          <div className="dxFixed fxDisplay">
            <div className="fxDirCol fxTextAlignCenter ui mini statistic">
              <div className="value">{shop.update_results.updated_ids ?? 0}</div>
              <div className="label">Updated</div>
            </div>
            <div className="fxDirCol fxTextAlignCenter ui mini statistic">
              <div className="value">{shop.update_results.inserted_ids ?? 0}</div>
              <div className="label">Added</div>
            </div>
            <div className="fxDirCol fxTextAlignCenter ui mini statistic">
              <div className="value">{shop.update_results.price_alt ?? 0}/{shop.update_results.added_offers ?? 0}</div>
              <div className="label">Prices/Offers</div>
            </div>
          </div>}
      </List.Content>
      <List.Content>
        <List.Header style={{ fontSize: "16px", paddingBottom: "6px" }}>
          {shop.title} {shop.update_time && <span> {format(new Date(shop.update_time * 1000), "dd/MM/yyyy HH:mm")}</span>}
        </List.Header>
        <List.Description>
          <span>
            {shop.aprovalStatus === "requested" && (
              <Label color="red" content="Pending Request" />
            )}
            {(shop.aprovalStatus === "requested") &&
              " - "}
            {shop.website && (
              <span
                className="div-as-link"
                onClick={() => {
                  window.open(shop.website, "_blank");
                }}
              >
                {shop.website}
              </span>
            )}
          </span>
        </List.Description>
      </List.Content>
    </List.Item>
  );
};
