import { action, computed, makeObservable, observable, reaction } from "mobx";
import { RootStore } from "./RootStoreContext";
import { IAccessToken } from "../interfaces/token";
import agent from "../api/agent";

export class SessionStore {
    rootStore: RootStore;

    @observable token: string | null = window.localStorage.getItem("jwt");
    @observable token_r: string | null = window.localStorage.getItem("jwt_r");

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;

        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem("jwt", token);
                } else {
                    window.localStorage.removeItem("jwt");
                }
            }
        );

        reaction(
            () => this.token_r,
            (token_r) => {
                if (token_r) {
                    window.localStorage.setItem("jwt_r", token_r);
                } else {
                    window.localStorage.removeItem("jwt_r");
                }
            }
        );
    }

    @computed get isLoggedIn() {
        if (this.token && this.token === "undefined undefined") {
            window.localStorage.removeItem("jwt");
            window.localStorage.removeItem("jwt_r");
        }

        return !!this.token;
    }

    @action logout = () => {
        const { StopHubConnection } = this.rootStore.signalRStore;
        StopHubConnection();

        this.token = null;
        this.token_r = null;

        this.rootStore.userStore.user = undefined;
        this.rootStore.commonStore.selectedMarketIds = [];

        window.location.assign("/")
    }

    @action setToken = (token: IAccessToken | null) => {
        this.token =
            token === null ? null : token?.token_type + " " + token?.access_token;

        this.token_r = token === null ? null : token?.refresh_token;
    };

    @action login = async (values: any) => {
        return agent.Auth.login(values).then((response: IAccessToken) => {
            this.setToken(response);
            return response;
        }
        )
    }

}