import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Button, Divider, Icon, List, Menu, Statistic } from "semantic-ui-react";
import { WalletTransactionTypes } from "../../options/WalletTransactionTypes";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { currencyFormat } from "../products/functions/productHelper";
import ManualTransactionWidget from "./components/ManualTransactionWidget";

const ClientCredits = () => {
  let { userId } = useParams<"userId">();
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { isLoggedIn } = context.sessionStore;
  const { user, isAdmin } = context.userStore;
  const { setPage } = context.analyticsStore;
  const { openModal } = context.modalStore;
  const { wallet, loading, getWallet, dispose } = context.clientCreditStore;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }

    setPage("Credits", window.location.pathname);
    return () => {
      dispose();
    };
  }, [dispose, isLoggedIn, setPage, navigate]);

  useEffect(() => {
    if (wallet === undefined) getWallet(userId);

    return () => { };
  }, [user, getWallet, userId, wallet]);

  return (
    <div className="simply-paddedPage">
      {isAdmin && <Fragment>
        <Menu
          secondary
          size="small"
          style={{ margin: "0 0 5px 0" }}>
          <Menu.Menu position="right">
            <Button
              content="Manual Transaction"
              basic
              color="red"
              onClick={() => openModal(<ManualTransactionWidget userId={userId!} />, "mini", "Manual Transactions", false, false, () => getWallet(userId))}
            />
          </Menu.Menu>
        </Menu>
        <Divider />
      </Fragment>}
      <div style={{ textAlign: "center" }}>
        <Statistic>
          <Statistic.Label>Credits</Statistic.Label>
          <Statistic.Value>
            <Icon loading={loading} name={loading ? "circle notch" : "euro"} />
            {currencyFormat(wallet?.balance ?? 0, false)}
          </Statistic.Value>
        </Statistic>
      </div>
      <Divider />
      <List divided style={{ padding: "10px" }}>
        <List.Item>
          <div className="fxFixed fxDisplay fxWrap">
            <div>Date</div>
            <div className="fxStretch fxTextAlignCenter">Type</div>
            <div>Amount</div>
          </div>
        </List.Item>
        {wallet?.transactions.map((x) => (
          <List.Item key={x.productId} className="credit-list-item">
            <div className="fxDisplay ">
              <div>{format(x.createdOn * 1000, "dd/MM/yyyy, HH:mm")}</div>
              <div className="fxStretch fxTextAlignCenter">
                {WalletTransactionTypes.filter((f) => f.key === x.type)[0].text}
              </div>
              <div>{currencyFormat(x.amount)}</div>
            </div>
          </List.Item>
        ))}
        {!wallet && (
          <List.Item className="credit-list-item ">
            <div
              className="fxStretch fxTextAlignCenter"
              style={{ padding: "20px" }}
            >
              There are no credit/debit transactions in your wallet.
            </div>
          </List.Item>
        )}
      </List>
    </div>
  );
};

export default observer(ClientCredits);
