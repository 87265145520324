import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Form, Button, Grid } from 'semantic-ui-react';
import { Form as FinalForm, Field } from "react-final-form";
import ErrorMessage from '../../../form/ErrorMessage';
import TextInput from '../../../form/TextInput';
import { combineValidators, isRequired } from 'revalidate';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { FORM_ERROR } from "final-form";
import arrayMutators from "final-form-arrays";
import SelectInput from '../../../form/SelectInput';
import { IAddress } from '../../../interfaces/user';
import { Countries } from '../../../options/Countries';
import { Localities } from '../../../options/Localities';

interface IProps {
    address?: IAddress;
}

// const isValidAddress = createValidator(
//     (message) => (value) => {
//         if (value === undefined || value.lat === undefined || value.lng === undefined || value.lat === 0 || value.lng === 0) {
//             return message;
//         }
//     },
//     (field) => "x"
// );

const validate = combineValidators({
    title: isRequired(""),
    countryId: isRequired(""),
    localityId: isRequired(""),
    addressLine1: isRequired(""),
    // geolocation: composeValidators(isRequired(""), isValidAddress(""))(""),
});

const AddressDetailWidget: React.FC<IProps> = ({ address }) => {
    const context = useContext(RootStoreContext);
    // const { mapKey } = context.locationIQStore;
    const { closeModal } = context.modalStore;
    const { saveNewAddress } = context.userAddressesStore;

    // const [point, setPoint] = useState<ILocationPoint | undefined>((address?.geolocation ?? undefined) !== undefined ? (address!.geolocation! as ILocationPoint) : undefined);

    return (
        <FinalForm
            onSubmit={(values: IAddress) =>
                saveNewAddress(values)
                    .catch((error) => ({
                        [FORM_ERROR]: error,
                    })).then(() => closeModal())
            }
            initialValues={address ?? undefined}
            mutators={{
                ...arrayMutators,
            }}
            validate={validate}
            render={({
                handleSubmit,
                submitError,
                dirtySinceLastSubmit,
                submitting,
                invalid,
                pristine,
                values,
                form,
                form: {
                    mutators: { push, remove },
                },
            }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={16} stackable>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"Country"}
                                    </label>
                                    <Field
                                        fluid
                                        // allowEdit={allowCountry}
                                        name={`countryId`}
                                        component={SelectInput}
                                        options={Countries.filter((f) => f.active === true)}
                                        placeholder={"Country"}
                                        changeFinished={() => { }}
                                    />
                                </Form.Field>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"Locality"}
                                    </label>
                                    <Field
                                        fluid
                                        name={`localityId`}
                                        component={SelectInput}
                                        options={Localities}
                                        placeholder={"Locality"}
                                        changeFinished={() => { }}
                                    />
                                </Form.Field>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"Title (e.g. Home, Work)"}
                                    </label>
                                    <Field
                                        fluid
                                        // allowEdit={allowCountry}
                                        name={`title`}
                                        component={TextInput}
                                        options={Countries.filter((f) => f.active === true)}
                                        placeholder={"Title"}
                                        changeFinished={() => { }}
                                    />
                                </Form.Field>
                                <Form.Group widths={'equal'}>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>
                                            {"Building Name/Number , Street Name"}
                                        </label>
                                        <Field
                                            fluid
                                            name={`addressLine1`}
                                            component={TextInput}
                                            placeholder={"Building Name/Number , Street Name"}
                                        />
                                    </Form.Field>
                                    {/* <div style={{ display: "flex", alignItems: "end" }}>
                                        <Button
                                            className='ct-green-button-active'
                                            disabled={(values.addressLine1?.trim() ?? "") === ""}
                                            icon={"location arrow"}
                                            // loading={submitting}
                                            // content={"Search"}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (!mapKey) return;
                                                if ((values.addressLine1?.trim() ?? "") !== "") {
                                                    console.log(values.localityId)
                                                    const addrLine = `${values.addressLine1} ${values.localityId !== undefined ? Localities.filter(f => f.value === values.localityId)[0].text : ""}`
                                                    agent.LocationIQ.searchAddress(addrLine, mapKey).then((result) => {
                                                        if (result.length > 0) {
                                                            setPoint({
                                                                lng: result[0].lon,
                                                                lat: result[0].lat
                                                            })
                                                        }

                                                    })
                                                }
                                            }}
                                        />
                                    </div> */}
                                </Form.Group>

                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"Flat Number, Interphone name"}
                                    </label>
                                    <Field
                                        fluid
                                        name={`addressLine2`}
                                        component={TextInput}
                                        placeholder={"Flat Number, Interphone name"}
                                    />
                                </Form.Field>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"Post Code"}
                                    </label>
                                    <Field
                                        fluid
                                        name={`postCode`}
                                        component={TextInput}
                                        placeholder={"Post Code"}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            {/* <Grid.Column width={8}>
                                <LocationIQ height='450px' point={point} updateCenter={(lng, lat) => {
                                    form.change("latitude", `${lat}`)
                                    form.change("longitude", `${lng}`)
                                    form.change("geolocation", {
                                        'lng': lng,
                                        'lat': lat,
                                    })
                                }} />
                            </Grid.Column> */}
                        </Grid.Row>
                    </Grid>

                    {submitError && !dirtySinceLastSubmit && (
                        <ErrorMessage error={submitError} />
                    )}

                    <div style={{ textAlign: "center" }}>
                        <Button.Group style={{ paddingTop: "15px" }}>
                            <Button
                                className='ct-green-button-active'
                                disabled={(invalid && !dirtySinceLastSubmit)}
                                loading={submitting}
                                content={"Save"}
                            />
                            <Button.Or />
                            <Button
                                onClick={(event) => {
                                    event.preventDefault();
                                    closeModal();
                                }}
                                content={"Cancel"}
                            />
                        </Button.Group>
                    </div>

                </Form>
            )}
        />

    );
}

export default observer(AddressDetailWidget);