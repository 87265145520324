import React, { Fragment } from "react";
import { Field } from "react-final-form";
import SelectInput from "../form/SelectInput";
import TextInput from "../form/TextInput";
import { Countries } from "../options/Countries";
import { Localities } from "../options/Localities";

interface IProps {
  allowCountry?: boolean;
  propertyName?: string;
  requiredFields?: string[];
}

// USED ONLY IN BILLING FOR NOW

const AddressWidget: React.FC<IProps> = ({
  allowCountry = true,
  propertyName = "",
  requiredFields = [],
}) => {
  return (
    <Fragment>
      <span>{"countryId" in requiredFields ? "*" : ""}</span>
      <Field
        fluid
        allowEdit={allowCountry}
        name={`${propertyName}countryId`}
        component={SelectInput}
        options={Countries.filter((f) => f.active === true)}
        placeholder={"Country"}
        changeFinished={() => { }}
      />
      <span>{"localityId" in requiredFields ? "*" : ""}</span>
      <Field
        fluid
        name={`${propertyName}localityId`}
        component={SelectInput}
        options={Localities}
        placeholder={"Locality"}
        changeFinished={() => { }}
      />
      <span>{"addressLine1" in requiredFields ? "*" : ""}</span>
      <Field
        fluid
        name={`${propertyName}addressLine1`}
        component={TextInput}
        placeholder={"Building Name/Number , Street Name"}
      />
      <span>{"addressLine2" in requiredFields ? "*" : ""}</span>
      <Field
        fluid
        name={`${propertyName}addressLine2`}
        component={TextInput}
        placeholder={"Flat Number, Interphone name"}
      />
      <span>{"postCode" in requiredFields ? "*" : ""}</span>
      <Field
        fluid
        name={`${propertyName}postCode`}
        component={TextInput}
        placeholder={"Post Code"}
      />
      {/* <span>{"city" in requiredFields ? "*" : ""}</span>
      <Field
        fluid
        name={`${propertyName}city`}
        component={TextInput}
        placeholder={"City"}
      />
      <span>{"stateProvinceRegion" in requiredFields ? "*" : ""}</span>
      <Field
        fluid
        name={`${propertyName}state`}
        component={TextInput}
        placeholder={"State/Province/Region"}
      /> */}
    </Fragment>
  );
};

export default AddressWidget;
