import { WalletTransactionType } from "../options/WalletTransactionTypes";
import { CheckoutType } from "./order";

export interface IVatSummaryItem {
    id: string;
    title: string;
    code: string;
    rate: number;
    net: number;
    vat: number;
    total: number;
}

export interface IItemizedSaleItem {
    id: string;
    title: string;
    account?: string;
    accountId?: string;
    quantity: number
    costNet: number
    costVat: number
    salesNet: number
    profit: number
    vat: number
}

export interface IProfitItem {
    id: string;
    costNet: number
    salesNet: number
    profit: number
    vat: number
    firstDate: number
}

export interface ICreditItem {
    id: string;
    userId: string
    name: string
    lastName: string
    amount: number
}

export interface IWalletTransaction {
    id: string;
    userId: string;
    name: string;
    lastName: string;
    adminId: string;
    adminName: string;
    createdOn: number;
    amount: number;
    type: WalletTransactionType;
    orderId?: string;
}

export interface ISaleReportItem {
    id: string;
    salesNet: number
    vat: number
}


export interface IReceipt {
    id: string;
    userId: string;
    name: string;
    lastName: string;
    vat_rates: VatRates;
    paidBy?: string;
    receiptNo?: string;
    createdOn?: number;
    deliveryFee?: number;
    checkoutType: CheckoutType;
}

export interface VatRates {
    [key: string]: VatRate;
}

export interface VatRate {
    id: string;
    rate: number;
    code: string;
    net: number;
    vat: number;
    net_disc: number;
    vat_disc: number;
}

export const sumNetDiscOrNet = (vatRates: VatRates): number => {
    return Object.values(vatRates).reduce((sum, vatRate) => {
        return sum + (vatRate.net_disc ?? vatRate.net);
    }, 0);
}

export const sumVatDiscOrVat = (vatRates: VatRates): number => {
    return Object.values(vatRates).reduce((sum, vatRate) => {
        return sum + (vatRate.vat_disc ?? vatRate.vat);
    }, 0);
}

export const getNetDetail = (vatRates: VatRates, code: string): number => {
    const vat_item = Object.values(vatRates).filter(f => f.code === code)[0];

    if (vat_item === undefined) return 0;

    return vat_item.net_disc ?? vat_item.net;
}


export const getVatDetail = (vatRates: VatRates, code: string): number => {
    const vat_item = Object.values(vatRates).filter(f => f.code === code)[0];

    if (vat_item === undefined) return 0;

    return vat_item.vat_disc ?? vat_item.vat;
}