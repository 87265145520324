import { action, computed, makeObservable, observable } from "mobx";
import { SemanticWIDTHS } from "semantic-ui-react";
import agent from "../../../api/agent";
import { IProductForUpdate, ITrackerProductForList } from "../../../interfaces/product";
import { RootStore } from "../../../stores/RootStoreContext";
import { ShopCategory } from "../../../interfaces/categories";

export class PriceTrackerStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable groupedOnly: boolean = false;
    @observable nonDelivery: boolean = false;
    @observable downloading: boolean = false;
    @observable percentage: boolean = false;
    @observable discontinued: boolean = false;
    @observable currentPage: number = 0;
    @observable shopId?: string = undefined;
    @observable editingId?: string = undefined;
    @observable hasMore: boolean = false;
    @observable products: ITrackerProductForList[] = []
    @observable editingItem?: IProductForUpdate = undefined
    @observable searchText: string = "";
    @observable selectedMarketIds: string[] = [];
    @observable selectedCategories: string[] = [];
    @observable shopCategories: ShopCategory[] = [];

    @action setSelectedMarketIds = (value: string[]) => this.selectedMarketIds = value;
    @action setSelectedCategories = (value: string[]) => this.selectedCategories = value;
    @action setLoading = (value: boolean) => this.loading = value;
    @action setGroupedOnly = (value: boolean) => this.groupedOnly = value;
    @action setNonDelivery = (value: boolean) => this.nonDelivery = value;
    @action setDownoading = (value: boolean) => this.downloading = value;
    @action setShopCategories = (value: ShopCategory[]) => this.shopCategories = value;
    @action setShopId = (value?: string) => this.shopId = value;
    @action setEditingItem = (value?: IProductForUpdate) => this.editingItem = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setEditingId = (value?: string) => this.editingId = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetProducts = (value: ITrackerProductForList[]) => this.products = value;
    @action setProducts = (value: ITrackerProductForList[]) => {
        var existing = this.products.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.products.push(...missing);
    };


    @action load_once = async (shopId?: string) => {
        if (this.shopCategories.length !== 0) return;

        agent.ProductCategories.getShop(shopId).then((value) => {
            this.setShopCategories(value);
        })
    }

    @computed get markets() {
        const { account_markets } = this.rootStore.accountPageStore;
        const { markets } = this.rootStore.cacheStore;
        const { isAdmin } = this.rootStore.userStore;

        return isAdmin ? markets : account_markets;
    }


    @action load = async (shopId?: string) => {
        try {
            shopId = shopId ?? this.shopId
            this.setShopId(shopId);
            this.setLoading(true);

            var response = await agent.PriceTracker.get(this.searchText, this.selectedCategories, this.selectedMarketIds, this.groupedOnly, this.nonDelivery, shopId, this.discontinued, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setProducts(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action loadItemDetail = async () => {
        try {
            if (!this.editingId) return;

            this.setLoading(true);

            var product = await agent.Inventory.getDetail(this.editingId);
            this.setEditingItem(product);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action search = async (text: string) => {
        try {
            this.searchText = text?.trim() ?? "";
            this.resetProducts([]);
            this.setCurrentPage(0)

            this.load(this.shopId);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @computed get getItemsPerRow(): SemanticWIDTHS {
        const { isMobile, isPortrait, isTablet } = this.rootStore.deviceStore;

        var retVal: SemanticWIDTHS = 2;
        if ((isMobile || isTablet) && isPortrait)
            retVal = 1;
        if (isTablet && !isPortrait)
            retVal = 2;
        if (isMobile && !isPortrait)
            retVal = 2;

        return retVal;
    }

    @action changeDiscontinued = async () => {
        try {
            this.discontinued = !this.discontinued;
            this.resetProducts([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.searchText = ""
        this.setShopId(undefined);
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetProducts([]);
        this.setHasMore(false);
    }

    @action disposeDetail = () => {
        this.setLoading(false);
        this.setEditingId(undefined)
        this.setEditingItem(undefined)
    }

    @action getColumnsForExportRow = (product: ITrackerProductForList) => {
        var columnsToReturn: string[] = [];

        columnsToReturn.push(product.sku ?? '')
        columnsToReturn.push(product.itemDesc ?? '')

        const { accountId } = this.rootStore.userStore;

        if (accountId) {
            columnsToReturn.push(product.currentPrice.toFixed(2))
        }

        (this.selectedMarketIds.length > 0 ?
            this.markets.filter(f => this.selectedMarketIds.includes(f.type_id)) :
            this.markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1)).filter(f => f.id !== accountId).forEach(x => {
                columnsToReturn.push(product.others
                    ?.filter(f => f.marketId === x.type_id)[0]?.currentPrice?.toFixed(2) ?? "");
            });

        return columnsToReturn;
    };

    @action exportDataForCsv = async () => {
        this.setDownoading(true);

        try {
            var complexData = await agent.PriceTracker.export(
                this.searchText,
                this.selectedCategories,
                this.selectedMarketIds,
                this.shopId,
                this.discontinued);

            const { accountId } = this.rootStore.userStore;

            var csvData: any[] = [];
            var headers = ["SKU", "Description"]
            if (accountId) {
                headers.push(this.markets.filter(f => f.id === accountId)[0].title)
            }

            (this.selectedMarketIds.length > 0 ?
                this.markets.filter(f => this.selectedMarketIds.includes(f.type_id) && !f.shutDown) :
                this.markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1)).filter(f => f.id !== accountId && !f.shutDown).forEach(x => {
                    headers.push(x.title);
                });

            csvData.push(headers);

            complexData.forEach((row: any) => {
                csvData.push(this.getColumnsForExportRow(row));
            });

            var today = new Date();
            var fileName = `${today.getFullYear()}_${today.getMonth()}_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
            return {
                data: csvData,
                filename: `cheap_trolley_export_${fileName}.csv`,
            };
        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownoading(false);
        }
    };

    @action changePercentage = async () => {
        try {
            this.percentage = !this.percentage;
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };
}