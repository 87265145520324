import { action, makeObservable, observable } from "mobx";
import { RootStore } from "../../../stores/RootStoreContext";

export class MenuStore {
    rootStore: RootStore;

    @observable sidebarVisible: boolean = false;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action setSidebarVisible = (value: boolean) => {
        this.sidebarVisible = value;
    }
}