import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IProductCategoryForCreate, ProductCategory } from "../../../interfaces/categories";
import { RootStore } from "../../../stores/RootStoreContext";

export class CategoriesStore {
    rootStore: RootStore;

    @observable categories: ProductCategory[] = [];
    @observable all_categories: ProductCategory[] = [];

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action setCategories = (value: ProductCategory[]) => this.categories = value.sort((n1, n2) => n1.order - n2.order);
    @action setAllCategories = (value: ProductCategory[]) => this.all_categories = value.sort((n1, n2) => n1.order - n2.order);

    @action load = async () => {
        await agent.ProductCategories.getMain().then(value => this.setCategories(value));
    }

    @action loadAll = async () => {
        await agent.ProductCategories.getAll().then(value => this.setAllCategories(value));
    }

    @action save = async (values: IProductCategoryForCreate) => {
        return agent.ProductCategories.create(values);
    }

}