import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { isMobile } from 'react-device-detect';
import { Menu, Dropdown, DropdownProps, Button, Divider, Dimmer, Loader, Segment, Table, Icon, Header } from 'semantic-ui-react';
import { Countries } from '../../../options/Countries';
import { DateTimePicker } from "react-widgets";
import { VatSummaryReportStore } from '../functions/VatSummaryReportStore';
import InfiniteScroll from 'react-infinite-scroll-component'
import { currencyFormat } from '../../products/functions/productHelper';
import CsvExport from '../../../common/csvExport';
import { NoResults } from '../../../common/NoResults';


const VatSummaryReport = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isAdmin } = context.userStore;

    const {
        setDateFrom,
        setDateTo,
        setfrDateFrom,
        setfrDateTo,
        setCountryId,
        search,
        dateFrom,
        frdateFrom,
        items,
        hasMore,
        load,
        loading,
        exportDataForCsv,
        downloading,
        dispose } = VatSummaryReportStore;

    const [timer, setTimer] = React.useState(0);
    const [valueFrom, setValueFrom] = React.useState<Date | null | undefined>();
    const [valueTo, setValueTo] = React.useState<Date | null | undefined>();
    const [frvalueFrom, setfrValueFrom] = React.useState<Date | null | undefined>();
    const [frvalueTo, setfrValueTo] = React.useState<Date | null | undefined>();

    useEffect(() => {
        if (!isAdmin) {
            navigate("/")
            return;
        }
        return () => {
            dispose()
        }
    }, [isAdmin, navigate, dispose])

    return (
        <div className="simply-paddedPage">
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "0 0 5px 0" }}
            >
                <Segment style={{ marginBottom: "0px" }}>
                    <Header style={{ marginBottom: "7px" }} size="tiny">Country</Header>
                    <Menu secondary>
                        <Menu.Item className={"no-padding"}>
                            <Dropdown
                                placeholder={"Select Country.."}
                                closeOnBlur
                                selection
                                clearable
                                search
                                onChange={(ev, data: DropdownProps) => {
                                    setCountryId(data?.value as number)
                                }}
                                options={Countries.filter((f) => f.active === true)
                                    ?.slice()
                                    .sort((a, b) => (a.text < b.text ? -1 : 1))
                                }
                            />
                        </Menu.Item>
                    </Menu>
                </Segment>
                <Segment style={{ marginLeft: "4px", marginTop: "0px", marginBottom: "0px" }}>
                    <Header style={{ marginBottom: "7px" }} size="tiny">Order Created</Header>
                    <Menu secondary>
                        <Menu.Item className={"no-padding"}>
                            <DateTimePicker
                                value={valueFrom}
                                placeholder={"Date From"}
                                onChange={(date: Date | null | undefined, rawValue: string) => {
                                    // date?.setUTCHours(0, 0, 0, 1);
                                    var dateNum = date?.getTime() ?? undefined;
                                    setValueFrom(date);
                                    setDateFrom((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                    if (!search) return;
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }

                                    setTimer(
                                        setTimeout(
                                            (callback: () => void, text: string) => {
                                                callback();
                                                clearTimeout(timer);
                                            },
                                            1000,
                                            search
                                        )
                                    );
                                }}
                                includeTime={true}
                                timePrecision={'minutes'}
                                max={new Date()}
                            />
                        </Menu.Item>
                        <Menu.Item className={"no-padding"}>
                            <DateTimePicker
                                placeholder={"Date To"}
                                value={valueTo}
                                onChange={(date: Date | null | undefined, rawValue: string) => {
                                    // date?.setUTCHours(23, 59, 59, 999);
                                    setValueTo(date);
                                    var dateNum = date?.getTime() ?? undefined;
                                    setDateTo((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                    if (!search) return;
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }

                                    setTimer(
                                        setTimeout(
                                            (callback: () => void, text: string) => {
                                                callback();
                                                clearTimeout(timer);
                                            },
                                            500,
                                            search
                                        )
                                    );
                                }}
                                includeTime={true}
                                timePrecision={'minutes'}
                                max={new Date()}
                                min={dateFrom ? new Date(dateFrom * 1000) : undefined}
                            />
                        </Menu.Item>
                    </Menu>
                </Segment>
                <Segment style={{ marginLeft: "4px", marginTop: "0px", marginBottom: "0px" }}>
                    <Header style={{ marginBottom: "7px" }} size="tiny">Receipt Printed</Header>
                    <Menu secondary>
                        <Menu.Item className={"no-padding"}>
                            <DateTimePicker
                                value={frvalueFrom}
                                placeholder={"Date From"}
                                onChange={(date: Date | null | undefined, rawValue: string) => {
                                    // date?.setUTCHours(0, 0, 0, 1);
                                    var dateNum = date?.getTime() ?? undefined;
                                    setfrValueFrom(date);
                                    setfrDateFrom((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                    if (!search) return;
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }

                                    setTimer(
                                        setTimeout(
                                            (callback: () => void, text: string) => {
                                                callback();
                                                clearTimeout(timer);
                                            },
                                            1000,
                                            search
                                        )
                                    );
                                }}
                                includeTime={true}
                                timePrecision={'minutes'}
                                max={new Date()}
                            />
                        </Menu.Item>
                        <Menu.Item className={"no-padding"}>
                            <DateTimePicker
                                placeholder={"Date To"}
                                value={frvalueTo}
                                onChange={(date: Date | null | undefined, rawValue: string) => {
                                    // date?.setUTCHours(23, 59, 59, 999);
                                    setfrValueTo(date);
                                    var dateNum = date?.getTime() ?? undefined;
                                    setfrDateTo((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                    if (!search) return;
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }

                                    setTimer(
                                        setTimeout(
                                            (callback: () => void, text: string) => {
                                                callback();
                                                clearTimeout(timer);
                                            },
                                            500,
                                            search
                                        )
                                    );
                                }}
                                includeTime={true}
                                timePrecision={'minutes'}
                                max={new Date()}
                                min={frdateFrom ? new Date(frdateFrom * 1000) : undefined}
                            />
                        </Menu.Item>
                    </Menu>
                </Segment>
                <Menu.Item>
                    <Button
                        color="green"
                        icon='refresh'
                        basic
                        className="no-margin"
                        onClick={() => search()}
                    />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item icon title="Export" style={{ padding: "0px" }}>
                        <CsvExport
                            onClick={exportDataForCsv}
                            content={
                                <Fragment>
                                    <Icon name="file excel outline" /> Export
                                </Fragment>
                            }
                            disabled={items.length === 0 || downloading}
                            loading={downloading}
                        />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider />
            {!loading || items.length > 0 ? (
                <InfiniteScroll
                    style={{ overflow: "hidden" }}
                    dataLength={items.length} //This is important field to render the next data
                    next={() => load()}
                    hasMore={hasMore}
                    loader={items ? <h4>Loading...</h4> : <div />}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            {items.length > 0 ? (
                                <b></b>
                            ) : (
                                <NoResults />
                            )}
                        </p>
                    }
                >
                    <Table basic="very" celled >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>VAT Rate</Table.HeaderCell>
                                <Table.HeaderCell>VAT Code</Table.HeaderCell>
                                <Table.HeaderCell>Description</Table.HeaderCell>
                                <Table.HeaderCell>Total VAT</Table.HeaderCell>
                                <Table.HeaderCell>Sales ex. VAT</Table.HeaderCell>
                                <Table.HeaderCell>Sales inc. VAT</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {items.slice().sort((a, b) => a.rate > b.rate ? 1 : -1).map((x) => (
                                <Table.Row key={x.id}>
                                    <Table.Cell>{`${x.rate * 100}%`}</Table.Cell>
                                    <Table.Cell>{x.code}</Table.Cell>
                                    <Table.Cell>{x.title}</Table.Cell>
                                    <Table.Cell textAlign='right'>{currencyFormat(x.vat, true)}</Table.Cell>
                                    <Table.Cell textAlign='right'>{currencyFormat(x.net, true)}</Table.Cell>
                                    <Table.Cell textAlign='right'>{currencyFormat(x.total, true)}</Table.Cell>
                                </Table.Row>

                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>Totals</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.vat, 0), true)}</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.net, 0), true)}</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.total, 0), true)}</Table.HeaderCell>
                        </Table.Footer>
                    </Table>
                </InfiniteScroll>
            ) : (
                <Segment
                    style={{
                        minHeight: "55vh",
                        boxShadow: "none",
                        border: "none",
                    }}
                >
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                </Segment>
            )}
        </div>
    )
}

export default observer(VatSummaryReport)