import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import arrayMutators from "final-form-arrays";
import { Form as FinalForm, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  Dimmer,
  Form,
  Header,
  Loader,
  Segment,
  Image,
} from "semantic-ui-react";
import { LoadingParagraph } from "../../components/LoadingParagraph";
import CardWithUpload from "../../components/photoUpload/CardWithUpload";
import DateInput from "../../form/DateInput";
import ErrorMessage from "../../form/ErrorMessage";
import NumericInput from "../../form/NumericInput";
import SelectInput from "../../form/SelectInput";
import TextInput from "../../form/TextInput";
// import { IAdvertFile, ICreateUpdateAdvert } from "../../interfaces/advert";
import { AdPanelPlatform } from "../../options/AdPanelPlatform";
import { AdPanelSize } from "../../options/AdPAnelSize";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { FORM_ERROR } from "final-form";
import {
  combineValidators,
  isRequired,
  isNumeric,
  composeValidators,
} from "revalidate";
import { toast } from "react-toastify";
import { AdImageSizes } from "../../options/AdImageSizes";

const validate = combineValidators({
  title: isRequired(""),
  redirectAt: isRequired(""),
  size: isRequired(""),
  startsOn: isRequired(""),
  platform: isRequired(""),
  duration: composeValidators(isRequired(""), isNumeric(""))(""),
  budget: composeValidators(isRequired(""), isNumeric(""))(""),
});

const CreateAdCampaign = () => {
  return <div />
  // let { id } = useParams<"id">();
  // const navigate = useNavigate();
  // const context = useContext(RootStoreContext);
  // const { setPage } = context.analyticsStore;
  // const { isMobile } = context.deviceStore;
  // const { isLoggedIn } = context.sessionStore;
  // const { loading, save, load, dispose, currentEditingItem, uploadFile } =
  //   context.adDetailStore;

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate("/");
  //     return;
  //   }

  //   if (id) setPage("Edit an Add", window.location.pathname);
  //   else setPage("Create new Add", window.location.pathname);

  //   if (id) load(id);

  //   return () => {
  //     dispose();
  //   };
  // }, [isLoggedIn, load, id, navigate, dispose, setPage]);

  // return (
  //   <div
  //     style={isMobile ? { paddingLeft: "5px", paddingRight: "5px" } : undefined}
  //   >
  //     <FinalForm
  //       mutators={{
  //         ...arrayMutators,
  //       }}
  //       onSubmit={(values: ICreateUpdateAdvert) =>
  //         save(values)
  //           .then(() => {
  //             toast.success(
  //               "Your campaign is saved. When you are ready press 'Submit' so as to send it over to us for a review; before we get it published."
  //             );
  //             navigate(-1);
  //           })
  //           .catch((error) => ({
  //             [FORM_ERROR]: error,
  //           }))
  //       }
  //       initialValues={
  //         currentEditingItem ?? {
  //           platform: ["mobile", "tablet", "desktop"],
  //           size: "small",
  //           files: [],
  //         }
  //       }
  //       validate={validate}
  //       render={({
  //         handleSubmit,
  //         submitError,
  //         dirtySinceLastSubmit,
  //         submitting,
  //         valid,
  //         values,
  //         form: {
  //           mutators: { push, remove },
  //         },
  //       }) => (
  //         <Form onSubmit={handleSubmit} error>
  //           {loading && (
  //             <Segment>
  //               <Dimmer active inverted>
  //                 <Loader inverted>Loading</Loader>
  //               </Dimmer>
  //               <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
  //             </Segment>
  //           )}
  //           <div style={{ textAlign: "center" }}>
  //             <Form.Field style={{ textAlign: "left" }}>
  //               <label style={{ fontSize: "13px" }}>{"Title"}</label>
  //               {loading ? (
  //                 <LoadingParagraph />
  //               ) : (
  //                 <Field
  //                   loading={loading}
  //                   component={TextInput}
  //                   name="title"
  //                   placeholder={"Title"}
  //                 />
  //               )}
  //             </Form.Field>
  //             <Form.Group widths={2}>
  //               <Form.Field style={{ textAlign: "left" }}>
  //                 <label style={{ fontSize: "13px" }}>
  //                   {"Select platform(s)"}
  //                 </label>

  //                 <Field
  //                   loading={loading}
  //                   component={SelectInput}
  //                   options={AdPanelPlatform}
  //                   allowEdit={false}
  //                   multiselect={true}
  //                   name="platform"
  //                   placeholder={"Select platform(s)"}
  //                 />
  //               </Form.Field>
  //               <Form.Field style={{ textAlign: "left" }}>
  //                 <label style={{ fontSize: "13px" }}>{"Panel Size"}</label>
  //                 {loading ? (
  //                   <LoadingParagraph />
  //                 ) : (
  //                   <Field
  //                     loading={loading}
  //                     component={SelectInput}
  //                     options={AdPanelSize}
  //                     name="size"
  //                     placeholder={"Select size"}
  //                   />
  //                 )}
  //               </Form.Field>
  //             </Form.Group>
  //             <Form.Group widths={3}>
  //               <Form.Field style={{ textAlign: "left" }}>
  //                 <label style={{ fontSize: "13px" }}>{"Start date"}</label>
  //                 {loading ? (
  //                   <LoadingParagraph />
  //                 ) : (
  //                   <Field
  //                     component={DateInput}
  //                     min={
  //                       new Date((new Date().getTime() / 1000 + 86400) * 1000)
  //                     }
  //                     name="startsOn"
  //                     date={true}
  //                     time={false}
  //                     placeholder={"Select date"}
  //                   />
  //                 )}
  //               </Form.Field>
  //               <Form.Field style={{ textAlign: "left" }}>
  //                 <label style={{ fontSize: "13px" }}>
  //                   {"Duration (Days)"}
  //                 </label>
  //                 {loading ? (
  //                   <LoadingParagraph />
  //                 ) : (
  //                   <Field
  //                     component={NumericInput}
  //                     name="duration"
  //                     placeholder={"Number of days the campaign will run"}
  //                   />
  //                 )}
  //               </Form.Field>
  //               <Form.Field style={{ textAlign: "left" }}>
  //                 <label style={{ fontSize: "13px" }}>
  //                   {"Daily budget (€)"}
  //                 </label>
  //                 {loading ? (
  //                   <LoadingParagraph />
  //                 ) : (
  //                   <Field
  //                     component={NumericInput}
  //                     name="budget"
  //                     placeholder={"Max budget to be spent per day"}
  //                   />
  //                 )}
  //               </Form.Field>
  //             </Form.Group>
  //             <Form.Field style={{ textAlign: "left" }}>
  //               <label style={{ fontSize: "13px" }}>
  //                 {"Redirect on click"}
  //               </label>
  //               {loading ? (
  //                 <LoadingParagraph />
  //               ) : (
  //                 <Field
  //                   loading={loading}
  //                   component={TextInput}
  //                   name="redirectAt"
  //                   placeholder={"Url to redirect when user clicks"}
  //                 />
  //               )}
  //             </Form.Field>
  //           </div>

  //           <Fragment>
  //             <div className="cpGrid cpGrid2ec" style={{ paddingTop: "18px" }}>
  //               {AdImageSizes.filter(
  //                 (f) =>
  //                   f.place === "inline" ||
  //                   (f.place === "side" && f.size === values.size)
  //               ).map((x) =>
  //                 loading ? (
  //                   <Image key={x.key} />
  //                 ) : (
  //                   <div
  //                     key={x.key}
  //                     style={{ paddingLeft: "6px", paddingRight: "6px" }}
  //                   >
  //                     <Header
  //                       content={`${x.place === "inline" ? "Inline" : "Side"
  //                         } image`}
  //                     />
  //                     <CardWithUpload
  //                       imageSize={x}
  //                       loading={loading}
  //                       imageSrc={
  //                         (values.files as IAdvertFile[])?.filter(
  //                           (f) => f.key === x.key
  //                         )[0]?.fileName
  //                       }
  //                       uploadImage={(file: object) => {
  //                         var idx = (values.files as IAdvertFile[])?.findIndex(
  //                           (f) => f.key === x.key
  //                         );
  //                         if (idx >= 0) remove("files", idx);

  //                         uploadFile(file).then((fileName) => {
  //                           push("files", { key: x.key, fileName });
  //                         });

  //                         return Promise.resolve();
  //                       }}
  //                     />
  //                   </div>
  //                 )
  //               )}
  //             </div>
  //           </Fragment>

  //           {submitError && !dirtySinceLastSubmit && (
  //             <ErrorMessage error={submitError} />
  //           )}
  //           <div style={{ textAlign: "center" }}>
  //             <Button.Group style={{ paddingTop: "15px" }}>
  //               <Button
  //                 color="blue"
  //                 disabled={!valid}
  //                 loading={submitting}
  //                 content={"Save"}
  //               />
  //               <Button.Or />
  //               <Button
  //                 onClick={(event) => {
  //                   event.preventDefault();
  //                   navigate(-1);
  //                 }}
  //                 content={"Cancel"}
  //               />
  //             </Button.Group>
  //           </div>
  //         </Form>
  //       )}
  //     />
  //   </div>
  // );
};

export default observer(CreateAdCampaign);
