import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import agent from '../../../api/agent';
import { IDietProfile } from '../../../interfaces/dietprofile';
import ReactJson from 'react-json-view';

interface IProps {
    userId?: string;
}

const AdminDietProfile: React.FC<IProps> = ({ userId }) => {

    const [profile, setProfile] = React.useState<IDietProfile | undefined>();

    const load = () => {
        if (!userId) return;

        agent.Habibai.DietProfiles.get(userId).then((x) => setProfile(x))
    }

    useEffect(() => {
        load()
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <ReactJson
                src={profile ?? {}}
            />
        </div>
    );
}

export default observer(AdminDietProfile)