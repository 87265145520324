import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Form } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import {
    combineValidators,
    isNumeric,
    isRequired,
} from "revalidate";
import ErrorMessage from "../../../form/ErrorMessage";
import SelectInput from "../../../form/SelectInput";
import { INewModuleForAssignment } from "../../../interfaces/billing";
import NumericInput from "../../../form/NumericInput";
import DateInput from "../../../form/DateInput";

interface IProps {
    shopId: string;
}

const validate = combineValidators({
    accountId: isRequired(""),
    moduleId: isRequired(""),
    startsOn: isRequired(""),
    cost: isNumeric("")
});

const AddNewModule: React.FC<IProps> = ({ shopId }) => {
    const context = useContext(RootStoreContext)
    const { closeModal } = context.modalStore;
    const { assignModule, availableModules } = context.billingModuleStore;

    return (
        <div>
            <FinalForm
                onSubmit={(values: INewModuleForAssignment) =>
                    assignModule(values)
                        .then(() => closeModal())
                        .catch((error) => ({
                            [FORM_ERROR]: error,
                        }))
                }
                initialValues={{
                    accountId: shopId
                }}
                validate={validate}
                render={({
                    handleSubmit,
                    submitError,
                    dirtySinceLastSubmit,
                    submitting,
                    invalid,
                    pristine,
                }) => (
                    <Form onSubmit={handleSubmit} error>
                        <div style={{ minWidth: "350px", paddingTop: "15px" }}>
                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>{"Module*"}</label>
                                <Field
                                    component={SelectInput}
                                    options={availableModules
                                        ?.slice()
                                        .map((x) => ({
                                            key: x.id,
                                            text: x.display,
                                            value: x.id,
                                        }))}
                                    name="moduleId"
                                    placeholder={"Select a module to assign"}
                                />
                            </Form.Field>
                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>{"Starts On*"}</label>
                                <Field
                                    component={DateInput}
                                    date={true}
                                    time={false}
                                    name="startsOn"
                                    placeholder={"Start date of the Module"}
                                />
                            </Form.Field>

                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>{"Cost"}</label>
                                <Field
                                    component={NumericInput}
                                    name="cost"
                                    placeholder={"Custom cost, if applicable"}
                                />
                            </Form.Field>
                        </div>

                        {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage error={submitError} />
                        )}

                        <div style={{ textAlign: "center" }}>
                            <Button.Group style={{ paddingTop: "15px" }}>
                                <Button
                                    color="blue"
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                    loading={submitting}
                                    content={"Assign"}
                                />
                                <Button.Or />
                                <Button
                                    onClick={(event) => {
                                        event.preventDefault();
                                        closeModal();
                                    }}
                                    content={"Cancel"}
                                />
                            </Button.Group>
                        </div>
                    </Form>
                )}
            />
        </div>
    )
}

export default observer(AddNewModule);