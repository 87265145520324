import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Modal, Button } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";

const ConfirmationModal = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    modal: {
      open,
      header,
      body,
      noButtonText,
      yesButtonText,
      noButtonNegative,
      noButtonPositive,
      yesButtonNegative,
      yesButtonPositive,
      yesLoading,
    },
    yesClicked,
    closeConfirmationalModal,
  } = rootStore.confirmationModalStore;

  return (
    <Modal open={open} onClose={closeConfirmationalModal} closeIcon size="tiny">
      {header && <Modal.Header>{header}</Modal.Header>}
      {body && <Modal.Content>{body}</Modal.Content>}
      <Modal.Actions>
        <Button
          onClick={closeConfirmationalModal}
          negative={noButtonNegative}
          positive={noButtonPositive}
        >
          {noButtonText}
        </Button>
        <Button
          onClick={yesClicked}
          negative={yesButtonNegative}
          positive={yesButtonPositive}
          content={yesButtonText}
          loading={yesLoading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default observer(ConfirmationModal);
