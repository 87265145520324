import React, { useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Button, Form, Grid } from "semantic-ui-react";
import { combineValidators, isRequired } from "revalidate";
import TextInput from "../../../form/TextInput";
import { RootStoreContext } from "../../../stores/RootStoreContext";

interface IProps {
  callbackOnSuccess?: (...args: any[]) => void;
}

const validate = combineValidators({
  code: isRequired(""),
});

export const PromoCodeChecker: React.FC<IProps> = ({ callbackOnSuccess }) => {
  const context = useContext(RootStoreContext);
  const { validateCode } = context.promoCodesStore;
  const { user } = context.userStore;

  return (
    <FinalForm
      validate={validate}
      initialValues={{ code: undefined }}
      onSubmit={(values: any) =>
        validateCode(values)
          .then(callbackOnSuccess)
          .catch((error) => ({
            [FORM_ERROR]: error,
          }))
      }
      render={({
        handleSubmit,
        submitting,
        invalid,
        dirtySinceLastSubmit,
      }) => (
        <Form onSubmit={handleSubmit} error className="">
          <Grid columns={16}>
            <Grid.Row>
              <Grid.Column width={12} style={{ paddingRight: "0px" }}>
                <Form.Field style={{ width: "100%", paddingRight: "7px" }}>
                  <Field
                    name="code"
                    disabled={!user}
                    component={TextInput}
                    placeholder={"Enter promotional code"}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={4} style={{ paddingLeft: "0px" }}>
                <Button
                  fluid
                  disabled={(invalid && !dirtySinceLastSubmit) || !user}
                  loading={submitting}
                  className="ct-green-button ct-green-button"
                  content={"Apply"}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    />
  );
};
