import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import { Button, Form } from "semantic-ui-react";
import TextInput from "../../form/TextInput";
import ErrorMessage from "../../form/ErrorMessage";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { IDietaryCategory } from "../../interfaces/dietaryCategory";
import { DietaryCategoriesStore } from "./functions/DietaryCategoriesStore";

const validate = combineValidators({
    description: isRequired(""),
});

const DietaryCategoriesManageWidget = () => {
    const context = useContext(RootStoreContext);
    const { closeModal } = context.modalStore;
    const { save } = DietaryCategoriesStore;

    return (
        <div>
            <FinalForm
                validate={validate}
                onSubmit={(values: IDietaryCategory) =>
                    save(values)
                        .then(() => closeModal())
                        .catch((error) => ({
                            [FORM_ERROR]: error,
                        }))
                }
                render={({
                    handleSubmit,
                    submitting,
                    invalid,
                    dirtySinceLastSubmit,
                    submitError,
                }) => (
                    <Form onSubmit={handleSubmit} error className="attached fluid">
                        <Form.Field>
                            <label style={{ fontSize: "13px" }}>{"Description"}</label>
                            <Field
                                name="description"
                                component={TextInput}
                                placeholder={"Description"}
                            />
                        </Form.Field>

                        {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage error={submitError} />
                        )}

                        <div style={{ marginTop: "7px", textAlign: "center" }}>
                            <Button.Group>
                                <Button
                                    disabled={invalid && !dirtySinceLastSubmit}
                                    loading={submitting}
                                    color="blue"
                                    content={"Save"}
                                />
                                <Button.Or />
                                <Button
                                    disabled={submitting}
                                    content={"Cancel"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        closeModal();
                                    }}
                                />
                            </Button.Group>
                        </div>
                    </Form>
                )}
            />
        </div>
    );
};

export default observer(DietaryCategoriesManageWidget);
