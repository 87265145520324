import { observer } from 'mobx-react-lite'
import React, { Fragment, PureComponent, SyntheticEvent, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { AssociationAnalysisStore } from '../functions/AssociationAnalysisStore';
import { isMobile } from 'react-device-detect';
import { Menu, Button, Divider, Table, Image, Checkbox, Grid, GridRow, GridColumn, Icon, Popup, List, Segment, Header, TableCell } from 'semantic-ui-react';
import { CSSTransition } from "react-transition-group";
import {
    CartesianGrid,
    Line,
    LineChart,
    ReferenceArea,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { getImageUrl } from '../../../helpers/productImage';
import { OpenAssociationModal } from '../functions/AssociationHelpers';
import { currencyFormat } from '../../products/functions/productHelper';
import { getMarketImageUrl } from '../../../helpers/supermarketImage';
import { format, formatRelative } from 'date-fns';
import CsvExport from '../../../common/csvExport';


// const CustomizedDot = (props: any) => {
//     const { cx, cy, stroke, payload, value } = props;
//     console.log(payload);
//     console.log(stroke);
//     return (
//         <g transform={`translate(${cx},${cy})`}>
//             <text x={0} y={0} dy={16} fontSize={8} >
//                 {currencyFormat(payload.value, false)}
//             </text>
//         </g>
//     );


// };

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, payload }: any = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} >
                    {format(new Date(payload.value * 1000), "do MMM yyyy")}
                </text>
            </g>
        );
    }
}

class CustomizedYAxisTick extends PureComponent {
    render() {
        const { x, y, payload }: any = this.props;

        return (
            <g transform={`translate(${x - 35},${y - 10})`}>
                <text x={0} y={0} dy={16} >
                    {currencyFormat(payload.value)}
                </text>
            </g>
        );
    }
}

function getMarketColor(marketId: string): string {
    switch (marketId) {
        case "greens":
            return "#7EA834";
        case "pama":
            return "#2D314D";
        case "daves":
            return "#DF081F";
        case "dirjanu":
            return "#EC6507";
        case "sparkleimage":
            return "#5C1B67";
        case "piscopo":
            return "#801E1C"
        case "arkadia":
            return "#00483A";
        case "convenience":
            return "#FCDA10";
        case "detHouse":
            return "#0A4E77";
        case "lighthouse":
            return "#575656";
        case "taganza":
            return "#EC0189";
        case "scotts":
            return "#850034";
        case "spar":
            return "#EC2427";
        case "towers":
        case "welbee":
            return "#008663";
        case "xzone":
            return "#B92025";
        case "mgarrFarms":
            return "#038460";
        case "savemart":
            return "#005BAA";
        case "smart":
            return "#FDB316";

        default:
            return "#FFFFFF"
    }
}

const AssociationAnalysis = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search); // Create URLSearchParams with the query string
    let id = searchParams.get('id');

    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isAdmin } = context.userStore;
    const { markets } = context.cacheStore;
    const { openModal } = context.modalStore;

    const {
        dispose,
        discontinued,
        toggleDiscontinued,
        selectedProducts,
        association,
        load,
        toggleProductItem,
        toggleAll,
        downloading,
        exportDataForCsv,
        filterData,
        setFilterData,
        priceHistoryArray
    } = AssociationAnalysisStore;

    useEffect(() => {
        if (!isAdmin) {
            navigate("/")
            return;
        }
        load(id ?? undefined);
        return () => {
            dispose()
        }
    }, [isAdmin, navigate, dispose, load, id])

    const [focusedLine, setFocusedLine] = React.useState("")
    const [expanded, setExpanded] = React.useState(false);
    let [left, setLeft] = React.useState('dataMin');
    let [right, setRight] = React.useState('dataMax');
    let [refAreaLeft, setRefAreaLeft] = React.useState('');
    let [refAreaRight, setRefAreaRight] = React.useState('');
    let [bottom] = React.useState('dataMin-0.2');
    let [top] = React.useState('dataMax+0.2');

    const zoom = () => {
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setRefAreaLeft('');
            setRefAreaRight('');
            return;
        }

        // xAxis domain
        if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

        setFilterData(priceHistoryArray.filter((f: any) => f.name >= refAreaLeft));

        setLeft(refAreaLeft);
        setRight(refAreaRight);
        setRefAreaLeft('');
        setRefAreaRight('');
    }

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <Segment>
                    <Header as="h4">{format(new Date(label * 1000), "do MMM yyyy")}</Header>
                    <List divided>
                        {payload.slice().sort((a: any, b: any) => (a.value < b.value ? 1 : -1)).map((x: any) => (
                            <List.Item key={x.dataKey} disabled={focusedLine !== "" && focusedLine !== x.dataKey} style={{ color: `${x.color}` }}>{x.name} : {x.value} </List.Item>))}
                    </List>
                </Segment>
            );
        }

        return null;
    };

    return (
        <div>
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "0 0 5px 0" }}
            >
                <Menu.Item className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}>
                    <Button
                        color={!discontinued ? "blue" : "red"}
                        basic={!discontinued}
                        content={"Include Discontinued"}
                        onClick={() => {
                            toggleDiscontinued(id ?? undefined);
                        }}
                    />
                </Menu.Item>
                <Menu.Item>
                    <Button
                        color="green"
                        icon='refresh'
                        basic
                        className="no-margin"
                        onClick={() => {
                            dispose();
                            load(id ?? undefined)
                        }}
                    />
                </Menu.Item>

                <Menu.Menu position='right'>
                    <Menu.Item icon title="Export" style={{ padding: "0px" }}>
                        <CsvExport
                            onClick={() => exportDataForCsv(markets)}
                            content={
                                <Fragment>
                                    <Icon name="file excel outline" /> Export
                                </Fragment>
                            }
                            disabled={downloading}
                            loading={downloading}
                        />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider />
            {association && markets && markets.length > 0 &&
                <Segment className="fxDisplay fxDirCol productCardItem" style={{ paddingTop: "0", paddingBottom: "0", marginTop: "0" }}>
                    <div className="fxDisplay fxFixed">
                        <Image
                            src={
                                association.imageUrl ??
                                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                            }
                            onError={({ currentTarget }: any) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                            }}
                            rounded
                            alt="Product Logo"
                            className="fxFixed"
                            style={{
                                maxHeight: "110px",
                                alignSelf: "center",
                                objectFit: "contain",
                            }}
                        />
                        <div
                            className="fxStretch fxDisplay fxJustifyEven fxDirCol"
                            style={{ padding: "1em" }}
                        >
                            <h3>{association.title}</h3>
                        </div>
                        <hr />
                        <nav
                            className={`fxFixed fxDisplay fxDirCol fxJustifyEven`}
                        >
                            {isAdmin && (
                                <Fragment>
                                    {(
                                        <Fragment>
                                            <Popup
                                                trigger={
                                                    <Icon
                                                        name="pencil"
                                                        color="blue"
                                                        onClick={() => {
                                                            OpenAssociationModal(
                                                                openModal,
                                                                association.products,
                                                                () => { },
                                                                (...args: []) => { },
                                                                association.id
                                                            );
                                                        }}
                                                    />
                                                }
                                                content={"Edit association"}
                                                on="hover"
                                            />
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}

                            <Icon
                                fitted
                                name={expanded ? "angle up" : "angle down"}
                                color="blue"
                                size="large"
                                onClick={() => {
                                    setExpanded(!expanded);
                                }}
                            />
                        </nav>
                    </div>
                    <CSSTransition
                        unmountOnExit
                        in={expanded}
                        timeout={300}
                        classNames="fxDisplay fxDirCol expansion "
                    >
                        <div>
                            <Segment attached="bottom">
                                <Menu>
                                    <Menu.Menu position="right">
                                        <Menu.Item>
                                            <Checkbox toggle checked={discontinued} onClick={() => toggleDiscontinued(id ?? undefined)} />
                                        </Menu.Item>
                                    </Menu.Menu>
                                </Menu>
                                <Table basic="very" celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Product</Table.HeaderCell>
                                            <Table.HeaderCell>Store</Table.HeaderCell>
                                            <Table.HeaderCell>Sku</Table.HeaderCell>
                                            <Table.HeaderCell>Last Update</Table.HeaderCell>
                                            <Table.HeaderCell>Price</Table.HeaderCell>
                                            <Table.HeaderCell>Remove</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {(discontinued ? association.products : association.products.filter(f => !f.discontinued))
                                            .slice()
                                            .sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1))
                                            .map((x) => (
                                                <Table.Row negative={x.discontinued}>
                                                    {!isMobile && (
                                                        <Table.Cell>
                                                            <Header as="h4" image>
                                                                <Image
                                                                    alt="Product Logo"
                                                                    src={
                                                                        getImageUrl(x) ??
                                                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                                                    }
                                                                    onError={({ currentTarget }: any) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src =
                                                                            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                                    }}
                                                                    rounded
                                                                    size="mini"
                                                                />

                                                                <Header.Content>{x.itemDesc}</Header.Content>
                                                            </Header>
                                                        </Table.Cell>
                                                    )}
                                                    <Table.Cell>
                                                        <Header as="h4" image>
                                                            <Image
                                                                alt="Product Logo"
                                                                src={getMarketImageUrl(
                                                                    markets.filter((f) => f.type_id === x.marketId)[0]
                                                                )}
                                                                rounded
                                                                size="mini"
                                                            />
                                                            <Header.Content>
                                                                {markets.filter((f) => f.type_id === x.marketId)[0].title}
                                                            </Header.Content>
                                                        </Header>
                                                    </Table.Cell>
                                                    <TableCell>{x.sku ?? `i - ${x.internalId}`}</TableCell>
                                                    {!isMobile && (
                                                        <Table.Cell>
                                                            {x.lastUpdated && (
                                                                <Fragment>
                                                                    {formatRelative(new Date(x.lastUpdated * 1000), new Date())}
                                                                </Fragment>
                                                            )}
                                                        </Table.Cell>
                                                    )}
                                                    <Table.Cell>
                                                        <div
                                                            style={{
                                                                marginBottom: "0.5em",
                                                                fontSize: "17px",
                                                                fontWeight: "bolder",
                                                            }}
                                                        >
                                                            <Icon name="euro" />
                                                            {currencyFormat(x.currentPrice, false)}
                                                        </div>
                                                    </Table.Cell>
                                                    {isAdmin && !isMobile && (
                                                        <TableCell>
                                                            <Button
                                                                circular
                                                                color="red"
                                                                icon="minus"
                                                                size="tiny"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    // removeFromAssociation(product)
                                                                }}
                                                            />
                                                        </TableCell>
                                                    )}
                                                </Table.Row>
                                            ))}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </div>
                    </CSSTransition>
                </Segment>}

            <Grid columns={16}>
                <GridRow>
                    <GridColumn width={4}>
                        <div style={{ maxHeight: "70vh", overflow: "auto" }}>
                            <Table >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            <Checkbox
                                                indeterminate={selectedProducts.length > 0 && selectedProducts.length < (association?.products?.length ?? 0)}
                                                checked={selectedProducts.length === association?.products?.length}
                                                unchecked={selectedProducts.length === 0}
                                                defaultChecked={selectedProducts.length === association?.products?.length}
                                                onChange={(event: SyntheticEvent, data: object) => {
                                                    toggleAll();
                                                }}
                                            /></Table.HeaderCell>
                                        <Table.HeaderCell>Shop</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {association && markets && markets.length > 0 &&
                                        association.products.slice().sort((a, b) => (a.currentPrice > b.currentPrice ? 1 : -1)).map((x, idnex) => (
                                            <Table.Row error={x.discontinued} key={x.id}>
                                                <Table.Cell>
                                                    <Checkbox
                                                        checked={selectedProducts.includes(x.id)}
                                                        // checked={selectedProducts.length === association?.products?.length}
                                                        onChange={(event: SyntheticEvent, data: object) => {
                                                            toggleProductItem(x.id);
                                                        }}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <div className="fxDisplay fxFixed">
                                                        <div className="fxDisplay fxStretch fxFixed"
                                                            style={{ color: `${getMarketColor(x.marketId)}`, fontWeight: `${focusedLine === x.id ? "bolder" : ""}` }}>
                                                            {markets.filter((f) => f.type_id === x.marketId)[0]
                                                                .title}
                                                        </div>
                                                        <div style={{ textAlign: "right" }}>
                                                            <div
                                                                style={{
                                                                    fontSize: "13px",
                                                                }}
                                                            >
                                                                <Icon name="euro" />
                                                                {currencyFormat(x.currentPrice, false)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                </Table.Body>
                            </Table>
                        </div>
                    </GridColumn>
                    <GridColumn width={12}>
                        <div
                            className="highlight-bar-charts"
                            style={{ userSelect: "none", height: "70vh" }}
                        >
                            <ResponsiveContainer width="100%" height="100%" >
                                <LineChart width={500} height={500}
                                    data={filterData ?? priceHistoryArray ?? []}
                                    onMouseDown={(e) => { if (e) setRefAreaLeft(e.activeLabel as any) }}
                                    onMouseMove={(e) => { if (e) refAreaLeft && setRefAreaRight(e.activeLabel as any) }}
                                    onMouseUp={zoom}
                                    onMouseLeave={() => setFocusedLine("")}>
                                    {/* <XAxis dataKey="name" />
                                    <YAxis allowDecimals={true} /> */}
                                    <XAxis dataKey="name" domain={[left, right]} type='number' tick={<CustomizedAxisTick />} />
                                    <YAxis domain={[bottom, top]} allowDecimals={true} type="number" yAxisId="1" tick={<CustomizedYAxisTick />} />

                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip content={<CustomTooltip />} />

                                    {association && markets && markets.length > 0 &&
                                        association.products.filter(f => selectedProducts.includes(f.id)).map((x, idnex) => (
                                            <Line
                                                key={idnex}
                                                // connectNulls
                                                yAxisId="1"
                                                type="stepAfter"
                                                // dot={<CustomizedDot />}
                                                strokeWidth={focusedLine !== "" && focusedLine === x.id ? 2.5 : 1}
                                                dataKey={x.id}
                                                stroke={getMarketColor(x.marketId)}
                                                name={
                                                    markets?.filter((f) => f.type_id === x.marketId)[0].title ??
                                                    ""
                                                }
                                                onMouseEnter={() => {
                                                    setFocusedLine(x.id)
                                                }}
                                            />
                                        ))}

                                    {refAreaLeft && refAreaRight ? (
                                        <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                                    ) : null}
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </GridColumn>
                </GridRow>

            </Grid>
        </div >
    )
}

export default observer(AssociationAnalysis);