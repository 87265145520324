import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, SyntheticEvent, useContext } from 'react'
import { Button, Divider, Dropdown, Header, Icon, Input } from 'semantic-ui-react'
import { RootStoreContext } from '../../../stores/RootStoreContext';

const UserDietProfile = () => {
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const {
        setProfile,
        profile,
        nextStep,
        previousStep,
        loading
    } = context.userDietProfileStore;


    return (
        <div
            className='fxFixed fxDisplay fxAlignCenter fxStretch fxDirCol'
            style={{ marginTop: "55px" }}>
            <Header
                size='large'
                textAlign='left'
                content="Tell us about your self"
                subheader="This information will help us personalize the nutrition requirements for you!"
                style={isMobile ? { padding: "15px" } : {
                    marginBottom: "25px"
                }}
            />

            <div style={isMobile ? { padding: "10px", width: "100%" } : { maxWidth: "550px", width: "550px" }}>
                <div className='fxDisplay '>
                    <div className='fxStretch' style={{ margin: "auto" }}>Sex</div>
                    <div className=''>
                        <Button.Group>
                            <Button onClick={() => setProfile({ ...profile, profile: { ...profile?.profile, sex: "male" } })} className={profile?.profile?.sex === "male" ? 'ct-green-button-active' : 'ct-green-button'} content="Male" active={profile?.profile?.sex === "male"} />
                            <Button onClick={() => setProfile({ ...profile, profile: { ...profile?.profile, sex: "female" } })} className={profile?.profile?.sex === "female" ? 'ct-green-button-active' : 'ct-green-button'} content="Female" active={profile?.profile?.sex === "female"} />
                            <Button onClick={() => setProfile({ ...profile, profile: { ...profile?.profile, sex: "other" } })} className={profile?.profile?.sex === "other" ? 'ct-green-button-active' : 'ct-green-button'} content="Other" active={profile?.profile?.sex === "other"} />
                        </Button.Group>
                    </div>
                </div>
                <Divider />
                <div className='fxDisplay' style={{ marginTop: "30px" }}>
                    <div className='fxStretch' style={{ margin: "auto" }}>Age</div>
                    <div className='fxDisplay'>
                        <Input
                            style={{ maxWidth: "60px" }}
                            defaultValue={profile?.profile?.age}
                            onChange={(event: ChangeEvent, data: any) => {
                                setProfile({ ...profile, profile: { ...profile?.profile, age: data.value * 1 } })

                            }}
                        />
                        <span style={{ paddingLeft: "8px", margin: "auto" }}>years</span>
                    </div>
                </div>
                <Divider />
                <div className='fxDisplay' style={{ marginTop: "30px" }}>
                    <div className='fxStretch' style={{ margin: "auto" }}>Height</div>
                    <div className='fxDisplay'>
                        <Input
                            style={{ maxWidth: "60px" }}
                            defaultValue={profile?.profile?.height}
                            onChange={(event: ChangeEvent, data: any) => {
                                setProfile({ ...profile, profile: { ...profile?.profile, height: data.value * 1 } })

                            }}
                        />
                        <span style={{ paddingLeft: "8px", margin: "auto" }}>cm</span>
                    </div>
                </div>
                <Divider />
                <div className='fxDisplay' style={{ marginTop: "30px" }}>
                    <div className='fxStretch' style={{ margin: "auto" }}>Weight</div>
                    <div className='fxDisplay'>
                        <Input
                            style={{ maxWidth: "60px" }}
                            defaultValue={profile?.profile?.weight}
                            onChange={(event: ChangeEvent, data: any) => {
                                setProfile({ ...profile, profile: { ...profile?.profile, weight: data.value * 1 } })

                            }}
                        />
                        <span style={{ paddingLeft: "8px", margin: "auto" }}>Kg</span>
                    </div>
                </div>
                <Divider />
                <div className='fxDisplay '>
                    <div className='fxStretch' style={{ margin: "auto" }}>Bodyfat</div>
                    <div className=''>
                        <Button.Group>
                            <Button onClick={() => setProfile({ ...profile, profile: { ...profile?.profile, body_fat: "low" } })} className={profile?.profile?.body_fat === "low" ? 'ct-green-button-active' : 'ct-green-button'} content="Low" active={profile?.profile?.body_fat === "low"} />
                            <Button onClick={() => setProfile({ ...profile, profile: { ...profile?.profile, body_fat: "medium" } })} className={profile?.profile?.body_fat === "medium" ? 'ct-green-button-active' : 'ct-green-button'} content="Medium" active={profile?.profile?.body_fat === "medium"} />
                            <Button onClick={() => setProfile({ ...profile, profile: { ...profile?.profile, body_fat: "high" } })} className={profile?.profile?.body_fat === "high" ? 'ct-green-button-active' : 'ct-green-button'} content="High" active={profile?.profile?.body_fat === "high"} />
                        </Button.Group>
                    </div>
                </div>
                <Divider />
                <div className='fxDisplay' style={{ marginTop: "18px" }}>
                    <div className='fxStretch' style={{ margin: "auto" }}>Excercise Level</div>
                    <div className='fxStretch' >
                        <Dropdown
                            fluid
                            selection
                            placeholder='Select one...'
                            onChange={(event: SyntheticEvent, data: any) => {
                                setProfile({ ...profile, profile: { ...profile?.profile, activity_lvl: (data?.value as any) } })
                            }}
                            defaultValue={profile?.profile?.activity_lvl}
                            options={[
                                {
                                    key: "none",
                                    value: "none",
                                    text: "Sedentary",
                                    content: (
                                        <Header size='small' content='Sedentary' subheader='Minimal movement, mostly sitting or lying down' />
                                    ),
                                },
                                {
                                    key: "light",
                                    value: "light",
                                    text: "Light Activity",
                                    content: (
                                        <Header size='small' content='Light Activity' subheader='Mostly sedentary with occasional movement (e.g., desk job)' />
                                    ),
                                },
                                {
                                    key: "lightly_active",
                                    value: "lightly_active",
                                    text: "Moderately Active",
                                    content: (
                                        <Header size='small' content='Moderately Active' subheader='Some exercise (e.g., workouts 2-3 times per week)' />
                                    ),
                                },
                                {
                                    key: "daily_active",
                                    value: "daily_active",
                                    text: "Active",
                                    content: (
                                        <Header size='small' content='Active' subheader='Regular exercise (e.g., workouts 4-5 times per week)' />
                                    ),
                                },
                                {
                                    key: "very_athletic",
                                    value: "very_athletic",
                                    text: "Very Active",
                                    content: (
                                        <Header size='small' content='Very Active' subheader='Frequent and intense exercise or physical job' />
                                    ),
                                },
                                {
                                    key: "extremely_athletic",
                                    value: "extremely_athletic",
                                    text: "Extremely Active",
                                    content: (
                                        <Header size='small' content='Extremely Active' subheader='Athletic training or highly intense daily physical activity' />
                                    ),
                                }
                            ]} />
                    </div>
                </div>
            </div>

            <div className='fxDisplay fxFixed fxJustifyBetween' style={isMobile ? {
                marginBottom: "15px",
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px"
            } : {
                width: "550px",
                marginBottom: "15px"
            }}>
                <div
                    className={`diet-continue-button clickable`}
                    style={{ marginTop: "22px", }}
                    onClick={() => {
                        if (!loading)
                            previousStep()
                    }}
                >
                    Back
                    <Icon name='angle left' />
                </div>
                <div
                    className={`diet-continue-button clickable`}
                    style={{ marginTop: "22px", }}
                    onClick={() => {
                        if (!loading)
                            nextStep()
                    }}
                >
                    <Icon name='angle right' />
                    {profile?.id === undefined ? "Continue" : "Save"}
                </div>
            </div>
        </div>
    )
}

export default observer(UserDietProfile)