import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router";
import {
  Button,
  Dimmer,
  Divider,
  Icon,
  Input,
  Loader,
  Menu,
  Segment,
  Sidebar,
  Table,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import InventoryProductTableRow from "./Components/InventoryProductTableRow";
import ProductDetailWidget from "./Components/ProductDetailWidget";
import { NoResults } from "../../common/NoResults";

interface IProps {
  shopId: string;
}

const Inventory: React.FC<IProps> = ({ shopId }) => {
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const { isAdmin } = context.userStore;
  const { setPage } = context.analyticsStore;
  const { markets } = context.cacheStore;
  const { isBranchesEnabled } = context.accountPageStore;
  const { loading,
    products,
    load,
    hasMore,
    dispose,
    search,
    editingId,
    getAllLocations,
    ungroupedOnly,
    activeOnly,
    toggleActive,
    toggleUngrouped,
    disposeDetail } =
    context.inventoryStore;

  const [timer, setTimer] = React.useState(0);
  const [myMarket] = useState(markets.filter(f => f.id === shopId)[0])

  useEffect(() => {
    getAllLocations(shopId);
    load(shopId);
    setPage("Inventory", window.location.pathname);

    return () => {
      dispose();
    };
  }, [load, navigate, dispose, setPage, shopId, getAllLocations]);


  return <div style={{ marginRight: "20px" }}>
    <Menu
      secondary
      size={isMobile ? "massive" : "small"}
      style={{ margin: "5px 0" }}
    >
      <Menu.Item
        className={!isMobile ? "no-padding" : ""}
        style={{ flex: isMobile ? 1 : "" }}
      >
        <Input
          fluid={isMobile}
          transparent={isMobile}
          size={isMobile ? "large" : "small"}
          icon={!isMobile ? <Icon name="search" color="blue" /> : undefined}
          placeholder={"Search..."}
          input={<input className={isMobile ? "" : "bluePlaceholder"} />}
          onChange={(event: ChangeEvent, data: any) => {
            if (!search) return;
            if (timer != null) {
              clearTimeout(timer);
              setTimer(0);
            }
            setTimer(
              setTimeout(
                (callback: (text: string) => void, text: string) => {
                  callback(text);
                  clearTimeout(timer);
                },
                500,
                search,
                data.value
              )
            );
          }}
        />
      </Menu.Item>
      {isAdmin &&
        <Menu.Item className={!isMobile ? "no-padding" : ""}
          style={{ flex: isMobile ? 1 : "" }}>
          <Button
            color={!ungroupedOnly ? "blue" : "green"}
            basic={!ungroupedOnly}
            content={"Filter Ungrouped"}
            onClick={() => {
              toggleUngrouped();
            }}
          />
        </Menu.Item>
      }
      <Menu.Item className={!isMobile ? "no-padding" : ""}
        style={{ flex: isMobile ? 1 : "" }}>
        <Button
          color={!activeOnly ? "blue" : "green"}
          basic={!activeOnly}
          content={"Active Only"}
          onClick={() => {
            toggleActive();
          }}
        />
      </Menu.Item>

      <Menu.Menu position="right">
        <Button
          color="blue"
          basic
          icon={"upload"}
          content={"Upload Wizard"}
          onClick={() => {
            navigate(`/shop/${shopId}/upload-wizard`);
          }}
        />
      </Menu.Menu>
    </Menu>
    <Divider style={isMobile ? { padding: "0", margin: "0" } : undefined} />


    {!loading || products.length > 0 ? (
      <div>
        <Sidebar
          as={Segment}
          animation={'scale down'}
          direction={'right'}
          width="very wide"
          visible={editingId !== undefined}
          onHidden={disposeDetail}
          style={{ zIndex: 1000 }}
        >
          <ProductDetailWidget extEditingId={editingId} />
        </Sidebar>
        <InfiniteScroll
          style={{ overflow: "hidden" }}
          dataLength={products.length} //This is important field to render the next data
          next={() => load()}
          hasMore={hasMore}
          loader={loading ? <h4>Loading...</h4> : <div />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              {products.length > 0 ? (
                <b></b>
              ) : (
                <NoResults />
              )}
            </p>
          }
        >
          <Table basic="very" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
                {isAdmin && <Table.HeaderCell>Grouped</Table.HeaderCell>}
                {isBranchesEnabled && <Table.HeaderCell>Outlets</Table.HeaderCell>}
                <Table.HeaderCell>Last Updated</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {products.map((x) => (
                <InventoryProductTableRow market={myMarket} product={x} key={x.id} />
              ))}
            </Table.Body>
          </Table>
        </InfiniteScroll>
      </div>
    ) : (
      <Segment
        style={{
          minHeight: "55vh",
          boxShadow: "none",
          border: "none",
        }}
      >
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Segment>
    )}
  </div >
};

export default observer(Inventory);
