
export const VivaEvents =
{
    2061: { 'reason': '3DS flow incomplete', 'description': 'Browser closed before authentication finished' },
    2062: { 'reason': '3DS validation failed', 'description': 'Wrong password or two-factor auth code entered' },
    2108: { 'reason': 'Payments Policy Acquiring Restriction', 'description': 'Payments Policy Acquiring Restriction' },
    10001: { 'reason': 'Refer to card issuer', 'description': 'The issuing bank prevented the transaction' },
    10003: { 'reason': 'Invalid merchant number', 'description': 'Security violation (source is not correct issuer)' },
    10004: { 'reason': 'Pick up card', 'description': 'The card has been designated as lost or stolen' },
    10005: { 'reason': 'Do not honor', 'description': 'The issuing bank declined the transaction without an explanation' },
    10006: { 'reason': 'General error', 'description': 'The card issuer has declined the transaction as there is a problem with the card number' },
    10007: { 'reason': 'Pick up card, special condition (fraud account)', 'description': 'This usually means the customer’s bank has stopped the transaction because the card has been marked as fraudulent' },
    10012: { 'reason': 'Invalid transaction', 'description': 'The bank has declined the transaction because of an invalid format or field. This indicates the card details were incorrect' },
    10013: { 'reason': 'Invalid amount', 'description': 'The card issuer has declined the transaction because of an invalid format or field' },
    10014: { 'reason': 'Invalid card number', 'description': 'The card issuer has declined the transaction as the credit card number is incorrectly entered or does not exist' },
    10015: { 'reason': 'Invalid issuer', 'description': 'The card issuer doesn\'t exist' },
    10030: { 'reason': 'Format error', 'description': 'The card issuer does not recognise the transaction details being entered. This is due to a format error' },
    10039: { 'reason': 'No credit account', 'description': 'The issuer has declined the transaction as the card number used is not a credit account' },
    10041: { 'reason': 'Lost card', 'description': 'The card issuer has declined the transaction as the card has been reported lost' },
    10043: { 'reason': 'Stolen card', 'description': 'The card has been designated as lost or stolen' },
    10046: { 'reason': 'Closed Account', 'description': 'The transaction has been refused as the account is closed' },
    10051: { 'reason': 'Insufficient funds', 'description': 'The card has insufficient funds to cover the cost of the transaction' },
    10052: { 'reason': 'No checking account', 'description': 'The issuer has declined the transaction as the credit card number is associated to a checking account that does not exist' },
    10053: { 'reason': 'No savings account', 'description': 'The issuer has declined the transaction as the credit card number is associated to a savings account that does not exist' },
    10054: { 'reason': 'Expired card', 'description': 'The payment gateway declined the transaction because the expiration date is expired or does not match' },
    10057: { 'reason': 'Function not permitted to cardholder', 'description': 'The card issuer has declined the transaction as the credit card cannot be used for this type of transaction' },
    10058: { 'reason': 'Function not permitted to terminal', 'description': 'The card issuer has declined the transaction as the credit card cannot be used for this type of transaction' },
    10059: { 'reason': 'Suspected Fraud', 'description': 'The issuing bank has declined the transaction as a result of suspected fraud' },
    10061: { 'reason': 'Withdrawal limit exceeded', 'description': 'Exceeds withdrawal amount limit' },
    10062: { 'reason': 'Restricted card', 'description': 'The customer\'s bank has declined their card' },
    10063: { 'reason': 'Issuer response security violation', 'description': 'Flag raised due to security validation problem' },
    10065: { 'reason': 'Soft decline', 'description': 'The issuer requests Strong Customer Authentication. The merchant should retry the transaction after successfully authenticating customer with 3DS first' },
    10070: { 'reason': 'Call issuer', 'description': 'Contact card issuer' },
    10075: { 'reason': 'PIN entry tries exceeded', 'description': 'Allowable number of PIN tries exceeded' },
    10076: { 'reason': 'Invalid / non-existent "to account" specified', 'description': 'An invalid or non-existent "to" account has been specified' },
    10077: { 'reason': 'Invalid / non-existent "from account" specified', 'description': 'An invalid or non-existent "from" account has been specified' },
    10079: { 'reason': 'Life Cycle', 'description': 'Issuer response: \'Life Cycle\' issue' },
    10080: { 'reason': 'No financial impact', 'description': 'This is usually returned when a reversal is sent for an authorization message that was already declined' },
    10083: { 'reason': 'Fraud/Security', 'description': 'Issuer response: \'Fraud / Security\' issue' },
    10084: { 'reason': 'Invalid Authorization Life Cycle', 'description': 'Issuer response: \'Invalid Authorization Life Cycle\' issue' },
    10088: { 'reason': 'Cryptographic failure', 'description': 'Issuer response: \'Cryptographic failure\' issue' },
    10089: { 'reason': 'Unacceptable PIN - Transaction Declined - Retry', 'description': 'The entered PIN code was incorrect' },
    10093: { 'reason': 'Transaction cannot be completed; violation of law', 'description': 'The issuing bank has recognised (or has been informed of) a legal violation on the part of the credit card user, and assets have been frozen' },
    10096: { 'reason': 'System malfunction', 'description': 'A temporary error occurred during the transaction' },
    10200: { 'reason': 'Generic error', 'description': 'A temporary error occurred during the transaction' },
    10210: { 'reason': 'Invalid CVV', 'description': 'The CVV2 code entered is incorrect' },
    10211: { 'reason': 'Negative Online CAM, dCVV, iCVV, CVV, or CAVV results or Offline PIN Authentication', 'description': 'Issuer response: \'Negative Online CAM, dCVV, iCVV, CVV, or CAVV results or Offline PIN Authentication\' issue' },
    10212: { 'reason': 'Blocked Card', 'description': 'Transaction from new cardholder, and card not properly unblocked' },
    10213: { 'reason': 'Revocation of authorization order', 'description': 'The cardholder has revoked authorisation for future payments to a particular merchant' },
    10214: { 'reason': 'Verification Data Failed', 'description': 'Issuer response: \'Verification Data Failed\' issue' },
    10215: { 'reason': 'Policy', 'description': 'The transaction has been refused due to a policy reason' },
    10216: { 'reason': 'Invalid/nonexistent account specified', 'description': 'The issuing bank of the credit card could not find an account for the card' },
    10301: { 'reason': 'Soft decline', 'description': 'The issuer requests Strong Customer Authentication. The merchant should retry the transaction after successfully authenticating customer with 3DS first' },

}

