import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { LastSyncItem } from "../../../interfaces/lastSync";

export class LastSyncStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable deletingId?: string = undefined;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable items: LastSyncItem[] = []

    @action setLoading = (value: boolean) => this.loading = value;
    @action setDeleting = (value?: string) => this.deletingId = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setItems = (value: LastSyncItem[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };


    @action load = async () => {
        try {

            this.setLoading(true);

            var response = await agent.LastSync.get(this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action deleteItem = async (id: string) => {
        try {

            this.setDeleting(id);

            await agent.LastSync.delete(id);

            runInAction(() => {
                this.items = this.items.filter(f => f.id !== id);
            })
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setDeleting(undefined);
        }
    }

    @action dispose = () => {
        this.setLoading(false);
        this.setCurrentPage(0);
        this.setHasMore(false);
    }
}