import { SemanticCOLORS } from "semantic-ui-react";

export const CTHubOrderProductStatus: string[] = ['fully_received', 'partially_received', 'missing', 'returned']

export const HubOrderStatus: {
    key: string,
    value: string
    text: string,
    order: number,
    color: SemanticCOLORS
}[] = [
        {
            key: "sent_to_market",
            value: "sent_to_market",
            text: "Sent To Market",
            color: "red",
            order: 0
        },
        {
            key: "received",
            value: "received",
            text: "Received",
            color: "green",
            order: 5
        },
        {
            key: "in_process",
            value: "in_process",
            text: "In process",
            color: "olive",
            order: 3
        },
        {
            key: "ready_for_delivery",
            value: "ready_for_delivery",
            text: "Ready For Pick/Deli",
            color: "green",
            order: 4
        },
        {
            key: "seen",
            value: "seen",
            text: "Seen",
            color: "yellow",
            order: 2
        },
        {
            key: "completed",
            value: "completed",
            text: "Completed",
            color: "green",
            order: 6
        },
    ];


export type ClientOrderProductStatusType = "fully_packed" | "partially_packed" | "missing" | "pending";

export const ClientOrderProductStatus: {
    key: ClientOrderProductStatusType,
    value: ClientOrderProductStatusType,
    text: string,
    clientText: string,
    color: SemanticCOLORS
}[] = [
        {
            key: "fully_packed",
            value: "fully_packed",
            text: "Packed in full",
            clientText: "Packed",
            color: "green"
        },
        {
            key: "partially_packed",
            value: "partially_packed",
            text: "Partially Packed",
            clientText: "Partially Packed",
            color: "yellow"
        },
        {
            key: "missing",
            value: "missing",
            text: "Missing",
            clientText: "Refunded",
            color: "red"
        },
        {
            key: "pending",
            value: "pending",
            text: "Pending",
            clientText: "Pending",
            color: "grey"
        }
    ];

export const HubOrderProductStatus: {
    key: string,
    value: string
    text: string,
    color: SemanticCOLORS
}[] = [
        {
            key: "fully_received",
            value: "fully_received",
            text: "Received in full",
            color: "green"
        },
        {
            key: "partially_received",
            value: "partially_received",
            text: "Partially Received",
            color: "yellow"
        },
        {
            key: "missing",
            value: "missing",
            text: "Missing",
            color: "orange"
        },
        {
            key: "returned",
            value: "returned",
            text: "Returned Back",
            color: "red"
        },
        {
            key: "fully_packed",
            value: "fully_packed",
            text: "Fully Packed",
            color: "green"
        },
        {
            key: "partially_packed",
            value: "partially_packed",
            text: "Partially Packed",
            color: "orange"
        },
        {
            key: "out_of_stock",
            value: "out_of_stock",
            text: "Out of Stock",
            color: "red"
        },
        {
            key: "pending",
            value: "pending",
            text: "Pending",
            color: "grey"
        }
    ];
