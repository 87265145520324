import { Fragment, useContext, useEffect } from "react";
import { Grid, Header, Divider, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import NamesEditor from "./Components/NamesEditor";
import EmailEditor from "./Components/EmailEditor";
import PhoneEditor from "./Components/PhoneEditor";
import PasswordEditor from "./Components/PasswordEditor";
import CurrentAddressesWidget from "./Components/CurrentAddressesWidget";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { useNavigate, useParams } from "react-router";
import { WidgetMode } from "../../interfaces/WidgetMode";
import PreferredSupermarkets from "./Components/PreferredSupermarkets";
import { ButtonTypes } from "../confirmationModal/functions/ButtonTypes";
import agent from "../../api/agent";
import DeliveryEditor from "./Components/DeliveryEditor";
import ChefAIEditor from "./Components/ChefAIEditor";

const ProfilePage = () => {
  let { userId } = useParams<"userId">();
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { getUser, dispose, profile } = context.profileStore;
  const { user, isOwner, isAdmin, userRoles } = context.userStore;
  const { isLoggedIn, logout } = context.sessionStore;
  const { isMobile } = context.deviceStore;
  const { setPage } = context.analyticsStore;
  const { oneTimeLoad } = context.productStore;
  const { openConfirmationModal, closeConfirmationalModal } =
    context.confirmationModalStore;

  useEffect(() => {
    oneTimeLoad();

    if (!isLoggedIn) navigate("/");
    if ((user?.id ?? "") === "") return;
    setPage("Profile", window.location.pathname);

    getUser(userId ?? user!.id);

    return () => {
      dispose();
    };
  }, [
    user,
    userId,
    oneTimeLoad,
    setPage,
    dispose,
    getUser,
    isLoggedIn,
    navigate,
  ]);

  return (
    <Grid
      textAlign="center"
      style={{ paddingTop: "35px", paddingBottom: "35px" }}
    >
      <Grid.Row columns={1}>
        <Grid.Column width={14}>
          <Header
            content={"Profile Details"}
            size="medium"
            className="ct-green-text"
            textAlign="left"
          />
          <Divider />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column width={isMobile ? 14 : 8}>
          <Grid divided="vertically" textAlign="center">
            {/* NAMES */}
            <NamesEditor />

            {/*Email */}
            <EmailEditor />

            {/* Phone */}
            <PhoneEditor />

            {/* Password */}
            <PasswordEditor />

            {/* Delivery */}
            {isOwner && <DeliveryEditor />}
            {isOwner && <ChefAIEditor />}

            {/* Delete Account */}
            <Grid.Row columns={isAdmin ? 2 : 1}>
              <Grid.Column>
                {!isAdmin && <Button
                  onClick={() => {
                    openConfirmationModal(
                      "Confirm account deletion",
                      "Are you sure you want to delete your account? This action is instant and cannot be reverted. All your data associated with your account will be anonymized and cannot be linked again with another account",
                      () =>
                        agent.Auth.deleteAccount().then(() => {
                          closeConfirmationalModal();
                          logout();
                        }),
                      "Cancel",
                      "Delete",
                      ButtonTypes.None,
                      ButtonTypes.Negative
                    );
                  }}
                  color="red"
                  className="button-as-link redColor"
                  content="Delete my account"
                />}
              </Grid.Column>
              {isAdmin && userId && ((userRoles?.roles?.filter(f => f.module === "user_management" && f.hasAccess === true).length ?? 0) > 0 || isOwner) && <Grid.Column><Button
                onClick={() => {
                  openConfirmationModal(
                    "Confirm account deletion",
                    "Are you sure you want to delete this account? This action is instant and cannot be reverted. All data associated with the account will be anonymized and cannot be linked again with another account",
                    () =>
                      agent.Auth.deleteUserAccount(userId!).then(() => {
                        closeConfirmationalModal();
                        getUser(userId!);
                      }),
                    "Cancel",
                    "Delete",
                    ButtonTypes.None,
                    ButtonTypes.Negative
                  );
                }}
                className="button-as-link yellowColor"
                content="Delete user's account"
              /></Grid.Column>}
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column width={14}>
          {/* Addresses */}
          <Fragment>
            <Header
              content={"Addresses"}
              size="medium"
              className="ct-green-text"
              textAlign="left"
            />
            <Divider />
            {profile && user && (
              <CurrentAddressesWidget
                userId={userId ?? user!.id}
                mode={
                  profile!.id === user!.id ? WidgetMode.Edit : WidgetMode.View
                }
              />
            )}
          </Fragment>

          {/* Preferred Stores */}
          <Fragment>
            <Header
              content={"Preferred Stores"}
              size="medium"
              className="ct-green-text"
              textAlign="left"
            />
            <Divider />
            <PreferredSupermarkets />
          </Fragment>
        </Grid.Column>
      </Grid.Row>
    </Grid >
  );
};

export default observer(ProfilePage);
