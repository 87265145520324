import React, { useContext, useRef } from 'react';
import { SmartProductCategory, CategoryNode } from '../../../interfaces/categories';
import { Image } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { observer } from "mobx-react-lite";
import CategoryIcon from './CategoryIcon';

const transformCategories = (categories: SmartProductCategory[]): CategoryNode[] => {
    // First, convert the flat array into a map for easy access
    const categoryMap = new Map<string, CategoryNode>();

    categories.forEach(category => {
        categoryMap.set(category.id, { ...category, children: [] });
    });

    // Now, build the tree
    const rootCategories: CategoryNode[] = [];

    categoryMap.forEach((node, id) => {
        if (node.isMain) {
            rootCategories.push(node); // If it's a main category, add it to the root categories
        } else {
            node.parents?.forEach(parentId => {
                const parent = categoryMap.get(parentId);
                if (parent) {
                    parent.children.push(node); // Add this node to its parent's children array
                }
            });
        }
    });

    // Optionally sort root categories by 'order'
    rootCategories.sort((a, b) => a.order - b.order);

    // Sorting children can be done recursively if needed, based on 'order' or any other criteria
    return rootCategories;
};

const CategoryBar = () => {
    const context = useContext(RootStoreContext)
    const { smartCategories } = context.cacheStore;
    const {
        expandedCategories,
        setExpandedCategories,
        lastClickedId,
        search_from_tree
    } = context.shoppingCatalogueStore;
    // const itemRefs: any = useRef({});

    // const getItemRef = (id: string) => {
    //     if (!itemRefs.current[id]) {
    //         itemRefs.current[id] = React.createRef();
    //     }
    //     return itemRefs.current[id];
    // };

    // useEffect(() => {
    //     if (search_bar_clicked && expandedCategories.includes(search_bar_clicked) && itemRefs.current[search_bar_clicked]) {
    //         const currentItem = itemRefs.current[search_bar_clicked].current;
    //         if (currentItem) {
    //             currentItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [search_bar_clicked]);


    const animatingNodeRef = useRef<HTMLDivElement>(null);

    const handleToggle = (category: CategoryNode) => {
        if (category.isMain) {
            if (expandedCategories.includes(category.id)) {
                setExpandedCategories([]);
            }
            else {
                setExpandedCategories([category.id]);
            }
        }
        else {
            if (expandedCategories.includes(category.id)) {
                setExpandedCategories(expandedCategories.filter(id => id !== category.id))
            }
            else {
                setExpandedCategories([...expandedCategories, category.id])
            }
        }
    };

    const tree = transformCategories(smartCategories);

    const renderTree = (nodes: CategoryNode[]) => (
        <ul>
            {nodes.map(node => {
                const isExpanded = expandedCategories.includes(node.id);
                // ref={getItemRef(node.id)}
                return (<li key={node.id} >
                    <div className={`category-item ${(node.children?.length ?? 0) > 0 ? "has-items" : ""} ${lastClickedId === node.id ? "ct-green-text" : ""}`}>
                        {node.isMain && <CategoryIcon node={node} onClick={search_from_tree} />}
                        <span className="category-description" onClick={() => search_from_tree(node)}>{node.description}</span>
                        {node.children && node.children.length > 0 && (
                            <Image
                                onClick={() => handleToggle(node)}
                                id="category_arrow"
                                src="arrow_down.png"
                                className={`category-toggle ${expandedCategories.includes(node.id) ? "open ct-green-text" : ""}`}
                            />
                        )}
                    </div>
                    {(node.children?.length ?? 0) > 0 && <div
                        className='category-children'
                        ref={animatingNodeRef}
                        style={{
                            maxHeight: isExpanded ? 1050 : 0,
                            overflow: "auto",
                            transition: 'max-height 0.5s ease'
                        }}>
                        <div style={{ minHeight: "15px" }} />
                        {renderTree(node.children.slice().sort((a, b) => a.description > b.description ? 1 : -1))}
                    </div>}
                </li>)
            }
            )}
        </ul>
    );

    return <nav className="category-bar">{renderTree(tree)}</nav>;
};

export default observer(CategoryBar);