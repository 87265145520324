import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { Accordion, Icon, Image, List, ListContent, ListDescription, ListHeader, ListItem, Popup } from 'semantic-ui-react';
import { titleCase } from '../../../helpers/textHelper';
import agent from '../../../api/agent';
import ProductsMessage from './ProductsMessage';
import { IMessage, IMessageContent } from '../../../interfaces/chat';
import { Guid } from '../../../helpers/guid';
import { isMobile } from 'react-device-detect';

interface Step {
    description: string;
    checked: boolean;
}

const RecipeDetail = () => {
    const context = useContext(RootStoreContext)
    const { selectedRecipe, setSelectedRecipe, conversationId } = context.chatUIStore;

    const [productResults, setProductResults] = React.useState<IMessage[]>([])
    const [executionMethod, setExecutionMethod] = React.useState<boolean>(true)
    const [steps, setSteps] = React.useState<Step[]>([])
    const [loadingIngredient, setLoadingIngredient] = React.useState<string>("")
    const [activeIndexes, setActiveIndexes] = React.useState<string[]>([])

    const handleIngredientCheck = async (index: number) => {
        try {
            const updatedIngredients = [...(selectedRecipe?.ingredients ?? [])];
            const isChecking = !updatedIngredients[index].checked;
            updatedIngredients[index].checked = !updatedIngredients[index].checked;

            if (isChecking) {
                setLoadingIngredient(updatedIngredients[index].name);

                if ((updatedIngredients[index].products?.length ?? 0) > 0) {
                    setExecutionMethod(false);
                }
                else
                    await agent.Habibai.searchIngredient(
                        conversationId!,
                        selectedRecipe!.messageId!,
                        selectedRecipe!.id,
                        updatedIngredients[index].id,
                        {
                            "message": updatedIngredients[index].name,
                            "recipeTitle": selectedRecipe!.recipeName
                        }).then((result) => {
                            if (result.products?.length > 0) {
                                updatedIngredients[index].products = result.products;
                                setActiveIndexes([updatedIngredients[index].name])
                                setProductResults([...productResults,
                                {
                                    msg_id: Guid.newGuid(),
                                    sentOn: (new Date()).getTime() / 1000,
                                    sender: "assistant",
                                    content: {
                                        message: updatedIngredients[index].name,
                                        products: result.products,
                                        query: result.query,
                                        recipes: result.recipes
                                    }
                                }])
                            }
                            setExecutionMethod(false);
                        })

            } else {
                if (!executionMethod) {
                    if (updatedIngredients.filter(f => f.checked).length === 0)
                        setExecutionMethod(true);
                }
            }

        } catch (error) {

        }
        finally {
            setLoadingIngredient("");
        }

    };

    const handleStepCheck = (index: number) => {
        const updatedSteps = [...steps];
        const isChecking = !updatedSteps[index].checked;

        // Update steps based on the action (check/uncheck)
        for (let i = 0; i < updatedSteps.length; i++) {
            if (i <= index && isChecking) {
                // If we are checking, mark all steps before or equal to the current one as checked
                updatedSteps[i].checked = true;
            } else if (i >= index && !isChecking) {
                // If we are unchecking, mark all steps after or equal to the current one as unchecked
                updatedSteps[i].checked = false;
            }
        }
        setSteps(updatedSteps);
    };

    const prepValues = () => {
        setSteps(selectedRecipe?.directions.map(x => ({
            "description": x,
            "checked": false
        })) ?? [])

        var items: any[] = []
        selectedRecipe?.ingredients?.filter(f => (f.products?.length ?? 0) > 0)
            .forEach(e => {
                if (e.products.length > 0) {
                    e.checked = true;
                    items.push(
                        {
                            msg_id: Guid.newGuid(),
                            sentOn: (new Date()).getTime() / 1000,
                            sender: "assistant",
                            content: {
                                message: e.name,
                                products: e.products,
                                recipes: []
                            }
                        }
                    )
                }
            });

        setProductResults(items as any[]);
    }

    useEffect(() => {
        prepValues();

        // if (selectedRecipe !== undefined) {
        //     document.body.style.overflow = 'hidden'; // Disable background scroll
        // } else {
        //     document.body.style.overflow = 'auto'; // Enable background scroll
        // }

        // Cleanup when the component unmounts or when the panel closes
        return () => {
            // document.body.style.overflow = 'auto';
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDifficulty = (difficulty: "easy" | "medium" | "hard") => {
        switch (difficulty) {
            case "easy":
                return "Easy"
            case "medium":
                return "Medium"
            case "hard":
                return "Hard"
            default:
                break;
        }
    }

    return (
        <div className='recipe-detail-container'>
            <Icon
                onClick={() => setSelectedRecipe("", undefined)}
                className='recipe-detail-close'
                name="close"
                size='big'
            />
            <div className='recipe-detail-header'>
                <div className='recipe-detail-header-name'>{selectedRecipe?.recipeName}</div>
                {selectedRecipe?.recipeUrl !== undefined &&
                    selectedRecipe?.recipeUrl !== "" &&
                    (selectedRecipe?.recipeUrl?.indexOf("example") ?? -1) < 0
                    && <div className='clickable recipe-detail-source' onClick={() => window.open(selectedRecipe.recipeUrl, "_blank")}>Visit Source</div>}

                <div className="recipe-info"
                    style={{
                        maxWidth: "430px",
                        margin: "auto",
                        padding: "15px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        borderRadius: "15px",
                        backgroundColor: "white"
                    }}

                >
                    <span className="recipe-time fxDisplay fxDirCol">
                        <Image src='/time.svg' />
                        <p>{selectedRecipe?.executionTime ? `${selectedRecipe.executionTime}'` : "-"}</p>
                    </span>
                    <span className="recipe-difficulty fxDisplay fxDirCol">
                        <Image src='/cook.svg' />
                        <p>{selectedRecipe?.difficulty ? getDifficulty(selectedRecipe.difficulty) : "-"}</p>
                    </span>
                    <span className="recipe-servings fxDisplay fxDirCol">
                        <Image src='/bowl.svg' />
                        <p>{selectedRecipe?.noOfServings ?? "-"}</p>
                    </span>
                </div>

                <div className='recipe-detail-description'>{selectedRecipe?.recipeDescription}</div>
            </div>

            <div className={`recipe-detail fxDisplay  ${isMobile ? 'mobile' : ''}`}>
                <div className={`ingredients-section ${isMobile ? 'mobile' : ''}`}>
                    <h2>Ingredients</h2>
                    {isMobile ?
                        <Popup
                            trigger={<div className={`recipe-tip-container`} ><div>TIP</div></div>}
                            content={<span>Check off the ingredients you have used in the recipe or note the ingredients you miss and add them to your shopping.</span>}
                        /> :
                        <div className={`fxDisplay fxFixed recipe-tip-container`} >
                            <div>TIP</div>
                            <span>Check off the ingredients you have used in the recipe or note the ingredients you miss and add them to your shopping.</span>
                        </div>}
                    <List relaxed divided>
                        {selectedRecipe?.ingredients.map((ingredient, index) => (
                            <ListItem key={index} style={{
                                width: "100%",
                                display: "flex",
                            }}>
                                <input
                                    disabled={loadingIngredient !== ""}
                                    style={{
                                        width: "23px",
                                        height: "23px",
                                        marginTop: "5px"

                                    }}
                                    type="checkbox"
                                    checked={ingredient.checked}
                                    onChange={() => handleIngredientCheck(index)}
                                />

                                <ListContent className='fxStretch'>
                                    <ListHeader>{titleCase(ingredient.name)}</ListHeader>
                                    <ListDescription>
                                        {ingredient.quantity}
                                    </ListDescription>
                                </ListContent>
                                {loadingIngredient === ingredient.name && <Icon style={{ color: "#10985d", margin: "0px", marginTop: "6px" }} loading size='large' name='circle notch' />}
                                {(ingredient.products?.length ?? 0) > 0 &&
                                    <span style={{ marginTop: "6px" }} onClick={() => { setExecutionMethod(false) }}>
                                        <Icon style={{ color: "#10985d", margin: "0px" }} size='tiny' name='dot circle' disabled={!executionMethod} />
                                        <Icon style={{ color: "#10985d", margin: "0px" }} size='tiny' name='dot circle' disabled={!executionMethod} />
                                        <Icon style={{ color: "#10985d" }} size='tiny' name='dot circle' disabled={!executionMethod} />
                                    </span>
                                }
                            </ListItem>
                        ))}
                    </List>
                </div>

                {executionMethod && <div className={`execution-section ${isMobile ? 'mobile' : ''}`}>
                    <h2>Execution Method</h2>
                    {isMobile ?
                        <Popup
                            trigger={<div className={`recipe-tip-container`} ><div>TIP</div></div>}
                            content={<span>While you are cooking, check off the steps you complete and follow the recipe without getting lost.</span>}
                        /> :
                        <div className={`fxDisplay fxFixed recipe-tip-container`} >
                            <div>TIP</div>
                            <span>While you are cooking, check off the steps you complete and follow the recipe without getting lost.</span>
                        </div>}
                    <ol className="timeline" style={{ marginTop: "14px" }}>
                        {steps.map((step, index) => (
                            <li className={`${step.checked ? "completed" : ""}`}>
                                <div
                                    className={`checkbox ${step.checked ? "completed" : ""}`}
                                    onClick={() => handleStepCheck(index)}>
                                    <div style={{
                                        border: "solid",
                                        borderColor: "white",
                                        borderWidth: "2px",
                                        color: "white",
                                        width: "19px",
                                        height: "19px",
                                        borderRadius: "50%"
                                    }} />
                                </div>
                                <p>{step.description}</p>
                            </li>
                        ))}
                    </ol>
                </div>}

                {!executionMethod &&
                    <div className="recipe-ingredients-search">
                        <Icon name='undo' size='large' onClick={() => setExecutionMethod(true)} />
                        <Accordion
                            style={{ padding: "5px" }}
                            onTitleClick={(e, data) => {
                                if (activeIndexes.includes(data.index as string)) {
                                    setActiveIndexes(activeIndexes.filter(f => f !== (data.index as string)));
                                }
                                else
                                    setActiveIndexes([...activeIndexes, data.index as string])
                            }}
                            panels={productResults.slice()
                                .sort((a, b) => ((a.content as IMessageContent).message > (b.content as IMessageContent).message ? 1 : -1))
                                .filter(f => selectedRecipe?.ingredients?.filter(z => z.checked).map(z => z.name).includes((f.content as IMessageContent).message))
                                .map((x, i) => (
                                    {
                                        key: x.msg_id,
                                        index: (x.content as IMessageContent).message,
                                        title: {
                                            className: `recipe-ingredients-title`,
                                            content: `${titleCase((x.content as IMessageContent).message)}`, //(${(x.content as IMessageContent).products.length ?? 0})
                                        },
                                        content: {
                                            className: `${activeIndexes.includes((x.content as IMessageContent).message) ? 'active' : 'closed'}`,
                                            content: (
                                                <p className="recipe-ingredients-content">
                                                    <ProductsMessage
                                                        msg_id=''
                                                        message={x.content as IMessageContent}
                                                        scroll={() => { }}
                                                        hideMessage={true}
                                                        query={(x.content as IMessageContent).message}
                                                    />
                                                </p>
                                            ),
                                        },
                                    }))} />
                    </div>}
            </div>


        </div>

    );
}

export default observer(RecipeDetail);