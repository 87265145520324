import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IAssociationForSave, IDeliveryAssociation } from "../../../interfaces/association";
import { RootStore } from "../../../stores/RootStoreContext";
import { SortByTypes } from "../../../interfaces/search";

export class UserAssociationStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable sortBy?: SortByTypes = "min_price_asc";
    @observable associations: IDeliveryAssociation[] = []
    @observable searchText: string = "";
    @observable selectedCategories: string[] = [];

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action setHasMore = (value: boolean) => { this.hasMore = value };

    @action setAssociations = (value: IDeliveryAssociation[]) => {
        var existing = this.associations.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.associations.push(...missing);
    };

    @action searchCategory = async (categoryId: string) => {
        try {
            if (this.selectedCategories.indexOf(categoryId) > -1) {
                this.selectedCategories = this.selectedCategories.filter(f => f !== categoryId);
            }
            else {
                this.selectedCategories = [categoryId];
            }

            this.resetAssociations();
            this.setCurrentPage(0)

            this.load();
            this.rootStore.analyticsStore.setSearchEvent("Associations - Category", categoryId);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action resetAssociations = () => {
        this.associations = [];
    };

    @action removeAssociation = (id: string) => {
        this.associations = this.associations.filter(f => f.id !== id);
    };

    @action requestToGet = (id: string) => {
        agent.Associations.Order.request(id).then(() => {
            runInAction(() => {
                var idx = this.associations.findIndex(f => f.id === id);
                if (idx >= 0) {
                    this.associations[idx].requested = true;
                }
            })
        })
    }

    @action updateAssociation = (id: string, item: IAssociationForSave) => {
        var assIdx = this.associations.findIndex(f => f.id === id);

        if (assIdx > -1) {
            this.associations[assIdx].imageUrl = item.imageUrl;
            this.associations[assIdx].title = item.title;
        }
    }

    @action setSortBy = async (value: SortByTypes) => {
        if (this.sortBy !== value) {
            this.sortBy = value
            this.setLoading(true);
            this.resetAssociations();
            this.setCurrentPage(0);
            this.load();
        }

    };

    @action load = async (id?: string) => {
        try {
            this.setLoading(true);

            var response = await agent.Associations.Order.get(this.searchText, this.rootStore.commonStore.selectedMarketIds, this.selectedCategories, this.sortBy, this.currentPage)

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);

            this.setAssociations(response.Items);
        } catch (error: any) {
            console.log(error);
        }
        finally {
            this.setLoading(false);
        }

    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetAssociations();
            this.setCurrentPage(0)

            this.load();

            if (text !== "" && text !== undefined)
                this.rootStore.analyticsStore.setSearchEvent("Groups", text);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.searchText = ""
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetAssociations();
        this.selectedCategories = [];
    }
}