import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IProfitItem } from "../../../interfaces/report";
import { currencyFormat } from "../../products/functions/productHelper";
import { format } from "date-fns";
import { Countries } from "../../../options/Countries";

class ProfitReport {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable aggregateBy: "day" | "month" | "year" = "day";
    @observable currentPage: number = 0;
    @observable downloading: boolean = false;
    @observable countryId?: number = undefined;
    @observable hasMore: boolean = false;
    @observable items: IProfitItem[] = []
    @observable dateFrom?: number = undefined;
    @observable dateTo?: number = undefined;
    @observable frdateFrom?: number = undefined;
    @observable frdateTo?: number = undefined;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setAggregateBy = (value: "day" | "month" | "year") => this.aggregateBy = value;
    @action setDateFrom = (value?: number) => this.dateFrom = value;
    @action setDownloading = (value: boolean) => this.downloading = value;
    @action setDateTo = (value?: number) => this.dateTo = value;
    @action setCountryId = (value?: number) => this.countryId = value;
    @action resetItems = (value: IProfitItem[]) => this.items = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setfrDateFrom = (value?: number) => this.frdateFrom = value;
    @action setfrDateTo = (value?: number) => this.frdateTo = value;
    @action setItems = (value: IProfitItem[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action search = async () => {
        try {
            this.resetItems([]);
            this.setCurrentPage(0)
            this.setHasMore(true)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action load = async () => {
        try {
            if (!this.countryId) return;

            this.setLoading(true);

            var response = await agent.Reports.Profit.get(this.countryId, this.aggregateBy, this.dateFrom, this.dateTo, this.frdateFrom, this.frdateTo, this.currentPage, 0);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action exportDataForCsv = async () => {
        if (!this.countryId) return;

        this.setDownloading(true);
        try {
            var csvData: any[] = [];
            var titleText = this.aggregateBy === "day" ? "Day" : (this.aggregateBy === "month" ? "Month" : "Year")

            var company = await agent.Company.get(this.countryId);
            csvData.push([`Generated by`, company.name])
            csvData.push([`Title`, `Profit Report By ${titleText}`])
            csvData.push([`Address `, `${company.addressLine1}, ${company.addressLine2}, ${company.country}`])
            csvData.push([`VAT`, company.vatNumber])
            csvData.push([`EXO`, company.exoNumber])
            csvData.push([`BCRS`, company.bcrsNumber])

            csvData.push([])
            csvData.push([])
            csvData.push(["Filters :"])
            csvData.push(["", `Country`, Countries.filter(f => f.value === this.countryId)[0].text])
            csvData.push(["", `(Created) - Date From`, this.dateFrom ? format(this.dateFrom * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push(["", `(Created) - Date To`, this.dateTo ? format(this.dateTo * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push(["", `(Receipt) - Date From`, this.frdateFrom ? format(this.frdateFrom * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push(["", `(Receipt) - Date To`, this.frdateTo ? format(this.frdateTo * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])

            csvData.push([])
            csvData.push([])


            csvData.push([titleText, "Cost ex. VAT", "Sold ex. VAT", "Profit", "VAT",]);


            var response = await agent.Reports.Profit.get(this.countryId, this.aggregateBy, this.dateFrom, this.dateTo, this.frdateFrom, this.frdateTo, 0, 1);
            (response.Items as IProfitItem[]).forEach((x) => {
                csvData.push([
                    x.id,
                    currencyFormat(x.costNet, true),
                    currencyFormat(x.salesNet, true),
                    currencyFormat(x.profit, true),
                    currencyFormat(x.vat, true),
                ])
            });

            // add totals
            var totalProfit = response.Items?.reduce((a, b) => a + b.profit, 0)
            var totalCostNet = response.Items?.reduce((a, b) => a + b.costNet, 0)

            csvData.push([
                "Totals :",
                currencyFormat(totalCostNet, true),
                currencyFormat(response.Items?.reduce((a, b) => a + b.salesNet, 0), true),
                currencyFormat(totalProfit, true),
                currencyFormat(response.Items?.reduce((a, b) => a + b.vat, 0), true),
            ])


            var today = new Date();
            var fileName = `${today.getFullYear()}_${today.getMonth()}_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()} `;

            return {
                data: csvData,
                filename: `cheap_trolley_profit_by_${this.aggregateBy}_${fileName}.csv`,
            };

        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownloading(false);
        }
    };

    @action dispose = () => {
        this.setCountryId(undefined);
        this.setDateFrom(undefined);
        this.setDateTo(undefined);
        this.setCurrentPage(0);
        this.setHasMore(false);
        this.setDownloading(false);
        this.setItems([]);
    }
}


export const ProfitReportStore = new ProfitReport();