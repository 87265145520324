import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { IMessageContent } from '../../../interfaces/chat'
import RecipeCard from './RecipeCard'
import ResponseFeedback from './ResponseFeedback';
import { RootStoreContext } from '../../../stores/RootStoreContext';

interface IProps {
    messageId: string;
    status?: boolean;
    message: IMessageContent
    scroll: () => void
}

const RecipeMessage: React.FC<IProps> = ({ messageId, message, status }) => {
    const context = useContext(RootStoreContext);
    const { conversationId } = context.chatUIStore;

    return (
        <Fragment>
            {message.message}

            <div className='fxDisplay fxFixed'>
                {message.recipes.map(x =>
                    <RecipeCard messageId={messageId} recipe={x} />)}
            </div>
            {messageId !== '' && <ResponseFeedback chat_id={conversationId!} msg_id={messageId} initialStatus={status} />}
        </Fragment>
    )
}

export default observer(RecipeMessage)