import { IProductForList } from "../interfaces/product";

export const getImageUrl = (item: IProductForList) => {
    return getImageUrlPlain(item.marketId, item.imageUrl);
};

export const getImageUrlPlain = (marketId: string, imageUrl?: string) => {
    if (!imageUrl || imageUrl === "") return `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
    switch (marketId) {
        case "daves":
            return imageUrl.indexOf("https://") > -1 ? imageUrl :
                `https://shop.daves.com.mt/${imageUrl}`;
        case "greens":
            return `https://www.greens.com.mt${imageUrl}`;
        case "towers":
            return imageUrl.indexOf("https://www") > -1
                ? imageUrl
                : `https://www.myparktowers.com/${imageUrl}`;
        // case "welbee":
        //     return `https://www.maltasupermarket.com/${imageUrl}`;
        case "sparkleimage":
            return `https://www.sparkleimage.com.mt/${imageUrl}`;
        default:
            return imageUrl;
    }
};