export const DaysOfWeek = [
    {
        'key': 0,
        'value': 0,
        'text': "Sunday",
    },
    {
        'key': 1,
        'value': 1,
        'text': "Monday",
    },
    {
        'key': 2,
        'value': 2,
        'text': "Tuesday",
    },
    {
        'key': 3,
        'value': 3,
        'text': "Wednesday",
    },
    {
        'key': 4,
        'value': 4,
        'text': "Thursday",
    },
    {
        'key': 5,
        'value': 5,
        'text': "Friday",
    },
    {
        'key': 6,
        'value': 6,
        'text': "Saturday",
    },
]

export const HoursOfDay = [
    { 'key': 0, 'value': 0, 'text': '00:00' },
    { 'key': 1800, 'value': 1800, 'text': '00:30' },
    { 'key': 3600, 'value': 3600, 'text': '01:00' },
    { 'key': 5400, 'value': 5400, 'text': '01:30' },
    { 'key': 7200, 'value': 7200, 'text': '02:00' },
    { 'key': 9000, 'value': 9000, 'text': '02:30' },
    { 'key': 10800, 'value': 10800, 'text': '03:00' },
    { 'key': 12600, 'value': 12600, 'text': '03:30' },
    { 'key': 14400, 'value': 14400, 'text': '04:00' },
    { 'key': 16200, 'value': 16200, 'text': '04:30' },
    { 'key': 18000, 'value': 18000, 'text': '05:00' },
    { 'key': 19800, 'value': 19800, 'text': '05:30' },
    { 'key': 21600, 'value': 21600, 'text': '06:00' },
    { 'key': 23400, 'value': 23400, 'text': '06:30' },
    { 'key': 25200, 'value': 25200, 'text': '07:00' },
    { 'key': 27000, 'value': 27000, 'text': '07:30' },
    { 'key': 28800, 'value': 28800, 'text': '08:00' },
    { 'key': 30600, 'value': 30600, 'text': '08:30' },
    { 'key': 32400, 'value': 32400, 'text': '09:00' },
    { 'key': 34200, 'value': 34200, 'text': '09:30' },
    { 'key': 36000, 'value': 36000, 'text': '10:00' },
    { 'key': 37800, 'value': 37800, 'text': '10:30' },
    { 'key': 39600, 'value': 39600, 'text': '11:00' },
    { 'key': 41400, 'value': 41400, 'text': '11:30' },
    { 'key': 43200, 'value': 43200, 'text': '12:00' },
    { 'key': 45000, 'value': 45000, 'text': '12:30' },
    { 'key': 46800, 'value': 46800, 'text': '13:00' },
    { 'key': 48600, 'value': 48600, 'text': '13:30' },
    { 'key': 50400, 'value': 50400, 'text': '14:00' },
    { 'key': 52200, 'value': 52200, 'text': '14:30' },
    { 'key': 54000, 'value': 54000, 'text': '15:00' },
    { 'key': 55800, 'value': 55800, 'text': '15:30' },
    { 'key': 57600, 'value': 57600, 'text': '16:00' },
    { 'key': 59400, 'value': 59400, 'text': '16:30' },
    { 'key': 61200, 'value': 61200, 'text': '17:00' },
    { 'key': 63000, 'value': 63000, 'text': '17:30' },
    { 'key': 64800, 'value': 64800, 'text': '18:00' },
    { 'key': 66600, 'value': 66600, 'text': '18:30' },
    { 'key': 68400, 'value': 68400, 'text': '19:00' },
    { 'key': 70200, 'value': 70200, 'text': '19:30' },
    { 'key': 72000, 'value': 72000, 'text': '20:00' },
    { 'key': 73800, 'value': 73800, 'text': '20:30' },
    { 'key': 75600, 'value': 75600, 'text': '21:00' },
    { 'key': 77400, 'value': 77400, 'text': '21:30' },
    { 'key': 79200, 'value': 79200, 'text': '22:00' },
    { 'key': 81000, 'value': 81000, 'text': '22:30' },
    { 'key': 82800, 'value': 82800, 'text': '23:00' },
    { 'key': 84600, 'value': 84600, 'text': '23:30' },
]
