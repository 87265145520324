import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Card, Image } from "semantic-ui-react";
import { getMarketImageUrl } from "../../../helpers/supermarketImage";
import { RootStoreContext } from "../../../stores/RootStoreContext";

const PreferredSupermarkets = () => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const { isMobile } = context.deviceStore;
  const { preferredStores, setPreferredStores, saving } = context.profileStore;

  return (
    <Fragment>
      <Card.Group
        centered
        className="displayGrid"
        itemsPerRow={isMobile ? 1 : 3}
      >
        {markets
          .slice()
          .sort((a, b) => (a.title < b.title ? -1 : 1))
          .map((x) => (
            <Card
              key={x.type_id}
              raised
              className={`superMarketCard ${preferredStores.includes(x.type_id)
                ? "selectedsuperMarketCard"
                : ""
                }`}
              id={x.type_id}
              onClick={() => {
                if (saving) return;

                if (preferredStores.includes(x.type_id))
                  setPreferredStores(
                    preferredStores.filter((f) => f !== x.type_id)
                  );
                else setPreferredStores([...preferredStores, x.type_id]);
              }}
            >
              <Image
                centered
                src={getMarketImageUrl(x)}
                size="tiny"
                className={`superMarketImage`}
                alt={x.title}
              />
              <Card.Content
                style={{ padding: "1em 0em" }}
                className="fxDisplay fxDirCol"
                textAlign="center"
              >
                <Card.Header>{x.title}</Card.Header>
              </Card.Content>
            </Card>
          ))}
      </Card.Group>
    </Fragment>
  );
};

export default observer(PreferredSupermarkets);
