import { Languages } from "../options/languages";

export interface ILanguage {
    key: string;
    description: string;
}


export function getLanguageDefaultJson() {
    var items: ILanguage[] = [];
    Languages.forEach((lang) => items.push({ key: lang.key, description: "{}" }));

    return items;
}