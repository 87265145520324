import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { ICampaignForList } from "../../../interfaces/marketingCampaign";

export class MarketingCampaignsStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable searchText?: string = undefined;
    @observable items: ICampaignForList[] = []

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setSearchText = (value?: string) => { this.searchText = value };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action setHasMore = (value: boolean) => { this.hasMore = value };
    @action resetItems = (value: ICampaignForList[]) => { this.items = value };
    @action setItems = (value: ICampaignForList[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.MarketingCampaigns.get(this.searchText?.trim(), this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetItems([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action dispose = () => {

        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetItems([]);
        this.setHasMore(false);
    }
}