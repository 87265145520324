import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Button, Icon, Label, List } from "semantic-ui-react";
import agent from "../../../api/agent";
import { IOrderForList } from "../../../interfaces/order";
import { CheckOutOptions } from "../../../options/CheckoutTypes";
import { OrderStatusOption } from "../../../options/OrderStatusOption";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { GetFormattedAddress } from "../../../helpers/addressHelper";

interface IProps {
  order: IOrderForList;
  onClick: () => void;
}

const ClientOrderItem: React.FC<IProps> = ({ order, onClick }) => {
  const context = useContext(RootStoreContext);
  const { setEventCustom } = context.analyticsStore;

  const downloadPdf = async (orderId: string) => {
    try {
      const response = await agent.Orders.download_receipt(orderId);
      // If using presigned URL
      window.open(response.url, '_blank');
      // If directly transferring file, you'll need to handle blob response and create a download link
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  return (
    <List.Item
      onDoubleClick={order.status === "pending_payment" ? undefined : onClick}
    >
      <List.Content floated="right">
        {order.receiptNo && <Button
          size="large"
          content="Download Receipt"
          className="button-as-link ct-green-text-dark"
          onClick={(e) => {
            downloadPdf(order.id);
          }}
        />}
        {order.status === "pending_payment" ? (
          <Button
            size="large"
            content="Complete Payment"
            className="button-as-link greenColor"
            onClick={(e) => {
              agent.Orders.retry(order.id)
                .then((redirectUrl) => {
                  setEventCustom("retry_payment");
                  window.location.href = redirectUrl;
                })
                .catch(() => { });
            }}
          />
        ) : (
          <Fragment>
            <b>{`${order.totalCost.toFixed(2)} €`}</b>
            <Button
              size="large"
              className="button-as-link greenColor"
              icon={"angle right"}
              onClick={onClick}
            />
          </Fragment>
        )}
      </List.Content>
      <List.Content>
        <List.Header>
          <Label
            basic
            size="small"
            color={
              OrderStatusOption.filter((f) => f.value === order.status)[0].color
            }
          >
            {OrderStatusOption.filter((f) => f.value === order.status)[0].text}
          </Label>
          <span style={{ paddingLeft: "7px" }}>
            <span>{order.displayId ?? "Display id is not available"}</span>
            {order.checkoutType === "delivery" && order.delivery && (
              <span style={{ paddingLeft: "3px" }}>
                <Icon name="clock outline" color="green" />
                {`${format(new Date(order.delivery?.startsOn * 1000), 'EEEE, dd/MM/YYY HH:mm')} - ${format(new Date(order.delivery.to * 1000), 'HH:mm')} h`}
              </span>
            )}
          </span>
        </List.Header>
        <List.Description style={{ marginTop: "3px" }}>
          <div className="fxDisplay fxFixed ">
            <div>{format(order.createdOn * 1000, "dd/MM/yy, HH:mm")}</div>
            <span style={{ marginLeft: "4px" }}>
              {`- ${CheckOutOptions.filter((f) => f.key === order.checkoutType)[0]
                .text
                }`}
            </span>
            {order.checkoutType === "delivery" && (
              <div style={{ paddingLeft: "3px" }}>
                <Icon name="point" color="green" />
                {`${GetFormattedAddress(
                  order.address,
                  undefined,
                  undefined,
                  true
                )}`}
              </div>
            )}
          </div>

        </List.Description>
      </List.Content>
    </List.Item>
  );
};

export default observer(ClientOrderItem);
