import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IItemizedSaleItem } from "../../../interfaces/report";
import { currencyFormat } from "../../products/functions/productHelper";
import { format } from "date-fns";
import { Countries } from "../../../options/Countries";
import { IPricingDetailsMini } from "../../../interfaces/billing";
import { IShopLocation } from "../../../interfaces/locations";

class ItemizedSalesReport {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable byClient: boolean = false;
    @observable currentPage: number = 0;
    @observable downloading: boolean = false;
    @observable countryId?: number = undefined;
    @observable hasMore: boolean = false;
    @observable items: IItemizedSaleItem[] = []
    @observable dateFrom?: number = undefined;
    @observable dateTo?: number = undefined;
    @observable frdateFrom?: number = undefined;
    @observable frdateTo?: number = undefined;
    @observable pricingAgreements: IPricingDetailsMini[] = [];
    @observable locations: IShopLocation[] = [];
    @observable selectedLocations: string[] = [];
    @observable moreColumns: boolean = false;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setLocations = (value: IShopLocation[]) => this.locations = value;
    @action setSelectedLocations = (value: string[]) => this.selectedLocations = value;
    @action setMoreColumns = (value: boolean) => this.moreColumns = value;
    @action setPricingAgreements = (value: IPricingDetailsMini[]) => this.pricingAgreements = value;
    @action setByClient = (value: boolean) => this.byClient = value;
    @action setDateFrom = (value?: number) => this.dateFrom = value;
    @action setDownloading = (value: boolean) => this.downloading = value;
    @action setDateTo = (value?: number) => this.dateTo = value;
    @action setCountryId = (value?: number) => this.countryId = value;
    @action resetItems = (value: IItemizedSaleItem[]) => this.items = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setfrDateFrom = (value?: number) => this.frdateFrom = value;
    @action setfrDateTo = (value?: number) => this.frdateTo = value;
    @action setItems = (value: IItemizedSaleItem[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action search = async () => {
        try {
            this.resetItems([]);
            this.setCurrentPage(0)
            this.setHasMore(true)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action oneTimeLoad = async () => {
        try {
            var locations = await agent.Shop.Locations.Admin.all();
            this.setLocations(locations);

            var agreements = await agent.Shop.Billing.Pricing.get_list();
            this.setPricingAgreements(agreements);
        } catch (error) {
        }
    }

    @action get_agreement_value = (x: IItemizedSaleItem) => {
        var agreement = this.pricingAgreements.filter(f => f.accountId === x.accountId);
        if (agreement.length === 0) {
            return x.salesNet;
        }

        if (agreement[0].profitType === "on_top")
            return x.costNet;
        else return x.salesNet;
    }

    @action load = async () => {
        try {
            if (!this.countryId) return;

            this.setLoading(true);

            var response = await agent.Reports.ItemizedSales.get(this.countryId, this.byClient, this.selectedLocations, this.dateFrom, this.dateTo, this.frdateFrom, this.frdateTo, this.currentPage, 0);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action exportDataForCsv = async () => {
        if (!this.countryId) return;

        this.setDownloading(true);
        try {
            var csvData: any[] = [];

            var company = await agent.Company.get(this.countryId);
            // csv push dates of the report
            csvData.push([`Generated by`, company.name])
            csvData.push([`Title`, `Itemized Sales Report${this.byClient ? " By Client" : ""}`])
            csvData.push([`Address `, `${company.addressLine1}, ${company.addressLine2}, ${company.country}`])
            csvData.push([`VAT`, company.vatNumber])
            csvData.push([`EXO`, company.exoNumber])
            csvData.push([`BCRS`, company.bcrsNumber])

            csvData.push([])
            csvData.push([])
            csvData.push(["Filters :"])
            csvData.push(["", `Country`, Countries.filter(f => f.value === this.countryId)[0].text])
            csvData.push(["", `(Created) - Date From`, this.dateFrom ? format(this.dateFrom * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push(["", `(Created) - Date To`, this.dateTo ? format(this.dateTo * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push(["", `(Receipt) - Date From`, this.frdateFrom ? format(this.frdateFrom * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push(["", `(Receipt) - Date To`, this.frdateTo ? format(this.frdateTo * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])

            csvData.push([])
            csvData.push([])

            var headerData = (this.moreColumns === false)
                ? ["Id", "Description", "Quantity Sold", "Cost ex. VAT", "Sold ex. VAT", "Profit", "VAT",]
                : ["Id", "Description", "Quantity Sold", "Cost ex. VAT", "Cost VAT", "Sold ex. VAT", "Sold VAT", "Total Sold", "Profit",]

            if (this.byClient) {
                headerData.splice(1, 0, "Account")
                headerData.push("% Profit")
            }
            csvData.push(headerData);


            var response = await agent.Reports.ItemizedSales.get(this.countryId, this.byClient, this.selectedLocations, this.dateFrom, this.dateTo, this.frdateFrom, this.frdateTo, 0, 1);
            (response.Items as IItemizedSaleItem[]).forEach((x) => {
                var itemData: any = [x.id]
                if (this.byClient)
                    itemData.push(x.account!)

                if (this.moreColumns === false)
                    itemData.push(...[
                        `${x.title}`,
                        currencyFormat(x.quantity, false),
                        currencyFormat(x.costNet, true),
                        currencyFormat(x.salesNet, true),
                        currencyFormat(x.profit, true),
                        currencyFormat(x.vat, true)
                    ])
                else {
                    itemData.push(...[
                        `${x.title}`,
                        x.quantity,
                        x.costNet,
                        x.costVat,
                        x.salesNet,
                        x.vat,
                        x.vat + x.salesNet,
                        x.profit,
                    ])
                }

                if (this.byClient)
                    itemData.push(`${currencyFormat((x.profit / this.get_agreement_value(x)) * 100, false)}%`)
                csvData.push(itemData);
            });

            // add totals
            var totalProfit = response.Items?.reduce((a, b) => a + b.profit, 0)
            var totalCostNet = response.Items?.reduce((a, b) => a + b.costNet, 0)

            var footerData = [""]
            if (this.byClient)
                footerData.push("")

            footerData.push(...[
                "Totals :",
                currencyFormat(response.Items?.reduce((a, b) => a + b.quantity, 0), false),
                currencyFormat(totalCostNet, true),
                currencyFormat(response.Items?.reduce((a, b) => a + b.salesNet, 0), true),
                currencyFormat(totalProfit, true),
                currencyFormat(response.Items?.reduce((a, b) => a + b.vat, 0), true),
                `${currencyFormat((response.Items?.reduce((a, b) => a + b.profit, 0) / response.Items?.reduce((a, b) => a + this.get_agreement_value(b), 0)) * 100, false)}%`
            ])

            csvData.push(footerData)

            var today = new Date();
            var fileName = `${today.getFullYear()}_${today.getMonth()}_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()} `;

            return {
                data: csvData,
                filename: `cheap_trolley_itemized_sales_${this.byClient ? "by_client_" : ""}${fileName}.csv`,
            };

        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownloading(false);
        }
    };

    @action dispose = () => {
        this.setCountryId(undefined);
        this.setDateFrom(undefined);
        this.setDateTo(undefined);
        this.setCurrentPage(0);
        this.setHasMore(false);
        this.setDownloading(false);
        this.setItems([]);
        this.setSelectedLocations([])
    }
}


export const ItemizedSalesReportStore = new ItemizedSalesReport();