import { action, makeAutoObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IUserAccess, IUserRoles } from "../../../interfaces/user";
import { toast } from "react-toastify";

class UserRoles {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable editingItem?: IUserRoles = undefined;
    @observable userAccess?: IUserAccess = undefined;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setUserAccess = (value: IUserAccess) => this.userAccess = value;
    @action setUserRoles = (value: IUserRoles) => this.editingItem = value;

    @action load = async (userId?: string) => {
        if (!userId) return;

        this.setLoading(true)
        try {

            var acc = await agent.Users.Manage.get_access(userId);
            this.setUserAccess(acc);

            var roles = await agent.Users.Manage.get_roles(userId);
            this.setUserRoles(roles);

        } catch (error) {

        }
        finally {
            this.setLoading(false);
        }
    }

    @action toggleAdmin = async (userId: string) => {
        agent.Users.Manage.toggleAdmin(userId).then(() => {
            runInAction(() => {
                if (!this.userAccess) return;

                this.userAccess.isAdmin = !this.userAccess?.isAdmin
            })
            toast.success("Updated")
        })
    }

    @action toggleOwner = async (userId: string) => {
        agent.Users.Manage.toggleOwner(userId).then(() => {
            runInAction(() => {
                if (!this.userAccess) return;

                this.userAccess.isAdmin = !this.userAccess?.isAdmin
            })
            toast.success("Updated")
        })
    }

    @action save = async (userId: string, values: IUserRoles) => {
        return agent.Users.Manage.update_roles(userId, values).then(() => {
            this.setUserRoles(values);
            toast.success("Updated")
        })
    }
}

export const UserRolesStore = new UserRoles();