import { observable, action, runInAction, makeObservable } from "mobx";
import { RootStore } from "../../../stores/RootStoreContext";
import { ButtonTypes } from "./ButtonTypes";

export default class ConfirmationModalStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable.shallow
    modal = {
        open: false,
        onYesClick: () => { },
        header: null,
        body: null,
        noButtonText: "No",
        yesButtonText: "Yes",
        noButtonNegative: true,
        noButtonPositive: false,
        yesButtonNegative: false,
        yesButtonPositive: true,
        yesLoading: false
    };

    @action
    yesClicked = (
    ) => {
        runInAction(() => {
            this.modal.yesLoading = true;
        })

        this.modal.onYesClick();
    };

    @action
    openConfirmationModal = (
        header: any,
        body: any,
        onYesClick: () => void,
        noButtonText?: string,
        yesButtonText?: string,
        noButtonType?: ButtonTypes,
        yesButtonType?: ButtonTypes) => {

        this.modal.open = true;
        this.modal.yesLoading = false;
        this.modal.onYesClick = onYesClick;
        this.modal.header = header;
        this.modal.body = body;

        if (noButtonText !== undefined) this.modal.noButtonText = noButtonText;
        if (yesButtonText !== undefined) this.modal.yesButtonText = yesButtonText;

        if (noButtonType !== undefined) {
            this.modal.noButtonNegative = noButtonType === ButtonTypes.Negative
            this.modal.noButtonPositive = noButtonType === ButtonTypes.Positive
        }
        if (yesButtonType !== undefined) {
            this.modal.yesButtonNegative = yesButtonType === ButtonTypes.Negative
            this.modal.yesButtonPositive = yesButtonType === ButtonTypes.Positive
        }

    };
    @action
    closeConfirmationalModal = () => {
        this.modal.open = false;
        this.modal.header = null;
        this.modal.body = null;
        this.modal.noButtonText = "No";
        this.modal.yesButtonText = "Yes";
        this.modal.noButtonNegative = true;
        this.modal.noButtonPositive = false;
        this.modal.yesButtonNegative = false;
        this.modal.yesButtonPositive = true;
        this.modal.onYesClick = () => { };
        this.modal.yesLoading = false;
    };
}
