import { observer } from "mobx-react-lite";
import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Icon } from "semantic-ui-react";

interface IProps extends FieldRenderProps<string, any>, FormFieldProps {}

const PasswordInput: React.FC<IProps> = ({
  input,
  width,
  placeholder,
  meta: { touched, error },
  onEnter,
  secondaryInput,
}) => {
  const [showingPassword, setShowingPassword] = React.useState(false);

  const togglePasswordVisiblity = () => {
    setShowingPassword(!showingPassword);
  };

  return (
    <Form.Field error={touched && !!error} width={width}>
      <div className="pass-wrapper">
        <input
          {...input}
          placeholder={placeholder}
          autoComplete="current-password"
          type={showingPassword ? "text" : "password"}
          onKeyPress={(e: any) => {
            if (e.key === "Enter") {
              if (onEnter) onEnter();
            }
          }}
        />
        <Icon
          className={secondaryInput ? "pass-icon-left" : "pass-icon"}
          name={showingPassword ? "eye slash" : "eye"}
          onClick={togglePasswordVisiblity}
        />
        {secondaryInput}
      </div>
    </Form.Field>
  );
};

export default observer(PasswordInput);
