import { action, makeAutoObservable, observable } from "mobx";
import { IEmailTemplateForList } from "../../../interfaces/email";
import agent from "../../../api/agent";

class EmailTemplates {
    constructor() {
        makeAutoObservable(this);
    }

    @observable isLoading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = true;
    @observable searchText?: string = "";
    @observable items: IEmailTemplateForList[] = [];

    @action setLoading = (value: boolean) => this.isLoading = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action resetItems = (value: any[]) => this.items = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setItems = (value: any[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action load = async () => {
        try {
            if (this.hasMore === false && this.currentPage !== 0) return;

            this.setLoading(true);

            var response = await agent.Emails.get(this.searchText, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
            console.log(error);
        }
        finally {
            this.setLoading(false);
        }
    }

    @action search = async (text?: string) => {
        try {
            this.searchText = text;

            this.resetItems([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action dispose = () => {
        this.items = [];
        this.isLoading = false;
        this.searchText = undefined;
    }

}

export const EmailTemplatesStore = new EmailTemplates();