import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button, Divider, Grid } from 'semantic-ui-react'
import ClientOrderMonitorWidget from './components/ClientOrderMonitorWidget'
import { OrderMonitoringStore } from './functions/orderMonitoringStore'

const OrdersMonitoring = () => {
    const [selectedMode, setSelectedMode] = React.useState<"prepare" | "monitor">("prepare")

    return (
        <div className='simply-paddedPage'>
            <div style={{ textAlign: "center" }}>
                <Button.Group>
                    <Button
                        basic={selectedMode !== "prepare"}
                        color="blue"
                        content={"Prepare Orders"}
                        onClick={() => setSelectedMode("prepare")}
                    />
                    <Button
                        basic={selectedMode !== "monitor"}
                        color="blue"
                        onClick={(event) => {
                            setSelectedMode("monitor")
                        }}
                        content={"Monitor Orders"}
                    />
                </Button.Group>
            </div>
            <Divider />
            <Grid columns={16}>
                <Grid.Row >
                    {selectedMode === "monitor" && <Grid.Column width={3}>
                        <ClientOrderMonitorWidget header={"New"} statuses={['payment_failed', 'pending_payment', 'pending_processing']} orderMonitoreStore={new OrderMonitoringStore()} />
                    </Grid.Column>}
                    <Grid.Column width={selectedMode === "monitor" ? 3 : 8}>
                        <ClientOrderMonitorWidget header={"Sent To Market"} statuses={['sent_to_market']} orderMonitoreStore={new OrderMonitoringStore()} />
                    </Grid.Column>
                    <Grid.Column width={selectedMode === "monitor" ? 3 : 8}>
                        <ClientOrderMonitorWidget header={"Ready for Del/Pick"} statuses={['ready_for_delivery', 'ready_for_pickup']} orderMonitoreStore={new OrderMonitoringStore()} />
                    </Grid.Column>
                    {selectedMode === "monitor" && <Grid.Column width={3}>
                        <ClientOrderMonitorWidget header={"In Transit"} statuses={['out_for_delivery']} orderMonitoreStore={new OrderMonitoringStore()} />
                    </Grid.Column>}
                    {selectedMode === "monitor" && <Grid.Column width={2}>
                        <ClientOrderMonitorWidget header={"Issues"} statuses={['client_unavailable']} orderMonitoreStore={new OrderMonitoringStore()} />
                    </Grid.Column>}
                    {selectedMode === "monitor" && <Grid.Column width={2}>
                        <ClientOrderMonitorWidget header={"Completed"} statuses={['completed']} orderMonitoreStore={new OrderMonitoringStore()} />
                    </Grid.Column>}
                </Grid.Row>
            </Grid>

        </div>
    )
}

export default observer(OrdersMonitoring)