import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent, useContext, useEffect } from 'react'
import { useParams } from 'react-router';
import { Button, Checkbox, Dimmer, Divider, Form, Header, Icon, Loader, Message, Segment, Table } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { Form as FinalForm, Field } from "react-final-form";
import ErrorMessage from '../../../form/ErrorMessage';
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { LoadingParagraph } from '../../../components/LoadingParagraph';
import SelectInput from '../../../form/SelectInput';
import CheckBox from '../../../form/CheckBox';
import { UserRolesStore } from '../functions/UserRolesStore';
import { FORM_ERROR } from "final-form";
import { AdminStatusOptions } from '../../../options/AdminModules';
import { IUserRoles } from '../../../interfaces/user';

const UserRoles = () => {
    let { userId } = useParams<"userId">();
    const context = useContext(RootStoreContext)
    const { isOwner } = context.userStore;

    const {
        load,
        save,
        editingItem,
        loading,
        userAccess,
        toggleAdmin,
        toggleOwner
    } = UserRolesStore;


    useEffect(() => {
        if (isOwner) {
            load(userId)
        }
        return () => { };
    }, [isOwner, userId, load]);

    return (
        !isOwner ? <Message error content="You don't have permission for this page" /> : <div>
            <Segment basic>
                <Header color='blue' content="Owner Area" />
                <Divider />
                {!loading ? <div className='fxDisplay fxJustifyEven'>
                    <div className='fxDisplay fxJustifyCenter '>
                        <b>Is Admin</b>
                        <Checkbox
                            style={{ marginLeft: "17px" }}
                            toggle
                            defaultChecked={userAccess?.isAdmin}
                            onChange={(event, data) => toggleAdmin(userId!)}
                        />
                    </div>
                    <div className='fxDisplay fxJustifyCenter '>
                        <b>Is Owner</b>
                        <Checkbox
                            style={{ marginLeft: "17px" }}
                            toggle
                            defaultChecked={userAccess?.isOwner}
                            onChange={(event, data) => toggleOwner(userId!)}
                        />
                    </div>
                </div> : <Segment>
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <LoadingParagraph lines={3} />
                </Segment>}
            </Segment>
            <Divider />
            <Segment basic>
                <Header color='blue' content="Admin Area" />
                <Divider />
                <FinalForm
                    onSubmit={(values: IUserRoles) =>
                        save(userId!, values)
                            .catch((error) => ({
                                [FORM_ERROR]: error,
                            }))
                    }
                    initialValues={editingItem ?? undefined}
                    mutators={{
                        ...arrayMutators,
                    }}
                    render={({
                        handleSubmit,
                        submitError,
                        dirtySinceLastSubmit,
                        submitting,
                        invalid,
                        pristine,
                        values,
                        form,
                        form: {
                            mutators: { push, remove },
                        },
                    }) => (
                        <Form onSubmit={handleSubmit} error>
                            {loading && (
                                <Segment>
                                    <Dimmer active inverted>
                                        <Loader inverted>Loading</Loader>
                                    </Dimmer>
                                    <LoadingParagraph lines={5} />
                                </Segment>
                            )}

                            {/* title */}
                            <div style={{ minWidth: "350px", paddingTop: "15px" }}>
                                <Form.Group>
                                    <Form.Field width={16}>
                                        <label style={{ fontSize: "13px" }}>
                                            Opening Hours
                                        </label>
                                        <Table celled>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>
                                                        Module
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Has Access
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell />
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                <FieldArray name="roles">
                                                    {({ fields }) =>
                                                        fields.map((name, index) => (
                                                            <Table.Row key={index}>
                                                                <Table.Cell>
                                                                    {loading ? (
                                                                        <LoadingParagraph lines={1} />
                                                                    ) : (
                                                                        <Field
                                                                            component={SelectInput}
                                                                            options={AdminStatusOptions}
                                                                            name={`${name}.module`}
                                                                            loading={loading}
                                                                            placeholder={"Select Modules"}
                                                                        />
                                                                    )}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Field
                                                                        type="checkbox"
                                                                        loading={loading}
                                                                        component={CheckBox}
                                                                        toggle
                                                                        name={`${name}.hasAccess`}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell disabled={loading}>
                                                                    <Button
                                                                        color="red"
                                                                        icon="minus"
                                                                        circular
                                                                        size="mini"
                                                                        onClick={(event: SyntheticEvent) => {
                                                                            remove("roles", index);
                                                                            event.preventDefault();
                                                                        }}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        ))
                                                    }
                                                </FieldArray>
                                            </Table.Body>

                                            <Table.Footer fullWidth>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan="9">
                                                        <Button
                                                            disabled={loading}
                                                            floated="right"
                                                            basic
                                                            color="blue"
                                                            onClick={(event: SyntheticEvent) => {
                                                                push("roles", {});
                                                                event.preventDefault();
                                                            }}
                                                        >
                                                            <Icon name="add" />{" "}
                                                            Add
                                                        </Button>
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Footer>
                                        </Table>
                                    </Form.Field>
                                </Form.Group>

                            </div>

                            {submitError && !dirtySinceLastSubmit && (
                                <ErrorMessage error={submitError} />
                            )}

                            <div style={{ textAlign: "center" }}>
                                <Button.Group style={{ paddingTop: "15px" }}>
                                    <Button
                                        color="blue"
                                        disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                        loading={submitting}
                                        content={"Save"}
                                    />
                                    <Button.Or />
                                    <Button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            form.reset(editingItem)
                                        }}
                                        content={"Cancel"}
                                    />
                                </Button.Group>
                            </div>

                        </Form>
                    )}
                />
            </Segment>
        </div>
    )
}

export default observer(UserRoles)