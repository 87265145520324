import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IMeasurement, IMeasurementForCreate } from "../../../interfaces/measurements";
import { RootStore } from "../../../stores/RootStoreContext";

export class MeasurementsStore {
    rootStore: RootStore;

    @observable measurements: IMeasurement[] = [];

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action setCategories = (value: IMeasurement[]) => this.measurements = value;

    @action load = () => {
        agent.ProductMeasurements.get().then(value => this.setCategories(value));
    }

    @action save = async (values: IMeasurementForCreate) => {
        return agent.ProductMeasurements.create(values);
    }

}