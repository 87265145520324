import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import agent from '../../../api/agent';
import { IOrderTrackRecord } from '../../../interfaces/order';
import { Dimmer, Loader, Segment, Table } from 'semantic-ui-react';
import { format } from 'date-fns';
import { CourrierStatusTypes } from '../../../options/CourrierStatusTypes';

interface IProp {
    orderId: string
}

const TrackOrderWidget: React.FC<IProp> = ({ orderId }) => {

    const [records, setRecords] = React.useState<IOrderTrackRecord[] | undefined>(undefined);

    const load = () => {
        agent.Orders.trackOrder(orderId).then((records) => setRecords(records))
            .catch(() => { });
    };

    useEffect(() => {
        load()

        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        records === undefined ?
            <Segment basic>
                <Dimmer active inverted>
                    <Loader >Loading</Loader>
                </Dimmer>
            </Segment> :
            <Table style={{ marginTop: "0px", paddingTop: "0px" }} basic='very' >
                <Table.Header>
                    <Table.HeaderCell>Event</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {(records?.length !== 0) ? records?.slice().sort((a, b) => a.createdOn > b.createdOn ? -1 : 1).map((x, i) =>
                        <Table.Row key={i}>
                            <Table.Cell>{CourrierStatusTypes.filter(f => f.value === x.type)[0].text}</Table.Cell>
                            <Table.Cell>{format(x.createdOn * 1000, "EEEE, do MMM HH:mm")}</Table.Cell>
                        </Table.Row>) : <Table.Row>
                        <Table.Cell colSpan="2" textAlign='center'>
                            We don't have any tracking information for your package yet.
                        </Table.Cell></Table.Row>}
                </Table.Body>
            </Table>
    )
}


export default observer(TrackOrderWidget);