import React, { useContext } from "react";
import { Button, Form, Grid } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import {
  IPromoCodeForAdd,
  IPromoCodeForList,
} from "../../../interfaces/promos";
import {
  combineValidators,
  composeValidators,
  isNumeric,
  isRequired,
} from "revalidate";
import ErrorMessage from "../../../form/ErrorMessage";
import TextInput from "../../../form/TextInput";
import DateInput from "../../../form/DateInput";
import NumericInput from "../../../form/NumericInput";
import { observer } from "mobx-react-lite";

const validate = combineValidators({
  code: isRequired(""),
  activeFrom: isRequired(""),
  activeTo: isRequired(""),
  discount: composeValidators(isRequired(""), isNumeric(""))(""),
  fixedDiscount: composeValidators(isRequired(""), isNumeric(""))(""),
  maxHits: composeValidators(isRequired(""), isNumeric(""))(""),
  maxTotalHits: composeValidators(isNumeric(""))(""),
});

interface IProps {
  editingItem?: IPromoCodeForList;
}

const PromoDetailWidget: React.FC<IProps> = ({ editingItem }) => {
  const context = useContext(RootStoreContext);
  const { create, update, disable } = context.promoCodesStore;
  const { closeModal } = context.modalStore;

  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column>
          <FinalForm
            onSubmit={(values: IPromoCodeForAdd) =>
              (editingItem === undefined
                ? create(values)
                : update(values as IPromoCodeForList)
              )
                .then(() => closeModal())
                .catch((error) => ({
                  [FORM_ERROR]: error,
                }))
            }
            validate={validate}
            initialValues={editingItem ?? {}}
            render={({
              handleSubmit,
              submitting,
              submitError,
              valid,
              dirtySinceLastSubmit,
              values,
            }) => (
              <Form onSubmit={handleSubmit} error>
                <Form.Field>
                  <label style={{ fontSize: "13px" }}>{"Code"}</label>
                  <Field
                    name="code"
                    component={TextInput}
                    placeholder={"What the user will type to apply..."}
                  />
                </Form.Field>
                <Form.Field>
                  <label style={{ fontSize: "13px" }}>
                    {"Fixed Discount (€)"}
                  </label>
                  <Field
                    name="fixedDiscount"
                    component={NumericInput}
                    placeholder={"Discount to be applied"}
                  />
                </Form.Field>
                <Form.Field>
                  <label style={{ fontSize: "13px" }}>{"Discount (%)"}</label>
                  <Field
                    name="discount"
                    component={NumericInput}
                    placeholder={"Discount % to be applied"}
                  />
                </Form.Field>
                <Form.Field>
                  <label style={{ fontSize: "13px" }}>
                    {"Max use per account"}
                  </label>
                  <Field
                    name="maxHits"
                    component={NumericInput}
                    placeholder="How many times an account can use the code..."
                  />
                </Form.Field>
                <Form.Field>
                  <label style={{ fontSize: "13px" }}>
                    {"Max use (global)"}
                  </label>
                  <Field
                    name="maxTotalHits"
                    component={NumericInput}
                    placeholder="How many accounts in total can use the code..."
                  />
                </Form.Field>

                <Form.Field>
                  <label style={{ fontSize: "13px" }}>{"Active from"}</label>
                  <Field
                    name="activeFrom"
                    component={DateInput}
                    min={new Date()}
                    date={true}
                    time={true}
                    placeholder={"Active from"}
                  />
                </Form.Field>

                <Form.Field>
                  <label style={{ fontSize: "13px" }}>{"Active until"}</label>
                  <Field
                    name="activeTo"
                    component={DateInput}
                    min={values?.activeFrom ?? new Date()}
                    date={true}
                    time={true}
                    placeholder={"Active To"}
                    dropUp={true}
                  />
                </Form.Field>

                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage error={submitError} />
                )}
                <div style={{ textAlign: "center" }}>
                  {editingItem === undefined ? (
                    <Button
                      disabled={!valid && !dirtySinceLastSubmit}
                      loading={submitting}
                      content={"Create"}
                      basic
                      color="blue"
                    />
                  ) : (
                    <Button.Group style={{ paddingTop: "15px" }}>
                      <Button
                        color="blue"
                        disabled={
                          values.activeFrom < new Date().getTime() / 1000 ||
                          values.activeTo < new Date().getTime() / 1000
                        }
                        loading={submitting}
                        content={"Update"}
                      />
                      <Button.Or />
                      <Button
                        color="red"
                        loading={submitting}
                        disabled={values.activeTo < new Date().getTime() / 1000}
                        onClick={(event) => {
                          event.preventDefault();
                          disable(editingItem.id).then(() => closeModal());
                        }}
                        content={"Disable"}
                      />
                      <Button.Or />
                      <Button
                        onClick={(event) => {
                          event.preventDefault();
                          closeModal();
                        }}
                        content={"Cancel"}
                      />
                    </Button.Group>
                  )}
                </div>
              </Form>
            )}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(PromoDetailWidget);
