import { SemanticCOLORS } from "semantic-ui-react"

export const LocationOrderStatusOptions: Array<{
    value: string,
    key: string,
    text: string,
    text2: string,
    color: SemanticCOLORS,
    color2: SemanticCOLORS
}> =
    [
        {
            "value": "sent_to_market",
            "key": "sent_to_market",
            "text": "Sent to shop",
            "text2": "Received",
            "color": "grey",
            "color2": "red",
        },
        {
            "value": "seen",
            "key": "seen",
            "text": "Seen by Shop",
            "text2": "Seen by Shop",
            "color": "grey",
            "color2": "red",
        },
        {
            "value": "in_process",
            "key": "in_process",
            "text": "In process by Shop",
            "text2": "Preparing",
            "color": "grey",
            "color2": "red",
        },
        {
            "value": "ready_for_delivery",
            "key": "ready_for_delivery",
            "text": "Ready for delivery",
            "text2": "Ready for delivery",
            "color": "grey",
            "color2": "green",
        },
        {
            "value": "received",
            "key": "received",
            "text": "Received",
            "text2": "Received by Cheap Trolley",
            "color": "green",
            "color2": "green",
        },
        {
            "value": "completed",
            "key": "completed",
            "text": "Completed",
            "text2": "Completed",
            "color": "green",
            "color2": "green",
        },
    ]