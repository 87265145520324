import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Button, Form } from 'semantic-ui-react'
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import ErrorMessage from '../../../form/ErrorMessage'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import TextInput from '../../../form/TextInput';
import { combineValidators, isRequired } from 'revalidate';

interface IProps {
    submit: (closeModal: () => void, updateTransactionId: string) => Promise<void>
}

const validate = combineValidators({
    vivaTransactionId: isRequired(""),
});

const ChangeOrderVivaTransaction: React.FC<IProps> = ({ submit }) => {
    const context = useContext(RootStoreContext);
    const { closeModal } = context.modalStore;

    return (
        <FinalForm
            validate={validate}
            onSubmit={(values: any) =>
                submit(closeModal, values.vivaTransactionId)
                    .catch((error) => ({
                        [FORM_ERROR]: error,
                    }))
            }
            render={({
                handleSubmit,
                submitting,
                invalid,
                dirtySinceLastSubmit,
                submitError,
            }) => (
                <Form onSubmit={handleSubmit} error className="attached fluid">
                    <Form.Field>
                        <label style={{ fontSize: "13px", textAlign: "left", color: "Blue" }}>
                            {"Viva TransactionId"}
                        </label>
                        <Field
                            name="vivaTransactionId"
                            component={TextInput}
                        />
                    </Form.Field>

                    {submitError && !dirtySinceLastSubmit && (
                        <ErrorMessage error={submitError} />
                    )}

                    <div style={{ marginTop: "7px", textAlign: "center" }}>
                        <Button
                            disabled={invalid && !dirtySinceLastSubmit}
                            loading={submitting}
                            color="blue"
                            content={"Save"}
                        />
                    </div>
                </Form>
            )}
        />
    )
}


export default observer(ChangeOrderVivaTransaction)