import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { IShopLocation } from "../../../interfaces/locations";
import { ICatalogueForList, ICatalogueForUpdate } from "../../../interfaces/catalogue";
import { ProductCategory } from "../../../interfaces/categories";
import { IMeasurement } from "../../../interfaces/measurements";
import { IDietaryCategory } from "../../../interfaces/dietaryCategory";
import { IExtraItem, IVATCategory } from "../../../interfaces/vat";
import { Guid } from "../../../helpers/guid";
import { toast } from "react-toastify";

export class CatalogueAdminStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable refreshingSearch: boolean = false;
    @observable loadingDetail: boolean = false;
    @observable currentPage: number = 0;
    @observable editingId?: string = undefined;
    @observable hasMore: boolean = false;
    @observable products: ICatalogueForList[] = []
    @observable editingItem?: ICatalogueForUpdate = undefined
    @observable refreshingId?: string = undefined
    @observable locations: IShopLocation[] = [];
    @observable searchText: string = "";
    @observable categories: ProductCategory[] = [];
    @observable measurements: IMeasurement[] = [];
    @observable active: boolean = false;
    @observable unavailable: boolean = false;
    @observable available: boolean = false;
    @observable VATCategories: IVATCategory[] = [];
    @observable Extras: IExtraItem[] = [];
    @observable dietaryCategories: IDietaryCategory[] = [];
    @observable selectedFields: string[] = [];
    @observable missingFields: string[] = [];
    @observable selectedCategories: string[] = [];
    @observable mode: "list" | "bulk" = 'list';

    @action setSelectedFields = (value: string[]) => this.selectedFields = value;
    @action setLoading = (value: boolean) => this.loading = value;
    @action setRefreshingSearch = (value: boolean) => this.refreshingSearch = value;
    @action setVATCategories = (value: IVATCategory[]) => this.VATCategories = value;
    @action setExtraItems = (value: IExtraItem[]) => this.Extras = value;
    @action setRefreshing = (value?: string) => this.refreshingId = value;
    @action setLoadingDetail = (value: boolean) => this.loadingDetail = value;
    @action setLocations = (value: IShopLocation[]) => this.locations = value;
    @action setEditingItem = (value?: ICatalogueForUpdate) => this.editingItem = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setEditingId = (value?: string) => this.editingId = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetProducts = (value: ICatalogueForList[]) => this.products = value;
    @action setCategories = (value: ProductCategory[]) => this.categories = value.sort((n1, n2) => n1.order - n2.order);
    @action setMeasurements = (value: IMeasurement[]) => this.measurements = value;
    @action setDietaryCategories = (value: IDietaryCategory[]) => this.dietaryCategories = value;
    @action setProducts = (value: ICatalogueForList[]) => {
        var existing = this.products.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.products.push(...missing);
    };

    @action refreshCategories = async () => agent.ProductCategories.getAll().then(value => this.setCategories(value)).catch(() => { });
    @action refreshMeasurements = async () => agent.ProductMeasurements.get().then(value => this.setMeasurements(value)).catch(() => { });
    @action refreshDietaryCategories = async () => agent.DietaryCategories.get().then(value => this.setDietaryCategories(value)).catch(() => { });

    @action oneTimeLoad = async () => {
        var locations = await agent.Shop.Locations.Admin.all();
        this.setLocations(locations);

        if (this.categories.length === 0)
            agent.ProductCategories.getAll().then(value => this.setCategories(value)).catch(() => { });
        if (this.dietaryCategories.length === 0)
            agent.DietaryCategories.get().then(value => this.setDietaryCategories(value)).catch(() => { });
        if (this.measurements.length === 0)
            agent.ProductMeasurements.get().then(value => this.setMeasurements(value)).catch(() => { });
        if (this.VATCategories.length === 0)
            agent.Catalogue.VAT.get(129).then(value => this.setVATCategories(value)).catch(() => { });
        if (this.Extras.length === 0)
            agent.Catalogue.Extras.get(129).then(value => this.setExtraItems(value)).catch(() => { });

    }

    @action toggleMode = () => {
        this.mode = this.mode === "bulk" ? "list" : "bulk";

        this.resetProducts([]);
        this.setCurrentPage(0);
        this.setHasMore(true);

        this.load();
    }

    @action load = async (associationId?: string) => {
        try {
            this.setLoading(true);

            var response = await agent.Catalogue.Admin.get(
                this.mode,
                this.searchText,
                this.active,
                this.unavailable,
                this.available,
                this.missingFields,
                this.selectedCategories,
                associationId,
                this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setProducts(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }
    };

    @action updateEditingItemImage = (img: string) => {
        if (this.editingItem)
            this.editingItem.imageUrl = img;
    }

    @action uploadFile = async (file: object) => {
        var type = (file as any).path.split('.').slice(-1)[0]

        var fileName = `ct-${Guid.newGuid()}.${type}`;

        var response = await agent.AWS.Bucket.createPresignedUrl(fileName)
        await agent.AWS.Bucket.uploadFile(file as Blob, response)

        return `${process.env.REACT_APP_CLOUDFRONT}/${fileName}`;
    };

    @action loadImages = async (associationId: string) => {
        return await agent.Catalogue.Admin.getImages(associationId);
    }

    @action refreshCatalogue = async (productId: string) => {
        if (this.refreshingId !== undefined) return;
        try {
            this.setRefreshing(productId);

            await agent.Catalogue.Admin.refresh(productId).then((updated) => {
                runInAction(() => {
                    var idx = this.products.findIndex(f => f.id === productId);

                    this.products[idx] = updated;
                })
            })
        } catch (error) {
        }
        finally {
            this.setRefreshing(undefined);
        }

    };

    @action refreshCatalogueData = async (productId: string) => {
        if (this.refreshingId !== undefined) return;
        try {
            this.setRefreshing(productId);

            await agent.Catalogue.Admin.refreshData(productId).then((updated) => {
                runInAction(() => {
                    var idx = this.products.findIndex(f => f.id === productId);
                    this.products[idx] = updated;
                })
            })
        } catch (error) {
        }
        finally {
            this.setRefreshing(undefined);
        }

    };

    @action changeAvailability = async (id: string, available: boolean) => {
        try {
            if (available) {
                agent.Catalogue.Admin.makeAvailable(id).then(() => {
                    this.refreshCatalogueData(id);
                }).catch(() => { })
            }
            else {
                agent.Catalogue.Admin.makeUnavailable(id).then(() => {
                    this.refreshCatalogueData(id);
                }).catch(() => { })
            }
        } catch (error: any) {
        }
    }

    @action loadItemDetail = async () => {
        try {
            if (!this.editingId) return;

            this.setLoadingDetail(true);

            var product = await agent.Catalogue.Admin.detail(this.editingId);
            this.setEditingItem(product);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoadingDetail(false);
        }

    };

    @action toggleMissingFields = (fields: string[]) => {
        try {
            this.disposeDetail();
            this.resetProducts([]);
            this.setCurrentPage(0)
            this.missingFields = fields;

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    }

    @action toggleSelectedCategories = (fields: string[]) => {
        try {
            this.disposeDetail();
            this.resetProducts([]);
            this.setCurrentPage(0)
            this.selectedCategories = fields;

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    }

    @action toggleUnavailable = () => {
        try {
            this.disposeDetail();
            this.resetProducts([]);
            this.setCurrentPage(0)
            this.unavailable = !this.unavailable;

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    }

    @action toggleAvailable = () => {
        try {
            this.disposeDetail();
            this.resetProducts([]);
            this.setCurrentPage(0)
            this.available = !this.available;

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    }


    @action toggleActive = () => {
        try {
            this.disposeDetail();
            this.resetProducts([]);
            this.setCurrentPage(0)
            this.active = !this.active;

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    }

    @action search = async (text: string) => {
        try {
            this.disposeDetail();
            this.searchText = text;
            this.resetProducts([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action bulkFilterSave = async (values: ICatalogueForUpdate) => {
        return agent.Catalogue.Admin.bulkUpdate(
            values,
            this.searchText,
            this.active,
            this.missingFields,
            this.unavailable,
            this.available,
            this.selectedCategories)
    };

    @action save = async (values: ICatalogueForUpdate) => {
        if (!this.editingId) return;

        await agent.Catalogue.Admin.update(this.editingId, values).then(() => {
            runInAction(() => {
                var idx = this.products.findIndex(f => f.id === this.editingId);
                this.products[idx].title = values.title;
                // this.products[idx].tags = vals.tags;
                this.products[idx].imageUrl = values.imageUrl;
                this.products[idx].active = values.active;
                this.products[idx].deleted = values.deleted;
                this.products[idx].brandTags = (values as any).brand_tags;
                this.products[idx].vat = (values as any).vat;
            })
        })
    };

    @action refreshData = async () => {
        this.setCurrentPage(0);
        this.resetProducts([]);
        this.setHasMore(false);

        this.load();
    }

    @action bulksave = async (values: ICatalogueForUpdate[]) => {
        var requests: any[] = []
        values.forEach((item) => {
            requests.push(agent.Catalogue.Admin.update(item.id, item).then(() => {
                toast.success(item.title)
            }).catch(() => toast.error(item.title)))
        })

        return Promise.allSettled(requests);
    }

    @action refreshSearch = async () => {
        this.setRefreshingSearch(true);
        return agent.Catalogue.Admin.refreshSmartSeatch().then((result) => {
            this.setRefreshingSearch(false);
            return result;
        })
    };

    @action toggleHasImage = async (catalogueId: string) => {
        try {
            agent.Catalogue.Admin.toggleHasImage(catalogueId).then(() => {
                runInAction(() => {
                    var idx = this.products.findIndex(x => x.id === catalogueId);
                    this.products[idx].hasImage = !this.products[idx].hasImage;
                })

            })
        } catch (error) {

        }
    }

    @action dispose = () => {
        this.searchText = ""
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetProducts([]);
        this.setHasMore(false);
        this.disposeDetail();
    }

    @action disposeDetail = () => {
        this.setLoading(false);
        this.setEditingId(undefined)
        this.setEditingItem(undefined)
    }

}
