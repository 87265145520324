import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Header, Icon, List, Popup, Table, Image } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { IBrand } from "../../../interfaces/brand";
import { ButtonTypes } from "../../confirmationModal/functions/ButtonTypes";
import { toast } from "react-toastify";

interface IProps {
    brand: IBrand;
    editingId?: string;
    setEditingId: (id: string) => void
}

const BrandTableRow: React.FC<IProps> = ({ brand, editingId, setEditingId }) => {
    const context = useContext(RootStoreContext);
    const { openConfirmationModal, closeConfirmationalModal } = context.confirmationModalStore;
    const {
        getProductSample,
        deleteBrand,
        mergeBrands,
        originalId,
        targetId,
        setOriginalId,
        setTargetId
    } = context.brandAdminStore;

    return (
        <Table.Row active={editingId === brand.id} onClick={() => {
            if (editingId !== undefined)
                setEditingId(brand.id);
        }}>
            <Table.Cell>
                <Header as="h4" image>
                    {brand.imageUrl && <Popup
                        trigger={
                            <Image
                                src={brand.imageUrl ?? `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`}
                                alt={brand.imageUrl !== undefined ? brand.title : "Image not found"}
                                size="mini"
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                }}
                            />
                        }
                        content={
                            <Image
                                src={brand.imageUrl ?? `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`}
                                alt={brand.imageUrl !== undefined ? brand.title : "Image not found"}
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                }}
                            />
                        }
                    />}
                    <Header.Content >
                        {brand.title ?? "-"}
                        {(brand.counter ?? 0) > 0 && <Header.Subheader>
                            {`${brand.counter} product(s)`}
                        </Header.Subheader>}
                    </Header.Content>
                </Header>
            </Table.Cell>
            <Table.Cell>
                {brand.tags.join(",")}
            </Table.Cell>
            <Table.Cell singleLine>
                {(!originalId || originalId === brand.id) && < Icon
                    inverted
                    name="object group"
                    color={originalId === brand.id ? "blue" : "grey"}
                    circular
                    onClick={() => {
                        if (!originalId) {
                            setOriginalId(brand.id);
                            return
                        }
                        else if (originalId === brand.id) {
                            setOriginalId(undefined);
                            setTargetId(undefined);
                            return
                        }
                    }}
                />}
                {originalId && originalId !== brand.id && (!targetId || targetId === brand.id) && < Icon
                    inverted
                    name="object group"
                    color={targetId === brand.id ? "orange" : "grey"}
                    circular
                    onClick={() => {
                        mergeBrands(brand.id);
                    }}
                />}
                <Icon
                    name="zoom in"
                    className="ct-green-button-active"
                    circular
                    onClick={() => {
                        getProductSample(brand.id).then((result?: string[]) => {
                            toast.info(<List divided>
                                {((result?.length ?? 0) > 0) ? result?.map(f => <List.Item>{f}</List.Item>) : <List.Item>No Items</List.Item>}
                            </List>)
                        })
                    }}
                />
                <Icon
                    name="pencil"
                    className="ct-green-button-active"
                    circular
                    onClick={() => {
                        setEditingId(brand.id);
                    }}
                />
                <Icon
                    inverted
                    name="close"
                    color="red"
                    circular
                    onClick={() => {
                        openConfirmationModal(
                            "Confirm brand deletion",
                            "Are you sure you want to delete this brand?",
                            () =>
                                deleteBrand(brand.id).then(() =>
                                    closeConfirmationalModal()
                                ),
                            "Cancel",
                            "Delete",
                            ButtonTypes.None,
                            ButtonTypes.Negative
                        );
                    }}
                />

            </Table.Cell>
        </Table.Row>
    );
};

export default observer(BrandTableRow);
