import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IInventoryProductForList, IProductForUpdate } from "../../../interfaces/product";
import { RootStore } from "../../../stores/RootStoreContext";
import { ILocationForDropDown } from "../../../interfaces/locations";
import { IPricingDetails } from "../../../interfaces/billing";

export class InventoryStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable loadingDetail: boolean = false;
    @observable currentPage: number = 0;
    @observable shopId?: string = undefined;
    @observable editingId?: string = undefined;
    @observable hasMore: boolean = false;
    @observable ungroupedOnly: boolean = false;
    @observable activeOnly: boolean = false;
    @observable products: IInventoryProductForList[] = []
    @observable editingItem?: IProductForUpdate = undefined
    @observable locations: ILocationForDropDown[] = [];
    @observable searchText: string = "";
    @observable updatingDelivery: string = "";
    @observable pricingDetails?: IPricingDetails = undefined;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setUngroupdOnly = (value: boolean) => this.ungroupedOnly = value;
    @action setActiveOnly = (value: boolean) => this.activeOnly = value;
    @action setLoadingDetail = (value: boolean) => this.loadingDetail = value;
    @action setUpdatingDelivery = (value: string) => this.updatingDelivery = value;
    @action setLocations = (value: ILocationForDropDown[]) => this.locations = value;
    @action setShopId = (value?: string) => this.shopId = value;
    @action setEditingItem = (value?: IProductForUpdate) => this.editingItem = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setEditingId = (value?: string) => this.editingId = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetProducts = (value: IInventoryProductForList[]) => this.products = value;
    @action setPricingDetails = (value?: IPricingDetails) => this.pricingDetails = value;

    @action setProducts = (value: IInventoryProductForList[]) => {
        var existing = this.products.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.products.push(...missing);
    };

    @action getAllLocations = async (shopId: string) => {
        var locations = await agent.Shop.Locations.all(shopId);
        this.setLocations(locations);

        const { isAdmin } = this.rootStore.userStore;
        if (isAdmin)
            agent.Shop.Billing.Pricing.get(shopId).then((details) => this.setPricingDetails(details));
    }

    @action load = async (shopId?: string) => {
        try {
            shopId = shopId ?? this.shopId
            this.setShopId(shopId);
            this.setLoading(true);

            var response = await agent.Inventory.get(this.searchText,
                this.ungroupedOnly,
                this.activeOnly,
                shopId,
                this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setProducts(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action loadItemDetail = async () => {
        try {
            if (!this.editingId) return;

            this.setLoadingDetail(true);

            var product = await agent.Inventory.getDetail(this.editingId);
            this.setEditingItem(product);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoadingDetail(false);
        }

    };

    @action discontinueProduct = async (id: string) => {
        agent.Inventory.update(id, { discontinued: true }).then(() => {
            var idx = this.products.findIndex(f => f.id === id)
            if (idx > -1) {
                this.products[idx].discontinued = true;
                this.products[idx].lastUpdated = (new Date()).getTime() / 1000;
            }
        })
    }

    @action saveProduct = async (item: IProductForUpdate) => {
        if (!this.editingId) return;

        agent.Inventory.update(this.editingId, item).then(() => {
            var idx = this.products.findIndex(f => f.id === this.editingId)
            if (idx > -1) {
                this.products[idx].discontinued = item.discontinued;
                this.products[idx].itemDesc = item.itemDesc!;
                this.products[idx].offerItem = item.offerItem ?? false;
                this.products[idx].imageUrl = item.imageUrl;
                this.products[idx].locations = item.locations;
                this.products[idx].currentPrice = parseFloat(item.currentPrice?.toString() ?? "");
                this.products[idx].lastUpdated = (new Date()).getTime() / 1000;
                this.setEditingId(undefined)
            }
        })
    }

    @action toggleDelivery = async (itemId: string) => {
        if (this.updatingDelivery) return;

        this.setUpdatingDelivery(itemId);

        await agent.Inventory.toggleDelivery(itemId).then((result) => {
            var idx = this.products.findIndex(f => f.id === this.updatingDelivery)
            if (idx > -1) {
                this.products[idx].delivery = result;
            }
            this.setUpdatingDelivery('');
        }).catch(() => this.setUpdatingDelivery(''))
    }

    @action search = async (text: string) => {
        try {
            this.disposeDetail();
            this.searchText = text;
            this.resetProducts([]);
            this.setCurrentPage(0)

            this.load(this.shopId);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action toggleUngrouped = async () => {
        try {
            this.disposeDetail();
            this.resetProducts([]);
            this.setCurrentPage(0)
            this.setUngroupdOnly(!this.ungroupedOnly);

            this.load(this.shopId);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action toggleActive = async () => {
        try {
            this.disposeDetail();
            this.resetProducts([]);
            this.setCurrentPage(0)
            this.setActiveOnly(!this.activeOnly);

            this.load(this.shopId);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.searchText = ""
        this.setShopId(undefined);
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetProducts([]);
        this.setHasMore(false);
        this.disposeDetail();
    }

    @action disposeDetail = () => {
        this.setLoading(false);
        this.setEditingId(undefined)
        this.setEditingItem(undefined)
    }

}