import React from 'react'
import { Header, Image } from 'semantic-ui-react'

interface IProps {
    header?: any,
    subheader?: any
    subheader2?: any
}

export const NoResults: React.FC<IProps> = ({ header, subheader, subheader2 }) => {

    return (
        <div className='no-results'>
            <Image
                centered
                src={`${process.env.PUBLIC_URL}/confusedTrolley.svg`}
            />
            <Header size='large' content={header ?? "No Products Found"} />
            <Header size='medium' content={subheader ?? "Your search did not match any products."} />
            <Header size='medium' content={subheader2 ?? "Please try again."} />
        </div>
    )
}
