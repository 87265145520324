import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { getNetDetail, getVatDetail, IReceipt, sumNetDiscOrNet, sumVatDiscOrVat } from "../../../interfaces/report";
import { currencyFormat } from "../../products/functions/productHelper";
import { format } from "date-fns";
import { PaymentOptions } from "../../../options/PaymentOptions";

class ReceiptsReport {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable searchText?: string = undefined;
    @observable dateFrom?: number = undefined;
    @observable dateTo?: number = undefined;
    @observable downloading: boolean = false;
    @observable hasMore: boolean = false;
    @observable items: IReceipt[] = []

    @action setLoading = (value: boolean) => this.loading = value;
    @action setSearchText = (value?: string) => this.searchText = value;
    @action setDateFrom = (value?: number) => this.dateFrom = value;
    @action setDateTo = (value?: number) => this.dateTo = value;
    @action setDownloading = (value: boolean) => this.downloading = value;
    @action resetItems = (value: IReceipt[]) => this.items = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setItems = (value: IReceipt[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action search = async (searchText?: string) => {
        try {
            this.resetItems([]);
            this.setCurrentPage(0)
            this.setSearchText(searchText)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Reports.Receipts.get(this.searchText, this.dateFrom, this.dateTo, this.currentPage, 0);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action exportDataForCsv = async () => {
        this.setDownloading(true);
        try {
            var csvData: any[] = [];
            var today = new Date();

            // TODO: this will not work to another country
            var company = await agent.Company.get(129);
            // csv push dates of the report
            csvData.push([`Generated by`, company.name])
            csvData.push([`Title`, "Receipts Report"])
            csvData.push([`Address `, `${company.addressLine1}, ${company.addressLine2}, ${company.country}`])
            csvData.push([`VAT`, company.vatNumber])
            csvData.push([`EXO`, company.exoNumber])
            csvData.push([`BCRS`, company.bcrsNumber])
            csvData.push([`Generated On`, format(today, "yyyy/MM/dd HH:mm:ss")])
            csvData.push([])
            csvData.push([])
            csvData.push(["Filters :"])
            csvData.push(["", `Date From`, this.dateFrom ? format(this.dateFrom * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push(["", `Date To`, this.dateTo ? format(this.dateTo * 1000, "yyyy/MM/dd HH:mm:ss") : "--"])
            csvData.push([])
            csvData.push([])

            csvData.push(["Receipt No", "Paid By", "Date", "Customer", "Exempt 0%", "", "BCRS", "", "Reduced 5%", "", "Full 18%", "", "Totals", "", "Delivery Cost (inc VAT)", "Total"]);
            csvData.push(["", "", "", "Net", "Vat", "Net", "Vat", "Net", "Vat", "Net", "Vat", "Net", "Vat", ""]);

            var response = await agent.Reports.Receipts.get(this.searchText, this.dateFrom, this.dateTo, 0, 1);


            (response.Items as IReceipt[]).forEach((x) => {
                csvData.push([
                    x.receiptNo ?? "-",
                    PaymentOptions.filter(f => f.key === x.paidBy)[0]?.text ?? "-",
                    (x.createdOn ?? 0) > 0 ? format(new Date(x.createdOn! * 1000), "dd/MM/yyyy HH:mm") : "-",
                    x.userId,
                    currencyFormat(getNetDetail(x.vat_rates, "E"), false),
                    currencyFormat(getVatDetail(x.vat_rates, "E"), false),
                    currencyFormat(getNetDetail(x.vat_rates, "M"), false),
                    currencyFormat(getVatDetail(x.vat_rates, "M"), false),
                    currencyFormat(getNetDetail(x.vat_rates, "R"), false),
                    currencyFormat(getVatDetail(x.vat_rates, "R"), false),
                    currencyFormat(getNetDetail(x.vat_rates, "F"), false),
                    currencyFormat(getVatDetail(x.vat_rates, "F"), false),
                    currencyFormat(sumNetDiscOrNet(x.vat_rates), false),
                    currencyFormat(sumVatDiscOrVat(x.vat_rates), false),
                    x.checkoutType === "delivery" ? currencyFormat(x.deliveryFee ?? 0, true) : "",
                    currencyFormat(sumVatDiscOrVat(x.vat_rates) + sumNetDiscOrNet(x.vat_rates), false)
                ])
            });

            var fileName = `${today.getFullYear()}_${today.getMonth()}_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()} `;

            return {
                data: csvData,
                filename: `cheap_trolleyreceipts_${fileName}.csv`,
            };

        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownloading(false);
        }
    };

    @action dispose = () => {
        this.setCurrentPage(0);
        this.setDateFrom(undefined);
        this.setDateTo(undefined);
        this.setSearchText(undefined);
        this.setHasMore(false);
        this.setDownloading(false);
        this.setItems([]);
    }
}


export const ReceiptsReportStore = new ReceiptsReport();