import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Image } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';

const WelcomeScreen = () => {
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const { sendMessage } = context.chatUIStore;
    const { isChefEnabled } = context.userStore;

    return (
        <div className="welcome-card-container fxDisplay fxDirCol">
            <Image src="/logo-chef-hat.svg" style={isMobile ?
                { maxWidth: "75%", paddingTop: "35px" } : { maxWidth: "50%" }} />

            {isChefEnabled && <div className={`fxDisplay ${isMobile ? "fxDirCol" : ""}`}
                style={{ alignItems: "center" }}>
                <div className="welcome-card clickable" onClick={() => {
                    sendMessage("I am craving some pancakes");
                }}>
                    <Image src="/pancakes.png" alt="icon" />
                    <p>I'm craving some pancakes!</p>
                </div>
                <div className="welcome-card clickable" onClick={() => {
                    sendMessage("What’s the cheapest pasta you have in stock right now?");
                }}>
                    <Image src="/pasta.png" alt="icon" />
                    <p>What’s the cheapest pasta you have in stock right now?</p>
                </div>
                <div className="welcome-card clickable" onClick={() => {
                    sendMessage("Can you suggest some stain removers for my clothes?");
                }}>
                    <Image src="/product.png" alt="icon" />
                    <p>Can you suggest some stain removers for my clothes?</p>
                </div>
            </div>}
        </div>
    );

}

export default observer(WelcomeScreen)