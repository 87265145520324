import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { isMobile } from 'react-device-detect';
import { Menu, Dropdown, DropdownProps, Button, Divider, Table, Icon, Segment, Header } from 'semantic-ui-react';
import { Countries } from '../../../options/Countries';
import { DateTimePicker } from "react-widgets";
import InfiniteScroll from 'react-infinite-scroll-component'
import { currencyFormat } from '../../products/functions/productHelper';
import CsvExport from '../../../common/csvExport';
import { ItemizedSalesReportStore } from '../functions/ItemizedSalesReportStore';
import { NoResults } from '../../../common/NoResults';


const ItemizedSalesReport = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isAdmin } = context.userStore;

    const {
        oneTimeLoad,
        setDateFrom,
        setDateTo,
        setCountryId,
        setSelectedLocations,
        locations,
        search,
        dateFrom,
        items,
        hasMore,
        load,
        exportDataForCsv,
        downloading,
        byClient,
        setByClient,
        setfrDateFrom,
        setfrDateTo,
        frdateFrom,
        get_agreement_value,
        moreColumns,
        setMoreColumns,
        dispose } = ItemizedSalesReportStore;

    const [timer, setTimer] = React.useState(0);
    const [valueFrom, setValueFrom] = React.useState<Date | null | undefined>();
    const [valueTo, setValueTo] = React.useState<Date | null | undefined>();
    const [frvalueFrom, setfrValueFrom] = React.useState<Date | null | undefined>();
    const [frvalueTo, setfrValueTo] = React.useState<Date | null | undefined>();

    useEffect(() => {
        if (!isAdmin) {
            navigate("/")
            return;
        }
        oneTimeLoad();
        return () => {
            dispose()
        }
    }, [isAdmin, navigate, dispose, oneTimeLoad])

    return (
        <div className="simply-paddedPage" >
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "0 0 5px 0" }}
            >
                <Segment style={{ marginBottom: "0px" }}>
                    <Header style={{ marginBottom: "7px" }} size="tiny">Country</Header>
                    <Menu secondary>
                        <Menu.Item className={"no-padding"}>
                            <Dropdown
                                placeholder={"Select Country.."}
                                closeOnBlur
                                selection
                                clearable
                                search
                                onChange={(ev, data: DropdownProps) => {
                                    setCountryId(data?.value as number)
                                }}
                                options={Countries.filter((f) => f.active === true)
                                    ?.slice()
                                    .sort((a, b) => (a.text < b.text ? -1 : 1))
                                }
                            />
                        </Menu.Item>
                    </Menu>
                </Segment>
                <Segment style={{ marginLeft: "4px", marginTop: "0px", marginBottom: "0px" }}>
                    <Header style={{ marginBottom: "7px" }} size="tiny">Order Created</Header>
                    <Menu secondary>
                        <Menu.Item className={"no-padding"}>
                            <DateTimePicker
                                value={valueFrom}
                                placeholder={"Date From"}
                                onChange={(date: Date | null | undefined, rawValue: string) => {
                                    // date?.setUTCHours(0, 0, 0, 1);
                                    var dateNum = date?.getTime() ?? undefined;
                                    setValueFrom(date);
                                    setDateFrom((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                    if (!search) return;
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }

                                    setTimer(
                                        setTimeout(
                                            (callback: () => void, text: string) => {
                                                callback();
                                                clearTimeout(timer);
                                            },
                                            1000,
                                            search
                                        )
                                    );
                                }}
                                includeTime={true}
                                timePrecision={'minutes'}
                                max={new Date()}
                            />
                        </Menu.Item>
                        <Menu.Item className={"no-padding"}>
                            <DateTimePicker
                                placeholder={"Date To"}
                                value={valueTo}
                                onChange={(date: Date | null | undefined, rawValue: string) => {
                                    // date?.setUTCHours(23, 59, 59, 999);
                                    setValueTo(date);
                                    var dateNum = date?.getTime() ?? undefined;
                                    setDateTo((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                    if (!search) return;
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }

                                    setTimer(
                                        setTimeout(
                                            (callback: () => void, text: string) => {
                                                callback();
                                                clearTimeout(timer);
                                            },
                                            500,
                                            search
                                        )
                                    );
                                }}
                                includeTime={true}
                                timePrecision={'minutes'}
                                max={new Date()}
                                min={dateFrom ? new Date(dateFrom * 1000) : undefined}
                            />
                        </Menu.Item>
                    </Menu>
                </Segment>
                <Segment style={{ marginLeft: "4px", marginTop: "0px", marginBottom: "0px" }}>
                    <Header style={{ marginBottom: "7px" }} size="tiny">Receipt Printed</Header>
                    <Menu secondary>
                        <Menu.Item className={"no-padding"}>
                            <DateTimePicker
                                value={frvalueFrom}
                                placeholder={"Date From"}
                                onChange={(date: Date | null | undefined, rawValue: string) => {
                                    // date?.setUTCHours(0, 0, 0, 1);
                                    var dateNum = date?.getTime() ?? undefined;
                                    setfrValueFrom(date);
                                    setfrDateFrom((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                    if (!search) return;
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }

                                    setTimer(
                                        setTimeout(
                                            (callback: () => void, text: string) => {
                                                callback();
                                                clearTimeout(timer);
                                            },
                                            1000,
                                            search
                                        )
                                    );
                                }}
                                includeTime={true}
                                timePrecision={'minutes'}
                                max={new Date()}
                            />
                        </Menu.Item>
                        <Menu.Item className={"no-padding"}>
                            <DateTimePicker
                                placeholder={"Date To"}
                                value={frvalueTo}
                                onChange={(date: Date | null | undefined, rawValue: string) => {
                                    // date?.setUTCHours(23, 59, 59, 999);
                                    setfrValueTo(date);
                                    var dateNum = date?.getTime() ?? undefined;
                                    setfrDateTo((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                    if (!search) return;
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }

                                    setTimer(
                                        setTimeout(
                                            (callback: () => void, text: string) => {
                                                callback();
                                                clearTimeout(timer);
                                            },
                                            500,
                                            search
                                        )
                                    );
                                }}
                                includeTime={true}
                                timePrecision={'minutes'}
                                max={new Date()}
                                min={frdateFrom ? new Date(frdateFrom * 1000) : undefined}
                            />
                        </Menu.Item>
                    </Menu>
                </Segment>
                <Segment style={{ marginLeft: "4px", marginTop: "0px", marginBottom: "0px" }}>
                    <Header style={{ marginBottom: "7px" }} size="tiny">Provider</Header>
                    <Menu secondary>
                        <Menu.Item className={"no-padding"}>
                            <Dropdown
                                placeholder={"Select Provider.."}
                                closeOnBlur
                                multiple
                                selection
                                clearable
                                search
                                onChange={(ev, data: DropdownProps) => {
                                    setSelectedLocations(data?.value as string[]);
                                    search();
                                }}
                                options={locations
                                    ?.slice()
                                    .sort((a, b) => (a.title < b.title ? -1 : 1))
                                    .map(x => ({
                                        key: x.id,
                                        value: x.id,
                                        text: x.title
                                    }))
                                }
                            />
                        </Menu.Item>
                    </Menu>
                </Segment>
                <Menu.Item className={!isMobile ? "no-padding fxDirCol" : ""}
                    style={{ flex: isMobile ? 1 : "" }}>
                    <Button
                        color={!byClient ? "blue" : "green"}
                        basic={!byClient}
                        content={"By Client"}
                        onClick={() => {
                            setByClient(!byClient);
                            search();
                        }}
                    />
                    <Button
                        style={{ marginTop: "10px" }}
                        color={!moreColumns ? "blue" : "green"}
                        content={"Details"}
                        basic={!moreColumns}
                        className="no-margin"
                        onClick={() => {
                            setMoreColumns(!moreColumns);
                        }}
                    />
                </Menu.Item>

                <Menu.Item>
                    <Button
                        color="green"
                        icon='refresh'
                        basic
                        className="no-margin"
                        onClick={() => search()}
                    />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item icon title="Export" style={{ padding: "0px" }}>
                        <CsvExport
                            onClick={exportDataForCsv}
                            content={
                                <Fragment>
                                    <Icon name="file excel outline" /> Export
                                </Fragment>
                            }
                            disabled={items.length === 0 || downloading}
                            loading={downloading}
                        />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider />
            <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={items.length}
                next={() => load()}
                hasMore={hasMore}
                loader={items ? <h4>Loading...</h4> : <div />}
                endMessage={
                    <div style={{ textAlign: "center" }}>
                        {items.length > 0 ? (
                            <b></b>
                        ) : (
                            <NoResults />
                        )}
                    </div>
                }
            >
                <Table basic="very" selectable celled compact>
                    <Table.Header>
                        <Table.Row>
                            {byClient && <Table.HeaderCell>Client</Table.HeaderCell>}
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Quantity Sold</Table.HeaderCell>
                            <Table.HeaderCell>Cost ex. VAT</Table.HeaderCell>
                            {moreColumns && <Table.HeaderCell>Cost VAT</Table.HeaderCell>}
                            <Table.HeaderCell>Sold ex. VAT</Table.HeaderCell>
                            <Table.HeaderCell>Sold VAT</Table.HeaderCell>
                            {moreColumns && <Table.HeaderCell>Sold Total</Table.HeaderCell>}
                            <Table.HeaderCell>Profit</Table.HeaderCell>
                            {byClient && <Table.HeaderCell>% Profit</Table.HeaderCell>}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items.map((x) => (
                            <Table.Row key={x.id}>
                                {byClient && <Table.Cell>{x.account}</Table.Cell>}
                                <Table.Cell>{x.title}</Table.Cell>
                                <Table.Cell textAlign='right'>{currencyFormat(x.quantity, false)}</Table.Cell>
                                <Table.Cell textAlign='right'>{currencyFormat(x.costNet, true)}</Table.Cell>
                                {moreColumns && <Table.Cell textAlign='right'>{currencyFormat(x.costVat, true)}</Table.Cell>}
                                <Table.Cell textAlign='right'>{currencyFormat(x.salesNet, true)}</Table.Cell>
                                <Table.Cell textAlign='right'>{currencyFormat(x.vat, true)}</Table.Cell>
                                {moreColumns && <Table.Cell textAlign='right'>{currencyFormat(x.salesNet + x.vat, true)}</Table.Cell>}
                                <Table.Cell textAlign='right'>{currencyFormat(x.profit, true)}</Table.Cell>
                                {byClient && <Table.Cell textAlign='right'>{`${currencyFormat((x.profit / get_agreement_value(x)) * 100, false)}%`}</Table.Cell>}
                            </Table.Row>
                        ))}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            {byClient && <Table.HeaderCell></Table.HeaderCell>}
                            <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>Totals</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.quantity, 0), false)}</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.costNet, 0), true)}</Table.HeaderCell>
                            {moreColumns && <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.costVat, 0), true)}</Table.HeaderCell>}
                            <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.salesNet, 0), true)}</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.vat, 0), true)}</Table.HeaderCell>
                            {moreColumns && <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.salesNet + b.vat, 0), true)}</Table.HeaderCell>}
                            <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.profit, 0), true)}</Table.HeaderCell>
                            {byClient && <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{`${currencyFormat((items?.reduce((a, b) => a + b.profit, 0) / items?.reduce((a, b) => a + get_agreement_value(b), 0)) * 100, false)}%`}</Table.HeaderCell>}
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </InfiniteScroll>

        </div>
    )
}

export default observer(ItemizedSalesReport)