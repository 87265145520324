import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useContext, useEffect } from 'react'
import { RootStoreContext } from '../../stores/RootStoreContext'
import { Button, Dimmer, Divider, Icon, Input, Loader, Menu, Segment, Sidebar, Table } from 'semantic-ui-react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router'
import BrandTableRow from './components/BrandTableRow'
import BrandDetailWidget from './components/BrandDetailWidget'
import { NoResults } from '../../common/NoResults'

const Brands = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isMobile } = context.deviceStore;
    const { isAdmin } = context.userStore;
    const { openModal, closeModal } = context.modalStore;
    const { loading,
        brands,
        load,
        hasMore,
        dispose,
        updateComplete,
        search } =
        context.brandAdminStore;

    const [timer, setTimer] = React.useState(0);
    const [editingId, setEditingId] = React.useState<string | undefined>(undefined);


    useEffect(() => {
        if (!isAdmin) {
            navigate("/")
            return;
        }
        load();
        return () => {
            dispose();
        }
    }, [load, dispose, isAdmin, navigate])

    return <div className="simply-paddedPage">
        <Menu
            secondary
            size={isMobile ? "massive" : "small"}
            style={{ margin: "5px 0" }}
        >
            <Menu.Item
                className={!isMobile ? "no-padding" : ""}
                style={{ flex: isMobile ? 1 : "" }}
            >
                <Input
                    fluid={isMobile}
                    className='ct-green-placeholder'
                    transparent={isMobile}
                    size={isMobile ? "large" : "small"}
                    icon={!isMobile ? <Icon name="search" className="ct-green-text" /> : undefined}
                    placeholder={"Search..."}
                    input={<input className={isMobile ? "" : "ctGreenPlaceholder"} />}
                    onChange={(event: ChangeEvent, data: any) => {
                        if (!search) return;
                        if (timer != null) {
                            clearTimeout(timer);
                            setTimer(0);
                        }
                        setTimer(
                            setTimeout(
                                (callback: (text: string) => void, text: string) => {
                                    callback(text);
                                    clearTimeout(timer);
                                },
                                500,
                                search,
                                data.value
                            )
                        );
                    }}
                />
            </Menu.Item>
            <Menu.Menu position='right'>
                <Menu.Item>
                    <Button
                        className='ct-green-button'
                        content="New"
                        icon="plus"
                        onClick={() =>
                            openModal(
                                <BrandDetailWidget
                                    closeOnSave={true}
                                    showCloseIcon={false}
                                    close={() => { closeModal() }}
                                />,
                                "mini",
                                "Create new Brand",
                                true,
                                true,
                            )} />
                </Menu.Item>
            </Menu.Menu>
        </Menu>
        <Divider style={isMobile ? { padding: "0", margin: "0" } : undefined} />


        {!loading || brands.length > 0 ? (
            <div>
                <Sidebar
                    as={Segment}
                    animation={'scale down'}
                    direction={'right'}
                    width="very wide"
                    visible={editingId !== undefined}
                >
                    <BrandDetailWidget brandId={editingId}
                        close={() => setEditingId(undefined)}
                        showCloseIcon={true}
                        completedCallback={updateComplete} />
                </Sidebar>
                <InfiniteScroll
                    style={{ overflow: "hidden" }}
                    dataLength={brands.length} //This is important field to render the next data
                    next={() => load()}
                    hasMore={hasMore}
                    loader={loading ? <h4>Loading...</h4> : <div />}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            {brands.length > 0 ? (
                                <b></b>
                            ) : (
                                <NoResults />
                            )}
                        </p>
                    }
                >
                    <Table basic="very" celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Brand</Table.HeaderCell>
                                <Table.HeaderCell>Tags</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {brands.map((x) => (
                                <BrandTableRow brand={x} key={x.id} editingId={editingId} setEditingId={setEditingId} />
                            ))}
                        </Table.Body>
                    </Table>
                </InfiniteScroll>
            </div>
        ) : (
            <Segment
                style={{
                    minHeight: "55vh",
                    boxShadow: "none",
                    border: "none",
                }}
            >
                <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            </Segment>
        )}
    </div >
}

export default observer(Brands);