import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import InfiniteScroll from 'react-infinite-scroll-component'
import { Form as FinalForm, Field } from "react-final-form";
import {
    Image,
    Form,
    Button,
    Icon,
    Tab,
    Table,
} from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { LoadingParagraph } from "../../../components/LoadingParagraph";
import TextInput from "../../../form/TextInput";
import ErrorMessage from "../../../form/ErrorMessage";
import SelectInput from "../../../form/SelectInput";
import { IBrand } from "../../../interfaces/brand";
import React from "react";
import { ButtonTypes } from "../../confirmationModal/functions/ButtonTypes";
import { BrandDetailStore } from "../functions/BrandDetailStore";
import BrandImageSelection from "./BrandImageSelection";
import { NoResults } from "../../../common/NoResults";

const validate = combineValidators({
    title: isRequired(""),
    tags: isRequired(""),
});

interface IProps {
    brandId?: string;
    completedCallback?: (item: IBrand) => void;
    isInModal?: boolean;
    showCloseIcon: boolean;
    closeOnSave?: boolean;
    close: () => void;
}

const BrandDetailWidget: React.FC<IProps> = ({ brandId, completedCallback, close, showCloseIcon, closeOnSave, isInModal = false }) => {
    const context = useContext(RootStoreContext);
    const { openModal, openSecondModal } = context.modalStore;
    const { openConfirmationModal, closeConfirmationalModal } = context.confirmationModalStore;
    const {
        editingItem,
        loadDetail,
        loadingDetail,
        disposeDetail,
        update,
        create,
        loadingProducts,
        hasMoreProducts,
        products,
        loadProducts,
        removeProductBrand
    } = BrandDetailStore;


    useEffect(() => {
        loadDetail(brandId);

        return () => {
            disposeDetail()
        };
    }, [disposeDetail, loadDetail, brandId]);

    return (
        <Fragment>
            {showCloseIcon && <Icon size='large' name='close' onClick={close} />}
            <Tab menu={{ secondary: true, pointing: true }} panes={
                [
                    {
                        menuItem: 'Details',
                        render: () => <Tab.Pane attached={false}>
                            <FinalForm
                                onSubmit={(values: IBrand) =>
                                    (editingItem !== undefined ? update(values, completedCallback) : create(values))
                                        .then(() => {
                                            if (closeOnSave)
                                                close();
                                        })
                                        .catch((error) => ({
                                            [FORM_ERROR]: error,
                                        }))
                                }
                                initialValues={editingItem ?? {}}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    submitError,
                                    dirtySinceLastSubmit,
                                    submitting,
                                    invalid,
                                    pristine,
                                    values,
                                    form,
                                }) => (
                                    <Form onSubmit={handleSubmit} error>
                                        <div
                                            className="fxFixed fxDisplay fxDirCol fxAlignCenter"
                                        >
                                            <div
                                                style={{
                                                    paddingLeft: "6px",
                                                    paddingRight: "6px",
                                                    maxWidth: "250px",
                                                }}
                                            >
                                                <Image
                                                    onClick={() => {
                                                        (isInModal ? openSecondModal : openModal)(<BrandImageSelection form={form} />, "mini", "Select or upload an image", true, false)
                                                    }}
                                                    src={
                                                        values.imageUrl ??
                                                        "https://ad-files-bucket.s3.eu-west-1.amazonaws.com/image-not-found.png"
                                                    }
                                                    onError={({ currentTarget }: any) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src =
                                                            "https://ad-files-bucket.s3.eu-west-1.amazonaws.com/image-not-found.png";
                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div><div><b>Id</b></div>{editingItem?.id}</div>

                                        <div style={showCloseIcon ? { minWidth: "400px", paddingTop: "15px" } : { width: "100%" }}>
                                            <Form.Field style={{ textAlign: "left" }}>
                                                <label style={{ fontSize: "13px" }}>{"Title"}</label>
                                                {loadingDetail ? (
                                                    <LoadingParagraph lines={2} />
                                                ) : (
                                                    <Field
                                                        loading={loadingDetail}
                                                        component={TextInput}
                                                        name="title"
                                                        placeholder={"Title"}
                                                    />
                                                )}
                                            </Form.Field>

                                            <Form.Field style={{ textAlign: "left" }}>
                                                <label style={{ fontSize: "13px" }}>{"Image URL"}</label>
                                                {loadingDetail ? (
                                                    <LoadingParagraph lines={2} />
                                                ) : (
                                                    <Field
                                                        loading={loadingDetail}
                                                        component={TextInput}
                                                        name="imageUrl"
                                                        placeholder={"Image URL"}
                                                    />
                                                )}
                                            </Form.Field>

                                            <Form.Field style={{ textAlign: "left" }}>
                                                <label style={{ fontSize: "13px" }}>{"Tag(s)"}</label>
                                                {loadingDetail ? (
                                                    <LoadingParagraph lines={2} />
                                                ) : (
                                                    <Field
                                                        loading={loadingDetail}
                                                        component={SelectInput}
                                                        allowAdditions={true}
                                                        onAddItem={(item: any) => console.log(item)}
                                                        name="tags"
                                                        placeholder={"Select tags(s)"}
                                                        options={values?.tags?.map(f => ({
                                                            key: f,
                                                            value: f,
                                                            text: f
                                                        }))}
                                                        multiselect={true}
                                                    />
                                                )}
                                            </Form.Field>
                                        </div>

                                        {submitError && !dirtySinceLastSubmit && (
                                            <ErrorMessage error={submitError} />
                                        )}
                                        <div style={{ textAlign: "center" }}>
                                            <Button.Group style={{ paddingTop: "15px" }}>
                                                <Button
                                                    color="blue"
                                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                                    loading={submitting}
                                                    content={"Save"}
                                                />
                                                <Button.Or />
                                                <Button
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        close();
                                                    }}
                                                    content={"Cancel"}
                                                />
                                            </Button.Group>
                                        </div>
                                    </Form>
                                )}
                            />
                        </Tab.Pane>
                    },
                    {
                        menuItem: 'Product(s)',
                        render: () => <Tab.Pane attached={false}>
                            <InfiniteScroll
                                style={{ overflow: "hidden" }}
                                dataLength={products?.length ?? 0} //This is important field to render the next data
                                next={() => loadProducts(brandId)}
                                hasMore={hasMoreProducts}
                                loader={loadingProducts ? <h4>Loading...</h4> : <div />}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        {products?.length > 0 ? (
                                            <b></b>
                                        ) : (
                                            <NoResults />
                                        )}
                                    </p>
                                }
                            >
                                <Table basic="very" celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Product</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {products?.map((x) => (
                                            <Table.Row key={x.id}>
                                                <Table.Cell>
                                                    {x.itemDesc}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Icon
                                                        inverted
                                                        name="close"
                                                        color="red"
                                                        circular
                                                        onClick={() => {
                                                            openConfirmationModal(
                                                                "Confirm product removal",
                                                                "Are you sure you want to remove this product from this brand?",
                                                                () =>
                                                                    removeProductBrand(x.id, editingItem!.id).then(() =>
                                                                        closeConfirmationalModal()
                                                                    ),
                                                                "Cancel",
                                                                "Delete",
                                                                ButtonTypes.None,
                                                                ButtonTypes.Negative
                                                            );
                                                        }}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </InfiniteScroll>
                        </Tab.Pane>
                    }
                ]
            } />
        </Fragment >
    );
};

export default observer(BrandDetailWidget);
