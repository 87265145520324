import { SemanticCOLORS } from "semantic-ui-react"

export type ClientOrderStatusType = "client_unavailable" | "pending_payment" | "pending_processing" | "payment_failed" | "sent_to_market" | "ready_for_pickup" | "ready_for_delivery" | "out_for_delivery" | "completed"

export const OrderStatusOption: Array<{
    value: ClientOrderStatusType,
    key: ClientOrderStatusType,
    text: string,
    color: SemanticCOLORS
    canCancel?: boolean,
}> =
    [
        {
            "value": "pending_payment",
            "key": "pending_payment",
            "text": "Pending Payment",
            "canCancel": true,
            "color": "red"
        },
        {
            "value": "pending_processing",
            "key": "pending_processing",
            "text": "Processing",
            "color": "green"

        },
        {
            "value": "payment_failed",
            "key": "payment_failed",
            "text": "Payment Failed",
            "color": "red"
        },
        {
            "value": "sent_to_market",
            "key": "sent_to_market",
            "text": "In Progress",
            "color": "green"
        },
        {
            "value": "ready_for_pickup",
            "key": "ready_for_pickup",
            "text": "Ready For Pickup",
            "color": "purple"
        },
        {
            "value": "ready_for_delivery",
            "key": "ready_for_delivery",
            "text": "Ready For Delivery",
            "color": "green"
        },
        {
            "value": "out_for_delivery",
            "key": "out_for_delivery",
            "text": "Out For Delivery",
            "color": "purple"
        },
        {
            "value": "client_unavailable",
            "key": "client_unavailable",
            "text": "Attempted Delivery / Client not home",
            "color": "red"
        },
        {
            "value": "completed",
            "key": "completed",
            "text": "Completed",
            "color": "grey"
        },
    ]