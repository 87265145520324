import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button, Icon, Input, Segment } from 'semantic-ui-react';
import agent from '../../../api/agent';

interface IProps {
    chat_id: string;
    msg_id: string;
    initialStatus?: boolean;
}

const ResponseFeedback: React.FC<IProps> = ({ chat_id, msg_id, initialStatus }) => {
    const [status, setStatus] = React.useState<boolean | undefined>(initialStatus);
    const [showDetailsInput, setShowDetailsInput] = React.useState(false);
    const [feedbackDetails, setFeedbackDetails] = React.useState('');

    const processFeedback = (isPositive: boolean) => {
        if (isPositive) {
            agent.Habibai.giveFeedback(chat_id, msg_id, {
                positive: true
            }).then(() => setStatus(true))
        } else {
            setShowDetailsInput(true);
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSubmitFeedback();
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeedbackDetails(event.target.value);
    };

    const handleSubmitFeedback = () => {
        if (feedbackDetails.trim()) {
            agent.Habibai.giveFeedback(chat_id, msg_id, {
                positive: false,
                reason: feedbackDetails
            }).then(() => setStatus(false))
            setShowDetailsInput(false);
            setFeedbackDetails('');
        }
    };

    return (
        <div>
            <span style={{ fontSize: "14px" }}>Let us know how we did: </span>
            {(status ?? true) && <Icon className='clickable' name={status ? 'thumbs up' : 'thumbs up outline'} color='green' onClick={() => processFeedback(true)} />}
            {(status ?? false) === false && <Icon className='clickable' name={status === false ? 'thumbs down' : 'thumbs down outline'} color='red' onClick={() => processFeedback(false)} />}

            {showDetailsInput && (
                <Segment style={{ marginTop: '10px' }}>
                    <label>Kindly provide some feedback to help us improve</label>
                    <Input
                        type="text"
                        placeholder="Please provide more details"
                        value={feedbackDetails}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress} // Listen for Enter key
                        style={{ padding: '5px', width: '300px' }}
                    />
                    <Button basic color='green' onClick={handleSubmitFeedback} style={{ marginLeft: '10px' }}>
                        Send
                    </Button>
                </Segment>
            )}
        </div>
    )
}

export default observer(ResponseFeedback)