import { formatRelative } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Header, Icon, Table, Image } from "semantic-ui-react";
import { getImageUrl } from "../../helpers/productImage";
import { IProductForList } from "../../interfaces/product";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { currencyFormat } from "./functions/productHelper";

interface IProps {
  product: IProductForList;
}

const ProductTableRow: React.FC<IProps> = ({ product }) => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;

  return (
    <Table.Row>
      <Table.Cell>
        <Header as="h4" image>
          <Image
            src={getImageUrl(product)}
            alt="Product Logo"
            rounded
            size="mini"
          />
          <Header.Content>
            {product.itemDesc}
            <Header.Subheader className="fluid">
              {markets.filter((f) => f.type_id === product.marketId)[0].title}
              {product.lastUpdated && (
                <Fragment>
                  <b> - Last Update :</b>{" "}
                  {formatRelative(
                    new Date(product.lastUpdated * 1000),
                    new Date()
                  )}
                </Fragment>
              )}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell>
        <div
          style={{
            marginBottom: "0.5em",
            fontSize: "17px",
            fontWeight: "bolder",
          }}
        >
          <Icon name="euro" />
          {currencyFormat(product.currentPrice, false)}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default observer(ProductTableRow);
