import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { Button, Dimmer, Divider, Header, Icon, Loader, Segment, Table } from 'semantic-ui-react'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import DeliveriesOverviewWidget from '../../deliverySchedule/components/DeliveriesOverviewWidget'

const ProposedHubOrders = () => {
    const context = useContext(RootStoreContext);
    const {
        proposedHubOrders,
        loadingProposedOrders,
        loadProposedOrders,
        locations,
        creatingHubOrders,
        createHubOrders,
        products
    } = context.hubOrdersStore;
    const { openModal } = context.modalStore;
    const { markets } = context.cacheStore;

    useEffect(() => {
        loadProposedOrders();

        return () => { };
    }, [loadProposedOrders]);

    return (
        <Segment basic>
            <div style={{ textAlign: "center" }}>
                <Header textAlign="center" color="blue">
                    <div style={{ position: "absolute", top: "15px", left: "40px" }}>
                        <Icon
                            onClick={loadProposedOrders}
                            className='clickable'
                            title="Reload"
                            name={'refresh'}
                            color='blue'
                            size='large'
                        />
                    </div>
                    <div style={{ position: "absolute", top: "15px", left: "0px" }}>
                        <Icon
                            onClick={() => {
                                openModal(<DeliveriesOverviewWidget />, "huge");
                            }}
                            className='clickable'
                            title="Open Delivery Slots"
                            name={'cog'}
                            color='green'
                            size='large'
                        />
                    </div>
                    <div style={{ position: "absolute", right: "0", top: "15px" }}>
                        <Icon
                            onClick={createHubOrders}
                            className='clickable'
                            title="Share order"
                            name={creatingHubOrders ? 'circle notch' : 'share'}
                            loading={creatingHubOrders}
                            color='orange'
                            size='large'
                        />
                    </div>
                    Proposed Hub Orders
                    {proposedHubOrders && <Header.Subheader>
                        {`${proposedHubOrders.length} order(s) - ${proposedHubOrders.slice().reduce((c, d) => c + d.products.slice().reduce((a, b) => a + b.totalQuantity, 0), 0)} product(s)`}
                    </Header.Subheader>}
                </Header>

            </div>
            <Divider />
            {loadingProposedOrders && (
                <Dimmer active inverted>
                    <Loader inverted content="Loading" />
                </Dimmer>
            )}

            {proposedHubOrders && markets.length > 0 && locations.length > 0 &&
                proposedHubOrders.map((x) => (
                    <Segment key={x.locationId}>
                        <div className="fxDisplay fxFixed fxJustifyBetween">
                            <div style={{ width: "80px", maxWidth: "80px" }} className='fxAlignCenter'>
                                <b>{`${markets.filter(f => f.id === x.accountId)[0].title}`}</b>
                                <p>{locations.filter(f => f.id === x.locationId)[0].title}</p>
                            </div>
                            <div className='fxTextAlignCenter'>
                                <b>{`${x.orderIds.length}`}</b>
                                <p>order(s)</p>
                            </div>
                            <div className='fxTextAlignCenter'>
                                <b>{`${x.products.slice().reduce((a, b) => a + b.totalQuantity, 0)}`}</b>
                                <p>product(s)</p>
                            </div>
                        </div>
                        <Divider />
                        <div style={{ textAlign: "center" }}>
                            <Button
                                content="Details"
                                basic
                                color='blue'
                                onClick={() => {
                                    openModal(
                                        <Table>
                                            <Table.Header>
                                                <Table.HeaderCell>Product</Table.HeaderCell>
                                                <Table.HeaderCell>Quantity</Table.HeaderCell>
                                            </Table.Header>
                                            <Table.Body>
                                                {x.products.map(x => <Table.Row key={x.productId}>
                                                    <Table.Cell>
                                                        {products.filter(f => f.id === x.productId)[0]?.itemDesc ?? "Not Found"}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {x.totalQuantity.toFixed(2)}
                                                    </Table.Cell>
                                                </Table.Row>)}
                                            </Table.Body>
                                        </Table>,
                                        "huge",
                                        `${markets.filter(f => f.id === x.accountId)[0].title} product(s)`);
                                }} />
                        </div>
                    </Segment>
                ))}
        </Segment>
    )
}


export default observer(ProposedHubOrders)