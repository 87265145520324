import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IWalletTransaction } from "../../../interfaces/report";
import { currencyFormat } from "../../products/functions/productHelper";
import { format } from "date-fns";
import { WalletTransactionTypes } from "../../../options/WalletTransactionTypes";

class ClientWalletDetailReport {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable userId?: string = undefined;
    @observable dateFrom?: number = undefined;
    @observable dateTo?: number = undefined;
    @observable downloading: boolean = false;
    @observable hasMore: boolean = false;
    @observable items: IWalletTransaction[] = []

    @action setLoading = (value: boolean) => this.loading = value;
    @action setUserId = (value?: string) => this.userId = value;
    @action setDateFrom = (value?: number) => this.dateFrom = value;
    @action setDateTo = (value?: number) => this.dateTo = value;
    @action setDownloading = (value: boolean) => this.downloading = value;
    @action resetItems = (value: IWalletTransaction[]) => this.items = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setItems = (value: IWalletTransaction[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action search = async (userId?: string) => {
        try {
            this.resetItems([]);
            this.setCurrentPage(0)
            this.setUserId(userId)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Reports.Wallets.Details.get(this.userId, this.dateFrom, this.dateTo, this.currentPage, 0);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action exportDataForCsv = async () => {
        this.setDownloading(true);
        try {
            var csvData: any[] = [];
            var today = new Date();

            // TODO: this will not work to another country
            var company = await agent.Company.get(129);
            // csv push dates of the report
            csvData.push([`Generated by`, company.name])
            csvData.push([`Address `, `${company.addressLine1}, ${company.addressLine2}, ${company.country}`])
            csvData.push([`VAT`, company.vatNumber])
            csvData.push([`EXO`, company.exoNumber])
            csvData.push([`BCRS`, company.bcrsNumber])
            csvData.push([`Generated On`, format(today, "yyyy/MM/dd HH:mm:ss")])
            csvData.push([])
            csvData.push([])

            csvData.push(["Date", "Customer", "Admin", "Type", "Amount"]);

            var response = await agent.Reports.Wallets.Details.get(this.userId, this.dateFrom, this.dateTo, 0, 1);

            (response.Items as IWalletTransaction[]).forEach((x) => {
                csvData.push([
                    format(new Date(x.createdOn * 1000), "dd/MM/yyyy HH:mm"),
                    x.userId,
                    x.adminId,
                    WalletTransactionTypes.filter(f => f.value === x.type)[0].text,
                    currencyFormat(x.amount, true),
                ])
            });

            var fileName = `${today.getFullYear()}_${today.getMonth()}_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()} `;

            return {
                data: csvData,
                filename: `cheap_trolley_wallet_transactions_${fileName}.csv`,
            };

        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownloading(false);
        }
    };

    @action dispose = () => {
        this.setCurrentPage(0);
        this.setDateFrom(undefined);
        this.setDateTo(undefined);
        this.setUserId(undefined);
        this.setHasMore(false);
        this.setDownloading(false);
        this.setItems([]);
    }
}


export const ClientWalletDetailReportStore = new ClientWalletDetailReport();