import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Divider,
  Header,
  Icon,
  Label,
  Popup,
  TextArea,
} from "semantic-ui-react";
import { GetFormattedAddress } from "../../../helpers/addressHelper";
import { IAddress } from "../../../interfaces/user";
import { WidgetMode } from "../../../interfaces/WidgetMode";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import CurrentAddressesWidget from "../../profile/Components/CurrentAddressesWidget";
import { getCheckoutIssue } from "../functions/getCheckOutIssue";
import { PromoCodeChecker } from "./PromoCodeChecker";
import LocationIQ from "../../../components/maps/LocationIQ";
import DeliveryTimeSelector from "./DeliveryTimeSelector";
import { formatRelative } from "date-fns/esm";
import AddressDetailWidget from "../../profile/Components/AddressDetailWidget";
import { requestUserSession } from "../../registration/functions/sessionFnc";

const CheckoutProcessWidget = () => {
  const context = useContext(RootStoreContext);
  const { openModal, closeModal } = context.modalStore;
  const { user } = context.userStore;
  const { isMobile } = context.deviceStore;
  const {
    minimumOrder,
    deliveryFee,
    fees,
    savings,
    promoCode,
    setPromoCode,
    calculateDiscount,
    getShoppingCartCost,
    calculateTotalPrice,
    serviceFee,
    checkoutType,
    deliveryAddress,
    setAddress,
    issues,
    // setAddressLocation,
    orderNotes,
    setOrderNotes,
    availableCredits,
    placeOrder,
    checkOutButtonText,
    checkOutButtonColor,
    checkOutStatus,
    emptyCart,
    paymentErrorCode,
    retryOrder,
    orderDetails,
    getItemsCount,
    deliverySlotId,
    setDeliverySlot,
    orderLocations,
    setCheckoutType,
    refreshDeliveryAddress,
  } = context.shoppingCartStore;

  const [showNotes, setShowNotes] = React.useState(false);

  // const pickupDate = new Date((new Date()).getFullYear(), 6, 30); // Month is 0-indexed, so 6 is July
  return (
    <Fragment>
      <Button.Group widths={2} style={checkoutType === "delivery" ? { marginBottom: "7px" } : {}} >
        <Button
          className={checkoutType === "pickup" ? "ct-green-button" : "ct-green-button-active-toggle"}
          disabled={orderDetails !== undefined}
          onClick={() => setCheckoutType("delivery")}
        >
          Delivery
        </Button>
        <Button
          // basic={checkoutType === "delivery"}
          className={checkoutType === "delivery" ? "ct-green-button" : "ct-green-button-active-toggle"}
          // className="ct-green-button-active-toggle"
          disabled={orderDetails !== undefined}
          onClick={() => setCheckoutType("pickup")}
        >
          Pick up
        </Button>
      </Button.Group>
      {checkoutType === "pickup" && <div style={{ marginTop: "8px", position: "relative" }}>
        <div className="overlay-color" style={{ width: "100%", fontSize: "19px", fontWeight: "400", paddingLeft: "4px", textAlign: "left" }}>
          {"Cheap Trolley Hub"}
        </div>

        <LocationIQ allowMove={false} point={{
          lat: 35.88974705161783,
          lng: 14.465657100486624
        }} height="200px" updateCenter={(lng, lat) => { }} />
        <div style={{ position: "absolute", bottom: "0" }}>
          <div className="clickable" style={{ textAlign: "left", textDecorationLine: "underline" }} onClick={() => { window.open("https://www.google.com/maps/place/Connect+Services+Ltd/@35.8897975,14.4630538,17z/data=!3m1!4b1!4m6!3m5!1s0x130e51473b5e2ae7:0xf64016b0e07db6d4!8m2!3d35.8897932!4d14.4656341!16s%2Fg%2F11pwzzf7b4?entry=ttu", "_blank") }}>
            Connect Services, Triq San Gwakkin, Birkirkara CBD 4030 - Tap for directions
          </div>
        </div>
      </div>}
      {deliveryAddress && checkoutType === "delivery" && (
        <div>
          {GetFormattedAddress(deliveryAddress, [], ",")}
        </div>
        // <div style={{ position: "relative" }}>
        //   <div className="overlay-color" style={{ width: "100%", fontSize: "19px", fontWeight: "400", paddingLeft: "4px", textAlign: "left" }}>
        //     {deliveryAddress.title ?? ""}
        //   </div>

        //   <LocationIQ point={deliveryAddress.geolocation} height="200px" updateCenter={(lng, lat) => {
        //     setAddressLocation(lat, lng);
        //   }} />
        //   <div style={{ position: "absolute", bottom: "0" }}>
        //     {GetFormattedAddress(deliveryAddress, [], ",")}
        //   </div>
        // </div>
      )}
      {!deliveryAddress && checkoutType === "delivery" && (
        <Button
          disabled={!user}
          style={{ marginTop: "8px", width: "100%" }}
          content="Select the delivery address"
          className="ct-green-button"
          onClick={() => {
            openModal(
              <div style={{ textAlign: "center" }}>
                <CurrentAddressesWidget
                  userId={user!.id}
                  mode={WidgetMode.Checkout}
                  onAddressSelected={(address: IAddress) => {
                    setAddress(address);
                    closeModal();
                  }}
                />
              </div>,
              "small",
              "Select your preferred address",
              true,
              false
            );
          }}
        />
      )}
      {deliveryAddress && checkoutType === "delivery" && (
        <Button
          style={{ marginTop: "8px", width: "100%" }}
          content="Change delivery address"
          disabled={orderDetails !== undefined}
          className="button-as-link ct-green-text"
          onClick={() => {
            setAddress(undefined);

            openModal(
              <div style={{ textAlign: "center" }}>
                <CurrentAddressesWidget
                  userId={user!.id}
                  mode={WidgetMode.Checkout}
                  onAddressSelected={(address: IAddress) => {
                    setAddress(address);
                    closeModal();
                  }}
                />
              </div>,
              "small",
              "Select your preferred address",
              true,
              false
            );
          }}
        />
      )}

      <Divider />
      <div className="fxDisplay fxFixed fxJustifyBetween" style={{ marginBottom: "4px" }}>
        <span className="fxStretched">Any notes for our team?</span>
        <Icon
          disabled={!user}
          name={showNotes ? "remove" : "pencil"}
          color={showNotes ? "red" : undefined}
          className={showNotes ? "red" : "ct-green-text"}
          onClick={() => setShowNotes(!showNotes)} />
      </div>
      {showNotes && <TextArea
        className="stylish-textarea"
        rows={4}
        disabled={orderDetails !== undefined}
        style={{ width: "100%" }}
        value={orderNotes}
        placeholder="Kindly use this space to write any notes for our team. e.g. if you place this order for someone on their behalf, special instructions for our delivery team etc"
        onChange={(e, d) => setOrderNotes((d.value ?? "") as string)}
      />}
      <Divider />
      {!orderDetails && !promoCode && (
        <PromoCodeChecker
          callbackOnSuccess={(promo) => setPromoCode(promo)}
        />
      )}
      {promoCode && (
        <div style={{ textAlign: "left" }}>
          <b>Promo code: </b>
          <Label color="teal" tag style={{ marginLeft: "20px" }}>
            {promoCode.code}
          </Label>
        </div>
      )}
      <Divider />
      <div className="fxDisplay fxFixed checkout-totals-box">
        <div className="fxFixed fxStretch fxDisplay">{`Subtotal - ${getItemsCount} item(s)`}</div>
        <div>{`${getShoppingCartCost.toFixed(2)} €`}</div>
      </div>
      {promoCode && (
        <div className="fxDisplay fxFixed checkout-totals-box">
          <div className="fxFixed fxStretch fxDisplay">Discount</div>
          {!promoCode && <div>{`0.00 €`}</div>}
          {promoCode && <div>{`-${calculateDiscount.toFixed(2)} €`}</div>}
        </div>
      )}
      <div className="fxDisplay fxFixed checkout-totals-box">
        <div className="fxFixed fxStretch fxDisplay">
          Service Fee {<Popup
            trigger={
              <Icon style={{ marginLeft: "3px" }} circular size="tiny" name="info" className="ct-green-button-active" />
            }
            content={checkoutType === "delivery" ? <div>Handling fee covers the cost of preparing your order and packaging it securely. It ensures your items are sent out promptly and arrive in perfect condition.</div> :
              <div>Handling fee covers the cost of preparing your order and packaging it securely.</div>}
            on="hover"
          />}
        </div>
        <div>{`${(serviceFee).toFixed(
          2
        )} €`}</div>
      </div>
      {checkoutType === "delivery" && <div className="fxDisplay fxFixed checkout-totals-box">
        <div className="fxFixed fxStretch fxDisplay">
          Delivery Fee {(savings?.discount ?? 0) > 0 &&
            <span style={{ marginLeft: "3px", fontSize: "12px", color: "#086c41" }}>{`(-${(savings?.discount ?? 0).toFixed(2)})`}
            </span>
          }
        </div>
        {(savings?.discount ?? 0) > 0 ? <div>
          <span className="strikethrough">
            {`${(fees.filter(f => f.key === "delivery")[0]?.value ?? 0).toFixed(
              2
            )} €`}
          </span>
          {/* <span>
            {`${(delivery_discount).toFixed(
              2
            )} €`}
          </span> */}
          <span style={{ marginLeft: "4px" }}>
            {`${(deliveryFee).toFixed(
              2
            )} €`}
          </span>
        </div> :
          <div>
            {`${(deliveryFee).toFixed(
              2
            )} €`}
          </div>}
      </div>}
      {availableCredits !== 0 && (
        <div className="fxDisplay fxFixed checkout-totals-box">
          <div className="fxFixed fxStretch fxDisplay">{availableCredits < 0.01 ? "Debt from previous order" : "Credits"}</div>
          <div>{`${availableCredits > 0 ? "-" : ""}${availableCredits.toFixed(2)} €`}</div>
        </div>
      )}
      <Divider />
      <div className="fxDisplay fxFixed checkout-market-box">
        <div className="fxFixed fxStretch fxDisplay">
          <b>Total</b>
        </div>
        <div>{`${calculateTotalPrice.toFixed(2)} €`}</div>
      </div>
      {!orderDetails && deliveryAddress && checkoutType === "delivery" &&
        <Fragment>
          <Divider />
          <DeliveryTimeSelector
            locations={orderLocations}
            address={deliveryAddress}
            setDeliverySlot={setDeliverySlot}
            deliverySlotId={deliverySlotId}
          />
        </Fragment>
      }
      {orderDetails && orderDetails.checkoutType === "delivery" && <div>
        <Divider />
        <b>Scheduled for </b>
        {`${formatRelative(new Date(orderDetails.deliverySlot * 1000), new Date())}`}</div>
      }
      <Divider />
      <div style={{ textAlign: "center" }}>
        {/* {getShoppingCartCost < minimumOrder && (
          <div className="checkout-market-minimum">
            <div>{`Minimum order is ${minimumOrder.toFixed(
              2
            )} €, kindly add ${(minimumOrder - getShoppingCartCost).toFixed(
              2
            )} € to enable checkout`}</div>
          </div>
        )} */}
        {issues.length > 0 && !orderDetails && (
          <div
            style={{ color: "red", textAlign: "left", fontSize: "13px" }}
          >
            {issues.map((item) => (
              <Fragment key={item}>
                {getCheckoutIssue(item, minimumOrder, getShoppingCartCost, paymentErrorCode, () => {
                  openModal(
                    <AddressDetailWidget address={deliveryAddress} />,
                    "mini",
                    "New Address",
                    true,
                    false, () => {
                      refreshDeliveryAddress()
                    }
                  );
                },
                  () => {
                    requestUserSession(
                      isMobile,
                      openModal,
                      closeModal,
                      () => {
                        closeModal();
                      }
                    );
                  })}
                <br />
              </Fragment>
            ))}
          </div>
        )}

        {/* {checkoutType === "pickup" && (new Date() < pickupDate) && <div style={
          {
            color: "white",
            padding: '20px',
            backgroundColor: '#10985d',
            border: '2px solid #086c41',
            borderRadius: '10px',
            margin: '20px 0',
            fontWeight: 'bold',
            textAlign: 'center',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
          }
        }>
          <h3>The next available pick-up date is on Tuesday, July 30th. Thanks for your patience!</h3>
        </div>} */}

        {!orderDetails && <Button
          fluid
          content={checkOutButtonText}
          disabled={issues.length !== 0 || checkOutStatus !== undefined}
          color={checkOutButtonColor === "blue" ? checkOutButtonColor : undefined}
          className={checkOutButtonColor === "blue" ? "ct-green-button-active" : undefined}
          style={{ marginTop: "8px" }}
          onClick={placeOrder}
        />}
        {orderDetails && orderDetails.status !== "pending_processing" && <Button
          fluid
          content={checkOutButtonText}
          color={checkOutButtonColor}
          disabled={orderDetails?.status !== "pending_payment"}
          style={{ marginTop: "8px" }}
          onClick={retryOrder}
        />}
        <p
          className="clickable"
          style={{ marginTop: "6px", fontSize: "13px" }}
          onClick={() => { window.open("https://wa.me/+35627995146", '_blank'); }}
        >
          Do you need help? Chat with us on <span style={{ fontWeight: "bolder", color: "#086c41" }}>WhatsApp</span>!
        </p>
        {orderDetails &&
          orderDetails.status === "pending_processing" &&
          <div className="ct-green-text-dark">
            <Header className="ct-green-text-dark">
              Thank you for your order
              <Header.Content style={{ fontSize: "15px" }}>We have received your order! You can see your order status & history <Link to={"/my-orders"}>here</Link>.</Header.Content>
            </Header>
            You can empty your cart by clicking <span className="linkable redColor" onClick={emptyCart}>here</span>, or you can leave it as it is for your next order!
          </div>
        }
      </div>
    </Fragment>
  );
};

export default observer(CheckoutProcessWidget);
