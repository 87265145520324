import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Input, Icon } from "semantic-ui-react";

interface IProps extends FieldRenderProps<string, any>, FormFieldProps { }

const NumericInput: React.FC<IProps> = ({
  input,
  width,
  type,
  placeholder,
  icon,
  className,
  iconClassName,
  disabled,
  meta: { touched, error },
}) => {
  var inputRef = React.createRef<Input>();

  // function validate(event: any) {
  //   var theEvent = event || window.event;

  //   // Handle paste
  //   if (theEvent.type === "paste") {
  //     key = event.clipboardData.getData("text/plain");
  //   } else {
  //     // Handle key press
  //     var key = theEvent.keyCode || theEvent.which;
  //     key = String.fromCharCode(key);
  //   }
  //   var regex = /[0-9]|\./;
  //   if (!regex.test(key)) {
  //     theEvent.returnValue = false;
  //     if (theEvent.preventDefault) theEvent.preventDefault();
  //   }
  // }

  return (
    <Form.Field error={touched && !!error} type={type} width={width}>
      <Input
        ref={inputRef}
        disabled={disabled}
        {...input}
        placeholder={placeholder}
        icon={icon ? <Icon className={iconClassName} name={icon}></Icon> : undefined}
        className={className}
      // onkeypress={(event: any) => validate(event)}
      />
    </Form.Field>
  );
};

export default NumericInput;
