import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect } from 'react'
import { Button, Divider, Dropdown, Icon, Menu, Table } from 'semantic-ui-react';
import { DateTimePicker } from "react-widgets";
import InfiniteScroll from 'react-infinite-scroll-component'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import CsvExport from '../../../common/csvExport';
import { NoResults } from '../../../common/NoResults';
import { currencyFormat } from '../../products/functions/productHelper';
import { ReferralSalesStore } from '../functions/ReferralSalesStore';

interface IProps {
    shopId: string;
}

const ReferralSalesWidget: React.FC<IProps> = ({ shopId }) => {
    const context = useContext(RootStoreContext)
    const { isMobile } = context.deviceStore;

    const { setDateFrom,
        setDateTo,
        search,
        dateFrom,
        items,
        hasMore,
        load,
        exportDataForCsv,
        downloading,
        setAggregateBy,
        aggregateBy,
        loading,
        dispose,
        setShopId } = ReferralSalesStore;

    const [timer, setTimer] = React.useState(0);
    const [valueFrom, setValueFrom] = React.useState<Date | null | undefined>();
    const [valueTo, setValueTo] = React.useState<Date | null | undefined>();

    useEffect(() => {
        setShopId(shopId);
        return () => {
            dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispose, shopId])


    return (
        <div className="simply-paddedPage">
            <Menu secondary size={isMobile ? "massive" : "small"}>
                <Menu.Item className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}>
                    <Dropdown
                        placeholder='Aggregate by'
                        defaultValue="day"
                        onChange={(e, d) => {
                            if (d.value) {
                                setAggregateBy(d.value as any)
                                search();
                            }
                        }}
                        selection
                        options={[{
                            'key': "day",
                            'value': "day",
                            'text': "Day",
                        }, {
                            'key': "month",
                            'value': "month",
                            'text': "Month",
                        }, {
                            'key': "year",
                            'value': "year",
                            'text': "Year",
                        }]} />
                </Menu.Item>
                <Menu secondary>
                    <Menu.Item className={"no-padding"}>
                        <DateTimePicker
                            value={valueFrom}
                            placeholder={"Date From"}
                            onChange={(date: Date | null | undefined, rawValue: string) => {
                                // date?.setUTCHours(0, 0, 0, 1);
                                var dateNum = date?.getTime() ?? undefined;
                                setValueFrom(date);
                                setDateFrom((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                if (!search) return;
                                if (timer != null) {
                                    clearTimeout(timer);
                                    setTimer(0);
                                }

                                setTimer(
                                    setTimeout(
                                        (callback: () => void, text: string) => {
                                            callback();
                                            clearTimeout(timer);
                                        },
                                        1000,
                                        search
                                    )
                                );
                            }}
                            includeTime={true}
                            timePrecision={'minutes'}
                            max={new Date()}
                        />
                    </Menu.Item>
                    <Menu.Item className={"no-padding"}>
                        <DateTimePicker
                            placeholder={"Date To"}
                            value={valueTo}
                            onChange={(date: Date | null | undefined, rawValue: string) => {
                                // date?.setUTCHours(23, 59, 59, 999);
                                setValueTo(date);
                                var dateNum = date?.getTime() ?? undefined;
                                setDateTo((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                                if (!search) return;
                                if (timer != null) {
                                    clearTimeout(timer);
                                    setTimer(0);
                                }

                                setTimer(
                                    setTimeout(
                                        (callback: () => void, text: string) => {
                                            callback();
                                            clearTimeout(timer);
                                        },
                                        500,
                                        search
                                    )
                                );
                            }}
                            includeTime={true}
                            timePrecision={'minutes'}
                            max={new Date()}
                            min={dateFrom ? new Date(dateFrom * 1000) : undefined}
                        />
                    </Menu.Item>
                </Menu>

                <Menu.Item>
                    <Button
                        color="green"
                        icon='refresh'
                        basic
                        className="no-margin"
                        onClick={() => search()}
                    />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item icon title="Export" style={{ padding: "0px" }}>
                        <CsvExport
                            onClick={exportDataForCsv}
                            content={
                                <Fragment>
                                    <Icon name="file excel outline" /> Export
                                </Fragment>
                            }
                            disabled={items.length === 0 || downloading}
                            loading={downloading}
                        />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider />
            <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={items.length} //This is important field to render the next data
                next={() => load()}
                hasMore={hasMore}
                loader={loading ? <h4>Loading...</h4> : <div />}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        {items.length > 0 ? (
                            <b></b>
                        ) : (
                            <NoResults header={"No Sales Found"} subheader={"Your search did not return any results."} />
                        )}
                    </p>
                }
            >
                <Table basic="very" celled >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{aggregateBy === "day" ? "Day" : (aggregateBy === "month" ? "Month" : "Year")}</Table.HeaderCell>
                            <Table.HeaderCell>Sold ex. VAT</Table.HeaderCell>
                            <Table.HeaderCell>VAT</Table.HeaderCell>
                            <Table.HeaderCell>Total</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items.map((x) => (
                            <Table.Row key={x.id}>
                                <Table.Cell>{x.id}</Table.Cell>
                                <Table.Cell textAlign='right'>{currencyFormat(x.salesNet, true)}</Table.Cell>
                                <Table.Cell textAlign='right'>{currencyFormat(x.vat, true)}</Table.Cell>
                                <Table.Cell textAlign='right'>{currencyFormat(x.salesNet + x.vat, true)}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                    <Table.Footer>
                        <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>Totals</Table.HeaderCell>
                        <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.salesNet, 0), true)}</Table.HeaderCell>
                        <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.vat, 0), true)}</Table.HeaderCell>
                        <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + + b.salesNet + b.vat, 0), true)}</Table.HeaderCell>
                    </Table.Footer>
                </Table>
            </InfiniteScroll>
        </div>
    )
}

export default observer(ReferralSalesWidget)