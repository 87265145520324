import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { IShopOrderForList } from "../../../interfaces/order";
import { ILocationForDropDown } from "../../../interfaces/locations";

export class ShopOrderStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable shopId?: string = undefined;
    @observable hasMore: boolean = false;
    @observable orders: IShopOrderForList[] = []
    @observable statuses: string[] = ['sent_to_market', 'seen', 'in_process', 'ready_for_delivery'];
    @observable displayId?: string = undefined;
    @observable dateFrom?: number = undefined;
    @observable dateTo?: number = undefined;
    @observable locations: ILocationForDropDown[] = [];

    @action setLoading = (value: boolean) => this.loading = value;
    @action setShopId = (value?: string) => this.shopId = value;
    @action setDisplay = (value?: string) => this.displayId = value;
    @action setDateFrom = (value?: number) => this.dateFrom = value;
    @action setDateTo = (value?: number) => this.dateTo = value;
    @action setSelectedStatuses = (value?: string[]) => this.statuses = value ?? [];
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetOrders = (value: IShopOrderForList[]) => this.orders = value;
    @action setLocations = (value: ILocationForDropDown[]) => this.locations = value;
    @action setOrders = (value: IShopOrderForList[]) => {
        var existing = this.orders.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.orders.push(...missing);
    };

    @action getAllLocations = async (shopId: string) => {
        var locations = await agent.Shop.Locations.all(shopId);
        this.setLocations(locations);
    }

    @action search = async () => {
        try {
            this.resetOrders([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action load = async (shopId?: string) => {
        try {
            shopId = shopId ?? this.shopId
            this.setLoading(true);
            this.setShopId(shopId);

            var response = await agent.Shop.Orders.get(this.shopId ?? shopId, this.displayId, this.statuses, this.dateFrom, this.dateTo, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setOrders(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action dispose = () => {
        this.setShopId(undefined);
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetOrders([]);
        this.setHasMore(false);
    }
}