import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IAssociationAnalysis } from "../../../interfaces/association";
import { RootStore } from "../../../stores/RootStoreContext";

export class ProductAnalysisStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable loading: boolean = false;
    @observable currentPage: number = 1;
    @observable hasMore: boolean = false;
    @observable items: IAssociationAnalysis[] = []
    @observable searchText: string = "";

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action setHasMore = (value: boolean) => { this.hasMore = value };
    @action setItems = (value: IAssociationAnalysis[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };
    @action resetItems = () => {
        this.items = [];
    };


    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Associations.Analysis.get(this.searchText, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetItems();
            this.setCurrentPage(1)

            this.load();

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.searchText = ""
        this.items = [];
        this.setLoading(false);
        this.setCurrentPage(1);
    }
}