import React, { Fragment, useContext } from 'react'
import { IShopOrderItemsForList } from '../../../interfaces/order';
import { observer } from 'mobx-react-lite';
import { Header, Popup, Table, Image, Label, Segment, Divider, Form, Button } from 'semantic-ui-react';
import { getImageUrlPlain } from '../../../helpers/productImage';
import { currencyFormat } from '../../products/functions/productHelper';
import { HubOrderProductStatus } from '../../../options/HubOrderProductStatus';
import { Form as FinalForm, Field } from "react-final-form";
import NumericInput from '../../../form/NumericInput'
import ErrorMessage from '../../../form/ErrorMessage'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { FORM_ERROR } from 'final-form';

interface IProps {
    item: IShopOrderItemsForList;
}

const ShopOrderItemTableRow: React.FC<IProps> = ({ item }) => {
    const context = useContext(RootStoreContext)
    const {
        update_order_item_status
    } = context.shopOrderDetailStore;
    const [editing, setEditing] = React.useState<boolean>(false);

    return (
        <Table.Row>
            <Table.Cell>
                <Header as="h4" image>
                    <Popup
                        trigger={
                            <Image
                                src={getImageUrlPlain(item.product.marketId, item.product.imageUrl)}
                                alt="Product Logo"
                                size="mini"
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                }}
                            />
                        }
                        content={
                            <Image
                                src={getImageUrlPlain(item.product.marketId, item.product.imageUrl)}
                                alt="Product Logo"
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                }}
                            />
                        }
                    />

                    <Header.Content>
                        {item.product.discontinued && (
                            <Fragment>
                                <Label content="Discontinued" basic color="grey" />
                                {" - "}
                            </Fragment>
                        )}
                        {item.product.itemDesc}
                    </Header.Content>
                </Header>
            </Table.Cell>
            <Table.Cell>
                {item.shopStatus ? <Label basic color={HubOrderProductStatus.filter(f => f.key === item.shopStatus)[0].color} content={HubOrderProductStatus.filter(f => f.key === item.shopStatus)[0].text} /> : <Label basic color='purple' content="Pending" />}
            </Table.Cell>
            <Table.Cell>
                {item.status ? <Label basic color={HubOrderProductStatus.filter(f => f.key === item.status)[0].color} content={HubOrderProductStatus.filter(f => f.key === item.status)[0].text} /> : <Label basic color='purple' content="Pending" />}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                <Segment >
                    <div className='fxDisplay fxFixed fxJustifyEven' style={{ fontSize: "18px", fontWeight: "600" }}>
                        <div style={{ color: ((item.packedAmount ?? 0) < item.quantity) ? "red" : "green" }}>
                            <div>
                                {item.packedAmount ?? 0}
                            </div>
                            <span style={{ fontWeight: "100", fontSize: "14px" }}>Packed</span>
                        </div>

                        <div>
                            <div>
                                {item.quantity ?? 0}
                            </div>
                            <span style={{ fontWeight: "100", fontSize: "14px" }}>Request</span>
                        </div>
                    </div>
                    {((item.receivedAmount ?? 0) > 0 || (item.missingAmount ?? 0) > 0 || (item.returnedAmount ?? 0) > 0) &&
                        <div style={{ fontWeight: "bolder", fontSize: "15px" }}>
                            <Divider clearing style={{ marginTop: "3px", marginBottom: "3px" }} />
                            {(item.receivedAmount ?? 0) > 0 && <span title="Accepted" style={{ color: "green", marginRight: "5px" }}>(Acc) : {item.receivedAmount}</span>}
                            {(item.missingAmount ?? 0) > 0 && <span title="Missing" style={{ color: "red" }}>(Mis) : {item.missingAmount}</span>}
                            {(item.returnedAmount ?? 0) > 0 && <span title="Returned" style={{ marginLeft: "5px", color: "orangered" }}>(Ret) : {item.returnedAmount}</span>}
                        </div>}
                </Segment>
                {editing ? <FinalForm
                    initialValues={{ 'packedAmount': undefined }}
                    onSubmit={(values: any) =>
                        update_order_item_status(item.product.id, +(values.packedAmount))
                            .then(() => setEditing(false))
                            .catch((error) => ({
                                [FORM_ERROR]: error,
                            }))
                    }
                    render={({
                        handleSubmit,
                        submitting,
                        invalid,
                        dirtySinceLastSubmit,
                        submitError,
                    }) => (
                        <Form onSubmit={handleSubmit} error className="attached fluid">
                            <Form.Field>
                                <label className='ct-green-text' style={{ fontSize: "13px", textAlign: "left" }}>
                                    {"Packed Amount"}
                                </label>
                                <Field
                                    name="packedAmount"
                                    component={NumericInput}
                                    placeholder={"Packed Amount"}
                                />
                            </Form.Field>

                            {submitError && !dirtySinceLastSubmit && (
                                <ErrorMessage error={submitError} />
                            )}

                            <div style={{ marginTop: "7px", textAlign: "center" }}>
                                <Button.Group>
                                    <Button
                                        disabled={invalid && !dirtySinceLastSubmit}
                                        loading={submitting}
                                        className='ct-green-button-active'
                                        content={"Save"}
                                    />
                                    <Button.Or />
                                    <Button
                                        onClick={() => setEditing(false)}
                                        loading={submitting}
                                        content={"Cancel"}
                                    />
                                </Button.Group>
                            </div>
                        </Form>
                    )}
                /> : <Button
                    onClick={() => setEditing(true)}
                    content={"Edit Packed Amount"}
                    className='ct-green-button'
                />}
            </Table.Cell>
            <Table.Cell textAlign='right' style={{
                fontSize: "17px",
            }}>
                {currencyFormat(item.unitNet, true)}
            </Table.Cell>
            <Table.Cell textAlign='right' style={{
                fontSize: "17px",
            }}>
                {currencyFormat(item.unitVat, true)}
            </Table.Cell>
            <Table.Cell textAlign='right' style={{
                fontSize: "17px",
            }}>
                {currencyFormat(item.unitNet + item.unitVat, true)}
            </Table.Cell>
            <Table.Cell textAlign='right' style={{
                fontSize: "17px",
            }}>
                {currencyFormat(item.unitNet * (item.receivedAmount ?? item.packedAmount ?? item.quantity), true)}
            </Table.Cell>
            <Table.Cell textAlign='right' style={{
                fontSize: "17px",
            }}>
                {currencyFormat(item.unitVat * (item.receivedAmount ?? item.packedAmount ?? item.quantity), true)}
            </Table.Cell>
            <Table.Cell textAlign='right' style={{
                fontSize: "17px",
            }}>
                {currencyFormat((item.unitNet + item.unitVat) * (item.receivedAmount ?? item.packedAmount ?? item.quantity), true)}
            </Table.Cell>

        </Table.Row>
    )
}

export default observer(ShopOrderItemTableRow)