import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useContext, useEffect } from 'react'
import { RootStoreContext } from '../../stores/RootStoreContext'
import { Button, Dimmer, Divider, Dropdown, DropdownProps, Grid, Icon, Input, Loader, Menu, Ref, Segment, Sticky, Table } from 'semantic-ui-react'
import InfiniteScroll from 'react-infinite-scroll-component'
import AdminCatalogueTableRow from './components/AdminCatalogueTableRow'
import AdminCatalogueDetailWidget from './components/AdminCatalogueDetailWidget'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import BulkEditCatalogue from './components/BulkEditCatalogue'
import BulkUpdateItemsWidget from './components/BulkUpdateItemsWidget'
import { NoResults } from '../../common/NoResults'

const CatalogueAdmin = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let associationId = searchParams.get('associationId');

    const stickyRef = React.createRef<HTMLElement>();
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isMobile } = context.deviceStore;
    const { isAdmin } = context.userStore;
    const { openModal } = context.modalStore;
    const { loading,
        products,
        load,
        hasMore,
        dispose,
        search,
        editingId,
        oneTimeLoad,
        unavailable,
        toggleUnavailable,
        available,
        toggleAvailable,
        refreshingSearch,
        refreshSearch,
        mode,
        active,
        toggleActive,
        toggleMode,
        refreshData,
        selectedFields,
        setSelectedFields,
        missingFields,
        toggleMissingFields,
        selectedCategories,
        toggleSelectedCategories,
        categories
    } = context.catalogueAdminStore;

    const [timer, setTimer] = React.useState(0);

    useEffect(() => {
        if (!isAdmin) {
            navigate("/")
            return;
        }
        oneTimeLoad();
        load(associationId ?? undefined);
        return () => {
            dispose();
        }
    }, [load, dispose, oneTimeLoad, navigate, isAdmin, associationId])

    return <div className="simply-paddedPage">
        <Menu
            secondary
            size={isMobile ? "massive" : "small"}
            style={{ margin: "5px 0" }}
        >
            <Menu.Item
                className={!isMobile ? "no-padding" : ""}
                style={{ flex: isMobile ? 1 : "" }}
            >
                <Input
                    fluid={isMobile}
                    transparent={isMobile}
                    size={isMobile ? "large" : "small"}
                    icon={!isMobile ? <Icon name="search" color="blue" /> : undefined}
                    placeholder={"Search..."}
                    input={<input className={isMobile ? "" : "bluePlaceholder"} />}
                    onChange={(event: ChangeEvent, data: any) => {
                        if (!search) return;
                        if (timer != null) {
                            clearTimeout(timer);
                            setTimer(0);
                        }
                        setTimer(
                            setTimeout(
                                (callback: (text: string) => void, text: string) => {
                                    callback(text);
                                    clearTimeout(timer);
                                },
                                500,
                                search,
                                data.value
                            )
                        );
                    }}
                />
            </Menu.Item>
            <Menu.Item className={!isMobile ? "no-padding" : ""}
                style={{ flex: isMobile ? 1 : "" }}>
                <Button
                    basic
                    color='green'
                    icon="refresh"
                    onClick={refreshData}
                />
            </Menu.Item>
            <Menu.Item className={!isMobile ? "no-padding" : ""}
                style={{ flex: isMobile ? 1 : "" }}>
                <Button
                    color={!active ? "blue" : "green"}
                    basic={!active}
                    content={"Active"}
                    onClick={() => {
                        toggleActive();
                    }}
                />
            </Menu.Item>
            <Menu.Item className={!isMobile ? "no-padding" : ""}
                style={{ flex: isMobile ? 1 : "" }}>
                <Button
                    color={!available ? "blue" : "green"}
                    basic={!available}
                    content={"Available"}
                    onClick={() => {
                        toggleAvailable();
                    }}
                />
            </Menu.Item>
            <Menu.Item className={!isMobile ? "no-padding" : ""}
                style={{ flex: isMobile ? 1 : "" }}>
                <Button
                    color={!unavailable ? "blue" : "red"}
                    basic={!unavailable}
                    content={"Unavailable"}
                    onClick={() => {
                        toggleUnavailable();
                    }}
                />
            </Menu.Item>
            <Menu.Item className={!isMobile ? "no-padding" : ""}>
                <Dropdown
                    placeholder='Select categories...'
                    value={selectedCategories}
                    closeOnBlur
                    selection
                    multiple
                    clearable
                    search
                    onChange={(ev, data: DropdownProps) => {
                        toggleSelectedCategories((data?.value ?? []) as string[]);
                    }}
                    options={categories?.slice()
                        .sort((a, b) => (a.description < b.description ? -1 : 1))
                        .map((val) => ({
                            key: val.id,
                            value: val.id,
                            text: val.description
                        }))
                    }
                />
            </Menu.Item>
            <Menu.Item className={!isMobile ? "no-padding" : ""}>
                <Dropdown
                    placeholder='Select missing fields...'
                    value={missingFields}
                    closeOnBlur
                    selection
                    multiple
                    clearable
                    search
                    onChange={(ev, data: DropdownProps) => {
                        toggleMissingFields((data?.value ?? []) as string[]);
                    }}
                    options={["imageUrl", "title", "brand_tags", "vat", "dietFlag", "largeContainer", "bottleLimit", "prices",
                        "compartment", "ct_categories", "measurment", "measurementUnit", "extras"]
                        ?.slice()
                        .sort((a, b) => (a < b ? -1 : 1))
                        .map((val) => ({
                            key: val,
                            value: val,
                            text: val
                        }))
                    }
                />
            </Menu.Item>
            <Menu.Item className={!isMobile ? "no-padding" : ""}
                style={{ flex: isMobile ? 1 : "" }}>
                <Button
                    color={"pink"}
                    content={"Bulk Update"}
                    onClick={() => {
                        openModal(<BulkUpdateItemsWidget />, "small", "Bulk Update Query", true, true, () => refreshData());
                    }}
                />
            </Menu.Item>


            {mode === "bulk" && <Menu.Item className={!isMobile ? "no-padding" : ""}
                style={{ flex: isMobile ? 1 : "" }}>
                <Dropdown
                    value={selectedFields}
                    closeOnBlur
                    selection
                    multiple
                    clearable
                    search
                    onChange={(ev, data: DropdownProps) => {
                        setSelectedFields((data?.value ?? []) as string[]);
                    }}
                    options={["imageUrl", "title", "brand_tags", "vat", "dietFlag", "largeContainer", "bottleLimit",
                        "compartment", "ct_categories", "measurment", "measurementUnit", "extras"]
                        ?.slice()
                        .sort((a, b) => (a < b ? -1 : 1))
                        .map((val) => ({
                            key: val,
                            value: val,
                            text: val
                        }))
                    }
                />
            </Menu.Item>}
            <Menu.Menu position='right'>
                <Menu.Item className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}>
                    <Button
                        loading={refreshingSearch}
                        color='brown'
                        icon="refresh"
                        content={"Smart Search"}
                        onClick={() => {
                            refreshSearch().then((result) => {
                                toast.success("Check your email for the results.");
                            });
                        }}
                    />
                </Menu.Item>
                <Menu.Item className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}>
                    <Button
                        basic={mode !== "bulk"}
                        color='brown'
                        icon="list ul"
                        content={"Bulk Mode"}
                        onClick={toggleMode}
                    />
                </Menu.Item>
            </Menu.Menu>
        </Menu>
        <Divider style={isMobile ? { padding: "0", margin: "0" } : undefined} />


        {!loading || products.length > 0 ? (

            mode === "list" ? <Ref innerRef={stickyRef}>
                <Grid columns={16}>
                    <Grid.Row>
                        <Grid.Column width={editingId ? 10 : 16}>
                            < InfiniteScroll style={{ overflow: "hidden" }}
                                dataLength={products.length}
                                next={() => load()}
                                hasMore={hasMore}
                                loader={loading ? <h4>Loading...</h4> : <div />}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        {products.length > 0 ? (
                                            <b></b>
                                        ) : (
                                            <NoResults />
                                        )}
                                    </p>
                                }
                            >
                                <Table basic="very" celled >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Product</Table.HeaderCell>
                                            <Table.HeaderCell>Prices</Table.HeaderCell>
                                            <Table.HeaderCell>Images</Table.HeaderCell>
                                            <Table.HeaderCell>Available</Table.HeaderCell>
                                            <Table.HeaderCell>Verified Group</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {products.map((x) => (
                                            <AdminCatalogueTableRow product={x} key={x.id} />
                                        ))}
                                    </Table.Body>
                                </Table>
                            </InfiniteScroll>
                        </Grid.Column>
                        {!!editingId && <Grid.Column width={editingId ? 6 : undefined}>
                            <Sticky offset={60} context={stickyRef}>
                                <AdminCatalogueDetailWidget editingId={editingId} />
                            </Sticky>
                        </Grid.Column>}
                    </Grid.Row>
                </Grid>
            </Ref> :
                <BulkEditCatalogue />
        ) : (
            <Segment
                style={{
                    minHeight: "55vh",
                    boxShadow: "none",
                    border: "none",
                }}
            >
                <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            </Segment>
        )}
    </div >
}

export default observer(CatalogueAdmin);