import { addDays, addMonths } from "date-fns";
import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IBillingDetails, IPaymentPlan } from "../../../interfaces/billing";
import { IAddress } from "../../../interfaces/user";
import { RootStore } from "../../../stores/RootStoreContext";

export default class BillingDetailsStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable billingDetails?: IBillingDetails = undefined;
    @observable loading: boolean = false;
    @observable activeTab: string = "billingDetails";
    @observable accountId: string = "";
    @observable currentlyEditing: string = "";
    @observable paymentPlans: IPaymentPlan[] = [];
    @observable paymentLink: string | null = null;
    @observable selectedPaymentPlan: IPaymentPlan | null = null;
    @observable renewalStep: number = 0;
    @observable nextExpirationDate: number = 0;
    @observable nextSubscriptionStartDate: number = 0;

    @action setActiveTab = (value: string) => this.activeTab = value;
    @action setAccountId = (value: string) => this.accountId = value;

    @action dispose = () => {
        this.billingDetails = undefined;
        this.loading = false;
        this.currentlyEditing = "";
        this.paymentPlans = [];
        this.paymentLink = null;
        this.selectedPaymentPlan = null;
        this.renewalStep = 0;
        this.nextExpirationDate = 0;
        this.nextSubscriptionStartDate = 0;
    };

    @action popupDispose = () => {
        this.renewalStep = 0;
        this.paymentLink = null;
        this.nextExpirationDate = 0;
        // this.accountBillingStore.accountStore.accountSubscriptionStore.getSubscription(
        //     this.accountBillingStore.accountStore.rootStore.commonStore.principalAccountId
        // );
        this.nextSubscriptionStartDate = 0;
    };

    @action getBillingDetails = async (accountId: string) => {
        this.setAccountId(accountId);
        try {
            this.loading = true;
            const response = await agent.Shop.Billing.get(accountId);

            runInAction(() => {
                this.billingDetails = (Object.keys(response)?.length ?? 0) === 0 ? undefined : response;
                this.loading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    @action updateBillingEmail = async (email: string) => {
        this.billingDetails === undefined ? await agent.Shop.Billing.create(this.accountId, {
            email: email,
        }) : await agent.Shop.Billing.update(this.accountId, {
            email: email,
        });

        runInAction(() => {
            if (this.billingDetails === undefined)
                this.billingDetails = { email: email };
            else this.billingDetails.email = email;

            this.currentlyEditing = "";
        });
    };

    @action updateBillingPhone = async (phone?: string) => {
        this.billingDetails === undefined ? await agent.Shop.Billing.create(this.accountId, {
            phoneNumber: phone,
        }) : await agent.Shop.Billing.update(this.accountId, {
            phoneNumber: phone,
        });

        runInAction(() => {
            if (this.billingDetails === undefined)
                this.billingDetails = { phoneNumber: phone };
            else this.billingDetails.phoneNumber = phone;

            this.currentlyEditing = "";
        });
    };

    @action setCurrentlyEditing = (value: string) => {
        this.currentlyEditing = value;
    };

    @action updateBillingAddress = async (address?: IAddress) => {
        this.billingDetails === undefined ? await agent.Shop.Billing.create(this.accountId, {
            address: address,
        }) : await agent.Shop.Billing.update(this.accountId, {
            address: address,
        });

        runInAction(() => {
            if (this.billingDetails === undefined)
                this.billingDetails = { address: address };
            else this.billingDetails.address = address;

            this.currentlyEditing = "";
        });
    };

    @action getPaymentPlans = async (accountId: string) => {
        try {
            this.loading = true;
            const response = [] as any //await agent.Billing.getPaymentPlans(accountId);

            runInAction(() => {
                this.paymentPlans = response;
                this.setSelectedPaymentPlan(this.paymentPlans.filter((item) => item.isCurrent)[0]);
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    @action setSelectedPaymentPlan = (paymentPlan: IPaymentPlan) => {
        try {
            this.selectedPaymentPlan = paymentPlan;
            // this.getNextSubscriptionRange(
            //     this.accountBillingStore.accountStore.accountSubscriptionStore.subscription?.expirationDate!,
            //     paymentPlan.quantityOfMonths
            // );
        } catch (error) {
            console.log(error);
        }
    };

    @action renewSubscription = async () => {
        try {
            this.loading = true;

            var response = "" // await agent.Billing.renewSubscription(
            //     this.accountBillingStore.accountStore.rootStore.commonStore.principalAccountId,
            //     this.selectedPaymentPlan?.id!
            // );

            runInAction(() => {
                this.paymentLink = response;
                this.loading = false;
                this.renewalStep = 2;
            });

            // await this.getPaymentPlans(this.accountBillingStore.accountStore.rootStore.commonStore.principalAccountId);

            // await this.accountBillingStore.accountStore.accountSubscriptionStore.getSubscription(
            //     this.accountBillingStore.accountStore.rootStore.commonStore.principalAccountId
            // );
        } catch (error) {
            console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    @action getNextSubscriptionRange = (currentExpDate: number, months: number) => {
        const today = new Date();

        if (new Date(currentExpDate * 1000) < today) {
            //subscription expired, start from tomorrow
            const tomorrow = addDays(today, 1);
            tomorrow.setHours(0);

            runInAction(() => {
                this.nextSubscriptionStartDate = tomorrow.getTime() / 1000;
                this.nextExpirationDate = addMonths(today, months).getTime() / 1000;
            });
        } else {
            //subscription not yet expired, start the next day of expiry date
            runInAction(() => {
                this.nextSubscriptionStartDate = addDays(currentExpDate * 1000, 1).getTime() / 1000;
                this.nextExpirationDate = addMonths(currentExpDate * 1000, months).getTime() / 1000;
            });
        }
    };
}
