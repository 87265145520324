import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import {
  Button,
  Dimmer,
  Divider,
  Header,
  Loader,
  Menu,
  Segment,
  Table,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import InfiniteScroll from "react-infinite-scroll-component";
import PromoDetailWidget from "./components/PromoDetailWidget";
import PromoCodeTableRow from "./components/PromoCodeTableRow";
import { NoResults } from "../../common/NoResults";

const PromoCodesWidget = () => {
  const context = useContext(RootStoreContext);
  const { load, dispose, loading, promoCodes, hasMore } =
    context.promoCodesStore;
  const { isMobile } = context.deviceStore;
  const { openModal } = context.modalStore;

  useEffect(() => {
    load();

    return () => {
      dispose();
    };
  }, [load, dispose]);

  return (
    <div className="simply-paddedPage">
      <Menu
        secondary
        size={isMobile ? "massive" : "small"}
        style={{ margin: "5px 0" }}
      >
        <Menu.Item header style={{ paddingLeft: "0px" }}>
          <Header>Promotional Codes</Header>
        </Menu.Item>
        <Menu.Menu position="right">
          <Button
            color="blue"
            basic
            icon={"plus"}
            content={"Add"}
            onClick={() => {
              openModal(
                <PromoDetailWidget />,
                "mini",
                "Create a new promotional code",
                true,
                false
              );
            }}
          />
        </Menu.Menu>
      </Menu>
      <Divider />

      {!loading || promoCodes.length > 0 ? (
        <div>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={promoCodes.length}
            next={() => load()}
            hasMore={hasMore}
            loader={loading ? <h4>Loading...</h4> : <div />}
            endMessage={
              <p style={{ textAlign: "center" }}>
                {promoCodes.length > 0 ? (
                  <b></b>
                ) : (
                  <NoResults />
                )}
              </p>
            }
          >
            <Table basic="very" celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Code</Table.HeaderCell>
                  <Table.HeaderCell>Discount</Table.HeaderCell>
                  <Table.HeaderCell>Hits</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Expires On</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {promoCodes.map((x) => (
                  <PromoCodeTableRow promoCode={x} key={x.id} />
                ))}
              </Table.Body>
            </Table>
          </InfiniteScroll>
        </div>
      ) : (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      )}
    </div>
  );
};

export default observer(PromoCodesWidget);
