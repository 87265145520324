import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { downloadURI } from "../../../helpers/urlImageHelper";
import { IUploadProduct } from "../../../interfaces/uploadInventory";
import { RootStore } from "../../../stores/RootStoreContext";
import { ILocationForDropDown } from "../../../interfaces/locations";

export class UploadWizard {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable step: number = 0;
    @observable locations: ILocationForDropDown[] = [];
    @observable selectedLocation: string = "";
    @observable processing: boolean = false;
    @observable uploading: boolean = false;
    @observable activeTablePage: number = 1;
    @observable editingId?: string = undefined;
    @observable accountId: string = "";
    @observable data: IUploadProduct[] = [];
    @observable errorData: IUploadProduct[] = [];

    @action setStep = (value: number) => this.step = value;
    @action setAccountId = (value: string) => this.accountId = value;
    @action setSelectedLocation = (value: string) => this.selectedLocation = value;
    @action setLocations = (value: ILocationForDropDown[]) => this.locations = value;
    @action setEditingId = (value?: string) => this.editingId = value;
    @action setProcessing = (value: boolean) => this.processing = value;
    @action setUploading = (value: boolean) => this.uploading = value;
    @action setActivePage = (value: number) => this.activeTablePage = value;

    @action dispose = () => {
        this.setStep(0);
        this.setProcessing(false);
        this.setUploading(false);
        this.setEditingId(undefined);
        this.data = [];
        this.errorData = [];
        this.activeTablePage = 1;
        this.added = 0
        this.updated = 0
        this.offers = 0
        this.success = 0
        this.issues = []
    }

    @action getAllLocations = async (shopId: string) => {
        this.setAccountId(shopId);

        var locations = await agent.Shop.Locations.all(shopId);
        this.setLocations(locations);
    }

    @action downloadSample = async () => {
        var url = await agent.AWS.Bucket.getFileUrl('cheap_trolley_inventory_sample.csv');
        downloadURI(url, 'cheap_trolley_inventory_sample.csv');
    }

    @action processText = async (text: string) => {
        try {
            var separateLines = text.split(/\r?\n|\r|\n/g);

            if (separateLines[0].toLowerCase().startsWith('internalid'))
                separateLines = separateLines.splice(1)

            separateLines.forEach(element => {
                var fields = element.split(',');
                if (!fields[0]) return;

                if (fields[2].trim() === "" ||
                    isNaN(parseFloat(fields[5])) ||
                    parseFloat(fields[5]) === 0 ||
                    this.data.findIndex(x => x.internalId === fields[0]) > -1)
                    this.errorData.push({
                        internalId: fields[0],
                        ean: fields[1],
                        title: fields[2],
                        quantity: isNaN(parseInt(fields[3])) ? undefined : parseInt(fields[3]),
                        pricebefore: isNaN(parseFloat(fields[4])) ? undefined : parseFloat(fields[4]),
                        priceafter: isNaN(parseFloat(fields[5])) ? 0 : parseFloat(fields[5]),
                        priceperKg: isNaN(parseFloat(fields[6])) ? 0 : parseFloat(fields[6]),
                        imageUrl: fields[7],
                        vat: isNaN(parseFloat(fields[8])) ? undefined : parseFloat(fields[8]),
                    })
                else
                    this.data.push({
                        internalId: fields[0],
                        ean: fields[1],
                        title: fields[2],
                        quantity: isNaN(parseInt(fields[3])) ? undefined : parseInt(fields[3]),
                        pricebefore: isNaN(parseFloat(fields[4])) ? undefined : parseFloat(fields[4]),
                        priceafter: isNaN(parseFloat(fields[5])) ? 0 : parseFloat(fields[5]),
                        priceperKg: isNaN(parseFloat(fields[6])) ? 0 : parseFloat(fields[6]),
                        imageUrl: fields[7],
                        vat: isNaN(parseFloat(fields[8])) ? undefined : parseFloat(fields[8]),
                    })
            })
        } catch (error) {
            // toast.error("There was an error processing your file. Kindly send us a copy of the file to review any issues.")
        }
        finally {
            this.setProcessing(false)
        }
    }

    @action removeError = (internalId: string) => {
        this.errorData = this.errorData.filter(f => f.internalId !== internalId)
    }

    @action updateTitle = (values: any) => {
        var index = this.errorData.findIndex(f => f.internalId === values.internalId);

        if (index > -1) {
            this.errorData[index].title = values.title;
            this.setEditingId(undefined)
            setTimeout(() => {
                this.reviewErrorData(index)
            }, 1000);
        }

    }

    @action updatePrice = (values: any) => {
        var index = this.errorData.findIndex(f => f.internalId === values.internalId);

        if (index > -1) {
            this.errorData[index].priceafter = isNaN(parseFloat(values.priceAfter)) ? 0 : parseFloat(values.priceAfter);
            this.setEditingId(undefined)
            setTimeout(() => {
                this.reviewErrorData(index)
            }, 1000);
        }
    }

    @action reviewErrorData = (index: number) => {
        if ((this.errorData[index].title?.trim() ?? "") === "" || (this.errorData[index].priceafter ?? 0) === 0)
            return;
        else {
            this.data.push(this.errorData[index]);
            this.errorData = this.errorData.filter(f => f.internalId !== this.errorData[index].internalId)
        }
    }

    @action showFile = async (e: any) => {
        this.setProcessing(true);
        this.setStep(this.step + 1);

        e.preventDefault()

        setTimeout(() => {
            const reader = new FileReader()
            reader.onload = async (e: any) => {
                const text = (e.target.result);
                this.processText(text);
            };
            reader.readAsText(e.target.files[0])
        }, 500);
    }

    @observable added: number = 0
    @observable updated: number = 0
    @observable offers: number = 0
    @observable success: number = 0
    @observable issues: IUploadProduct[] = []

    @action uploadData = async () => {
        this.setUploading(true)

        try {
            const chunkSize = 50;
            var chunks = [];
            for (let i = 0; i < this.data.length; i += chunkSize) {
                const chunk = this.data.slice(i, i + chunkSize);
                chunks.push(chunk);
            }

            for await (const chunk of chunks) {
                await agent.Inventory.upload(this.accountId, chunk, this.selectedLocation).then((result) => {
                    runInAction(() => {
                        this.added += result.added ?? 0;
                        this.updated += result.updated ?? 0;
                        this.offers += result.offersAdded ?? 0;

                        if (result.failed && result.failed.length > 0)
                            this.issues.push(...this.data.filter(f => result.failed.includes(f.internalId)))

                        this.success += chunk.length - (result.failed?.length ?? 0);
                    })
                })

            }
        } catch (error) {
            console.log(error);
        }
        finally {
            this.setUploading(false)
        }

    }

}