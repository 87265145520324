import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IShopForClaim } from "../../../interfaces/shopClaim";
import { RootStore } from "../../../stores/RootStoreContext";

export class ShopClaimStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable loading: boolean = true;
    @observable editingItem?: IShopForClaim = undefined;

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setEditingItem = (value: IShopForClaim) => { this.editingItem = value };

    @action load = async (shopId?: string) => {
        this.setLoading(true)
        try {
            var claim = await agent.Shop.Claims.getMine();

            this.setEditingItem(claim);

        } catch (error) {

        }
        finally {
            this.setLoading(false)
        }
    }

    @action openClaim = async (values: IShopForClaim) => {
        return agent.Shop.Claims.request(values).then((id) => this.setEditingItem({ ...values, id: id, createdOn: (new Date().getTime()) / 1000, status: "pending_approval" }));
    }

    @action dispose = () => {
        this.loading = false;
        this.editingItem = undefined;
    }

}