import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IUserAddressForLocality, IUserConfig, IUserProfile } from "../../../interfaces/user";
import { IInterestRequest } from "../../../interfaces/interest";

class EarlyUserList {

    constructor() {
        makeAutoObservable(this);

    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable count: number = 0;
    @observable hasMore: boolean = false;
    @observable searchText?: string = undefined;
    @observable interests: IInterestRequest[] = []
    @observable users: IUserProfile[] = []
    @observable addresses: IUserAddressForLocality[] = []
    @observable configResults: IUserConfig[] = []
    @observable userDictionary: { [key: string]: IUserProfile } = {}
    @observable userAddressDictionary: { [key: string]: number } = {}
    @observable configDictionary: { [key: string]: boolean } = {}

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setSearchText = (value?: string) => { this.searchText = value };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action setCount = (value: number) => { this.count = value };
    @action setHasMore = (value: boolean) => { this.hasMore = value };
    @action setUserDictionary = (value: { [key: string]: IUserProfile }) => { this.userDictionary = value };
    @action setUserAddressDictionary = (value: { [key: string]: number }) => { this.userAddressDictionary = value };
    @action setConfigDictionary = (value: { [key: string]: boolean }) => { this.configDictionary = value };

    @action setInterests = (value: IInterestRequest[]) => {
        var existing = this.interests.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.interests.push(...missing);
    };
    @action setUsers = (value: IUserProfile[]) => {
        var existing = this.users.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.users.push(...missing);
    };

    @action setAdresses = (value: IUserAddressForLocality[]) => {
        var existing = this.addresses.map(f => f.userId)
        var missing = value.filter(f => existing.indexOf(f.userId) < 0);

        this.addresses.push(...missing);
    };

    @action setConfigResults = (value: IUserConfig[]) => {
        var existing = this.configResults.map(f => f.userId)
        var missing = value.filter(f => existing.indexOf(f.userId) < 0);

        this.configResults.push(...missing);
    };

    @action getCount = () => {
        agent.ExpressInterest.EarlyBird.count().then((count) => this.setCount(count));
    };

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.ExpressInterest.EarlyBird.get_list(this.currentPage);

            var userIds = response.Items.map(x => x.userId);
            var usersResult = await agent.Users.getMany(userIds);
            var usersAddressResult = await agent.Users.Address.getMany(userIds);
            var configResult = await agent.GlobalConfig.isBulkUserDeliveryEnabled(userIds);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setUsers(usersResult);
            this.setAdresses(usersAddressResult);
            this.setConfigResults(configResult);

            const dictionary: { [key: string]: IUserProfile } = usersResult.reduce((acc: { [key: string]: IUserProfile }, obj: IUserProfile) => {
                this.userDictionary[obj.id] = obj;
                return this.userDictionary;
            }, {});

            const addressDictionary: { [key: string]: number } = usersAddressResult.reduce((acc: { [key: string]: number }, obj: IUserAddressForLocality) => {
                this.userAddressDictionary[obj.userId] = obj.localityId;
                return this.userAddressDictionary;
            }, {});

            const dictionaryConfig: { [key: string]: boolean } = configResult.reduce((acc: { [key: string]: boolean }, obj: any) => {
                this.configDictionary[obj.userId] = obj.value;
                return this.configDictionary;
            }, {});

            this.setUserDictionary(dictionary);
            this.setUserAddressDictionary(addressDictionary);
            this.setConfigDictionary(dictionaryConfig);

            this.setInterests(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action dispose = () => {

        this.setLoading(false);
        this.setCurrentPage(0);
        // this.resetUsers([]);
        this.setHasMore(false);
    }
}

export const EarlyUserListStore = new EarlyUserList();