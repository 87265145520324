import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { RootStoreContext } from '../../stores/RootStoreContext';
import { Grid } from 'semantic-ui-react';
import ActiveHubOrders from './components/ActiveHubOrders';
import ProposedHubOrders from './components/ProposedHubOrders';
import ClientOrdersToSend from './components/ClientOrdersToSend';

const HubOrders = () => {
    const context = useContext(RootStoreContext)
    const { dispose } = context.hubOrdersStore


    useEffect(() => {
        return () => {
            dispose();
        };
    }, [dispose]);


    return (
        <Grid
            columns={3}
            centered
            stackable
        >
            <Grid.Row>
                <Grid.Column>
                    <ClientOrdersToSend />
                </Grid.Column>
                <Grid.Column>
                    <ProposedHubOrders />
                </Grid.Column>
                <Grid.Column>
                    <ActiveHubOrders />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default observer(HubOrders)