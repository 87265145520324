import { action, makeObservable, observable, runInAction } from "mobx";
import {
    HubConnection,
    HubConnectionBuilder,
    LogLevel,
    HubConnectionState,
} from "@microsoft/signalr";
import { RootStore } from "./RootStoreContext";

export default class SignalRStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable.ref hubConnection: HubConnection | null = null;
    @observable connectedGroups: string[] = [];
    @observable pendingConnections: string[] = [];
    @observable hubInitialized: boolean = false;

    @action disposeSignalR = async () => {
        try {
            await this.StopHubConnection()
                .then(() => {
                    runInAction(() => {
                        this.connectedGroups = [];
                        this.pendingConnections = [];
                        this.hubConnection = null;
                        this.hubInitialized = false;
                    });
                })
                .catch((error) => console.error(error));
        } catch (error) {
            console.log(error);
        }
    };

    @action CreateHubConnection = async () => {
        if (!this.rootStore.sessionStore.isLoggedIn || this.hubInitialized) return;

        this.hubInitialized = true;
        var signalRUrl = `${process.env.REACT_APP_USER_API?.replace('/api/users', '')}/signalhub`;

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(signalRUrl, {
                accessTokenFactory: () =>
                    this.rootStore.sessionStore.token!.replace("Bearer ", ""),
            })
            .configureLogging(
                window.location.hostname === "localhost"
                    ? LogLevel.Information
                    : LogLevel.Critical
            )
            .withAutomaticReconnect([0, 2000, 10000, 30000, 60000, 120000])
            .build();

        this.hubConnection
            .start()
            .then(async () => await this.setupHubGroups())
            .then(async () => await this.verifyPendingConnectionsEvery30Seconds())
            .catch((error) =>
                console.log("Error establising WebSocket connection", error)
            );

        if (!this.hubConnection) return;

        this.hubConnection.on(
            `CartChange`, async (args: any) => {
                this.rootStore.shoppingListStore.countdown();
            }
        );

        this.hubConnection.on(
            `ShoppingCartChange`, async (args: any) => {
                this.rootStore.shoppingCartStore.cartChanged();
            }
        );

        this.hubConnection.onreconnecting((error) => {
            console.error(
                `Cheap Trolly WebSocket connection lost due to error ["${error}"]. State: ["${this.hubConnection?.state}"]`
            );
        });

        this.hubConnection.onreconnected(async (connectionId?: string) => {
            console.info(
                `Cheap Trolly WebSocket reconnection established! ConnectionId: ["${connectionId}"] State: ["${this.hubConnection?.state}"]`
            );
            var currentConnection = this.connectedGroups.slice();
            await this.removeAllGroups().then(() =>
                currentConnection.forEach((f: string) => this.addToGroup(f))
            );
        });

        this.hubConnection.onclose(async (error) => {
            console.error(
                `Cheap Trolly WebSocket connection closed due to error ["${error}"]. State: ["${this.hubConnection?.state}"]`
            );
            this.hubInitialized = false;
            await this.CreateHubConnection();
        });
    };

    @action setupHubGroups = async () => {
        await this.removeAllGroups();

        await this.addToGroup(`CartChange-${this.rootStore.userStore.user!.id}`)
            .catch((error) =>
                console.error("Error creating Notifications WebSocket group", error)
            );
        await this.addToGroup(`ShoppingCartChange-${this.rootStore.userStore.user!.id}`)
            .catch((error) =>
                console.error("Error creating Notifications WebSocket group", error)
            );

        if (this.rootStore.userStore.isAdmin)
            await this.addToGroup(`HubOrders-admin`)
                .catch((error) =>
                    console.error("Error creating Notifications WebSocket group", error)
                );
    };

    @action addToGroup = async (groupName: string) => {
        if (!this.hubConnection) {
            console.log("hub connection is closed");
            this.pendingConnections.push(groupName);
            return;
        }

        if (this.connectedGroups.filter((f) => f === groupName).length > 0) return;
        runInAction(() => this.connectedGroups.push(groupName));

        await this.hubConnection.invoke("AddToGroup", groupName)
            .catch(() =>
                runInAction(() => {
                    this.connectedGroups = this.connectedGroups.filter(
                        (f) => f !== groupName
                    );
                    this.pendingConnections.push(groupName);
                })
            );
    };

    @action removeFromGroup = async (groupName: string) => {
        if (this.connectedGroups.filter((f) => f === groupName).length === 0)
            return;

        await this.hubConnection
            ?.invoke("RemoveFromGroup", groupName)
            .catch((error) => console.log(error))
            .then(() => {
                runInAction(
                    () =>
                    (this.connectedGroups = this.connectedGroups.filter(
                        (f) => f !== groupName
                    ))
                );
            });
    };

    @action removeAllGroups = async () => {
        if (this.connectedGroups) {
            for await (const groupName of this.connectedGroups) {
                await this.hubConnection?.invoke("RemoveFromGroup", groupName);
            }

            runInAction(() => {
                this.connectedGroups = [];
                this.pendingConnections = [];
            });
        }
    };

    @action StopHubConnection = async () => {
        await this.removeAllGroups();

        if (this.hubConnection?.state === HubConnectionState.Connected)
            await this.hubConnection?.stop();
    };

    @action verifyPendingConnectionsEvery30Seconds = async () => {
        // Every 30 seconds
        let getNextExecution = () => 3e4 - (Date.now() % 3e4);

        let checkPendingConnections = () => {
            this.pendingConnections.forEach(async (group) => {
                await this.addToGroup(group);
            });
            setTimeout(checkPendingConnections, getNextExecution());
        };

        setTimeout(checkPendingConnections, getNextExecution());
    };
}