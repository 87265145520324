import { IProductForList } from "../../../interfaces/product";
import AssociationDetailWidget from "../AssociationDetailWidget";

export function OpenAssociationModal(
  openModal: any,
  products: IProductForList[],
  execOnClose?: () => void,
  execOnSuccess?: (...args: []) => void,
  id?: string
) {
  return openModal(
    <AssociationDetailWidget
      products={products}
      id={id}
      execOnSuccess={execOnSuccess}
    />,
    "large",
    "Create new association",
    true,
    false,
    execOnClose
  );
}
