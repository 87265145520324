import { FormApi } from "final-form";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { IEmailTemplateForCreateUpdate, IEmailTemplateForDetail } from "../../../interfaces/email";
import { ILanguage, getLanguageDefaultJson } from "../../../interfaces/common";
import agent from "../../../api/agent";

class EmailTemplate {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable currentlyEditingItem?: IEmailTemplateForDetail = undefined;

    @observable initialTemplate = {
        subject: getLanguageDefaultJson(),
        design: getLanguageDefaultJson(),
        body: getLanguageDefaultJson(),
    };

    @action load = async (id: string) => {
        this.loading = true;

        try {
            var templ = await agent.Emails.getDetail(id);
            runInAction(() => (this.currentlyEditingItem = templ));

        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    @action saveItem = async (values: IEmailTemplateForCreateUpdate) => {
        if (this.currentlyEditingItem?.id) {
            await agent.Emails.update(this.currentlyEditingItem.id, values);
        } else {
            await agent.Emails.create(values);
        }
    };

    @action getItemDesign = (languageKey: string) => {
        var index =
            this.currentlyEditingItem?.design?.findIndex(
                (f) => f.key === languageKey
            ) ?? -1;

        if (index > -1 && this.currentlyEditingItem?.design) {
            return JSON.parse(this.currentlyEditingItem?.design[index].description);
        } else {
            if (languageKey !== "en") {
                index =
                    this.currentlyEditingItem?.design?.findIndex((f) => f.key === "en") ??
                    -1;
                if (index > -1 && this.currentlyEditingItem?.design)
                    return JSON.parse(
                        this.currentlyEditingItem?.design[index].description
                    );
            }
            return undefined;
        }
    };

    @action getItemHtml = (languageKey: string, values: any) => {
        if (values.body) {
            var index =
                values.body?.findIndex(
                    (f: ILanguage) => f.key === languageKey
                ) ?? -1;
            if (index > -1) return values.body[index].description;
        }

        return undefined;
    };

    @action saveLanguageContent = async (
        languageKey: string,
        htmlExport: any, //HtmlExport,
        form: FormApi,
        values: any,
        push: (...args: any[]) => any
    ) => {
        if (this.currentlyEditingItem && this.currentlyEditingItem.id) {
            if (!values.design) {
                form.change("design", [
                    {
                        key: languageKey,
                        description: JSON.stringify(htmlExport.design),
                    },
                ]);
                form.change("body", [
                    {
                        key: languageKey,
                        description: htmlExport.html,
                    },
                ]);
            } else {
                var index = values.design.findIndex(
                    (f: ILanguage) => f.key === languageKey
                );

                if (index > -1) {
                    form.change(
                        `design[${index}].description`,
                        JSON.stringify(htmlExport.design)
                    );
                    form.change(`body[${index}].description`, htmlExport.html);
                } else {
                    push("design", {
                        key: languageKey,
                        description: JSON.stringify(htmlExport.design),
                    });
                    push(`body`, {
                        key: languageKey,
                        description: htmlExport.html,
                    });
                }
            }
        } else {
            var index2 = values.design.findIndex(
                (f: ILanguage) => f.key === languageKey
            );

            if (index2 > -1) {
                form.change(
                    `design[${index2}].description`,
                    JSON.stringify(htmlExport.design)
                );
                form.change(`body[${index2}].description`, htmlExport.html);
            } else {
                push("design", {
                    key: languageKey,
                    description: JSON.stringify(htmlExport.design),
                });
                push(`body`, {
                    key: languageKey,
                    description: htmlExport.html,
                });
            }
        }

    };

    @action dispose = () => {
        this.loading = false;
        this.currentlyEditingItem = undefined;
        this.initialTemplate = {
            subject: getLanguageDefaultJson(),
            design: getLanguageDefaultJson(),
            body: getLanguageDefaultJson(),
        };
    };
}


export const EmailTemplateStore = new EmailTemplate();