import { makeObservable, action, observable } from "mobx";
import { RootStore } from "../../../stores/RootStoreContext";

export class ModalContainerStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable.shallow modal = {
        open: false,
        body: null,
        header: undefined,
        size: undefined,
        closeIcon: true,
        scrolling: true,
        execOnClose: () => { },
        mountNode: undefined,
        contentStyle: ""
    };

    @observable.shallow secondModal = {
        secondOpen: false,
        secondBody: null,
        secondHeader: undefined,
        secondSize: undefined,
        secondcloseIcon: true,
        secondscrolling: true,
        secondexecOnClose: () => { },
        secondmountNode: undefined,
        secondcontentStyle: ""
    };

    @action openModal = (
        content: any,
        size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen' | undefined | any,
        header?: string | any,
        closeIcon?: boolean,
        scrolling?: boolean,
        execOnClose?: () => void,
        contentStyle?: string | undefined
    ) => {
        this.modal.mountNode = this.rootStore.deviceStore.isMobile
            ? document.getElementById("mainContainer")
            : (undefined as any);
        this.modal.size = size;
        this.modal.header = header;
        this.modal.open = true;
        this.modal.body = content;
        this.modal.closeIcon = closeIcon ?? true;
        this.modal.scrolling = scrolling ?? true;
        this.modal.execOnClose = execOnClose ?? (() => { });
        this.modal.contentStyle = contentStyle ?? ""
    };

    @action openSecondModal = (
        content: any,
        size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen' | undefined | any,
        header?: string | any,
        closeIcon?: boolean,
        scrolling?: boolean,
        execOnClose?: () => void,
        contentStyle?: string | undefined
    ) => {
        this.secondModal.secondSize = size;
        this.secondModal.secondHeader = header;
        this.secondModal.secondOpen = true;
        this.secondModal.secondBody = content;
        this.secondModal.secondcloseIcon = closeIcon ?? true;
        this.secondModal.secondscrolling = scrolling ?? true;
        this.secondModal.secondexecOnClose = execOnClose ?? (() => { });
        this.secondModal.secondcontentStyle = contentStyle ?? ""
    };

    @action closeModal = () => {
        this.modal.execOnClose();
        this.modal.open = false;
        this.modal.body = null;
    };

    @action closeSecondModal = () => {
        this.secondModal.secondexecOnClose();
        this.secondModal.secondOpen = false;
        this.secondModal.secondBody = null;
    };

    @action setModalSize = (size: "small" | "large" | "tiny" | "fullscreen" | "mini" | undefined | any) => {
        this.modal.size = size;
    };

    @action setSecondModalSize = (size: "small" | "large" | "tiny" | "fullscreen" | "mini" | undefined | any) => {
        this.secondModal.secondSize = size;
    };

}