import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import { combineValidators } from "revalidate";
import { Grid, Header } from "semantic-ui-react";
import AddressWidget from "../../../form/AddressWidget";
import { GetFormattedAddress } from "../../../helpers/addressHelper";
import { IAddress } from "../../../interfaces/user";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import ProfileFieldUpdate from "../../profile/Components/ProfileFieldUpdate";

const BillingDetailsAddressWidget = () => {
  const context = useContext(RootStoreContext);
  const { isAdmin, isShopAdmin } = context.userStore;
  const {
    billingDetails,
    currentlyEditing,
    setCurrentlyEditing,
    loading,
    updateBillingAddress,
  } = context.billingDetailsStore;

  return (
    <Grid textAlign="center" className="small-top-padding">
      <Grid.Row columns={1}>
        <Grid.Column width={16}>
          <Grid divided="vertically" textAlign="center">
            <Grid.Row columns={1} centered style={{ paddingBottom: "0px" }}>
              <Grid.Column textAlign="center" style={{ marginBottom: "0px" }}>
                <Header className="blueColor" as="h2" textAlign="left">
                  {"Address"}
                </Header>
              </Grid.Column>
            </Grid.Row>
            {/*Address */}
            <ProfileFieldUpdate
              allowEdit={isAdmin || isShopAdmin}
              fieldName="billingAddress"
              loading={loading}
              groupFieldLabel={"Address"}
              editMode={currentlyEditing === "billingAddress"}
              editClicked={(value: string) => {
                setCurrentlyEditing(value);
              }}
              cancelClicked={() => {
                setCurrentlyEditing("");
              }}
              update={(address: IAddress) =>
                updateBillingAddress(address).catch((error) => ({
                  [FORM_ERROR]: error,
                }))
              }
              validate={combineValidators({
                // address: isRequired("address"),
              })}
              initialValues={billingDetails?.address ?? undefined}
              displayValue={
                billingDetails && billingDetails.address!
                  ? GetFormattedAddress(billingDetails.address!)
                  : "-"
              }
              fields={[
                {
                  display: "",
                  placeholder: "Address",
                  name: "address",
                  showDisplay: false,
                  customComponent: <AddressWidget allowCountry />,
                },
              ]}
            />
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(BillingDetailsAddressWidget);
