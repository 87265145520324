import React, { Fragment, useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import {
  Pagination,
  Table,
  Image,
  Popup,
  Message,
  Button,
  Icon,
  Form,
  Dimmer,
  Loader,
  Segment,
} from "semantic-ui-react";
import { currencyFormat } from "../../products/functions/productHelper";
import { Form as FinalForm, Field } from "react-final-form";
import TextInput from "../../../form/TextInput";
import { combineValidators, isRequired } from "revalidate";
import NumericInput from "../../../form/NumericInput";

const UploadStep2 = () => {
  const context = useContext(RootStoreContext);
  const {
    activeTablePage,
    errorData,
    data,
    setActivePage,
    removeError,
    setEditingId,
    editingId,
    updatePrice,
    updateTitle,
    setStep,
    uploadData,
    processing,
  } = context.uploadWizard;

  return (
    <div>
      {processing && (
        <Segment
          style={{
            minHeight: "55vh",
            boxShadow: "none",
            border: "none",
          }}
        >
          <Dimmer active inverted>
            <Loader inverted>Processing file...</Loader>
          </Dimmer>
        </Segment>
      )}
      {errorData.length > 0 ? (
        <Message
          error
          header="There were some issues found with your data. Please take a look at the table below and fix them before proceeding."
        />
      ) : (
        <Fragment>
          {!processing && (
            <Message
              success
              header={`Your data look perfect! We have found ${data.length} products. Please click proceed to update your inventory!`}
            />
          )}
        </Fragment>
      )}
      {errorData.length > 0 && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Internal Id</Table.HeaderCell>
              <Table.HeaderCell>EAN</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Price Before (€)</Table.HeaderCell>
              <Table.HeaderCell>Price After (€)</Table.HeaderCell>
              <Table.HeaderCell>Price per Kg (€)</Table.HeaderCell>
              <Table.HeaderCell>Image</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {errorData
              .slice((activeTablePage - 1) * 10, activeTablePage * 10)
              .map((x) => (
                <Table.Row key={x.internalId}>
                  <Table.Cell
                    error={
                      data.findIndex((z) => z.internalId === x.internalId) > -1
                    }
                  >
                    {x.internalId}
                  </Table.Cell>
                  <Table.Cell>{x.ean ?? "-"}</Table.Cell>
                  <Table.Cell error={(x.title?.trim() ?? "") === ""}>
                    {editingId === x.internalId ? (
                      (x.title?.trim() ?? "") === "" ? (
                        <FinalForm
                          id="titleForm"
                          validate={combineValidators({
                            title: isRequired(""),
                          })}
                          initialValues={{
                            internalId: x.internalId,
                            title: x.title,
                          }}
                          onSubmit={(values: any) => updateTitle(values)}
                          render={({ handleSubmit }) => (
                            <Form
                              id="titleForm"
                              onSubmit={handleSubmit}
                              error
                              className="attached fluid"
                            >
                              <Form.Field>
                                <Field
                                  name="title"
                                  component={TextInput}
                                  placeholder={"Product title"}
                                />
                              </Form.Field>
                            </Form>
                          )}
                        />
                      ) : (
                        x.title
                      )
                    ) : (
                      x.title
                    )}
                  </Table.Cell>
                  <Table.Cell>{x.quantity ?? 0}</Table.Cell>
                  <Table.Cell>{currencyFormat(x.pricebefore ?? 0)}</Table.Cell>
                  <Table.Cell error={(x.priceafter ?? 0) === 0}>
                    {editingId === x.internalId ? (
                      (x.priceafter ?? 0) === 0 ? (
                        <FinalForm
                          id="priceForm"
                          validate={combineValidators({
                            priceAfter: isRequired(""),
                          })}
                          initialValues={{
                            internalId: x.internalId,
                            priceAfter: x.priceafter,
                          }}
                          onSubmit={(values: any) => updatePrice(values)}
                          render={({ handleSubmit }) => (
                            <Form
                              id="priceForm"
                              onSubmit={handleSubmit}
                              error
                              className="attached fluid"
                            >
                              <Form.Field>
                                <Field
                                  name="priceAfter"
                                  component={NumericInput}
                                  placeholder={"e.g. 2.34"}
                                />
                              </Form.Field>
                            </Form>
                          )}
                        />
                      ) : (
                        currencyFormat(x.priceafter ?? 0)
                      )
                    ) : (
                      currencyFormat(x.priceafter ?? 0)
                    )}
                  </Table.Cell>
                  <Table.Cell>{currencyFormat(x.priceperKg ?? 0)}</Table.Cell>
                  <Table.Cell>
                    {x.imageUrl && (
                      <Popup
                        trigger={<Image src={x.imageUrl} size="mini" />}
                        content={<Image src={x.imageUrl} />}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell singleLine>
                    {(x.title?.trim() ?? "") === "" ||
                    (x.priceafter ?? 0) === 0 ? (
                      editingId === x.internalId ? (
                        <Fragment>
                          <Icon
                            inverted
                            name="check"
                            color="green"
                            circular
                            onClick={() => {
                              (
                                document.getElementById("titleForm") as any
                              )?.dispatchEvent(
                                new Event("submit", {
                                  cancelable: true,
                                  bubbles: true,
                                })
                              );

                              (
                                document.getElementById("priceForm") as any
                              )?.dispatchEvent(
                                new Event("submit", {
                                  cancelable: true,
                                  bubbles: true,
                                })
                              );
                            }}
                          />
                          <Icon
                            inverted
                            name="close"
                            color="red"
                            circular
                            onClick={() => setEditingId(undefined)}
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Icon
                            inverted
                            name="pencil"
                            color="blue"
                            circular
                            onClick={() => {
                              setEditingId(x.internalId);
                            }}
                          />
                          <Icon
                            inverted
                            name="minus"
                            color="red"
                            circular
                            onClick={() => removeError(x.internalId)}
                          />
                        </Fragment>
                      )
                    ) : (
                      <div />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row textAlign="right">
              <Table.HeaderCell colSpan="9">
                <Pagination
                  defaultActivePage={activeTablePage}
                  onPageChange={(e, d: any) => {
                    setActivePage(d.activePage);
                  }}
                  firstItem={null}
                  lastItem={null}
                  pointing
                  secondary
                  totalPages={
                    errorData.length % 10 === 0
                      ? errorData.length / 10
                      : Math.ceil(errorData.length / 10)
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
      <div style={{ textAlign: "center" }}>
        <Button
          color="blue"
          disabled={processing || errorData.length > 0}
          content="Proceed"
          onClick={() => {
            setStep(2);
            uploadData();
          }}
        />
      </div>
    </div>
  );
};

export default observer(UploadStep2);
