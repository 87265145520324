import { IProduct } from "../../../interfaces/product";
import { ISuperMarket } from "../../../interfaces/supermarket";

export function findCheaper(selectedIds: string[], products: IProduct[]) {
  var toFilter =
    selectedIds.length > 0
      ? products.filter((f) => selectedIds.includes(f.marketId))
      : products;

  if (toFilter.length === 0) return undefined;

  return toFilter?.reduce(function (prev, curr) {
    return prev.currentPrice < curr.currentPrice ? prev : curr;
  });
}

export function findCheapestDelivery(
  markets: ISuperMarket[],
  products: IProduct[]
) {
  var options: any[] = [];

  products.forEach((x) => {
    var market = markets.filter((f) => f.type_id === x.marketId)[0];

    if (market.offersDelivery) options.push(x);
  });

  if (options.length === 0) return undefined;

  return options
    .slice()
    .sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1))[0];
}

export function currencyFormat(num: number, includeSign: boolean = true) {
  if (isNaN(num)) {
    return (0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (includeSign ? "€" : "")
  }
  return (
    (num ?? 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
    (includeSign ? "€" : "")
  );
}

export function getStepOptionsInc(max: number, step?: number) {
  var retVal = [];
  // console.log(`${max}, ${step}`)
  for (var i = 0.0; i <= max; i += (step ?? 1)) {
    // console.log(i);
    retVal.push({
      key: i,
      value: i,
      text: i.toFixed(2),
    })
  }
  // console.log(retVal);

  return retVal;
}