import React from "react";
import { Link } from "react-router-dom";
import { List } from "semantic-ui-react";

const Terms = () => {
  return (
    <div className="paddedPage documents">
      <h1>Terms and conditions</h1>
      <p>
        These terms and conditions ("Terms", "Agreement") are an agreement between Cheap Trolley LTD (C-106584) ( "us", "we" or "our")
        and you ("User", "you" or "your"). This Agreement sets forth the general terms and conditions of
        your use of the <Link to="/">www.cheaptrolley.com</Link> (“website”) and any of its products or services (collectively,
        "Mobile Application" or "Services"). By accessing or using the cheaptrolley.com website and services,
        you are agreeing to comply with and be bound by the following terms and conditions.
        If you do not agree with any part of these terms, we kindly ask that you refrain from using our website.
        We do suggest that you will also review the
        <Link to="/privacy-policy"> privacy policy</Link> and
        <Link to="/disclaimer"> disclaimer </Link> for completeness purposes.
      </p>
      <h2>Age requirement</h2>
      <p>
        You must be at least 18 years of age to use this website. By using this website and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age.
      </p>
      <h2>Online Purchase Process</h2>
      <p>
        Orders can be placed through our website.
        <List bulleted style={{ marginTop: "4px" }}>
          <List.Item>Prices, product availability, and delivery options are subject to change without prior notice. </List.Item>
          <List.Item>Orders are subject to acceptance and confirmation by CheapTrolley LTD.</List.Item>
          <List.Item>We reserve the right to limit or refuse service to any customer based on our discretion and operational requirements.</List.Item>
        </List>
        During checkout, you will choose whether you prefer your order to be:
        <List bulleted style={{ marginBottom: "4px" }}>
          <List.Item>Delivery</List.Item>
          <List.Item>Pick-up</List.Item>
        </List>
        Once the service is selected, changes can only be made before the order is dispatched to the courier. Once the order is out for delivery, it cannot be changed to pick-up.
      </p>
      <h2>Payment</h2>
      <p>
        You can make payment for your order using the available payment methods provided on our website. We assure you that all
        payment information is processed securely through <a rel="noreferrer" target="_blank" href="https://www.vivawallet.com/gr_el">Viva Wallet</a>. However, for added security measures, we do not retain your payment details.

        You are responsible for paying all fees or charges associated with your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. To ensure the security of sensitive and private data exchange between our website and users, we employ SSL secured communication channels and encryption techniques, along with digital signatures.

        In certain instances where we perceive a purchase to be a high-risk transaction, we may request additional verification. This may include providing us with a copy of your valid government-issued photo identification and potentially a recent bank statement for the credit or debit card used for the purchase.

        Please note that we reserve the right to modify products and product pricing at any time.
      </p>
      <h2>Delivery</h2>
      <p>
        Delivery times and fees may vary. For specific delivery information, please refer to our website FAQs, and you can also see the next slots available once you checkout.
        We utilize a third-party service provider for order delivery. While we strive for timely deliveries, please note that we are not liable for delays beyond our control.
      </p>
      <h2>Returns and Refunds</h2>
      <p>
        You are eligible for a return and a refund if:
        <List bulleted style={{ marginTop: "4px", marginBottom: "4px" }}>
          <List.Item>You have received an expired product.</List.Item>
          <List.Item>You have received another product other then the one you ordered.</List.Item>
          <List.Item>Item is not in a good state. </List.Item>
          <List.Item>You did not receive the item you ordered.</List.Item>
        </List>

        You can initiate the return process by either including the item with your next online purchase for return via our delivery courier or by returning it in person to our warehouse. Regardless of the return method you choose, you will receive credit on your account for use toward your next online purchase.
      </p>
      <h2>Privacy</h2>
      <p>
        Your privacy is a top priority for us. To understand how we collect, use, and safeguard your personal information, we encourage you to review our
        <Link to="/privacy-policy"> privacy policy</Link>
      </p>
      <h2>User content</h2>
      <p>
        We do not own any data, information or material ("Content") that you submit on the website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may, but have no obligation to, monitor and review Content in the Mobile Application submitted or created using our Services by you. Unless specifically permitted by you, your use of the website does not grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose. But you grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you.
      </p>
      <h2>Accuracy of information</h2>
      <p>
        Occasionally there may be information on the website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, availability, promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the website or on any related Service is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information in the website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied in the website should be taken to indicate that all information in the website or on any related Service has been modified or updated.
      </p>
      <h2>Third-party services</h2>
      <p>
        If you decide to enable, access or use third-party services, be advised that your access and use of such other services are governed solely by the terms and conditions of such other services, and we do not endorse, are not responsible or liable for, and make no representations as to any aspect of such other services, including, without limitation, their content or the manner in which they handle data (including your data) or any interaction between you and the provider of such other services. You irrevocably waive any claim against website Developer with respect to such other services. website Developer is not liable for any damage or loss caused or alleged to be caused by or in connection with your enablement, access or use of any such other services, or your reliance on the privacy practices, data security processes or other policies of such other services. You may be required to register for or log into such other services on their respective websites. By enabling any other services, you are expressly permitting website Developer to disclose your data as necessary to facilitate the use or enablement of such other service.
      </p>
      <h2>Backups</h2>
      <p>
        We are not responsible for Content residing in the website. In no event shall we be held liable for any loss of any Content. It is your sole responsibility to maintain appropriate backup of your Content. Notwithstanding the foregoing, on some occasions and in certain circumstances, with absolutely no obligation, we may be able to restore some or all of your data that has been deleted as of a certain date and time when we may have backed up data for our own purposes. We make no guarantee that the data you need will be available.
      </p>
      <h2>Links to other websites</h2>
      <p>
        Although this website may link to other websites, we are not, directly
        or indirectly, implying any approval, association, sponsorship,
        endorsement, or affiliation with any linked mobile application, unless
        specifically stated herein. Some of the links in the website may be
        &quot;affiliate links&quot;. This means if you click on the link and
        purchase an item, website Developer will receive an affiliate
        commission. We are not responsible for examining or evaluating, and we
        do not warrant the offerings of, any businesses or individuals or the
        content of their websites. We do not assume any responsibility or
        liability for the actions, products, services, and content of any other
        third-parties. You should carefully review the legal statements and
        other conditions of use of any website which you access through a link
        from this Mobile Application. Your linking to any other off-site
        websites is at your own risk.
      </p>
      <h2>Disclaimer of warranty</h2>
      <p>
        You agree that your use of our website or Services is solely at your own
        risk. You agree that such Service is provided on an &quot;as is&quot;
        and &quot;as available&quot; basis. We expressly disclaim all warranties
        of any kind, whether express or implied, including but not limited to
        the implied warranties of merchantability, fitness for a particular
        purpose and non-infringement. We make no warranty that the Services will
        meet your requirements, or that the Service will be uninterrupted,
        timely, secure, or error-free; nor do we make any warranty as to the
        results that may be obtained from the use of the Service or as to the
        accuracy or reliability of any information obtained through the Service
        or that defects in the Service will be corrected. You understand and
        agree that any material and/or data downloaded or otherwise obtained
        through the use of Service is done at your own discretion and risk and
        that you will be solely responsible for any damage to your computer
        system or loss of data that results from the download of such material
        and/or data. We make no warranty regarding any goods or services
        purchased or obtained through the Service or any transactions entered
        into through the Service. No advice or information, whether oral or
        written, obtained by you from us or through the Service shall create any
        warranty not expressly made herein.
      </p>
      <h2>Dispute resolution</h2>
      <p>
        The formation, interpretation, and performance of this Agreement and any
        disputes arising out of it shall be governed by the substantive and
        procedural laws of Northern, Malta without regard to its rules on
        conflicts or choice of law and, to the extent applicable, the laws of
        Malta. The exclusive jurisdiction and venue for actions related to the
        subject matter hereof shall be the state and federal courts located in
        Northern, Malta, and you hereby submit to the personal jurisdiction of
        such courts. You hereby waive any right to a jury trial in any
        proceeding arising out of or related to this Agreement. The United
        Nations Convention on Contracts for the International Sale of Goods does
        not apply to this Agreement.
      </p>
      <h2>Changes and amendments</h2>
      <p>
        We reserve the right to modify this Agreement or its policies relating
        to the website or Services at any time, effective upon posting of an
        updated version of this Agreement in the Mobile Application. When we do,
        we will send you an email to notify you. Continued use of the website
        after any such changes shall constitute your consent to such changes.
      </p>
      <h2>Governing Law</h2>
      <p>
        These terms and conditions are governed by the laws of Malta.
      </p>
      <h2>Contacting us</h2>
      <p>
        If you would like to contact us to understand more about this Agreement
        or wish to contact us concerning any matter relating to it, you may do
        so via the{" "}
        <a
          target="_blank"
          href="https://cheaptrolley.com/about"
          rel="noreferrer"
        >
          about page
        </a>{" "}
        or send an email to{" "}
        <a target="_blank" rel="noreferrer" href="mailto:info@cheaptrolley.com">
          info@cheaptrolley.com
        </a>
      </p>
      <p>This document was last updated on March 23, 2024</p>
    </div>
  );
};

export default Terms;
