import React, { useContext } from 'react';
import { IRecipe } from '../../../interfaces/chat';
import { Image } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/RootStoreContext';

interface IProps {
    messageId: string;
    recipe: IRecipe,
}

const RecipeCard: React.FC<IProps> = ({ recipe, messageId }) => {
    const context = useContext(RootStoreContext);
    const { setSelectedRecipe } = context.chatUIStore;

    const backgrounds = [
        "RecipeBg1.png",
        "RecipeBg2.png",
        "RecipeBg3.png",
        "RecipeBg4.png",
        "RecipeBg5.png",
    ]
    const randomBackground = backgrounds[Math.floor(Math.random() * backgrounds.length)];

    const getDifficulty = (difficulty: "easy" | "medium" | "hard") => {
        switch (difficulty) {
            case "easy":
                return "Easy"
            case "medium":
                return "Medium"
            case "hard":
                return "Hard"
            default:
                break;
        }
    }

    return (
        <div
            className="clickable recipe-card fxFixed fxDisplay fxDirCol"
            onClick={() => setSelectedRecipe(messageId, recipe)}
        >
            <div className="recipe-card-image">
                <Image
                    src={`${process.env.REACT_APP_CLOUDFRONT}/${randomBackground}`}
                    onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null;
                        currentTarget.src =
                            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found-ctl.png`;
                    }}
                    alt={recipe.recipeName}
                />
            </div>
            <div className="recipe-card-content fxStretch">
                <h3 className="recipe-title">{recipe.recipeName}</h3>
                <p className="recipe-description">{recipe.recipeDescription}</p>
            </div>
            <div className="recipe-card-footer">
                <div className="recipe-info">
                    <span className="recipe-time fxDisplay fxDirCol">
                        <Image src='/time.svg' />
                        <p>{recipe.executionTime ? `${recipe.executionTime}'` : "-"}</p>
                    </span>
                    <span className="recipe-difficulty fxDisplay fxDirCol">
                        <Image src='/cook.svg' />
                        <p>{recipe.difficulty ? getDifficulty(recipe.difficulty) : "-"}</p>
                    </span>
                    <span className="recipe-servings fxDisplay fxDirCol">
                        <Image src='/bowl.svg' />
                        <p>{recipe.noOfServings ?? "-"}</p>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default observer(RecipeCard);
