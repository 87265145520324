import { formatDistance, formatRelative } from 'date-fns';
import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect } from 'react'
import { Button, Dimmer, Divider, Header, Icon, Label, Loader, Message, Segment } from 'semantic-ui-react'
import { OrderMonitoringStore } from '../functions/orderMonitoringStore';
import { ClientOrderStatusType, OrderStatusOption } from '../../../options/OrderStatusOption';
import { useNavigate } from 'react-router';
import { ButtonTypes } from '../../confirmationModal/functions/ButtonTypes';
import { RootStoreContext } from '../../../stores/RootStoreContext';

interface IProps {
    header: string;
    statuses: ClientOrderStatusType[]
    orderMonitoreStore: OrderMonitoringStore
}

const ClientOrderMonitorWidget: React.FC<IProps> = ({ header, statuses, orderMonitoreStore }) => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext);
    const { openConfirmationModal, closeConfirmationalModal } = context.confirmationModalStore;
    const {
        clientOrders,
        deliverySlots,
        load,
        reload,
        loading,
        dispose,
        count,
        deleteOrder,
        acceptOrder
    } = orderMonitoreStore;

    useEffect(() => {
        load(statuses)
        return () => {
            dispose()
        }
    }, [load, dispose, statuses])

    return (
        <Segment basic>
            <div style={{ textAlign: "center" }}>
                <Header textAlign="center" color="blue">
                    <div style={{ position: "absolute", right: "0", top: "15px" }}>
                        <Icon
                            onClick={() => reload(statuses)}
                            className='clickable'
                            title="Share order"
                            name={loading ? 'circle notch' : 'refresh'}
                            loading={loading}
                            color='blue'
                            size='large'
                        />
                    </div>
                    {header}
                    {clientOrders && <Header.Subheader>
                        {`${count} order(s)`}
                    </Header.Subheader>}
                </Header>
            </div>
            <Divider />
            {loading && (
                <Dimmer active inverted>
                    <Loader inverted content="Loading" />
                </Dimmer>
            )}

            {clientOrders &&
                clientOrders.map((x) => (
                    <Segment key={x.id} color={x.checkoutType === "delivery" ? "olive" : "blue"}>
                        <div className="fxDisplay fxFixed fxJustifyBetween">
                            <div>
                                <b>{x.displayId}</b>
                                {x.ref && <Label content={x.ref} color='pink' />}
                                <p><Icon name='circle' color={OrderStatusOption.filter(f => f.value === x.status)[0].color} /> {OrderStatusOption.filter(f => f.value === x.status)[0].text}</p>

                            </div>
                            {x.deliverySlotId ?
                                (deliverySlots.length > 0 ? <div>
                                    {formatRelative(
                                        deliverySlots?.filter(f => f.id === x.deliverySlotId)[0]?.startsOn * 1000,
                                        new Date(),
                                        {}
                                    )}
                                    <p style={{ textAlign: "right" }}>in {formatDistance(
                                        deliverySlots?.filter(f => f.id === x.deliverySlotId)[0]?.startsOn * 1000,
                                        new Date()
                                    )}</p>
                                </div> : <div>Loading...</div>)
                                :
                                <div>Pick up</div>}
                        </div>

                        {x.notes && <Segment basic className='no-padding'>
                            <Divider />
                            <Message content={x.notes} warning />
                        </Segment>}
                        <Divider />
                        <div className="fxDisplay fxFixed fxJustifyEven">
                            <Button
                                basic
                                color='blue'
                                content={x.status === "sent_to_market" ? "Start Packing" : "Review"}
                                onClick={() => navigate(`${x.id}/prepare`)}
                            />

                            {(x.status === "payment_failed" || x.status === "pending_payment") && <Fragment>
                                <Button
                                    color='green'
                                    content={"Accept"}
                                    onClick={() => openConfirmationModal(
                                        "Confirm order acceptance",
                                        "Are you sure you want to accept this order?",
                                        () =>
                                            acceptOrder(x.id).then(() =>
                                                closeConfirmationalModal()
                                            ),
                                        "Cancel",
                                        "Accept",
                                        ButtonTypes.None,
                                        ButtonTypes.Positive
                                    )}
                                />
                                <Button
                                    color='red'
                                    content={"Delete"}
                                    onClick={() => openConfirmationModal(
                                        "Confirm order deletion",
                                        "Are you sure you want to delete this order?",
                                        () =>
                                            deleteOrder(x.id).then(() =>
                                                closeConfirmationalModal()
                                            ),
                                        "Cancel",
                                        "Delete",
                                        ButtonTypes.None,
                                        ButtonTypes.Negative
                                    )}
                                />
                            </Fragment>}
                        </div>
                    </Segment>
                ))}
        </Segment>
    )
}

export default observer(ClientOrderMonitorWidget)