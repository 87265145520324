import { addYears } from "date-fns";
import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IUserForListDto } from "../../../interfaces/user";
import { RootStore } from "../../../stores/RootStoreContext";

export class UserListStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable searchText?: string = undefined;
    @observable users: IUserForListDto[] = []
    @observable lockedOnly: boolean = false;
    @observable admin: boolean = false;
    @observable owner: boolean = false;

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setLockedOnly = (value: boolean) => { this.lockedOnly = value };
    @action setAdminOnly = (value: boolean) => { this.admin = value };
    @action setOwnerOnly = (value: boolean) => { this.owner = value };
    @action setSearchText = (value?: string) => { this.searchText = value };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action setHasMore = (value: boolean) => { this.hasMore = value };
    @action resetUsers = (value: IUserForListDto[]) => { this.users = value };
    @action setUsers = (value: IUserForListDto[]) => {
        var existing = this.users.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.users.push(...missing);
    };

    @action toggleLocked = async () => {
        try {
            this.resetUsers([]);
            this.setCurrentPage(0)
            this.setLockedOnly(!this.lockedOnly);

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action toggleAdmin = async () => {
        try {
            this.resetUsers([]);
            this.setCurrentPage(0)
            this.setAdminOnly(!this.admin);

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action toggleOwner = async () => {
        try {
            this.resetUsers([]);
            this.setCurrentPage(0)
            this.setOwnerOnly(!this.owner);

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action deleteUserPermanately = async (userId: string) => {
        try {
            await agent.Users.Manage.permanent_delete(userId)
                .then(() =>
                    this.resetUsers(this.users.filter(f => f.id !== userId)
                    ))
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Users.get(this.searchText?.trim(), this.lockedOnly, this.admin, this.owner, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.hasMore);
            this.setUsers(response.data);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action lockUser = async (id: string) => {
        return await agent.Users.Manage.lock(id).then(() => {
            var idex = this.users.findIndex(f => f.id === id);
            runInAction(() => {
                this.users[idex].lockoutEnd = addYears(new Date(), 1000);
            })
        });
    }

    @action unlockUser = async (id: string) => {
        return await agent.Users.Manage.unlock(id).then(() => {
            var idex = this.users.findIndex(f => f.id === id);
            runInAction(() => {
                this.users[idex].lockoutEnd = null
            })
        });
    }

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetUsers([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action dispose = () => {

        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetUsers([]);
        this.setHasMore(false);
    }
}