import { observer } from 'mobx-react-lite';
import { useState, useEffect, Fragment } from 'react';
import { CategoryNode } from '../../../interfaces/categories';

interface IPRops {
    node: CategoryNode,
    onClick: (category: CategoryNode) => Promise<void>
}

const CategoryIcon: React.FC<IPRops> = ({ node, onClick }) => {
    const [imageExists, setImageExists] = useState(false);

    useEffect(() => {
        const checkImageExists = async () => {
            try {
                const response = await fetch(`/categories/${node.internalId}.png`);
                // console.log(response.headers)
                if (response.ok && node.internalId !== "mymama" && node.internalId !== "free_delivery") { //response.headers.has("Content-Length")) {
                    // console.log(node.internalId);
                    setImageExists(true);
                }
            } catch (error) {
                // console.error("Error checking image existence:", error);
            }
        };

        if (node.isMain) {
            checkImageExists();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {imageExists ? (
                <img
                    src={`/categories/${node.internalId}.png`}
                    alt={node.description}
                    className="category-icon"
                    onClick={() => onClick(node)}
                />
            ) : <div style={{ width: "30px" }}></div>}
        </Fragment>
    );
};

export default observer(CategoryIcon);
