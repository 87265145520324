import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { combineValidators, isRequired } from "revalidate";
import { FORM_ERROR } from "final-form";
import ProfileFieldUpdate from "./ProfileFieldUpdate";
import { RootStoreContext } from "../../../stores/RootStoreContext";

const PhoneEditor = () => {
  const context = useContext(RootStoreContext);
  const {
    profile,
    loading,
    currentlyEditing,
    setCurrentlyEditing,
    updatePhone,
  } = context.profileStore;
  const { user } = context.userStore;
  const { isMobile } = context.deviceStore;

  return (
    <ProfileFieldUpdate
      fullWidthOnEdit={isMobile}
      loading={loading}
      allowEdit={profile?.id === user?.id}
      groupFieldLabel={"Phone Number"}
      editMode={currentlyEditing === "phone"}
      fieldName="phone"
      editClicked={(value: string) => {
        setCurrentlyEditing(value);
      }}
      cancelClicked={() => {
        setCurrentlyEditing("");
      }}
      update={(user: any) =>
        updatePhone(user.phoneNumber).catch((error) => ({
          [FORM_ERROR]: error,
        }))
      }
      validate={combineValidators({
        phoneNumber: isRequired(""),
      })}
      initialValues={profile!}
      displayValue={
        profile?.phoneNumber ? profile.phoneNumber : "+ (###) #######"
      }
      fields={[
        {
          display: "Number",
          placeholder: "Number",
          name: "phoneNumber",
          showDisplay: false,
        },
      ]}
    />
  );
};

export default observer(PhoneEditor);
