import React, { Fragment, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import PhotoWidgetDropzone from "./PhotoWidgetDropzone";

interface IProps {
  uploadPhoto: (file: object) => Promise<void>;
}

const PhotoUploadWidget: React.FC<IProps> = ({ uploadPhoto }) => {
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    return () => {
      // files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  });
  return (
    <Fragment>
      <div className="fxStretch">
        <PhotoWidgetDropzone
          setFiles={(files) => {
            setFiles(files);
            uploadPhoto(files[0]);
          }}
        />
      </div>
    </Fragment>
  );
};

export default observer(PhotoUploadWidget);
