import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IUserForListDto } from "../../../interfaces/user";

export class CampaignsDetail {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loadingUsers: boolean = false;
    @observable loadingCampaignUsers: boolean = false;
    @observable currentPageUsers: number = 0;
    @observable currentPageCampaignUsers: number = 0;
    @observable hasMoreUsers: boolean = false;
    @observable hasMoreCampaignUsers: boolean = false;
    @observable searchText?: string = undefined;
    @observable users: IUserForListDto[] = []
    @observable campaignUsers: any[] = []
    @observable selectedUsers: string[] = []

    @action setLoadingUsers = (value: boolean) => { this.loadingUsers = value };
    @action setLoadingCampaignUsers = (value: boolean) => { this.loadingCampaignUsers = value };
    @action setSearchText = (value?: string) => { this.searchText = value };
    @action setCurrentPageUsers = (value: number) => { this.currentPageUsers = value };
    @action setCurrentPageCampaignUsers = (value: number) => { this.currentPageCampaignUsers = value };
    @action setHasMoreUsers = (value: boolean) => { this.hasMoreUsers = value };
    @action setHasMoreCampaignUsers = (value: boolean) => { this.hasMoreCampaignUsers = value };
    @action resetUsers = (value: IUserForListDto[]) => { this.users = value };
    @action resetCampaignUsers = (value: any[]) => { this.campaignUsers = value };
    @action setUsers = (value: IUserForListDto[]) => {
        var existing = this.users.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.users.push(...missing);
    };
    @action setCampaignUsers = (value: any[]) => {
        var existing = this.campaignUsers.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.campaignUsers.push(...missing);
    };

    @action loadUsers = async () => {
        try {
            this.setLoadingUsers(true);

            var response = await agent.Users.get(this.searchText?.trim(), false, false, false, this.currentPageUsers);

            this.setCurrentPageUsers(this.currentPageUsers + 1);
            this.setHasMoreUsers(response.hasMore);
            this.setUsers(response.data);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoadingUsers(false);
        }

    };


    @observable campaignStats: any = undefined
    @action setCampaignStats = (value: any) => this.campaignStats = value;
    @action getCampaignStats = async (campaignId: string) => {
        var result = await agent.MarketingCampaigns.stats(campaignId);
        this.setCampaignStats(result);
    }

    @observable sendingEmails: boolean = false
    @action setSendingEmails = (value: boolean) => this.sendingEmails = value;
    @action sendBulkEmails = async (campaignId: string) => {
        var response = await agent.MarketingCampaigns.sendEmails(campaignId);
        this.getCampaignStats(campaignId);

        if (response.HasMore) {
            this.sendBulkEmails(campaignId)
        }
        else {
            this.setSendingEmails(false);
        }
    }

    @action loadCampaignUsers = async (campaignId: string, status: "unsent" | undefined) => {
        try {
            this.setLoadingCampaignUsers(true);

            var response = await agent.MarketingCampaigns.Users.get(campaignId, status, this.currentPageCampaignUsers);

            this.setCurrentPageCampaignUsers(this.currentPageCampaignUsers + 1);
            this.setHasMoreCampaignUsers(response.HasMore);
            this.setCampaignUsers(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoadingCampaignUsers(false);
        }

    };
    @action removeUser = async (campaignId: string, userId: string) => {
        try {
            await agent.MarketingCampaigns.Users.remove(campaignId, userId).then(() => {
                var users = this.campaignUsers.filter(f => f.id !== userId);
                this.resetCampaignUsers(users);
            });


        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoadingCampaignUsers(false);
        }

    }

    @action addUser = async (campaignId: string, userId: string) => {
        try {
            await agent.MarketingCampaigns.Users.add(campaignId, userId).then(() => {
                var user = this.users.filter(f => f.id === userId)[0];

                this.setCampaignUsers([{
                    'id': userId,
                    'name': user.firstName,
                    'lastName': user.lastName,
                    'email': user.email,
                    'status': 'unsent'
                }])
            });


        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoadingCampaignUsers(false);
        }

    }

    @action addUsers = async (campaignId: string) => {
        try {
            await agent.MarketingCampaigns.Users.add_all(campaignId).then(() => {
                this.setHasMoreCampaignUsers(true);
                this.setCurrentPageCampaignUsers(0);
                this.loadCampaignUsers(campaignId, undefined);
            });


        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoadingCampaignUsers(false);
        }

    }

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetUsers([]);
            this.setCurrentPageUsers(0)

            this.loadUsers();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.setLoadingUsers(false);
        this.setCurrentPageUsers(0);
        this.setHasMoreUsers(false);
        this.resetUsers([]);
        this.setLoadingCampaignUsers(false);
        this.setCurrentPageCampaignUsers(0);
        this.setHasMoreCampaignUsers(false);
        this.resetCampaignUsers([]);
    }
}

export const CampaignsDetailStore = new CampaignsDetail()