import { action, computed, makeObservable, observable } from "mobx";
import agent from "../api/agent";
import { IUserMinimal, IUserRoles } from "../interfaces/user";
import { RootStore } from "./RootStoreContext";
import jwt_decode from "jwt-decode";

export class UserStore {
    rootStore: RootStore;

    @observable user?: IUserMinimal = undefined;
    @observable loading: boolean = false;
    @observable isChefEnabled: boolean = true;

    @observable userRoles?: IUserRoles = undefined;
    @action setUserRoles = (value: IUserRoles) => this.userRoles = value;
    @action setChefEnabled = (value: boolean) => this.isChefEnabled = value;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @action getUser = async () => {
        try {
            this.setLoading(true);

            var user = await agent.Users.getCurrent();

            var roles = await agent.Users.Manage.get_roles(user.id);
            this.setUserRoles(roles);

            // agent.GlobalConfig.getConfig("chefAI", false).then((value) => this.setChefEnabled(value ?? false));

            this.setAccountId((jwt_decode(this.rootStore.sessionStore.token!) as any).client_account_id);

            if (this.isAdmin) {
                var preferredMarkets = await agent.Users.getPreferredStore(user.id);
                this.setChefEnabled(true);

                if (preferredMarkets)
                    this.rootStore.commonStore.setSelectedMarketIds(preferredMarkets);
            }

            this.setUser(user);

        } catch (error) {
            this.setUser(undefined);
            this.rootStore.sessionStore.logout();
            console.log(error);
        } finally {
            this.setLoading(false);
        }
    }

    @observable accountId?: string = undefined;

    @action setUser = (values?: IUserMinimal) => this.user = values;
    @action setAccountId = (value?: string) => this.accountId = value;

    @computed get isAdmin() {
        if (!this.rootStore.sessionStore.isLoggedIn) return false;

        return (jwt_decode(this.rootStore.sessionStore.token!) as any).client_is_admin === "True";
    }

    @computed get isShopAdmin() {
        if (!this.rootStore.sessionStore.isLoggedIn) return false;

        return (jwt_decode(this.rootStore.sessionStore.token!) as any).client_role_id === "1";
    }

    @computed get isCommercialUser() {
        if (!this.rootStore.sessionStore.isLoggedIn) return false;

        return (jwt_decode(this.rootStore.sessionStore.token!) as any).client_role_id === "3";
    }

    @computed get isOwner() {
        if (!this.rootStore.sessionStore.isLoggedIn) return false;

        return (jwt_decode(this.rootStore.sessionStore.token!) as any).client_is_owner === "True";
    }

    @computed get UserId() {
        if (!this.rootStore.sessionStore.isLoggedIn) return undefined;

        return (jwt_decode(this.rootStore.sessionStore.token!) as any).sub;
    }

    @action setLoading = (value: boolean) => this.loading = value;



}