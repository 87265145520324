import React, { useContext } from 'react'
import { RootStoreContext } from '../../stores/RootStoreContext'
import { observer } from 'mobx-react-lite';
import Associations from '../associations/Associations';
import UserAssociations from '../associations/UserAssociations';

const AssociationsPathDecision = () => {
    const context = useContext(RootStoreContext)
    const { isAdmin } = context.userStore;

    return isAdmin ? <Associations /> : <UserAssociations />
}

export default observer(AssociationsPathDecision)