import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IRequestForShopIntegration } from "../../../interfaces/shopIntegration";
import { RootStore } from "../../../stores/RootStoreContext";

export class IntegrationRequestListStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable statuses: string[] = ["pending_approval"];
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable integrations: IRequestForShopIntegration[] = []

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action setStatuses = (value: string[]) => { this.statuses = value };
    @action setHasMore = (value: boolean) => { this.hasMore = value };
    @action resetIntegrations = (value: IRequestForShopIntegration[]) => { this.integrations = value };
    @action setIntegrations = (value: IRequestForShopIntegration[]) => {
        var existing = this.integrations.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.integrations.push(...missing);
    };

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Shop.Integration.get(this.statuses);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setIntegrations(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action search = async (text: string) => {
        try {
            this.resetIntegrations([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action reject = async (id: string) => {
        try {
            agent.Shop.Integration.reject(id).then(() => {
                this.resetIntegrations(this.integrations.filter(f => f.id !== id));
            })
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action approve = async (id: string) => {
        try {
            agent.Shop.Integration.approve(id).then(() => {
                this.resetIntegrations(this.integrations.filter(f => f.id !== id))
            })
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetIntegrations([]);
        this.setHasMore(false);
        this.statuses = ["pending_approval"]
    }
}