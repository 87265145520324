import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { Divider, Dropdown, DropdownProps, Header, List, Message } from "semantic-ui-react";

const UploadStep1 = () => {
  const context = useContext(RootStoreContext);
  const { downloadSample, showFile, locations, setSelectedLocation, selectedLocation } = context.uploadWizard;

  return (
    <div>
      <Header color="blue" content="Introduction" />
      <p className="font-size-16">
        Our wizard will guide you, through sequence of steps, for easy
        integration. Once you upload the list of products on Cheap Trolley, we
        will compare the new list, with your current products, and override any
        common products with the newly entered information. It is highly
        recommended to use a desktop/PC for this process, as it may be harder to
        do so via mobile.
      </p>
      <p className="font-size-16">
        In case you would like further assistance, feel free to{" "}
        <a href="mailto:info@cheaptrolley.com">contact us</a>, in order to setup
        a call.
      </p>
      <Divider />
      <Header color="blue" content="Sample file & Data Structure" />
      <p className="font-size-16">
        You can download a sample file by clicking{" "}
        <span
          style={{ color: "blue" }}
          className="div-as-link"
          onClick={() => downloadSample()}
        >
          here
        </span>
        . Please find below the meaning of each column:
        <List bulleted style={{ paddingLeft: "13px" }}>
          <List.Item>
            <b>
              InternalId <span style={{ color: "#2285d0" }}>[Mandatory]</span> :{" "}
            </b>
            the unique key of the product (if you don't have any, use same value
            with EAN)
          </List.Item>
          <List.Item>
            <b>EAN : </b>standard EAN for EU products, it's used to compare your
            prices to other providers
          </List.Item>
          <List.Item>
            <b>
              Title <span style={{ color: "#2285d0" }}>[Mandatory]</span> :{" "}
            </b>
            name of the product
          </List.Item>
          <List.Item>
            <b>Quantity : </b>quantity in your inventory (every time this is
            changed, it will override any existing quantity)
          </List.Item>
          <List.Item>
            <b>Price Before : </b>in case you offer a discount, mention the
            price before the discount here
          </List.Item>
          <List.Item>
            <b>
              Price After <span style={{ color: "#2285d0" }}>[Mandatory]</span>{" "}
              :{" "}
            </b>
            current price of your product (the final price the consumer will
            purchase it for)
          </List.Item>
          <List.Item>
            <b>Price Per Kg : </b>price per kilo of your product (if any)
          </List.Item>
          <List.Item>
            <b>Image URL : </b>mention the url where we can access the image of
            the product
          </List.Item>
        </List>
      </p>
      <Divider />
      <Message
        info
        icon={"info"}
        header="The order of the columns is important. In case you do not have all the data for the optional columns, you can leave it empty."
      />
      <Divider />
      <Header color="blue" content="Uploading the file" />
      <p className="font-size-16">
        You can start the wizard by {locations.length > 0 && "selecting a desired location and "} uploading the file below. Once the file is
        uploaded, we will guide you through our wizard, to review all your
        products and amend in case of issues.
      </p>

      {
        locations.length > 0 && <Dropdown
          style={{ marginRight: "7px" }}
          placeholder="Select location"
          closeOnBlur
          selection
          search
          onChange={(ev, data: DropdownProps) => {
            setSelectedLocation((data?.value ?? "") as string);
          }}
          options={locations
            .map((x) => ({
              key: x.id,
              text: x.title,
              value: x.id,
            }))}
        />
      }

      <input
        className="ui blue basic button"
        disabled={locations.length > 0 && selectedLocation === ""}
        type="file"
        onChange={(e) => showFile(e)}
      />
    </div>
  );
};

export default observer(UploadStep1);
