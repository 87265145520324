import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { IOrderMonitorProduct } from '../../../interfaces/order'
import { Button, Divider, Form, Header, Icon, Label, Segment, Table } from 'semantic-ui-react'
import { ClientOrderProductStatus } from '../../../options/HubOrderProductStatus'
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import NumericInput from '../../../form/NumericInput'
import ErrorMessage from '../../../form/ErrorMessage'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import { ButtonTypes } from '../../confirmationModal/functions/ButtonTypes'
import CameraCapture from '../../../common/CameraCapture'
import { currencyFormat } from '../../products/functions/productHelper'

interface IProps {
    item: IOrderMonitorProduct;
    updatingProduct: boolean;
    orderClosed: boolean;
    orderId?: string;
    displayId?: string;
    undoProduct: (productId: string, locationId: string) => Promise<void>;
    updatePackedProduct: (productId: string, locationId: string, quantity: number) => Promise<void>;
    refundProduct: (catalogueId: string, locationId: string, quantity: number) => Promise<void>;
    toggleHasImage: (catalogueId: string) => Promise<void>;
}

const OrderPreparationTableRow: React.FC<IProps> = ({ orderId, displayId, item, updatingProduct, updatePackedProduct, refundProduct, undoProduct, orderClosed, toggleHasImage }) => {
    const context = useContext(RootStoreContext);
    const { openConfirmationModal, closeConfirmationalModal } =
        context.confirmationModalStore;
    const { openModal, closeModal } = context.modalStore;
    const [issueOpen, setIssueOpen] = React.useState(false);

    return (
        <Table.Row key={item.catalogueId}>
            {displayId && <Table.Cell onClick={() => {
                window.open(`${window.location.origin}/orders-monitoring/${orderId}/prepare`, '_blank', 'noopener,noreferrer');
            }} className='ct-green-button-active' style={{ padding: "0px", fontSize: "20px", fontWeight: "bolder" }} textAlign='center'>
                {displayId} <Icon name="external alternate" />
            </Table.Cell>}
            <Table.Cell>
                <Header size='small'>
                    {item.catalogueDesc}
                    <Header.Subheader>
                        {item.productDesc}
                        <Icon name="camera" size='large' color={item.hasImage === true ? "green" : "red"} style={{ marginLeft: "10px" }} onClick={() => openModal(<CameraCapture catalogueId={item.catalogueId} closeModal={closeModal} />, "fullscreen", undefined, false, false)} />
                        <Icon name="image" size='large' color={item.hasImage === true ? "green" : "red"} style={{ marginLeft: "10px" }} onClick={() => toggleHasImage(item.catalogueId)} />
                    </Header.Subheader>

                </Header>

            </Table.Cell>
            <Table.Cell textAlign='center'>
                <Label size='large' color={ClientOrderProductStatus.filter(f => f.value === (item.status ?? "pending"))[0].color}>
                    {ClientOrderProductStatus.filter(f => f.value === (item.status ?? "pending"))[0].text}
                </Label>
            </Table.Cell>
            <Table.Cell>
                <p>Unit: {currencyFormat(item.currentPrice)}</p>
                <p>Batch: {currencyFormat((item.packedQuantity ?? item.quantity) * item.currentPrice)}</p>
                <p style={{ "color": "red" }}>Refun: {currencyFormat((item.refundedQuantity ?? 0) * item.currentPrice)}</p>
            </Table.Cell>
            <Table.Cell textAlign='center'>
                <Segment style={{ backgroundColor: item.quantity !== ((item.packedQuantity ?? 0) + (item.refundedQuantity ?? 0)) ? "#D0A9A9" : "#C5D8B3" }}>
                    <div className='fxDisplay fxFixed fxJustifyEven' style={{ fontSize: "18px", fontWeight: "600" }}>
                        <div>
                            <div>
                                {item.quantity ?? 0}
                            </div>
                            <span style={{ fontWeight: "100", fontSize: "14px" }}>Requested</span>
                        </div>

                        <div style={{ color: ((item.packedQuantity ?? 0) < item.quantity) ? "#b36009" : "green" }}>
                            <div>
                                {item.packedQuantity ?? 0}
                            </div>
                            <span style={{ fontWeight: "100", fontSize: "14px" }}>Packed</span>
                        </div>

                        <div style={{ color: ((item.refundedQuantity ?? 0) > 0) ? "red" : "black" }}>
                            <div>
                                {item.refundedQuantity ?? 0}
                            </div>
                            <span style={{ fontWeight: "100", fontSize: "14px" }}>Refunded</span>
                        </div>

                    </div>
                </Segment>
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {(!item.status || item.status === "pending") && <Fragment>
                    <Icon disabled={updatingProduct || orderClosed} name="check" size='large' color='green' onClick={() => updatePackedProduct(item.catalogueId, item.locationId, item.quantity)} />
                    <Icon disabled={updatingProduct || orderClosed} style={{ marginLeft: "7px", marginRight: "10px" }} name="warning sign" size='large' color='yellow' onClick={() => setIssueOpen(!issueOpen)} />
                </Fragment>}
                {(item.status !== undefined && item.status !== "pending") && <Icon disabled={updatingProduct || orderClosed} name="undo alternate" size='large' color='red' onClick={() => undoProduct(item.catalogueId, item.locationId)} />}
                {(item.status !== undefined && item.status !== "fully_packed") && <Icon disabled={updatingProduct || orderClosed || (item.refundedQuantity ?? 0) > 0} name="money bill alternate outline" size='large' color='red' onClick={() => {
                    openConfirmationModal(
                        <div>Refund <span style={{ color: "red", fontWeight: "bolder" }}>{item.quantity - (item.packedQuantity ?? 0)}</span> units to the client</div>,
                        "Are you sure you want to refund the client? If you make a mistake you have to manually adjust the balance to the clients wallet.",
                        () =>
                            refundProduct(item.catalogueId, item.locationId, item.quantity - (item.packedQuantity ?? 0)).then(() =>
                                closeConfirmationalModal()
                            ),
                        "Cancel",
                        "Refund",
                        ButtonTypes.Positive,
                        ButtonTypes.Negative
                    );
                }} />}

                {issueOpen && <Fragment>
                    <Divider />
                    <FinalForm
                        initialValues={{ 'packedQuantity': item.packedQuantity }}
                        onSubmit={(values: any) =>
                            updatePackedProduct(item.catalogueId, item.locationId, values.packedQuantity)
                                .then((values) => setIssueOpen(false))
                                .catch((error) => ({
                                    [FORM_ERROR]: error,
                                }))
                        }
                        render={({
                            handleSubmit,
                            submitting,
                            invalid,
                            dirtySinceLastSubmit,
                            submitError,
                        }) => (
                            <Form onSubmit={handleSubmit} error className="attached fluid">
                                <Form.Field>
                                    <label style={{ fontSize: "13px", textAlign: "left", color: "Blue" }}>
                                        {"Packed Amount"}
                                    </label>
                                    <Field
                                        name="packedQuantity"
                                        component={NumericInput}
                                        placeholder={"Packed Amount"}
                                    />
                                </Form.Field>

                                {submitError && !dirtySinceLastSubmit && (
                                    <ErrorMessage error={submitError} />
                                )}

                                <div style={{ marginTop: "7px", textAlign: "center" }}>
                                    <Button
                                        disabled={invalid && !dirtySinceLastSubmit}
                                        loading={submitting}
                                        color="blue"
                                        content={"Save"}
                                    />
                                </div>
                            </Form>
                        )}
                    />
                </Fragment>}
            </Table.Cell>
        </Table.Row>
    )
}

export default observer(OrderPreparationTableRow);