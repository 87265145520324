import { observer } from 'mobx-react-lite'
import Highlighter from "react-highlight-words";
import React, { Fragment, useContext } from 'react'
import { IAssociationForScore, IAssociationV2 } from '../../../interfaces/association'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import { Header, Segment, Table, Image, Icon, Popup, Divider, Label } from 'semantic-ui-react'
import { getImageUrl, getImageUrlPlain } from '../../../helpers/productImage'
import { getMarketImageUrl } from '../../../helpers/supermarketImage'
import { currencyFormat } from '../../products/functions/productHelper'
import { IProduct } from '../../../interfaces/product'
import { ButtonTypes } from '../../confirmationModal/functions/ButtonTypes'

interface IProps {
    item: IAssociationV2
}

const BulkPossibleAssociationItem: React.FC<IProps> = ({ item }) => {
    const context = useContext(RootStoreContext);
    const { markets } = context.cacheStore;
    const { mergeAssociationListView,
        deletePossibleAssociationListView
    } = context.possibleAssociationsV2Store;
    const { closeConfirmationalModal, openConfirmationModal } =
        context.confirmationModalStore;

    return (
        <Segment.Group horizontal>
            <Segment style={{ maxWidth: "450px", minWidth: "450px" }}>
                <Table basic="very">
                    <Table.Body>
                        {item.products.map((x => <Table.Row>
                            <Table.Cell>
                                <Header as="h4" image>
                                    <Popup
                                        trigger={
                                            <Image
                                                src={
                                                    getImageUrl(x) ??
                                                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                                }
                                                onError={({
                                                    currentTarget,
                                                }: any) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src =
                                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                }}
                                                alt="Product Logo"
                                                rounded
                                                size="mini"
                                            />
                                        }
                                        content={
                                            <Image
                                                src={
                                                    getImageUrl(x) ??
                                                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                                }
                                                onError={({
                                                    currentTarget,
                                                }: any) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src =
                                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                }}
                                                alt="Product Logo"
                                                rounded
                                            />
                                        }
                                    />
                                    <Header as="h4" image style={{ minWidth: "220px" }}>
                                        <Header.Content>{x.itemDesc}</Header.Content>
                                        <Header.Subheader>
                                            {x.sku ?? x.internalId}
                                        </Header.Subheader>
                                    </Header>
                                </Header>
                            </Table.Cell>
                            <Table.Cell textAlign='right'>
                                <Image
                                    alt="Product Logo"
                                    size="tiny"
                                    src={getMarketImageUrl(
                                        markets?.filter(
                                            (z) => z.type_id === x.marketId
                                        )[0]
                                    )}
                                />
                                <p>
                                    {
                                        markets?.filter(
                                            (z) => z.type_id === x.marketId
                                        )[0]?.title
                                    }
                                </p>
                                <div
                                    style={{
                                        marginBottom: "0.5em",
                                        fontSize: "17px",
                                        fontWeight: "bolder",
                                        textAlign: "end"
                                    }}
                                >
                                    {currencyFormat(x.currentPrice, true)}

                                </div>
                            </Table.Cell>
                        </Table.Row>))}
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                {item.possibleGroups
                    ?.slice()
                    .sort((a: IAssociationForScore, b: IAssociationForScore) =>
                        a.score < b.score ? 1 : -1
                    )
                    .map((association: IAssociationForScore) => (
                        <div key={association.id} >
                            <div className='fxFixed fxDisplay fxJustifyBetween'>
                                <span
                                    title="Approve"
                                    className="item"
                                    onClick={() => {
                                        mergeAssociationListView(item.id, association.id);
                                    }}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="green check large fitted icon"
                                    ></i>
                                </span>
                                <div className='fxStrech'>
                                    <Header as="h3">{association.title}</Header>
                                    <Header.Subheader>{
                                        <Highlighter
                                            caseSensitive={false}
                                            highlightStyle={{
                                                background: "#ffb7b7",
                                            }}
                                            searchWords={association.title.split(" ") ?? []}
                                            autoEscape={true}
                                            textToHighlight={item.products[0].itemDesc}
                                        />
                                    }</Header.Subheader>
                                </div>
                                {(item.possibleGroups?.length ?? 0) > 0 && item.possibleGroups![0].products.filter(f => !f.discontinued && f.marketId === item.products[0].marketId).length > 0 &&
                                    <Label color='red'>
                                        {
                                            markets?.filter(
                                                (z) => z.type_id === item.products[0].marketId
                                            )[0]?.title
                                        } exists
                                    </Label>}
                                <span onClick={() => {
                                    window.open(`${window.location.origin}/compare?id=${association.id}`, "_blank")
                                }}>
                                    <i
                                        aria-hidden="true"
                                        className="purple external alternate large fitted icon"
                                    ></i>
                                </span>
                            </div>
                            <Divider />

                            <div style={{ maxHeight: "250px", overflow: "auto" }}>
                                <Table basic="very" celled>
                                    <Table.Body>
                                        {association.products &&
                                            association.products.slice()
                                                .sort((a, b) => (a.currentPrice > b.currentPrice ? 1 : -1)).map(
                                                    (f: IProduct) =>
                                                        <Table.Row key={f.id} error={f.discontinued}>
                                                            <Table.Cell warning={f.currentPrice === item.products[0].currentPrice}
                                                                negative={f.currentPrice > item.products[0].currentPrice}
                                                                positive={f.currentPrice < item.products[0].currentPrice}>
                                                                <div
                                                                    style={{
                                                                        fontSize: "17px",
                                                                        fontWeight: "bolder",
                                                                    }}
                                                                >
                                                                    <Icon name="euro" />
                                                                    {currencyFormat(f.currentPrice, false)}
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Header as="h4" image>
                                                                    <Popup
                                                                        trigger={
                                                                            <Image
                                                                                src={getImageUrlPlain(
                                                                                    f.marketId,
                                                                                    f.imageUrl
                                                                                )}
                                                                                onError={({
                                                                                    currentTarget,
                                                                                }: any) => {
                                                                                    currentTarget.onerror = null;
                                                                                    currentTarget.src =
                                                                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                                                }}
                                                                                alt="Product Logo"
                                                                                rounded
                                                                                size="mini"
                                                                            />
                                                                        }
                                                                        content={
                                                                            <Image
                                                                                src={
                                                                                    getImageUrlPlain(
                                                                                        f?.marketId,
                                                                                        f?.imageUrl
                                                                                    ) ??
                                                                                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                                                                }
                                                                                onError={({
                                                                                    currentTarget,
                                                                                }: any) => {
                                                                                    currentTarget.onerror = null;
                                                                                    currentTarget.src =
                                                                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                                                }}
                                                                                alt="Product Logo"
                                                                                rounded
                                                                            />
                                                                        }
                                                                    />
                                                                    <Header.Content>
                                                                        {f.itemDesc}
                                                                    </Header.Content>
                                                                </Header>
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Fragment>
                                                                    <b>SKU :</b> {f.sku ?? "-"}
                                                                    <br />
                                                                    <b>Internal Id :</b> {f.internalId}
                                                                </Fragment>
                                                            </Table.Cell>
                                                            <Table.Cell style={{ padding: "2px" }}>
                                                                <Fragment>
                                                                    <Image
                                                                        alt="Product Logo"
                                                                        title={markets?.filter(
                                                                            (z) => z.type_id === f.marketId
                                                                        )[0]?.title}
                                                                        size="tiny"
                                                                        src={getMarketImageUrl(
                                                                            markets?.filter(
                                                                                (z) => z.type_id === f.marketId
                                                                            )[0]
                                                                        )}
                                                                    />

                                                                </Fragment>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                )}
                                    </Table.Body>
                                </Table>
                            </div>

                        </div>
                    ))}</Segment>
            <Segment textAlign='center' vertical>
                <span
                    title="Remove Product"
                    className="item"
                    onClick={() => {
                        openConfirmationModal(
                            "Confirm product info deletion",
                            "Are you sure you want to delete this product?",
                            () =>
                                deletePossibleAssociationListView(item.id).then(() =>
                                    closeConfirmationalModal()
                                ),
                            "Cancel",
                            "Delete",
                            ButtonTypes.None,
                            ButtonTypes.Negative
                        );
                    }}
                >
                    <i
                        aria-hidden="true"
                        className="red large fitted icon trash alternate"
                    ></i>
                </span>
            </Segment>
        </Segment.Group >
    )
}

export default observer(BulkPossibleAssociationItem)