import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Button, Divider, Tab } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import Terms from "./Terms";
import PrivacyPolicy from "./PrivacyPolicy";
import BusinessPrivacyPolicy from "./BusinessPrivacyPolicy";
import BusinessTerms from "./BusinessTerms";

interface IProps {
  mode: "b2b" | "b2c";
}

const LegalDocuments: React.FC<IProps> = ({ mode }) => {
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const { closeSecondModal, closeModal } = context.modalStore;

  return (
    <div>
      <Tab
        menu={{
          secondary: true,
          pointing: true,
          color: "blue",
          size: isMobile ? "massive" : "small",
        }}
        panes={[
          {
            menuItem: {
              content: "Privacy Policy",
              style: { flex: isMobile ? 1 : "" },
            },
            render: () =>
              mode === "b2c" ? <PrivacyPolicy /> : <BusinessPrivacyPolicy />,
          },
          {
            menuItem: {
              content: "Terms & Conditions",
              style: { flex: isMobile ? 1 : "" },
            },
            render: () => (mode === "b2c" ? <Terms /> : <BusinessTerms />),
          },
        ]}
      />
      <Divider />
      <div style={{ textAlign: "center" }}>
        <Button
          color="blue"
          content={"Close"}
          onClick={() => (mode === "b2c" ? closeSecondModal() : closeModal())}
        />
      </div>
    </div>
  );
};

export default observer(LegalDocuments);
