import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, Fragment, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { Button, Checkbox, Dimmer, Divider, Icon, Input, List, Loader, Menu, Popup, Segment, Table } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import GlobalInventoryTableRow from './GlobalInventoryTableRow';
import CsvExport from '../../../common/csvExport';
import { NoResults } from '../../../common/NoResults';

interface IProps {
    shopId?: string;
}

const GlobalInventory: React.FC<IProps> = ({ shopId }) => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const { accountId } = context.userStore;
    const { toggleFullScreen } = context.accountPageStore;
    const { setPage } = context.analyticsStore;
    const {
        loading,
        associations,
        load,
        hasMore,
        dispose,
        search,
        searchMinPrice,
        searchMaxPrice,
        searchText,
        searchSku,
        setSelectedMarketIds,
        discontinued,
        changeDiscontinued,
        percentage,
        changePercentage,
        exportDataForCsv,
        internal,
        changeInternal,
        notMyPriceDrops,
        changeNotMyPriceDrops,
        changeDropSince,
        downloading,
        markets,
        selectedMarketIds,
        selectedSearchType,
        changeSearchType,
        separateSKU,
        setSeparateSKU
    } = context.globalInventoryStore;

    const [timer, setTimer] = React.useState(0);
    const [advancedFilter, setAdvancedFilter] = React.useState(false);
    const [selectedDrop, setSelectedDrop] = React.useState(-1);

    const priceDropItems = [
        { index: 1, text: "Last 1 day" },
        { index: 2, text: "Last 2 days" },
        { index: 7, text: "Last week" },
        { index: 0, text: "All" },
    ]

    const searchTypeItems = [
        { index: "any", text: "Any word in text" },
        { index: "contain", text: "Contains exact text" },
        { index: "starts", text: "Starts with" },
    ]

    useEffect(() => {
        load(shopId);
        setPage("GlobalInventory", window.location.pathname);

        return () => {
            dispose();
        };
    }, [load, navigate, dispose, setPage, shopId]);

    return (
        <div className="simply-paddedPage" >
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "5px 0" }}
            >
                <Menu.Item
                    className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}
                >
                    <Input
                        fluid={isMobile}
                        transparent={isMobile}
                        size={isMobile ? "large" : "small"}
                        icon={!isMobile ? <Icon name="search" className='ct-green-text' /> : undefined}
                        placeholder={"Search Text..."}
                        input={<input className={isMobile ? "" : "ctGreenPlaceholder"} />}
                        onChange={(event: ChangeEvent, data: any) => {
                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }
                            setTimer(
                                setTimeout(
                                    (callback: (text: string, sku: string) => void, text: string) => {
                                        callback(text, searchSku);
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search,
                                    data.value
                                )
                            );
                        }}
                    />
                </Menu.Item>
                <Menu.Item
                    className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}
                >
                    <Input
                        fluid={isMobile}
                        transparent={isMobile}
                        size={isMobile ? "large" : "small"}
                        icon={!isMobile ? <Icon name="search" className='ct-green-text' /> : undefined}
                        placeholder={"Search SKU..."}
                        input={<input className={isMobile ? "" : "ctGreenPlaceholder"} />}
                        onChange={(event: ChangeEvent, data: any) => {
                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }
                            setTimer(
                                setTimeout(
                                    (callback: (text: string, sku: string) => void, text: string) => {
                                        callback(searchText, text);
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search,
                                    data.value
                                )
                            );
                        }}
                    />
                </Menu.Item>
                <Menu.Item style={{ padding: "0px" }}>
                    <div className="fxFixed fxDisplay fxAlignCenter">
                        <div className="catalogue-sort-by-holder">Shops |</div>
                        <Popup
                            on="click"
                            closeOnTriggerClick
                            closeOnDocumentClick
                            closeOnPortalMouseLeave
                            closeOnTriggerBlur
                            closeOnTriggerMouseLeave
                            closeOnEscape
                            position="bottom center"
                            trigger={
                                <div className="fxFixed fxDisplay">
                                    <div className="fxStrech catalogue-sort-by-selected">
                                        {selectedMarketIds.length === 0 ? "All" : selectedMarketIds.length > 2 ? `${selectedMarketIds.length} shops` :
                                            markets.filter(f => f.id !== accountId && !f.shutDown).filter(f => selectedMarketIds.includes(f.type_id)).map(x => x.title).join(',')}
                                    </div>
                                    <Icon name="angle down" />
                                </div>}
                            content={
                                <List size="medium">
                                    {markets
                                        .filter(f => f.id !== accountId && !f.shutDown)
                                        ?.slice()
                                        .sort((a, b) => (a.title < b.title ? -1 : 1)).map((f, i) => (
                                            <List.Item
                                                key={f.type_id}
                                                onClick={() => {
                                                    if (selectedMarketIds.includes(f.type_id)) {
                                                        setSelectedMarketIds(selectedMarketIds.filter(x => x !== f.type_id));
                                                    }
                                                    else {
                                                        setSelectedMarketIds([...selectedMarketIds, f.type_id])
                                                    }
                                                    // console.log(f.type_id);
                                                    // console.log([...selectedMarketIds, f.type_id])

                                                    if (!search) return;
                                                    if (timer != null) {
                                                        clearTimeout(timer);
                                                        setTimer(0);
                                                    }

                                                    setTimer(
                                                        setTimeout(
                                                            (callback: (text: string) => void, text: string) => {
                                                                callback(searchText);
                                                                clearTimeout(timer);
                                                            },
                                                            500,
                                                            search
                                                        )
                                                    );
                                                }}
                                                className={`${i !== 0 ? "catalogue-sort-by-option" : ""} clickable`}
                                            >
                                                <div className={`fxDisplay fxFixed ${selectedMarketIds.includes(f.type_id) ? "ct-green-text-dark" : ""}`}  >
                                                    <div style={{ width: "18px", textAlign: "start" }}>{selectedMarketIds.includes(f.type_id) && <Icon name={"check"} />}</div>
                                                    <div style={{ marginLeft: "5px" }}>{f.title}</div>
                                                </div>
                                            </List.Item>
                                        ))}
                                </List>
                            }
                        />
                    </div>
                </Menu.Item>

                <Menu.Menu position="right">
                    <Menu.Item>
                        <Button
                            onClick={() => setAdvancedFilter(!advancedFilter)}
                            className='button-as-link clickable'
                            content="Advanced Filter" />
                    </Menu.Item>
                    <Menu.Item icon title="Export" style={{ padding: "0px" }}>
                        <CsvExport
                            onClick={exportDataForCsv}
                            content={
                                <Fragment>
                                    <Icon name="file excel outline" /> Export
                                </Fragment>
                            }
                            disabled={downloading}
                            loading={downloading}
                        />
                    </Menu.Item>
                    <Menu.Item icon title="Full screen">
                        <Icon name='expand'
                            color='blue'
                            size='large'
                            onClick={() => { toggleFullScreen() }} />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            {advancedFilter && <Fragment>
                <div className='fxFixed fxDisplay'>
                    <div>
                        <Menu.Item>
                            <div className="fxFixed fxDisplay fxAlignCenter">
                                <div className="catalogue-sort-by-holder">Search Type |</div>
                                <Popup
                                    on="click"
                                    closeOnTriggerClick
                                    closeOnDocumentClick
                                    closeOnPortalMouseLeave
                                    closeOnTriggerBlur
                                    closeOnTriggerMouseLeave
                                    closeOnEscape
                                    position="bottom center"
                                    trigger={
                                        <div className="fxFixed fxDisplay">
                                            <div className="fxStrech catalogue-sort-by-selected">
                                                {searchTypeItems.filter(f => f.index === selectedSearchType)[0]?.text}
                                            </div>
                                            <Icon name="angle down" />
                                        </div>}
                                    content={
                                        <List size="medium">
                                            {searchTypeItems.map((f, i) => (
                                                <List.Item
                                                    key={f.index}
                                                    onClick={() => {
                                                        changeSearchType(f.index as any);
                                                    }}
                                                    className={`${i !== 0 ? "catalogue-sort-by-option" : ""} clickable`}
                                                >
                                                    <div className={`fxDisplay fxFixed ${selectedSearchType === f.index ? "ct-green-text-dark" : ""}`}  >
                                                        <div style={{ width: "18px", textAlign: "start" }}>{selectedSearchType === f.index && <Icon name={"check"} />}</div>
                                                        <div style={{ marginLeft: "5px" }}>{f.text}</div>
                                                    </div>
                                                </List.Item>
                                            ))}
                                        </List>
                                    }
                                />
                            </div>
                        </Menu.Item>
                        <Menu.Item style={{ margin: "0 .5rem" }}>
                            <span style={{ fontWeight: "bold" }}>Price From :</span>
                            <Input
                                style={{ marginLeft: "4px" }}
                                transparent
                                icon={<Icon name="euro" className='ct-green-text' />}
                                placeholder={"Price From..."}
                                input={<input className={isMobile ? "" : "ctGreenPlaceholder"} />}
                                onChange={(event: ChangeEvent, data: any) => {
                                    if (!searchMinPrice) return;
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }
                                    setTimer(
                                        setTimeout(
                                            (callback: (text?: number) => void, text?: number) => {
                                                callback(text);
                                                clearTimeout(timer);
                                            },
                                            500,
                                            searchMinPrice,
                                            data.value
                                        )
                                    );
                                }}
                            />
                        </Menu.Item>
                        <Menu.Item style={{ margin: "0 .5rem" }}>
                            <span style={{ fontWeight: "bold" }}>Price To :</span>
                            <Input
                                style={{ marginLeft: "22px" }}
                                transparent
                                icon={<Icon name="euro" className='ct-green-text' />}
                                placeholder={"Price To..."}
                                input={<input className={isMobile ? "" : "ctGreenPlaceholder"} />}
                                onChange={(event: ChangeEvent, data: any) => {
                                    if (!searchMaxPrice) return;
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }
                                    setTimer(
                                        setTimeout(
                                            (callback: (text?: number) => void, text?: number) => {
                                                callback(text);
                                                clearTimeout(timer);
                                            },
                                            500,
                                            searchMaxPrice,
                                            data.value
                                        )
                                    );
                                }}
                            />
                        </Menu.Item>
                    </div>
                    <div className="custom-divider"></div>
                    <div>
                        <Menu.Item>
                            <div className="fxFixed fxDisplay fxAlignCenter">
                                <div className="catalogue-sort-by-holder">Price Drops Since |</div>
                                <Popup
                                    on="click"
                                    closeOnTriggerClick
                                    closeOnDocumentClick
                                    closeOnPortalMouseLeave
                                    closeOnTriggerBlur
                                    closeOnTriggerMouseLeave
                                    closeOnEscape
                                    position="bottom center"
                                    trigger={
                                        <div className="fxFixed fxDisplay">
                                            <div className="fxStrech catalogue-sort-by-selected">
                                                {selectedDrop === -1 ? "None" : priceDropItems.filter(f => f.index === selectedDrop)[0]?.text}
                                            </div>
                                            <Icon name="angle down" />
                                        </div>}
                                    content={
                                        <List size="medium">
                                            {priceDropItems.map((f, i) => (
                                                <List.Item
                                                    key={f.index}
                                                    onClick={() => {
                                                        if (selectedDrop === f.index) {
                                                            setSelectedDrop(-1);
                                                            changeDropSince(-1)
                                                            return;
                                                        }

                                                        setSelectedDrop(f.index);
                                                        var factor = f.index;
                                                        if (f.index > 0) {
                                                            factor = ((new Date()).getTime() / 1000) - (f.index * 86400)
                                                        }

                                                        changeDropSince(factor);
                                                    }}
                                                    className={`${i !== 0 ? "catalogue-sort-by-option" : ""} clickable`}
                                                >
                                                    <div className={`fxDisplay fxFixed ${selectedDrop === f.index ? "ct-green-text-dark" : ""}`}  >
                                                        <div style={{ width: "18px", textAlign: "start" }}>{selectedDrop === f.index && <Icon name={"check"} />}</div>
                                                        <div style={{ marginLeft: "5px" }}>{f.text}</div>
                                                    </div>
                                                </List.Item>
                                            ))}
                                        </List>
                                    }
                                />
                            </div>
                        </Menu.Item>
                        {selectedDrop !== -1 && <Menu.Item icon title="Exclude my shop price changes" style={{ padding: "0px" }}>
                            <Checkbox label='Exclude my shop price changes' checked={notMyPriceDrops} onClick={() => changeNotMyPriceDrops()} disabled={loading} />
                        </Menu.Item>}
                    </div>
                    <div className="custom-divider"></div>
                    <div>
                        <Menu.Item icon title="Only my items with compare" style={{ padding: "0px" }}>
                            <Checkbox label='Filter Only my inventory with compare' checked={internal} onClick={() => { if (selectedSearchType !== "starts") changeInternal() }} disabled={loading || selectedSearchType === "starts"} />
                            <Popup
                                trigger={<Icon style={{ marginLeft: "5px" }} name='info' color='blue' circular inverted size='small' />}
                                content={"By enabling this filter we will exclude all products that do not have a price for your shop and all products that only your shop has - so they have nothing to compare with."}
                                on={"hover"}
                                position='bottom right'
                            />
                        </Menu.Item>

                        <Menu.Item icon title="See differences in %" style={{ padding: "0px" }}>
                            <Checkbox label='See differences in %' checked={percentage} onClick={() => changePercentage()} disabled={loading} />
                        </Menu.Item>
                        <Menu.Item icon title="Include discontinued products">
                            <Checkbox label='Include discontinued products' checked={discontinued} onClick={() => changeDiscontinued()} disabled={loading} />
                        </Menu.Item>
                    </div>
                    <div className="custom-divider"></div>
                    <div>
                        <Menu.Item icon title="Export SKU(s) separately" style={{ padding: "0px" }}>
                            <Checkbox label='Export SKU(s) separately' checked={separateSKU} onClick={() => setSeparateSKU(!separateSKU)} />
                        </Menu.Item>
                    </div>
                </div>

            </Fragment>}
            <Divider style={isMobile ? { padding: "0", margin: "0" } : undefined} />
            {
                !loading || associations.length > 0 ? (
                    <div>
                        <InfiniteScroll
                            dataLength={associations.filter(f => f.products.filter(p => !p.discontinued).length > 0).length}
                            next={() => load()}
                            hasMore={hasMore}
                            loader={loading ? <h4>Loading...</h4> : <div />}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    {associations.length > 0 ? (
                                        <b></b>
                                    ) : (
                                        <NoResults />
                                    )}
                                </p>
                            }
                        >
                            <Table celled compact>
                                <Table.Header className='tableTest'>
                                    <Table.Row>
                                        <Table.HeaderCell>Product</Table.HeaderCell>
                                        {(shopId ?? accountId) && <Table.HeaderCell>{markets.filter(f => f.id === (shopId ?? accountId))[0]?.title}</Table.HeaderCell>}
                                        {(selectedMarketIds.length > 0 ? markets.filter(f => selectedMarketIds.includes(f.type_id)) : markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1)).filter(f => f.id !== (shopId ?? accountId) && !f.shutDown).map(x => <Table.HeaderCell key={x.id}>{x.title}</Table.HeaderCell>)}
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {(discontinued ? associations : associations
                                        .filter(f => f.products.filter(p => !p.discontinued).length > 0))
                                        .map((x) => (
                                            <GlobalInventoryTableRow key={x.id} association={x} accountId={(shopId ?? accountId)!} />
                                        ))}
                                </Table.Body>
                            </Table>
                        </InfiniteScroll>
                    </div>
                ) : (
                    <Segment
                        style={{
                            minHeight: "55vh",
                            boxShadow: "none",
                            border: "none",
                        }}
                    >
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    </Segment>
                )
            }
        </div >
    )
}

export default observer(GlobalInventory)