export type SortByTypes = "min_price_desc" | "min_price_asc" | "popularity" | "title_asc" | "title_desc" | "unit_price_asc" | "unit_price_desc"

export const SortByOptions: {
  key: SortByTypes,
  value: SortByTypes,
  text: string
}[] = [
    {
      key: "popularity",
      value: "popularity",
      text: "Trending",
    },
    {
      key: "unit_price_asc",
      value: "unit_price_asc",
      text: "Unit Price (Low to High)",
    },
    {
      key: "unit_price_desc",
      value: "unit_price_desc",
      text: "Unit Price (High to Low)",
    },
    {
      key: "min_price_asc",
      value: "min_price_asc",
      text: "Price (Low to High)",
    },
    {
      key: "min_price_desc",
      value: "min_price_desc",
      text: "Price (High to Low)",
    },
    {
      key: "title_asc",
      value: "title_asc",
      text: "Title (A to Z)",
    },
    {
      key: "title_desc",
      value: "title_desc",
      text: "Title (Z to A)",
    }
  ]

export interface Brand {
  id: string;
  imageUrl: string | null;
  title: string;
}

export interface Category {
  description: string;
  id: string;
  imageUrl: string | null;
  internalId: string;
}

export interface Word {
  id: string;
  key: string;
}

export interface Suggestions {
  brands: Brand[];
  categories: Category[];
  words: Word[];
}

export interface SearchState {
  searchTerm: string;
  isSticky: boolean;
  isSidebarSticky: boolean;
  isDrawerOpen: boolean;
}

export type SearchPost = {
  sortBy?: SortByTypes,
  brandIds?: string[],
  categoryIds?: string[],
  wordIds?: string[],
  searchText?: string,
  currentPage?: number,
  force_search?: boolean
}