import { action, makeAutoObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IDeliveryForOrderList, IDeliverySlotForList } from "../../../interfaces/delivery";
import { IClientOrderMonitor } from "../../../interfaces/order";
import { ClientOrderStatusType } from "../../../options/OrderStatusOption";

export class OrderMonitoringStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable count: number = 0;
    @observable hasMore: boolean = false;
    @observable clientOrders: IClientOrderMonitor[] = [];
    @observable deliverySlots: IDeliveryForOrderList[] = [];
    @observable currentPage: number = 0;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setCount = (value: number) => this.count = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetOrders = (value: IClientOrderMonitor[]) => this.clientOrders = value;
    @action resetDeliveries = (value: IDeliverySlotForList[]) => this.deliverySlots = value;
    @action setOrders = (value: IClientOrderMonitor[]) => {
        var existing = this.clientOrders.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);
        this.clientOrders.push(...missing);
    };
    @action setDeliverySlots = (value: IDeliveryForOrderList[]) => {
        var existing = this.deliverySlots.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);
        this.deliverySlots.push(...missing);
    };

    @action reload = async (statusIds: ClientOrderStatusType[]) => {
        this.dispose().then(() => this.load(statusIds));
    }

    @action load = async (statusIds: ClientOrderStatusType[]) => {
        this.setLoading(true);
        try {
            var response = await agent.Orders.Admin.Monitor.get(statusIds, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setCount(response.Count);
            this.setOrders(response.Items);
            this.setDeliverySlots(response.DeliverySlots);

        } catch (error) {

        }
        finally {
            this.setLoading(false);
        }
    }

    @action acceptOrder = async (orderId: string) => {
        agent.Orders.Admin.Monitor.accept(orderId).then(() => {
            runInAction(() => {
                var idx = this.clientOrders.findIndex(f => f.id === orderId);
                this.clientOrders[idx].status = "pending_processing";
            })
        })
    }

    @action deleteOrder = async (orderId: string) => {
        agent.Orders.Admin.Monitor.delete(orderId).then(() => {
            runInAction(() => {
                this.clientOrders = this.clientOrders.filter(f => f.id !== orderId);
            })
        })
    }

    @action dispose = async () => {
        this.setLoading(false);
        this.setCurrentPage(0);
        this.setHasMore(false);
        this.setCount(0);
        this.resetDeliveries([]);
        this.resetOrders([]);
    }
}