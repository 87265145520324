import React from "react";
import { Placeholder } from "semantic-ui-react";

interface IProps {
  lines?: number;
}

export const LoadingParagraph: React.FC<IProps> = ({ lines }) => {
  return (
    <Placeholder>
      <Placeholder.Header>
        {Array.from({ length: lines ?? 1 }, (value, index) => (
          <Placeholder.Line key={index} />
        ))}
      </Placeholder.Header>
    </Placeholder>
  );
};
