import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Menu, List, Segment, Dimmer, Loader, Label, Icon } from 'semantic-ui-react';
import { ConstractFullName } from '../../helpers/textHelper';
import { EarlyUserListStore } from './functions/EarlyUserListStore';
import agent from '../../api/agent';
import { runInAction } from 'mobx';
import { ShoppingRecurrencyOptions, ShoppingWayOptions } from '../../options/ShoppingOptions';
import { Localities } from '../../options/Localities';

const EarlyAccessUsers = () => {
    const navigate = useNavigate();
    const { loading,
        interests,
        load,
        dispose,
        hasMore,
        userDictionary,
        count,
        getCount,
        configDictionary,
        userAddressDictionary
    } = EarlyUserListStore;

    useEffect(() => {
        getCount();
        load();

        return () => {
            dispose();
        };
    }, [load, dispose, getCount]);

    return (
        <div className="simply-paddedPage">
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "5px 0" }}
            >
                <Menu.Menu position='right'>
                    <Menu.Item>
                        {count} requests
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            {!loading || interests.length > 0 ? (
                <div>
                    <InfiniteScroll
                        style={{ overflow: "hidden" }}
                        dataLength={interests.length} //This is important field to render the next data
                        next={() => load()}
                        hasMore={hasMore}
                        loader={loading ? <h4>Loading...</h4> : <div />}

                    >
                        <List
                            divided
                            relaxed
                            selection
                            style={isMobile ? { padding: "7px" } : undefined}
                        >
                            {interests.map((x) => (
                                <List.Item key={x.id} onDoubleClick={() => navigate(`/user/${x.userId}`)}>
                                    <List.Content floated="right">
                                        {configDictionary[x.userId] ?
                                            <Label color='green' onClick={() => agent.GlobalConfig.updateDeliveryEnabled(x.userId, false).then(() => runInAction(() => { configDictionary[x.userId] = false }))}><Icon name='truck' /> ON</Label> :
                                            <Label color='red'><Icon name='truck' onClick={() => agent.GlobalConfig.updateDeliveryEnabled(x.userId, true).then(() => runInAction(() => { configDictionary[x.userId] = true }))} /> OFF</Label>}
                                    </List.Content>
                                    <List.Icon
                                        name="user circle outline"
                                        size="large"
                                        verticalAlign="middle"
                                    />
                                    <List.Content>
                                        <List.Header>
                                            {x.userId in userAddressDictionary ? `${userAddressDictionary[x.userId] <= 55 ? 'Malta' : 'Gozo'} - ${Localities.filter(f => f.value === userAddressDictionary[x.userId])[0]?.text} - ` : "N/A - "}
                                            {x.userId in userDictionary ? ConstractFullName(userDictionary[x.userId].firstName, userDictionary[x.userId].lastName, userDictionary[x.userId].middleName) : x.userId}
                                        </List.Header>
                                        <List.Description>
                                            {`${x.id}`}
                                            {(x.way || x.recurrence) && <span>
                                                {" - "}
                                                {x.way && <b style={{ marginRight: "5px", color: "darkgreen" }}>{ShoppingWayOptions.filter(f => f.value === x.way)[0]?.text}</b>}
                                                {" - "}
                                                {x.recurrence && <b style={{ color: "darkgreen" }}>{ShoppingRecurrencyOptions.filter(f => f.value === x.recurrence)[0]?.text}</b>}

                                            </span>}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            ))}
                        </List>
                    </InfiniteScroll>
                </div>
            ) : (
                <Segment>
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                </Segment>
            )
            }
        </div >
    )
}

export default observer(EarlyAccessUsers);