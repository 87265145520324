import React, { useContext, useEffect } from 'react'
import { Button, Form } from 'semantic-ui-react';
import ErrorMessage from '../../../form/ErrorMessage';
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import { Form as FinalForm, Field } from "react-final-form";
import SelectInput from '../../../form/SelectInput';
import { IEmailOptions } from '../../../interfaces/email';
import agent from '../../../api/agent';
import { toast } from 'react-toastify';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { useNavigate } from 'react-router';


const validate = combineValidators({
    emailId: isRequired(""),
});


export const CreateCampaignWidget = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext);
    const { closeModal } = context.modalStore;

    const [options, setOptions] = React.useState<IEmailOptions[] | undefined>(undefined);

    const load = () => {
        agent.Emails.getOptions().then((records) => setOptions(records)).catch(() => toast.error("Error while fetching data..."));
    };

    useEffect(() => {
        load();

        return () => {
        }
    }, [])

    return (
        <div>
            <FinalForm
                onSubmit={(values: any) =>
                    agent.MarketingCampaigns.create(values.emailId)
                        .then((campaignId) => { navigate(`/marketing-campaigns/${campaignId}`); closeModal() })

                        .catch((error) => ({
                            [FORM_ERROR]: error,
                        }))
                }
                initialValues={{}}
                validate={validate}
                render={({
                    handleSubmit,
                    submitError,
                    dirtySinceLastSubmit,
                    submitting,
                    invalid,
                    form: {
                        mutators: { push, remove },
                    },
                }) => (
                    <Form onSubmit={handleSubmit} error>
                        <Form.Group widths={'equal'}>
                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>
                                    {"Email Template"}
                                </label>
                                <Field
                                    loading={(options?.length ?? 0) === 0}
                                    component={SelectInput}
                                    options={options?.map((x) => ({
                                        key: x.id,
                                        value: x.id,
                                        text: x.subject.filter(f => f.key === "en")[0].description,
                                    }))}
                                    name="emailId"
                                    placeholder={"Select an email"}
                                />
                            </Form.Field>
                        </Form.Group>

                        {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage error={submitError} />
                        )}
                        <div style={{ textAlign: "center" }}>
                            <Button.Group style={{ paddingTop: "15px" }}>
                                <Button
                                    color="blue"
                                    disabled={(invalid && !dirtySinceLastSubmit)}
                                    loading={submitting}
                                    content={"Save"}
                                />
                                <Button.Or />
                                <Button
                                    onClick={(event) => {
                                        event.preventDefault();
                                        closeModal()
                                    }}
                                    content={"Cancel"}
                                />
                            </Button.Group>
                        </div>
                    </Form>
                )}
            />
        </div>
    )
}
