import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useEffect } from 'react'
import { CountriesStore } from '../functions/CountriesStore';
import { Dimmer, Divider, Icon, Input, List, Loader, Menu, Segment } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import CountryListItem from './CountryListItem';

const ContriesDeliveryConfig = () => {
    const {
        loading,
        load,
        dispose,
        countries,
        search,
        hasMore,
    } = CountriesStore;

    const [timer, setTimer] = React.useState(0);

    useEffect(() => {
        load();
        return () => {
            dispose();
        };
    }, [dispose, load]);

    return (
        <div>
            <Menu
                secondary
                size={"small"}
                style={{ margin: "5px 0" }}
            >
                <Menu.Item>
                    <Input
                        size={"small"}
                        icon={<Icon name="search" color="blue" />}
                        placeholder={"Search..."}
                        input={
                            <input className="bluePlaceholder" />
                        }
                        onChange={(event: ChangeEvent, data: any) => {
                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: (text: string) => void, text: string) => {
                                        callback(text);
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search,
                                    data.value
                                )
                            );
                        }}
                    />
                </Menu.Item>
            </Menu>
            <Divider />

            {!loading || countries.length > 0 ? (
                <div>
                    <InfiniteScroll
                        style={{ overflow: "hidden" }}
                        dataLength={countries.length} //This is important field to render the next data
                        next={() => load()}
                        hasMore={hasMore}
                        loader={loading ? <h4>Loading...</h4> : <div />}
                    >
                        <List
                            divided
                            relaxed
                            selection
                        >
                            {countries.map((x) => (
                                <CountryListItem key={x.id} country={x} />
                            ))}
                        </List>
                    </InfiniteScroll>
                </div>
            ) : (
                <Segment>
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                </Segment>
            )}
        </div>
    )
}

export default observer(ContriesDeliveryConfig)