import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Button, Label, List } from "semantic-ui-react";
import { ConstractFullName } from "../../../helpers/textHelper";
import { IShopUserForListDto } from "../../../interfaces/user";
import { ShopUserRoleOptions } from "../../../options/ShopUserRoleOptions";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import ManageShopUserWidget from "./ManageShopUserWidget";

interface IProps {
  item: IShopUserForListDto;
}

const ShopUserListItem: React.FC<IProps> = ({ item }) => {
  const context = useContext(RootStoreContext);
  const { user } = context.userStore;
  const { openModal } = context.modalStore;

  return (
    <List.Item>
      <List.Content floated="right">
        {user?.id !== item.id && (
          <Button
            size="large"
            className="button-as-link blueColor"
            content="Manage"
            onClick={() => {
              openModal(
                <ManageShopUserWidget item={item} />,
                "mini",
                `Manage ${ConstractFullName(item.firstName, item.lastName)}`,
                true,
                false
              );
            }}
          />
        )}
      </List.Content>
      <List.Content>
        <List.Header>
          {user?.id === item.id ? (
            <Fragment>
              <Label basic color={"blue"}>
                This is you
              </Label>
              {" - "}
            </Fragment>
          ) : (
            <Label
              className="button-as-link"
              // color={item.roleId === 1 ? "teal" : "green"}
            >
              {ShopUserRoleOptions.filter((f) => f.key === item.roleId)[0].text}{" "}
              -
            </Label>
          )}
          {ConstractFullName(item.firstName, item.lastName, item.middleName)}
        </List.Header>
        <List.Description style={{ marginTop: "3px" }}></List.Description>
      </List.Content>
    </List.Item>
  );
};

export default observer(ShopUserListItem);
