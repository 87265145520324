import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { isMobile } from 'react-device-detect';
import { Menu, Divider, Table, Icon } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component'
import { currencyFormat } from '../../products/functions/productHelper';
import CsvExport from '../../../common/csvExport';
import { ClientWalletReportStore } from '../functions/ClientWalletReportStore';
import { NoResults } from '../../../common/NoResults';

const ClientWalletReport = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isAdmin } = context.userStore;

    const {
        items,
        hasMore,
        load,
        exportDataForCsv,
        downloading,
        loading,
        dispose } = ClientWalletReportStore;

    useEffect(() => {
        if (!isAdmin) {
            navigate("/")
            return;
        }
        load();

        return () => {
            dispose()
        }
    }, [isAdmin, navigate, dispose, load])


    return (
        <div className="simply-paddedPage">
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "0 0 5px 0" }}
            >
                <Menu.Menu position='right'>
                    <Menu.Item icon title="Export" style={{ padding: "0px" }}>
                        <CsvExport
                            onClick={exportDataForCsv}
                            content={
                                <Fragment>
                                    <Icon name="file excel outline" /> Export
                                </Fragment>
                            }
                            disabled={items.length === 0 || downloading}
                            loading={downloading}
                        />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider />
            <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={items.length} //This is important field to render the next data
                next={() => load()}
                hasMore={hasMore}
                loader={loading ? <h4>Loading...</h4> : <div />}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        {items.length > 0 ? (
                            <b></b>
                        ) : (
                            <NoResults />
                        )}
                    </p>
                }
            >
                <Table basic="very" celled >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Customer</Table.HeaderCell>
                            <Table.HeaderCell>Balance</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items.map((x) => (
                            <Table.Row key={x.id}>
                                <Table.Cell>{`${x.name} ${x.lastName}`}</Table.Cell>
                                <Table.Cell textAlign='right'>{currencyFormat(x.amount, true)}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                    <Table.Footer>
                        <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>Totals</Table.HeaderCell>
                        <Table.HeaderCell textAlign='right' style={{ fontWeight: 'bolder' }}>{currencyFormat(items?.reduce((a, b) => a + b.amount, 0), true)}</Table.HeaderCell>
                    </Table.Footer>
                </Table>
            </InfiniteScroll>
        </div>
    )
}

export default observer(ClientWalletReport)