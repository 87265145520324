import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { Button, Flag, List } from 'semantic-ui-react'
import { ICountry } from '../../../interfaces/country'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import DeliveryConfigurationWidget from './DeliveryConfigurationWidget'

interface IProps {
    country: ICountry
}

const CountryListItem: React.FC<IProps> = ({ country }) => {
    const context = useContext(RootStoreContext)
    const { openModal } = context.modalStore

    return (
        <List.Item
            onClick={() => openModal(<DeliveryConfigurationWidget countryId={country.id} />, "fullscreen", `Pickup & Delivery Schedule for : ${country.text}`, false, true)}
        >
            <List.Content floated="right">
                <Fragment>
                    <Button
                        size="large"
                        className="button-as-link greenColor"
                        icon={"angle right"}
                        onClick={() => { }}
                    />
                </Fragment>
            </List.Content>
            <List.Content>
                <List.Header>
                    {country.flag && <Fragment>
                        <Flag name={country.flag as any} /> - {" "}
                    </Fragment>}
                    {country.text}
                </List.Header>
            </List.Content>
        </List.Item>
    )
}

export default observer(CountryListItem);