import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useContext, useEffect } from "react";
import { Button, Dimmer, Icon, Input, List, Loader, Menu, Segment } from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { EmailTemplatesStore } from "./functions/EmailTemplatesStore";
import EmailTemplateListItem from "./components/EmailTemplateListItem";
import { RootStoreContext } from "../../stores/RootStoreContext";
import EmailTemplateDetail from "./components/EmailTemplateDetail";

const EmailTemplatesWidget = () => {
    const context = useContext(RootStoreContext);
    const { openModal } = context.modalStore;

    const {
        isLoading,
        load,
        dispose,
        items,
        search,
        hasMore,
    } = EmailTemplatesStore;

    const [timer, setTimer] = React.useState(0);


    useEffect(() => {
        load();
        return () => {
            dispose();
        };
    }, [dispose, load]);

    return (
        <div className="simply-paddedPage">
            <Menu
                secondary
                size={"small"}
                style={{ margin: "5px 0" }}
            >
                <Menu.Item>
                    <Input
                        size={"small"}
                        icon={<Icon name="search" color="blue" />}
                        placeholder={"Search..."}
                        input={
                            <input className="bluePlaceholder" />
                        }
                        onChange={(event: ChangeEvent, data: any) => {
                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: (text: string) => void, text: string) => {
                                        callback(text);
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search,
                                    data.value
                                )
                            );
                        }}
                    />
                </Menu.Item>
                <Menu.Menu position="right">
                    <Button
                        content="New Template"
                        color="blue"
                        basic
                        onClick={() => {
                            openModal(<EmailTemplateDetail />, "small");
                        }}
                    />
                </Menu.Menu>
            </Menu>

            {!isLoading || items.length > 0 ? (
                <div>
                    <InfiniteScroll
                        style={{ overflow: "hidden" }}
                        dataLength={items.length} //This is important field to render the next data
                        next={() => load()}
                        hasMore={hasMore}
                        loader={isLoading ? <h4>Loading...</h4> : <div />}

                    >
                        <List
                            divided
                            relaxed
                            selection
                        >
                            {items.map((x) => (
                                <EmailTemplateListItem key={x.id} item={x} />
                            ))}
                        </List>
                    </InfiniteScroll>
                </div>
            ) : (
                <Segment>
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                </Segment>
            )}
        </div>
    );
};

export default observer(EmailTemplatesWidget);