import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form } from "semantic-ui-react";
import { DateTimePicker } from "react-widgets";
import { getLocaleDateTimeFormat } from "../helpers/dateFormats";

interface IProps extends FieldRenderProps<Date, any>, FormFieldProps { }

const DateInput: React.FC<IProps> = ({
  input,
  width,
  className,
  placeholder,
  min,
  time = false,
  disabled = false,
  dropUp = false,
  changeFinished,
  meta: { touched, error },
}) => {
  return (
    <Form.Field className={className} error={touched && !!error} width={width}>
      <DateTimePicker
        placeholder={placeholder}
        min={min}
        value={
          typeof input.value === "number"
            ? new Date(input.value * 1000)
            : input.value || null
        }
        disabled={disabled}
        dropUp={dropUp}
        valueDisplayFormat={getLocaleDateTimeFormat(time)}
        onChange={(event: any) => {
          input.onChange(event);
          if (changeFinished) changeFinished(event);
        }}
        onBlur={input.onBlur}
        onKeyDown={(e) => e.preventDefault()}
        includeTime={time}
      />
    </Form.Field>
  );
};

export default DateInput;
