import { observer } from "mobx-react-lite";
import { combineValidators, isRequired } from "revalidate";
import { IProductForList } from "../../interfaces/product";
import { Form as FinalForm, Field } from "react-final-form";
import { Button, Card, Form, Label } from "semantic-ui-react";
import { FORM_ERROR } from "final-form";
import { useContext, useEffect } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import ErrorMessage from "../../form/ErrorMessage";
import TextInput from "../../form/TextInput";
import { IAssociationForSave } from "../../interfaces/association";
import { getImageUrl } from "../../helpers/productImage";
import React from "react";
import AssociationProductCard from "./AssociationProductCard";
import SelectInput from "../../form/SelectInput";
import { LoadingParagraph } from "../../components/LoadingParagraph";
import NumericInput from "../../form/NumericInput";

interface IProps {
  id?: string;
  products: IProductForList[];
  execOnSuccess?: (...args: []) => void;
}

const validate = combineValidators({
  title: isRequired(""),
});

const AssociationDetailWidget: React.FC<IProps> = ({
  products,
  id,
  execOnSuccess,
}) => {
  const context = useContext(RootStoreContext);
  const { save, currentlyEditingItem, dispose, load, loading } =
    context.productAssociationStore;
  const { closeModal } = context.modalStore;
  const [selectedid, setSelectedId] = React.useState(
    id === undefined ? products[0].id : undefined
  );

  useEffect(() => {
    load(id);

    return () => {
      dispose();
    };
  }, [id, load, dispose]);

  return (
    <div style={{ alignItems: "center" }}>
      {!loading && (
        <FinalForm
          onSubmit={(values: IAssociationForSave) =>
            save(values)
              .then((...args: []) => {
                if (execOnSuccess) execOnSuccess(...args);
              })
              .catch((error) => ({
                [FORM_ERROR]: error,
              }))
          }
          initialValues={
            currentlyEditingItem ?? {
              productIds: products.map((x) => x.id),
              imageUrl: getImageUrl(products[0]),
              title: products[0].itemDesc,
              noOfItems: 1,
            }
          }
          validate={validate}
          render={({
            handleSubmit,
            submitError,
            dirtySinceLastSubmit,
            submitting,
            form,
          }) => (
            <Form onSubmit={handleSubmit} error>
              <Card.Group centered>
                {products.map((x) => (
                  <AssociationProductCard
                    key={x.id}
                    product={x}
                    selected={selectedid === x.id}
                    onSelection={(id: string) => {
                      setSelectedId(id);
                      form.change(
                        "imageUrl",
                        getImageUrl(products.filter((x) => x.id === id)[0])
                      );
                    }}
                  />
                ))}
              </Card.Group>
              <div style={{ textAlign: "center" }}>
                <Form.Group widths="equal">
                  <Form.Field style={{ textAlign: "left" }}>
                    <label style={{ fontSize: "13px" }}>
                      {"Association Title"}
                    </label>
                    <Field
                      loading={loading}
                      component={TextInput}
                      name="title"
                      placeholder={"Title"}
                    />
                  </Form.Field>
                  <Form.Field style={{ textAlign: "left" }}>
                    <label style={{ fontSize: "13px" }}>{"No of items"}</label>
                    {loading ? (
                      <LoadingParagraph />
                    ) : (
                      <Field
                        component={NumericInput}
                        name="noOfItems"
                        placeholder={"No of items"}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field style={{ textAlign: "left" }}>
                    <label style={{ fontSize: "13px" }}>
                      {"Measurement Unit"}
                    </label>
                    <Field
                      loading={loading}
                      component={SelectInput}
                      options={[
                        {
                          value: "grams",
                          key: "grams",
                          text: "grams",
                        },
                        {
                          value: "ml",
                          key: "ml",
                          text: "ml",
                        },
                      ]}
                      name="measurementUnit"
                      placeholder={"Measurement Unit"}
                    />
                  </Form.Field>
                  <Form.Field style={{ textAlign: "left" }}>
                    <label style={{ fontSize: "13px" }}>{"Measurment"}</label>
                    {loading ? (
                      <LoadingParagraph />
                    ) : (
                      <Field
                        component={NumericInput}
                        name="measurment"
                        placeholder={"Measurment e.g. 700"}
                      />
                    )}
                    <Label
                      color="blue"
                      basic
                      size="small"
                      style={{ marginTop: "4px" }}
                      content="500"
                      onClick={() => form.change("measurment", 500)}
                    />
                    <Label
                      color="blue"
                      basic
                      size="small"
                      style={{ marginTop: "4px" }}
                      content="750"
                      onClick={() => form.change("measurment", 750)}
                    />
                    <Label
                      color="blue"
                      size="small"
                      basic
                      style={{ marginTop: "4px" }}
                      content="1000"
                      onClick={() => form.change("measurment", 1000)}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field style={{ textAlign: "left" }}>
                    <label style={{ fontSize: "13px" }}>{"Step"}</label>
                    {loading ? (
                      <LoadingParagraph />
                    ) : (
                      <Field
                        component={NumericInput}
                        name="step"
                        placeholder={"e.g. 0.1"}
                      />
                    )}
                    <Label
                      color="blue"
                      basic
                      size="small"
                      style={{ marginTop: "4px" }}
                      content="0.1-100gr"
                      onClick={() => form.change("step", 0.1)}
                    />
                    <Label
                      color="blue"
                      basic
                      size="small"
                      style={{ marginTop: "4px" }}
                      content="1-1kg"
                      onClick={() => form.change("step", 1)}
                    />
                  </Form.Field>
                  <Form.Field style={{ textAlign: "left" }}></Form.Field>
                </Form.Group>
              </div>
              {submitError && !dirtySinceLastSubmit && (
                <ErrorMessage error={submitError} />
              )}

              <div style={{ textAlign: "center" }}>
                <Button.Group style={{ paddingTop: "15px" }}>
                  <Button color="blue" loading={submitting} content={"Save"} />
                  <Button.Or />
                  <Button onClick={() => closeModal()} content={"Cancel"} />
                </Button.Group>
              </div>
            </Form>
          )}
        />
      )}
    </div>
  );
};

export default observer(AssociationDetailWidget);
