import { useEffect, useRef } from "react";
import { FloatMenuPosition } from "../functions/FloatMenuTypes";
function useDragger( id: string, style?: FloatMenuPosition): void {

    const isClicked = useRef<boolean>(false);

    const coordinates = useRef<{
        startX: number,
        startY: number
        lastX: number,
        lastY: number,
        startTargetX: number,
        startTargetY: number
    }>({
        startX: 0,
        startY: 0,
        lastX: 0,
        lastY: 0,
        startTargetX: 0,
        startTargetY: 0
    })
    useEffect(() => {
        const target = document.getElementById(id);
        if (!target) throw new Error("Element with given ID does not exist.");

        if (style) {
            if (style.yPos) {
                target.style.top = `${style.yPos}${style.yUnit||"px"}`;
            }
            if (!style.xAlign || style.xAlign === 'left') {
                if ( style.xPos ) {
                    target.style.left = `${style.xPos}${style.xUnit||"px"}`;
                }
            } else if ( style.xPos) {
                target.style.left = `${window.innerWidth - style.xPos}${style.xUnit||"px"}`
            }
        }

        const bounds = target.parentElement;
        if (!bounds) throw new Error("Target element must be wrapped in a boundary container.");

        const updateTargetStartCoordinates = () => {
            coordinates.current.startTargetX = target.offsetLeft;
            coordinates.current.startTargetY = target.offsetTop;
        }
        
        const onMouseDown = (e: PointerEvent) => {
            isClicked.current = true;
            
            coordinates.current.startX = e.clientX;
            coordinates.current.startY = e.clientY;
            updateTargetStartCoordinates();
            //target.style.transition = "none";
        }
        const onMouseUp = () => {
            isClicked.current = false;
            // Stick the target where it is left
            coordinates.current.lastX = target.offsetLeft;
            coordinates.current.lastY = target.offsetTop;
            //snapToSide(e);
            if (coordinates.current.lastX < window.innerWidth / 2) {
                target.classList.remove('right');
                target.classList.add('left');
            } else {
                target.classList.remove('left');
                target.classList.add('right');
            }
            //target.style.transition = "0.3s ease-in-out left";
        }

        const onMouseLeave = () => {
            isClicked.current = false;
            // Stick the target where it is left
            coordinates.current.lastX = target.offsetLeft;
            coordinates.current.lastY = target.offsetTop;
        }

        const onClickActive = () => {
            if (
                coordinates.current.lastY === coordinates.current.startTargetY &&
                coordinates.current.lastX === coordinates.current.startTargetX
              ) {
                target.classList.toggle("target-active");
              }
          };

        const snapToSide = (e: PointerEvent) => {
            //if (!isClicked.current) return;
            const targetHeight= parseInt(target.style.height)
            const windowWidth = window.innerWidth;
            
            const currPositionX = e.clientX;
            const currPositionY = e.clientY;
            if(currPositionY < 50) {
             target.style.top = 50 + "px"; 
            }
            if(currPositionY > bounds.clientHeight - targetHeight) {
                target.style.top = (bounds.clientHeight - targetHeight) + "px"; 
            }
            if (currPositionX < windowWidth / 2) {
                target.style.left = 30 + "px";
                target.classList.remove('right');
                target.classList.add('left');
            } else {
                target.style.left = windowWidth - 80 + "px";
                target.classList.remove('left');
                target.classList.add('right');
            }
        };
        const onMouseMove = (e: PointerEvent) => {
            if(e.stopPropagation) e.stopPropagation();
            if(e.preventDefault) e.preventDefault();
            if (!isClicked.current) return;
            let nextX, nextY = 0
            nextX = e.clientX - coordinates.current.startX + coordinates.current.lastX;
            nextY = e.clientY - coordinates.current.startY + coordinates.current.lastY;

            target.style.top = `${nextY}px`;
            target.style.left = `${nextX}px`;


        }
        // Ensure mouse or touch action is up before drag feature take place
        onMouseUp();

        // Assign Event listeners
        target.addEventListener("pointerdown", onMouseDown)
        target.addEventListener('pointerup', onMouseUp);
        target.addEventListener('click', onClickActive);
        bounds.addEventListener('pointermove', onMouseMove);
        bounds.addEventListener('pointerleave', onMouseLeave);

        const cleanup = () => {
            //Clean event listeners when no in use
            target.removeEventListener("pointerdown", onMouseDown);
            target.removeEventListener('pointerup', onMouseUp)
            target.removeEventListener('click', onClickActive);
            bounds.removeEventListener("pointermove", onMouseMove);
            bounds.removeEventListener('pointerleave', onMouseLeave);
        }
        return cleanup;
    }, [id])
}

export default useDragger;