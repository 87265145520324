import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Divider, Grid, Header, Icon, Segment, Table } from "semantic-ui-react";
import { LoadingParagraph } from "../../../components/LoadingParagraph";
import { GetFormattedAddress } from "../../../helpers/addressHelper";
import { IOrderForList } from "../../../interfaces/order";
import { IUserProfile } from "../../../interfaces/user";
import { CheckOutOptions } from "../../../options/CheckoutTypes";
import { OrderStatusOption } from "../../../options/OrderStatusOption";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { currencyFormat } from "../../products/functions/productHelper";
import TrackOrderWidget from "../../orderMonitoring/components/TrackOrderWidget";
import OrderDetailTableRow from "./OrderDetailTableRow";

interface IProps {
  order: IOrderForList;
  user?: IUserProfile;
}

const OrderDetailWidget: React.FC<IProps> = ({ order, user }) => {
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const {
    getDetail,
    loadingDetail,
    disposeDetail,
    orderDetail,
    products
  } = context.allClientOrdersStore;

  useEffect(() => {
    getDetail(order.id);
    return () => {
      disposeDetail();
    };
  }, [getDetail, order, disposeDetail]);

  return (
    <div>
      <Segment textAlign={isMobile ? "left" : "center"} basic className="no-padding">
        <Grid columns={3} stackable>
          <Grid.Row>
            <Grid.Column className="no-padding">
              <Header color="blue" style={{ padding: "0px", margin: "0px" }}>
                {CheckOutOptions.filter((f) => f.key === order.checkoutType)[0].text}
              </Header>
              <div style={{ paddingLeft: "12px" }}>
                <div>
                  {order.checkoutType === "delivery" && (
                    <div className="fxFixed checkout-market-box" style={{ display: "inline-flex" }}>
                      <div className="fxFixed fxDisplay">
                        <Icon name="point" color="teal" />
                      </div>
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {GetFormattedAddress(order.address)}
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </Grid.Column >
            <Grid.Column className="no-padding">
              <Header
                color="blue"
                style={{ padding: "0px", margin: "0px", paddingTop: "8px" }}
              >
                Order Status
              </Header>
              <div style={{ paddingLeft: "12px" }}>
                <div className="fxFixed checkout-market-box" style={{ textAlign: "center", display: "inline-flex" }}>
                  <div className="fxFixed ">
                    <Icon name="shopping bag" color="teal" />
                  </div>
                  <div>
                    {OrderStatusOption.filter((f) => f.key === order.status)[0].text}
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column className="no-padding">
              <Header
                color="blue"
                style={{ padding: "0px", margin: "0px", paddingTop: "8px" }}
              >
                Total Cost
              </Header>
              <div style={{ paddingLeft: "12px" }}>
                <div className="fxFixed checkout-market-box" style={{ textAlign: "center", display: "inline-flex" }}>
                  <div className="fxFixed ">
                    <Icon name="euro" color="teal" />
                  </div>
                  <div>{
                    currencyFormat((orderDetail?.items.slice().reduce((a, b) => a + (b.packedAmount ?? b.quantity) * b.currentPrice, 0) ?? 0) + (orderDetail?.creditsUsed ?? 0) + (orderDetail?.serviceFee ?? 0) + (orderDetail?.deliveryFee ?? 0) + (orderDetail?.discount ?? 0), false)}
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      {orderDetail?.checkoutType === "delivery" && orderDetail?.parcelTrackingNumber !== null && orderDetail?.parcelTrackingNumber !== undefined && <Fragment>
        <Header
          color="blue"
          style={{
            padding: "0px",
            margin: "0px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          Tracking Information
        </Header>
        <Divider style={{ padding: "0px", margin: "0px" }} />
        <TrackOrderWidget orderId={order?.id} />
      </Fragment>}

      {loadingDetail && <LoadingParagraph lines={15} />}
      {!loadingDetail && orderDetail && (
        <div>
          <Table basic="very" compact>
            {!isMobile && <Table.Header>
              <Table.HeaderCell textAlign="left">Product</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Quantity</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Unit Cost</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Total Cost</Table.HeaderCell>
            </Table.Header>}
            <Table.Body>
              {orderDetail.items.map((x) => (
                <OrderDetailTableRow
                  key={x.catalogueId} item={x}
                  product={products.filter((f) => f.id === x.catalogueId)[0]}
                  replacedProduct={products.filter((f) => f.id === x.replacementFor)[0]}
                />
              ))}
            </Table.Body>
          </Table>
          <Divider />
          <div className="fxDisplay fxFixed checkout-totals-box">
            <div className="fxFixed fxStretch fxDisplay">Subtotal</div>
            <div>{currencyFormat(orderDetail.items.slice().reduce((a, b) => a + (b.packedAmount ?? b.quantity) * b.currentPrice, 0) ?? 0, true)}</div>
          </div>
          {orderDetail.discount !== undefined && orderDetail.discount !== 0 && (
            <div className="fxDisplay fxFixed checkout-totals-box">
              <div className="fxFixed fxStretch fxDisplay">Discount</div>
              <div>{currencyFormat(orderDetail.discount ?? 0, true)}</div>
            </div>
          )}

          {order.checkoutType === "delivery" && (
            <Fragment>
              <div className="fxDisplay fxFixed checkout-totals-box">
                <div className="fxFixed fxStretch fxDisplay">Delivery Fee</div>
                <div>{currencyFormat(orderDetail.deliveryFee ?? 0, true)}</div>
              </div>
            </Fragment>
          )}

          {orderDetail.serviceFee > 0 && <div className="fxDisplay fxFixed checkout-totals-box">
            <div className="fxFixed fxStretch fxDisplay">Service Fee</div>
            <div>{currencyFormat(orderDetail.serviceFee ?? 0, true)}</div>
          </div>}
          {(orderDetail.creditsUsed ?? 0) !== 0 && (
            <div className="fxDisplay fxFixed checkout-totals-box">
              <div className="fxFixed fxStretch fxDisplay">{orderDetail.creditsUsed! < 0 ? "Debt from previous order" : "Credits"}</div>
              <div>{`${(orderDetail.creditsUsed ?? 0) > 0 ? "-" : ""}${orderDetail.creditsUsed?.toFixed(2)} €`}</div>
            </div>
          )}
          <Divider />
          <div className="fxDisplay fxFixed checkout-totals-box">
            <div className="fxFixed fxStretch fxDisplay">Total</div>
            <div>{currencyFormat(
              (orderDetail?.items.slice().reduce((a, b) => a + ((b.packedAmount ?? b.quantity) * b.currentPrice), 0) ?? 0) +
              (orderDetail?.creditsUsed ?? 0) +
              (orderDetail?.serviceFee ?? 0) +
              (orderDetail?.deliveryFee ?? 0) +
              (orderDetail?.discount ?? 0), true)}</div>
          </div>
          <div className="fxDisplay fxFixed checkout-totals-box">
            <div className="fxFixed fxStretch fxDisplay">Reserved Amount</div>
            <div>{currencyFormat(orderDetail?.lockedAmount ?? 0, true)}</div>
          </div>
          <div className="fxDisplay fxFixed checkout-totals-box">
            <div className="fxFixed fxStretch fxDisplay">Charged Amount</div>
            <div>{currencyFormat(orderDetail?.capturedAmount ?? 0, true)}</div>
          </div>
          {order.notes && (
            <Fragment>
              <Divider />

              <div className="fxDisplay fxFixed checkout-totals-box">
                <div className="fxFixed fxStretch fxDisplay">Notes : </div>
                <div>{`${order.notes}`}</div>
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};
export default observer(OrderDetailWidget);
