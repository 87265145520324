import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useContext, useEffect } from "react";
import {
  Dimmer,
  Divider,
  Dropdown,
  DropdownProps,
  Icon,
  Input,
  Label,
  List,
  Loader,
  Menu,
  Segment,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { CSSTransition } from "react-transition-group";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClientOrderStatusType, OrderStatusOption } from "../../options/OrderStatusOption";
import { useNavigate } from "react-router";
import ClientOrderItem from "./components/ClientOrderItem";
import AdminClientOrderItem from "./components/AdminClientOrderItem";
import OrderDetailWidget from "./components/OrderDetailWidget";
import { NoResults } from "../../common/NoResults";

interface IProps {
  userId?: string
}

const AllClientOrders: React.FC<IProps> = ({ userId }) => {
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const {
    dispose,
    load,
    orders,
    loading,
    search,
    hasMore,
    searchText,
    statuses,
    setSelectedStatuses,
    refreshOrder,
    users,
    first_load
  } = context.allClientOrdersStore;
  const { setPage } = context.analyticsStore;
  const { isLoggedIn } = context.sessionStore;
  const { user, isAdmin } = context.userStore;
  const { isMobile } = context.deviceStore;
  const { openModal } = context.modalStore;

  const [advancedSearch, setAdvanceSearch] = React.useState(false);
  const [timer, setTimer] = React.useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isLoggedIn) {
      navigate("/");
      return;
    }

    first_load(userId);
    setPage("Orders", window.location.pathname);
    load();

    return () => {
      dispose();
    };
  }, [first_load, dispose, load, setPage, isLoggedIn, navigate]);

  return (
    <div className="simply-paddedPage">

      <Menu
        secondary
        size={isMobile ? "massive" : "small"}
        style={{ padding: "0px" }}
      >
        <Menu.Item className={!isMobile ? "no-padding" : ""} style={{ flex: isMobile ? 1 : "" }}>
          <Input
            fluid={isMobile}
            // disabled={loading || !search}
            transparent={isMobile}
            size={isMobile ? "large" : "small"}
            icon={
              !isMobile ? <Icon name="search" className="ct-green-text" /> : undefined
            }
            placeholder={"Search order number..."}
            input={
              <input className={isMobile ? "" : "ctGreenPlaceholder"} />
            }
            onChange={(event: ChangeEvent, data: any) => {
              if (!search) return;
              if (timer != null) {
                clearTimeout(timer);
                setTimer(0);
              }

              setTimer(
                setTimeout(
                  (callback: (text: string) => void, text: string) => {
                    callback(text);
                    clearTimeout(timer);
                  },
                  500,
                  search,
                  data.value
                )
              );
            }}
          />
        </Menu.Item>
        <Menu.Item
          className={!isMobile ? "no-padding" : ""}
          style={{ flex: isMobile ? 1 : "" }}
        ></Menu.Item>
        {!isMobile && (
          <Menu.Item style={{ padding: "0px" }}>
            <Dropdown
              placeholder={"Filter statuses"}
              value={statuses}
              closeOnBlur
              selection
              multiple
              clearable
              search
              renderLabel={(
                item: any,
                index: number,
                defaultLabelProps: any
              ) => {
                if (index === 0) return { content: item.text };
                if (index === 1) return { content: item.text };

                return index === 2
                  ? {
                    color: defaultLabelProps.color,
                    content: `and ${statuses.length - 2} more status(es)`,
                  }
                  : undefined;
              }}
              onChange={(ev, data: DropdownProps) => {
                setSelectedStatuses((data?.value ?? []) as ClientOrderStatusType[]);
                if (!search) return;
                if (timer != null) {
                  clearTimeout(timer);
                  setTimer(0);
                }

                setTimer(
                  setTimeout(
                    (callback: (text: string) => void, text: string) => {
                      callback(searchText);
                      clearTimeout(timer);
                    },
                    500,
                    search
                  )
                );
              }}
              options={OrderStatusOption.map((x) => ({
                key: x.key,
                text: x.text,
                value: x.value,
              }))}
            />
          </Menu.Item>
        )}
        {isMobile && (
          <Menu.Item icon style={{ margin: 0 }}>
            <Icon.Group>
              <Icon
                fitted
                onClick={() => {
                  setAdvanceSearch(!advancedSearch);
                }}
                name="filter"
                color={advancedSearch ? "blue" : "black"}
                disabled={loading}
              />
              {statuses.length > 0 && (
                <Label
                  circular
                  className="ct-green-text"
                  floating
                  size="mini"
                  style={{ marginLeft: "1px", margingBottom: "1px" }}
                  onClick={() => setAdvanceSearch(!advancedSearch)}
                >
                  {statuses.length}
                </Label>
              )}
            </Icon.Group>
          </Menu.Item>
        )}
      </Menu>
      <CSSTransition
        unmountOnExit
        in={advancedSearch}
        timeout={300}
        classNames="fluid headerWithSearchAdvancedSearch transition"
      >
        <Menu secondary compact={isMobile} widths={1}>
          <Menu.Item>
            <Dropdown
              disabled={!user}
              placeholder={"Filter statuses"}
              value={statuses}
              closeOnBlur
              selection
              multiple
              fluid
              clearable
              search
              style={{ marginLeft: "10px", marginRight: "10px" }}
              renderLabel={(
                item: any,
                index: number,
                defaultLabelProps: any
              ) => {
                if (index === 0) return { content: item.text };
                if (index === 1) return { content: item.text };

                return index === 2
                  ? {
                    color: defaultLabelProps.color,
                    content: `and ${statuses.length - 2} more status(es)`,
                  }
                  : undefined;
              }}
              onChange={(ev, data: DropdownProps) => {
                setSelectedStatuses((data?.value ?? []) as ClientOrderStatusType[]);
                if (!search) return;
                if (timer != null) {
                  clearTimeout(timer);
                  setTimer(0);
                }

                setTimer(
                  setTimeout(
                    (callback: () => void, text: string) => {
                      callback();
                      clearTimeout(timer);
                    },
                    500,
                    search
                  )
                );
              }}
              options={OrderStatusOption.map((x) => ({
                key: x.key,
                text: x.text,
                value: x.value,
              }))}
            />
          </Menu.Item>
        </Menu>
      </CSSTransition>

      <Divider />

      {
        isLoggedIn && !loading && orders.length === 0 && (
          <NoResults />
        )
      }
      {
        (!loading || orders.length > 0) && isLoggedIn ? (
          <div style={{ overflowY: "hidden" }}>
            <InfiniteScroll
              style={{ overflow: "hidden" }}
              dataLength={orders.length} //This is important field to render the next data
              next={() => load()}
              hasMore={hasMore}
              loader={loading ? <h4>Loading...</h4> : <div />}
            >
              <List
                divided
                selection
                relaxed
                style={isMobile ? { padding: "7px" } : undefined}
              >
                {orders.map((x, i) =>
                  isAdmin ? (
                    <AdminClientOrderItem
                      key={x.id}
                      order={x}
                      openModal={openModal}
                      refresh={refreshOrder}
                      user={users?.filter((f) => f.id === x.userId)[0]}
                      onClick={() => {
                        openModal(
                          <OrderDetailWidget
                            order={x}
                            user={users?.filter((f) => f.id === x.userId)[0]}
                          />,
                          "large",
                          `Order No.${x.displayId}`,
                          true,
                          false
                        );
                      }}
                    />
                  ) : (
                    <ClientOrderItem
                      key={x.id}
                      order={x}
                      onClick={() => {
                        openModal(
                          <OrderDetailWidget order={x} />,
                          "large",
                          `Order No.${x.displayId}`,
                          true,
                          false
                        );
                      }}
                    />
                  )
                )}
              </List>
            </InfiniteScroll>
          </div>
        ) : (
          isLoggedIn && (
            <Segment
              style={{
                minHeight: "55vh",
                boxShadow: "none",
                border: "none",
              }}
            >
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            </Segment>
          )
        )
      }
    </div >
  );
};

export default observer(AllClientOrders);
