import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useContext, useEffect } from "react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { Dimmer, Divider, Dropdown, DropdownProps, Icon, Input, List, Loader, Menu, Segment } from "semantic-ui-react";
import { LocationOrderStatusOptions } from "../../../options/LocationOrderStatusOptions";
import { DateTimePicker } from "react-widgets";
import InfiniteScroll from "react-infinite-scroll-component";
import ShopOrderItem from "./ShopOrderItem";
import { useNavigate } from "react-router";

interface IProps {
  shopId?: string;
}

const ShopOrders: React.FC<IProps> = ({ shopId }) => {
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { setPage } = context.analyticsStore;
  const { accountId } = context.userStore;
  const { isMobile } = context.deviceStore;
  const { load,
    getAllLocations,
    dispose,
    setDisplay,
    search,
    setSelectedStatuses,
    setDateFrom,
    setDateTo,
    loading,
    orders,
    hasMore,
    statuses,
    dateFrom }
    = context.shopOrderStore;

  const [timer, setTimer] = React.useState(0);
  const [valueFrom, setValueFrom] = React.useState<Date | null | undefined>();
  const [valueTo, setValueTo] = React.useState<Date | null | undefined>();

  useEffect(() => {
    setPage("MyShop-Orders", window.location.pathname);

    getAllLocations(shopId ?? accountId!);
    load(shopId ?? accountId);

    return () => {
      dispose();
    };
  }, [setPage, accountId, shopId, load, dispose, getAllLocations]);

  return (
    <div >
      <Menu
        secondary
        size={isMobile ? "massive" : "small"}
        style={{ padding: "0px" }}
      >
        <Menu.Item
          className={!isMobile ? "no-padding" : ""}
          style={{ flex: isMobile ? 1 : "" }}
        >
          <Input
            fluid={isMobile}
            disabled={loading || !search}
            transparent={isMobile}
            size={isMobile ? "large" : "small"}
            icon={
              !isMobile ? <Icon name="search" color="blue" /> : undefined
            }
            placeholder={"Search by id..."}
            input={
              <input className={isMobile ? "" : "bluePlaceholder"} />
            }
            onChange={(event: ChangeEvent, data: any) => {
              if (!search) return;
              setDisplay(data.value);

              if (timer != null) {
                clearTimeout(timer);
                setTimer(0);
              }

              setTimer(
                setTimeout(
                  (callback: (text: string) => void, text: string) => {
                    callback(text);
                    clearTimeout(timer);
                  },
                  500,
                  search,
                  data.value
                )
              );
            }}
          />
        </Menu.Item>
        <Menu.Item style={{ padding: "0px", maxWidth: "250px" }}>
          <Dropdown
            placeholder={"Filter statuses"}
            closeOnBlur
            defaultValue={statuses}
            selection
            multiple
            clearable
            search
            onChange={(ev, data: DropdownProps) => {
              setSelectedStatuses((data?.value ?? []) as string[]);
              if (!search) return;
              if (timer != null) {
                clearTimeout(timer);
                setTimer(0);
              }

              setTimer(
                setTimeout(
                  (callback: () => void, text: string) => {
                    callback();
                    clearTimeout(timer);
                  },
                  500,
                  search
                )
              );
            }}
            options={LocationOrderStatusOptions.map((x) => ({
              key: x.key,
              text: x.text2,
              value: x.value,
            }))}
          />
        </Menu.Item>
        <Menu.Item
          className={!isMobile ? "no-padding" : ""}
          style={{ flex: isMobile ? 1 : "" }}
        >
          <DateTimePicker
            value={valueFrom}
            placeholder={"Date From"}
            onChange={(date: Date | null | undefined, rawValue: string) => {
              // date?.setUTCHours(0, 0, 0, 1);
              var dateNum = date?.getTime() ?? undefined;
              setValueFrom(date);
              setDateFrom((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

              if (!search) return;
              if (timer != null) {
                clearTimeout(timer);
                setTimer(0);
              }

              setTimer(
                setTimeout(
                  (callback: () => void, text: string) => {
                    callback();
                    clearTimeout(timer);
                  },
                  1000,
                  search
                )
              );
            }}
            includeTime={true}
            timePrecision={'minutes'}
            max={new Date()}
          />
        </Menu.Item>
        <Menu.Item
          className={!isMobile ? "no-padding" : ""}
          style={{ flex: isMobile ? 1 : "" }}
        >
          <DateTimePicker
            placeholder={"Date To"}
            value={valueTo}
            onChange={(date: Date | null | undefined, rawValue: string) => {
              // date?.setUTCHours(23, 59, 59, 999);
              setValueTo(date);
              var dateNum = date?.getTime() ?? undefined;
              setDateTo((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

              if (!search) return;
              if (timer != null) {
                clearTimeout(timer);
                setTimer(0);
              }

              setTimer(
                setTimeout(
                  (callback: () => void, text: string) => {
                    callback();
                    clearTimeout(timer);
                  },
                  500,
                  search
                )
              );
            }}
            includeTime={true}
            timePrecision={'minutes'}
            max={new Date()}
            min={dateFrom ? new Date(dateFrom * 1000) : undefined}
          />
        </Menu.Item>
      </Menu>
      <Divider />
      {!loading || orders.length > 0 ? (
        <div>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={orders.length} //This is important field to render the next data
            next={() => load(shopId ?? accountId)}
            hasMore={hasMore}
            loader={loading ? <h4>Loading...</h4> : <div />}
          >
            <List
              divided
              relaxed
              selection
              style={isMobile ? { padding: "7px" } : undefined}
            >
              {orders.map((x) => (
                <ShopOrderItem
                  key={x.id}
                  order={x}
                  onClick={() => {
                    navigate(`/my-shop/${x.locationId}/orders/${x.id}?displayId=${x.displayId}`)
                  }}
                />
              ))}
            </List>
          </InfiniteScroll>
        </div>
      )
        : (
          <Segment>
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
    </div>
  );
};

export default observer(ShopOrders);
