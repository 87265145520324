import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import arrayMutators from "final-form-arrays";
import React, { Fragment, useContext, useEffect } from "react";
import {
    Dimmer,
    Divider,
    Loader,
    Menu,
    Segment,
    Button,
    Form,
    Checkbox,
} from "semantic-ui-react";
import { LoadingParagraph } from "../../components/LoadingParagraph";
import ErrorMessage from "../../form/ErrorMessage";
import { RootStoreContext } from "../../stores/RootStoreContext";
import TextInput from "../../form/TextInput";
import SelectInput from "../../form/SelectInput";
import { combineValidators } from "revalidate";
import CheckBox from "../../form/CheckBox";
import { LabelStat } from "../../interfaces/labelStats";
import Products from "../products/Products";

const validate = combineValidators({
    //   title: isRequired(""),
});

interface IProps {
    editLabel?: LabelStat
}

const LabelsDictionary: React.FC<IProps> = ({ editLabel }) => {
    const context = useContext(RootStoreContext);
    const {
        loadNext,
        dispose,
        loading,
        label,
        count,
        save,
        setReviewed,
    } = context.labelStatStore;

    useEffect(() => {
        loadNext(editLabel);
        return () => {
            dispose();
        };
    }, [loadNext, dispose, editLabel]);

    return (
        <div>
            <Segment>
                {loading && (
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                )}

                <Fragment>
                    <Menu secondary>

                        <Menu.Item>
                            <Checkbox
                                label="Reviewed"
                                toggle
                                onChange={(e, data) => {
                                    setReviewed(data.checked ?? false);
                                    loadNext();
                                }}
                            />
                        </Menu.Item>
                        <Menu.Menu position="right">
                            <Menu.Item>{loading ? "---" : <b>{count}</b>} op</Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    <Divider />
                </Fragment>

                {label && (
                    <FinalForm
                        onSubmit={(values: LabelStat) =>
                            save(values).catch((error: any) => ({
                                [FORM_ERROR]: error,
                            }))
                        }
                        initialValues={label}
                        validate={validate}
                        mutators={{
                            ...arrayMutators,
                        }}
                        render={({
                            handleSubmit,
                            submitError,
                            dirtySinceLastSubmit,
                            submitting,
                            form,
                            values,
                            form: {
                                mutators: { push, remove },
                            },
                        }) => {
                            (window as any).groupForm = form;

                            return (
                                <Form onSubmit={handleSubmit} error>
                                    <div>

                                        <div
                                            className="fxDirCol fxStretch fxDisplay"
                                            style={{ padding: "7px" }}
                                        >
                                            <Form.Group widths="equal">
                                                <Form.Field>
                                                    <label style={{ fontSize: "13px" }}>{"Stems"}</label>
                                                    <Field
                                                        loading={loading}
                                                        multiselect
                                                        component={SelectInput}
                                                        allowEdit={false}
                                                        options={
                                                            values.stems.map((x: string) => ({
                                                                key: x,
                                                                value: x,
                                                                text: x,
                                                            })) ?? []
                                                        }
                                                        name="stems"
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label style={{ fontSize: "13px" }}>{"Originals"}</label>
                                                    <Field
                                                        loading={loading}
                                                        multiselect
                                                        component={SelectInput}
                                                        allowEdit={false}
                                                        options={
                                                            values.originals.map((x: string) => ({
                                                                key: x,
                                                                value: x,
                                                                text: x,
                                                            })) ?? []
                                                        }
                                                        name="originals"
                                                    />
                                                    <a
                                                        className="grey"
                                                        href={`https://translate.google.com/?sl=auto&tl=en&text=${label.originals[0]}&op=translate`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Google Translate
                                                    </a>
                                                </Form.Field>
                                                <Form.Field>
                                                    <label style={{ fontSize: "13px" }}>{"Translations"}</label>
                                                    <Field
                                                        loading={loading}
                                                        multiselect
                                                        component={SelectInput}
                                                        allowAdditions={true}
                                                        onAddItem={(item: any) => console.log(item)}
                                                        options={
                                                            values.translations?.map((x: string) => ({
                                                                key: x,
                                                                value: x,
                                                                text: x,
                                                            })) ?? []
                                                        }
                                                        name="translations"
                                                    />
                                                </Form.Field>
                                                <Form.Field style={{ textAlign: "left" }}>
                                                    <label style={{ fontSize: "13px" }}>
                                                        {"Convert To"}
                                                    </label>
                                                    <Field
                                                        loading={loading}
                                                        multiselect
                                                        component={SelectInput}
                                                        allowAdditions={true}
                                                        onAddItem={(item: any) => console.log(item)}
                                                        options={
                                                            values.convert_to?.map((x: string) => ({
                                                                key: x,
                                                                value: x,
                                                                text: x,
                                                            })) ?? []
                                                        }
                                                        name="convert_to"
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                            <Form.Group widths="equal">
                                                <Form.Field style={{ textAlign: "center" }}>
                                                    <label style={{ fontSize: "13px" }}>
                                                        {"Organic"}
                                                    </label>
                                                    {loading ? (
                                                        <LoadingParagraph />
                                                    ) : (
                                                        <Field
                                                            type="checkbox"
                                                            loading={loading}
                                                            component={CheckBox}
                                                            toggle
                                                            name="organic"
                                                        />
                                                    )}
                                                </Form.Field>
                                                <Form.Field style={{ textAlign: "center" }}>
                                                    <label style={{ fontSize: "13px" }}>
                                                        {"Gluten Free"}
                                                    </label>
                                                    {loading ? (
                                                        <LoadingParagraph />
                                                    ) : (
                                                        <Field
                                                            type="checkbox"
                                                            loading={loading}
                                                            component={CheckBox}
                                                            toggle
                                                            name="glutenf"
                                                        />
                                                    )}
                                                </Form.Field>
                                                <Form.Field style={{ textAlign: "center" }}>
                                                    <label style={{ fontSize: "13px" }}>
                                                        {"Lactose Free"}
                                                    </label>
                                                    {loading ? (
                                                        <LoadingParagraph />
                                                    ) : (
                                                        <Field
                                                            type="checkbox"
                                                            loading={loading}
                                                            component={CheckBox}
                                                            toggle
                                                            name="lactosef"
                                                        />
                                                    )}
                                                </Form.Field>
                                                <div>
                                                    <Form.Field style={{ textAlign: "center" }}>
                                                        <label style={{ fontSize: "13px" }}>
                                                            {"Is Brand"}
                                                        </label>
                                                        {loading ? (
                                                            <LoadingParagraph />
                                                        ) : (
                                                            <Field
                                                                type="checkbox"
                                                                loading={loading}
                                                                component={CheckBox}
                                                                onChange={(e: any) => {
                                                                    console.log("tralala")
                                                                    var val = values.stems[0].toString().match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
                                                                        .map((x: any) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
                                                                        .join(' ');

                                                                    form.change("brand", e.target.checked ?? false ? val : undefined)
                                                                }}
                                                                toggle
                                                                name="isbrand"
                                                            />
                                                        )}
                                                    </Form.Field>
                                                    <Form.Field style={{ textAlign: "left" }}>
                                                        <label style={{ fontSize: "13px" }}>
                                                            {"Brand Name"}
                                                        </label>
                                                        <Field
                                                            loading={loading}
                                                            disabled={values.isbrand !== true}
                                                            component={TextInput}
                                                            name="brand"
                                                            placeholder={"Brand"}
                                                        />
                                                    </Form.Field>
                                                </div>
                                            </Form.Group>
                                            <Form.Group widths="equal">
                                                <Form.Field style={{ textAlign: "center" }}>
                                                    <label style={{ fontSize: "13px" }}>
                                                        {"Ignore"}
                                                    </label>
                                                    {loading ? (
                                                        <LoadingParagraph />
                                                    ) : (
                                                        <Field
                                                            type="checkbox"
                                                            loading={loading}
                                                            component={CheckBox}
                                                            toggle
                                                            name="ignore"
                                                        />
                                                    )}
                                                </Form.Field>
                                                <Form.Field style={{ textAlign: "center" }}>
                                                    <label style={{ fontSize: "13px" }}>
                                                        {"Reviewed"}
                                                    </label>
                                                    {loading ? (
                                                        <LoadingParagraph />
                                                    ) : (
                                                        <Field
                                                            type="checkbox"
                                                            loading={loading}
                                                            component={CheckBox}
                                                            toggle
                                                            name="reviewed"
                                                        />
                                                    )}
                                                </Form.Field>
                                            </Form.Group>

                                        </div>
                                    </div>
                                    {
                                        submitError && !dirtySinceLastSubmit && (
                                            <ErrorMessage error={submitError} />
                                        )
                                    }

                                    <div style={{ textAlign: "center" }}>
                                        <Button
                                            color="blue"
                                            loading={submitting}
                                            content={"Save"}
                                        />
                                    </div>
                                </Form>
                            );
                        }}
                    />
                )}
                <Divider />
                <Products stems={(label ?? editLabel)?.originals[0] ?? undefined} />
            </Segment>
        </div >
    );
};

export default observer(LabelsDictionary);
