import { action, computed, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { getCustomFormattedDate } from "../../../helpers/dateFormats";
import { IAssociationForView } from "../../../interfaces/association";
import { IProductPriceHistoryFlaten } from "../../../interfaces/product";
import { RootStore } from "../../../stores/RootStoreContext";
import { currencyFormat } from "../../products/functions/productHelper";

export class AssociationViewStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable loading: boolean = true;
    @observable item?: IAssociationForView = undefined;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setItem = (value?: IAssociationForView) => this.item = value;

    @action dispose = () => {
        this.loading = false;
        this.setItem(undefined);
    }

    @action oneTimeLoad = async () => {
        try {
            const { markets, setMarkets } = this.rootStore.cacheStore;
            if (markets.length !== 0) return;

            agent.Supermarkets.get().then((markets) => setMarkets(markets));
        } catch (error) {
            // toast.error("Oops, it looks like we are facing an issue. ")
        }
    }

    @action load = async (id: string) => {
        try {
            this.setLoading(true)

            this.oneTimeLoad();

            var item = await agent.Associations.getDetailView(id, false);
            this.setItem(item);
        } catch (error) {
            // toast.error("Oops, it looks like we are facing an issue. ")
        }
        finally {
            this.setLoading(false)
        }
    }

    @computed get priceHistoryArray() {
        if (!this.item || this.loading) return [];

        var retval: any[] = [];
        var allVariations = this.item.products.map(x => x.priceHistory.map(z => ({ ...z, 'marketId': x.marketId })))

        var merged: IProductPriceHistoryFlaten[] = [].concat.apply([], allVariations as any);

        var sortedVariations = merged.slice()
            .sort((a, b) => (a.untilDateNum < b.untilDateNum ? -1 : 1));


        sortedVariations.forEach(x => {
            if (retval.filter(f => f.name === x.untilDate).length > 0) return;

            var propName: string = `${x.marketId}`
            var entry: any = {
                name: x.untilDate,
            }
            entry[propName] = currencyFormat(x.priceBefore, false)

            this.item?.products.filter(f => f.marketId !== x.marketId).forEach(z => {
                var prices = z.priceHistory?.filter(f => f.untilDateNum === entry.name);
                var price = prices.length > 0 ? prices[0].priceBefore : z.currentPrice;
                var propName: string = `${z.marketId}`
                entry[propName] = currencyFormat(price, false)
            });

            retval.push(entry);
        });

        var lastDate = getCustomFormattedDate(new Date());

        if (retval.filter(f => f.name === lastDate).length === 0) {
            var lastEntry: any = {
                name: getCustomFormattedDate(new Date())
            }

            this.item.products.forEach(x => {
                var propName: string = `${x.marketId}`
                lastEntry[propName] = currencyFormat(x.currentPrice, false)
            });

            retval.push(lastEntry);
        }

        return retval;
    }

}