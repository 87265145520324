import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IShopForIntegration } from "../../../interfaces/shopIntegration";
import { RootStore } from "../../../stores/RootStoreContext";

export class IntegrationRequestStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable loading: boolean = true;
    @observable editingItem?: IShopForIntegration = undefined;

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setEditingItem = (value: IShopForIntegration) => { this.editingItem = value };

    @action load = async (shopId?: string) => {
        this.setLoading(true)
        try {
            var integration = await agent.Shop.Integration.getMine();

            this.setEditingItem(integration);

        } catch (error) {

        }
        finally {
            this.setLoading(false)
        }
    }

    @action openIntegrationRequest = async (values: IShopForIntegration) => {
        return agent.Shop.Integration.request(values).then((id) => this.setEditingItem({ ...values, id: id, createdOn: (new Date().getTime()) / 1000, status: "pending_approval" }));
    }

    @action dispose = () => {
        this.loading = false;
        this.editingItem = undefined;
    }

}