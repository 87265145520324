import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router';
import { Button, Dropdown, Icon, Message, Segment, Statistic, StatisticLabel, StatisticValue, Table } from 'semantic-ui-react';
import { OrderPreparationStore } from '../functions/orderPreparationStore';
import { OrderStatusOption } from '../../../options/OrderStatusOption';
import { Localities } from '../../../options/Localities';
import { Countries } from '../../../options/Countries';
import OrderPreparationTableRow from './OrderPreparationTableRow';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import AskForBoxes from './AskForBoxes';
import TrackOrderWidget from './TrackOrderWidget';
import ChangeOrderVivaTransaction from './ChangeOrderVivaTransaction';
import { currencyFormat } from '../../products/functions/productHelper';
import { PaymentOptions } from '../../../options/PaymentOptions';

const OrderPreparation = () => {
    const navigate = useNavigate();
    let { id } = useParams<"id">();

    const context = useContext(RootStoreContext);
    const { openModal, closeModal } = context.modalStore;


    const {
        order,
        load,
        loading,
        dispose,
        totalCost,
        updatingOrder,
        updateOrderStatus,
        updatePackedProduct,
        refundProduct,
        updatingProduct,
        undoProduct,
        getNextStep,
        getTrackingNumber,
        updateTransactionId,
        isNextStepActive,
        getTrackingNumberEnabled,
        printShippingLabel,
        toggleHasImage,
        setPaymentType
    } = OrderPreparationStore;


    useEffect(() => {
        if (!id) return;

        load(id);

        return () => {
            dispose();
        }
    }, [load, dispose, id])

    return (
        <div className='simply-paddedPage'>
            <Segment basic>
                <div className='fxDisplay fxFixed fxJustifyBetween'>
                    {order?.address && <Statistic>
                        <StatisticLabel>Address</StatisticLabel>
                        <div>{order.address.addressLine1}</div>
                        <div>{order.address.addressLine2}</div>
                        <div>{`${Localities.filter(f => f.value === order.address.localityId)[0].text}, ${Countries.filter(f => f.value === order.address.countryId)[0].text}`}</div>
                    </Statistic>}
                    {order?.user !== undefined && <Statistic onDoubleClick={() => navigate(`/manage-user/${order.user.id}`)}>
                        <StatisticLabel>Client</StatisticLabel>
                        <div><Icon name="user outline" />{`${order.user.firstName} ${order.user.middleName ? `[${order.user.middleName}]` : ''} ${order.user.lastName}`}</div>
                        <div><Icon name="mail outline" /><a target="_blank" rel="noopener noreferrer" href={`mailto:${order.user.email}?subject=${encodeURIComponent(`Cheap Trolley Order - ${order.displayId}`)}`}> {order.user.email}</a></div>
                        <div><Icon name="phone" /><a target="_blank" rel="noopener noreferrer" href={`tel:${order.user.phoneNumber}`}> {order.user.phoneNumber}</a></div>
                    </Statistic>}
                    <Statistic>
                        <StatisticLabel>Order Id</StatisticLabel>
                        <StatisticValue>{order?.displayId}</StatisticValue>
                        <StatisticLabel
                            className={`clickable ${(order?.transactionId !== undefined && order?.transactionId !== null) ? "ct-green-text-dark" : "redColor"}`}
                            onClick={() => openModal(<ChangeOrderVivaTransaction submit={updateTransactionId} />, "mini", "VivaWallet Transaction ID", true, false)}
                        ><Icon name="share square outline" />{order?.vivaPaymentCode}</StatisticLabel>
                        <StatisticLabel>{OrderStatusOption.filter(f => f.value === order?.status)[0]?.text}</StatisticLabel>
                    </Statistic>
                    <Statistic size='small' color={totalCost === order?.capturedAmount ? "green" : "red"}>
                        <StatisticLabel>Total</StatisticLabel>
                        <StatisticValue>{currencyFormat(totalCost)}</StatisticValue>
                        <Statistic.Group size='mini'>
                            <Statistic size='small' color="green">
                                <StatisticLabel>PAID</StatisticLabel>
                                <StatisticValue>{currencyFormat(order?.amount ?? 0)}</StatisticValue>
                            </Statistic>
                            <Statistic size='small' color={totalCost === order?.capturedAmount ? "green" : "red"}>
                                <StatisticLabel>Captured</StatisticLabel>
                                <StatisticValue>{currencyFormat(order?.capturedAmount ?? 0)}</StatisticValue>
                            </Statistic>
                        </Statistic.Group>
                    </Statistic>
                    {order?.checkoutType === "delivery" && <Statistic size='mini'>
                        <StatisticLabel>Tracking No</StatisticLabel>
                        {order?.parcelTrackingNumber === null ? <Button
                            loading={updatingOrder}
                            disabled={updatingOrder || !getTrackingNumberEnabled()}
                            color={'orange'}
                            content={"Get Tracking No"}
                            onClick={() => openModal(<AskForBoxes initialValues={order.boxes} submit={getTrackingNumber} />, "mini", "No of Boxes", true, false)}
                        /> : <Fragment>
                            <StatisticValue>{order?.parcelTrackingNumber}</StatisticValue>
                            {order?.shippingLabel && <Button fluid content="Print Label" color='blue' style={{ marginTop: "7px" }} onClick={() => printShippingLabel(openModal, closeModal, order.shippingLabel!)} />}
                            {order?.parcelTrackingNumber && <Button fluid content="Track" color='brown' style={{ marginTop: "7px" }} onClick={() => openModal(<TrackOrderWidget orderId={order?.id} />, "mini", `Tracking info for ${order?.parcelTrackingNumber}`, true, false)} />}
                        </Fragment>}
                    </Statistic>}
                    {order && <div className='fxDisplay fxDirCol'>
                        <Button
                            className='fxStretch'
                            loading={updatingOrder}
                            disabled={updatingOrder || !isNextStepActive}
                            color={OrderStatusOption.filter(f => f.value === order?.status)[0]?.color}
                            content={getNextStep}
                            onClick={() => updateOrderStatus()}
                        />
                        {!order.transactionId &&
                            <Dropdown style={{ marginTop: "6px" }}
                                placeholder='Paid by...'
                                search
                                selection
                                options={PaymentOptions}
                                onChange={(e, data) => {
                                    setPaymentType(data.value as string)
                                }}
                            />}
                    </div>}

                </div>
            </Segment >
            {order?.notes && <Message header={"Customer Notes"} content={order.notes} warning size='big' />}
            <Segment loading={loading} basic>
                <Table basic="very">
                    <Table.Header>
                        <Table.HeaderCell>Product</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Status</Table.HeaderCell>
                        <Table.HeaderCell textAlign='left'>Pricing</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Quantities</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Action</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {order && order.products && order.products.slice().sort((a, b) => a.catalogueDesc > b.catalogueDesc ? 1 : -1).map((x) =>
                            <OrderPreparationTableRow
                                key={x.catalogueId}
                                item={x}
                                orderClosed={order.status === "completed" || order.status === "ready_for_delivery" || order.status === "ready_for_pickup" || order.status === "out_for_delivery"}
                                undoProduct={undoProduct}
                                updatePackedProduct={updatePackedProduct}
                                refundProduct={refundProduct}
                                updatingProduct={updatingProduct}
                                toggleHasImage={toggleHasImage}
                            />
                        )}
                    </Table.Body>
                </Table>
            </Segment>
        </div >
    )
}

export default observer(OrderPreparation);