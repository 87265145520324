export const compartments: KeyValuePair[] = [
    {
        key: "frozen",
        value: "frozen",
        text: "Frozen",
    },
    {
        key: "dry",
        value: "dry",
        text: "Dry",
    },
    {
        key: "chill",
        value: "chill",
        text: "Chill",
    },
]

interface KeyValuePair {
    key: string;
    value: string;
    text: string;
}