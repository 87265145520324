import { observer } from "mobx-react-lite";
import React, { ChangeEvent, Fragment, useContext, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import {
  Card,
  Divider,
  Menu,
  Input,
  Icon,
  Message,
  Button,
  Segment,
  Dimmer,
  Loader,
  Dropdown,
  DropdownProps,
  Label,
  Pagination,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import {
  findCheaper,
} from "../products/functions/productHelper";
import { requestUserSession } from "../registration/functions/sessionFnc";
import AssociationCard from "./AssociationCard";
import { IAssociation } from "../../interfaces/association";
import { useLocation } from "react-router";
import { NoResults } from "../../common/NoResults";

interface IProps {
  mode?: "stabbilita_select" | "select" | "group_select" | "group_merge" | "temporary" | undefined;
}

const Associations: React.FC<IProps> = ({ mode }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let id = searchParams.get('id');

  const context = useContext(RootStoreContext);
  const { isLoggedIn } = context.sessionStore;
  const { openModal, closeModal } = context.modalStore;
  const { isMobile } = context.deviceStore;
  const { user, isAdmin } = context.userStore;
  const {
    dispose,
    load,
    loading,
    associations,
    search,
    currentPage,
    searchText,
    selectedMarketIds,
    setSelectedMarketIds,
    discontinued,
    setDiscontinued,
    totalPages,
    pageChange
  } = context.associationStore;
  const { markets } = context.cacheStore;

  const [timer, setTimer] = React.useState(0);
  const [advancedSearch, setAdvanceSearch] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isLoggedIn) return;

    if (mode !== "select" && mode !== "group_select") load(id ?? undefined, mode);

    return () => {
      dispose();
    };
  }, [dispose, load, isLoggedIn, mode, id]);

  return (
    <div className="simply-paddedPage">
      <div>
        <Menu
          secondary
          size={isMobile ? "massive" : "small"}
          style={{ margin: "0 0 5px 0" }}
        >
          <Menu.Item
            className={!isMobile ? "no-padding" : ""}
            style={{ flex: isMobile ? 1 : "" }}
          >
            <Input
              fluid={isMobile}
              transparent={isMobile}
              size={isMobile ? "large" : "small"}
              icon={!isMobile ? <Icon name="search" className="ct-green-text" /> : undefined}
              placeholder={"Search..."}
              input={<input className={isMobile ? "" : "ctGreenPlaceholder"} />}
              onChange={(event: ChangeEvent, data: any) => {
                if (!search) return;
                if (timer != null) {
                  clearTimeout(timer);
                  setTimer(0);
                }

                setTimer(
                  setTimeout(
                    (callback: (text: string) => void, text: string) => {
                      callback(text);
                      clearTimeout(timer);
                    },
                    500,
                    search,
                    data.value
                  )
                );
              }}
            />
          </Menu.Item>
          {!isMobile && mode !== "select" && mode !== "group_select" && (
            <Menu.Item style={{ padding: "0px" }}>
              <Dropdown
                placeholder={
                  !user ? "Sign in to filter stores" : "Filter stores"
                }
                value={selectedMarketIds}
                closeOnBlur
                selection
                multiple
                clearable
                search
                renderLabel={(
                  item: any,
                  index: number,
                  defaultLabelProps: any
                ) => {
                  if (index === 0) return { content: item.text };
                  if (index === 1) return { content: item.text };

                  return index === 2
                    ? {
                      color: defaultLabelProps.color,
                      content: `and ${selectedMarketIds.length - 2
                        } more supermarket(s)`,
                    }
                    : undefined;
                }}
                onChange={(ev, data: DropdownProps) => {
                  setSelectedMarketIds((data?.value ?? []) as string[]);
                  if (!search) return;
                  if (timer != null) {
                    clearTimeout(timer);
                    setTimer(0);
                  }

                  setTimer(
                    setTimeout(
                      (callback: (text: string) => void, text: string) => {
                        callback(searchText);
                        clearTimeout(timer);
                      },
                      500,
                      search
                    )
                  );
                }}
                options={markets?.map((x) => ({
                  key: x.type_id,
                  text: x.title,
                  value: x.type_id,
                  disabled: user === undefined,
                }))}
              />
            </Menu.Item>
          )}
          <Menu.Item className={!isMobile ? "no-padding" : ""}
            style={{ flex: isMobile ? 1 : "" }}>
            <Button
              color={discontinued ? "blue" : "red"}
              basic={discontinued}
              content={"Include Discontinued"}
              onClick={() => {
                setDiscontinued(!discontinued);
                search(searchText);
              }}
            />
          </Menu.Item>
          {isMobile && (
            <Fragment>
              <Menu.Item icon style={{ margin: 0 }}>
                <Icon.Group>
                  <Icon
                    fitted
                    onClick={() => {
                      setAdvanceSearch(!advancedSearch);
                    }}
                    name="filter"
                    color={advancedSearch ? "teal" : "black"}
                    disabled={loading}
                  />
                  {selectedMarketIds.length > 0 && (
                    <Label
                      circular
                      color="orange"
                      floating
                      size="mini"
                      style={{ marginLeft: "1px", margingBottom: "1px" }}
                      onClick={() => setAdvanceSearch(!advancedSearch)}
                    >
                      {selectedMarketIds.length}
                    </Label>
                  )}
                </Icon.Group>
              </Menu.Item>
            </Fragment>
          )}
        </Menu>
        <CSSTransition
          unmountOnExit
          in={advancedSearch}
          timeout={300}
          classNames="fluid headerWithSearchAdvancedSearch transition"
        >
          <Menu secondary compact={isMobile} vertical style={{ display: 'table-cell' }}>
            <Menu.Item>
              <Dropdown
                placeholder={
                  !user ? "Sign in to filter stores" : "Filter stores"
                }
                value={selectedMarketIds}
                closeOnBlur
                selection
                multiple
                fluid
                clearable
                search
                style={{ marginLeft: "10px", marginRight: "10px" }}
                renderLabel={(
                  item: any,
                  index: number,
                  defaultLabelProps: any
                ) => {
                  if (index === 0) return { content: item.text };
                  if (index === 1) return { content: item.text };

                  return index === 2
                    ? {
                      color: defaultLabelProps.color,
                      content: `and ${selectedMarketIds.length - 2
                        } more supermarket(s)`,
                    }
                    : undefined;
                }}
                onChange={(ev, data: DropdownProps) => {
                  setSelectedMarketIds((data?.value ?? []) as string[]);
                  if (!search) return;
                  if (timer != null) {
                    clearTimeout(timer);
                    setTimer(0);
                  }

                  setTimer(
                    setTimeout(
                      (callback: (text: string) => void, text: string) => {
                        callback(searchText);
                        clearTimeout(timer);
                      },
                      500,
                      search
                    )
                  );
                }}
                options={markets?.map((x) => ({
                  key: x.type_id,
                  text: x.title,
                  value: x.type_id,
                  disabled: user === undefined,
                }))}
              />
            </Menu.Item>
          </Menu>
        </CSSTransition>
      </div>
      <Divider />

      {!loading && !isLoggedIn && (
        <div>
          <div style={{ padding: "5px" }}>
            <Message
              icon="user"
              info
              header="A free account is required to access this page!"
              content={
                <div>
                  {
                    "Hi there, thank you for being here, we have already grouped many products for your convenience to see which grocery shop has the cheapest but you need to create a free account. Your account will always be free and we will not ask you for any money!"
                  }
                  <Divider hidden />
                  <div style={{ textAlign: "center" }} >
                    <Button
                      className="ct-green-button-active"
                      content="Sign In"
                      onClick={() => {
                        requestUserSession(
                          isMobile,
                          openModal,
                          closeModal,
                          () => {
                            closeModal();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      )}
      {(!loading || associations.length > 0) &&
        markets.length > 0 &&
        isLoggedIn ? (
        <div style={{ overflowY: "hidden" }}>
          <div style={{ textAlign: "center" }}>
            <Pagination
              pointing
              secondary
              activePage={currentPage}
              boundaryRange={1}
              onPageChange={(e, v) => pageChange((v.activePage ?? 0) as number)}
              siblingRange={1}
              totalPages={totalPages}
              firstItem={currentPage === 1 ? {
                disabled: true,
                type: "firstItem",
                icon: "double angle left",
                content: ""
              } : {
                type: "firstItem",
                icon: "double angle left",
                content: ""
              }}
              prevItem={currentPage === 1 ? {
                disabled: true,
                type: "firstItem",
                icon: "angle left",
                content: ""
              } : {
                type: "firstItem",
                icon: "angle left",
                content: ""
              }}
              nextItem={currentPage === totalPages ? {
                disabled: true,
                type: "nextItem",
                icon: "angle right",
                content: ""
              } : {
                type: "nextItem",
                icon: "angle right",
                content: ""
              }}
              lastItem={currentPage === totalPages ? {
                disabled: true,
                type: "lastItem",
                icon: "double angle right",
                content: ""
              } : {
                type: "lastItem",
                icon: "double angle right",
                content: ""
              }}
            />
          </div>

          {loading && <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>}

          {associations.length === 0 ? (loading ? <Fragment /> : <NoResults />) : <Card.Group
            centered
            className="productPage"
            itemsPerRow={isMobile || isAdmin ? 1 : undefined}
            style={{ padding: "5px" }}
          >
            {(associations as IAssociation[])
              .filter((f) => f.products && f.products.length > 0)
              .map((x, i) =>
                isAdmin ? (
                  <AssociationCard
                    key={x.id}
                    association={x}
                    cheapest={findCheaper(selectedMarketIds, x.products)}
                    mode={mode}
                  />
                ) : <div />
              )}
          </Card.Group>}

          <div style={{ textAlign: "center" }}>
            <Pagination
              pointing
              secondary
              activePage={currentPage}
              boundaryRange={1}
              onPageChange={(e, v) => pageChange((v.activePage ?? 0) as number)}
              siblingRange={1}
              totalPages={totalPages}
              firstItem={currentPage === 1 ? {
                disabled: true,
                type: "firstItem",
                icon: "double angle left",
                content: ""
              } : {
                type: "firstItem",
                icon: "double angle left",
                content: ""
              }}
              prevItem={currentPage === 1 ? {
                disabled: true,
                type: "firstItem",
                icon: "angle left",
                content: ""
              } : {
                type: "firstItem",
                icon: "angle left",
                content: ""
              }}
              nextItem={currentPage === totalPages ? {
                disabled: true,
                type: "nextItem",
                icon: "angle right",
                content: ""
              } : {
                type: "nextItem",
                icon: "angle right",
                content: ""
              }}
              lastItem={currentPage === totalPages ? {
                disabled: true,
                type: "lastItem",
                icon: "double angle right",
                content: ""
              } : {
                type: "lastItem",
                icon: "double angle right",
                content: ""
              }}
            />
          </div>
        </div>
      ) : (
        isLoggedIn && (
          <Segment
            style={{
              minHeight: "55vh",
              boxShadow: "none",
              border: "none",
            }}
          >
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Segment>
        )
      )}
    </div>
  );
};

export default observer(Associations);
