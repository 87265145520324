import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Container, Grid, Icon, Message, Image } from "semantic-ui-react";
import agent from "../../api/agent";

const ActivateUser = () => {
  let { id } = useParams<"id">();
  let { token } = useParams<"token">();
  const navigate = useNavigate();
  const [processResult, setProcessResult] = React.useState(1);

  useEffect(() => {
    if (token && id) {
      agent.Auth.activate(id, token)
        .then(() => {
          setProcessResult(0);
          setTimeout(() => navigate("/"), 3000);
        })
        .catch((error: any) => {
          // console.log(error);
          setProcessResult(2);
        });
    } else {
      navigate("/notFound");
    }
  }, [id, token, navigate]);

  return (
    <Grid stackable centered style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Row columns={1}>
        <Grid.Column width={5}>
          <Container>
            <Image
              src="/early.svg"
              alt="Cheap Trolley Logo"
              size="medium"
              centered
            />
          </Container>
          <Message
            warning={processResult === 1}
            negative={processResult === 2}
            positive={processResult === 0}
            icon={
              <Icon
                loading={processResult === 1}
                name={
                  processResult === 1
                    ? "circle notch"
                    : processResult === 0
                      ? "check"
                      : "delete"
                }
              />
            }
            header={
              processResult === 1
                ? "We are currently activating your account"
                : processResult === 0
                  ? "Your account is activated"
                  : "Something went wrong!"
            }
            content={
              processResult === 1 ? (
                "Please wait..."
              ) : processResult === 0 ? (
                "We will redirect you soon in the sign in page!"
              ) : (
                <Fragment>
                  {"Please contact "}
                  <a
                    href="mailto:info@cheaptrolley.com"
                    referrerPolicy="no-referrer"
                  >
                    {"registration support"}
                  </a>
                  {" to help you with your issue!"}
                </Fragment>
              )
            }
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(ActivateUser);
