import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";

const UnassociatedProductCount = () => {
  const context = useContext(RootStoreContext);
  const { load, loading, count } = context.productCounterStore;

  useEffect(() => {
    load();

    return () => {};
  }, [load]);

  return (
    <div>
      <Button icon labelPosition="right" color="blue" basic>
        {count}
        <Icon
          onClick={() => load()}
          loading={loading}
          name="refresh"
          style={{ backgroundColor: "transparent" }}
        />
      </Button>
    </div>
  );
};

export default observer(UnassociatedProductCount);
