import { action, makeObservable, observable } from "mobx";
import { RootStore } from "../../../stores/RootStoreContext";
import { IHabibaiResponse, IConversation, IMessage, IRecipe, IMessageContent } from "../../../interfaces/chat";
import agent from "../../../api/agent";
import { Guid } from "../../../helpers/guid";

export class ChatUIStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable inputRef: any = undefined;
    @observable showSidebar: boolean = true;
    @observable adminMode: boolean = false;
    @observable selectedRecipe?: IRecipe = undefined;

    @observable loadingConversations: boolean = false;
    @observable loadingMessages: boolean = false;
    @observable processingMessage: boolean = false;

    @observable hasMoreConversations: boolean = true;
    @observable hasMoreMessages: boolean = true;

    @observable messages: IMessage[] = [];
    @observable conversations: IConversation[] = [];

    @observable conversationId?: string = undefined;
    @observable currentMessage: string = "";

    @observable currentConvPage: number = 0;
    @observable currentMessagePage: number = 0;
    @observable recipeIngredients: number = 0;

    @action setInputRef = (value: any) => this.inputRef = value;
    @action setShowSidebar = (value: boolean) => this.showSidebar = value;
    @action setSelectedRecipe = (messageId: string, value?: IRecipe) => {
        if (value !== undefined)
            value.messageId = messageId;

        this.selectedRecipe = value;
    }

    @action setLoadingConversations = (value: boolean) => this.loadingConversations = value;
    @action setLoadingMessages = (value: boolean) => this.loadingMessages = value;
    @action setProcessingMessage = (value: boolean) => this.processingMessage = value;

    @action setCurrentMessage = (value: string) => this.currentMessage = value;
    @action setCurrentConvPage = (value: number) => this.currentConvPage = value;
    @action setCurrentMessagePage = (value: number) => this.currentMessagePage = value;

    @action setHasMoreConversations = (value: boolean) => this.hasMoreConversations = value;
    @action setHasMoreMessages = (value: boolean) => this.hasMoreMessages = value;

    @action setConversations = (value: IConversation[]) => {
        var existing = this.conversations.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.conversations.push(...missing);
    };
    @action setMessages = (value: IMessage[]) => {
        // value.reverse();

        var existing = this.messages.map(f => f.msg_id)
        var missing = value.filter(f => existing.indexOf(f.msg_id) < 0);

        this.messages.push(...missing);
    };

    @action load = async (adminMode?: boolean) => {
        const { isMobile } = this.rootStore.deviceStore;
        if (isMobile) {
            this.setShowSidebar(false);
        }
        this.adminMode = adminMode ?? false;
        this.loadConversations();
    }

    @action loadConversations = async () => {
        try {
            this.setLoadingConversations(true);

            var response = await agent.Habibai.getChats(this.adminMode, this.currentConvPage);

            this.setCurrentConvPage(this.currentConvPage + 1);
            this.setHasMoreConversations(response.HasMore);
            this.setConversations(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoadingConversations(false);
        }
    }

    @action loadMessages = async (chatId: string) => {
        if (this.loadingMessages) return;
        try {
            this.setLoadingMessages(true);

            var response = await agent.Habibai.getMessages(chatId, this.currentMessagePage);

            this.setCurrentMessagePage(this.currentMessagePage + 1);
            this.setHasMoreMessages(response.HasMore);
            this.setMessages(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoadingMessages(false);
        }
    }

    @action sendMessage = async (message?: string) => {
        try {
            this.setProcessingMessage(true);

            if (this.currentMessage.trim() === '') {
                if (message !== undefined) {
                    this.currentMessage = message
                }
                if (this.currentMessage.trim() === '')
                    return;
            }

            const newMsgId = Guid.newGuid();
            const responseId = Guid.newGuid();

            var lastMessages = this.messages.slice(0, 10).filter(f => f.sender === "user" || (f.sender === "assistant" && ((f.content as IMessageContent).recipes?.length ?? 0) > 0)).map(f => f.sender === "user" ?
                { "role": "user", "content": f.content as string } :
                { "role": "assistant", "content": (f.content as IMessageContent).recipes.map(re => re.recipeName).join(",") }
            );

            this.addMessage({
                msg_id: newMsgId,
                sentOn: (new Date()).getTime() / 1000,
                sender: 'user',
                content: this.currentMessage.trim(),
            })

            this.addMessage({
                msg_id: responseId,
                sentOn: (new Date()).getTime() / 1000,
                sender: 'assistant',
                content: "Processing...",
            })

            agent.Habibai.sendMessage({
                id: newMsgId,
                messages: lastMessages,
                message: this.currentMessage.trim(),
                chatId: this.conversationId
            }).then((response) => {
                this.messages = this.messages.filter(f => f.msg_id !== responseId);
                this.processChefResponse(response, responseId);
            })

            this.setCurrentMessage("");
        } catch (error) {

        } finally {
            this.setProcessingMessage(false);
        }

    }

    @action processChefResponse = (response: IHabibaiResponse, msg_id: string) => {
        if (this.conversationId === undefined) {
            this.addConversation({
                "id": response.chatId,
                "title": response.title ?? "Untitled"
            })
        }

        this.conversationId = response.chatId;

        this.addMessage({
            msg_id: response.responseId,
            sentOn: (new Date()).getTime() / 1000,
            sender: "assistant",
            content: {
                message: response.message,
                query: response.query,
                products: response.products,
                recipes: response.recipes
            }
        })
    }

    @action addConversation = (chat: IConversation) => {
        this.conversations.unshift(chat);
    }

    @action addMessage = (message: IMessage) => {
        this.messages.unshift(message);
    }

    @action setSelectedConversation = async (conversationId: string) => {
        if (conversationId === this.conversationId) {
            return;
        }
        this.selectedRecipe = undefined;

        this.conversationId = conversationId;
        this.currentMessagePage = 0;
        this.setHasMoreMessages(true);

        // clean messages       
        this.messages = []
        // load messages

        if (this.rootStore.deviceStore.isMobile) {
            this.setShowSidebar(false);
        }
        await this.loadMessages(conversationId);
    }

    @action startNewChat = () => {
        if (this.processingMessage) return;

        this.messages = [];
        this.conversationId = undefined;
        this.currentMessage = "";
        this.selectedRecipe = undefined;
        this.inputRef?.current?.focus();
    }

    @action dispose = () => {
        this.messages = [];
        this.conversations = [];
        this.setShowSidebar(true);
        this.setCurrentMessage("");
        this.conversationId = undefined;
        this.inputRef = undefined;
        this.showSidebar = true;
        this.selectedRecipe = undefined;
        this.loadingConversations = false;
        this.loadingMessages = false;
        this.processingMessage = false;
        this.hasMoreConversations = true;
        this.hasMoreMessages = true;
        this.currentConvPage = 0;
        this.currentMessagePage = 0;
    }

}