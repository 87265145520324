import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { ProductCategoryForProcessing } from "../../../interfaces/categories";


export class CategoriesAdminStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable categories: ProductCategoryForProcessing[] = []
    @observable selected_categories: string[] = []
    @observable search_categories?: ProductCategoryForProcessing[] = undefined
    @observable searchText: string = "";
    @observable childId: string = "";

    @action setLoading = (value: boolean) => this.loading = value;
    @action setCategories = (value: ProductCategoryForProcessing[]) => {
        this.categories = value;
    };
    @action setSelectedCategories = (value: string[]) => {
        this.selected_categories = value;
    };
    @action setChildId = (value: string) => this.childId = value;

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.ProductCategories.getAdmin(this.searchText);

            this.setCategories(response);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action refresh = async (item: ProductCategoryForProcessing) => {
        try {
            var idx = this.categories.findIndex(f => f.id === item.id);

            runInAction(() => {
                this.categories[idx] = item;
            })
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action update = async (values: ProductCategoryForProcessing) => {
        return agent.ProductCategories.update(values).then(() => this.refresh(values));
    }

    @action search = async (text: string) => {
        try {
            this.searchText = text.toLocaleLowerCase();
            if (text.trim() === "") {
                if (this.selected_categories.length > 0) {
                    this.search_categories = this.categories?.filter(f => f.parents && f.parents.filter(x => this.selected_categories.includes(x)).length > 0);
                }
                else {
                    this.search_categories = undefined;
                }

            }
            else
                this.search_categories = this.categories.filter(f => f.description.toLowerCase().includes(this.searchText));

            if (this.selected_categories.length > 0) {
                this.search_categories = this.search_categories?.filter(f => f.parents && f.parents.filter(x => this.selected_categories.includes(x)).length > 0);
            }
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action nest = async (childId: string, parentId: string) => {
        if (!childId || !parentId || childId === "" || parentId === "")
            return;

        return agent.ProductCategories
            .nest(childId, parentId)
            .then(() => {
                this.setChildId("");
                this.load();
            });
    }

    @action dispose = () => {
        this.searchText = ""
        this.setLoading(false);
        this.setCategories([]);
        this.search_categories = undefined;
        this.selected_categories = []
    }
}