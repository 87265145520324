import { formatRelative } from "date-fns";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Card, Icon, List } from "semantic-ui-react";
import agent from "../../api/agent";
import { getImageUrl } from "../../helpers/productImage";
import { IProduct, IProductForList } from "../../interfaces/product";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { currencyFormat } from "../products/functions/productHelper";

interface IProps {
  product: IProductForList;
  selected: boolean;
  onSelection: (id: string) => void;
}

const AssociationProductCard: React.FC<IProps> = ({
  product,
  onSelection,
  selected,
}) => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const [productFull, setProductFull] = useState<IProduct>();

  useEffect(() => {
    agent.Products.getDetail(product.id).then((response) =>
      setProductFull(response)
    );

    return () => { };
  }, [product]);

  return (
    <Card
      onClick={() => onSelection(product.id)}
      style={selected ? { backgroundColor: "aliceblue" } : undefined}
    >
      <img
        alt="Product Logo"
        // label={
        //   selected && { as: "a", corner: "right", icon: "check", color: "blue" }
        // }
        src={
          getImageUrl(product) ??
          `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
        }
        style={{ maxHeight: "150px", objectFit: "contain" }}
      />
      <Card.Content>
        <Card.Header>{product.itemDesc}</Card.Header>
        {/* <Card.Meta>
          <span className="date">Joined in 2015</span>
        </Card.Meta> */}
        <Card.Description>
          <List>
            <List.Item>
              <span>Market :</span>
              {markets.filter((f) => f.type_id === product.marketId)[0].title}
            </List.Item>
            <List.Item>
              <span>Last Updated :</span>
              {formatRelative(
                new Date(product.lastUpdated * 1000),
                new Date(),
                {}
              )}
            </List.Item>
            <List.Item>
              <span>Sku :</span> {productFull?.sku ?? "-"}
            </List.Item>
            <List.Item>
              <span>Internal Id :</span> {productFull?.internalId}
            </List.Item>
            <List.Item>
              <span>Unit :</span> {productFull?.unit ?? "N/A"}
            </List.Item>
            <List.Item>
              <span>Price Per Unit :</span> {productFull?.pricePerUnit ?? "N/A"}
            </List.Item>
          </List>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          <Icon name="euro" />
          {currencyFormat(product.currentPrice, false)}
        </div>
      </Card.Content>
    </Card>
  );
};

export default observer(AssociationProductCard);
