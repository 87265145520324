import { observer } from 'mobx-react-lite'
import React from 'react'
import { Tab, } from 'semantic-ui-react';
import DeliveriesOverviewWidget from './components/DeliveriesOverviewWidget';
import ContriesDeliveryConfig from './components/ContriesDeliveryConfig';


const DeliveryScheduler = () => {
    return (
        <Tab className="simply-paddedPage" menu={{ color: "green", secondary: true, pointing: true }}
            panes={[
                {
                    menuItem: "Deliveries",
                    render: () => (<Tab.Pane attached={false}>
                        <DeliveriesOverviewWidget />
                    </Tab.Pane>)
                },
                {
                    menuItem: "Pick Ups",
                    render: () => (<Tab.Pane attached={false}>
                        <div>here we will show all the actual scheduled pickups</div>
                    </Tab.Pane>)
                },
                {
                    menuItem: "Countries Delivery Config",
                    render: () => (<Tab.Pane attached={false}>
                        <ContriesDeliveryConfig />
                    </Tab.Pane>)
                },
            ]}
        />
    )
}

export default observer(DeliveryScheduler)