import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { Button, Checkbox, Icon, List, Segment, Statistic, StatisticLabel, StatisticValue } from 'semantic-ui-react';
import { HubOrderVerificationStore } from './functions/HubOrderVerificationStore';
import VerifyOrderTableRow from './components/VerifyOrderTableRow';
import { HubOrderStatus } from '../../options/HubOrderProductStatus';
import { IHubOrderForDetail } from '../../interfaces/order';
import CsvExport from '../../common/csvExport';

const HubOrderVerification = () => {
    let { id } = useParams<"id">();
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { markets } = context.cacheStore
    const { locations, loadLocations } = context.hubOrdersStore;
    const {
        load,
        loading,
        order,
        dispose,
        updatingOrder,
        updateOrderStatus,
        updateProductAmount,
        updateProduct,
        toggleHasImage,
        updateProductForShop,
        replaceProductForShop,
        updateShoppingOrderPackedProduct,
        undoShoppingOrderProduct,
        refundShoppingOrderProduct,
        updatingProduct,
        exportDataForCsv,
        downloading
    } = HubOrderVerificationStore;


    useEffect(() => {
        if (!id) {
            navigate("/hub-orders");
            return;
        }
        if (locations.length === 0) {
            loadLocations()
        }
        load(id);

        return () => {
            dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, load, id, dispose]);

    const [splitId, setSplitId] = React.useState<string | undefined>(undefined);
    const [filterPending, setFilterPending] = React.useState<boolean>(false);
    const [filterMissing, setFilterMissing] = React.useState<boolean>(false);

    const onDoubleClick = (id: string) => {
        if (splitId === id) {
            setSplitId(undefined);
        }
        else {
            setSplitId(id);
        }
    }

    const getProducts = (order: IHubOrderForDetail) => {
        var items = order.products.slice();

        if (filterPending === true) {
            items = order.products.slice().filter(f => (f.packedAmount ?? 0) <= 0)
        }

        if (filterMissing === true) {
            items = order.products.slice().filter(f => (f.packedAmount ?? -1) === 0)
        }

        return items.sort((a, b) => a.productTitle < b.productTitle ? -1 : 1)
    }

    return (
        <div>
            <Segment basic>
                <div className='fxDisplay fxFixed fxJustifyBetween'>
                    <Statistic>
                        <StatisticLabel>Order Id</StatisticLabel>
                        <StatisticValue>{order?.displayId}</StatisticValue>
                        <StatisticLabel>{HubOrderStatus.filter(f => f.value === order?.status)[0]?.text}</StatisticLabel>
                    </Statistic>
                    <Statistic onClick={() => navigate(`/shop/${order?.accountId}`)}>
                        <StatisticLabel>Retailer</StatisticLabel>
                        <StatisticValue>{markets?.filter(f => f.id === order?.accountId)[0]?.title}</StatisticValue>
                    </Statistic>
                    <Statistic>
                        <StatisticLabel>Order(s)</StatisticLabel>
                        <StatisticValue>{order?.orderIds.length}</StatisticValue>
                    </Statistic>
                    <Statistic>
                        <StatisticLabel>Product(s)</StatisticLabel>
                        <StatisticValue>{order?.quantity}</StatisticValue>
                    </Statistic>
                    <Button
                        loading={updatingOrder}
                        disabled={updatingOrder || (order?.products.filter(f => f.status === "pending").length ?? 0) > 0}
                        color={(order?.status === "ready_for_delivery" || order?.status === "sent_to_market") ? "blue" : (order?.status === "received" ? "green" : "red")}
                        content={(order?.status === "ready_for_delivery" || order?.status === "sent_to_market") ? "Mark as received" : (order?.status === "received" ? "Mark as Completed" : "Reopen")}
                        onClick={() => updateOrderStatus()}
                    />
                </div>
            </Segment>
            <div style={{ padding: "0px 14px" }}>
                <Checkbox label='Filter unpacked' checked={filterPending} onClick={() => {
                    setFilterPending(false);
                    setFilterPending(!filterPending)
                }} disabled={loading} />
                <Checkbox style={{ marginLeft: "14px" }} label='Filter Out of Stock' checked={filterMissing} onClick={() => {
                    setFilterPending(false);
                    setFilterMissing(!filterMissing)
                }} disabled={loading} />
                <span style={{ marginLeft: "14px" }}>
                    <CsvExport
                        onClick={exportDataForCsv}
                        content={
                            <Fragment>
                                <Icon name="file excel outline" /> Export
                            </Fragment>
                        }
                        disabled={(order?.products.length ?? 0) === 0 || downloading}
                        loading={downloading}
                    />
                </span>
            </div>
            <Segment loading={loading} basic>
                <List basic="very" className='hub-order-verify-table'>
                    {order && order.products && getProducts(order).map((x) =>
                        <div key={x.productId}>
                            <VerifyOrderTableRow
                                key={x.productId}
                                item={x}
                                onDoubleClick={onDoubleClick}
                                updateProduct={updateProduct}
                                updatingProduct={updatingProduct}
                                updateProductAmount={updateProductAmount}
                                toggleHasImage={toggleHasImage}
                                updateProductForShop={updateProductForShop}
                                orders={splitId === x.catalogueId ? order.orders.filter(f => f.products.filter(p => p.catalogueId === x.catalogueId).length > 0) : undefined}
                                undoShoppingOrderProduct={undoShoppingOrderProduct}
                                updateShoppingOrderPackedProduct={updateShoppingOrderPackedProduct}
                                refundShoppingOrderProduct={refundShoppingOrderProduct}
                                replaceProductForShop={replaceProductForShop}
                            />
                        </div>)}
                </List>
            </Segment>
        </div >
    )
}

export default observer(HubOrderVerification);