import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import ManageShopAccount from "./ManageShopAccount";
import OpenNewAccount from "./OpenNewAccount";

interface IProps {
  mode: "new" | "claim" | "integrate";
}

const OnlineShopPage: React.FC<IProps> = ({ mode }) => {
  const context = useContext(RootStoreContext);
  const { accountId } = context.userStore;

  useEffect(() => {
    return () => {};
  }, [accountId]);

  return (
    <div>
      {!accountId && (
        <div>
          <OpenNewAccount mode={mode} />
        </div>
      )}

      {accountId && (
        <div>
          <ManageShopAccount />
        </div>
      )}
    </div>
  );
};

export default observer(OnlineShopPage);
