// Import necessary components and hooks
import React, { useEffect, useRef } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BrandSelectorStore } from './functions/BrandSelectorStore';

interface IProps {
    brandSelectStore: BrandSelectorStore
    defaultValue?: string[];
    selectionChanged?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void
}

const BrandsSelector: React.FC<IProps> = ({ selectionChanged, defaultValue, brandSelectStore }) => {
    const { brands, load, search } = brandSelectStore;
    const dropdownWrapperRef = useRef(null);
    const [timer, setTimer] = React.useState(0);

    // Load initial data
    useEffect(() => {
        load(defaultValue, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load, defaultValue]);

    // Scroll event handler
    const handleScroll = (e: any) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollHeight - scrollTop === clientHeight) { // Check if scrolled to bottom
            load(); // Load next page
        }
    };

    // Set up scroll event listener
    useEffect(() => {
        const dropdownMenu = (dropdownWrapperRef.current as any)?.querySelector('.menu');
        if (dropdownMenu) {
            dropdownMenu.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (dropdownMenu) {
                dropdownMenu.removeEventListener('scroll', handleScroll);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownWrapperRef]);

    // Render the dropdown
    return (
        <div ref={dropdownWrapperRef}>
            <Dropdown
                fluid
                clearable
                // multiple
                search
                placeholder='Search for a brand..'
                defaultValue={defaultValue!! ? (defaultValue as any[])[0] : undefined}
                onChange={selectionChanged}
                onSearchChange={(e, d: any) => {
                    if (!search) return;
                    if (timer != null) {
                        clearTimeout(timer);
                        setTimer(0);
                    }

                    setTimer(
                        setTimeout(
                            (callback: (text: string) => void, text: string) => {
                                callback(text);
                                clearTimeout(timer);
                            },
                            500,
                            search,
                            d.searchQuery
                        )
                    );
                }}
                selection
                options={brands?.map(item => ({ key: item.id, value: item.id, text: item.title }))}
            // loading={issea}
            />
        </div>
    );
};

export default observer(BrandsSelector);