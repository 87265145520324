import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Button, Form } from 'semantic-ui-react'
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import NumericInput from '../../../form/NumericInput'
import ErrorMessage from '../../../form/ErrorMessage'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import SelectInput from '../../../form/SelectInput'
import { WalletTransactionType, WalletTransactionTypes } from '../../../options/WalletTransactionTypes'
import TextAreaInput from '../../../form/TextAreaInput'
import agent from '../../../api/agent'
import { combineValidators, isRequired } from 'revalidate';


interface IProps {
    userId: string
}

const validate = combineValidators({
    type: isRequired(""),
    amount: isRequired(""),
    comment: isRequired(""),
});

const ManualTransactionWidget: React.FC<IProps> = ({ userId }) => {
    const context = useContext(RootStoreContext)
    const { closeModal } = context.modalStore;

    return (
        <FinalForm
            initialValues={{}}
            validate={validate}
            onSubmit={(values: { type: WalletTransactionType, amount: number, comment: string }) =>
                agent.Users.Wallet.append(userId, values.type, values.amount, values.comment)
                    .then((values) => closeModal())
                    .catch((error) => ({
                        [FORM_ERROR]: error,
                    }))
            }
            render={({
                handleSubmit,
                submitting,
                invalid,
                dirtySinceLastSubmit,
                submitError,
            }) => (
                <Form onSubmit={handleSubmit} error className="attached fluid">
                    <Form.Field>
                        <label style={{ fontSize: "13px", textAlign: "left", color: "Blue" }}>
                            {"Amount (€)"}
                        </label>
                        <Field
                            name="amount"
                            component={NumericInput}
                            placeholder={"Type an amount"}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label style={{ fontSize: "13px", textAlign: "left", color: "Blue" }}>
                            {"Transaction Type"}
                        </label>
                        <Field
                            name="type"
                            component={SelectInput}
                            options={WalletTransactionTypes}
                            placeholder={"Packed Amount"}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label style={{ fontSize: "13px", textAlign: "left", color: "Blue" }}>
                            {"Comments"}
                        </label>
                        <Field
                            name="comment"
                            component={TextAreaInput}
                            rows={3}
                            placeholder={"Write your comments, reason for the transaction"}
                        />
                    </Form.Field>

                    {submitError && !dirtySinceLastSubmit && (
                        <ErrorMessage error={submitError} />
                    )}

                    <div style={{ marginTop: "7px", textAlign: "center" }}>
                        <Button.Group>
                            <Button
                                disabled={invalid && !dirtySinceLastSubmit}
                                loading={submitting}
                                color="blue"
                                content={"Save"}
                            />
                            <Button.Or />
                            <Button
                                onClick={(event) => {
                                    event.preventDefault();
                                    closeModal();
                                }}
                                content={"Cancel"}
                            />
                        </Button.Group>
                    </div>
                </Form >
            )}
        />
    )
}

export default observer(ManualTransactionWidget)
