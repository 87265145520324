import React, { useContext } from "react";
import { Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/RootStoreContext";

const ModalContainer = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    modal: { open, body, size, header, closeIcon, scrolling, mountNode, contentStyle },
    secondModal: { secondOpen, secondBody, secondSize, secondHeader, secondcloseIcon, secondcontentStyle, secondmountNode, secondscrolling },
    closeModal,
    closeSecondModal,
  } = rootStore.modalStore;

  return (
    <Modal
      open={open}
      onClose={closeModal}
      closeIcon={closeIcon}
      mountNode={mountNode}
      size={size}
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content scrolling={scrolling} className={contentStyle}>
        <Modal.Description>{body}</Modal.Description>
      </Modal.Content>

      <Modal
        open={secondOpen}
        onClose={closeSecondModal}
        closeIcon={secondcloseIcon}
        size={secondSize}
        mountNode={secondmountNode}
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        {secondHeader && <Modal.Header>{secondHeader}</Modal.Header>}
        <Modal.Content scrolling={secondscrolling} className={secondcontentStyle}>
          <Modal.Description>
            {secondBody}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </Modal>
  );
};

export default observer(ModalContainer);
