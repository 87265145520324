import React, { ChangeEvent, useContext, useEffect } from 'react'
import { useLocation } from 'react-router';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { Button, Divider, Form, Icon, Input, Label, Menu, Segment, Table } from 'semantic-ui-react';
import { Form as FinalForm, Field } from "react-final-form";
import ErrorMessage from "../../form/ErrorMessage";
import NumericInput from "../../form/NumericInput";
import { IAssociationForSave } from '../../interfaces/association';
import { FORM_ERROR } from "final-form";
import { observer } from 'mobx-react-lite';
import { combineValidators, isRequired } from 'revalidate';
import TextInput from '../../form/TextInput';
import { LoadingParagraph } from '../../components/LoadingParagraph';
import SelectInput from '../../form/SelectInput';
import AssociationProductTableRow from '../associations/AssociationProductTableRow';
import { ButtonTypes } from '../confirmationModal/functions/ButtonTypes';

const validate = combineValidators({
    title: isRequired(""),
});


const AssociationVerification = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let associationId = searchParams.get('associationId');

    const context = useContext(RootStoreContext);
    const { markets } = context.cacheStore;
    const { isMobile } = context.deviceStore;
    const { openConfirmationModal, closeConfirmationalModal } =
        context.confirmationModalStore;
    const { load, dispose, association, counter, loading, update, removeFromAssociation, deleteAssociation } = context.associationVerificationStore;

    useEffect(() => {
        load(associationId ?? undefined);

        return () => {
            dispose();
        };
    }, [load, dispose, associationId]);

    const [timer, setTimer] = React.useState(0);

    return (
        <div className="simply-paddedPage">
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "0 0 5px 0" }}
            >
                <Menu.Item
                    className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}
                >
                    <Input
                        fluid={isMobile}
                        // disabled={loading || !search}
                        transparent={isMobile}
                        size={isMobile ? "large" : "small"}
                        icon={!isMobile ? <Icon name="search" color="blue" /> : undefined}
                        placeholder={"Search..."}
                        input={<input className={isMobile ? "" : "bluePlaceholder"} />}
                        onChange={(event: ChangeEvent, data: any) => {
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: (text: string) => void, text: string) => {
                                        callback(text);
                                        clearTimeout(timer);
                                    },
                                    500,
                                    load,
                                    data.value
                                )
                            );
                        }}
                    />
                </Menu.Item>
                <Menu.Item>
                    {`Remaining : ${counter}`}
                </Menu.Item>
            </Menu>

            <Segment>
                {markets.length > 0 && <Table basic="very" celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Product</Table.HeaderCell>
                            <Table.HeaderCell>Store</Table.HeaderCell>
                            <Table.HeaderCell>Sku</Table.HeaderCell>
                            <Table.HeaderCell>Last Update</Table.HeaderCell>
                            <Table.HeaderCell>Price</Table.HeaderCell>
                            <Table.HeaderCell>Remove</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {association?.products
                            .slice()
                            .sort((a, b) => (a.marketId < b.marketId ? -1 : 1))
                            .map((x) => (
                                <AssociationProductTableRow key={x.id} product={x} removeFromAssociation={removeFromAssociation} />
                            ))}
                    </Table.Body>
                </Table>}
                <Divider />
                {!loading && (
                    <FinalForm
                        onSubmit={(values: IAssociationForSave) =>
                            update(values)
                                .catch((error) => ({
                                    [FORM_ERROR]: error,
                                }))
                        }
                        initialValues={association}
                        validate={validate}
                        render={({
                            handleSubmit,
                            submitError,
                            dirtySinceLastSubmit,
                            submitting,
                            form,
                        }) => (
                            <Form onSubmit={handleSubmit} error>
                                <div style={{ textAlign: "center" }}>
                                    <Form.Group widths="equal">
                                        <Form.Field style={{ textAlign: "left" }}>
                                            <label style={{ fontSize: "13px" }}>
                                                {"Association Title"}
                                            </label>
                                            <Field
                                                loading={loading}
                                                component={TextInput}
                                                name="title"
                                                placeholder={"Title"}
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths="equal">
                                        <Form.Field style={{ textAlign: "left" }}>
                                            <label style={{ fontSize: "13px" }}>{"No of items"}</label>
                                            {loading ? (
                                                <LoadingParagraph />
                                            ) : (
                                                <Field
                                                    component={NumericInput}
                                                    name="noOfItems"
                                                    placeholder={"No of items"}
                                                />
                                            )}
                                        </Form.Field>
                                        <Form.Field style={{ textAlign: "left" }}>
                                            <label style={{ fontSize: "13px" }}>
                                                {"Measurement Unit"}
                                            </label>
                                            <Field
                                                loading={loading}
                                                component={SelectInput}
                                                options={[
                                                    {
                                                        value: "grams",
                                                        key: "grams",
                                                        text: "grams",
                                                    },
                                                    {
                                                        value: "ml",
                                                        key: "ml",
                                                        text: "ml",
                                                    },
                                                ]}
                                                name="measurementUnit"
                                                placeholder={"Measurement Unit"}
                                            />
                                        </Form.Field>
                                        <Form.Field style={{ textAlign: "left" }}>
                                            <label style={{ fontSize: "13px" }}>{"Measurment"}</label>
                                            {loading ? (
                                                <LoadingParagraph />
                                            ) : (
                                                <Field
                                                    component={NumericInput}
                                                    name="measurment"
                                                    placeholder={"Measurment e.g. 700"}
                                                />
                                            )}
                                            <Label
                                                color="blue"
                                                basic
                                                size="small"
                                                style={{ marginTop: "4px" }}
                                                content="500"
                                                onClick={() => form.change("measurment", 500)}
                                            />
                                            <Label
                                                color="blue"
                                                basic
                                                size="small"
                                                style={{ marginTop: "4px" }}
                                                content="750"
                                                onClick={() => form.change("measurment", 750)}
                                            />
                                            <Label
                                                color="blue"
                                                size="small"
                                                basic
                                                style={{ marginTop: "4px" }}
                                                content="1000"
                                                onClick={() => form.change("measurment", 1000)}
                                            />
                                        </Form.Field>
                                        <Form.Field style={{ textAlign: "left" }}>
                                            <label style={{ fontSize: "13px" }}>{"Step"}</label>
                                            {loading ? (
                                                <LoadingParagraph />
                                            ) : (
                                                <Field
                                                    component={NumericInput}
                                                    name="step"
                                                    placeholder={"e.g. 0.1"}
                                                />
                                            )}
                                            <Label
                                                color="blue"
                                                basic
                                                size="small"
                                                style={{ marginTop: "4px" }}
                                                content="0.1-100gr"
                                                onClick={() => form.change("step", 0.1)}
                                            />
                                            <Label
                                                color="blue"
                                                basic
                                                size="small"
                                                style={{ marginTop: "4px" }}
                                                content="1-1kg"
                                                onClick={() => form.change("step", 1)}
                                            />
                                        </Form.Field>
                                        <Form.Field style={{ textAlign: "left" }}></Form.Field>
                                    </Form.Group>
                                </div>
                                {submitError && !dirtySinceLastSubmit && (
                                    <ErrorMessage error={submitError} />
                                )}

                                <Divider />

                                <div style={{ textAlign: "center" }}>
                                    <Button.Group style={{ paddingTop: "15px" }}>
                                        <Button disabled={association?.id === undefined} color="blue" loading={submitting} content={"Save & Verify"} />
                                        <Button.Or />
                                        <Button
                                            disabled={submitting || association?.id === undefined}
                                            color='red'
                                            content={"Delete"}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                openConfirmationModal(
                                                    "Confirm association deletion",
                                                    "Are you sure you want to delete this association?",
                                                    () =>
                                                        deleteAssociation(association!.id).then(() =>
                                                            closeConfirmationalModal()
                                                        ),
                                                    "Cancel",
                                                    "Delete",
                                                    ButtonTypes.None,
                                                    ButtonTypes.Negative
                                                );
                                            }}
                                        />
                                    </Button.Group>
                                </div>
                            </Form>
                        )}
                    />
                )}
            </Segment>

        </div>
    )
}

export default observer(AssociationVerification)