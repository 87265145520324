import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IHubs } from "../../../interfaces/hubs";
import { RootStore } from "../../../stores/RootStoreContext";

export class HubsStore {
    rootStore: RootStore;

    @observable loading: boolean = false;
    @observable hubs: IHubs[] = [];

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action setLoading = (value: boolean) => {
        this.loading = value;
    }

    @action load = async () => {
        try {
            this.setLoading(true);

            var hubs = await agent.Hubs.get();

            if (hubs.length === 1) {
                // const { setSelectedHub } = this.rootStore.shoppingListStore;
                // setSelectedHub(hubs[0].id);
            }

            runInAction(() => {
                this.hubs = hubs;
            });

        } catch (error) {
            console.log(error);
        }
        finally {
            this.setLoading(false);
        }
    }

    @action dispose = () => {
        this.hubs = [];
    }

}