import { action, computed, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { IAssociationForGlobalIntentory, IAssociationForGlobalIntentoryExport } from "../../../interfaces/association";

export class GlobalInventoryStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable downloading: boolean = false;
    @observable discontinued: boolean = false;
    @observable internal: boolean = false;
    @observable notMyPriceDrops: boolean = false;
    @observable dropSince: number = -1;
    @observable percentage: boolean = false;
    @observable separateSKU: boolean = false;
    @observable currentPage: number = 0;
    @observable minPrice?: number = undefined;
    @observable maxPrice?: number = undefined;
    @observable shopId?: string = undefined;
    @observable editingId?: string = undefined;
    @observable hasMore: boolean = false;
    @observable associations: IAssociationForGlobalIntentory[] = []
    @observable selectedSearchType: "any" | "contain" | "starts" = "any";
    @observable searchText: string = "";
    @observable searchSku: string = "";
    @observable selectedMarketIds: string[] = [];

    @action setSelectedMarketIds = (value: string[]) => this.selectedMarketIds = value;
    @action setLoading = (value: boolean) => this.loading = value;
    @action setSeparateSKU = (value: boolean) => this.separateSKU = value;
    @action setDownloading = (value: boolean) => this.downloading = value;
    @action setShopId = (value?: string) => this.shopId = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setEditingId = (value?: string) => this.editingId = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetAssociations = (value: IAssociationForGlobalIntentory[]) => this.associations = value;
    @action setAssociations = (value: IAssociationForGlobalIntentory[]) => {
        var existing = this.associations.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.associations.push(...missing);
    };

    @computed get markets() {
        const { account_markets } = this.rootStore.accountPageStore;
        const { markets } = this.rootStore.cacheStore;
        const { isAdmin } = this.rootStore.userStore;

        return isAdmin ? markets : account_markets;
    }

    @action load = async (shopId?: string) => {
        try {
            this.setLoading(true);

            const { accountId } = this.rootStore.userStore;

            var myMarket = accountId ? this.markets.filter(f => f.id === accountId)[0].type_id : undefined

            if (this.selectedMarketIds.length === 1 && myMarket && this.selectedMarketIds[0] === myMarket) {
                this.selectedMarketIds = []
            }
            else if (this.selectedMarketIds.length > 0 && myMarket && !this.selectedMarketIds.includes(myMarket)) {
                this.selectedMarketIds.push(myMarket);
            }

            var response = await agent
                .GlobalInventory
                .get(
                    this.selectedSearchType,
                    this.searchText,
                    this.searchSku,
                    this.selectedMarketIds,
                    this.discontinued,
                    this.internal,
                    this.dropSince,
                    this.notMyPriceDrops,
                    this.minPrice,
                    this.maxPrice,
                    this.currentPage
                );

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setAssociations(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action search = async (text: string, sku?: string) => {
        try {
            this.searchText = text?.trim() ?? "";
            this.searchSku = sku?.trim() ?? "";
            this.resetAssociations([]);
            this.setCurrentPage(0)

            this.load(this.shopId);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action searchMinPrice = async (from?: number) => {
        try {
            this.minPrice = from;
            this.resetAssociations([]);
            this.setCurrentPage(0)

            this.load(this.shopId);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action searchMaxPrice = async (to?: number) => {
        try {
            this.maxPrice = to;
            this.resetAssociations([]);
            this.setCurrentPage(0)

            this.load(this.shopId);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action changeDiscontinued = async () => {
        try {
            this.discontinued = !this.discontinued;
            this.search(this.searchText);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action changeInternal = async () => {
        try {
            this.internal = !this.internal;
            this.search(this.searchText);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action changeNotMyPriceDrops = async () => {
        try {
            this.notMyPriceDrops = !this.notMyPriceDrops;
            this.search(this.searchText);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action changeDropSince = async (dropSince: number) => {
        try {
            this.dropSince = dropSince;
            this.search(this.searchText);
        } catch (error) {
        }
    };

    @action changeSearchType = async (type: "any" | "contain" | "starts") => {
        try {
            this.selectedSearchType = type;
            if (type === "starts") {
                this.internal = true;
            }
            this.search(this.searchText);
        } catch (error) {
        }
    };

    @action changePercentage = async () => {
        try {
            this.percentage = !this.percentage;
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.searchText = "";
        this.selectedSearchType = "any";
        this.minPrice = undefined;
        this.maxPrice = undefined;
        this.dropSince = -1;
        this.notMyPriceDrops = false;
        this.setShopId(undefined);
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetAssociations([]);
        this.setHasMore(false);
        this.setSelectedMarketIds([]);
    }

    @action exportDataForCsv = async () => {
        this.setDownloading(true);
        const { accountId } = this.rootStore.userStore;

        try {
            var myMarket = accountId ? this.markets.filter(f => f.id === accountId)[0].type_id : undefined

            if (this.selectedMarketIds.length > 0 && myMarket) {
                this.selectedMarketIds.push(myMarket);
            }

            var complexData = await agent.GlobalInventory.export(
                this.selectedSearchType,
                this.searchText,
                this.selectedMarketIds,
                this.shopId,
                this.discontinued,
                this.internal,
                this.dropSince,
                this.notMyPriceDrops,
                this.minPrice,
                this.maxPrice,
            );

            var csvData: any[] = [];
            var headers = ["SKU", "Description"]
            if (accountId) {
                headers.push(this.markets.filter(f => f.id === accountId)[0].title)
            }

            (this.selectedMarketIds.length > 0 ?
                this.markets.filter(f => this.selectedMarketIds.includes(f.type_id) && !f.shutDown) :
                this.markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1)).filter(f => f.id !== accountId && !f.shutDown).forEach(x => {
                    headers.push(x.title);
                });

            csvData.push(headers);

            complexData.forEach((row: IAssociationForGlobalIntentoryExport) => {
                csvData.push(...this.getColumnsForExportRow(row, myMarket));
            });

            var today = new Date();
            var fileName = `${today.getFullYear()}_${today.getMonth()}_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
            return {
                data: csvData,
                filename: `cheap_trolley_export_${fileName}.csv`,
            };
        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownloading(false);
        }
    };

    @action getColumnsForExportRow = (group: IAssociationForGlobalIntentoryExport, marketId?: string) => {
        var rowsToReturn = []
        var skus = Array.from(
            new Set(group.products?.map((z) => z.sku))
        ).filter(f => f && f !== "" && f?.length > 7)

        const { accountId } = this.rootStore.userStore;

        if (this.separateSKU && skus.length > 1) {
            skus.forEach(sku => {
                var columnsToReturn: string[] = [];
                columnsToReturn.push(sku ?? '')
                columnsToReturn.push(group.title ?? '')

                if (marketId) {
                    columnsToReturn.push(group.products.filter(f => f.marketId === marketId)[0]?.currentPrice.toFixed(2))
                }

                (this.selectedMarketIds.length > 0 ?
                    this.markets.filter(f => this.selectedMarketIds.includes(f.type_id)) :
                    this.markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1)).filter(f => f.id !== accountId && !f.shutDown).forEach(x => {
                        columnsToReturn.push(group.products
                            ?.filter(f => f.marketId === x.type_id)[0]?.currentPrice?.toFixed(2) ?? "");
                    });

                rowsToReturn.push(columnsToReturn)
            });
        }
        else {
            var columnsToReturn: string[] = [];
            columnsToReturn.push(skus.join(","))
            columnsToReturn.push(group.title ?? '')

            if (marketId) {
                columnsToReturn.push(group.products.filter(f => f.marketId === marketId)[0]?.currentPrice.toFixed(2))
            }

            (this.selectedMarketIds.length > 0 ?
                this.markets.filter(f => this.selectedMarketIds.includes(f.type_id)) :
                this.markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1)).filter(f => f.id !== accountId && !f.shutDown).forEach(x => {
                    columnsToReturn.push(group.products
                        ?.filter(f => f.marketId === x.type_id)[0]?.currentPrice?.toFixed(2) ?? "");
                });

            rowsToReturn.push(columnsToReturn)
        }

        return rowsToReturn;
    };


    @action disposeDetail = () => {
        this.setLoading(false);
        this.setEditingId(undefined)
        // this.setEditingItem(undefined)
    }
}