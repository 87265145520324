import Highlighter from "react-highlight-words";
import { formatRelative } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { createRef, Fragment, useContext, useEffect } from "react";
import {
  Segment,
  Image,
  Dimmer,
  Loader,
  Popup,
  Icon,
  Label,
  Table,
  Header,
  Divider,
  Grid,
  Ref,
  Sticky,
  Menu,
} from "semantic-ui-react";
import { LoadingParagraph } from "../../components/LoadingParagraph";
import { getImageUrlPlain } from "../../helpers/productImage";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { ButtonTypes } from "../confirmationModal/functions/ButtonTypes";
import { currencyFormat } from "./functions/productHelper";
import NewAssociationWidget from "../possibleAssociations/components/NewAssociationWidget";
import Associations from "../associations/Associations";
import { getMarketImageUrl } from "../../helpers/supermarketImage";

interface IProps {
  byWeight?: boolean;
}

const OrphanProducts: React.FC<IProps> = ({ byWeight = false }) => {
  var contextRef = createRef<any>();
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const { openConfirmationModal, closeConfirmationalModal } =
    context.confirmationModalStore;
  const {
    loadNext,
    dispose,
    loading,
    product,
    expandedIds,
    toggleExpanded,
    removePossibleGroupRelation,
    approvePossibleGroupRelation,
    deleteProduct,
    convertToGroup,
    existingGroupsView,
    toggleView,
    approveGroupRelation,
    removeGroupRelation,
    orphansCount,
    createOpen,
    setCreateOpen,
  } = context.orphanStore;

  useEffect(() => {
    loadNext(byWeight);
    return () => {
      dispose();
    };
  }, [loadNext, dispose, byWeight]);

  return (
    <Ref innerRef={contextRef}>
      <Segment>
        <Sticky context={contextRef}>
          <Segment>
            {loading && (
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            )}

            <Menu secondary>
              <Menu.Menu position="right">
                <Menu.Item>
                  {loading ? "---" : <b>{orphansCount}</b>} op
                </Menu.Item>
              </Menu.Menu>
            </Menu>

            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column verticalAlign="middle">
                  {product && <h3>{product.itemDesc}</h3>}
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <div className="ui tiny compact text labeled icon menu">
                    <span
                      title="Create new Association"
                      className="item"
                      onClick={() => {
                        setCreateOpen(!createOpen);
                      }}
                    >
                      <i
                        aria-hidden="true"
                        className="green large fitted icon save outline"
                      ></i>
                      Create Group
                    </span>
                    <span
                      title="Add to group"
                      className="item"
                      onClick={() => toggleView()}
                    >
                      <i
                        aria-hidden="true"
                        className="purple large fitted icon paperclip"
                      ></i>
                      Add to group
                    </span>
                    <span
                      title="Remove Product"
                      className="item"
                      onClick={() => {
                        openConfirmationModal(
                          "Confirm product info deletion",
                          "Are you sure you want to delete this product?",
                          () =>
                            deleteProduct().then(() =>
                              closeConfirmationalModal()
                            ),
                          "Cancel",
                          "Delete",
                          ButtonTypes.None,
                          ButtonTypes.Negative
                        );
                      }}
                    >
                      <i
                        aria-hidden="true"
                        className="red large fitted icon trash alternate"
                      ></i>
                      Delete
                    </span>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider style={{ margin: "0px", marginTop: "2px" }} />

            <div className="fxDisplay fxFixed">
              <div className="fxDisplay fxFixed">
                {product ? (
                  <Image
                    alt="Product Logo"
                    src={
                      getImageUrlPlain(product?.marketId, product?.imageUrl) ??
                      `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                    }
                    onError={({ currentTarget }: any) => {
                      currentTarget.onerror = null;
                      currentTarget.src =
                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                    }}
                    className="fxFixed"
                    style={{
                      maxWidth: "225px",
                      maxHeight: "250px",
                      alignSelf: "center",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <Image
                    src={
                      `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                    }
                  />
                )}
              </div>

              <div
                className="fxStretch fxDisplay  fxDirCol"
                style={{ padding: "1em" }}
              >
                {loading && <LoadingParagraph lines={7} />}
                {product && (
                  <div className="fxDisplay">
                    <div className="subText">
                      {product.lastUpdated && (
                        <p>
                          <b>Last Update :</b>{" "}
                          {formatRelative(
                            new Date(product.lastUpdated * 1000),
                            new Date(),
                            {}
                          )}
                        </p>
                      )}
                      <p>
                        <b>SKU :</b> {product.sku ?? "-"}
                      </p>
                      <p>
                        <b>Internal Id :</b> {product.internalId}
                      </p>
                      <p>
                        <b>Price :</b> {currencyFormat(product.currentPrice)}
                      </p>
                      <p>
                        <b>Association Id :</b> {product.associationId ?? "-"}
                      </p>
                      <p>
                        <b>Labels :</b> {product.labels?.join(", ") ?? "-"}
                      </p>
                      <p>
                        <b>Units :</b>{" "}
                        {product.units
                          ?.map((x) => `${x.value ?? 0}/${x.unit ?? "-"}`)
                          .join(", ")}
                      </p>
                      {product.discontinued && (
                        <p>
                          <Label color="red" basic content="Discontinued" />
                        </p>
                      )}
                    </div>
                    <div style={{ marginLeft: "25px" }}>
                      <p>
                        <b>Category Id :</b> {product.categoryId ?? "-"}
                      </p>
                      ?: string;                    </div>
                  </div>
                )}
              </div>
              <hr />
              <div
                className="fxFixed leftSection fxAlignCenter"
                style={{ padding: "1.1em 1.5em", width: "10%" }}
              >
                <div style={{ marginBottom: "0.5em" }}>
                  {product ? (
                    <Fragment>
                      <Image
                        alt="Product Logo"
                        src={getMarketImageUrl(
                          markets?.filter(
                            (f) => f.type_id === product.marketId
                          )[0]
                        )}
                      />
                      <p>
                        {
                          markets?.filter(
                            (f) => f.type_id === product.marketId
                          )[0].title
                        }
                      </p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Image
                        alt="Product Logo"
                        centered
                        style={{ padding: "3px" }}
                        size="tiny"
                      />
                      <LoadingParagraph />
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            {createOpen && (
              <Fragment>
                <Divider />
                <Header>Fast Group Creation</Header>
                {product && (
                  <NewAssociationWidget
                    loading={loading}
                    byWeight={byWeight}
                    fastMode={true}
                    approveAssociation={(values) => convertToGroup(values)}
                    products={[product as any]}
                  />
                )}
              </Fragment>
            )}
          </Segment>
        </Sticky>

        {!existingGroupsView && (
          <Fragment>
            <Divider />
            <Associations mode="select" />
          </Fragment>
        )}
        {product?.groups &&
          product?.groups.length > 0 &&
          existingGroupsView && (
            <Fragment>
              <Header content="Associations" />
              <Divider />
              {product.groups
                .slice()
                .sort((a, b) => (a.score < b.score ? 1 : -1))
                .map((x) => (
                  <Segment key={x.id}>
                    <Grid>
                      <Grid.Row columns={1}>
                        <Grid.Column textAlign="right">
                          <div className="ui green tiny compact text labeled icon menu">
                            <span
                              title="Approve"
                              className="item"
                              onClick={() => {
                                approveGroupRelation(x.id);
                              }}
                            >
                              <i
                                aria-hidden="true"
                                className="green chain large fitted icon"
                              ></i>
                              Approve
                            </span>
                            <span
                              title="Remove Group"
                              className="item"
                              onClick={() => {
                                openConfirmationModal(
                                  "Confirm relation removal",
                                  "Are you sure you want to remove this relation?",
                                  () =>
                                    removeGroupRelation(x.id).then(() =>
                                      closeConfirmationalModal()
                                    ),
                                  "Cancel",
                                  "Remove",
                                  ButtonTypes.None,
                                  ButtonTypes.Negative
                                );
                              }}
                            >
                              <i
                                aria-hidden="true"
                                className="red large fitted icon trash alternate"
                              ></i>
                              Remove Group
                            </span>

                            <span
                              className="item"
                              title="Expand/Compress"
                              onClick={() => toggleExpanded(x.id)}
                            >
                              <i
                                aria-hidden="true"
                                className={`grey large fitted icon ${expandedIds.findIndex((z) => z === x.id) > -1
                                  ? "compress"
                                  : "expand"
                                  }`}
                              ></i>
                              {expandedIds.findIndex((z) => z === x.id) > -1
                                ? "Compress"
                                : "Expand"}
                            </span>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Table basic="very">
                      <Table.Header>
                        <Table.HeaderCell>
                          <b>Title :</b> {x.title}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <b>Score :</b> {x.score}
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan="2">
                          {x.labels && (
                            <p>
                              <b>Labels :</b>
                              <Highlighter
                                caseSensitive={false}
                                highlightStyle={{
                                  background: "#ffb7b7",
                                }}
                                searchWords={product.labels ?? []}
                                autoEscape={true}
                                textToHighlight={x.labels.join(", ")}
                              />
                            </p>
                          )}
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan="2">
                          {x.units && (
                            <p>
                              <b>Units :</b>{" "}
                              <Highlighter
                                caseSensitive={false}
                                highlightStyle={{
                                  background: "#ffb7b7",
                                }}
                                searchWords={
                                  product.units
                                    ? [
                                      ...product.units.map((m) => m.unit),
                                      ...product.units.map((m) => m.value),
                                    ]
                                    : []
                                }
                                autoEscape={true}
                                textToHighlight={x.units
                                  .map(
                                    (x) => `${x.value ?? 0}/${x.unit ?? "-"}`
                                  )
                                  .join(", ")}
                              />
                            </p>
                          )}
                        </Table.HeaderCell>
                      </Table.Header>
                      <Table.Header>
                        <Table.HeaderCell colSpan="5">
                          SKUs :{" "}
                          {Array.from(
                            new Set(x.products?.map((z) => z.sku))
                          ).join(",")}
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan="1">
                          <Label size="large" color="blue">
                            {(x.products
                              ?.map((z) => z.marketId)
                              .indexOf(product.marketId) ?? 0) < 0
                              ? `${markets?.filter(
                                (f) => f.type_id === product.marketId
                              )[0].title
                              } is missing`
                              : `${markets?.filter(
                                (f) => f.type_id === product.marketId
                              )[0].title
                              } is present`}
                          </Label>
                        </Table.HeaderCell>
                      </Table.Header>
                      <Table.Body>
                        {x.products &&
                          x.products.map(
                            (f, i) =>
                              (i < 1 ||
                                expandedIds.findIndex((z) => z === x.id) >
                                -1) && (
                                <Table.Row key={f.id}>
                                  <Table.Cell>
                                    <Header as="h4" image>
                                      <Popup
                                        trigger={
                                          <Image
                                            src={getImageUrlPlain(
                                              f.marketId,
                                              f.imageUrl
                                            )}
                                            alt="Product Logo"
                                            rounded
                                            size="mini"
                                          />
                                        }
                                        content={
                                          <Image
                                            src={
                                              getImageUrlPlain(
                                                f?.marketId,
                                                f?.imageUrl
                                              ) ??
                                              `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                            }
                                            onError={({
                                              currentTarget,
                                            }: any) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src =
                                                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                            }}
                                            alt="Product Logo"
                                            rounded
                                          />
                                        }
                                      />
                                      <Header.Content>
                                        {f.itemDesc}
                                      </Header.Content>
                                    </Header>
                                  </Table.Cell>
                                  <Table.Cell>
                                    {formatRelative(
                                      new Date(f.lastUpdated * 1000),
                                      new Date(),
                                      {}
                                    )}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {f.discontinued && (
                                      <p>
                                        <Label
                                          color="red"
                                          basic
                                          content="Discontinued"
                                        />
                                      </p>
                                    )}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Fragment>
                                      <p>
                                        <b>SKU :</b> {f.sku ?? "-"}
                                      </p>
                                      <p>
                                        <b>Internal Id :</b> {f.internalId}
                                      </p>
                                    </Fragment>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Fragment>
                                      <Image
                                        alt="Product Logo"
                                        size="tiny"
                                        src={getMarketImageUrl(
                                          markets?.filter(
                                            (z) => z.type_id === f.marketId
                                          )[0]
                                        )}
                                      />
                                      <p>
                                        {
                                          markets?.filter(
                                            (z) => z.type_id === f.marketId
                                          )[0].title
                                        }
                                      </p>
                                    </Fragment>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <div
                                      style={{
                                        marginBottom: "0.5em",
                                        fontSize: "17px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      <Icon name="euro" />
                                      {currencyFormat(f.currentPrice, false)}
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                              )
                          )}
                      </Table.Body>
                    </Table>
                  </Segment>
                ))}
            </Fragment>
          )}
        {product?.possibleGroups &&
          product?.possibleGroups.length > 0 &&
          existingGroupsView && (
            <Fragment>
              <Header content="Possible Associations" />
              <Divider />
              {product.possibleGroups
                .slice()
                .sort((a, b) => (a.score < b.score ? 1 : -1))
                .map((x) => (
                  <Segment key={x.id}>
                    <Grid>
                      <Grid.Row columns={1}>
                        <Grid.Column textAlign="right">
                          <div className="ui green tiny compact text labeled icon menu">
                            <span
                              title="Approve"
                              className="item"
                              onClick={() => {
                                approvePossibleGroupRelation(x.id);
                              }}
                            >
                              <i
                                aria-hidden="true"
                                className="green chain large fitted icon"
                              ></i>
                              Approve
                            </span>
                            <span
                              title="Remove Group"
                              className="item"
                              onClick={() => {
                                openConfirmationModal(
                                  "Confirm relation removal",
                                  "Are you sure you want to remove this relation?",
                                  () =>
                                    removePossibleGroupRelation(x.id).then(() =>
                                      closeConfirmationalModal()
                                    ),
                                  "Cancel",
                                  "Remove",
                                  ButtonTypes.None,
                                  ButtonTypes.Negative
                                );
                              }}
                            >
                              <i
                                aria-hidden="true"
                                className="red large fitted icon trash alternate"
                              ></i>
                              Remove Group
                            </span>

                            <span
                              className="item"
                              title="Expand/Compress"
                              onClick={() => toggleExpanded(x.id)}
                            >
                              <i
                                aria-hidden="true"
                                className={`grey large fitted icon ${expandedIds.findIndex((z) => z === x.id) > -1
                                  ? "compress"
                                  : "expand"
                                  }`}
                              ></i>
                              {expandedIds.findIndex((z) => z === x.id) > -1
                                ? "Compress"
                                : "Expand"}
                            </span>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Segment.Group>
                      <Segment.Group horizontal>
                        <Segment>
                          <p>
                            <b>SKU :</b> {x.sku}
                          </p>
                        </Segment>
                        <Segment>
                          <p>
                            <b>Score :</b> {x.score}
                          </p>
                        </Segment>
                      </Segment.Group>
                      <Segment.Group horizontal>
                        <Segment>
                          {x.labels && (
                            <p>
                              <b>Labels :</b>
                              <Highlighter
                                caseSensitive={false}
                                highlightStyle={{
                                  background: "#ffb7b7",
                                }}
                                searchWords={product.labels ?? []}
                                autoEscape={true}
                                textToHighlight={x.labels.join(", ")}
                              />
                            </p>
                          )}
                        </Segment>
                        <Segment>
                          {x.units && (
                            <p>
                              <b>Units :</b>{" "}
                              <Highlighter
                                caseSensitive={false}
                                highlightStyle={{
                                  background: "#ffb7b7",
                                }}
                                searchWords={
                                  product.units
                                    ? [
                                      ...product.units.map((m) => m.unit),
                                      ...product.units.map((m) => m.value),
                                    ]
                                    : []
                                }
                                autoEscape={true}
                                textToHighlight={x.units
                                  .map(
                                    (x) => `${x.value ?? 0}/${x.unit ?? "-"}`
                                  )
                                  .join(", ")}
                              />
                            </p>
                          )}
                        </Segment>
                      </Segment.Group>
                    </Segment.Group>

                    <Segment>
                      <Table basic="very" celled>
                        <Table.Body>
                          {x.products &&
                            x.products.map(
                              (f, i) =>
                                (i < 1 ||
                                  expandedIds.findIndex((z) => z === x.id) >
                                  -1) && (
                                  <Table.Row key={f.id}>
                                    <Table.Cell>
                                      <Header as="h4" image>
                                        <Popup
                                          trigger={
                                            <Image
                                              src={getImageUrlPlain(
                                                f.marketId,
                                                f.imageUrl
                                              )}
                                              alt="Product Logo"
                                              rounded
                                              size="mini"
                                            />
                                          }
                                          content={
                                            <Image
                                              src={
                                                getImageUrlPlain(
                                                  f?.marketId,
                                                  f?.imageUrl
                                                ) ??
                                                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                              }
                                              onError={({
                                                currentTarget,
                                              }: any) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src =
                                                  `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                              }}
                                              alt="Product Logo"
                                              rounded
                                            />
                                          }
                                        />
                                        <Header.Content>
                                          {f.itemDesc}
                                        </Header.Content>
                                      </Header>
                                    </Table.Cell>
                                    <Table.Cell>
                                      {formatRelative(
                                        new Date(f.lastUpdated * 1000),
                                        new Date(),
                                        {}
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {f.discontinued && (
                                        <p>
                                          <Label
                                            color="red"
                                            basic
                                            content="Discontinued"
                                          />
                                        </p>
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Fragment>
                                        <p>
                                          <b>SKU :</b> {f.sku ?? "-"}
                                        </p>
                                        <p>
                                          <b>Internal Id :</b> {f.internalId}
                                        </p>
                                      </Fragment>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Fragment>
                                        <Image
                                          alt="Product Logo"
                                          size="tiny"
                                          src={getMarketImageUrl(
                                            markets?.filter(
                                              (z) => z.type_id === f.marketId
                                            )[0]
                                          )}
                                        />
                                        <p>
                                          {
                                            markets?.filter(
                                              (z) => z.type_id === f.marketId
                                            )[0].title
                                          }
                                        </p>
                                      </Fragment>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <div
                                        style={{
                                          marginBottom: "0.5em",
                                          fontSize: "17px",
                                          fontWeight: "bolder",
                                        }}
                                      >
                                        <Icon name="euro" />
                                        {currencyFormat(f.currentPrice, false)}
                                      </div>
                                    </Table.Cell>
                                  </Table.Row>
                                )
                            )}
                        </Table.Body>
                      </Table>
                    </Segment>
                  </Segment>
                ))}
            </Fragment>
          )}
      </Segment>
    </Ref>
  );
};

export default observer(OrphanProducts);
