import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { IOrderItemDetail } from '../../../interfaces/order'
import { Label, Table } from 'semantic-ui-react'
import { ClientOrderProductStatus } from '../../../options/HubOrderProductStatus'
import { currencyFormat } from '../../products/functions/productHelper'
import { ICatalogueForOrder } from '../../../interfaces/catalogue'
import { generateQuantity } from '../../../helpers/productTitleHelper'
import { RootStoreContext } from '../../../stores/RootStoreContext'

interface IProps {
    item: IOrderItemDetail;
    product: ICatalogueForOrder;
    replacedProduct?: ICatalogueForOrder;
}

const OrderDetailTableRow: React.FC<IProps> = ({ item, product, replacedProduct }) => {
    const context = useContext(RootStoreContext);
    const { measurements } = context.cacheStore;
    const { isMobile } = context.deviceStore;

    return (
        <Table.Row key={item.catalogueId}>
            {isMobile ?
                <Table.Cell>
                    {product.title} {generateQuantity(measurements, product.measurment, product.measurementUnit)}
                    {item.origin === "replacement" && <div className='ct-green-text-dark'>
                        Replacement for {replacedProduct?.title}
                    </div>}
                    <div style={{ marginTop: "4px" }} className='fxDisplay fxFixed fxAlignCenter fxJustifyBetween'>
                        <div>Unit price</div>
                        <div className='fxJustifyCenter'>
                            {currencyFormat(item.currentPrice)}
                        </div>
                    </div>
                    <div style={{ marginTop: "4px" }} className='fxDisplay fxFixed fxAlignCenter fxJustifyBetween'>
                        <div>Total price</div>
                        <div>{currencyFormat((item.packedAmount ?? item.quantity) * item.currentPrice)}</div>
                    </div>
                    <div style={{ marginTop: "4px" }} className='fxDisplay fxFixed fxAlignCenter fxJustifyBetween'>
                        <div>
                            <Label size='small' color={ClientOrderProductStatus.filter(f => f.value === (item.status ?? "pending"))[0].color}>
                                {ClientOrderProductStatus.filter(f => f.value === (item.status ?? "pending"))[0].text}
                            </Label>
                        </div>
                        <div>
                            <span style={{ fontSize: "17px", fontWeight: "bold" }}>{item.packedAmount ?? 0} of {item.quantity ?? 0}</span>
                        </div>
                    </div>
                </Table.Cell> :
                <Fragment>
                    <Table.Cell>
                        {product.title} {generateQuantity(measurements, product.measurment, product.measurementUnit)}
                        {item.origin === "replacement" && <div className='ct-green-text-dark'>
                            Replacement for {replacedProduct?.title}
                        </div>}
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                        <Label size='large' color={ClientOrderProductStatus.filter(f => f.value === (item.status ?? "pending"))[0].color}>
                            {ClientOrderProductStatus.filter(f => f.value === (item.status ?? "pending"))[0].clientText}
                        </Label>
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                        <span style={{ fontSize: "17px", fontWeight: "bold" }}>{item.packedAmount ?? 0}</span>
                        <div>of {item.quantity ?? 0}</div>
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                        {currencyFormat(item.currentPrice)}
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                        {currencyFormat((item.packedAmount ?? item.quantity) * item.currentPrice)}
                    </Table.Cell>
                </Fragment>}
        </Table.Row >
    )
}

export default observer(OrderDetailTableRow);