import { formatRelative } from "date-fns";
import React, { Fragment, useContext } from "react";
import { Header, Table, Image, Icon, TableCell } from "semantic-ui-react";
import { getImageUrl } from "../../helpers/productImage";
import { IProductForSimilarity } from "../../interfaces/product";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { currencyFormat } from "../products/functions/productHelper";
import Highlighter from "react-highlight-words";

interface IProps {
  parentTitle: string;
  product: IProductForSimilarity;
  selected: boolean;
  setSelected: (id: string) => void;
}

export const WizardProductTableRow: React.FC<IProps> = ({
  product,
  selected,
  setSelected,
  parentTitle,
}) => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;

  return (
    <Table.Row>
      <Table.Cell>
        <Image
          alt="Product Logo"
          src={
            getImageUrl(product) ??
            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
          }
          onError={({ currentTarget }: any) => {
            currentTarget.onerror = null;
            currentTarget.src =
              `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
          }}
          rounded
          size="mini"
        />

        <Highlighter
          caseSensitive={false}
          highlightStyle={{
            background: "#ffb7b7",
          }}
          searchWords={parentTitle.split(" ")}
          autoEscape={true}
          textToHighlight={product.itemDesc}
        />
      </Table.Cell>
      <Table.Cell>{product.internalId}</Table.Cell>
      <Table.Cell>
        <Header as="h4" image>
          <Header.Content>
            {markets.filter((f) => f.type_id === product.marketId)[0].title}
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell>
        {product.lastUpdated && (
          <Fragment>
            {formatRelative(new Date(product.lastUpdated * 1000), new Date())}
          </Fragment>
        )}
      </Table.Cell>
      <Table.Cell>
        <div
          style={{
            marginBottom: "0.5em",
            fontSize: "17px",
            fontWeight: "bolder",
          }}
        >
          <Icon name="euro" />
          {currencyFormat(product.currentPrice, false)}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div
          style={{
            marginBottom: "0.5em",
            fontSize: "17px",
            fontWeight: "bolder",
          }}
        >
          {currencyFormat(product.score, false)}
        </div>
      </Table.Cell>

      <TableCell>
        <div>
          <Icon
            circular
            inverted={selected}
            color={selected ? "green" : "grey"}
            name="check"
            onClick={() => setSelected(product.id)}
          />
        </div>
      </TableCell>
    </Table.Row>
  );
};
