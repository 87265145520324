import { ISuperMarket } from "../interfaces/supermarket";

export const getMarketImageUrl = (item: ISuperMarket) => {
    if (!item?.imageUrl || item?.imageUrl === "") return undefined;

    if (item.createdOn && !item.imageUrl?.startsWith("http")) {
        var storageItem = localStorage.getItem(item.imageUrl);
        if (storageItem) {
            var obj = JSON.parse(storageItem);
            return obj.value;
        }
    }
    else
        return item?.imageUrl;
};
