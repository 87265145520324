import { observer } from 'mobx-react-lite'
import React, { Fragment, useEffect } from 'react'
import agent from '../../../api/agent';
import { Divider, Header, Segment } from 'semantic-ui-react';
import { format, isTomorrow, isToday } from 'date-fns';
import { IDeliverySlot } from '../../../interfaces/delivery';
import { IAddress } from '../../../interfaces/user';

interface IProps {
    locations: string[],
    address?: IAddress,
    deliverySlotId?: string,
    setDeliverySlot: (id: string) => void
}

const DeliveryTimeSelector: React.FC<IProps> = ({
    locations,
    deliverySlotId,
    address,
    setDeliverySlot
}) => {

    const [loading, setLoading] = React.useState(false);
    const [slots, setSlots] = React.useState<IDeliverySlot[]>([]);

    const get_next_date_delivery = (date: Date) => {
        return `${date.toLocaleDateString('en-US', { weekday: 'long' })}`
    }

    const get_next_slots = (localityId: number, loc: string[]) => {
        setLoading(true);
        agent.Scheduler.get((new Date().getTime() / 1000), localityId, locations).then((result) => {
            setSlots(result);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (!address) return;

        get_next_slots(address.localityId, locations);
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address])

    return (
        <Fragment>
            <Header as='h4'>Select a Delivery Slot</Header>
            {loading ? <div className="timeline-container">

            </div> : <div className="timeline-container">
                <Segment.Group horizontal className='invisible-segment'>
                    {slots.map((slot, index) => (
                        <div key={index}
                            className={`time-slot`}
                            onClick={() => {
                                setDeliverySlot(slot.id!)
                            }}>
                            <Segment
                                style={{ paddingTop: "3px", height: "85px", width: "90px" }}
                                className={`${deliverySlotId === slot.id ? 'selected' : ''} `}
                                raised>
                                <b>{isToday(new Date(slot.from * 1000)) ? "Today" : (isTomorrow(new Date(slot.from * 1000)) ? "Tomorrow" : get_next_date_delivery(new Date(slot.from * 1000)))}</b>
                                <div style={{ fontSize: "11px", fontWeight: "100" }}>{new Date(slot.from * 1000).getDate()}/{new Date(slot.from * 1000).getMonth() + 1}</div>
                                <Divider style={{ margin: '0px', marginTop: "3px" }} />
                                <div style={{ fontSize: "13px" }}>{`${format(new Date(slot.from * 1000), 'H')} to ${format(new Date(slot.to * 1000), 'HH')} h`}</div>
                            </Segment>
                        </div>
                    ))}
                </Segment.Group>
            </div>
            }
        </Fragment>);
}

export default observer(DeliveryTimeSelector)