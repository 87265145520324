import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { Button, Divider, Header, Icon, Segment } from 'semantic-ui-react';
import { RootStoreContext } from '../../stores/RootStoreContext';
import UserDietProfileSetup from '../mealplanner/components/UserDietProfileSetup';
// import { useNavigate } from 'react-router';
import ChatUI from '../chat/ChatUI';
import { requestUserSession } from '../registration/functions/sessionFnc';

const AILanding = () => {
    // const navigate = useNavigate();
    const context = useContext(RootStoreContext);
    const { setPage } = context.analyticsStore;
    const { profile, loading } = context.userDietProfileStore;
    const { isLoggedIn } = context.sessionStore;
    const { openModal, closeModal } = context.modalStore;
    const { isMobile } = context.deviceStore;

    useEffect(() => {
        setPage("HabibAi-Landing", window.location.pathname);
        // document.body.style.overflow = 'hidden';  

        return () => {
            // document.body.style.overflow = 'auto';
        }
    }, [])

    if (!isLoggedIn)
        return <div className="welcome-card-container fxDirCol fxFixed fxAlignCenter fxTextAlignCenter"
            style={{ marginTop: "15%" }}>
            <Segment style={{ maxWidth: "550px", margin: "auto" }}>
                <Header content="A free account is required to access this page!" />
                <div>
                    Hi there, thank you for being here, to start chatting with HabibAi you need to sign in with your account.
                    <Divider hidden />
                    <div style={{ textAlign: "center" }}>
                        <Button
                            content="Sign In"
                            className="ct-green-button-active"
                            onClick={() => {
                                requestUserSession(
                                    isMobile,
                                    openModal,
                                    closeModal,
                                    () => {
                                        closeModal();
                                    }
                                );
                            }}
                        />
                    </div>
                </div>
            </Segment>
        </div>

    return (
        loading ?
            <div className="welcome-card-container fxDirCol"
                style={{ marginTop: "15%", textAlign: "center" }}>
                <Icon
                    name={loading ? "circle notch" : "download"}
                    color="green"
                    loading={loading}
                    size='huge'
                />
                <Header>We are cooking, hang in there...</Header>
            </div> : ((profile?.id ?? undefined) === undefined ?
                <UserDietProfileSetup /> : <ChatUI />
                // <div>
                //     <div className="welcome-card-container fxDirCol" style={{ marginTop: "15%" }}>
                //         <Image src="logo-chef-hat.svg" style={isMobile ? { width: "85%" } : { marginLeft: "-26px", width: "25%" }} />
                //     </div>

                //     <div className="welcome-card-container" style={{ marginTop: "25px" }}>
                //         <div className="welcome-card clickable" onClick={() => {
                //             navigate("/habibai/meal-planning")
                //         }}>
                //             <Image src="chef-book.png" alt="icon" />
                //             <p>Meal planning</p>
                //         </div>
                //         <div className="welcome-card clickable" onClick={() => {
                //             navigate("/habibai/chat")
                //         }}>
                //             <Image src="pancakes.png" alt="icon" />
                //             <p>Chat with HabibAi for recipe ideas or recommendations</p>
                //         </div>
                //     </div>
                // </div>
            )
    )
}

export default observer(AILanding);