import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { IStabbiltaForList } from "../../../interfaces/stabbilta";

export class StabbiltaStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable orderCheapest: boolean = false;
    @observable products: IStabbiltaForList[] = []
    @observable searchText: string = "";

    @action setLoading = (value: boolean) => this.loading = value;
    @action setOrderCheapest = (value: boolean) => this.orderCheapest = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetProducts = (value: IStabbiltaForList[]) => this.products = value;
    @action setProducts = (value: IStabbiltaForList[]) => {
        var existing = this.products.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.products.push(...missing);
    };

    @action load = async (stems?: string) => {
        try {
            if (stems) {
                this.searchText = stems
            }

            this.setLoading(true);

            var response = await agent.Stabbilta.get(this.searchText, [], this.orderCheapest, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setProducts(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action changeOrder = async () => {
        try {
            this.setOrderCheapest(!this.orderCheapest);

            this.resetProducts([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetProducts([]);
            this.setCurrentPage(0)

            this.load();

            if (text !== "" && text !== undefined)
                this.rootStore.analyticsStore.setSearchEvent("Catalogue", text);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.searchText = ""
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetProducts([]);
        this.setOrderCheapest(false);
        this.setHasMore(false);
    }
}