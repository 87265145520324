import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, Fragment, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { isMobile } from 'react-device-detect';
import { Menu, Divider, Table, Icon, Input, Header, Button, Dropdown } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component'
import { currencyFormat } from '../../products/functions/productHelper';
import CsvExport from '../../../common/csvExport';
import { DateTimePicker } from "react-widgets";
import { format } from 'date-fns';
import { NoResults } from '../../../common/NoResults';
import { ReceiptsReportStore } from '../functions/ReceiptsReportStore';
import { getNetDetail, getVatDetail, sumNetDiscOrNet, sumVatDiscOrVat } from '../../../interfaces/report';
import agent from '../../../api/agent';
import { PaymentOptions } from '../../../options/PaymentOptions';
import { toast } from 'react-toastify';

const ReceiptsReport = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isAdmin, isOwner } = context.userStore;

    const {
        items,
        hasMore,
        load,
        exportDataForCsv,
        downloading,
        loading,
        setDateFrom,
        setDateTo,
        search,
        dateFrom,
        dispose } = ReceiptsReportStore;

    useEffect(() => {
        return () => {
            dispose()
        }
    }, [isAdmin, navigate, dispose, load])

    const [timer, setTimer] = React.useState(0);
    const [forcePaymentChange, setForcePaymentChange] = React.useState(false);
    const [valueFrom, setValueFrom] = React.useState<Date | null | undefined>();
    const [valueTo, setValueTo] = React.useState<Date | null | undefined>();

    const downloadPdf = async (orderId: string) => {
        try {
            const response = await agent.Orders.download_receipt(orderId);
            // If using presigned URL
            window.open(response.url, '_blank');
            // If directly transferring file, you'll need to handle blob response and create a download link
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };


    return (
        <div className="simply-paddedPage">
            <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "0 0 5px 0" }}
            >
                <Menu.Item
                    className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}
                >
                    <Input
                        fluid={isMobile}
                        transparent={isMobile}
                        size={isMobile ? "large" : "small"}
                        icon={
                            !isMobile ? <Icon name="search" color="green" /> : undefined
                        }
                        placeholder={"User Id or Receipt Number..."}
                        input={
                            <input className={isMobile ? "" : "bluePlaceholder"} />
                        }
                        onChange={(event: ChangeEvent, data: any) => {
                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: (text: string) => void, text: string) => {
                                        callback(text);
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search,
                                    data.value
                                )
                            );
                        }}
                    />
                </Menu.Item>
                <Menu.Item className={"no-padding"}>
                    <DateTimePicker
                        value={valueFrom}
                        placeholder={"Date From"}
                        onChange={(date: Date | null | undefined, rawValue: string) => {
                            // date?.setUTCHours(0, 0, 0, 1);
                            var dateNum = date?.getTime() ?? undefined;
                            setValueFrom(date);
                            setDateFrom((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: () => void, text: string) => {
                                        callback();
                                        clearTimeout(timer);
                                    },
                                    1000,
                                    search
                                )
                            );
                        }}
                        includeTime={true}
                        timePrecision={'minutes'}
                        max={new Date()}
                    />
                </Menu.Item>
                <Menu.Item className={"no-padding"}>
                    <DateTimePicker
                        placeholder={"Date To"}
                        value={valueTo}
                        onChange={(date: Date | null | undefined, rawValue: string) => {
                            // date?.setUTCHours(23, 59, 59, 999);
                            setValueTo(date);
                            var dateNum = date?.getTime() ?? undefined;
                            setDateTo((dateNum ?? 0) > 0 ? (dateNum! / 1000) : dateNum);

                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: () => void, text: string) => {
                                        callback();
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search
                                )
                            );
                        }}
                        includeTime={true}
                        timePrecision={'minutes'}
                        max={new Date()}
                        min={dateFrom ? new Date(dateFrom * 1000) : undefined}
                    />
                </Menu.Item>
                <Menu.Item>
                    <Button
                        color="green"
                        icon='refresh'
                        basic
                        className="no-margin"
                        onClick={() => search()}
                    />
                </Menu.Item>
                {isOwner && <Menu.Item>
                    <Button
                        color="green"
                        icon='euro'
                        basic={!forcePaymentChange}
                        className="no-margin"
                        onClick={() => setForcePaymentChange(!forcePaymentChange)}
                    />
                </Menu.Item>}
                <Menu.Menu position='right'>
                    <Menu.Item icon title="Export" style={{ padding: "0px" }}>
                        <CsvExport
                            onClick={exportDataForCsv}
                            content={
                                <Fragment>
                                    <Icon name="file excel outline" /> Export
                                </Fragment>
                            }
                            disabled={items.length === 0 || downloading}
                            loading={downloading}
                        />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider />
            <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={items.length} //This is important field to render the next data
                next={() => load()}
                hasMore={hasMore}
                loader={loading ? <h4>Loading...</h4> : <div />}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        {items.length > 0 ? (
                            <b></b>
                        ) : (
                            <NoResults />
                        )}
                    </p>
                }
            >
                <Table celled  >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan='2'>Receipt No</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>Paid By</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>Date</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>Customer</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2' textAlign='center'>Exempt 0%</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2' textAlign='center'>BCRS</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2' textAlign='center'>Reduced 5%</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2' textAlign='center'>Full 18%</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2' textAlign='center'>Totals</Table.HeaderCell>
                            <Table.HeaderCell style={{ maxWidth: "55px" }} rowSpan='2' textAlign='center'>Delivery Cost (inc VAT)</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>Total</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'></Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>Net</Table.HeaderCell>
                            <Table.HeaderCell>Vat</Table.HeaderCell>
                            <Table.HeaderCell>Net</Table.HeaderCell>
                            <Table.HeaderCell>Vat</Table.HeaderCell>
                            <Table.HeaderCell>Net</Table.HeaderCell>
                            <Table.HeaderCell>Vat</Table.HeaderCell>
                            <Table.HeaderCell>Net</Table.HeaderCell>
                            <Table.HeaderCell>Vat</Table.HeaderCell>
                            <Table.HeaderCell>Net</Table.HeaderCell>
                            <Table.HeaderCell>Vat</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items.map((x) => {
                            return (
                                <Table.Row key={x.id}>
                                    <Table.Cell>{x.receiptNo ?? "-"}</Table.Cell>
                                    <Table.Cell>{PaymentOptions.filter(f => f.key === x.paidBy)[0]?.text ?? "-"}</Table.Cell>
                                    <Table.Cell>
                                        {(x.createdOn ?? 0) > 0 ? format(new Date(x.createdOn! * 1000), "dd/MM/yyyy HH:mm") : "-"}
                                    </Table.Cell>
                                    <Table.Cell style={{
                                        color: "green",
                                        textDecoration: "underline"
                                    }} className='clickable' onClick={() =>
                                        window.open(`${window.location.origin}/manage-user/${x.userId}`, '_blank', 'noopener,noreferrer')}>
                                        <Header>
                                            {`${x.name} ${x.lastName}`}
                                            <Header.Subheader>
                                                {x.userId}
                                            </Header.Subheader>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{currencyFormat(getNetDetail(x.vat_rates, "E"))}</Table.Cell>
                                    <Table.Cell>{currencyFormat(getVatDetail(x.vat_rates, "E"))}</Table.Cell>
                                    <Table.Cell>{currencyFormat(getNetDetail(x.vat_rates, "M"))}</Table.Cell>
                                    <Table.Cell>{currencyFormat(getVatDetail(x.vat_rates, "M"))}</Table.Cell>
                                    <Table.Cell>{currencyFormat(getNetDetail(x.vat_rates, "R"))}</Table.Cell>
                                    <Table.Cell>{currencyFormat(getVatDetail(x.vat_rates, "R"))}</Table.Cell>
                                    <Table.Cell>{currencyFormat(getNetDetail(x.vat_rates, "F"))}</Table.Cell>
                                    <Table.Cell>{currencyFormat(getVatDetail(x.vat_rates, "F"))}</Table.Cell>
                                    <Table.Cell>{currencyFormat(sumNetDiscOrNet(x.vat_rates), true)}</Table.Cell>
                                    <Table.Cell>{currencyFormat(sumVatDiscOrVat(x.vat_rates), true)}</Table.Cell>
                                    <Table.Cell style={x.checkoutType === "delivery" ? {} : { color: "grey" }} >{x.checkoutType === "delivery" ? currencyFormat(x.deliveryFee ?? 0, true) : "Pickup"}</Table.Cell>
                                    <Table.Cell>{currencyFormat(sumVatDiscOrVat(x.vat_rates) + sumNetDiscOrNet(x.vat_rates), true)}</Table.Cell>
                                    <Table.Cell>
                                        {(!x.paidBy || forcePaymentChange) && <Dropdown style={{ color: "red" }}
                                            search
                                            icon={"euro"}
                                            options={PaymentOptions}
                                            onChange={(e, data) => {
                                                agent.Orders.Admin.Monitor.setPaymentType(x.id, data.value as string).then(() => toast.success("Updated!"));
                                            }}
                                        />}
                                        <Icon disabled={!x.receiptNo} name='download' onClick={() => {
                                            downloadPdf(x.id);
                                        }} /></Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </InfiniteScroll>
        </div >
    )
}

export default observer(ReceiptsReport)