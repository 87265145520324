import React, { useContext } from "react";
import { Grid, Form, Button } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { IShopUserForListDto } from "../../../interfaces/user";
import TextInput from "../../../form/TextInput";
import ErrorMessage from "../../../form/ErrorMessage";
import SelectInput from "../../../form/SelectInput";
import { ShopUserRoleOptions } from "../../../options/ShopUserRoleOptions";
import { ButtonTypes } from "../../confirmationModal/functions/ButtonTypes";

const validate = combineValidators({
  roleId: isRequired(""),
});

interface IProps {
  item: IShopUserForListDto;
}

const ManageShopUserWidget: React.FC<IProps> = ({ item }) => {
  const context = useContext(RootStoreContext);
  const { updateUser, unlinkUser, deleteUser } = context.shopUserStore;
  const { closeModal } = context.modalStore;
  const { isShopAdmin } = context.userStore;
  const { openConfirmationModal, closeConfirmationalModal } =
    context.confirmationModalStore;

  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column>
          <FinalForm
            onSubmit={(values: IShopUserForListDto) =>
              updateUser(values)
                .then(() => closeModal())
                .catch((error) => ({
                  [FORM_ERROR]: error,
                }))
            }
            validate={validate}
            initialValues={item}
            render={({
              handleSubmit,
              submitting,
              submitError,
              valid,
              dirtySinceLastSubmit,
            }) => (
              <Form onSubmit={handleSubmit} error>
                <Form.Field>
                  <label style={{ fontSize: "13px" }}>{"First Name"}</label>
                  <Field
                    name="firstName"
                    component={TextInput}
                    placeholder={"First Name"}
                    disabled={true}
                  />
                </Form.Field>
                <Form.Field>
                  <label style={{ fontSize: "13px" }}>{"Last Name"}</label>
                  <Field
                    name="lastName"
                    component={TextInput}
                    placeholder="Last Name"
                    disabled={true}
                  />
                </Form.Field>

                <Form.Field>
                  <label style={{ fontSize: "13px" }}>{"E-mail"}</label>
                  <Field
                    name="email"
                    component={TextInput}
                    placeholder={"E-mail"}
                    disabled={true}
                  />
                </Form.Field>

                <Form.Field>
                  <label style={{ fontSize: "13px" }}>{"Role"}</label>
                  <Field
                    name="roleId"
                    component={SelectInput}
                    options={ShopUserRoleOptions.map((section) => ({
                      key: section.key,
                      value: section.value,
                      text: section.text,
                      description: section.description,
                    }))}
                    multiselect={false}
                    placeholder={"Select Role"}
                  />
                </Form.Field>

                <Grid columns={2} centered style={{ marginBottom: "4px" }}>
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="center" style={{ padding: "0px" }}>
                      <Button
                        disabled={!isShopAdmin}
                        title="The user will still have access to Cheap Trolley but no connection or access to your shop"
                        onClick={(e) => {
                          e.preventDefault();
                          openConfirmationModal(
                            "Confirm action",
                            "Are you sure you want to remove this user from your shop? Later you can re-invite the user to your shop if you need.",
                            () =>
                              unlinkUser(item.id).then(() => {
                                closeConfirmationalModal();
                                closeModal();
                              }),
                            "Cancel",
                            "Unlink",
                            ButtonTypes.None,
                            ButtonTypes.Negative
                          );
                        }}
                        color="red"
                        className="button-as-link redColor"
                        content="Unlink account"
                      />
                      <p className="small-letters">
                        The user will still have access to Cheap Trolley but{" "}
                        <b>no connection or access to your shop</b>
                      </p>
                    </Grid.Column>
                    <Grid.Column textAlign="center" style={{ padding: "0px" }}>
                      <Button
                        disabled={!isShopAdmin}
                        title="The user will not have access to Cheap Trolley and all personal data will be anonymized"
                        onClick={(e) => {
                          e.preventDefault();
                          openConfirmationModal(
                            "Confirm account deletion",
                            "Are you sure you want to delete this user completely from Cheap Trolley? This action cannot be reverted.",
                            () =>
                              deleteUser(item.id).then(() => {
                                closeConfirmationalModal();
                                closeModal();
                              }),
                            "Cancel",
                            "Delete",
                            ButtonTypes.None,
                            ButtonTypes.Negative
                          );
                        }}
                        color="red"
                        className="button-as-link redColor"
                        content="Delete account"
                      />
                      <p className="small-letters">
                        The user access to Cheap Trolley will be removed and all
                        personal <b>data will be anonymized</b>
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage error={submitError} />
                )}
                <div style={{ textAlign: "center" }}>
                  <Button.Group>
                    <Button
                      disabled={!valid || !isShopAdmin}
                      loading={submitting}
                      content={"Update"}
                      color="blue"
                    />
                    <Button.Or />
                    <Button
                      disabled={submitting}
                      content={"Cancel"}
                      onClick={(e) => {
                        e.preventDefault();
                        closeModal();
                      }}
                    />
                  </Button.Group>
                </div>
              </Form>
            )}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(ManageShopUserWidget);
