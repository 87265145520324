import { action, makeObservable, observable } from "mobx";
import { RootStore } from "./RootStoreContext";

export class CommonStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable selectedMarketIds: string[] = [];
    @observable appFocused: boolean = true;

    @action setSelectedMarketIds = (value: string[]) => this.selectedMarketIds = value;
    @action setAppFocused = (value: boolean) => this.appFocused = value;


}