import { observer } from "mobx-react-lite";
import { Fragment, useState, useEffect } from "react";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, Image, Icon, Dropdown } from "semantic-ui-react";
import { camalize } from "../../helpers/textHelper";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { currencyFormat } from "../products/functions/productHelper";
import { requestUserSession } from "../registration/functions/sessionFnc";
import "./Header.css";
import Search from "../search/Search";

const HeaderMainMenu = () => {
  const context = useContext(RootStoreContext);
  const { menuLoading } =
    context.shoppingListStore;
  const { getShoppingCartCost, loadingShoppingCart } = context.shoppingCartStore;
  const { isLoggedIn, logout } = context.sessionStore;
  const { user, isAdmin, isOwner, accountId } = context.userStore;
  const { openModal, closeModal } = context.modalStore;
  const { balance } = context.clientCreditStore;
  const { state, setDrawerState } = context.searchStore;
  const { search, selectedBrands, selectedCategories } = context.shoppingCatalogueStore;

  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (state.isSticky === false) {
      setShowSearch(true);
    }
  }, [state.isSticky])
  const location = useLocation();
  // Check products page in case search feature pops up on other pages
  const checkProductsPath = location.pathname === "/online-shop" || location.pathname === "/";
  const checkPath = (route: string) => location.pathname === `${route.charAt(0) === "/" ? "" : "/"}${route}`;
  const headerOffset = (document.querySelector('header')?.offsetHeight || 70);

  const targetDiv = document.getElementsByClassName('productsContainer');
  const scroll = () => {
    if (!targetDiv || targetDiv.length <= 0) {
      return;
    }
    if (state.isDrawerOpen === true) {
      setDrawerState(false);
    }
    const y = targetDiv[0].getBoundingClientRect().top + window.scrollY - headerOffset; // - (document.querySelector('.ui .large .label .ct-green-button-active') ? 41 : 0);
    // if (targetDiv[0].getBoundingClientRect().top > headerOffset) {
    window.scrollTo({
      top:
        y,
      behavior: 'smooth',
    });
    // }
  };
  return (
    <header>
      <div>
        <Menu fluid={true} secondary={true} className="main-header-menu">
          <Menu.Item style={{ padding: "0px" }}>
            <Image
              alt="logo"
              src="/cheaptrolley_slogan.svg"
              size="small"
              onClick={() => {
                navigate("/");
              }}
            />
          </Menu.Item>
          {(!showSearch || state.isSticky === false) &&
            <Menu.Menu position="left">
              <Menu.Item className={checkPath("/online-shop") ? "ct-green-text-dark selected-menu-item" : ""} name={"Online Shop"} as={Link} to="/online-shop" />

              <Menu.Item className={checkPath("/habibai") ? "ct-green-text-dark selected-menu-item" : ""}
                as={Link}
                to="/habibai" >
                ĦabibAi
                <div className="new-header">Beta</div>
              </Menu.Item>
              <Menu.Item className={checkPath("/compare") ? "ct-green-text-dark selected-menu-item" : ""} name="Compare" as={Link} to="/compare" />
              <Menu.Item className={checkPath("/price-drop") ? "ct-green-text-dark selected-menu-item" : ""} as={Link} to="/price-drop">
                Price Drop
              </Menu.Item>

              {!accountId &&
                <Menu.Item className={checkPath("/join-us") ? "ct-green-text-dark selected-menu-item" : ""} as={Link} to={"/join-us"}>
                  Join Us
                </Menu.Item>}
              <Menu.Item className={checkPath("/about") ? "ct-green-text-dark selected-menu-item" : ""} name="About" as={Link} to="/about" />
            </Menu.Menu>
          }
          {(state.isSticky && showSearch) &&
            <Menu.Item position="right" className={`headerSearchWrapper ${showSearch ? 'searchActive' : 'searchInactive'}`}>
              <Search
                className="styledInput"
                isHeader={true}
                scroll={scroll}
                onSubmit={(data: any) => {
                  //if (!search) return;
                  scroll();
                  search(data.searchTerm, selectedBrands, selectedCategories);
                }} />
            </Menu.Item>
          }
          {(state.isSticky && checkProductsPath) && (
            <Menu.Item className="searchMenuIcon exclude" onClick={() => {
              setShowSearch(!showSearch);
            }}>
              <Icon fitted name={showSearch ? "x" : "search"} size="large" style={{ paddingRight: "4px" }} />
            </Menu.Item>
          )}
          <Menu.Menu position="right">
            {isLoggedIn && (isOwner || isAdmin) && (
              <Menu.Item className={location.pathname === "/adminPanel" ? "ct-green-text-dark selected-menu-item" : ""} name="Admin Panel" as={Link} to="/adminPanel" />
            )}
            {isLoggedIn && accountId && (
              <Menu.Item className={location.pathname === "/my-shop" ? "ct-green-text-dark selected-menu-item" : ""} name="My Shop" as={Link} to="/my-shop" />
            )}
            <Menu.Item
              className="exclude"
              style={{ paddingRight: "0px" }}
            >
              <Icon
                name="whatsapp"
                size="large"
                className="clickable new-green"
                style={{ padding: "0px" }}
                onClick={() => { window.open("https://wa.me/+35627995146", '_blank'); }} />
            </Menu.Item>
            {!isAdmin && !accountId && (
              <Menu.Item
                className="exclude"
                style={{ paddingRight: "0px" }}
                onClick={() => {
                  navigate("/my-shopping-list");
                }}
              >
                <Image style={{ height: "20px", padding: "0px" }} src={"/shoppingCart.png"} />
                {isLoggedIn && (
                  <div
                    className="new-green"
                    style={{
                      fontSize: "17px",
                      fontWeight: "600",
                      paddingLeft: "7px",
                    }}
                  >
                    {currencyFormat((getShoppingCartCost) ?? 0, !(menuLoading || loadingShoppingCart))}
                    {(menuLoading || loadingShoppingCart) && <Icon name="circle notch" loading />
                    }
                  </div>
                )}
              </Menu.Item>
            )}
          </Menu.Menu>
          {(!isLoggedIn || !user) && (
            <Menu.Item
              onClick={() => {
                requestUserSession(false, openModal, closeModal, () => {
                  closeModal();
                });
              }}
            >
              <Icon fitted name="user circle outline" size="large" style={{ paddingRight: "4px" }} /> Sign In
            </Menu.Item>
          )}
          {isLoggedIn && user && (
            <Menu.Item>
              <Dropdown
                className="account-button"
                pointing="top right"
                trigger={
                  <span>
                    <Icon
                      fitted
                      size="large"
                      name="user circle outline"
                      style={{ paddingRight: "4px" }}
                    />
                    {`Hi, ${camalize(user.firstName)}`}
                  </span>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    as={Link}
                    to={`/my-profile`}
                    text={"My Profile"}
                  />
                  {!isAdmin && !accountId && (
                    <Fragment>
                      <Dropdown.Item
                        as={Link}
                        to={`/nutrition-profile`}
                        text={"Diet & Nutrition"}
                      />
                      <Dropdown.Item
                        as={Link}
                        to={`/my-credits`}
                        text={`Credits (${currencyFormat(balance)})`}
                      />
                      <Dropdown.Item
                        as={Link}
                        to={`/my-orders`}
                        text={"Orders"}
                      />
                    </Fragment>
                  )}
                  <Dropdown.Divider className="no-margin" />
                  <Dropdown.Item
                    onClick={logout}
                    text={"Sign Out"}
                    className="no-padding"
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          )}
        </Menu>
      </div>
    </header>
  );
};

export default observer(HeaderMainMenu);
