import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { IShopForSave } from "../../interfaces/shop";
import CardWithUpload from "../../components/photoUpload/CardWithUpload";
import { LoadingParagraph } from "../../components/LoadingParagraph";
import ErrorMessage from "../../form/ErrorMessage";
import TextInput from "../../form/TextInput";
import { FORM_ERROR } from "final-form";
import {
  combineValidators,
  composeValidators,
  createValidator,
  isRequired,
} from "revalidate";
import { Form as FinalForm, Field } from "react-final-form";
import { useNavigate } from "react-router";
import { RootStoreContext } from "../../stores/RootStoreContext";
import {
  Dimmer,
  Loader,
  Segment,
  Image,
  Form,
  Button,
  Message,
} from "semantic-ui-react";
import CheckBox from "../../form/CheckBox";
import LegalDocuments from "../legal/LegalDocuments";

const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  (field) => "x"
);

const isChecked = (n: boolean) =>
  createValidator(
    (message) => (value: boolean) => {
      if (value !== n) {
        return message;
      }
    },
    (field) => "x"
  );

const validate = combineValidators({
  termsAndConditions: isChecked(true)(""),
  title: isRequired(""),
  vat: isRequired(""),
  email: composeValidators(isRequired, isValidEmail)(""),
  phone: isRequired(""),
});

interface IProps {
  shopId?: string;
}

const AccountDetails: React.FC<IProps> = ({ shopId }) => {
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const { activeModules } = context.accountPageStore;
  const { accountId, isAdmin, isOwner } = context.userStore;
  const { openModal } = context.modalStore;
  const {
    loading,
    load,
    save,
    editingItem,
    uploadFile,
    dispose,
    updateImage,
    requestActivation,
    handleButtonClick,
    handleHideButtonClick
  } = context.onlineShopStore;

  useEffect(() => {
    load(shopId);

    return () => {
      dispose();
    };
  }, [dispose, load, shopId]);

  return (
    <div
      className="fxFixed fxDisplay fxDirCol fxAlignCenter"
      style={isMobile ? { paddingLeft: "5px", paddingRight: "5px" } : undefined}
    >
      {editingItem?.aprovalStatus === "requested" && (
        <Message
          info
          icon={"info"}
          header="Your shop is not online yet! But we have received your request!"
          content={
            <div>
              <p>
                We have received your request and we are currently reviewing it.
                If there is more than 48h passed and we did not contact you
                kindly contact us at{" "}
                <a href="mailto:info@cheaptrolley.com">info@cheaptrolley.com</a>
              </p>
            </div>
          }
        />
      )}
      {(editingItem?.isPublished ?? false) === false &&
        editingItem?.aprovalStatus === undefined && (
          <Message
            warning
            icon={"warning"}
            header="Your shop is not online yet!"
            content={
              <div>
                <p>
                  When you finish adding your inventory then you can press the
                  button below to request your shop activation. We are going to
                  contact you on the details you have provided to verify your
                  account and then we will activate it.
                </p>
                <div style={{ textAlign: "center" }}>
                  <Button
                    className="button-as-link blueColor"
                    content="Request Activation"
                    onClick={() => requestActivation()}
                  />
                </div>
              </div>
            }
          />
        )}
      {(isOwner || isAdmin) && (
        <Message
          success={activeModules.length > 0}
          error={activeModules.length === 0}
          header={`Registered modules : ${activeModules.join(',')}`}
          content={''}
        />
      )}
      <FinalForm
        onSubmit={(values: IShopForSave) =>
          save(values).catch((error) => ({
            [FORM_ERROR]: error,
          }))
        }
        initialValues={editingItem ?? {}}
        validate={validate}
        render={({
          handleSubmit,
          submitError,
          dirty,
          dirtySinceLastSubmit,
          submitting,
          invalid,
          pristine,
          values,
          form,
        }) => (
          <Form onSubmit={handleSubmit} error>
            {loading && (
              <Segment>
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
                <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
              </Segment>
            )}

            <div
              style={{ paddingTop: "18px" }}
              className="fxFixed fxDisplay fxDirCol fxAlignCenter"
            >
              {loading ? (
                <Image />
              ) : (
                <div
                  style={{
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    maxWidth: "250px",
                  }}
                >
                  <CardWithUpload
                    disabled={isAdmin}
                    loading={loading}
                    imageSrc={values?.imageUrl}
                    uploadImage={(file: object) => {
                      // TODO: delete file

                      uploadFile(file).then((fileName) => {
                        form.change("imageUrl", fileName);

                        if (accountId) {
                          updateImage(fileName);
                        }
                      });

                      return Promise.resolve();
                    }}
                  />
                </div>
              )}
            </div>

            <div style={{ minWidth: "350px", paddingTop: "15px" }}>
              <Form.Field style={{ textAlign: "left" }}>
                <label style={{ fontSize: "13px" }}>{"Title *"}</label>
                {loading ? (
                  <LoadingParagraph />
                ) : (
                  <Field
                    loading={loading}
                    component={TextInput}
                    name="title"
                    placeholder={"Title"}
                  />
                )}
              </Form.Field>

              <Form.Field style={{ textAlign: "left" }}>
                <label style={{ fontSize: "13px" }}>{"VAT *"}</label>
                {loading ? (
                  <LoadingParagraph />
                ) : (
                  <Field
                    loading={loading}
                    component={TextInput}
                    name="vat"
                    placeholder={"VAT"}
                  />
                )}
              </Form.Field>

              <Form.Field style={{ textAlign: "left" }}>
                <label style={{ fontSize: "13px" }}>{"E-mail *"}</label>
                {loading ? (
                  <LoadingParagraph />
                ) : (
                  <Field
                    loading={loading}
                    component={TextInput}
                    name="email"
                    placeholder={"E-mail"}
                  />
                )}
              </Form.Field>

              <Form.Field style={{ textAlign: "left" }}>
                <label style={{ fontSize: "13px" }}>{"Contact Number *"}</label>
                {loading ? (
                  <LoadingParagraph />
                ) : (
                  <Field
                    loading={loading}
                    component={TextInput}
                    name="phone"
                    placeholder={"phone"}
                  />
                )}
              </Form.Field>

              <Form.Field style={{ textAlign: "left" }}>
                <label style={{ fontSize: "13px" }}>{"Website"}</label>
                {loading ? (
                  <LoadingParagraph />
                ) : (
                  <Field
                    loading={loading}
                    component={TextInput}
                    name="website"
                    placeholder={"Website (optional)"}
                  />
                )}
              </Form.Field>

              {(isOwner || isAdmin) && <Form.Group widths={2}>
                <Form.Field style={{ textAlign: "left" }}>
                  <label style={{ fontSize: "13px" }}>
                    {"Enable Delivery"}
                  </label>
                  {loading ? <LoadingParagraph /> : <Fragment />}
                </Form.Field>
                <Form.Field style={{ textAlign: "right" }} title={"This options is managed by Cheap Trolley, if you have any questions please contact us."}>
                  {loading ? (
                    <LoadingParagraph />
                  ) : (
                    <Field
                      type="checkbox"
                      loading={loading}
                      component={CheckBox}
                      toggle
                      name="offersDelivery"
                    />
                  )}
                </Form.Field>
              </Form.Group>}

              <Form.Field>
                <Field
                  name="termsAndConditions"
                  component={CheckBox}
                  type="checkbox"
                  label={
                    <a
                      href="#/"
                      referrerPolicy="no-referrer"
                      onClick={() => {
                        openModal(<LegalDocuments mode="b2b" />, "small");
                      }}
                    >
                      {
                        "I accept the Cheap Trolley Policy and Terms & Conditions"
                      }
                    </a>
                  }
                />
              </Form.Field>
            </div>

            {submitError && !dirtySinceLastSubmit && (
              <ErrorMessage error={submitError} />
            )}

            {isAdmin && editingItem && (
              <div style={{ textAlign: "center", paddingTop: "15px" }}>
                <Button.Group>
                  <Button
                    content={
                      editingItem?.aprovalStatus === "requested"
                        ? "Approve"
                        : "Disable"
                    }
                    color={
                      editingItem?.aprovalStatus === "requested" ? "green" : "red"
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      handleButtonClick();
                    }}
                  />
                  <Button.Or />
                  <Button
                    content={"Hide"}
                    color={"blue"}
                    onClick={(event) => {
                      event.preventDefault();
                      handleHideButtonClick();
                    }}
                  />
                </Button.Group>
              </div>
            )}

            <div style={{ textAlign: "center" }}>
              <Button.Group style={{ paddingTop: "15px" }}>
                <Button
                  color="blue"
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  loading={submitting}
                  content={"Save"}
                />
                <Button.Or />
                <Button
                  disabled={!dirty}
                  onClick={(event) => {
                    event.preventDefault();
                    if (!accountId) navigate(-1);
                    else form.reset(editingItem);
                  }}
                  content={"Cancel"}
                />
              </Button.Group>
            </div>

          </Form>
        )}
      />
    </div>
  );
};

export default observer(AccountDetails);
