import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Button, Form, Icon, Image, Label, Table } from 'semantic-ui-react'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import { LoadingParagraph } from '../../../components/LoadingParagraph'
import CheckBox from '../../../form/CheckBox'
import ErrorMessage from '../../../form/ErrorMessage'
import TextInput from '../../../form/TextInput'
import { FORM_ERROR } from "final-form";
import { Form as FinalForm, Field } from "react-final-form";
import CreateUpdateCategoryWidget from '../../categories/CreateUpdateCategoryWidget'
import SelectInput from '../../../form/SelectInput'
import CreateUpdateMeasurementWidget from '../../measurementUnits/CreateUpdateMeasurementWidget'
import NumericInput from '../../../form/NumericInput'
import { compartments } from '../../../options/compartments'
import BrandsSelector from '../../../components/BrandsDropdown/BrandsSelector'
import DietaryCategoriesManageWidget from '../../dietaryCategories/DietaryCategoriesManageWidget'
import BrandDetailWidget from '../../brands/components/BrandDetailWidget'
import { toast } from 'react-toastify'
import arrayMutators from "final-form-arrays";
import { FieldArray } from 'react-final-form-arrays'
import CatalogueImageSelection from './CatalogueImageSelection'
import { BrandSelectorStore } from '../../../components/BrandsDropdown/functions/BrandSelectorStore'
import TextAreaInput from '../../../form/TextAreaInput'

const BulkEditCatalogue = () => {
    const context = useContext(RootStoreContext)
    const { openModal, closeModal } = context.modalStore;
    const {
        products,
        VATCategories,
        loading,
        bulksave,
        categories,
        refreshCategories,
        measurements,
        refreshMeasurements,
        dietaryCategories,
        refreshDietaryCategories,
        Extras,
        selectedFields
    } = context.catalogueAdminStore;

    const splitLineDecision = 6;

    return (
        <div>
            <FinalForm
                onSubmit={(values: any) =>
                    bulksave(values.products)
                        .then(() => {
                            toast.success("Saved", { autoClose: 1000 });
                        })
                        .catch((error) => ({
                            [FORM_ERROR]: error,
                        }))
                }
                initialValues={{
                    "products": products
                }}
                // validate={validate}
                mutators={{
                    ...arrayMutators,
                }}
                render={({
                    handleSubmit,
                    submitError,
                    dirtySinceLastSubmit,
                    submitting,
                    invalid,
                    pristine,
                    values,
                    form,
                    form: {
                        mutators: { push, remove },
                    },
                }) => (
                    <Form onSubmit={handleSubmit} error>
                        <Form.Group>
                            <Form.Field width={16}>
                                <Table basic='very'>
                                    <Table.Body>
                                        <FieldArray name="products">
                                            {({ fields }) =>
                                                fields.map((name, index) => (
                                                    <Table style={{ borderRight: "none", borderLeft: "none", borderBottom: "none" }} color='blue'>
                                                        <Table.Body>
                                                            <Table.Row key={index}>
                                                                {(selectedFields.length === 0 || selectedFields.includes("imageUrl")) && <Table.Cell textAlign='center'>
                                                                    <div
                                                                        style={{
                                                                            padding: "0px",
                                                                        }}
                                                                    >
                                                                        <Image
                                                                            centered
                                                                            style={{
                                                                                padding: "0px",
                                                                                maxWidth: "180px",
                                                                                maxHeight: "180px",
                                                                            }}
                                                                            onClick={() => {
                                                                                openModal(<CatalogueImageSelection
                                                                                    onUpdate={(path: string) => { form.change(`${name}.imageUrl`, path) }}
                                                                                    associationId={values.products[index].associationId!}
                                                                                />, "large", "Select or upload an image", true, false)
                                                                            }}
                                                                            src={
                                                                                values.products[index].imageUrl ??
                                                                                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                                                            }
                                                                            onError={({ currentTarget }: any) => {
                                                                                currentTarget.onerror = null;
                                                                                currentTarget.src =
                                                                                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Table.Cell>}
                                                                <Table basic='very'>
                                                                    <Table.Body>
                                                                        {(selectedFields.length === 0 || ((selectedFields.includes("imageUrl") || selectedFields.includes("title")) && selectedFields.length > splitLineDecision)) && <Table.Row>
                                                                            <Table.Cell>
                                                                                {(selectedFields.length === 0 || selectedFields.includes("title")) && <Form.Field style={{ textAlign: "left" }}>
                                                                                    <label style={{ fontSize: "13px" }}>{"Product Title"}</label>
                                                                                    {loading ? (
                                                                                        <LoadingParagraph lines={1} />
                                                                                    ) : (
                                                                                        <Field
                                                                                            loading={loading}
                                                                                            component={TextInput}
                                                                                            rows={1}
                                                                                            name={`${name}.title`}
                                                                                            placeholder={"Product Title"}
                                                                                        />
                                                                                    )}
                                                                                    <Label
                                                                                        className="button-as-link clickable blueColor"
                                                                                        content="Format Title"
                                                                                        onClick={() => {
                                                                                            const words = (values.products[index].title as string).split(' ').filter(f => f.trim() !== '');
                                                                                            // Transform the words into camelCase
                                                                                            let result = words
                                                                                                .map((word: string, index: number) => {
                                                                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                                                                })
                                                                                                .join(' '); // Join the words back together

                                                                                            form.change(`${name}.title`, result)
                                                                                        }}
                                                                                    />
                                                                                </Form.Field>}
                                                                                {(selectedFields.length === 0 || selectedFields.includes("imageUrl")) && <Form.Field>
                                                                                    <Field
                                                                                        loading={loading}
                                                                                        component={TextInput}
                                                                                        name={`${name}.imageUrl`}
                                                                                        placeholder={"Image URL"}
                                                                                    />
                                                                                </Form.Field>}
                                                                            </Table.Cell>
                                                                        </Table.Row>}
                                                                        <Table.Row>
                                                                            {(((selectedFields.includes("imageUrl") || selectedFields.includes("title")) && selectedFields.length > 0 && selectedFields.length <= splitLineDecision)) && <Table.Cell>
                                                                                {(selectedFields.length === 0 || selectedFields.includes("title")) && <Form.Field style={{ textAlign: "left" }}>
                                                                                    <label style={{ fontSize: "13px" }}>{"Product Title"}</label>
                                                                                    {loading ? (
                                                                                        <LoadingParagraph lines={1} />
                                                                                    ) : (
                                                                                        <Field
                                                                                            loading={loading}
                                                                                            component={TextAreaInput}
                                                                                            rows={1}
                                                                                            name={`${name}.title`}
                                                                                            placeholder={"Product Title"}
                                                                                        />
                                                                                    )}
                                                                                    <Label
                                                                                        className="button-as-link clickable blueColor"
                                                                                        content="Format Title"
                                                                                        onClick={() => {
                                                                                            const words = (values.products[index].title as string).split(' ').filter(f => f.trim() !== '');
                                                                                            // Transform the words into camelCase
                                                                                            let result = words
                                                                                                .map((word: string, index: number) => {
                                                                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                                                                })
                                                                                                .join(' '); // Join the words back together

                                                                                            form.change(`${name}.title`, result)
                                                                                        }}
                                                                                    />
                                                                                </Form.Field>}
                                                                                {(selectedFields.length === 0 || selectedFields.includes("imageUrl")) && <Form.Field>
                                                                                    <Field
                                                                                        loading={loading}
                                                                                        component={TextInput}
                                                                                        name={`${name}.imageUrl`}
                                                                                        placeholder={"Image URL"}
                                                                                    />
                                                                                </Form.Field>}
                                                                            </Table.Cell>}
                                                                            {(selectedFields.length === 0 || selectedFields.includes("brand_tags") || selectedFields.includes("vat")) && <Table.Cell>
                                                                                {(selectedFields.length === 0 || selectedFields.includes("brand_tags")) && <Form.Field style={{ textAlign: "left" }}>
                                                                                    <label style={{ fontSize: "13px" }}>
                                                                                        {"Brand"}
                                                                                    </label>
                                                                                    <BrandsSelector
                                                                                        brandSelectStore={new BrandSelectorStore()}
                                                                                        defaultValue={values.products[index]?.brand_tags}
                                                                                        selectionChanged={(e, d: any) => {
                                                                                            var arr: string[] = []
                                                                                            if (Array.isArray(d.value)) {
                                                                                                arr = d.value
                                                                                            }
                                                                                            else {
                                                                                                arr = [d.value]
                                                                                            }

                                                                                            arr = arr.filter(f => f.trim() !== "");

                                                                                            form.change(`${name}.brand_tags`, arr)
                                                                                        }} />
                                                                                    <Label
                                                                                        className="button-as-link clickable blueColor"
                                                                                        content="Create New Brand"
                                                                                        onClick={() => {
                                                                                            openModal(
                                                                                                <BrandDetailWidget
                                                                                                    isInModal={true}
                                                                                                    closeOnSave={true}
                                                                                                    showCloseIcon={false}
                                                                                                    close={() => { closeModal() }}
                                                                                                />,
                                                                                                "mini",
                                                                                                "Create new Brand",
                                                                                                true,
                                                                                                true,
                                                                                                () => {
                                                                                                    // refreshDietaryCategories();
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </Form.Field>}
                                                                                {(selectedFields.length === 0 || selectedFields.includes("vat")) && <Form.Field style={{ textAlign: "left" }}>
                                                                                    <label style={{ fontSize: "13px" }}>
                                                                                        {"VAT Category"}
                                                                                    </label>
                                                                                    <Field
                                                                                        loading={loading}
                                                                                        component={SelectInput}
                                                                                        options={VATCategories.map(v => ({
                                                                                            'key': v.id!,
                                                                                            'value': v.id!,
                                                                                            'text': v.title
                                                                                        }))}
                                                                                        name={`${name}.vat`}
                                                                                        placeholder={"Select VAT"}
                                                                                    />
                                                                                    {VATCategories.map((f) => (
                                                                                        <Label
                                                                                            color="blue"
                                                                                            basic
                                                                                            size="small"
                                                                                            style={{ marginTop: "4px" }}
                                                                                            content={f.title}
                                                                                            onClick={() => {
                                                                                                form.change(
                                                                                                    `${name}.vat`,
                                                                                                    f.id!
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    ))}
                                                                                </Form.Field>}

                                                                            </Table.Cell>}
                                                                            {(selectedFields.length === 0 || selectedFields.includes("compartment") || selectedFields.includes("ct_categories")) && <Table.Cell >
                                                                                {(selectedFields.length === 0 || selectedFields.includes("compartment")) && <Form.Field style={{ textAlign: "left" }}>
                                                                                    <label style={{ fontSize: "13px" }}>
                                                                                        {"Compartment"}
                                                                                    </label>
                                                                                    <Field
                                                                                        loading={loading}
                                                                                        component={SelectInput}
                                                                                        options={compartments}
                                                                                        name={`${name}.compartment`}
                                                                                        placeholder={"Select compartment"}
                                                                                    />
                                                                                    {compartments.map((f) => (
                                                                                        <Label
                                                                                            key={f.key}
                                                                                            color="blue"
                                                                                            basic
                                                                                            size="small"
                                                                                            style={{ marginTop: "4px" }}
                                                                                            content={f.text}
                                                                                            onClick={() => {
                                                                                                form.change(
                                                                                                    `${name}.compartment`,
                                                                                                    f.value
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    ))}
                                                                                </Form.Field>}
                                                                                {(selectedFields.length === 0 || selectedFields.includes("ct_categories")) && <Form.Field style={{ textAlign: "left" }}>
                                                                                    <label style={{ fontSize: "13px" }}>
                                                                                        {"Categories"}
                                                                                    </label>

                                                                                    <Field
                                                                                        loading={loading}
                                                                                        multiselect={true}
                                                                                        component={SelectInput}
                                                                                        options={categories
                                                                                            .slice()
                                                                                            .sort((a, b) => a.description > b.description ? 1 : -1).map((x) => ({
                                                                                                key: x.id,
                                                                                                value: x.id,
                                                                                                text: x.description,
                                                                                            }))}
                                                                                        name={`${name}.ct_categories`}
                                                                                        placeholder={"Select category(ies)"}
                                                                                    />
                                                                                    <Label
                                                                                        className="button-as-link clickable blueColor"
                                                                                        content="Create New Category"
                                                                                        onClick={() => {
                                                                                            openModal(
                                                                                                <CreateUpdateCategoryWidget
                                                                                                    categories={categories}
                                                                                                    showCloseIcon={false}
                                                                                                    close={closeModal} />,
                                                                                                "mini",
                                                                                                "Create new Category",
                                                                                                true,
                                                                                                false,
                                                                                                () => {
                                                                                                    refreshCategories();
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </Form.Field>}
                                                                            </Table.Cell>}
                                                                            {(selectedFields.length === 0 || selectedFields.includes("measurment") || selectedFields.includes("measurementUnit")) && <Table.Cell>
                                                                                {(selectedFields.length === 0 || selectedFields.includes("measurment")) && <Form.Field style={{ textAlign: "left" }}>
                                                                                    <label style={{ fontSize: "13px" }}>
                                                                                        {"Measurement"}
                                                                                    </label>
                                                                                    {loading ? (
                                                                                        <LoadingParagraph />
                                                                                    ) : (
                                                                                        <Field
                                                                                            component={NumericInput}
                                                                                            name={`${name}.measurment`}
                                                                                            placeholder={"Measurment e.g. 700"}
                                                                                        />
                                                                                    )}

                                                                                    <Label
                                                                                        color="blue"
                                                                                        basic
                                                                                        size="small"
                                                                                        style={{ marginTop: "4px" }}
                                                                                        content="100"
                                                                                        onClick={() =>
                                                                                            form.change(
                                                                                                `${name}.measurment`,
                                                                                                100
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <Label
                                                                                        color="blue"
                                                                                        basic
                                                                                        size="small"
                                                                                        style={{ marginTop: "4px" }}
                                                                                        content="500"
                                                                                        onClick={() =>
                                                                                            form.change(
                                                                                                `${name}.measurment`,
                                                                                                500
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <Label
                                                                                        color="blue"
                                                                                        basic
                                                                                        size="small"
                                                                                        style={{ marginTop: "4px" }}
                                                                                        content="750"
                                                                                        onClick={() =>
                                                                                            form.change(
                                                                                                `${name}.measurment`,
                                                                                                750
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <Label
                                                                                        color="blue"
                                                                                        size="small"
                                                                                        basic
                                                                                        style={{ marginTop: "4px" }}
                                                                                        content="1000"
                                                                                        onClick={() =>
                                                                                            form.change(
                                                                                                `${name}.measurment`,
                                                                                                1000
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Form.Field>}
                                                                                {(selectedFields.length === 0 || selectedFields.includes("measurementUnit")) && <Form.Field style={{ textAlign: "left" }}>
                                                                                    <label style={{ fontSize: "13px" }}>
                                                                                        {"Measurement Unit"}
                                                                                    </label>
                                                                                    <Field
                                                                                        loading={loading}
                                                                                        component={SelectInput}
                                                                                        options={measurements?.map((x) => ({
                                                                                            key: x.key,
                                                                                            value: x.key,
                                                                                            text: x.description,
                                                                                        }))}
                                                                                        name={`${name}.measurementUnit`}
                                                                                        placeholder={"Measurement Unit"}
                                                                                    />
                                                                                    <div className='fxFixed fxDisplay fxJustifyBetween'>
                                                                                        <div className='fxStretch'>
                                                                                            <Label
                                                                                                color="blue"
                                                                                                size="small"
                                                                                                basic
                                                                                                style={{ marginTop: "4px" }}
                                                                                                content="gr"
                                                                                                onClick={() =>
                                                                                                    form.change(`${name}.measurementUnit`, "grams")
                                                                                                }
                                                                                            />
                                                                                            <Label
                                                                                                color="blue"
                                                                                                size="small"
                                                                                                basic
                                                                                                style={{ marginTop: "4px" }}
                                                                                                content="ml"
                                                                                                onClick={() =>
                                                                                                    form.change(`${name}.measurementUnit`, "ml")

                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <div style={{ margin: "auto" }}>
                                                                                            <Label
                                                                                                className="button-as-link clickable blueColor"
                                                                                                content="Create"
                                                                                                onClick={() => {
                                                                                                    openModal(
                                                                                                        <CreateUpdateMeasurementWidget />,
                                                                                                        "mini",
                                                                                                        "Create new Measurement Unit",
                                                                                                        true,
                                                                                                        true,
                                                                                                        () => {
                                                                                                            refreshMeasurements();
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </Form.Field>}
                                                                            </Table.Cell>}
                                                                            {(selectedFields.length === 0 || selectedFields.includes("extras")) && <Table.Cell>
                                                                                <Form.Field width={16}>
                                                                                    <label style={{ fontSize: "13px" }}>
                                                                                        Extras
                                                                                        {/* {((editingItem?.bcrsAmount?.length ?? 0) > 0) &&
                                                                                    <Popup trigger={<Icon style={{ paddingLeft: "5px" }} color='blue' name='info circle' />} content={<List>
                                                                                        {editingItem?.bcrsAmount?.map((vtc, i) => (<List.Item id={i}>{currencyFormat(vtc, true)}</List.Item>))}
                                                                                    </List>} />} */}
                                                                                    </label>
                                                                                    <Table basic='very'>
                                                                                        <Table.Body>
                                                                                            <FieldArray name={`${name}.extras`}>
                                                                                                {({ fields }) =>
                                                                                                    fields.map((extraName, extraIndex) => (
                                                                                                        <Table.Row key={extraIndex}>
                                                                                                            <Table.Cell>
                                                                                                                {loading ? (
                                                                                                                    <LoadingParagraph lines={1} />
                                                                                                                ) : (
                                                                                                                    <Field
                                                                                                                        name={`${extraName}.id`}
                                                                                                                        component={SelectInput}
                                                                                                                        options={Extras.map(x => ({ 'key': x.id, 'value': x.id, 'text': x.title }))}
                                                                                                                        changeFinished={(value: any) => {
                                                                                                                            if (value === "") {
                                                                                                                                form.change(`${extraName}.fee`, 0);
                                                                                                                                form.change(`${extraName}.vat_rate_id`, undefined);
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                form.change(`${extraName}.fee`, Extras.filter(f => f.id === value)[0].fee);
                                                                                                                                form.change(`${extraName}.vat_rate_id`, Extras.filter(f => f.id === value)[0].vat_rate);
                                                                                                                            }
                                                                                                                        }}
                                                                                                                    />
                                                                                                                )}
                                                                                                            </Table.Cell>
                                                                                                            <Table.Cell>
                                                                                                                <Field
                                                                                                                    loading={loading}
                                                                                                                    component={NumericInput}
                                                                                                                    name={`${extraName}.quantity`}
                                                                                                                    placeholder='Qnt'
                                                                                                                />
                                                                                                            </Table.Cell>
                                                                                                            <Table.Cell>
                                                                                                                <Field
                                                                                                                    loading={loading}
                                                                                                                    component={NumericInput}
                                                                                                                    name={`${extraName}.fee`}
                                                                                                                />
                                                                                                            </Table.Cell>

                                                                                                            <Table.Cell textAlign='right' disabled={loading}>
                                                                                                                <Icon
                                                                                                                    disabled={loading}
                                                                                                                    circular
                                                                                                                    inverted
                                                                                                                    color="red"
                                                                                                                    onClick={() => {
                                                                                                                        remove(`${name}.extras`, extraIndex);
                                                                                                                    }}
                                                                                                                    name='minus'
                                                                                                                />
                                                                                                            </Table.Cell>
                                                                                                        </Table.Row>
                                                                                                    ))
                                                                                                }
                                                                                            </FieldArray>
                                                                                        </Table.Body>

                                                                                        <Table.Footer fullWidth>
                                                                                            <Table.Row>
                                                                                                <Table.HeaderCell textAlign='right'>
                                                                                                    <Icon
                                                                                                        disabled={loading}
                                                                                                        circular
                                                                                                        inverted
                                                                                                        color="blue"
                                                                                                        onClick={() => {
                                                                                                            push(`${name}.extras`, {});
                                                                                                        }}
                                                                                                        name='add'
                                                                                                    />
                                                                                                </Table.HeaderCell>
                                                                                            </Table.Row>
                                                                                        </Table.Footer>
                                                                                    </Table>
                                                                                </Form.Field>
                                                                            </Table.Cell>}
                                                                            {(selectedFields.length === 0 || selectedFields.includes("dietFlag") || selectedFields.includes("largeContainer")) && <Table.Cell>
                                                                                {(selectedFields.length === 0 || selectedFields.includes("dietFlag")) && <Form.Field style={{ textAlign: "left" }}>
                                                                                    <label style={{ fontSize: "13px" }}>
                                                                                        {"Dietary Categories"}
                                                                                    </label>
                                                                                    <Field
                                                                                        loading={loading}
                                                                                        component={SelectInput}
                                                                                        multiselect={true}
                                                                                        options={dietaryCategories?.map((x) => ({
                                                                                            key: x.id,
                                                                                            value: x.id,
                                                                                            text: x.description,
                                                                                        }))}
                                                                                        name={`${name}.dietFlag`}
                                                                                        placeholder={"Select a category"}
                                                                                    />
                                                                                    <Label
                                                                                        className="button-as-link clickable blueColor"
                                                                                        content="Create New Dietary Category"
                                                                                        onClick={() => {
                                                                                            openModal(
                                                                                                <DietaryCategoriesManageWidget />,
                                                                                                "mini",
                                                                                                "Create new Dietary Category",
                                                                                                true,
                                                                                                true,
                                                                                                () => {
                                                                                                    refreshDietaryCategories();
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </Form.Field>}
                                                                                {(selectedFields.length === 0 || selectedFields.includes("largeContainer")) && <Form.Field style={{ textAlign: "left" }}>
                                                                                    {loading ? (
                                                                                        <LoadingParagraph />
                                                                                    ) : (
                                                                                        <Field
                                                                                            name={`${name}.largeContainer`}
                                                                                            component={CheckBox}
                                                                                            type="checkbox"
                                                                                            label={
                                                                                                <span>Six Pack</span>
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </Form.Field>}
                                                                            </Table.Cell>}
                                                                        </Table.Row>
                                                                    </Table.Body>
                                                                </Table>
                                                            </Table.Row>
                                                        </Table.Body>
                                                    </Table>
                                                ))
                                            }
                                        </FieldArray>
                                    </Table.Body>

                                </Table>
                            </Form.Field>
                        </Form.Group>


                        {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage error={submitError} />
                        )}
                        <div style={{ textAlign: "center" }}>
                            <Button.Group style={{ paddingTop: "15px" }}>
                                <Button
                                    color="blue"
                                    disabled={(invalid && !dirtySinceLastSubmit)}
                                    loading={submitting}
                                    content={"Save"}
                                />
                            </Button.Group>
                        </div>
                    </Form>
                )}
            />
        </div>
    )
}


export default observer(BulkEditCatalogue);