import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";

export class ProductCounterStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable discontinued: boolean = false;
    @observable count: number = 0;
    @observable loading: boolean = true;

    @action setCount = (value: number) => { this.count = value };
    @action setLoading = (value: boolean) => { this.loading = value };

    @action load = () => {
        this.setLoading(true)
        agent.Products.getCount(true,
            this.rootStore.commonStore.selectedMarketIds,
            this.discontinued
        ).then((count) => this.setCount(count)).then(() => this.setLoading(false));
    }
}