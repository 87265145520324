import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Dimmer,
  Divider,
  Header,
  List,
  Loader,
  Message,
  Segment,
  Statistic,
  Table,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { currencyFormat } from "../products/functions/productHelper";

const CampaignPerformance = () => {
  // let { id } = useParams<"id">();
  // const navigate = useNavigate();
  // const context = useContext(RootStoreContext);
  // const { setPage } = context.analyticsStore;
  // const { isLoggedIn } = context.sessionStore;
  // const { isMobile } = context.deviceStore;
  // const { load, dispose, item, loading } = context.adPerformanceStore;

  // useEffect(() => {
  //   if (!isLoggedIn || !id) {
  //     navigate(-1);
  //     return;
  //   }

  //   setPage("Ad Performance", window.location.pathname);

  //   if (id) load(id);

  //   return () => {
  //     dispose();
  //   };
  // }, [isLoggedIn, load, id]);

  return <div />;
  // return loading ? (
  //   <div style={{ padding: "30px" }}>
  //     <Segment style={{ height: "200px" }}>
  //       <Dimmer active inverted>
  //         <Loader inverted>Loading</Loader>
  //       </Dimmer>
  //     </Segment>
  //   </div>
  // ) : (
  //   <div style={{ padding: "12px" }}>
  //     <Header content="Campaign Totals" />
  //     <Divider />
  //     {isMobile ? (
  //       <List divided>
  //         <List.Item>
  //           <List.Content>
  //             <span style={{ color: "black" }}>Impressions : </span>

  //             {item?.stats?.reduce((a, b) => a + b.impressions, 0)}
  //           </List.Content>
  //         </List.Item>
  //         <List.Item>
  //           <List.Content>
  //             <span style={{ color: "black" }}>Clicks : </span>

  //             {item?.stats?.reduce((a, b) => a + b.clicks, 0)}
  //           </List.Content>
  //         </List.Item>
  //         <List.Item>
  //           <List.Content>
  //             <span style={{ color: "black" }}>Money Spent : </span>

  //             {currencyFormat(
  //               item?.stats?.reduce(
  //                 (a, b) => a + (b.startingBalance - b.balance),
  //                 0
  //               ) ?? 0
  //             )}
  //           </List.Content>
  //         </List.Item>
  //         <List.Item>
  //           <List.Content>
  //             <span style={{ color: "black" }}>Duration : </span>
  //             {`${item?.stats?.length} days`}
  //           </List.Content>
  //         </List.Item>
  //         <List.Item>
  //           <List.Content>
  //             <span style={{ color: "black" }}>Budget : </span>

  //             {currencyFormat(
  //               item?.stats?.reduce((a, b) => a + b.startingBalance, 0) ?? 0
  //             )}
  //           </List.Content>
  //         </List.Item>
  //       </List>
  //     ) : (
  //       <Table>
  //         <Table.Header>
  //           <Table.Row>
  //             <Table.HeaderCell>Impressions</Table.HeaderCell>
  //             <Table.HeaderCell>Clicks</Table.HeaderCell>
  //             <Table.HeaderCell>Money Spent</Table.HeaderCell>
  //             <Table.HeaderCell>Duration</Table.HeaderCell>
  //             <Table.HeaderCell>Budget</Table.HeaderCell>
  //           </Table.Row>
  //         </Table.Header>
  //         <Table.Body>
  //           <Table.Row>
  //             <Table.Cell>
  //               {item?.stats?.reduce((a, b) => a + b.impressions, 0)}
  //             </Table.Cell>
  //             <Table.Cell>
  //               {item?.stats?.reduce((a, b) => a + b.clicks, 0)}
  //             </Table.Cell>
  //             <Table.Cell>
  //               {currencyFormat(
  //                 item?.stats?.reduce(
  //                   (a, b) => a + (b.startingBalance - b.balance),
  //                   0
  //                 ) ?? 0
  //               )}
  //             </Table.Cell>
  //             <Table.Cell>{`${item?.stats?.length} days`}</Table.Cell>
  //             <Table.Cell>
  //               {currencyFormat(
  //                 item?.stats?.reduce((a, b) => a + b.startingBalance, 0) ?? 0
  //               )}
  //             </Table.Cell>
  //           </Table.Row>
  //         </Table.Body>
  //       </Table>
  //     )}
  //     <Header content="Day by Day Performance" />
  //     <Divider />
  //     <List selection verticalAlign="middle" divided>
  //       {item?.stats?.filter((f) => f.date < new Date().getTime() / 1000)
  //         .length === 0 && (
  //         <Message
  //           info
  //           header={`Campaign will start on ${format(
  //             item?.stats[0].date * 1000,
  //             "EEEE, do MMMM yyyy"
  //           )}`}
  //         />
  //       )}
  //       {item?.stats
  //         ?.filter((f) => f.date < new Date().getTime() / 1000)
  //         .slice()
  //         .sort((a, b) => (a.date > b.date ? -1 : 1))
  //         .map((x, i) => (
  //           <List.Item key={`${x.date}_${i}`}>
  //             <span style={{ color: "black" }}>
  //               {format(x.date * 1000, "EEEE, do MMMM yyyy")}
  //             </span>
  //             <List.Content floated="right">
  //               <Statistic size="tiny">
  //                 <Statistic.Label>Spent</Statistic.Label>
  //                 <Statistic.Value>
  //                   {currencyFormat(x.startingBalance - x.balance)}
  //                 </Statistic.Value>
  //               </Statistic>
  //               <Statistic size="tiny">
  //                 <Statistic.Label>Impressions</Statistic.Label>
  //                 <Statistic.Value>{x.impressions}</Statistic.Value>
  //               </Statistic>
  //               <Statistic size="tiny">
  //                 <Statistic.Label>Clicks</Statistic.Label>
  //                 <Statistic.Value>{x.clicks}</Statistic.Value>
  //               </Statistic>
  //             </List.Content>
  //           </List.Item>
  //         ))}
  //     </List>
  //   </div>
  // );
};

export default observer(CampaignPerformance);
