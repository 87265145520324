import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { Button, Divider, Progress, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStoreContext";

const UploadStep3 = () => {
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { added, updated, data, issues, offers, uploading } =
    context.uploadWizard;

  return (
    <div className="">
      <p>
        We are currnetly processing your data. Please do not close this tab
        until we finish...
      </p>
      <Progress
        percent={Math.ceil(
          ((added + updated + issues.length) / data.length) * 100
        )}
        inverted
        color={issues.length === 0 ? "green" : "red"}
        progress
      />
      <div className="fxFixed fxDisplay fxDirCol fxAlignCenter">
        <Table basic="very" celled collapsing size="small">
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Added</Table.HeaderCell>
              <Table.HeaderCell>Updated</Table.HeaderCell>
              <Table.HeaderCell>Price Drops</Table.HeaderCell>
              <Table.HeaderCell>Errors</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row textAlign="center">
              <Table.Cell> {added}</Table.Cell>
              <Table.Cell>{updated}</Table.Cell>
              <Table.Cell>{offers}</Table.Cell>
              <Table.Cell error> {issues.length}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Divider hidden />

        <Button
          color="blue"
          disabled={uploading}
          content="Close Wizard"
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
    </div>
  );
};

export default observer(UploadStep3);
