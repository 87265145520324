import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Button, Form, Icon, Menu, Popup, Tab, Table } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { IEmailTemplateForList } from "../../../interfaces/email";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { EmailTemplateStore } from "../functions/EmailTemplateStore";
import { LoadingParagraph } from "../../../components/LoadingParagraph";
import TextInput from "../../../form/TextInput";
import TextAreaInput from "../../../form/TextAreaInput";
import { Languages } from "../../../options/languages";
import HTMLAdvancedEditor from "../../../common/HTMLAdvancedEditor";
import ErrorMessage from "../../../form/ErrorMessage";
import { toast } from "react-toastify";

interface IProps {
    item?: IEmailTemplateForList;
}

const required = (value: any) => (value ? undefined : "");
const requiredArray = (value: any) =>
    value && value.length > 0 ? undefined : "";

const EmailTemplateDetail: React.FC<IProps> = ({ item }) => {
    const context = useContext(RootStoreContext);
    const {
        closeModal,
        openSecondModal,
        closeSecondModal,
        setModalSize,
    } = context.modalStore;
    const {
        loading,
        load,
        getItemDesign,
        saveLanguageContent,
        getItemHtml,
        currentlyEditingItem,
        saveItem,
        dispose,
        initialTemplate,
    } = EmailTemplateStore;

    useEffect(() => {
        if (item)
            load(item.id);
        return () => {
            dispose();
        };
    }, [load, dispose, item]);

    return (
        <FinalForm
            mutators={{
                ...arrayMutators,
            }}
            onSubmit={(values: any) =>
                saveItem({ ...values })
                    .then(() => closeModal())
                    .catch((error) => {
                        toast.error("Template was not saved.")
                    })
            }
            initialValues={currentlyEditingItem ?? initialTemplate}
            render={({
                handleSubmit,
                submitting,
                submitError,
                dirtySinceLastSubmit,
                invalid,
                form,
                form: {
                    mutators: { push, remove },
                },
                values,
            }) => {
                if (!(window as any).templateDetailFrom)
                    (window as any).templateDetailFrom = form;

                return (
                    <Form onSubmit={handleSubmit} error>
                        <Tab
                            onTabChange={(event, data) => {
                                if (data.activeIndex === 0) {
                                    setModalSize("small");
                                } else if (data.activeIndex === 1) {
                                    setModalSize("large");
                                }
                            }}
                            menu={{ color: "teal", secondary: true, pointing: true }}
                            panes={[
                                {
                                    menuItem: "General",
                                    render: () => (
                                        <Tab.Pane attached={false}>
                                            <label style={{ fontSize: "13px" }}>{"Unique Key"}</label>
                                            <Field
                                                validate={required}
                                                loading={loading}
                                                component={TextInput}
                                                name="key"
                                                placeholder={"Unique Key"}
                                            />

                                            <Table color={"teal"} className="only-top-border">
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell colSpan="2">
                                                            {"Title"}
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    <FieldArray name="subject" validate={requiredArray}>
                                                        {({ fields }) =>
                                                            fields.map((name, index) => (
                                                                <Table.Row key={index}>
                                                                    {loading ? (
                                                                        <Table.Cell>
                                                                            <LoadingParagraph />
                                                                        </Table.Cell>
                                                                    ) : (
                                                                        <Fragment>
                                                                            <Table.Cell>
                                                                                <label style={{ fontSize: "13px" }}>
                                                                                    {
                                                                                        Languages.filter(
                                                                                            (e) =>
                                                                                                e.key ===
                                                                                                values.subject[index].key
                                                                                        )[0]?.text
                                                                                    }
                                                                                </label>
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                <Field
                                                                                    name={`${name}.key`}
                                                                                    component={TextInput}
                                                                                    hidden
                                                                                />
                                                                                <Field
                                                                                    validate={required}
                                                                                    name={`${name}.description`}
                                                                                    component={TextAreaInput}
                                                                                    rows={3}
                                                                                />
                                                                            </Table.Cell>
                                                                        </Fragment>
                                                                    )}
                                                                </Table.Row>
                                                            ))
                                                        }
                                                    </FieldArray>
                                                </Table.Body>
                                            </Table>
                                        </Tab.Pane>
                                    ),
                                },
                                {
                                    menuItem: "Content",
                                    render: () => (
                                        <Tab.Pane attached={false}>
                                            <Tab
                                                menu={{
                                                    color: "teal",
                                                    fluid: true,
                                                    vertical: true,
                                                    tabular: true,
                                                }}
                                                panes={Languages.map((language) => ({
                                                    menuItem: (
                                                        <Menu.Item key={language.key}>
                                                            {language.text}
                                                            <Popup
                                                                trigger={
                                                                    <Icon
                                                                        className="icon-as-link"
                                                                        name={"pencil"}
                                                                        onClick={(e: any) => {
                                                                            e.preventDefault();

                                                                            openSecondModal(
                                                                                <HTMLAdvancedEditor
                                                                                    design={getItemDesign(language.key)}
                                                                                    closeCallback={closeSecondModal}
                                                                                    saveCallback={(
                                                                                        contentExport: any
                                                                                    ) => {
                                                                                        saveLanguageContent(
                                                                                            language.key,
                                                                                            contentExport,
                                                                                            form,
                                                                                            values,
                                                                                            push
                                                                                        ).then(() => closeSecondModal());
                                                                                    }}
                                                                                />,
                                                                                "fullscreen",
                                                                                "Edit email template"
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                content={"Edit"}
                                                                on="hover"
                                                            />
                                                        </Menu.Item>
                                                    ),
                                                    render: () => (
                                                        <Tab.Pane>
                                                            {
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            getItemHtml(language.key, values) ??
                                                                            "Empty",
                                                                    }}
                                                                />
                                                            }
                                                        </Tab.Pane>
                                                    ),
                                                }))}
                                            />
                                        </Tab.Pane>
                                    ),
                                },
                            ]}
                        />

                        {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage error={submitError} />
                        )}
                        <div style={{ textAlign: "center" }}>
                            <Button.Group style={{ paddingTop: "15px" }}>
                                <Button
                                    disabled={invalid && !dirtySinceLastSubmit}
                                    color="blue"
                                    loading={submitting}
                                >
                                    {"Save"}
                                </Button>
                                <Button.Or />
                                <Button onClick={() => closeModal()}>
                                    {"Cancel"}
                                </Button>
                            </Button.Group>
                        </div>
                    </Form>
                );
            }}
        />
    );
};

export default observer(EmailTemplateDetail);