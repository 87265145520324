import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { Button, Header, Image } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import DietSelection from './DietSelection';
import FoodExclution from './FoodExclution';
import MetricSelection from './MetricSelection';
import DietGoal from './DietGoal';
import UserDietProfile from './UserDietProfile';
import { useNavigate } from 'react-router';

const UserDietProfileSetup = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext);
    const {
        setupSteps,
        selectedStep,
    } = context.userDietProfileStore;
    const { isMobile } = context.deviceStore;

    return (
        <div
            style={{
                marginTop: "25px",
            }}
            className='fxFixed fxDirCol fxJustifyCenter'>

            {selectedStep !== 6 && <div className='fxFixed fxDisplay fxJustifyCenter fxStretch'>
                {
                    setupSteps?.map((x) => <Fragment>
                        {
                            x !== selectedStep ?
                                <div key={x} style={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    marginLeft: "8px",
                                    marginRight: "8px",
                                }} className="green-border-circle"></div>
                                :
                                <Image key={x} style={{
                                    height: "45px",
                                    marginLeft: "8px",
                                    marginRight: "8px",
                                }} src="./trolley-cone.svg" />
                        }
                    </Fragment>)
                }
            </div>}
            {selectedStep === 1 &&
                <DietSelection />
            }
            {selectedStep === 2 &&
                <FoodExclution />
            }
            {selectedStep === 3 &&
                <MetricSelection />
            }
            {selectedStep === 4 &&
                <DietGoal />
            }
            {selectedStep === 5 &&
                <UserDietProfile />
            }
            {selectedStep === 6 &&
                <div
                    className='fxFixed fxDisplay fxAlignCenter fxStretch fxDirCol'
                    style={isMobile ? { marginTop: "15px" } : { marginTop: "55px" }}>
                    <Image src="/logo-chef-hat.svg" style={isMobile ? { width: "85%" } : { marginLeft: "-26px", width: "45%" }} />
                    <Header
                        size='large'
                        textAlign='center'
                        content="You are ready to go!"
                        subheader="Click the 'Let's Cook' button below to start chatting with HabibAi!"
                        style={isMobile ? { padding: "15px" } : {
                            marginTop: "45px"
                        }}
                    />
                    <Button content="Let's Cook" className='ct-green-button-active' onClick={() => {
                        navigate("/habibai")
                    }} />
                </div>
            }
        </div >
    )
}

export default observer(UserDietProfileSetup);
