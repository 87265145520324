import { action, makeAutoObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IHubOrderForDetail } from "../../../interfaces/order";
import { ClientOrderProductStatusType } from "../../../options/HubOrderProductStatus";
import { currencyFormat } from "../../products/functions/productHelper";

class HubOrderVerification {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loading: boolean = false;
    @observable updatingProduct: boolean = false;
    @observable updatingOrder: boolean = false;
    @observable order?: IHubOrderForDetail = undefined;
    @observable downloading: boolean = false;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setUpdatingProduct = (value: boolean) => this.updatingProduct = value;
    @action setUpdatingOrder = (value: boolean) => this.updatingOrder = value;
    @action setOrder = (value?: IHubOrderForDetail) => this.order = value;
    @action setDownloading = (value: boolean) => this.downloading = value;


    @action load = async (id: string) => {
        try {
            this.setLoading(true);

            var detail = await agent.HubOrders.get_detail(id);
            this.setOrder(detail);
        } catch (error) {

        }
        finally {
            this.setLoading(false);
        }
    }

    @action dispose = () => {
        this.setLoading(false);
        this.setOrder(undefined);
    }

    @action toggleHasImage = async (catalogueId: string) => {
        try {
            agent.Catalogue.Admin.toggleHasImage(catalogueId).then(() => {
                runInAction(() => {
                    if (!this.order) return;

                    var idx = this.order?.products.findIndex(x => x.catalogueId === catalogueId);
                    this.order.products[idx].hasImage = !this.order.products[idx].hasImage;
                })

            })
        } catch (error) {

        }
    }

    @action updateOrderStatus = async () => {
        if (!this.order) return;

        try {
            this.setUpdatingOrder(true);

            var newStatus: "received" | "completed" = (this.order.status === "ready_for_delivery" || this.order.status === "sent_to_market") ? "received" : (this.order.status === "received" ? "completed" : "received")

            await agent.HubOrders.Receive.update(this.order.id, newStatus)
                .then(() => {
                    runInAction(() => {
                        if (!this.order) return;
                        this.order.status = newStatus
                    })
                })
        } catch (error) {

        }
        finally {
            this.setUpdatingOrder(false);
        }
    }

    @action replaceProductForShop = async (values: any) => {
        if (!this.order) return;

        values.locationId = this.order.locationId;
        return agent.HubOrders.replace(this.order?.id, values);
    }

    @action updateProductForShop = async (productId: string, packedAmount: number) => {
        if (!this.order) return;
        try {
            this.setUpdatingProduct(true);

            var idx = this.order?.products.findIndex(x => x.productId === productId);
            var requestedAmount = this.order.products[idx].quantity
            var status: "fully_packed" | "partially_packed" | "out_of_stock" | 'pending' | undefined = undefined;
            if (packedAmount >= requestedAmount) {
                status = "fully_packed"
            }
            else if (packedAmount < requestedAmount && packedAmount > 0) {
                status = "partially_packed"
            }
            else {
                status = "out_of_stock"
            }

            await agent.HubOrders.Receive.updateItemForShop(this.order.id, productId, {
                packedAmount: packedAmount,
                status: status
            }).then(() => {
                runInAction(() => {
                    if (!this.order) return;

                    var idx = this.order?.products.findIndex(x => x.productId === productId);
                    this.order.products[idx].packedAmount = packedAmount;
                    this.order.products[idx].shopStatus = status;
                })
            })
        }
        catch (error) {

        }
        finally {
            this.setUpdatingProduct(false);
        }
    }

    @action updateProductAmount = async (productId: string, returnedAmount?: number, missingAmount?: number) => {
        if (!this.order) return;

        try {
            this.setUpdatingProduct(true);

            var idx = this.order?.products.findIndex(x => x.productId === productId);
            var status = "partially_received"

            var quantity = this.order.products[idx].packedAmount ?? 0

            if (+(returnedAmount ?? 0) === this.order.products[idx].packedAmount) {
                status = "returned"
            }
            if (+(missingAmount ?? 0) === this.order.products[idx].packedAmount) {
                status = "missing"
            }
            if (quantity >= this.order.products[idx].quantity) {
                status = "fully_received"
            }

            quantity = (this.order.products[idx].packedAmount ?? 0) - (returnedAmount ?? 0) - (missingAmount ?? 0);

            await agent.HubOrders.Receive.updateItem(this.order?.id, productId, {
                quantity: quantity,
                returnedAmount: returnedAmount,
                missingAmount: missingAmount,
                status: status as "fully_received" | "partially_received" | "missing" | "returned" | 'pending'
            }).then(() => {
                runInAction(() => {
                    if (!this.order) return;

                    var idx = this.order?.products.findIndex(x => x.productId === productId);
                    this.order.products[idx].receivedAmount = quantity;
                    this.order.products[idx].returnedAmount = returnedAmount;
                    this.order.products[idx].missingAmount = missingAmount;
                    this.order.products[idx].status = status as "fully_received" | "partially_received" | "missing" | "returned" | 'pending';
                })

            })
        } catch (error) {

        }
        finally {
            this.setUpdatingProduct(false);
        }
    }

    @action updateProduct = async (productId: string, quantity: number, status: "fully_received" | "partially_received" | "missing" | "returned" | 'pending') => {
        if (!this.order) return;

        try {
            this.setUpdatingProduct(true);

            await agent.HubOrders.Receive.updateItem(this.order?.id, productId, {
                quantity: quantity,
                status: status
            }).then(() => {
                runInAction(() => {
                    if (!this.order) return;

                    var idx = this.order?.products.findIndex(x => x.productId === productId);
                    this.order.products[idx].receivedAmount = quantity;
                    this.order.products[idx].status = status;

                    if (status === "pending") {
                        this.order.products[idx].returnedAmount = 0;
                        this.order.products[idx].missingAmount = 0;
                    }
                })
            })
        } catch (error) {

        }
        finally {
            this.setUpdatingProduct(false);
        }
    }

    @action updateShoppingOrderPackedProduct = async (orderId: string, catalogueId: string, locationId: string, packedAmount: number) => {
        if (!this.order) return;

        try {
            this.setUpdatingProduct(true);

            var orderIdx = this.order.orders.findIndex(f => f.id === orderId);
            var productIdx = this.order.orders[orderIdx].products.findIndex(f => f.catalogueId === catalogueId);

            var requestQ = this.order.orders[orderIdx].products[productIdx].quantity

            var status: ClientOrderProductStatusType = +packedAmount === 0 ? "missing" : +packedAmount === requestQ ? "fully_packed" : "partially_packed"

            await agent.Orders.Admin.Monitor.updateItem(orderId, locationId, catalogueId, {
                quantity: +packedAmount,
                status: status
            }).then(() => {
                runInAction(() => {
                    if (!this.order) return;

                    var orderIdx = this.order.orders.findIndex(f => f.id === orderId);
                    var productIdx = this.order.orders[orderIdx].products.findIndex(f => f.catalogueId === catalogueId);

                    this.order.orders[orderIdx].products[productIdx].packedQuantity = packedAmount;
                    this.order.orders[orderIdx].products[productIdx].status = status;
                })

            })
        } catch (error) {

        }
        finally {
            this.setUpdatingProduct(false);
        }
    }

    @action undoShoppingOrderProduct = async (orderId: string, catalogueId: string, locationId: string) => {
        if (!this.order) return;

        try {
            this.setUpdatingProduct(true);

            await agent.Orders.Admin.Monitor.updateItem(orderId, locationId, catalogueId, {
                quantity: 0,
                status: "pending"
            }).then(() => {
                runInAction(() => {
                    if (!this.order) return;

                    var orderIdx = this.order.orders.findIndex(f => f.id === orderId);
                    var productIdx = this.order.orders[orderIdx].products.findIndex(f => f.catalogueId === catalogueId);

                    this.order.orders[orderIdx].products[productIdx].packedQuantity = 0
                    this.order.orders[orderIdx].products[productIdx].refundedQuantity = 0
                    this.order.orders[orderIdx].products[productIdx].status = "pending"
                })

            })
        } catch (error) {

        }
        finally {
            this.setUpdatingProduct(false);
        }
    }

    @action refundShoppingOrderProduct = async (orderId: string, catalogueId: string, locationId: string, quantity: number) => {
        if (!this.order) return;

        try {
            this.setUpdatingProduct(true);

            await agent.Orders.Admin.Monitor.refundItem(orderId, locationId, catalogueId, quantity).then(() => {
                runInAction(() => {
                    if (!this.order) return;

                    var orderIdx = this.order.orders.findIndex(f => f.id === orderId);
                    var productIdx = this.order.orders[orderIdx].products.findIndex(f => f.catalogueId === catalogueId);

                    this.order.orders[orderIdx].products[productIdx].refundedQuantity = quantity
                })

            })
        } catch (error) {

        }
        finally {
            this.setUpdatingProduct(false);
        }
    }

    @action exportDataForCsv = async () => {
        if (!this.order?.products) return;

        this.setDownloading(true);
        try {
            var csvData: any[] = [];

            csvData.push(["Product", "Barcode", "Quantity Asked", "Unit Cost", "Total Cost"]);

            this.order?.products.forEach((x) => {
                var finalAmount = x.receivedAmount ?? x.packedAmount ?? x.quantity;
                csvData.push([
                    x.productTitle,
                    x.sku ?? "-",
                    x.quantity,
                    currencyFormat(x.shopPrice, false),
                    currencyFormat(finalAmount * (x.shopPrice), false),
                ]);
            });

            var today = new Date();
            var fileName = `${today.getFullYear()}_${today.getMonth()}_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
            return {
                data: csvData,
                filename: `${this.order.displayId}_${fileName}.csv`,
            };

        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownloading(false);
        }
    }
}

export const HubOrderVerificationStore = new HubOrderVerification();