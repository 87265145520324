import { action, makeAutoObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IVATCategory } from "../../../interfaces/vat";

class VatCategories {
    @observable vatCategories: IVATCategory[] = [];
    @observable editingItem?: IVATCategory = undefined;
    @observable countryId?: number = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    @action setCategories = (value: IVATCategory[]) => this.vatCategories = value;
    @action setEditingItem = (value?: IVATCategory) => this.editingItem = value;

    @action load = (countryId?: number) => {
        if (!countryId) {
            this.countryId = undefined;
            this.setCategories([]);
        }
        else {
            this.countryId = countryId;
            agent.Catalogue.VAT.get(countryId).then(value => this.setCategories(value));
        }
    }

    @action save = async (values: IVATCategory) => {
        if (values.id) {
            return agent.Catalogue.VAT.update(values).then(() => {
                this.load(values.countryId)
                this.setEditingItem(undefined)
            });

        }
        else {
            return agent.Catalogue.VAT.create(values).then(() => {
                this.load(values.countryId)
                this.setEditingItem(undefined)
            });


        }
    }
}

export const VatCategoriesStore = new VatCategories();