import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { Brand } from "../../interfaces/search";

interface IProps {
  brand: Brand;
  onBrandClick: (brandId: string) => void;
  scroll?: () => void;
}
const BrandSliderItem: React.FC<IProps> = ({
  brand,
  onBrandClick,
  scroll
}) => {
  const context = useContext(RootStoreContext);
  const handleBrandClick = (brandId: string) => {
    setCategoryClickEvent(brandId);
    onBrandClick(brandId);
  };
  const { setCategoryClickEvent } = context.analyticsStore;
  // useSliderHook(brand.id, setCategoryClickEvent, onBrandClick, scroll)
  return (
    <div id={brand.id}
      key={brand.id}
      onClick={() => handleBrandClick(brand.id)}
    >
      <div className="horizontalListItemCaption">{brand.title}</div>

    </div>
  )
}
export default observer(BrandSliderItem);