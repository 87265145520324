import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IProductForList } from "../../../interfaces/product";
import { RootStore } from "../../../stores/RootStoreContext";

export class ProductsStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable discontinued: boolean = false;
    @observable unassociated?: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable orderCheapest: boolean = true;
    @observable products: IProductForList[] = []
    @observable searchText: string = "";
    @observable selectedCategories: string[] = [];
    @observable toaster?: React.ReactText = undefined;
    @action setLoading = (value: boolean) => this.loading = value;
    @action setUnassociated = (value?: boolean) => this.unassociated = value;
    @action setOrderCheapest = (value: boolean) => this.orderCheapest = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setToaster = (value?: React.ReactText) => this.toaster = value;
    @action resetProducts = (value: IProductForList[]) => this.products = value;
    @action setProducts = (value: IProductForList[]) => {
        var existing = this.products.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.products.push(...missing);
    };

    @action oneTimeLoad = async (unassociated?: boolean) => {
        try {
            this.setUnassociated(unassociated);

        } catch (error) {
        }
    }

    @action load = async (stems?: string) => {
        try {
            if (stems) {
                this.searchText = stems
            }

            this.setLoading(true);

            var response = this.unassociated ?
                await agent.Products.getUnassociated(
                    this.searchText,
                    this.discontinued,
                    this.rootStore.commonStore.selectedMarketIds,
                    this.currentPage) :
                await agent.Products.get(this.searchText,
                    this.selectedCategories,
                    this.rootStore.commonStore.selectedMarketIds,
                    this.orderCheapest,
                    this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setProducts(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action updateAssociationIdLocal = (productIds: string[], associationId: string) => {
        this.products = this.products.filter(f => !productIds.includes(f.id));
    }

    @action changeOrder = async () => {
        try {
            this.setOrderCheapest(!this.orderCheapest);

            this.resetProducts([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action changeDiscontinued = async () => {
        try {
            this.discontinued = !this.discontinued;
            this.rootStore.productCounterStore.discontinued = this.discontinued;
            this.resetProducts([]);
            this.setCurrentPage(0)

            this.rootStore.productCounterStore.load();
            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetProducts([]);
            this.setCurrentPage(0)

            this.load();

            if (text !== "" && text !== undefined)
                this.rootStore.analyticsStore.setSearchEvent("Online Shop", text);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action searchCategory = async (categoryId: string) => {
        try {
            if (this.selectedCategories.indexOf(categoryId) > -1) {
                this.selectedCategories = this.selectedCategories.filter(f => f !== categoryId);
            }
            else {
                // this.selectedCategories.push(categoryId);
                this.selectedCategories = [categoryId];
            }

            this.resetProducts([]);
            this.setCurrentPage(0)

            this.load();
            this.rootStore.analyticsStore.setSearchEvent("Online Shop - Category", categoryId);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.searchText = ""
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetProducts([]);
        this.setOrderCheapest(true);
        this.setHasMore(false);
        this.selectedCategories = [];
    }
}