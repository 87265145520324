import React, { useEffect, Fragment } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Container,
  Image,
  Form,
  Button,
  Message,
  Icon,
} from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import {
  combineValidators,
  isRequired,
  composeValidators,
  matchesField,
} from "revalidate";
import agent from "../../api/agent";
import PasswordInput from "../../form/PasswordInput";
import ErrorMessage from "../../form/ErrorMessage";
import { observer } from "mobx-react-lite";

const validate = combineValidators({
  newPassword: isRequired("Password"),
  confirmNewPassword: composeValidators(
    isRequired("Confirm Password"),
    matchesField("newPassword", "Password does not match")
  )("Is empty or password does not match"),
});

const PasswordResetForm = () => {
  const navigate = useNavigate();
  let { id } = useParams<"id">();
  let { token } = useParams<"token">();
  const [resetComplete, setResetComplete] = React.useState(false);

  useEffect(() => {
    if (!id || !token) {
      navigate("/notFound");
    }
  }, [id, token, navigate]);

  return (
    <Grid stackable centered style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Row columns={1}>
        <Grid.Column width={resetComplete ? 6 : 5} textAlign="center">
          <Container style={{ padding: "0px" }}>
            <Image
              src="/early.svg"
              alt="Cheap Trolley Logo"
              size="medium"
              centered
            />
          </Container>
          {!resetComplete && (
            <Fragment>
              <h3>Insert your new password below</h3>

              <FinalForm
                onSubmit={(values: any) =>
                  agent.Auth.passwordReset(id!, {
                    token: token,
                    ...values,
                  })
                    .then((response: any) => {
                      setResetComplete(true);
                      setTimeout(() => navigate("/"), 3000);
                    })
                    .catch((error) => ({
                      [FORM_ERROR]: error,
                    }))
                }
                validate={validate}
                render={({
                  handleSubmit,
                  submitting,
                  submitError,
                  dirtySinceLastSubmit,
                }) => (
                  <Form
                    onSubmit={handleSubmit}
                    error
                    style={{ paddingTop: "15px" }}
                  >
                    <Field
                      name="newPassword"
                      component={PasswordInput}
                      placeholder="Password"
                      className="passwordIcon customInputIcon"
                    />
                    <Field
                      name="confirmNewPassword"
                      component={PasswordInput}
                      placeholder="Password Confirm"
                      className="passwordIcon customInputIcon"
                    />
                    <Button
                      loading={submitting}
                      className="ct-green-button-active"
                      content="Reset Password"
                    />

                    {submitError && !dirtySinceLastSubmit && (
                      <ErrorMessage error={submitError} />
                    )}
                  </Form>
                )}
              />
            </Fragment>
          )}
          {resetComplete && (
            <Message
              positive
              icon={<Icon name="check" />}
              header={"Your password has changed successfully!"}
              content={
                <Fragment>
                  {"You can click "}
                  <NavLink to="/">here</NavLink>
                  {
                    " to login or wait 3 seconds and we will redirect you automatically to the login page!"
                  }
                </Fragment>
              }
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(PasswordResetForm);
