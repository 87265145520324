import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IRequestForShopClaim } from "../../../interfaces/shopClaim";
import { RootStore } from "../../../stores/RootStoreContext";

export class ClaimRequestStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable statuses: string[] = ["pending_approval"];
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable claims: IRequestForShopClaim[] = []

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action setStatuses = (value: string[]) => { this.statuses = value };
    @action setHasMore = (value: boolean) => { this.hasMore = value };
    @action resetClaims = (value: IRequestForShopClaim[]) => { this.claims = value };
    @action setClaims = (value: IRequestForShopClaim[]) => {
        var existing = this.claims.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);
        this.claims.push(...missing);
    };

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.Shop.Claims.get(this.statuses, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setClaims(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action search = async (text: string) => {
        try {
            this.resetClaims([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action reject = async (id: string) => {
        try {
            agent.Shop.Claims.reject(id).then(() => {
                this.resetClaims(this.claims.filter(f => f.id !== id));
            })
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action approve = async (id: string) => {
        try {
            agent.Shop.Claims.approve(id).then(() => {
                var idx = this.claims.findIndex(f => f.id === id);
                agent.Shop.Manage.updateUser(this.claims[idx].userId, { accountId: this.claims[idx].shopId, roleId: 1 }).then(() => {
                    this.resetClaims(this.claims.filter(f => f.id !== id))
                });
            })
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetClaims([]);
        this.setHasMore(false);
        this.statuses = ["pending_approval"]
    }
}