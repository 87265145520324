import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { useParams } from 'react-router';
import { CampaignsDetailStore } from '../functions/CampaignsDetailStore';
import { Button, Divider, Segment, Statistic, StatisticLabel, StatisticValue } from 'semantic-ui-react';

const MarketingCampaignInProgress = () => {
    let { id } = useParams<"id">();

    const {
        campaignStats,
        getCampaignStats,
        sendBulkEmails,
        sendingEmails
    } = CampaignsDetailStore;

    useEffect(() => {
        if (!id) return;

        getCampaignStats(id!)

        return () => {

        }
    }, [getCampaignStats, id])


    return (
        <Segment basic textAlign='center'>
            <div className='fxDisplay fxFixed fxJustifyEven'>
                {campaignStats?.map((x: any) => (<Statistic>
                    <StatisticValue>{x.total}</StatisticValue>
                    <StatisticLabel>{x.status}</StatisticLabel>
                </Statistic>))}
            </div>
            <Divider />
            <Button
                loading={sendingEmails}
                disabled={sendingEmails}
                color='green'
                basic
                content="Send E-mails"
                onClick={() => sendBulkEmails(id!)} />
        </Segment>
    )
}


export default observer(MarketingCampaignInProgress)