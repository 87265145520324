import { observer } from "mobx-react-lite";
import React from "react";
import { combineValidators, isRequired } from "revalidate";
import { Form as FinalForm, Field } from "react-final-form";
import { FormApi, FORM_ERROR } from "final-form";
import { IAssociationForSave } from "../../../interfaces/association";
import { getImageUrlPlain } from "../../../helpers/productImage";
import { IProduct } from "../../../interfaces/product";
import { Button, Form, Image, Label } from "semantic-ui-react";
import { LoadingParagraph } from "../../../components/LoadingParagraph";
import TextInput from "../../../form/TextInput";
import NumericInput from "../../../form/NumericInput";
import SelectInput from "../../../form/SelectInput";
import ErrorMessage from "../../../form/ErrorMessage";
import TextAreaInput from "../../../form/TextAreaInput";

interface IProps {
  fastMode?: boolean;
  byWeight?: boolean;
  loading: boolean;
  approveAssociation: (values: IAssociationForSave) => Promise<void>;
  products: IProduct[];
}

const validate = combineValidators({
  title: isRequired(""),
});

const NewAssociationWidget: React.FC<IProps> = ({
  loading,
  approveAssociation,
  products,
  fastMode = false,
  byWeight = false,
}) => {
  return (
    <div>
      <FinalForm
        onSubmit={(values: IAssociationForSave) =>
          approveAssociation(values).catch((error: any) => ({
            [FORM_ERROR]: error,
          }))
        }
        initialValues={{
          imageUrl: getImageUrlPlain(
            products[0].marketId,
            products[0].imageUrl
          ),
          title: products[0].itemDesc,
          measurementUnit: "grams",
          measurment: undefined,
          byWeight: byWeight,
          noOfItems: 1,
        }}
        validate={validate}
        render={({
          handleSubmit,
          submitError,
          dirtySinceLastSubmit,
          submitting,
          form,
          values,
        }) => {
          (window as any).groupForm = form;

          return (
            <Form onSubmit={handleSubmit} error>
              <div style={{ textAlign: "center" }}>
                <div className="fxDisplay fxFixed">
                  <div className="fxDisplay fxFixed fxDirCol">
                    <Image
                      alt="Product Logo"
                      src={
                        values?.imageUrl ??
                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                      }
                      onError={({ currentTarget }: any) => {
                        currentTarget.onerror = null;
                        currentTarget.src =
                          `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                      }}
                      className="fxFixed"
                      style={{
                        maxWidth: fastMode ? "75px" : "225px",
                        alignSelf: "center",
                        objectFit: "contain",
                      }}
                    />
                    {values?.imageUrl && (
                      <span
                        title="Convert to Group"
                        className="item linkable"
                        onClick={() => {
                          ((window as any).groupForm as FormApi).change(
                            "imageUrl",
                            undefined
                          );
                        }}
                      >
                        <i
                          aria-hidden="true"
                          className="red large fitted icon file image outline"
                        ></i>{" "}
                        Remove Image
                      </span>
                    )}
                  </div>

                  <div
                    className="fxDirCol fxStretch fxDisplay"
                    style={{ padding: "7px" }}
                  >
                    {!fastMode && (
                      <Form.Group widths="equal">
                        <Form.Field style={{ textAlign: "left" }}>
                          <label style={{ fontSize: "13px" }}>
                            {"Association Title"}
                          </label>
                          <Field
                            loading={loading}
                            component={TextInput}
                            name="title"
                            placeholder={"Title"}
                          />
                        </Form.Field>
                      </Form.Group>
                    )}
                    <Form.Group widths={fastMode ? "5" : "4"} >
                      {fastMode && <Form.Field style={{ textAlign: "left" }}>
                        <label style={{ fontSize: "13px" }}>
                          {"Association Title"}
                        </label>
                        <Field
                          loading={loading}
                          component={TextAreaInput}
                          name="title"
                          placeholder={"Title"}
                        />
                      </Form.Field>}
                      <Form.Field style={{ textAlign: "left" }} >
                        <label style={{ fontSize: "13px" }}>
                          {"No of items"}
                        </label>
                        {loading ? (
                          <LoadingParagraph />
                        ) : (
                          <Field
                            component={NumericInput}
                            name="noOfItems"
                            placeholder={"No of items"}
                          />
                        )}
                      </Form.Field>
                      <Form.Field style={{ textAlign: "left" }}>
                        <label style={{ fontSize: "13px" }}>
                          {"Measurement Unit"}
                        </label>
                        <Field
                          loading={loading}
                          fluid={false}
                          component={SelectInput}
                          options={[
                            {
                              value: "grams",
                              key: "grams",
                              text: "grams",
                            },
                            {
                              value: "ml",
                              key: "ml",
                              text: "ml",
                            },
                          ]}
                          name="measurementUnit"
                          placeholder={"Measurement Unit"}
                        />
                      </Form.Field>
                      <Form.Field style={{ textAlign: "left" }}>
                        <label style={{ fontSize: "13px" }}>
                          {"Measurment"}
                        </label>
                        {loading ? (
                          <LoadingParagraph />
                        ) : (
                          <Field
                            component={NumericInput}
                            name="measurment"
                            placeholder={"Measurment e.g. 700"}
                          />
                        )}
                        <Label
                          color="blue"
                          basic
                          size="small"
                          style={{ marginTop: "4px" }}
                          content="100"
                          onClick={() =>
                            ((window as any).groupForm as FormApi).change(
                              "measurment",
                              100
                            )
                          }
                        />
                        <Label
                          color="blue"
                          basic
                          size="small"
                          style={{ marginTop: "4px" }}
                          content="500"
                          onClick={() =>
                            ((window as any).groupForm as FormApi).change(
                              "measurment",
                              500
                            )
                          }
                        />
                        <Label
                          color="blue"
                          basic
                          size="small"
                          style={{ marginTop: "4px" }}
                          content="750"
                          onClick={() =>
                            ((window as any).groupForm as FormApi).change(
                              "measurment",
                              750
                            )
                          }
                        />
                        <Label
                          color="blue"
                          size="small"
                          basic
                          style={{ marginTop: "4px" }}
                          content="1000"
                          onClick={() =>
                            ((window as any).groupForm as FormApi).change(
                              "measurment",
                              1000
                            )
                          }
                        />
                      </Form.Field>
                      <Form.Field style={{ textAlign: "left" }}>
                        <label style={{ fontSize: "13px" }}>{"Step"}</label>
                        {loading ? (
                          <LoadingParagraph />
                        ) : (
                          <Field
                            component={NumericInput}
                            name="step"
                            placeholder={"e.g. 100"}
                          />
                        )}
                        <Label
                          color="blue"
                          basic
                          size="small"
                          style={{ marginTop: "4px" }}
                          content={`0.1 of ${values.measurment ?? 0}`}
                          onClick={() =>
                            ((window as any).groupForm as FormApi).change(
                              "step",
                              0.1
                            )
                          }
                        />
                        <Label
                          color="blue"
                          basic
                          size="small"
                          style={{ marginTop: "4px" }}
                          content={`1 of ${values.measurment ?? 0}`}
                          onClick={() =>
                            ((window as any).groupForm as FormApi).change(
                              "step",
                              1
                            )
                          }
                        />
                      </Form.Field>
                    </Form.Group>
                  </div>
                </div>
              </div>
              {
                submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage error={submitError} />
                )
              }

              <div style={{ textAlign: "center" }}>
                <Button color="blue" loading={submitting} content={"Save"} />
              </div>
            </Form>
          );
        }}
      />
    </div >
  );
};

export default observer(NewAssociationWidget);
