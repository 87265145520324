import { action, observable, makeObservable } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { IModule, IModuleForList, INewModuleForAssignment } from "../../../interfaces/billing";

export default class BillingModuleStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable selectedAccountId?: string = undefined;
    @observable loading: boolean = true;
    @observable searching: boolean = false;
    @observable lastId: string | null = null;
    @observable lastSearch: string = "";
    @observable items: IModuleForList[] = [];
    @observable availableModules: IModule[] = [];

    @action setAvailableModules = (value: IModule[]) => this.availableModules = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setLoading = (value: boolean) => this.loading = value;
    @action setSearching = (value: boolean) => this.searching = value;
    @action setItems = (value: IModuleForList[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };

    @action oneTimeLoad = async (selectedAccountId?: string) => {
        try {
            this.loading = true;
            this.selectedAccountId = selectedAccountId;

            agent.Shop.Billing.Modules.types().then((items) => {
                this.setAvailableModules(items);
            })

        } catch (error) {
        }
    }

    @action loadData = async () => {
        try {
            if (!this.selectedAccountId) return;

            this.loading = true;
            var response = await agent.Shop.Billing.Modules.get(
                this.selectedAccountId,
                this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
            console.log(error)
        }
    }

    @action reload = async () => {
        try {
            if (!this.selectedAccountId) return;

            this.currentPage = 0;
            this.hasMore = true;
            this.items = [];

            this.loadData();
        } catch (error) {
            console.log(error)
        }
    }

    @action assignModule = async (values: INewModuleForAssignment) => {
        if ((values.startsOn as any) instanceof (Date)) {
            values.startsOn = (values.startsOn as any).getTime() / 1000;
        }

        return agent.Shop.Billing.Modules.assign(values.accountId, values).then((id) => {
            this.reload();
        })

    }

    @action dispose = () => {
        this.selectedAccountId = undefined;
        this.currentPage = 0;
        this.loading = true;
        this.searching = false;
        this.lastId = null;
        this.hasMore = false;
        this.lastSearch = "";
        this.items = [];
    }

}