import React, { useContext } from "react";
import { Menu, Image, Icon } from "semantic-ui-react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { currencyFormat } from "../products/functions/productHelper";
import Search from "../search/Search";

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { setSidebarVisible, sidebarVisible } = context.menuStore;
  const { search, selectedBrands, selectedCategories } = context.shoppingCatalogueStore;
  const {
    menuLoading,
    setCheckingOut,
  } = context.shoppingListStore;
  const { isAdmin } = context.userStore;
  const { isLoggedIn } = context.sessionStore;
  const { getShoppingCartCost, loadingShoppingCart } = context.shoppingCartStore;
  const { state, setDrawerState } = context.searchStore;

  const location = useLocation();
  const checkProductsPath = (location.pathname === "/online-shop" || location.pathname === "/");
  const headerOffset = (document.querySelector('header')?.offsetHeight || 140);
  const scrollToTop = () => {
    if (state.isDrawerOpen === true) {
      setDrawerState(false);
    }
    window.scrollTo({
      top:
        0 - headerOffset,
      behavior: 'smooth',
    });
  };
  return (
    <header className="setToBack" style={{ maxWidth: "100vw" }}>
      <div>
        <Menu icon secondary fixed="top" className="appHeader">
          <Menu.Item
            style={{ color: "black", fontSize: "2em", padding: "0.2em" }}
            icon="sidebar"
            onClick={() => {
              setSidebarVisible(!sidebarVisible);
            }}
          ></Menu.Item>

          <Menu.Item
            header
            as={NavLink}
            className="no-padding"
            style={{ backgroundColor: "transparent" }}
            to="/"
          >
            <Image
              src="/cheaptrolley_slogan.svg"
              // size="small"
              style={{ maxHeight: "30px", maxWidth: "140px" }}
            />
          </Menu.Item>

          <Menu.Menu style={{ marginRight: "5%" }} position="right">
            <Menu.Item
              className="exclude"
              style={{ paddingRight: "0px" }}
            >
              <Icon
                name="whatsapp"
                size="big"
                className="clickable new-green"
                style={{ padding: "0px" }}
                onClick={() => { window.open("https://wa.me/+35627995146", '_blank'); }} />
            </Menu.Item>
            {!isAdmin && (
              <Menu.Item
                onClick={() => {
                  setCheckingOut(false);
                  navigate("/my-shopping-list");
                }}
              >
                <div className="fxDisplay fxFixed fxJustifyCenter fxDirCol fxAlignCenter no-padding">
                  <Image style={{ height: "20px", padding: "0px", width: "20px" }} src={"/shoppingCart.png"} />
                  {isLoggedIn && (
                    <div
                      style={{
                        fontSize: "17px",
                        fontWeight: "600",
                        paddingLeft: "7px",
                        color: "#21973f",
                      }}
                    >
                      {currencyFormat((getShoppingCartCost) ?? 0, !(menuLoading || loadingShoppingCart))}
                      {(menuLoading || loadingShoppingCart) && <Icon name="circle notch" loading />
                      }
                    </div>
                  )}
                </div>
              </Menu.Item>
            )}
          </Menu.Menu>
        </Menu>
      </div>
      {checkProductsPath &&
        <Search
          scroll={scrollToTop}
          onSubmit={(data: any) => {
            //if (!search) return;
            search(data.searchTerm, selectedBrands, selectedCategories);
          }}
        />}

    </header>
  );
};

export default observer(NavBar);
